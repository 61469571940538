
import { push } from 'connected-react-router'
import axios from 'axios';
import { toastr } from 'react-redux-toastr';
import { getHeaders } from "../oidc/AuthCallBack";
// import { withCookies, Cookies } from 'react-cookie';
import { getToken } from "../oidc/AuthService"
export const ADD_MODAL = 'ADD_MODAL'
var localurl = "";

/**
 * 
 */
export function setUserRoles(userMdl) {
    return function (dispatch) {
        if (userMdl !== undefined) {
            console.log("userMdl", userMdl)
            userMdl['expired'] = false;
            userMdl['accessControl'] = userMdl.accessControlList;
            dispatch({
                type: "SET_USER_DETAILS",
                payload: userMdl
            })
            if (window.outerWidth < 768 && userMdl.activeProgramID <= 3) {
                dispatch(push({ pathname: "/download" }));
            } else {
                dispatch(push({ pathname: "/home",query: { flagCEA: userMdl.flagCEA, flagNET: userMdl.flagNET, flagUC: userMdl.flagUC,flagLN:userMdl.flagLN ,flagAudit: userMdl.flagTSP }}));
            }

        }

    }
}

export function updateUserMdl(userMdl, renderHome) {
    return function (dispatch) {
        dispatch({
            type: "SET_USER_DETAILS",
            payload: userMdl
        })
        if(renderHome){
            dispatch(push({ pathname: "/home" }));
        }
    }

}

/**
 * Fetch User Acces Control List from server.
 */
export function fetchAccessControlList(userMdl) {
    var userDto = {
        roles: userMdl.roles,
        lang: userMdl.lang
    }
    return function (dispatch) {
        setBlockingState(true);
        axios.post(localurl + '/user/access-control-list/' + userMdl.username + '/' + userMdl.nmsc, userDto,
            {
                headers: {
                    'Access-Control-Allow-Origin': '*',
                    'Authorization': "Bearer " + getToken()
                }
            }

        )
            .then(response => {
                setBlockingState(false);
                if (response.data !== undefined) {
                    userMdl['accessControlList'] = response.data.accessControlList;
                    userMdl['userLangList'] = response.data.userLangList;
                    userMdl['fullName'] = response.data.fullName;
                    userMdl['gdprFlag'] = response.data.gdprFlag;
                    userMdl['programList'] = response.data.programList;
                    userMdl['countryCode'] = response.data.countryCode
                    userMdl["notificationsFlag"] = response.data.notificationsFlag;
                    userMdl["themeFlag"] = response.data.themeFlag;
                    userMdl['businessAreasList'] = response.data.businessAreasList;
                    userMdl['activeBusinessAreaID'] = response.data.businessAreasList[0].id
                    userMdl['activeProgram'] = response.data.businessAreasList[0].programsDTOList[0].programKey;
                    userMdl['activeProgramName'] = response.data.businessAreasList[0].programsDTOList[0].programName;
                    userMdl['activeProgramID'] = response.data.businessAreasList[0].programsDTOList[0].id
                    userMdl['activeProgramOBJ'] = response.data.businessAreasList[0].programsDTOList[0];
                    userMdl['businessAreaNameEn'] = response.data.businessAreasList[0].businessAreaNameEn
                    userMdl['businessAreaKey'] = response.data.businessAreasList[0].businessAreaKey;
                    userMdl['flagValueChain'] = response.data.isValueChain;
                    userMdl['flagBRiT'] = response.data.isBRiT;
                    userMdl['flagCEA'] = response.data.isCEA;
                    userMdl['flagNET'] = response.data.isNET;
                    userMdl['flagEA'] = response.data.isEA;
                    userMdl['flagUC'] = response.data.isUC;
                    userMdl['flagLN'] = response.data.isLN;
                    userMdl['accessNavList'] = response.data.accessNavList;
                    userMdl['flagAudit'] = response.data.isTSP
                    userMdl['getNetworkUserPrimaryKey'] = response.data.getNetworkUserPrimaryKey;
                    if (response.data.getCEAYears && response.data.getCEAYears.length) {
                        let date = new Date();
                        userMdl['ceaDefaultYears'] = { label: date.getFullYear(), value: date.getFullYear() }
                        userMdl['getCEAYears'] = response.data.getCEAYears;
                    } else {
                        let date = new Date();
                        userMdl['ceaDefaultYears'] = { label: date.getFullYear(), value: date.getFullYear() }
                        userMdl['getCEAYears'] = [];
                    }
                    dispatch(setUserRoles(userMdl));
                }
            })
            .catch(function (error) {
                setBlockingState(false);
                console.error(error);
            });
        // For Local Use.
        //  userMdl['accessControlList'] = [{"function":"MASTER_KPI","accessType":"R/W"},{"function":"LOCAL_KPI","accessType":"R"},{"function":"DOWNLOAD_Q","accessType":"R/W"},{"function":"ACTIVITY","accessType":"R/W"},{"function":"ACTIVITY_DETAIL","accessType":"R/W"},{"function":"SUB_QUESTIONNAIRE","accessType":"R/W"},{"function":"QUESTIONNAIRE_CHAPTER","accessType":"R/W"},{"function":"LIBRARY","accessType":"R/W"},{"function":"ACTIVITY_EVALUATION","accessType":"R/W"},{"function":"TME ADMIN MENU","accessType":"R/W"},{"function":"ACTIVITY_EVALUATION_ACTION","accessType":"R/W"},{"function":"TME ADMIN MENU","accessType":"R/W"}];
        //  userMdl['userLangList'] = [];
        //  userMdl['fullName'] = 'OCTAVIAN CIOROIANU';
        //  userMdl['gdprFlag'] = 1;
        //  userMdl['programList'] = ["TSM","TSM_BNP","TSM_USED_CAR"];
        //  userMdl['activeProgram'] = "TSM";
        //  dispatch(setUserRoles(userMdl));   
    }
}

/**
 * 
 */
export function setBlockingState(isBlocked) {
    return function (dispatch) {
        dispatch({
            type: "SET_BLOCKING_STATE",
            payload: isBlocked
        })
    }
}

/**
 * 
 */
export function authenticateUser(userObj) {
    console.log(userObj);
    return function (dispatch) {
        console.log(userObj);
        setBlockingState(true);
        axios.post(
            localurl + '/api/auth', userObj, {
            headers: {
                'Content-type': 'application/json',
                'Accept': 'application/json',
                'Pragma': 'no-cache'
            },
        }).then(function (response) {
            setBlockingState(false);

            // const cookies = new Cookies();
            // cookies.set('jwt', response.data.tokenType + ' ' + response.data.accessToken,{path: "/"})
            // cookies.set('usrMdl', response.data,{path: "/"})
            axios.defaults.headers.common['Authorization'] = response.data.tokenType + ' ' + response.data.accessToken;
            axios.defaults.baseURL = '/pmsc';
            dispatch(setUserRoles(response.data));

        })
            .catch(function (error) {
                setBlockingState(false);

                if (error.response !== undefined) {
                    toastr.error(error.response.data);
                }
            });
    }
}

export function logOut() {
    return function (dispatch) {
        dispatch({
            type: "LOG_OUT",
            payload: {}
        })
    }
}

export function getUserDetails() {
    return function (dispatch) {
        axios.get(localurl + '/user/access-control-list', {
            headers: {
                'Access-Control-Allow-Origin': '*',
                'Authorization': 'Bearer ' + getToken()
            }
        })
            .then(response => {
                if (response.data !== undefined) {
                    var userMdl = response.data;
                    userMdl['activeBusinessAreaID'] = response.data.businessAreasList[0].id;
                    userMdl['activeProgram'] = response.data.businessAreasList[0].programsDTOList[0].programKey;
                    userMdl['activeProgramID'] = response.data.businessAreasList[0].programsDTOList[0].id;
                    userMdl['activeProgramOBJ'] = response.data.businessAreasList[0].programsDTOList[0];
                    userMdl['activeProgramName'] = response.data.businessAreasList[0].programsDTOList[0].programName;
                    userMdl['businessAreaNameEn'] = response.data.businessAreasList[0].businessAreaNameEn
                    userMdl['businessAreaKey'] = response.data.businessAreasList[0].businessAreaKey;
                    userMdl['flagValueChain'] = response.data.isValueChain;
                    userMdl['flagBRiT'] = response.data.isBRiT;
                    userMdl['flagCEA'] = response.data.isCEA;
                    userMdl['flagNET'] = response.data.isNET;
                    userMdl['flagEA'] = response.data.isEA;
                    userMdl['flagUC'] = response.data.isUC;
                    userMdl['flagLN'] = response.data.isLN;
                    userMdl['accessNavList'] = response.data.accessNavList;
                    userMdl['countryCode'] = response.data.countryCode;
                    userMdl['flagAudit'] = response.data.isTSP
                    userMdl['getNetworkUserPrimaryKey'] = response.data.getNetworkUserPrimaryKey;
                    if (response.data.getCEAYears && response.data.getCEAYears.length) {
                        let date = new Date();
                        userMdl['ceaDefaultYears'] = { label: date.getFullYear(), value: date.getFullYear() };
                        userMdl['getCEAYears'] = response.data.getCEAYears;
                    } else {
                        let date = new Date();
                        userMdl['ceaDefaultYears'] = { label: date.getFullYear(), value: date.getFullYear() }
                        userMdl['getCEAYears'] = [];
                    }
                    console.log(userMdl);
                    dispatch(setUserRoles(userMdl));
                }
            })
            .catch(function (error) {
                setBlockingState(false);
                console.error(error);
            });
    }
}

export function updateGdprFlag(userMdl) {
    var obj = {
        username: userMdl.username
    }
    return function (dispatch) {
        axios.post("/user/updateGdpr", obj, {
            headers: {
                "Content-type": "application/json",
                Accept: "application/json",
                Pragma: "no-cache"
            }
        })
            .then(response => {

            })
            .catch(function (error) {
                setBlockingState(false);
                console.error(error);
            });
    }
}


/**
 * Fetch User Acces Control List from server.
 */
export function getAccessControlList(userMdl) {
    var userDto = {
        roles: userMdl.roles,
        businessAreaNameEn: userMdl.businessAreaNameEn,
        businessAreaKey: userMdl.businessAreaKey,
        programKey: userMdl.activeProgram
    }
    console.log(userDto,userMdl);
    return function (dispatch) {
        setBlockingState(true);
        axios.post(localurl + '/user/access_control_list/' + userMdl.activeBusinessAreaID, userDto,{
            headers: {
                'Access-Control-Allow-Origin': '*',
                'Authorization': "Bearer " + getToken()
            }
     })
            .then(response => {
                setBlockingState(false);
                if (response.data !== undefined) {
                    userMdl['accessControlList'] = response.data.accessControlList;
                    userMdl['accessNavList'] = response.data.accessNavList;
                    userMdl['flagValueChain'] = response.data.isValueChain;
                    userMdl['flagBRiT'] = response.data.isBRiT;
                    userMdl['flagCEA'] = response.data.isCEA;
                    userMdl['flagNET'] = response.data.isNET;
                    userMdl['flagEA'] = response.data.isEA;
                    userMdl['flagUC'] = response.data.isUC;
                    userMdl['flagLN'] = response.data.isLN;
                    //userMdl['countryCode'] = response.data.countryCode;
                    userMdl['flagAudit'] = response.data.isTSP
                    console.log("On Program Change")
                    console.log(userMdl)
                    console.log(response.data.accessControlList)
                    console.log(response.data)
                    if (response.data.getCEAYears && response.data.getCEAYears.length) {
                        let date = new Date();
                        userMdl['ceaDefaultYears'] = { label: date.getFullYear(), value: date.getFullYear() };
                        userMdl['getCEAYears'] = response.data.getCEAYears;
                    } else {
                        let date = new Date();
                        userMdl['ceaDefaultYears'] = { label: date.getFullYear(), value: date.getFullYear() }
                        userMdl['getCEAYears'] = [];
                    }
                    dispatch(setUserRoles(userMdl));
                }
            })
            .catch(function (error) {
                setBlockingState(false);
                console.error(error);
            });
    }
}

/**
 * Toggle notifications flag
 */
export function toggleNotifications(userMdl) {
    var obj = {
        username: userMdl.username,
        notificationsFlag: userMdl.notificationsFlag
    }
    return function (dispatch) {
        axios.post("/user/toggleNotifications", obj, {
            headers: {
                'Authorization': 'Bearer ' + getToken(),
                "Content-type": "application/json",
                Accept: "application/json",
                Pragma: "no-cache"
            }
        })
        .then(response => {
            console.log("success");
        })
        .catch(function (error) {
            setBlockingState(false);
            console.error(error);
        });
    }
}

/**
 * Toggle theme flag
 */
 export function toggleTheme(userMdl) {
    var obj = {
        username: userMdl.username,
        themeFlag: userMdl.themeFlag
    }
    return function (dispatch) {
        axios.post("/user/toggleTheme", obj, {
            headers: {
                'Authorization': 'Bearer ' + getToken(),
                "Content-type": "application/json",
                Accept: "application/json",
                Pragma: "no-cache"
            }
        })
        .then(response => {
            console.log("success");

        })
        .catch(function (error) {
            setBlockingState(false);
            console.error(error);
        });
    }
}

export function userInfoDetails(token){
    const BEARER_TOKEN = token;
    return function (dispatch) {
        axios.get("/user/userInfoDetails", {
            headers: {
                'Authorization': `Bearer ${BEARER_TOKEN}`
            }
        })
        .then(response => {
            console.log(response.data);
            if(response.status==200){
                const userDto = {
                    username: response.data.username,
                    roles: response.data.roles,
                    lang: response.data.lang,
                    nmsc: response.data.nmsc,
                    // role: [{ label: "KET TME Admin", value: "KET TME Admin" }]
                };
                dispatch(fetchAccessControlList(userDto))
                dispatch(getUserDetails())
            }
        })
        .catch(error => {
            console.error('Error:', error);
            const userDto = {
                        username: 'OCI7842@TMME',
                        roles: [
                            "KET-CEA User",
                            "KET-UC Admin",
                            "KET-ReachIT Admin",
                            "KET-NET Admin",
                            "KET-EA Admin",
                            "KET TME Admin",
                            "KET-BRiT TME Admin"
                        ],
                        lang: 'en',
                        nmsc: 'tme',
                        // role: [{ label: "KET TME Admin", value: "KET TME Admin" }]   
                    };
                    dispatch(fetchAccessControlList(userDto));

        });
    }
   
}