import React from "react";
import { Modal, ModalBody, ModalHeader, ModalFooter, Row } from "reactstrap";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { saveBusinessFunToDB } from "../../actions/leftNavAction.jsx";
import { withTheme } from '../../themes/theming';
import { Container } from "react-bootstrap";
import { toastr } from "react-redux-toastr";
import Select from "react-select";
import * as AppConst from '../../AppConstant';
var I18n = require("react-redux-i18n").I18n;

class AddEditBusiFunc extends React.Component {
    /* eslint-disable */
    constructor(props) {
        super(props);
        this.state = {
            selectedRow: {
                selectedUser5: "",
                selectedUser4: "",
                selectedUser3: "",
                selectedUser2: "",
                selectedUser1: "",
                isDisabled5: false,
                isDisabled4: false,
                isDisabled3: false,
                isDisabled2: false,
                isDisabled1: false,
                display5: true,
                display4: true,
                display3: true,
                display2: true,
                display1: false,
            },
            businessFunction: '',
            packageOwnerIds: '',
            count: 0,
            ownerLists: [],  
            ownerListsAarr: [],
            ceaBusinessOwnerList:this.props.ceaBusinessOwnerList,
            ceaYear:this.props.ceaYear,
            newBusiFun:true,
            selectedToolboxName: '',
            toolboxId: '',                              
        }
    }

    componentDidMount(){
        const selectedRow = { ...this.state.selectedRow };
        if(this.props.modalFor && this.props.modalFor === "Edit"){
            selectedRow["isDisabled1"] = true
            this.setState({ selectedRow });
        }else{
            this.setState({ newBusiFun:false });
        }        
    }

    changeBusinessName = e => {
        this.setState({ businessFunction: e.target.value });
    }    

    saveBusinessFunction = () => {
        let businessFunction = this.state.businessFunction.replace(/\s/g, '').trim();
        if (this.props.modalFor==='Edit' && this.state.selectedToolboxName.length === 0) {
            toastr.error(I18n.t('others.select')+" "+I18n.t('kpiSetUp.businessFunction'));
            return;
        }
        if (businessFunction.length === 0) {
            toastr.error(I18n.t('kpiSetUp.businessFName'));
            return;
        }        
        this.state.packageOwnerIds = this.state.ownerLists.length> 0 ? this.state.ownerLists.toString() : ""        
        if (this.state.ownerLists.length === 0 && this.props.user.userMdl.flagCEA) {
            toastr.error(I18n.t('kpiSetUp.selectBusinessOwner'));
            return;
        }
        let businessFunctionDTO = {
            toolboxId: this.state.toolboxId,
            businessOwner: this.state.packageOwnerIds,
            toolboxName: this.state.businessFunction,
            action: this.props.modalFor,
            userId: this.props.user.userMdl.username,
            program: this.props.user.userMdl.activeProgramID,
            type: this.props.user.userMdl.businessAreaKey === AppConst.businessAreaKeyPer ? this.props.user.userMdl.activeProgram : AppConst.TYPE_CEA,
            year: this.state.ceaYear
        };
        this.props.dispatch(saveBusinessFunToDB(businessFunctionDTO, this));
    }
    
    onBusinessOwnerChange = (e, selection) => {
        let ownerLists = this.state.ownerLists
        let ownerListsAarr = this.state.ownerListsAarr
        
        let ceaOwnerList = []
        var count = this.state.count;
        const selectedRow = { ...this.state.selectedRow };       

        const display = selection === "isDisabled1" ? "display2"  :
        selection === "isDisabled2" ? "display3"  :
        selection === "isDisabled3" ? "display4"  : "display5" 

        const selectedUser = selection === "isDisabled1" ? "selectedUser1"  :
        selection === "isDisabled2" ? "selectedUser2"  :
        selection === "isDisabled3" ? "selectedUser3"  :
        selection === "isDisabled4" ? "selectedUser4"  : "selectedUser5"     
            
        ownerLists.push(e.value);
        ownerListsAarr.push(e);        
        ceaOwnerList = this.state.ceaBusinessOwnerList.filter((item) => item.value !== e.value);         
        count = count + 1;
        if(count<5 && selection === "isDisabled5"){
            selectedRow["selectedUser2"] = selectedRow["selectedUser3"] =selectedRow["selectedUser4"] =selectedRow["selectedUser5"] = "";
            selectedRow["display2"] = selectedRow["display3"] =selectedRow["display4"] =selectedRow["display5"] = true;
            selectedRow["isDisabled2"] = selectedRow["isDisabled3"] =selectedRow["isDisabled4"] =selectedRow["isDisabled5"] = false;
            let leng = ownerListsAarr.length
            leng += 1
            selectedRow["display"+ leng] = false;
            ownerListsAarr.map((item, key) => {
                let counter = key
                counter += 1
                selectedRow["selectedUser" + counter] = [item];
                selectedRow["display" + counter] = false;
                selectedRow["isDisabled" + counter] = true;
            })       
        }else{
            selectedRow[selection] = true;
            selectedRow[display] = false;
            selectedRow[selectedUser] = [e];
        }
        this.setState({ selectedRow,ownerLists,ownerListsAarr, count, ceaBusinessOwnerList:ceaOwnerList});
    };

    toggleDelete = (ownerIds,selectedUser) => {    
        let ownerLists = this.state.ownerLists
        let ownerListsAarrNew = []
        let ceaOwnerList = [...this.state.ceaBusinessOwnerList];
        const selectedRow = { ...this.state.selectedRow };
        var count = this.state.count;
        const index = ownerLists.indexOf(ownerIds[0].value);
        if (index > -1) {
            ownerLists.splice(index, 1);
        }
        ownerListsAarrNew = this.state.ownerListsAarr.filter((item) => item.value !== ownerIds[0].value);

        const display = selectedUser === "selectedUser1" ? "display1"  :
        selectedUser === "selectedUser2" ? "display2"  :
        selectedUser === "selectedUser3" ? "display3"  :
        selectedUser === "selectedUser4" ? "display4"  : "display5"

        const isDisabled = selectedUser === "selectedUser1" ? "isDisabled1"  :
        selectedUser === "selectedUser2" ? "isDisabled2"  :
        selectedUser === "selectedUser3" ? "isDisabled3"  :
        selectedUser === "selectedUser4" ? "isDisabled4"  : "isDisabled5"
        
        // if(count === 5){
        //     ownerListsAarrNew.map((item, key) => {
        //         let counter = key
        //         counter += 1
        //         selectedRow["selectedUser" + counter] = [item];
        //     })    
        //     selectedRow["selectedUser5"] = "";
        //     selectedRow["isDisabled5"] = false;
        // }else{
            selectedRow[selectedUser] = "";
            selectedRow[display] = true;
            selectedRow[isDisabled] = false;  
            if(count===0){
                selectedRow["display2"] = selectedRow["display3"] =selectedRow["display4"] =selectedRow["display5"] = true;
                selectedRow["display1"] = false;
            }
        // }        
        count = count - 1;
        ceaOwnerList.push(ownerIds[0]);         
        this.setState({ selectedRow, count,ownerLists,ownerListsAarr:ownerListsAarrNew,ceaBusinessOwnerList:ceaOwnerList});
    }
    onToolboxChange = toolbox => {
        const selectedRow = { ...this.state.selectedRow };
        let ownerList = this.props.ceaBusinessOwnerList;
        var count = 0;
        let ownerAarr = [];
        let packageOwner = [];
        if(toolbox!==null){
            selectedRow["selectedUser1"] = selectedRow["selectedUser2"] = selectedRow["selectedUser3"] =selectedRow["selectedUser4"] =selectedRow["selectedUser5"] = "";
            selectedRow["display1"] = selectedRow["display2"] = selectedRow["display3"] =selectedRow["display4"] =selectedRow["display5"] = true;
            selectedRow["isDisabled1"] = selectedRow["isDisabled2"] = selectedRow["isDisabled3"] =selectedRow["isDisabled4"] =selectedRow["isDisabled5"] = false;
            if(toolbox.packageOwner!==undefined){                           
                packageOwner = toolbox.packageOwner.split(',');
                if(packageOwner.length>0){
                    // selectedRow["selectedUser1"] = selectedRow["selectedUser2"] = selectedRow["selectedUser3"] =selectedRow["selectedUser4"] =selectedRow["selectedUser5"] = "";
                    // selectedRow["display1"] = selectedRow["display2"] = selectedRow["display3"] =selectedRow["display4"] =selectedRow["display5"] = true;
                    // selectedRow["isDisabled1"] = selectedRow["isDisabled2"] = selectedRow["isDisabled3"] =selectedRow["isDisabled4"] =selectedRow["isDisabled5"] = false;                
                    packageOwner.map((item, key) => {
                        let boLength = this.props.ceaBusinessOwnerList.filter((i) => i.value === item);
                        if(boLength.length>0){
                            count = count + 1;                                          
                            selectedRow["selectedUser" + count] = this.props.ceaBusinessOwnerList.filter((i) => i.value === item);
                            selectedRow["display" + count] = false;
                            selectedRow["isDisabled" + count] = true;
                            ownerList = ownerList.filter((k) => k.value !== item);  
                            ownerAarr.push(item);                        
                        }                    
                    })
                    // if(count){
                    //     let leng = packageOwner.length
                    //     leng += 1
                    //     selectedRow["display"+ leng] = false;
                    // }else{
                    //     selectedRow["display1"] = false;
                    // }
                }                
            }
            if(count){
                let leng = packageOwner.length
                leng += 1
                selectedRow["display"+ leng] = false;
            }else{
                selectedRow["display1"] = false;
            }
            this.setState({ toolboxId: toolbox.value,selectedToolboxName:toolbox.label,businessFunction: toolbox.label, selectedRow, ceaBusinessOwnerList:ownerList, newBusiFun:false, ownerLists:ownerAarr,count});
        }
    };
    preventDefault() { }
    render() {
        let isAddModal = this.props.modalFor && this.props.modalFor === "Add" ? true : false;
        return (
            <Modal centered
                className={"addEditModal-" + this.props.theme.name}
                isOpen={true} style={{ maxWidth: '20%!important' }}
                toggle={() => this.preventDefault()} >
                <ModalHeader toggle={()=>this.props.toggle()}>
                    {isAddModal ? I18n.t('tmeAdminMaster.add') :
                        I18n.t('tmeAdminMaster.edit')} {I18n.t('kpiSetUp.businessFunction')}
                </ModalHeader>
                <ModalBody>
                    <Container>
                        <div>
                            <Row>
                                <label className={"toolboxLabel"}>
                                    {I18n.t('kpiSetUp.businessFunction')} / {I18n.t('kpiSetUp.divisionName')}
                                    <span className="redFont"> *</span>
                                </label>
                                {isAddModal ? 
                                <input type="text"
                                    className="form-control"
                                    value={this.state.businessFunction} 
                                    onChange={this.changeBusinessName} 
                                />
                                :
                                <div className="col-md-12" style={{padding: '0px'}}>
                                    <Select
                                        placeholder= {I18n.t('others.select')}
                                        value={this.state.toolbox}
                                        onChange={this.onToolboxChange}
                                        options={this.props.toolList}
                                        // isClearable
                                    />
                                </div>
                                }
                            </Row>
                        </div>
                        <br />
                        {!isAddModal ? 
                        <div>
                            <Row>
                                <label className={"toolboxLabel"}>
                                {I18n.t('Table.new')} {I18n.t('kpiSetUp.businessFunction')} / {I18n.t('kpiSetUp.divisionName')}
                                    <span className="redFont"> *</span>
                                </label>
                                <input type="text"
                                    className="form-control"
                                    value={this.state.businessFunction} 
                                    onChange={this.changeBusinessName} 
                                    disabled={this.state.newBusiFun ? true : false}
                                />
                            </Row>
                        </div> : ""}
                        {!isAddModal ? <br /> : ""}
                        {/* For Used Cars We dont need BuisnessOwner Dropdown */}
                        {this.props.user.userMdl.businessAreaKey === AppConst.businessAreaKeyPer?"":<Row>
                            <label className={"toolboxLabel"}>
                                {I18n.t('kpiSetUp.businessOwner')}
                                <span className="redFont"> *</span>
                            </label>
                            <div className="col-md-12" style={{padding: '0px'}}>
                                <Select
                                    placeholder= {I18n.t('others.select')}
                                    value={this.state.selectedRow.selectedUser5}
                                    onChange={(e)=>{this.onBusinessOwnerChange(e, "isDisabled5")}}
                                    options={this.state.ceaBusinessOwnerList}
                                    isClearable={false}
                                    className= {this.state.selectedRow.display5 ? "selectFieldDisplay" : "selectField"} 
                                    isDisabled={this.state.selectedRow.isDisabled5 ? true : false}
                                />
                                <a 
                                    onClick={(e) => this.toggleDelete(this.state.selectedRow.selectedUser5,"selectedUser5")}
                                    >
                                    <div
                                    title={I18n.t("tmeAdminMaster.delete")}
                                    className="delete-icon marginTop"
                                    style={{ display: !this.state.selectedRow.isDisabled5 ? "none" : "block"}}
                                    />
                                </a>
                                {/* <br /> */}
                                <Select
                                    placeholder= {I18n.t('others.select')}
                                    value={this.state.selectedRow.selectedUser4}
                                    onChange={(e)=>{this.onBusinessOwnerChange(e, "isDisabled4")}}
                                    options={this.state.ceaBusinessOwnerList}
                                    isClearable={false}
                                    className= {this.state.selectedRow.display4 ? "selectFieldDisplay" : "selectField"}  
                                    isDisabled={this.state.selectedRow.isDisabled4 ? true : false}
                                />
                                <a 
                                    onClick={(e) => this.toggleDelete(this.state.selectedRow.selectedUser4,"selectedUser4")}
                                    >
                                    <div
                                    title={I18n.t("tmeAdminMaster.delete")}
                                    className="delete-icon marginTop"
                                    style={{ display: !this.state.selectedRow.isDisabled4 ? "none" : "block"}}
                                    />
                                </a>
                                {/* <br /> */}
                                <Select
                                    placeholder= {I18n.t('others.select')}
                                    value={this.state.selectedRow.selectedUser3}
                                    onChange={(e)=>{this.onBusinessOwnerChange(e, "isDisabled3")}}
                                    options={this.state.ceaBusinessOwnerList}
                                    isClearable={false}
                                    className= {this.state.selectedRow.display3 ? "selectFieldDisplay" : "selectField"}
                                    isDisabled={this.state.selectedRow.isDisabled3 ? true : false}
                                />
                                <a 
                                    onClick={(e) => this.toggleDelete(this.state.selectedRow.selectedUser3,"selectedUser3")}
                                    >
                                    <div
                                    title={I18n.t("tmeAdminMaster.delete")}
                                    className="delete-icon marginTop"
                                    style={{ display: !this.state.selectedRow.isDisabled3 ? "none" : "block"}}
                                    />
                                </a>
                                {/* <br /> */}
                                <Select
                                    placeholder= {I18n.t('others.select')}
                                    value={this.state.selectedRow.selectedUser2}
                                    onChange={(e)=>{this.onBusinessOwnerChange(e, "isDisabled2")}}
                                    options={this.state.ceaBusinessOwnerList}
                                    isClearable={false}
                                    className= {this.state.selectedRow.display2 ? "selectFieldDisplay" : "selectField"}
                                    isDisabled={this.state.selectedRow.isDisabled2 ? true : false}
                                />
                                <a 
                                    onClick={(e) => this.toggleDelete(this.state.selectedRow.selectedUser2,"selectedUser2")}
                                    >
                                    <div
                                    title={I18n.t("tmeAdminMaster.delete")}
                                    className="delete-icon marginTop"
                                    style={{ display: !this.state.selectedRow.isDisabled2 ? "none" : "block"}}
                                    />
                                </a>
                                {/* <br /> */}
                                <Select
                                    placeholder= {I18n.t('others.select')}
                                    value={this.state.selectedRow.selectedUser1}
                                    onChange={(e)=>{this.onBusinessOwnerChange(e, "isDisabled1")}}
                                    options={this.state.ceaBusinessOwnerList}
                                    isClearable={false}
                                    className={this.state.selectedRow.display1 ? "selectFieldDisplay" : "selectField"}
                                    isDisabled={this.state.selectedRow.isDisabled1 ? true : false}
                                />
                                <a 
                                    onClick={(e) => this.toggleDelete(this.state.selectedRow.selectedUser1,"selectedUser1")}
                                    >
                                    <div
                                    title={I18n.t("tmeAdminMaster.delete")}
                                    className="delete-icon marginTop"
                                    style={{ display: (!this.state.selectedRow.isDisabled1 || this.state.newBusiFun) ? "none" : "block"}}
                                    />
                                </a>
                            </div>
                        </Row> }                      
                    </Container>
                </ModalBody>
                <ModalFooter>
                    <button
                        className={"buttonCancel-" + this.props.theme.name}
                        onClick={() => this.props.toggle()}
                    >
                        {I18n.t("tmeAdminMaster.cancel")}
                    </button>
                    <button
                        className={"button-" + this.props.theme.name}
                        onClick={this.saveBusinessFunction}
                    >
                        {I18n.t("tmeAdminMaster.save")}
                    </button>
                </ModalFooter>
            </Modal>
        );
    }
}
const mapStateToProps = state => {
    return {
        user: state.loginReducer
    };
};
export default withRouter(connect(mapStateToProps)(withTheme(AddEditBusiFunc)));
