import { UserManager } from 'oidc-client';

let userManager = null;
let user = null;

export const createUserManagerConfig =() =>{
  const { REACT_APP_client_id,
          REACT_APP_scope,
          REACT_APP_response_type,
          REACT_APP_redirect_uri,
          REACT_APP_authority, 
          REACT_APP_post_logout_redirect_uri,
          REACT_APP_SILENT_RENEW_URL
     } = process.env;

  const userConfigJson = {
      authority: REACT_APP_authority,
      client_id: REACT_APP_client_id,
      redirect_uri: REACT_APP_redirect_uri,
      post_logout_redirect_uri: REACT_APP_post_logout_redirect_uri,
      response_type: REACT_APP_response_type,
      scope: REACT_APP_scope,
      silent_redirect_uri: REACT_APP_SILENT_RENEW_URL,
      automaticSilentRenew: true
  }  
  return userConfigJson;
};

export const getUserManager =()=> {
    console.log("getUserManager");
  if (!userManager) {
   userManager = new UserManager(createUserManagerConfig());
  }
 return userManager;
}

export const login = () => {
    console.log("In Login");
  return getUserManager().signinRedirect();
}

export const isLoggedIn = () =>{
  return user !== null && !user.expired;
}

export const completeAuthentication = () =>{
    console.log("In completeAuthentication");
  return getUserManager().signinRedirectCallback().then(userdata => {
    console.log("In signinRedirectCallback");
      return userdata;
  }).catch(error => {
  });
}

export const silentCallBack = () =>{
  getUserManager().signinSilentCallback().then(userdata => {      
      getUser().then(u=>{
      })
  }).catch(error => {
  });
}

export const getUser =()=> {
  return getUserManager().getUser();
}

export const renewToken = () => {
  return getUserManager().signinSilent();
}

export const logout = () => {
  console.log("logout");
  if (window.location.href.indexOf("localhost") > 0) {
    getUserManager().signoutRedirect({ 'id_token': getToken() });
  } else {
    window.location.href = process.env.REACT_APP_post_logout_redirect_uri;    
  }
  return sessionStorage.clear;
}

export const setToken = (id_token) => {    
  if(id_token){
    return sessionStorage.setItem("id_token", id_token);
  }else{
    getUser().then(user=>{
      return sessionStorage.setItem("id_token", user.id_token);
    });    
  }  
}

export const getToken = () => {
  return sessionStorage.getItem("id_token");
}

//id_token
export const setUserRoles = (roles) => {
  return sessionStorage.setItem("userRoles", roles);
}

export const getUserDetails = () => {
  return sessionStorage.getItem("userRoles");
}







  

  


