import axios from 'axios';
import fileDownload from 'js-file-download';
import { getCallWithReducer, postCallWithReducer } from "./commonAction";
import { getToken } from '../oidc/AuthService';
export const ADD_MODAL = 'ADD_MODAL'

// export function fetchKaizenPoints(activityId) {
//   return function (dispatch) {
//     axios
//       .get("/evaluation/kaizenPoints/" + activityId, {
//         headers: {
//           "Content-type": "application/json",
//           Accept: "application/json",
//           Pragma: "no-cache"
//         }
//       })
//       .then(response => {
//         dispatch({
//           type: "KAIZEN_POINTS",
//           payload: response.data
//         });
//       })
//       .catch(function (error) {
//         //dispatch(setBlockingState(false));
//         console.error(error);
//       })
//   }
// }
export function fetchKaizenPoints(activityId) {
  return function (dispatch) {
  dispatch(getCallWithReducer("/evaluation/kaizenPoints/" + activityId,null,"KAIZEN_POINTS"))
  };
}
export function downloadKaizenPoints(activityId, lang, brand, dto, nmsc) {
  return function (dispatch) {
    //dispatch(setBlockingState(true));
    var url = "/evaluation/downloadKaizenPoints/" + activityId + "/" + lang + "/" + brand + "/" + nmsc;
    axios
      .post(url, dto, {
        contentType: "application/pdf",
        headers: {
          'Authorization': 'Bearer ' + getToken(),
          Pragma: "no-cache"
        }
        ,
        responseType: "arraybuffer"
      })
      .then(function (response) {
        //dispatch(setBlockingState(false));
        fileDownload(response.data, decodeURI(response.headers["filename"].replace(/\+/g, " ")));
      })
      .catch(function (error) {
        //dispatch(setBlockingState(false));
        console.error(error);
      });
  };
}
