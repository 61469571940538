import React from "react";
import { connect } from "react-redux";
import { Container } from "reactstrap";
import { MDBRow, MDBCol, MDBContainer } from "mdbreact";
import { withRouter } from "react-router-dom";
import { withTheme } from '../../themes/theming';
import FileDownload from "js-file-download";
import axios from "axios";
import { setBlockingState } from "../../actions/loginAction.jsx";
import { toastr } from "react-redux-toastr";
import "./index.css";
var I18n = require("react-redux-i18n").I18n;

class Download extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            preSignedURL: ""
        }
    }

    // componentWillMount() {
    //     // this.downloadiPA();
    // }

    /**
     * 
     */
    downloadApk = (fileName) => {
        axios.get("/artifact/getPreSignedUrl/" + fileName, {
            headers: {
                'Accept': 'application/json',
                'Pragma': 'no-cache'
            }
        })
            .then((response) => {
                this.props.dispatch(setBlockingState(true))
                this.downloadArtifact(response);
            })
            .catch(err => {
                toastr.error(I18n.t('ServerMessage.error_message'));
                console.error(err);
            });
    }

    downloadiPA = () => {
        axios.get("/library/getiOSPreSignedUrl", {
            headers: {

                'Accept': 'application/json',
                'Pragma': 'no-cache'
            }
        })
            .then((response) => {
                this.props.dispatch(setBlockingState(true));
                console.log(response)
                this.setState({ preSignedURL: response.data.desc })
                //toastr.success(response.data.desc);
                this.downloadArtifact(response);
            })
            .catch(err => {
                toastr.error(I18n.t('ServerMessage.error_message'));
                console.error(err);
                //dispatch(setBlockingState(false));
            });
    };



    /**
     * 
     */
    downloadArtifact = (obj) => {
        axios({
            method: 'get',
            contentType: "application/octet-stream",
            url: obj.data.desc,
            responseType: 'arraybuffer',
            headers: { 'Pragma': 'no-cache' }
        }).then(function (response) {
            FileDownload(response.data, obj.data.body);
        })
    }
    render() {
        return (

            <Container fluid className="ml-0 mb-1" style={{ marginTop: "3%", width: "100%" }}>
                <div className="DownloadView">
                    <MDBContainer>
                        <MDBRow className="mt-4">
                            <MDBCol md="6" xl="5" className="mt-4">
                                <div className="white-text text-center text-md-left">
                                    <div className="row Downloadbtn">
                                        {/* <MDBBtn color="white" onClick={() => this.downloadApk('KET.apk')}>Download<MDBIcon fa icon="android" /></MDBBtn> */}
                                        {/*<MDBBtn outline color="white" onClick={() => this.downloadApk('KET.ipa')}>Download<MDBIcon fa icon="apple" /></MDBBtn>*/}
                                        {/* marginTop: "3%", width: "100%", fontSize: "30px", textAlign: "left" */}
                                        <div className="mobileSize">
                                            <div>
                                                {I18n.t("TopNav.Download Mobile App")}
                                            </div>
                                            <a href="itms-services://?action=download-manifest&amp;url=https://ketd.toyota-europe.com/images/KET.plist">Download KET Mobile App for iOS</a>
                                            <div></div>
                                            <ul>
                                                <li>Click on the above Download link to download KET app</li>
                                                <li>A pop-up will be shown asking the user whether it can allow the App Source to download and install the app. Tap on “Download/ Install”</li>
                                                <li>Minimise the browser and user will be able to see the progress of the download in the home screen of iPhone.</li>
                                                <li>Once app is installed. User needs to go to Settings -&gt; General -&gt; Device Management -&gt; Toyota Motor NV -&gt; Tap on  “Verify/ Trust KET app”</li>
                                                <li>User can go back to home screen and launch the app successfully.</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </MDBCol>
                            <MDBCol md="6" xl="5" className="mt-4">
                                <img
                                    src="/images/KaizenMobileApp.png"
                                    alt=""
                                    className="img-fluid kaizenImage"
                                />
                            </MDBCol>
                        </MDBRow>
                    </MDBContainer>
                </div>

            </Container>
        );
    }
}
const mapStateToProps = state => {
    return {
        user: state.loginReducer,
        blocking: state.loginReducer,
        router: state.routing
    };
};
export default withRouter(connect(mapStateToProps)(withTheme(Download)));
