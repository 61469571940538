import axios from 'axios';
import { setBlockingState } from './loginAction.jsx';
import { toastr } from "react-redux-toastr";
import * as AppConst from '../AppConstant';
import { getCallWithReducer, postCallWithReducer } from "./commonAction";
import { getToken } from '../oidc/AuthService';
var I18n = require("react-redux-i18n").I18n;

/**
 * Publish selected kpis.
 */
// export function fetchLeftNav() {
//     return function (dispatch) {
//         //dispatch(setBlockingState(true));
//         axios.get('/toolbox/all', {
//             headers: {
//                 'Content-type': 'application/json',
//                 'Accept': 'application/json',
//                 'Pragma': 'no-cache'
//             }
//         }).then(response => {
//             //dispatch(setBlockingState(false));
//             dispatch({
//                 type: 'LEFT_NAV',
//                 payload: response
//             });
//         }).catch(function (error) {
//             //dispatch(setBlockingState(false));
//             console.error(error);
//         });
//     }
// }

export function fetchLeftNav() {
    return function (dispatch) {
    dispatch(getCallWithReducer("/toolbox/all",null,"LEFT_NAV"))
    };
  }
export function fetchToolboxByType(program, flagCEA, flagNetOrUc, ceaYear, getNetworkUserPrimaryKey) {
    return function (dispatch) {
        axios.get('/toolbox/' + program + "/" + flagCEA + '/' + flagNetOrUc + '/'+ ceaYear, {
            headers: {
                'Authorization': 'Bearer ' + getToken(),
                'Content-type': 'application/json',
                'Accept': 'application/json',
                'Pragma': 'no-cache'
            }
        }).then(response => {
            // getNetworkUserPrimaryKey = 8871;
            if (flagCEA || flagNetOrUc) {
                let intialList = [];
                let lastList = [];
                response.data.forEach(_ => {
                    if (_.packageOwner) {
                        let packageOwner = _.packageOwner.split(",");
                        if (packageOwner.includes(getNetworkUserPrimaryKey.toString())) {
                            _.flagOwner = true
                            intialList.push(_);
                        } else {
                            _.flagOwner = false
                            lastList.push(_);
                        }
                    } else {
                        _.flagOwner = false
                        lastList.push(_);
                    }
                })
                let final = intialList.concat(lastList);
                dispatch({
                    type: 'TOOLBOX_BY_TYPE',
                    payload: final
                });
            } else {
                dispatch({
                    type: 'TOOLBOX_BY_TYPE',
                    payload: response.data
                });
            }
        }).catch(function (error) {
            console.error(error);
        });
    }
}


// export function fetchTmeToolboxForNmsc(program, nmscCode) {
//     return function (dispatch) {
//         //dispatch(setBlockingState(true));
//         axios
//             .post("/toolbox/tmeToolBoxForNmsc/" + program + "/" + nmscCode, {
//                 headers: {
//                     "Content-type": "application/json",
//                     Accept: "application/json",
//                     Pragma: "no-cache"
//                 }
//             })
//             .then(response => {
//                 //dispatch(setBlockingState(false));
//                 dispatch({
//                     type: "ACTIVITY_TOOLBOX",
//                     payload: response.data
//                 });
//             })
//             .catch(function (error) {
//                 //dispatch(setBlockingState(false));
//                 console.error(error);
//             });
//     };
// }

export function fetchTmeToolboxForNmsc(program, nmscCode) {
    return function (dispatch) {
    dispatch(postCallWithReducer("/toolbox/tmeToolBoxForNmsc/" + program + "/" + nmscCode,null,"ACTIVITY_TOOLBOX"))
    };
}
// export function fetchNmscToolboxByType(program, nmscCode, langCode) {
//     return function (dispatch) {
//         axios.get('/toolbox/nmsc/' + program + '/' + nmscCode + '/' + langCode, {
//             headers: {
//                 'Content-type': 'application/json',
//                 'Accept': 'application/json',
//                 'Pragma': 'no-cache'
//             }
//         }).then(response => {
//             dispatch({
//                 type: 'TOOLBOX_BY_TYPE',
//                 payload: response.data
//             });
//             dispatch(fetchNmscToolbox(program, nmscCode));
//         }).catch(function (error) {
//             console.error(error);
//         });
//     }
// }
export function fetchNmscToolboxByType(program, nmscCode, langCode) {
    return function (dispatch) {
    dispatch(getCallWithReducer('/toolbox/nmsc/' + program + '/' + nmscCode + '/' + langCode,null,"TOOLBOX_BY_TYPE"))
    };
  }
export function saveToolboxToDB(toolboxDTO, this_) {
    return function (dispatch) {
        //dispatch(setBlockingState(true));
        axios
            .post("/toolbox/save", toolboxDTO, {
                headers: {
                    'Authorization': 'Bearer ' + getToken(),
                    "Content-type": "application/json",
                    Accept: "application/json",
                    Pragma: "no-cache"
                }
            })
            .then(response => {
                //dispatch(setBlockingState(false));
                var saveToolboxDTO;
                if (response.data.statusCode === "200") {
                    dispatch({
                        type: "TOOLBOX_SAVED",
                        payload: response.data
                    });
                    let values = [];
                    if (response.data.body !== undefined) {
                        values = response.data.body.split('|');
                    }
                    let toolboxId = values[0] !== undefined ? values[0] : 0;
                    let toolboxName = values[1] !== undefined ? values[1] : '';
                    let location = values[2] !== undefined ? values[2] : '';
                    let toolboxKey = values[3] !== undefined ? values[3] : 0;
                    let type = values[4] !== undefined ? values[4] : 0;
                    let packageMapPrimaryKey = values[5] !== undefined ? values[5] : 0;
                    saveToolboxDTO = {
                        toolboxId: toolboxId,
                        toolboxName: toolboxName,
                        location: location,
                        toolboxKey: toolboxKey,
                        type: type,
                        packageMapPrimaryKey: packageMapPrimaryKey
                    };
                    toastr.success(I18n.t("ServerMessage." + response.data.desc));
                } else {
                    toastr.error(I18n.t("toolbox." + response.data.desc));
                }
                let date = new Date();
                date = this_.props.user.userMdl.ceaDefaultYears ? this_.props.user.userMdl.ceaDefaultYears.value : date.getfullYear();
                let flagNetOrUc = this_.props.user.userMdl.businessAreaKey === AppConst.businessAreaKeyPer;
                dispatch(fetchToolboxByType(toolboxDTO.program, this_.props.user.userMdl.flagCEA,flagNetOrUc, date, this_.props.user.userMdl.getNetworkUserPrimaryKey));
                if(toolboxDTO.program!==23 && this_.props.user.userMdl.activeProgram!=="TSM_SURVEYS"){
                    this_.props.propFunction(saveToolboxDTO);
                }                
            })
            .catch(function (error) {
                //dispatch(setBlockingState(false));
                console.error(error);
            });
    };
}

export function saveNmscToolboxToDB(nmscDTO, propFunction) {
    return function (dispatch) {
        axios.post('/toolbox/saveToNmscToolbox/', nmscDTO, {
            headers: {
                'Authorization': 'Bearer ' + getToken(),
                "Content-type": "application/json",
                Accept: "application/json",
                Pragma: "no-cache"
            }
        })
            .then(response => {
                var saveNmscToolboxDTO;
                //dispatch(setBlockingState(false));
                if (response.data.statusCode === "200") {
                    dispatch({
                        type: "NMSC_TOOLBOX_SAVED",
                        payload: response.data
                    });
                    let values = [];
                    if (response.data.body !== undefined) {
                        values = response.data.body.split('|');
                    }
                    let toolboxId = values[0] !== undefined ? values[0] : 0;
                    // let nmscToolboxId = values[1] !== undefined ? values[1] : 0;
                    let toolboxName = values[2] !== undefined ? values[2] : '';
                    let location = values[3] !== undefined ? values[3] : '';
                    let toolboxKey = values[4] !== undefined ? values[4] : 0;
                    let type = values[5] !== undefined ? values[5] : 0;
                    let packageMapPrimaryKey = values[6] !== undefined ? values[6] : 0;

                    saveNmscToolboxDTO = {
                        toolboxId: toolboxId,
                        toolboxName: toolboxName,
                        location: location,
                        toolboxKey: toolboxKey,
                        type: type,
                        packageMapPrimaryKey: packageMapPrimaryKey

                    };
                    toastr.success(I18n.t("ServerMessage." + response.data.desc));
                } else {
                    if(nmscDTO.program === 23 || nmscDTO.program > 24){
                        toastr.error(I18n.t("ServerMessage." + response.data.desc));
                    }else{
                        toastr.error(I18n.t("toolbox." + response.data.desc));
                    }  
                }
                dispatch(fetchNmscToolboxByType(nmscDTO.program, nmscDTO.nmscCode, nmscDTO.langCode));
                dispatch(fetchNmscToolbox(nmscDTO.program, nmscDTO.nmscCode));
                dispatch(fetchNmscToolboxByLang(nmscDTO.program, nmscDTO.nmscCode))
                if (response.data.statusCode === "200") {
                    propFunction(saveNmscToolboxDTO);
                }

            })
            .catch(function (error) {
                console.error(error);
            });
    };
}

export function updateNmscToolboxToDB(nmscDTO, propFunction) {
    return function (dispatch) {
        axios.post('/toolbox/editNmscToolbox/', nmscDTO, {
            headers: {
                'Authorization': 'Bearer ' + getToken(),
                "Content-type": "application/json",
                Accept: "application/json",
                Pragma: "no-cache"
            }
        })
            .then(response => {
                //dispatch(setBlockingState(false));
                var saveNmscToolboxDTO;
                if (response.data.statusCode === "200") {
                    dispatch({
                        type: "NMSC_TOOLBOX_SAVED",
                        payload: response.data
                    });
                    let values = [];
                    if (response.data.body !== undefined) {
                        values = response.data.body.split('|');
                    }
                    let toolboxId = values[0] !== undefined ? values[0] : 0;
                    // let nmscToolboxId = values[1] !== undefined ? values[1] : 0;
                    let toolboxName = values[2] !== undefined ? values[2] : '';
                    let location = values[3] !== undefined ? values[3] : '';
                    let toolboxKey = values[4] !== undefined ? values[4] : 0;
                    let type = values[5] !== undefined ? values[5] : 0;
                    let packageMapPrimaryKey = values[6] !== undefined ? values[6] : 0;

                    saveNmscToolboxDTO = {
                        toolboxId: toolboxId,
                        toolboxName: toolboxName,
                        location: location,
                        toolboxKey: toolboxKey,
                        type: type,
                        packageMapPrimaryKey: packageMapPrimaryKey

                    };
                    toastr.success(I18n.t("ServerMessage." + response.data.desc));
                } else {
                    if(nmscDTO.program === 23 || nmscDTO.program > 24){
                        toastr.error(I18n.t("ServerMessage." + response.data.desc));
                    }else{
                        toastr.error(I18n.t("toolbox." + response.data.desc));
                    }                    
                }
                dispatch(fetchNmscToolboxByType(nmscDTO.program, nmscDTO.nmscCode, nmscDTO.langCode));
                dispatch(fetchNmscToolboxByLang(nmscDTO.program, nmscDTO.nmscCode))
                if (response.data.statusCode === "200") {
                    propFunction(saveNmscToolboxDTO);
                }
            })
            .catch(function (error) {
                console.error(error);
            });
    };
}

export function deleteToolbox(toolboxId, program, changeToolBoxFlag, flagCEA, flagNetOrUC, year, getNetworkUserPrimaryKey) {
    return function (dispatch) {
        axios.delete('/toolbox/delete/' + toolboxId + '/' + program, {
            headers: {
                'Authorization': 'Bearer ' + getToken(),
                "content-type": "application/json",
                Accept: "application/json",
                Pragma: "no-cache"
            }
        }).then(response => {
            if (response.data.statusCode === "200") {
                changeToolBoxFlag(0, false, { packageMapPrimaryKey: 0 });
                toastr.success(I18n.t("ServerMessage." + response.data.desc));
            }
            else {
                toastr.error(I18n.t("ServerMessage." + response.data.desc));
            }
            dispatch({
                type: "DELETE_TOOLBOX",
                payload: response.data
            });
            dispatch(fetchToolboxByType(program, flagCEA, flagNetOrUC, year, getNetworkUserPrimaryKey));
        }).catch(function (error) {
            console.log(error);
        });
    }
}

// export function fetchNmscToolbox(program, nmscCode) {
//     return function (dispatch) {
//         axios.get('/toolbox/getNmsc/' + program + '/' + nmscCode, {
//             headers: {
//                 'Content-type': 'application/json',
//                 'Accept': 'application/json',
//                 'Pragma': 'no-cache'
//             }
//         }).then(response => {
//             dispatch({
//                 type: 'TOOLBOX_BY_TYPE_LIST',
//                 payload: response.data
//             });
//         }).catch(function (error) {
//             console.error(error);
//         });
//     }
// }
export function fetchNmscToolbox(program, nmscCode) {
    return function (dispatch) {
    dispatch(getCallWithReducer('/toolbox/getNmsc/' + program + '/' + nmscCode,null,"TOOLBOX_BY_TYPE_LIST"))
    };
  }

// export function fetchNmscToolboxByLang(program, nmscCode) {
//     return function (dispatch) {
//         axios.get('/toolbox/getAllToolboxes/' + program + '/' + nmscCode, {
//             headers: {
//                 'Content-type': 'application/json',
//                 'Accept': 'application/json',
//                 'Pragma': 'no-cache'
//             }
//         }).then(response => {
//             dispatch({
//                 type: 'TOOLBOX_BY_TYPE_LANG',
//                 payload: response.data
//             });
//         }).catch(function (error) {
//             console.error(error);
//         });
//     }
// }
export function fetchNmscToolboxByLang(program, nmscCode) {
    return function (dispatch) {
    dispatch(getCallWithReducer('/toolbox/getAllToolboxes/' + program + '/' + nmscCode,null,"TOOLBOX_BY_TYPE_LANG"))
    };
  }

export function deleteNmscToolbox(toolboxId, langCode, nmscCode, program, changeToolBoxFlag) {
    console.log("deleteNmscToolbox", toolboxId, langCode, nmscCode, program, changeToolBoxFlag)
    return function (dispatch) {
        axios.delete('/toolbox/nmscDelete/' + toolboxId + '/' + langCode + '/' + nmscCode, {
            headers: {
                'Authorization': 'Bearer ' + getToken(),
                "content-type": "application/json",
                Accept: "application/json",
                Pragma: "no-cache"
            }
        }).then(response => {
            if (response.data.statusCode === "200") {
                changeToolBoxFlag(0, false, { packageMapPrimaryKey: 0 });
                toastr.success(I18n.t("ServerMessage." + response.data.desc));
            }
            else {
                toastr.error(I18n.t("ServerMessage." + response.data.desc));
            }
            dispatch({
                type: "DELETE_TOOLBOX",
                payload: response.data
            });
            dispatch(fetchNmscToolboxByType(program, nmscCode, langCode));
            dispatch(fetchNmscToolboxByLang(program, nmscCode))
        }).catch(function (error) {
            console.log(error);
        });
    }
}


// export function fetchUsersByRole(role) {
//     return function (dispatch) {
//         axios.get('/user/fetchUsersByRole/'+role, {
//             headers: {
//                 'Content-type': 'application/json',
//                 'Accept': 'application/json',
//                 'Pragma': 'no-cache'
//             }
//         }).then(response => {
//             dispatch({
//                 type: 'CEA_BUSINESS_OWNER',
//                 payload: response.data
//             });
//         }).catch(function (error) {
//             console.error(error);
//         });
//     }
// }

export function fetchUsersByRole(role) {
    return function (dispatch) {
    dispatch(getCallWithReducer('/user/fetchUsersByRole/'+role,null,"CEA_BUSINESS_OWNER"))
    };
  }
export function saveBusinessFunToDB(toolboxDTO, this_) {
    return function (dispatch) {
        //dispatch(setBlockingState(true));
        axios
            .post("/toolbox/saveCeaToolbox", toolboxDTO, {
                headers: {
                    'Authorization': 'Bearer ' + getToken(),
                    "Content-type": "application/json",
                    Accept: "application/json",
                    Pragma: "no-cache"
                }
            })
            .then(response => {
                //dispatch(setBlockingState(false));
                var saveToolboxDTO;
                if (response.data.statusCode === "200") {
                    dispatch({
                        type: "TOOLBOX_SAVED",
                        payload: response.data
                    });
                    let values = [];
                    console.log("response.data", response.data)
                    if (response.data.body !== undefined) {
                        values = response.data.body.split('|');
                    }
                    let toolboxId = values[0] !== undefined ? values[0] : 0;
                    let toolboxName = values[1] !== undefined ? values[1] : '';
                    let location = values[2] !== undefined ? values[2] : '';
                    let toolboxKey = values[3] !== undefined ? values[3] : 0;
                    let type = values[4] !== undefined ? values[4] : 0;
                    let packageMapPrimaryKey = values[5] !== undefined ? values[5] : 0;
                    saveToolboxDTO = {
                        toolboxId: toolboxId,
                        toolboxName: toolboxName,
                        location: location,
                        toolboxKey: toolboxKey,
                        type: type,
                        packageMapPrimaryKey: packageMapPrimaryKey
                    };
                    toastr.success(I18n.t("kpiSetUp." + response.data.desc));
                } else {
                    toastr.error(I18n.t("kpiSetUp." + response.data.desc));
                }
                let date = new Date();
                date = this_.props.user.userMdl.ceaDefaultYears ? this_.props.user.userMdl.ceaDefaultYears.value : date.getfullYear();
                dispatch(fetchToolboxByType(toolboxDTO.program, this_.props.user.userMdl.flagCEA, (this_.props.user.userMdl.flagNET || this_.props.user.userMdl.flagUC || this_.props.user.userMdl.flagLN || this_.props.user.userMdl.flagAudit), date, this_.props.user.userMdl.getNetworkUserPrimaryKey));
                this_.props.propFunction(saveToolboxDTO);
            })
            .catch(function (error) {
                //dispatch(setBlockingState(false));
                console.error(error);
            });
    };
}

// export function fetchPillarPointsCea(program, flagCEA, ceaYear, toYear) {
//     return function (dispatch) {
//         axios.post('/toolbox/getPillarPoints/' + program + "/" + flagCEA + '/' + ceaYear+ '/' + toYear, {
//             headers: {
//                 'Content-type': 'application/json',
//                 'Accept': 'application/json',
//                 'Pragma': 'no-cache'
//             }
//         }).then(response => {
//             console.log("response.data",response.data)
//             dispatch({
//                 type: 'PILLAR_TOOLBOX_DATA',
//                 payload: response.data
//             });
//         }).catch(function (error) {
//             console.error(error);
//         });
//     }
// }

export function fetchPillarPointsCea(program, flagCEA, ceaYear, toYear) {
    return function (dispatch) {
    dispatch(postCallWithReducer('/toolbox/getPillarPoints/' + program + "/" + flagCEA + '/' + ceaYear+ '/' + toYear,"","PILLAR_TOOLBOX_DATA"))
    };
}
export function fetchAllPillarPointsCea(program, flagCEA, ceaYear) {
    return function (dispatch) {
    dispatch(postCallWithReducer('/toolbox/getPillarPoints/' + program + "/" + flagCEA + '/' + ceaYear," ","PILLAR_TO_YEAR_TOTAL_POINT"))
    };
}
// export function fetchAllPillarPointsCea(program, flagCEA, ceaYear) {
//     return function (dispatch) {
//         axios.post('/toolbox/getAllPillarPoints/' + program + "/" + flagCEA + '/' + ceaYear, {
//             headers: {
//                 'Content-type': 'application/json',
//                 'Accept': 'application/json',
//                 'Pragma': 'no-cache'
//             }
//         }).then(response => {
//             console.log("response.data",response.data)
//             dispatch({
//                 type: 'PILLAR_TO_YEAR_TOTAL_POINT',
//                 payload: response.data
//             });
//         }).catch(function (error) {
//             console.error(error);
//         });
//     }
// }

export function saveAllocatedPoints(savePointAllocateDTO,extraDataObj){
    return function (dispatch) {        
       axios
         .put("/toolbox/saveAllocatedPointsCea" ,savePointAllocateDTO, {
           headers: {
            'Authorization': 'Bearer ' + getToken(),
             'Access-Control-Allow-Origin': '*',
             "Content-type": "application/json",
             Accept: "application/json",
             Pragma: "no-cache"
           }
         })
         .then(response => {
            dispatch(setBlockingState(false));
            dispatch(fetchPillarPointsCea(extraDataObj.activeProgramID,true,extraDataObj.fromYearOption,extraDataObj.toYearOption)); 
            toastr.success("Points saved for "+extraDataObj.activeProgramName+" are "+extraDataObj.toPtTotal+". Total Points saved for all pillar are "+(extraDataObj.toPtTotal + extraDataObj.pillarToYrTotalPt)+".");        
         })
         .catch(function (error) {
           dispatch(setBlockingState(false));
           toastr.error(I18n.t("kpiEntry.serverErrorMsg"));
         });
     };
    
  }

  export function updateAllocatedPoints(savePointAllocateDTO,extraDataObj){
    return function (dispatch) {        
       axios
         .put("/toolbox/updateAllocatedPointsCea" ,savePointAllocateDTO, {
           headers: {
            'Authorization': 'Bearer ' + getToken(),
             'Access-Control-Allow-Origin': '*',
             "Content-type": "application/json",
             Accept: "application/json",
             Pragma: "no-cache"
           }
         })
         .then(response => {
            dispatch(setBlockingState(false));
            dispatch(fetchPillarPointsCea(extraDataObj.activeProgramID,true,extraDataObj.fromYearOption,extraDataObj.toYearOption)); 
            toastr.success("Points saved for "+extraDataObj.activeProgramName+" are "+extraDataObj.toPtTotal+". Total Points saved for all pillar are "+(extraDataObj.toPtTotal + extraDataObj.pillarToYrTotalPt)+".");        
         })
         .catch(function (error) {
           dispatch(setBlockingState(false));
           toastr.error(I18n.t("kpiEntry.serverErrorMsg"));
         });
     };
    
  }
