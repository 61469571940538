import axios from "axios";
import { setBlockingState } from "./loginAction.jsx";
import { toastr } from "react-redux-toastr";
import { getToken } from '../oidc/AuthService';
var I18n = require("react-redux-i18n").I18n;


export function fetchTargetFilters(targetSearchDto, searchFor) {
    return function (dispatch) {
      //dispatch(setBlockingState(true));
      axios
        .post("/finalizeTarget/filters/" + searchFor, targetSearchDto, {
          headers: {
            'Authorization': 'Bearer ' + getToken(),
            "Content-type": "application/json",
            Accept: "application/json",
            Pragma: "no-cache"
          }
        })
        .then(response => {
          var type =
            searchFor === "nmscCode"
              ? "NMSC_CODE_DATA"
              : searchFor === "brand"
                ? "KPI_BRAND_DATA"
                : searchFor === "status"
                  ? "KPI_STATUS_DATA"
                  : searchFor === "toolbox"
                    ? "TOOLBOX_DATA"
                    : searchFor === "KPI"
                      ? "KPI_DATA"
                      : searchFor === "subpillar"
                        ? "SUBPILLAR_DATA"
                        : ""
  
          //dispatch(setBlockingState(false));
          dispatch({
            type: type,
            payload: response.data
          });
        })
        .catch(function (error) {
          //dispatch(setBlockingState(false));
          console.error(error);
        });
    };
  }

  export function saveCEASearchFilters(searchDto){

    return function (dispatch) {
     dispatch(setBlockingState(true));
      axios
        .post("/finalizeTarget/search" ,searchDto, {
          headers: {
            'Access-Control-Allow-Origin': '*',
            "Content-type": "application/json",
            Accept: "application/json",
            Pragma: "no-cache",
            'Authorization': 'Bearer ' + getToken()
          }
        })
        .then(response => {
          dispatch(setBlockingState(false));
          console.log(response.data)
  
          dispatch({
            type: "FETCH_TARGET_REFRESH",
            payload: response.data
          })
          console.log(response.data)

        })
        .catch(function (error) {
          dispatch(setBlockingState(false));
          toastr.error(I18n.t("UserManagement.error_message"));
        });
    };
    
  }
  
  export function searchEmptyCEASearchFilters() {
    return function (dispatch) {
      dispatch({
        type: "FETCH_TARGET_REFRESH",
        payload: null
      });    
    };
  }
  
  export function saveCeaTargetStatus(kpiEvaluationId,status,businessFunction,nmscCode,kpi){
    return function (dispatch) {
     dispatch(setBlockingState(true));
     console.log("SaveCea kpiid:",kpiEvaluationId);
     console.log("SaveCea status:",status);
     console.log("SaveCea businessFunction:",businessFunction);
     console.log("SaveCea nmscCode:",nmscCode);
     console.log("SaveCea kpi:",kpi);
      axios
      .post("/finalizeTarget/saveStatus/"+kpiEvaluationId+"/"+status+"/"+businessFunction+"/"+nmscCode+"/"+kpi,{
          headers: {
            'Authorization': 'Bearer ' + getToken(),
            "Content-type": "application/json",
            Accept: "application/json",
            Pragma: "no-cache",
            
          }
        })
        .then(response => {
          dispatch(setBlockingState(false));
          toastr.success(I18n.t("ServerMessage." + response.data.desc));
          console.log(response.data)

        })
        .catch(function (error) {
          dispatch(setBlockingState(false));
          toastr.error(I18n.t("UserManagement.error_message"));
        });
    };    
  }
  
  
  export function submitKpiEvaluationComment(KpiEvaluationCommentDTO) {
    return function (dispatch) {
      dispatch(setBlockingState(true));
      let url = "/finalizeTarget/submitKpiEvaluationComment";
      axios
        .post(url, KpiEvaluationCommentDTO, {
          headers: {
            'Authorization': 'Bearer ' + getToken(),
            "Content-type": "application/json",
            Accept: "application/json",
            Pragma: "no-cache"
          }
        })
        .then(response => {
          dispatch(setBlockingState(false));
          toastr.success(I18n.t("ServerMessage." + response.data.desc));
        })
        .catch(function (error) {
          dispatch(setBlockingState(false));
          toastr.error("" + error);
        });
    };
  }

  export function getKpiEvaluationCommentHistory(kpiEvaluationId) {
    return function (dispatch) {
      //dispatch(setBlockingState(true));
      axios
        .get("/finalizeTarget/getKpiEvaluationCommentHistory/" + kpiEvaluationId, {
          headers: {
            'Authorization': 'Bearer ' + getToken(),
            Accept: "application/json",
            Pragma: "no-cache"
          }
        })
        .then(response => {
          console.log(response)
          dispatch(setBlockingState(false));
          dispatch({
            type: "KPI_EVALUATION_COMMENT",
            payload: response.data
          });
        })
        .catch(function (error) {
          dispatch(setBlockingState(false));
          console.error(error);
        });
    };
  }

  export function updateTargetValue(targetSaveDTOS) {
    return function (dispatch) {
      dispatch(setBlockingState(true));
      let url = "/finalizeTarget/target/save";
      axios
        .post(url, targetSaveDTOS, {
          headers: {
            'Authorization': 'Bearer ' + getToken(),
            "Content-type": "application/json",
            Accept: "application/json",
            Pragma: "no-cache"
          }
        })
        .then(response => {
          dispatch(setBlockingState(false));
          toastr.success(I18n.t("ServerMessage." + response.data.desc));
        })
        .catch(function (error) {
          dispatch(setBlockingState(false));
          toastr.error("" + error);
        });
    };
  }  