const translationsEL = {
  el: {
    Policy: {
      HeaderMsg:
        "Δήλωση απορρήτου για την επεξεργασία των προσωπικών σας δεδομένων στα πλαίσια του Kaizen Expert Tool",
      Message1:
        "Αυτή η Δήλωση Απορρήτου σχετίζεται με την Επεξεργασία των Προσωπικών σας Δεδομένων στα πλαίσια του Kaizen Expert Tool. Η παρούσα πρέπει πάντα να διαβάζεται μαζί με τη Γενική Πολιτική Απορρήτου και Προστασίας των Προσωπικών Δεδομένων της Toyota, όπου περιγράφονται οι γενικές λεπτομέρειες για την επεξεργασία των Προσωπικών σας Δεδομένων, και την οποία πρέπει ν'αποδεχτείτε προκειμένου να συνεχίσετε.",
      Message2:
        "H Toyota Motor Europe NV/SA, με έδρα στην Avenue du Bourget/Bourgetlaan 60, 1140 Brussels, Belgium, ως Υπεύθυνος Επεξεργασίας Δεδομένων, συλλέγει τα Προσωπικά Δεδομένα σας (όπως υποδεικνύεται παρακάτω) για τους σκοπούς που περιγράφονται κατωτέρω.",
      Message3: "Ποια Προσωπικά Δεδομένα σας θα συλλεχθούν?",
      Message4:
        "Θα συλλέξουμε την/τις ακόλουθη/ες κατηγορία/ες Προσωπικών Δεδομένων σας για το σκοπό του Kaizen Expert Tool:",
      Table1: {
        tr1: "Κατηγορία/ες Προσωπικών Δεδομένων:",
        tr2: "Id Χρήστη",
        tr3: "Όνομα Χρήστη (Πλήρες Όνομα)",
        tr4: "Μάρκα (Toyota, Lexus)",
        tr5: "Προτιμώμενη γλώσσα",
        tr6: "Σχετιζόμενος οργανισμός"
      },

      Message5:
        "Σκοπός και νομικές βάσεις για την επεξεργασία των προσωπικών δεδομένων σας.",
      Message6:
        "Θα επεξεργαστούμε τα Προσωπικά Δεδομένα σας για τους σκοπούς και με βάση τις νομικές βάσεις που περιγράφονται κατωτέρω:",
      Table2: {
        tr1: {
          th1: "Σκοπός:",
          th2: "Νομική βάση:"
        },
        tr2: {
          td1: "KET - 1: Kaizen Απόδοσης Επίσημου Εμπόρου",
          td2: "Έννομο συμφέρον επιδιωκόμενο από τον/τους Υπευθύνο/ους Επεξεργασίας Δεδομένων"
        },
        tr3: {
          td1: "KET - 2: Υποστήριξη IT",
          td2: "Έννομο συμφέρον επιδιωκόμενο από τον/τους Υπευθύνο/ους Επεξεργασίας Δεδομένων "
        }
      },
      Message7: "Διάρκεια Διακράτησης των Προσωπικών Δεδομένων σας",
      Message8:
        "Τα Προσωπικά σας Δεδομένα θα διατηρηθούν για την περίοδο που υποδεικνύεται παρακάτω:",
      Table3: {
        tr1: {
          th1: "Περίοδος διακράτησης:",
          th2: "Κατηγορία/ες Προσωπικών Δεδομένων:"
        },
        tr2: {
          td1: "Κάθε προσωπικό δεδομένο όπως αυτό περιγράφεται ανωτέρω θα διατηρείται για μέγιστη περίοδο 5 ετών + 1 μήνα από την ημέρα της οριστικής απενεργοποίησης του Kaizen Expert Tool",
          td2: "Μικρό όνομα χρήστη, Επώνυμο, Προτιμήσεις γλώσσας, Οργανισμός"
        }
      },
      Message9:
        "Προαιρετικά: Σε περίπτωση δημοσιοποίησης/μεταφοράς των προσωπικών δεδομένων σας σε χώρες εκτός του Ευρωπαϊκού Οικονομικού Χώρου (ΕΟΧ)",
      Message10:
        "Τα Προσωπικά σας Δεδομένα θα γίνουν προσβάσιμα σε παραλήπτη/ες εκτός ΕΟΧ όπως υποδεικνύονται παρακάτω:",
      Table4: {
        tr1: {
          td1: "Ταυτότητα",
          td2: "Χώρα",
          td3: ""
        },
        tr2: {
          td1: "Παραλήπτες εκτός ΕΟΧ",
          td2: "Infosys",
          td3: "Ινδία"
        }
      },
      Message11:
        "Επιβεβαιώνω ότι έχω καταλάβει την παρούσα ειδοποίηση· έχω διαβάσει και αποδεχτεί τη Γενική Πολιτική Προστασίας του Απορρήτου και των Προσωπικών Δεδομένων της Toyota."
    },
    ACCESSORIES: "Αξεσουάρ",
    activity: {
      Assignee: "Εκπρόσωπος",
      CompletionDate: "Ημερομηνία Ολοκλήρωσης",
      Dealer: "Επωνυμία  Εγκατάστασης",
      FirstVisit: "Πρώτη επίσκεψη",
      kpi: {
        kpi: "Ο δείκτης απόδοσης KPI  πρέπει να είναι αριθμός (θετικός ή αρνητικός)",
        kpiLength: "Το μέγιστο μέγεθος του KPI είναι 10 χαρακτήρες",
        mandatory: "Εισάγετε μια έγκυρη ημερομηνία σε όλα τα υποχρεωτικά πεδία",
        mandatoryTargets: "Παρακαλώ εισάγετε όλα τα KPI",
        target: "Ο στόχος πρέπει να είναι μόνο αριθμός",
        targetLength: "Το μέγιστο μέγεθος του στόχου είναι 10 χαρακτήρες"
      },
      ReminderOn: "Υπενθύμιση ",
      statusDD: "Κατάσταση",
      Toolbox: "Εργαλειοθήκη",
      activityCreation: "Activity Creation process has been started",
      activityCreated: "activity(s) have been created"
    },
    DPOK: "Παραγγελία Ανταλλακτικών ",
    EFFICIENCY: "Απόδοση",
    FIX_IT_RIGHT: "Σωστή επισκευή",
    footer: {
      message: "Υποχρεωτικά πεδία"
    },
    FOUNDATION: "Βάσεις",
    MAINTENANCE_REMINDERS: "Υπενθύμιση συντήρησης",
    EMPLOYEE_ENGAGEMENT: "Employee Engagement",
    CUSTOMER_CENTRICITY: "Customer Centricity",
    TOYOTA_WAY: "Toyota Way",
    BUSINESS_SUSTAINABILITY: "Business Sustainability", FUNDAMENTALS: "Fundamentals", FIX_IT_RIGHT_BnP: "Fix it Right (B&P)", UTILIZATION_AND_EFFICIENCY: "Utilization and Efficiency", CUSTOMER_EXPERIENCE: "Customer Experience", UPSELLING_OPPORTUNITIES: "Upselling Opportunities", MANAGING_CAPACITY: "Managing Capacity", VEHICLE_TRADE_IN: "Vehicle Trade-In", STOCK_MANAGEMENT: "Stock Management", REFURBISHMENT: "Refurbishment",
    months: {
      apr: "Απρ.",
      aug: "Αύγ.",
      dec: "Δεκ.",
      feb: "Φεβ.",
      jan: "Ιαν.",
      jul: "Ιούλ.",
      jun: "Ιούν.",
      mar: "Μάρτ.",
      may: "Μάιος",
      nov: "Νοέμβρ.",
      oct: "Οκτ.",
      sep: "Σεπτ."
    },
    others: {
      back: "Πίσω",
      closed: "Κλειστό",
      completed: "Ολοκληρώθηκε ",
      countryLanguageCode: "Χώρα / Κωδικός Χώρας ",
      createActivity: "Δημιουργία δραστηριότητας",
      createdDate: "Ημερομηνία δημιουργίας",
      dataUneditableNote:
        "Σημείωση: Τα δεδομένα που έχουν αποθηκευτεί δεν μπορούν να υποστούν επεξεργασία. Για να προσαρμόσετε τα δεδομένα επικοινωνήστε με τον διαχειριστή της εταιρείας μάρκετινγκ και πωλήσεων.",
      date: "Ημερομηνία",
      downloadQuestionnaire: "Λήψη Ερωτηματολογίου",
      editActivity: "Επεξεργασία δραστηριότητας",
      initialHelpText:
        "Η αρχική μέτρηση KPI θα πρέπει να είναι η μέση τιμή των προηγούμενων έξι μηνών",
      inProgress: "Σε εξέλιξη",
      name: "Όνομα",
      next: "Επόμενο",
      noActivities: "Δεν βρέθηκε δραστηριότητα",
      noResults: "Δεν υπάρχουν αποτελέσματα",
      of: "του",
      ongoing: "Σε εξέλιξη",
      open: "Ανοιχτό",
      page: "Σελίδα",
      planned: "Σχεδιασμένο",
      problemDescription: "Περιγραφή Προβλήματος",
      signature: "Υπογραφή",
      select: "Επιλέξτε",
      bulkAction: "Bulk Action",
      bulkActions: "Bulk Actions"
    },
    ServerMessage: {
      error_message: "Σφάλμα κατά το κατέβασμα",
      no_published_subquestion: "Παρακαλώ δημοσιεύστε το Ερωτηματολόγιο γι'αυτή την εργαλειοθήκη προκειμένου να ξεκινήσετε αυτή τη Δραστηριότητα.",
      activity_upload_error_msg: "Σφάλμα κατά το ανέβασμα των Λεπτομερειών της Δραστηριότητας",
      action_plan_created: "Το πλάνο δράσης δημιουργήθηκε επιτυχώς",
      action_plan_deleted: "Το πλάνο δράσης διεγράφη επιτυχώς",
      action_plan_saved: "Το πλάνο δράσης αποθηκεύτηκε επιτυχώς",
      activity_kpi_saved: "Τα KPIs της δραστηριότητας αποθηκεύτηκαν επιτυχώς",
      master_kpi_confirm_delete:
        "Είστε βέβαιοι ότι θέλετε τα διαγράψετε τo επιλεγμένο KPI",
      master_kpi_created: "Το Master KPI δημιουργήθηκε επιτυχώς",
      master_kpi_deleted: "Το Master KPI διαγράφτηκε",
      master_kpi_not_found: "Το Master KPI δεν βρέθηκε",
      master_kpi_published: "Τα επιλεγμένα Master KPI δημοσιεύτηκαν επιτυχώς",
      master_kpi_retired: "Το Master KPI αποσύρθηκε",
      master_kpi_saved: "Το Master KPI αποθηκεύτηκε επιτυχώς",
      subquestion_created: "Η υπο-ερώτηση αποθηκεύτηκε επιτυχώς.",
      subquestion_updated: "Η υπο-ερώτηση ενημερώθηκε επιτυχώς.",
      subquestionnaire_not_found: "Η υπο-ερώτηση δεν βρέθηκε",
      subquestion_retired: "Η υπο-ερώτηση έχει αποσυρθεί",
      subquestion_deleted: "Η υπο-ερώτηση έχει διαγραφεί.",
      subquestion_published: "Οι υπο-ερωτήσεις έχουν δημοσιευτεί επιτυχώς",
      mainquestion_created: "Το Κύριο Ερωτηματολόγιο έχει δημιουργηθεί.",
      mainquestion_deleted: "Το Κύριο Ερωτηματολόγιο έχει διαγραφεί.",
      mainquestion_updated: "Η Κύρια ερώτηση έχει ενημερωθεί.",
      duplicateMsg: "Διπλότυπες εγγραφές στο αρχείο Excel.",
      deleteErr: "Σφάλμα κατά τη διαγραφή του φακέλου.",
      toolbox_deleted: "Toolbox has been deleted successfully",
      toolbox_already_exists: "This toolbox already exists.",
      toolbox_saved: "Toolbox saved",
      toolbox_created: "Toolbox Created",
      toolbox_not_found: "Toolbox not found",
      published_content_found: "This toolbox cannot be deleted as it has some published content",
      dealers_already_mapped: " Οι επιλεγμένοι Ε.Ε. έχουν ήδη ανατεθεί σε αυτό το χρήστη ",
      dealer_already_mapped: " Dealer already mapped ",
      dealer_added: "  Ο Ε.Ε. προστέθηκε. ",
      dealers_added: "  Ο Ε.Ε. προστέθηκε. ",
      total_dealers_mapped_now: "Ο συνολικός αριθμός Ε.Ε. που έχουν ανατεθεί στο χρήστη είναι . ",
      no_dealer_available: "Δεν υπάρχουν διαθέσιμοι Ε.Ε. για τα επιλεγμένα κριτήρια",
      proper_dealer_selected: "Παρακαλώ επιλέξτε ένα κατάλληλο Ε.Ε.",
      user_referenced_ongoing_activity: "Ο χρήστης έχει καταχωρηθεί στις δραστηριότητες Σε Εξέλιξη. Παρακαλώ αντικαταστήστε τον καταχωρημένο χρήστη στις δραστηριότητες αυτές και δοκιμάστε ξανά. ",
      want_to_remove_mapping: "Θέλετε σίγουρα να αφαιρέσετε την ανάθεση του χρήστη γι'αυτούς τους Ε.Ε.?",
      user_mapping_removed: "Οι συγκεκριμένοι Ε.Ε. έχουν αφαιρεθεί επιτυχώς από την ανάθεση του χρήστη",
      dealer_closed_in_A2D: "Ο Ε.Ε. έχει καταχωρηθεί ως \"Κλειστός\" στο A2D",
      network_level_not_found: "Παρακαλώ επιλέξτε ένα έγκυρο συνδυασμό επιπέδων δικτύου σύμφωνα με τη δομή δικτύου στο KET",
      duplicate_dealer_uuid: "Ο Ε.Ε. υπάρχει ήδη στη βάση δεδομένων KET",
      dealer_created: "Ο Ε.Ε. δημιουργήθηκε",
      dealer_updated: "Τα χαρακτηριστικά του Ε.Ε. ενημερώθηκαν",
      dealer_not_present: "Ο Ε.Ε. δεν έχει καταχωρηθεί στο A2D",
      dealer_uuid_other_nmsc: "Ο κωδικός uuid A2D που επιλέχθηκε δεν είναι διαθέσιμος για το δίκτυό σας",
      target_kpi_updated: "The KPI's status have been updated successfully.",
      target_updated: "The KPI's target have been updated successfully.",
      kpi_launched: "The KPI's bulk launch successfully.",
      targetUpdate:"Application doesn't allow target to be modified for accepted status.",
    },
    standardMessages: {
      delete: "Είστε βέβαιοι για τη διαγραφή?",
      addActionPlanNotAllowed: "Το Σχέδιο Δράσης δεν μπορεί να δημιουργηθεί ή να τροποποιηθεί αφού η Δραστηριότητα έχει Κλείσει, Ολοκληρωθεί ή Απορριφθεί.",
      addToolboxNotAllowed: "Cannot create more than 19 toolbox",
      britRejectMsg: "Θέλετε σίγουρα ν'Απορρίψετε αυτή τη Δραστηριότητα? Μετά την απόρριψη, οι αλλαγές δεν είναι δυνατές.",
      rejectMsg: "Θέλετε σίγουρα ν'Απορρίψετε αυτή τη Δραστηριότητα? Μετά την απόρριψη, οι αλλαγές δεν είναι δυνατές.",
      selectNMSCTab: "Please select nmsc language to publish",
      all: "Όλα",
      deleteConfirm: "Σίγουρα θέλετε να διαγράψετε το επιλεγμένο KPI;",
      no: "Όχι",
      retireConfirm:
        "Σίγουρα θέλετε να αποσύρετε αυτό το KPI; Αφού αποσυρθεί, αυτό το KPI δεν μπορεί να ενεργοποιηθεί ξανά",
      warning: "Προειδοποίηση",
      yes: "Ναι",
      saveDataConfirmation: "Επιθυμείτε σίγουρα να αποθηκεύσετε τα δεδομένα;",
      saveKPIWarning:
        "Σημείωση: Δεν μπορείτε να επεξεργαστείτε τα δεδομένα αφού αποθηκευτούν. Για να προσαρμόσετε τα δεδομένα επικοινωνήστε με τον διαχειριστή της εταιρείας μάρκετινγκ και πωλήσεων ΚΕΤ.Για να προσαρμόσετε τα δεδομένα επικοινωνήστε με τον διαχειριστή KET της ΤΟΥΟΤΑ ΕΛΛΑΣ",
      addKpiNotAllowed: "Η πρόσθεση του επόμενου μήνα για το KPI μπορει να γίνει μετά το τέλος του τρέχοντος μήνα. ",
      activityLaunch: "Θέλετε σίγουρα να ξεκινήσετε τη Δραστηριότητα? Μόλις ξεκινήσει, η Δραστηριότητα δεν μπορεί να επιστρέψει στην κατάσταση 'Προγραμματισμένη', ή να διαγραφεί. ",
      deleteConfirmSubQuestionNaire: "Θέλετε σίγουρα να διαγράψετε την επιλεγμένη υπο-ερώτηση?",
      retireConfirmSubQuestionNaire: "Θέλετε σίγουρα ν'αποσύρετε την επιλεγμένη υπο-ερώτηση?",
      deleteConfirmMainQuestion: "Παρακαλώ επιβεβαιώστε ότι θέλετε να διαγράψετε την Κύρια ερώτηση και τις σχετιζόμενες υπο-ερωτήσεις.",
      deleteConfirmChapter: "Θέλετε σίγουρα να διαγράψετε το κεφάλαιο?",
      deleteLibraryFolder: "Θέλετε σίγουρα να διαγράψετε το φάκελο?",
      deletePublishedFolder: "Κάποια αρχεία μέσα στο φάκελο μπορεί να συνδέονται με το Ερωτηματολόγιο. Είστε βέβαιοι ότι θέλετε να διαγράψετε οριστικά αυτό τον υποφάκελο και να αφαιρέσετε το (τους) σύνδεσμο (ους) από το Ερωτηματολόγιο?",
      importQuestion: "Θέλετε σίγουρα να εισάγετε αυτή την υπο-ερώτηση? Η τρέχουσα υπο-ερώτηση θ'αντικατασταθεί.",
      noQuestion: "Δεν υπάρχουν διαθέσιμες ερωτήσεις",
      MandatoryWarning: "Παρακαλώ συμπληρώστε όλα τα υποχρεωτικά πεδία για όλες τις τοπικές γλώσσες.",
      certificationSubmission:
        "Toolbox is submitted to TME for dealer certification",
      sameToolBox: "You cannot submit same Toolbox more than once. Please select different toolbox",
      deleteConfirmDealer: "Are you sure you want to delete this dealer ?",
      updateVcConfirmDealer: "Όλες οι δραστηριότητες που δημιουργήθηκαν για το συγκεκριμένο E.E. στο \"Value Chain\" θ'αφαιρεθούν. Θέλετε σίγουρα να συνεχίσετε?",
      updateBritConfirmDealer: "Όλες οι δραστηριότητες που δημιουργήθηκαν για το συγκεκριμένο E.E. στο \"BRiT\" θ'αφαιρεθούν. Θέλετε σίγουρα να συνεχίσετε?",
      completed_activity_delete:"Τα δεδομένα δεν μπορούν ν'ανακτηθούν άπαξ και διαγραφούν. Θέλετε σίγουρα να διαγράψετε?",
      deleteAllQuestionary: "Are you sure you want to delete the entire Questionnaire?",
      deleteAllLibraries: "Are you sure you want to delete all folders and files in the Library?",
      selectAll: "Επιλογή όλων",
      unSelectAll: "Καταργήστε την επιλογή όλων",
      select:"Επιλέγω…",
      warningMsg:"Υπάρχουν ανοιχτά σχέδια δράσης σε αυτήν τη δραστηριότητα. Θα θέλατε να ολοκληρώσετε τη δραστηριότητα;"
    },
    status: {
      published: "Δημοσιεύτηκε",
      retired: "Αποσύρθηκε",
      saved: "Αποθηκεύτηκε",
      rejected: "Απορρίφθηκε",
      open: "Ανοιχτό",
      closed: "Κλειστό",
      close: "Κλειστό",
      ongoing: "Σε εξέλιξη",
      completed: "Ολοκληρώθηκε",
      complete: "Ολοκληρώθηκε",
      planned: "Σχεδιασμένο",
      overdue: "Εκπρόθεσμο",
      due: "Προθεσμία",
      pending: "Pending",
    },
    Rejected: "Απορρίφθηκε",
    Open: "Ανοιχτό",
    Closed: "Κλειστό",
    Close: "Κλειστό",
    Ongoing: "Σε εξέλιξη",
    Completed: "Ολοκληρώθηκε",
    Complete: "Ολοκληρώθηκε",
    Planned: "Σχεδιασμένο",
    tabDetails: {
      noActionAvailable: "Δεν υπάρχουν ενέργειες",
      activeDealer: "Αυτός ο Ε.Ε. είναι καταχωρημένος ως Ενεργός στο A2D και δεν μπορεί να διαγραφεί.",
      dealerDeleted: "Dealer is deleted."
    },
    Table: {
      action: "",
      activity: "Δραστηριότητα",
      activityCompletionDate: "Ημερομηνία ολοκλήρωσης δραστηριότητας",
      activityCompletionDateFrom: "Ημερομηνία ολοκλήρωσης δραστηριότητας από",
      activityCompletionDateTo: "Ημερομηνία ολοκλήρωσης δραστηριότητας έως",
      activityStartDate: "Ημερομηνία έναρξης δραστηριότητας",
      activityStartDateFrom: "Ημερομηνία έναρξης δραστηριότητας από",
      activityStartDateTo: "Ημερομηνία έναρξης δραστηριότητας έως",
      assignedTo: "Ανατέθηκε σε",
      assignee: "Εκπρόσωπος",
      benchmark: "Σημείο αναφοράς",
      city: "Πόλη",
      completedBy: "Ολοκληρώθηκε από ",
      completionBy: "Θα ολοκληρωθεί από",
      completionDate: "Ημερομηνία Ολοκλήρωσης",
      counterMeasure: "Ενέργεια αποκατάστασης αντίμετρα",
      country: "Χώρα",
      createdBy: "Δημιουργήθηκε από",
      dealer: "Επωνυμία  Εγκατάστασης",
      dealerNameAD: "Όνομα Ε.Ε. (A2D)",
      dealerName: "Ονομασία Εμπόρου",
      definition: "Προσδιορισμός",
      description: "Περιγραφή",
      dueDate: "Ημερομηνία λήξης",
      endDate: "Ημερομηνία ολοκλήρωσης",
      firstVisit: "Πρώτη επίσκεψη",
      followup: "Μετά την παράδοση",
      franchise: "Δικαιόχρηση",
      history: "Ιστορικό",
      id: "ID",
      initial: "Αρχικό",
      kpi: "KPI",
      launch: "Λανσάρισμα",
      mandatory: "Υποχρεωτικό",
      nmsc: "NMSC",
      problem: "Πρόβλημα",
      publishedOn: "Δημοσιεύτηκε στις",
      questionReference: "Αναφορά ερώτησης",
      reference: "Παραπομπή",
      reminderOn: "Υπενθύμιση",
      score: "Βαθμολογία",
      startDate: "Ημερομηνία έναρξης",
      status: "Κατάσταση",
      target: "Target",
      toolbox: "Εργαλειοθήκη",
      view: "Προβολή",
      kpiResult: "Αποτελέσματα KPI",
      nmscTarget: "NMSC Target",
      penEbenchmark: "PanE Benchmark",
      kpiField: "KPI",
      location: "Τοποθεσία",
      ActionItemReport: "Αντικείμενα Δράσεων σε εξέλιξη",
      certification: "Κατάσταση Πιστοποίησης",
      noOfToolbox: "Αριθμός Ολοκληρωμένων Εργαλειοθηκών",
      program: "Πρόγραμμα",
      certificationDate: "Ημερομηνία Πιστοποίησης",
      activityId : 'ID Δραστηριότητας',
      approvedBy:"Approved By",
      kpis:"KPIs",
      lastmonthKpis:"Last Month KPIs",
      questionPercent:"Completion %",
      actionPlan: "Σχέδιο δράσης",
      monthlyKpi:"Monthly KPIs",
      initialKpi:"Initial KPIs",
      targetKpi:"Target KPIs",
    },
    tabsDetails: {
      actionPlan: "Σχέδιο δράσης",
      confirmDeleteMessage:
        "Επιβεβαιώστε εάν θέλετε να διαγράψετε το επιλεγμένο KPI",
      confirmRetireMessage:
        "Επιβεβαιώστε εάν θέλετε να αποσύρετε το επιλεγμένο KPI καθώς έχει ήδη δημοσιευτεί",
      errorRetireMsg:
        "Αυτό το KPI δεν μπορεί να αποσυρθεί καθώς είναι ένα ενεργό υποχρεωτικό TME KPI",
      import: "Εισαγωγή",
      importKpiMessage: "Εισάγετε το KPI σας από τη λίστα TME Master KPI",
      kpi: "Βασικοί δείκτες απόδοσης",
      library: "Βιβλιοθήκη",
      noActionAvailable: "No Actions available",
      NoKPI: "Δεν υπάρχουν διαθέσιμα KPI",
      publish: "Δημοσίευση",
      questionnaire: "Ερωτηματολόγιο",
      tmeKPIList: "Λίστα TME Master KPI",
      toyotaCityBrussels: "TOYOTA CITY BRUSSELS",
      attachment: "Attachment",
      attachments: "Attachment(s)",
      uploadAttachment: "Upload Attachment",
      noAttachment: "No attachments available",
    },
    tmeAdminMaster: {
      ToolboxName: "Όνομα εργαλειοθήκης",
      newToolboxName: "Νέο όνομα",
      deleteToolbox: "Διαγραφή εργαλειοθήκης?",
      programName: "Όνομα προγράμματος",
      ToolboxName_EN: "Όνομα εργαλειοθήκης (EN)",
      deleteTool: "Είστε βέβαιοι ότι θέλετε να διαγράψετε την εργαλειοθήκη?",
      selectToolbox: "Παρακαλώ επιλέξτε μία εργαλειοθήκη προς διαγραφή.",
      editToolBox: "Επεξεργασία Εργαλειοθήκης",
      addToolbox: "Προσθήκη Εργαλειοθήκης",
      toolbox: "Παρακαλώ εισάγετε ένα όνομα για την Εργαλειοθήκη",
      addActionPlan: "Add Σχέδιο δράσης",
      actionPlanItem: "Σχέδιο δράσης - Στοιχείο #",
      add: "Προσθήκη",
      addActivity: "Προσθήκη δραστηριότητας",
      additionalInformation: "Πρόσθετες πληροφορίες",
      benchmark: "Σημείο αναφοράς",
      cancel: "Ακύρωση",
      createEditActivity: "Δημιουργία / επεξεργασία δραστηριότητας",
      createKPI: "Δημιουργία  KPI",
      delete: "Διαγραφή",
      download: "Λήψη",
      edit: "Επεξεργασία",
      editKPI: "Eπεξεργασία KPI",
      keyPerformanceIndicator: "Βασικός δείκτης απόδοσης",
      kpiDefinition: "Ορισμός KPI",
      mandatoryKpi: "Υποχρεωτικό KPI",
      save: "Αποθήκευση",
      search: "Αναζήτηση",
      unit: "Μονάδα",
      update: "Ενημέρωση",
      validData: "Καταχωρίστε έγκυρα δεδομένα",
      hide: "Απόκρυψη",
      show: "Προβολή",
      upload_dealer_network: "Ανέβασμα Δικτύου Εμπόρων",
      upload_question: "Ανέβασμα Ερωτηματολογίου",
      upload_user: "Ανέβασμα Λεπτομερειών Χρήστη",
      upload_activity: "Ανέβασμα Δραστηριοτήτων",
      remove: "Remove",
      toyota: "Toyota",
      lexus: "Lexus",
      launch: "Launch",
      close: "Close",
      generate: "Generate",
      dueDateExceed:"H προθεσμία έχει παρέλθει",
      delQuestionnaire:"Delete questionnaire"
    },
    toastr: {
      activityStatus_condition: "Παρακαλώ απαντήστε όλες τις ερωτήσεις προκειμένου να μπορέσετε να κλείσετε τη Δραστηριότητα.",
      emptyTarget: "Please enter the NMSC target to publish the KPI",
      retailerActual: "Please enter the Retailer Actual",
      initialTargetMonthly: "Εισαγάγετε την αρχική μέτρηση KPI, τους στόχους KPI και τουλάχιστον 1 μήνα μέτρησης για να συνεχίσετε.",
      initialTarget: "Παρακαλώ εισάγετε την αρχική τιμή του KPI καθώς και τις τιμές στόχους του KPI για να συνεχίσετε.",
      upload_error: "Σφάλμα κατά το ανέβασμα του Δικτύου Εμπόρων",
      action_item_create_warning: "Μέχρι πέντε καταχωρήσεις επιτρέπτεται να συγχωνευθούν σε ένα αντικείμενο Δράσης",
      actionPlanStartDateValidation:
        "Η ημερομηνία ολοκλήρωσης δεν μπορεί να είναι προγενέστερη της ημερομηνίας έναρξης",
      actionsNotClosed:
        "Υπάρχουν ενέργειες σε εξέλιξη στο σχέδιο δράσης, κλείστε όλες τις ενέργειες προτού κλείσετε μια δραστηριότητα",
      activityDuplicate:
        "Υπάρχει ήδη μια άλλη δραστηριότητα για τον ίδιο έμπορο και την ίδια εργαλειοθήκη",
      assigneeNotExist:
        "Ο επιλεγμένος εκπρόσωπος δεν έχει εγγραφεί στο σύστημα",
      assigneeValidation: "Μη έγκυρος εκπρόσωπος",
      atleastOnePublish: "Επιλέξτε τουλάχιστον ένα KPI για δημοσίευση",
      cannotRetireActive:
        "Αυτό είναι ένα ενεργό υποχρεωτικό TME KPI που δεν μπορεί να αποσυρθεί ",
      completionDate:
        "Η ημερομηνία ολοκλήρωσης δεν μπορεί να είναι προγενέστερη της ημερομηνίας πρώτης επίσκεψης",
      confirDeletePlannedActivity:
        "Σίγουρα θέλετε να διαγράψετε αυτήν την προγραμματισμένη δραστηριότητα;",
      DateformatValidation: "Date should be in format DD/MM/YYYY",
      DateValidation: "From Date Should Be Less Than To Date",
      dealerValidation: "Μη έγκυρος έμπορος",
      deleteSelectedConfirm: "Σίγουρα θέλετε να διαγράψετε το επιλεγμένο KPI;",
      duplicate:
        "Υπάρχει ήδη ένα άλλο KPI με το ίδιο όνομα και τον ίδιο ορισμό",
      modalMandatory:
        "Εισάγετε μια έγκυρη ημερομηνία σε όλα τα υποχρεωτικά πεδία",
      noPublishKpi:
        "Τα KPI δεν έχουν δημοσιευτεί ακόμα για την επιλεγμένη εργαλειοθήκη",
      validSearchCriteria: "Καταχωρίστε ένα έγκυρο κριτήριο αναζήτησης",
      multiSelectPlaceHolder: "Εισάγετε τουλάχιστον 5 χαρακτήρες",
      multiSelectPlaceHolderUser: "Εισάγετε τουλάχιστον 3 χαρακτήρες",
      startDateValidation: "Η ημερομηνία έναρξης δεν μπορεί να είναι μεταγενέστερη της ημερομηνίας ολοκλήρωσης",
      fileSelectionErr: "Παρακαλώ επιλέξτε πρώτα το αρχείο",
      atleastOneQuestion: "Παρακαλώ επιλέξτε τουλάχιστον μία υπο-ερώτηση για εισαγωγή.",
      atleastOneSubQues: "Παρακαλούμε επιλέξτε τουλάχιστον μία υπο-ερώτηση προς δημοσίευση",
      uploadErr: "Αριθμός αρχείων που απέτυχαν ν'ανέβούν:",
      uploadSuccess: "Το Δίκτυο Εμπόρων ανέβηκε επιτυχώς",
      file_upload_success: "Το(τα) αρχείο(α) ανέβηκε(αν) επιτυχώς",
      KPIinOngoingClosedActivity: "This KPI is present in one, or more, Ongoing/Closed Activities.Please Complete these Activities before making any change to the KPI.",
      Role_Error: "Η διαμόρφωση του χρήστη δεν έχει ολοκληρωθεί, παρακαλώ επικοινωνήστε με τους διαχειριστές KET του NMSC",
      points_allocation_incorrect: "Λανθασμένη απόδοση βαθμών, παρακαλώ τροποποιήστε",
      activity_deleted:"Η δραστηριότητα διαγράφηκε επιτυχώς.",
      activity_certified_dealer:"Ο Ε.Ε. έχει πιστοποιηθεί για την επιλεγμένη Δραστηριότητα, δεν μπορεί να διαγραφεί",
      activity_submited_for_certificate_dealer:"Η Δραστηριότητα έχει συμπεριληφθεί στην Αίτηση Πιστοποίησης που έχει σταλεί στην TME, επομένως η κατάστασή της δεν μπορεί ν'αλλάξει."
    },
    TopNav: {
      ActivityLog: "Δραστηριότητες",
      ApplicationName: "Kaizen Expert Tool",
      Home: "Αρχική",
      logout: "Αποσύνδεση",
      MasterDataUpload: "Μεταφόρτωση κύριων δεδομένων",
      Toolbox: "Εργαλειοθήκες",
      welcome: "Καλώς ήλθατε",
      Admin: "Διαχειριστής"
    },
    questionnaire: {
      addSubQuestion: "Πρόσθεση υπο-ερώτησης",
      editSubQuestion: "Επεξεργασία υπο-ερώτησης",
      subQuestionTitle: "Υπο-ερώτηση",
      additionalInfo: "Πρόσθετες Πληροφορίες",
      na: "Προσθήκη 'Δεν εφαρμόζεται' ως πιθανή απάντηση σε αυτή την ερώτηση",
      question: "Ερώτηση",
      questionTitle: "Τίτλος Ερώτησης",
      why: "Γιατί?",
      addMainQuestion: "Προσθήκη Κύριας ερώτησης",
      editMainQuestion: "Επεξεργασία Κύριας ερώτησης",
      previousQuestion: "Προηγούμενη ερώτηση",
      nextQuestion: "Επόμενη ερώτηση",
      what: "Τι?",
      library: "Βιβλιοθήκη",
      subQuestionTable: {
        no: "Όχι",
        subQuestion: "Υπό-ερωτήσεις",
        status: "Κατάσταση",
        publishedDate: "Ημερομηνία δημοσίευσης",
        notApplicable: "Δεν εφαρμόζεται"
      },
      links: {
        question_link_button: "Σύνδεσμος",
        question_link_include_folder: "Συμπεριλάβετε έναν υποφάκελο",
        question_link_add_link: "Συνδέστε αρχείο(α) από τη Βιβλιοθήκη",
        question_link_view_folder: "Προβολή Βιβλιοθήκης"
      },
      mainQuestion: "Νέες/Ενημερωμένες Κύριες Ερωτήσεις",
      subQuestion: "Νέες/Ενημερωμένες Υπο-ερωτήσεις",
      retiredQuestion: "Αποσυρμένες Υπο-ερωτήσεις",
      libraries: "Νέα/Ενημερωμένα αρχεία στις Βιβλιοθήκες:",
      noFiles: "Δεν υπάρχουν διαθέσιμα αρχεία"
    },
    library: {
      selectFile: "Παρακαλώ επιλέξτε τουλάχιστον ένα αρχείο",
      fileSelected: "Επιλεγμένα αρχεία",
      publish_library_file: "Παρακαλώ επιλέξτε τουλάχιστον ένα αρχείο",
      library_files_deleted: "Are you sure you want to delete this file?",
      library_files_linked_deleted: "Είστε βέβαιοι ότι θέλετε να διαγράψετε αυτό το αρχείο και τους συνδέσμους που δημιουργήθηκαν στο Ερωτηματολόγιο?",
      max_size_error: 'Το μέγεθος των αρχείων υπερβαίνει το 1GB ανά ανέβασμα',
      empty_file_desc: "Παρακαλώ εισάγετε μια περιγραφή αρχείου",
      file_not_selected: "Please select file to upload",
      file_type_error: "Αυτός ο τύπος αρχείου είναι μη επιτρεπτός",
      file_name_error: "Μη έγκυρος χαρακτήρας στο όνομα αρχείου",
      file_name_lenght_error: "Το όνομα αρχείου μπορεί να έχει κατά μέγιστο 50 χαρακτήρες.",
      addFolder: "Προσθήκη Φακέλου",
      editFolder: "Επεξεργασία Φακέλου",
      currentFolder: "Όνομα Φακέλου",
      parentFolder: "Όνομα Φακέλου 'Γονέα'",
      emptyFolderName: "Το Όνομα Φακέλου δεν μπορεί να είναι κενό.",
      invalidFolderName: "The Folder Name cannot contain '/'.",
      folder_created: "Ο Φάκελος δημιουργήθηκε με επιτυχία",
      folder_updated: "Ο Φάκελος ενημερώθηκε με επιτυχία",
      folder_not_found: "Ο Φάκελος δεν βρέθηκε",
      lib_invalid_toolbox: "Επιλέχθηκε μη έγκυρη Εργαλειοθήκη",
      lib_folder_already_exists: "Αυτός ο Φάκελος υπάρχει ήδη",
      lib_folder_deleted: "Ο Φάκελος διαγράφηκε επιτυχώς.",
      library_files_published: "Τα αρχεία έχουν δημοσιευτεί.",
      addFile: "Προσθήκη Αρχείου",
      editFile: "Επεξεργασία Αρχείου",
      fileName: "Όνομα αρχείου",
      upload: "Ανέβασμα",
      desc: "Περιγραφή",
      folderCreationErr: "Η ιεραρχία περιορίζεται σε δύο επίπεδα",
      subFolderWarning: "Παρακαλώ επιλέξτε ένα υποφάκελο για να προσθέσετε αρχείο(α)",
      deleteWarning: "Παρακαλώ επιλέξτε ένα φάκελο για διαγραφή.",
      beforeAfterNotSelected: "Επιλέξτε εάν τα στοιχεία είναι πριν ή μετά το kaizen",
    },
    chapter: {
      chapter_exist: "Αυτό το Κεφάλαιο υπάρχει ήδη.",
      chapter_saved: "Το Κεφάλαιο Αποθηκεύτηκε",
      chapter_cannot_delete: "Αυτό το Κεφάλαιο δεν μπορεί να διαγραφεί, καθώς έχουν ήδη δημοσιευτεί υπο-ερωτήσεις.",
      failed: "Αποτυχία!",
      chapter_deleted: "Το Κεφάλαιο Διαγράφηκε",
      chapter_nonEmpty: "Παρακαλώ δημιουργήστε ένα όνομα κεφαλαίου",
      addchapter: "Προσθήκη κεφαλαίου",
      editChapter: "Edit chapter",
      DELETE_SUCC:"Questionnaires deleted successfully.",
      DELETE_SUCC_LIB:"Libraries deleted successfully.",
      DELETE_ERR : "Something Went wrong."
    },
    evaluation: {
      attachments: "Συνημμένα",
      comments: "Σχόλια",
      evidence: "Πειστήρια",
      evidences: "Πειστήριο(α)",
      yes: "YES",
      no: "Όχι",
      na: "Μ/Δ",
      uploadEvidence: "Ανέβασμα πειστηρίων",
      fileUpload: "Παρακαλώ επιλέξτε το(τα) αρχείο(α) για ανέβασμα (μέγ. 1GB)",
      who: "Ποιος?",
      answer: "Απάντηση",
      comment: "Comment",
      date: "Date",
      submit: "Υποβολή",
      commentMsg: "Παρακαλώ εισάγετε τα σχόλια",
      noCommentAvailable: "Σχόλια μη διαθέσιμα",
      uploadSuccessMsg: "File(s)  have been uploaded successfully",
      uploadErrorMsg: "Number of files failing to upload: ",
      fileWithSameName: "Ένα άλλο αρχείο με το ίδιο όνομα υπάρχει ήδη",
      noEvidence: "No evidences available",
      createAction: "Δημιουργία αντικείμενου Δράσης",
      chapterName: "Όνομα κεφαλαίου",
      questionNo: "Ερώτηση υπ'αριθμ.",
      when: "Πότε ?",
      noRecords: "Δεν υπάρχουν διαθέσιμες καταχωρήσεις",
      kaizenPoints: "Βαθμοί Kaizen",
      renameSuccessMsg: "File has been renamed successfully",
      deleteSuccessMsg: "File has been deleted successfully",
      question: "Ερώτηση",
      before:"Before",
      after:"After",
      beforeAfterLabel:"Είναι αυτό το στοιχείο πριν ή μετά το kaizen;",
      move:"Μετακίνηση αρχείων",
      moveMsg: "Το/α αρχείο/α μετακινήθηκε/αν με επιτυχία",
      noToYesMsg:"Previous answer was NO",
    },
    WALK_AROUND_CHECK: "Οπτικός έλεγχος",
    ketProgram: {
      TSM: "TSM/LSM",
      BRiT: "BRiT RI",
      TSM_BNP: "Body & Paint",
      TSM_USED_CAR: "Used Car",
      value_chain: "Value Chain",
      brit: "BRiT",
      ea: "EA",
      per:"Performance",
      nettrans:"Network Transformation",
    },
    dashboard: {
      notice_board: "Πίνακας Ειδοποιήσεων",
      kpi_target_not_set: "KPI's targets not set",
      kpi_initial_not_set: "KPI's initial measurement not set",
      kpi_monthly_not_set: "KPI's monthly measurement not set",
      kpi_below_target: "KPI's below targets present",
      activation: "Ενεργοποίηση",
      users: "Χρήστες",
      nmscs: "NMSC's",
      activeRetailer: "Ενεργοί Ε.Ε. ",
      certificationRate: "Ποσοστό Πιστοποίησης",
      reCertificationRate: "Ποσοστό Επανα-πιστοποίησης",
      activityDue: "Προθεσμία Δραστηριοτήτων",
      avgdays: "Μέσος Αριθμός Ημερών/Εργαλειοθήκη",
      overDueAct: "Εκπρόθεσμες Δραστηριότητες",
      actionItem: "Αντικείμενα Δράσεων",
      bussResult: "Επιχειρηματικά Αποτελέσματα",
      toolboxImpl: "Εφαρμογή Εργαλειοθηκών",
      statusCompletedL:"Status - Completed, Progress - ",
      statusClosedL:"Status - Closed, Progress - ",
      statusOngoingL:"Status - Ongoing, Progress - ",
      recentUpdates: "Πρόσφατες Ενημερώσεις",
      rolloutTopNmsc: "Αποτελέσματα Υπολοίησης - Κορυφαίοι NMSC",
      bestPractice: "Πίνακας Βέλτιστων Πρακτικών",
      availableKpi: "Διαθέσιμα KPI",
      selectedKpi: "Επιλεγμένα KPI",
      dealerBestPractice: "Dealer best practice board",
      kpiList: "Λίστα KPI",
      go: "Εκκίνηση",
      nodata: "No data available",
      businessResultTop: "Επιχειρηματικά Αποτελέσματα - Κορυφαίοι Ε.Ε.",
      businessResultNMSC: "Επιχειρηματικά Αποτελέσματα - Κορυφαίοι NMSC"


    },
    dealerCertification: {
      dealerCertification: "Πιστοποίηση Ε.Ε.",
      readyForCertification: "Έτοιμοι για Πιστοποίηση",
      certification: "Πιστοποίηση",
      submittedOn: "Υποβλήθηκε στις",
      certificateDate: "Ημερομηνία Πιστοποιητικού",
      reCertificateDate: "Ημερομηνία Πιστοποιητικού επαναπιστοποίησης",
      toolboxDetails: "Λεπτομέρειες Εργαλειοθήκης",
      approve: "Έγκριση",
      reject: "Απόρριψη",
      pending: "Pending",
      region: "Περιοχή"



    },
    uploadBulk: {
      browse: "Browse",
      executionId: "Κωδικός (id) εκτέλεσης",
      uploadedOn: "Μεταφορτώθηκε στις",
      lastUpdatedOn: "Τελευταία ανανέωση στις",
      noDataTable: "No data to view",
      kpiUpload: "Μεταφόρτωση KPI",
      action: "Ενέργεια",
      processed: "Επεξεργάστηκε",
      error: "Σφάλμα",
      received: "Received",
      createActivity: "Δημιουργήστε τις Δραστηριότητες",
      fileUpload: "File Upload",
      targetSetting: "Target Setting",
      kpiEntry:"KPI Entry",
      accepted:"Accepted",
      launchNmsc:"Launch for NMSC"
    },

    userManagement: {
      userManagement: "Διαχείριση Χρηστών",
      createUser: "Create User",
      editUser: "Edit User",
      viewUser: "View User",
      nmscId: "NMSC ID",
      tarsId: "TARS ID",
      firstName: "Μικρό Όνομα  ",
      lastName: "Επώνυμο",
      emailId: "E-mail",
      britDealerChamp: "Brit Dealer Champion",
      invalidEmail: "Please enter valid E-Mail ID",
      invalidTars: "Please enter valid TARS ID",
      invalidFirstName: "Please enter valid First Name",
      invalidLastName: "Please enter valid Last Name",
      user_created: "User Created",
      user_updated: "User updated",
      duplicate_tarsuser: "This TARS id is already registered in the KET database.",
      user_define_in_tool: "User is defined as Business owner in Active Business Function. Hence, could not be made Inactive",
      user_refer_on: "User is referenced in Ongoing activity",
      pbiAccount : "Λογαριασμός PB",
      invalidPbiAccount : "Ο Λογαριασμός PBI πρέπει να περιέχει κατ'ελάχιστο 3 χαρακτήρες",
      businessArea: "Περιοχή Επιχειρηματικής Δραστηριότητας",
      valueChainAndBRiT: "Value Chain & BRiT",
      only:"Only",
      optionText:"Please select at least one of the two options below",
      valueChainOnly: "Μόνο Value Chain",
      britOnly: "Μόνο BRiT"

    },
    UserManagement: {
      nmscId: "NMSC ID",
      tarsId: "TARS ID",
      firstName: "Μικρό Όνομα",
      lastName: "Επώνυμο",
      emailId: "E-mail",
      languageCode: "Κωδικός Γλώσσας",
      ACTIVE: "Active",
      INACTIVE: "Ανενεργό",
      addUser: "Προσθήκη Χρήστη",
      Assign: "Ανάθεση",
      List: "Λίστα",
      user: "Χρήστης",
      error_message: "An Error Occurred. Please try again later",
      role: "Ρόλος Χρήστη",
      standardMessage: "No User Found",
      dealerCode: "Κωδικός Ε.Ε.",
      a2d: "A2D UUID",
      britReady: "Ο Ε.Ε. συμμετείχε στο Brit Ready",
      britLive: "Ο Ε.Ε. συμμετείχε στο Brit Live",
      britJapan: "Ο Ε.Ε. συμμετείχε στο Brit Discovery Japan",
      britEss:"Ο Ε.Ε. έχει ενημερώσει το Πλάνο Δράσης ESS",
      addDealer: "Προσθήκη Ε.Ε. (από A2D)",
      noDealer: "Δεν βρέθηκε Ε.Ε.",
      dealerUuid: "Κωδικός uuid του Ε.Ε. (A2D)",
      language: "Γλώσσα",
      L3: "L3",
      L4: "L4",
      L5: "L5",
      L6: "L6",
    },
    CeaKpi: {
      survey: "Survey",
      evaluationItem: "Evaluation Item",
      kpiMetric: "KPI Evaluation Method",
      stratagicPillar: "Strategic Pillar",
      addKPI: "Add KPI",
      edit: "Edit",
      delete: "Delete",
      subPillar: "Sub-Pillar",
      kaizenImp_impact_def: "Kaizen Importance / Impact / Definition",
      evalMethod: "Evaluation Method",
      scoreMethod: "Scoring Method",
      typeOftarget: "Type of Target",
      target: "Target",
      mode: "Mode",
      points: "Points",
      inputFreq: "Input Frequency",
      cea_kpi_created: "CEA KPI has been created successfully",
      noPointsAllocated:"No Points defined for selected Pillar/Business function mapping",
      targetIncorrect:"Target value is Invalid",
      viewKpi:"View KPI",
      general:"General",
      targetScoring:"Targets & Scoring",
      inputs:"Input Parameters",
      maxFiveParam:"Maximum 5 parameters for a KPI can be added",
      validParam:"Please Enter Valid Parameter",
      validDesc:"Please Enter Valid Parameter Description",
      dataEntry:"Data Entry in KET",
      paramater:"Parameter",
      paramDesc:"Parameter Description",
      noParamAvai:"No Parameters Available",
      kpiDeleted:"KPI deleted Successfully",
      kpiDeleteError:"KPI Not found",
      kpiActualsError:"Actuals present. KPI cannot be deleted",
      cea_kpi_updated: "CEA KPI has been updated successfully",
      addParam:"Add Parameters"

    },

    dealerManagement: {
      invalidUuid: "Παρακαλώ εισάγετε ένα έγκυρο A2D uuid",
      invalidDealerName: "Παρακαλώ εισάγετε ένα έγκυρο όνομα Ε.Ε.",
      invalidCity: "Παρακαλώ εισάγετε μία έγκυρη τοποθεσία",
      invaliDealerCode: "Παρακαλώ εισάγετε ένα έγκυρο κωδικό Ε.Ε.",
      existingUuid: "Ο κωδικός uuid στο A2D υπάρχει ήδη στη βάση δεδομένων του KET",
      updateUuid: "Ενημέρωση κωδικού uuid E.E.",
      newUuid: "Νέος κωδικός uuid στο A2D",
    },
    networkManagement: {
      networkManagement: "Διαχείριση Δικτύου"
    },
    kpiEntry: {
      cancel : "Cancel",
      save : "Save",
      no : "No",
      yes : "Yes",
      hide : "Hide",
      show : "Show",
      dataNotSaved : "Data is not saved. Entered data may be lost. Do you want to Proceed?",
      wantToProceed : "Do you want to proceed?",
      status: 'Status',
      evaluationYear: 'Evaluation Year',
      frequency: 'Frequency',
      typeOfTarget: 'Type of Target',
      target: 'Target',
      unit: 'Unit',
      standardMessage: 'No Kpi Actuals Data Found',
      srNo: 'S.No.',
      actuals: 'Actuals',
      subPillar: 'Sub Pillar',
      businessFunc: 'Business Function',
      brand: 'Brand',
      Jan : "Jan",
      Feb : "Feb",
      Mar : "Mar",
      Apr : "Apr",
      May : "May",
      Jun : "Jun",
      Jul : "Jul",
      Aug : "Aug",
      Sep : "Sep",
      Oct : "Oct",
      Nov : "Nov",
      Dec : "Dec",
      q1 : "Q1",
      q2 : "Q2",
      q3 : "Q3",
      q4 : "Q4",
      h1 : "H1",
      h2 : "H2",
      cy : "CY",
      kpi : "Key Performance Indicators",
      actuals1  : "Actuals should not be more than allotted points. Please re-enter",
      actionPlan	: "Action Plan",
      enterActualsData : "Please enter actuals data.",
      notNumber : "Please enter valid number.",
      serverErrorMsg : "An error occured, please try again later.",
      launchEvaluationError : "Please launch evaluations.",
      actualsDataSaved : "Actuals data uploaded successfully.",
      inputTxt:"Inputs"
    },
    ceaEvaluation :{
      nmsc : "NMSC",
      search : "Search",
      all : "All",
      status : "Status",
      businessFunction :"Business Function",
      noEvaluationsFound : "No Evalutions Found",
      evaluationYear : "Evalution Year",
      view : "View",
      evaluations : "Evaluations",
      launchTargetSetting: "Launch Target Setting",
      launchEvaluation : "Launch Evaluation",
    },
    britDashboard:{
      noVideo:"No videos available",
      playVideo:"Play Video",
      trainingVideos:"Training  Videos",
      download: "Download Video",
      back:"Back"
    },
    ceaActionPlan:{
      id: "ID",
      counterMeasure: "Countermeasure",
      questionReference: "KPI Reference",
      status: "Status",
      startDate: "Start Date",
      dueDate: "Due Date",
      assignedTo: "Assigned To",
      noActionAvailable: "No Actions available",
      add: "Add",
      download: "Download",
      edit: "Edit",
      delete: "Delete",
      deleteMsg: "Are you sure you want to delete ?",
      no: "No",
      yes: "Yes",
      addActionPlanNotAllowed: "The Action Plan cannot be created, or edited after the Activity has been Closed, Completed, or Rejected.",
      actionPlanItem: "Action Plan - Item #",
      addActionPlan: "Add Action Plan",
      open: "Open",
      actionPlanStartDateValidation:"End Date cannot be earlier than the Start Date",
      modalMandatory: "Please enter valid data in all the mandatory fields",
      problem: "Problem",
      description: "Description",
      followup: "Follow Up",
      history: "History",
      cancel: "Cancel",
      save: "Save",
      update: "Update",
      assignee: "Assignee",
      endDate: "End Date", 
      select: "Select",
      notApplicablePer:"Not Applicable for CEA, Networks and Used car"
    },
    reachItDashboard:{
      topNavReachIT:"ReachIT",
      downloads:"Λήψεις",
      historyFiles:"Ιστορικό προηγούμενων αρχείων",
      reachText1:"Καλώς ήρθατε στην σελίδα του Reach IT. Η ομάδα του τμήματος Διαχείρησης Περιβάλλοντος της Toyota Ευρώπης  χρησιμοποιεί αυτή την ιστοσελίδα για να μοιράζεται πληροφορίες σχετικά με την SCIP: Η βάση δεδομένων παρεχει πληροφοριες σχετικά με Ουσίες που Προκαλούν Ανησυχία και περιέχονται σε αντικείμενα ή σύνθετα προιόντα σύμφωνα με την Οδηγία-Πλαίσιο για τα Απόβλητα έτσι ώστε να εκπληρώσετε την υποχρέωση σας να προχωρήσετε στην κοινοποίηση SCIP",
      reachText2:"'Για περισσότερες πληροφορίες παρακαλώ επικοινωνήστε με το Τμήμα Διαχείρησης Περιβάλλοντος της Toyota Ευρώπης στο παρακάτω συνδεσμο-link.",
      reachText3:"Για τυχόν απορίες παρακαλώ επικοινωνήστε με την Toyota Ελλάς",
      envMngt:"Τμήμα Διαχείρησης Περιβάλλοντος",
      fileStatistics: "Λήψη αρχείου Στατιστικών",
      Downloaded: "Ελήφθη",
      not: "Όχι",
      exportToExcel:"Εξαγωγή δεδομένων σε Excel"
    },
    usedCarComment:{
      ongoing:"Ongoing",
      completed:"Completed",
      commentHistory:"Monthly Comments History",
      addComment:"Add Monthly Comment",
      month:"Month",
      comment:"Comment",
      username:"Username",
      email:"Email",
      createdDate:"Created Date"
    },
  }
};

export default translationsEL;
