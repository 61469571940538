import React from 'react';
import {Treebeard, decorators} from 'react-treebeard';
import { connect } from "react-redux";
import { Col, Fa} from "mdbreact";
import { withRouter } from "react-router-dom";
import { saveCeaTargetStatus, submitKpiEvaluationComment,updateTargetValue } from '../../../actions/finalizeTargetAction.jsx';
import { withTheme } from "../../../themes/theming";
import "./../index.css";
import BootstrapTable from "react-bootstrap-table-next";
import cellEditFactory  from 'react-bootstrap-table2-editor';
import AddComments from "./addComments"
import { toastr } from "react-redux-toastr";
import * as AppConst from '../../../AppConstant';
import CeaNetKpiModal from '../../ceaNetKpiModal';
import axios from "axios";
var I18n = require("react-redux-i18n").I18n;
var modal= false;
var modalFor= 'View';
var selectedRow={}
/* eslint-disable */
function toggleLaunch(node){
    if(node.childTableList.length>0){
        let kpiId = node.childTableList[0].kpiId
        let targetDto = [];      
        let targetFlag = true;
        node.childTableList.map(function (item) {
            if(item.target!==""){
             //Target Update start            
            if(item.oldTarget !== undefined){
                if(item.oldTarget !== item.target){
                    let selectedObj = {
                        kpiEvaluationId: item.kpiEvaluationId,
                        kpiId: item.kpiId,
                        target: item.target
                    };
                    targetDto.push(selectedObj);
                }
                item.oldTarget = item.target
            } 
            //Target Update end          
            if(item.status === "PENDING" || item.status=== "REJECTED"){
                item.status = "FOR ACCEPTANCE";
            }            
            }else{
                targetFlag = false;
                toastr.error(I18n.t("ServerMessage.bulkLaunchTargetMsg"));
                return;
            }
        })
        //Target Update start
        if(targetDto.length>0){
            fetch(axios.defaults.baseURL+'/finalizeTarget/target/save',{
                method: 'POST', 
                headers: {
                    'Content-type': 'application/json',
                    'Accept': 'application/json',
                    'Pragma': 'no-cache'
                },
                body: JSON.stringify(targetDto)
                })
                .then(response => {
                    if(response.status===200){
                         toastr.success(I18n.t("ServerMessage.target_updated"));    
                    }                          
                });
        }
        //Target Update end
        //Bulk Launch
        if(targetFlag){
            fetch(axios.defaults.baseURL+'/finalizeTarget/bulkLaunch/'+kpiId,{
            method: 'GET', 
            headers: {
                'Access-Control-Allow-Origin': '*',
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
            })
            .then(response => {
                if(response.status===200){
                    toastr.success(I18n.t("ServerMessage.kpi_launched"));    
                }                          
            });
        }   
    }
  }


decorators.Header = ({ style, node }) => {
    let flag = false;
    if(node.childTableList!==undefined && node.childTableList.length>0){
        node.childTableList.map((n) =>{
            if(n.status !== "ACCEPTED" || n.status !== "Accepted"){
                flag = true;    
                return
            }                  
        })
    }
    return (
        <span>
            {node.children ?
                <span title={node.name} style={{ display: 'inline-block',margin: '-6px 10px',textOverflow: "ellipsis", overflow: "hidden", whiteSpace: "nowrap", wordBreak: 'break-all', maxWidth: "100%" }}>                  
                    {node.seqNumber}{node.name}                    
                </span> : 
                <span style={{  height: '20px' }} >                    
                    {
                        (node.childTableData===undefined || node.childTableData === "") ?
                        <img className="toggleTreebeard toyota" src="/images/T_Artboard 21mdpi.png"  height={'20px'} width={'20px'} alt=""/>                        
                        :
                        <img className="toggleTreebeard toyota" src="/images/q_down.png"  height={'20px'} width={'20px'} alt=""/>                        
                    }
                    <span style={{ marginLeft: '10px'}}>
                        {node.name}                        
                        </span>  
                        {node.childTableList!==undefined && node.childTableList.length>0 ?
                        <a onClick={() => toggleView(node.childTableList[0].kpiId)} style={{ marginLeft: '10px'}}>
                            <Fa icon='eye' fixed size="lg" title={I18n.t('Table.View')}/>
                        </a>  :""}                
                        
                        {
                        (node.childTableData!==undefined && node.childTableData) ?
                            <span>
                                {(node.childTableList!==undefined && node.childTableList.length && node.ceaBoRole && flag)?
                                    <a style={{ marginLeft: '10px',cursor: (node.childTableList[0].isEditable === undefined || node.childTableList[0].isEditable === false)? 'not-allowed' : 'pointer'}} 
                                        onClick={() => (node.childTableList[0].isEditable === undefined || node.childTableList[0].isEditable === false)? {} : toggleLaunch(node)}>
                                        <Fa icon='rocket' fixed size="lg" title={I18n.t('uploadBulk.launchForAll')}/>
                                    </a>
                                : ""}                                 
                                <br />
                                {node.childTableData}
                            </span>
                        :
                        ""
                        }

                                {/* kpi view--start */}
                                {modal === true ?
                                <CeaNetKpiModal
                                    modal={modal}
                                    toggle={popUpClose}
                                    modalFor={modalFor}
                                    selectedRow={selectedRow}
                                /> : null}
                                {/* kpi view--end */} 
                </span >
                }                
        </span>
    );
};

function toggleView(kpiId){
    modal= !modal
    modalFor= 'View'
    selectedRow= {localKpiId:kpiId}         
}

//for kpi view
function popUpClose(action){
    // this.setState({
        // modal= !modal,
        modal= false
        modalFor= action
    // });
}


var isRotate = false;
decorators.Toggle = ({ style }) => {
    isRotate = isRotate ? false : true;
    var isRotateVal = "rotate-" + isRotate;
    return (
        <span style={{ display: 'inline-block', height: '20px' }} >
            <img className="toggleTreebeard toyota" src="/images/T_Artboard 21mdpi.png"  height={'20px'} width={'20px'} alt=""/>
        </span >
    );
};

class TargetSetting extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            questionLeftPane: [],
            searchTargetList: [],
            data: [],
            toggleComments: false,
            toggleReject: false,
            ceaAdminRole: false,
            ceaBoRole: false,
            ceaNmscAdminRole: false,
            ceaNmscUser: false,
            ceaCeaUser: false,
            expandFlag: true
        }        
        this.onToggle = this.onToggle.bind(this);
    }

    componentDidMount(){
        let ceaAdminRole = false;
        let ceaBoRole = false
        let ceaNmscAdminRole = false;
        let ceaNmscUser = false;
        let ceaCeaUser = false;
        if (this.props.user.userMdl.roles.includes(AppConst.CEA_ROLES.KET_CEA_Admin)) {
            ceaAdminRole = true
        } 
        if (this.props.user.userMdl.roles.includes(AppConst.CEA_ROLES.KET_CEA_BO)) {
            ceaBoRole = true
        }
       if (this.props.user.userMdl.roles.includes(AppConst.CEA_ROLES.KET_CEA_NMSC_Admin)) {
            ceaNmscAdminRole = true
        }
        if (this.props.user.userMdl.roles.includes(AppConst.CEA_ROLES.KET_NMSC_User)) {
            ceaNmscUser = true
        }
        if (this.props.user.userMdl.roles.includes(AppConst.CEA_ROLES.KET_CEA_User)) {
            ceaCeaUser = true
        }
        this.setState({ questionLeftPane: [], ceaAdminRole:ceaAdminRole,ceaBoRole:ceaBoRole,ceaNmscAdminRole:ceaNmscAdminRole,ceaNmscUser:ceaNmscUser,ceaCeaUser:ceaCeaUser })            
    }

    componentDidUpdate(){        
        if(this.props.user.userMdl.roles.includes(AppConst.CEA_ROLES.KET_CEA_NMSC_Admin) && this.state.questionLeftPane.length !== 0 && this.state.expandFlag){
            this.state.questionLeftPane.map(r => {
                this.onToggle(r,true)
            });
            this.setState({ expandFlag:false });
        }      
        let cursor = this.state.cursor
        if (this.state.cursor && this.state.cursor.child!== undefined && this.state.cursor.child) { 
            cursor["child"] = false; 
            this.setState({ cursor });
        }
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        var kpiList = []
        var busiFunList = []
        if(nextProps.searchTargetDetails && nextProps.searchTargetDetails !== prevState.searchTargetList){
            kpiList = nextProps.searchTargetDetails
            if(Object.keys(kpiList).length){
                Object.keys(kpiList).map((item, key) => {
                    busiFunList[key] = []
                    busiFunList[key]['children'] = []
                    busiFunList[key]['name'] = item
                     var nav = item.toString().replace(/"/g, "");                    
                    if(Object.keys(kpiList[nav]).length){
                        Object.keys(kpiList[nav]).map((i, k) => {
                            busiFunList[key]['children'][k] = []
                            busiFunList[key]['children'][k]['name'] = i
                            busiFunList[key]['children'][k]['flag'] = false
                            busiFunList[key]['children'][k]['child'] = false
                            var nm = i.toString().replace(/"/g, "");   
                            busiFunList[key]['children'][k]['childTableList'] = kpiList[nav][nm]
                        })   
                    }
                })
            }
            return { 
                questionLeftPane: busiFunList,
                searchTargetList:nextProps.searchTargetDetails,
                expandFlag:true
            };
        }
    }    

    deactiveNode = (currentNode) => {
        var flag = false;
        var nodeList = this.state.questionLeftPane;
        for (var i = 0; i < nodeList.length; i++) {
            var chapt = nodeList[i];
            var node = nodeList[i].children;
            for (var j = 0; j < node.length; j++) {
                var question = node[j];
                if (question.name !== currentNode.name) {
                    // question.active = false;
                }
                else {
                    flag = true
                }
            }
            if (!flag) {
                if(this.props.user.userMdl.roles.includes(AppConst.CEA_ROLES.KET_CEA_NMSC_Admin) && this.state.expandFlag){ 
                    chapt.toggled = true;
                    chapt.active = true;
                }else{
                    // chapt.toggled = false;
                    // chapt.active = false;
                }
            }
        }
        this.setState({ questionLeftPane: nodeList})
    }

    onToggle(node, toggled) {
        if(toggled!==undefined){     
        this.deactiveNode(node);
        if (this.state.cursor) {
            // this.state.cursor.active = false; 
            this.state.cursor.ceaBoRole = this.state.ceaBoRole
            node.child = this.state.cursor.child;
        }
        node.active = true;
        if (node.children) {
             node.toggled = toggled;
        }
        if ((!node.children || node.children.length === 0) && (!this.state.cursor.child)) {
            node.flag  = !node.flag
            node.childTableData = ""   
            node.ceaBoRole = this.state.ceaBoRole          
            if(node.flag && node.childTableList.length !== 0){
                node.childTableData = this.createTable(node.childTableList)
            }else{
                node.active  = false
            }
        }
        this.setState({ cursor: node });
        if(this.props.user.userMdl.roles.includes(AppConst.CEA_ROLES.KET_CEA_NMSC_Admin) && this.state.expandFlag){            
            node.children.map(r => {
                this.deactiveNode(r);
                r.active = true;
                r.flag  = true
                r.childTableData = ""   
                r.ceaBoRole = this.state.ceaBoRole          
                if(r.childTableList.length !== 0){
                    r.childTableData = this.createTable(r.childTableList)
                }
               this.setState({ cursor: r });
            });
        }     
        }
    }

    changeCursorChild = () =>{
        let cursor = this.state.cursor
        if (this.state.cursor) { 
            cursor["child"] = true; 
            this.setState({ cursor });
        }
    }        

    toggleCommentsAcceptRejectLaunch = (param, row) => {
        if(param === "Launch"){
            if(row.target!==""){
            console.log("Launch row",row);
            this.props.dispatch(saveCeaTargetStatus(row.kpiEvaluationId,AppConst.NMSC_STATUS_LAUNCH,row.businessFunction,row.nmscCode,row.kpi ));
            this.toggleSave()
            row.status = "FOR ACCEPTANCE";
            }else{
                toastr.error(I18n.t("ServerMessage.launchTargetMsg"));
            }           
        }else if(param === "Accepted"){
            this.props.dispatch(saveCeaTargetStatus(row.kpiEvaluationId,AppConst.NMSC_STATUS_ACCEPT,row.businessFunction,row.nmscCode,row.kpi));
            row.status = "ACCEPTED";
        }else if(param === "Rejected"){ 
            console.log("Rejected row", row);
            // this.props.dispatch(saveCeaTargetStatus(row.kpiEvaluationId,AppConst.NMSC_STATUS_REJECT,row.businessFunction,row.nmscCode,row.kpi));
            this.setState({ 
                row:row,
                toggleComments: true,
                toggleReject: true
            });    
        }else{
            this.setState({ row:row,toggleComments: true });
        }
    }

    toggleSave = () => {
        this.changeCursorChild()
        let cursor = this.state.cursor
        let targetDto = [];
        if(this.state.cursor.childTableList.length>0){
            this.state.cursor.childTableList.map(function (item) {
                if(item.oldTarget !== undefined){
                    if(item.oldTarget !== item.target){
                        let selectedObj = {
                            kpiEvaluationId: item.kpiEvaluationId,
                            kpiId: item.kpiId,
                            target: item.target
                        };
                        targetDto.push(selectedObj);
                    }
                    item.oldTarget = item.target
                }                
            })
            this.setState({ cursor });
            if(targetDto.length>0){
                this.props.dispatch(updateTargetValue(targetDto));
            }            
        }
    }
    
    toggleCancel = (kpiEvaluationId,param) => {
        this.changeCursorChild()
        let cursor = this.state.cursor
        if(this.state.cursor.childTableList.length>0){
            this.state.cursor.childTableList.map(function (item) {
                if(param=== "comment"){
                    if(kpiEvaluationId === item.kpiEvaluationId){
                        item.status = "REJECTED"
                    }
                }
                else{
                    if(item.oldTarget !== undefined){
                        item.target = item.oldTarget
                    }
                }
            })
            this.setState({ cursor });
        }        
    }

    toggleCommentsCancel = ()=>{
        this.setState({ toggleComments: false,
            toggleReject: false
         });
    }

    submitComment = (comment, refreshHistory) => {
        if(this.state.toggleReject){
            this.toggleCancel(this.state.row.kpiEvaluationId, "comment")
             this.props.dispatch(saveCeaTargetStatus(this.state.row.kpiEvaluationId,AppConst.NMSC_STATUS_REJECT,this.state.row.businessFunction,this.state.row.nmscCode,this.state.row.kpi));
        }
        var kpiEvaluationDTO = {
            kpiEvaluationId: this.state.row.kpiEvaluationId,                                
            target: this.state.row.oldTarget,
            nmscStatus: this.state.row.status,
            comment: comment,
            loginId: this.props.user.userMdl.username,
        }
        this.props.dispatch(submitKpiEvaluationComment(kpiEvaluationDTO));        
        this.setState({ toggleComments: false,
            toggleReject: false
         });
    }    

    nmscFormatter = (cell, row) => {
        return (
            <div style={{ textOverflow: "ellipsis", overflow: "hidden", whiteSpace: "nowrap" }}>{row.nmscCode}</div>
        );
    }    

    targetFormatter = (cell, row, rowIndex) => {
        return (
            <input
            type="text"
            defaultValue={(cell && cell !== undefined) ? cell : ""}
            onChange={e => this.onChangeTextBox(e, row, cell)}
            className="form-control"
            onKeyDown={e => this.autoTab(e, row, cell)}
            onBlur={e => this.onTabChange(e, row, cell)}
            disabled={(this.state.ceaAdminRole ||this.state.ceaBoRole && row.isEditable)? false : true}
            />
        );
    }; 

    onChangeTextBox = (e, row, cell) => {    
    };

    autoTab = (e, row, cell) => {
        if (e.keyCode === 9) {
          this.onTabChange(e, row, cell)
        }
    };  

    onTabChange = (e, row, cell) => {
        if(row.oldTarget === undefined){
            row.oldTarget = row.target;
        }
        // if (row.status === "ACCEPTED" || row.status === "Accepted") {
        //     toastr.error(I18n.t("ServerMessage.targetUpdate"));
        //     e.target.value = row.target
        // } 
        else {
            let val = e.target.value.trim();
            var Number = /^\d*\.?\d{0,6}$/;
            if ((Number.test(val) && val !== "") || val === "-") {
                e.target.value = val  
                row.target = val;  
            }else{
                toastr.error(I18n.t("CeaKpi.targetIncorrect")); 
                e.target.value = row.target
            }
        }
    };

    unitFormatter=(cell,row)=>{
        return (
            <div style={{ textOverflow: "ellipsis", overflow: "hidden", whiteSpace: "nowrap" }}>{row.unit}</div>
        );
    }

    statusFormatter = (cell, row) => {
        return (
            <div style={{ textOverflow: "ellipsis", overflow: "hidden", whiteSpace: "nowrap" }}>{row.status}</div>
        );
    }

    buttonFormatter = (cell, row, rowIndex) => {               
        let pending = false
        let forAcceptance = false
        let accepted = false
        let rejected = false
        let activityStatus = true
        pending = row.status === "PENDING" ? true:false;
        forAcceptance = row.status === "FOR ACCEPTANCE" ? true:false;
        accepted = (row.status === "ACCEPTED" || row.status === "Accepted") ? true:false;
        rejected = row.status === "REJECTED" ? true:false;
        activityStatus = (row.activityStatus === "ONGOING" || row.activityStatus === "COMPLETED") ? false:true;
        return (
          <div className="">
              {
                <a 
                    style={{
                        cursor: row.isEditable === false? 'not-allowed' : 'pointer',
                        visibility:((pending || rejected) && (this.state.ceaBoRole)) ? "unset" :"hidden"
                    }}
                    onClick={() => row.isEditable === false? {} : this.toggleCommentsAcceptRejectLaunch("Launch",row)}>
                    <Fa icon='rocket' disabled={true} fixed size="lg" title={I18n.t('uploadBulk.launchNmsc')}/> 
                </a>
            }
            {
                <a 
                    style={{
                        visibility: 
                        ((pending && (this.state.ceaAdminRole || this.state.ceaBoRole)) || ((forAcceptance || accepted || rejected) && (this.state.ceaAdminRole || this.state.ceaBoRole || this.state.ceaNmscAdminRole || this.state.ceaNmscUser || this.state.ceaCeaUser))) 
                        ? "unset" :"hidden"}}
                    onClick={() => this.toggleCommentsAcceptRejectLaunch("Comments",row)} >
                    <div title={I18n.t('evaluation.comments')} className="comment-icon" />
                </a>
            }            
            {
                <a 
                    style={{
                        visibility: (forAcceptance && this.state.ceaNmscAdminRole)? "unset" :"hidden"
                    }}
                    onClick={() => this.toggleCommentsAcceptRejectLaunch("Accepted",row)}>
                    <Fa icon='thumbs-up' fixed size="lg" title={I18n.t('uploadBulk.accepted')}/>
                </a>
            }
            {
                <a
                    style={{
                        visibility: (((forAcceptance && this.state.ceaNmscAdminRole) || (accepted && this.state.ceaAdminRole)) && activityStatus)? "unset" :"hidden"
                    }}
                    onClick={() => this.toggleCommentsAcceptRejectLaunch("Rejected",row)}>
                   <Fa icon='thumbs-down' fixed size="lg" title={I18n.t('status.Rejected')}/>
                </a>
            }            
          </div>
        );
    }

    createTable = (data) => {     
        const rowEvents = {            
            onClick: (e, row, rowIndex) => {
                this.changeCursorChild()
            }
        };
        const columns = [
            {
                dataField: "nmscCode",
                text: I18n.t("Table.nmsc"),
                editable: false,
                sort: true,
                style: { width: "30%", textAlign: "center", display: "inline-table"},
                headerStyle: { width: "30%", textAlign: "center" },
                formatter: this.nmscFormatter,
                title: true
            },
            {
                dataField: "target",
                text: I18n.t("Table.target"),
                editable: this.state.ceaAdminRole || this.state.ceaBoRole && data.length && data[0].isEditable === true ? true : false,
                // editable:true,
                // sort: true,
                style: { width: "17%", textAlign: "center"},
                headerStyle: { width: "16%", textAlign: "center" },
                formatter: this.targetFormatter,
                title: true
            },
            {
                dataField: "unit",
                text: I18n.t("kpiEntry.unit"),
                editable: false,             
                sort: true,
                style: { width: "10%", textAlign: "center", display: "inline-table" },
                headerStyle: { width: "10%", textAlign: "center" },
                formatter: this.unitFormatter,
                title: true
            },
            {
                dataField: "status",
                text: I18n.t("Table.status"),
                editable: false,
                sort: true,
                style: { width: "18%", textAlign: "center", display: "inline-table" },
                headerStyle: { width: "18%", textAlign: "center" },
                formatter: this.statusFormatter,
                title: true
            },           
            {
                dataField: "",
                text: I18n.t("uploadBulk.action"),
                editable: false,
                style: { width: "25%", textAlign: "center", display: "inline-table" },
                headerStyle: { width: "25%", textAlign: "center" },
                formatter: this.buttonFormatter,
                title: true
            }
        ];
        return(<div>
            <div className = "col-md-12 listDealerContainer">
                <Col md="12" className="activityTableContainer">
                    <div className="targetSetting activity-content">
                        <div className="col-md-12 row control-label noPadding">
                            <div className="col-md-12">                                        
                                <BootstrapTable
                                    keyField="kpiEvaluationId"
                                    noDataIndication={I18n.t('dashboard.nodata')}
                                    data={data}
                                    columns={columns}
                                    hover
                                    condensed
                                    rowEvents={rowEvents}
                                    cellEdit={cellEditFactory({
                                        mode: 'click',
                                        blurToSave: true,
                                        beforeSaveCell(oldValue, newValue, row, column, done) {
                                            setTimeout(() => {
                                                if(row.oldTarget === undefined){
                                                    row.oldTarget = oldValue;
                                                }
                                                // if (row.status === "ACCEPTED" || row.status === "Accepted") {
                                                //     toastr.error(I18n.t("ServerMessage.targetUpdate")); 
                                                //     done(false);
                                                // } 
                                                else {                                                    
                                                    let val = newValue.trim();
                                                    var Number = /^\d*\.?\d{0,6}$/;
                                                    if ((Number.test(val) && val !== "") || val === "-") {
                                                        row.target = newValue; 
                                                        done(true);
                                                    }else{
                                                        toastr.error(I18n.t("CeaKpi.targetIncorrect")); 
                                                        done(false)
                                                    }   
                                                }
                                            }, 0);
                                            return { async: true };
                                            }
                                    })}
                                />
                                <div className="col-md-12 input-group control-label">
                                { this.state.ceaAdminRole || this.state.ceaBoRole && data.length && data[0].isEditable === true ?
                                    <div className="col-md-12" style={{ padding: '0px' }}>
                                        <button
                                            className={"button-" + this.props.theme.name}
                                            onClick={this.toggleSave}
                                            style = {{float : 'right' }} 
                                            >
                                            {I18n.t("tmeAdminMaster.save")}
                                        </button>
                                        <button
                                            className={"buttonCancel-" + this.props.theme.name}
                                            onClick={this.toggleCancel}
                                            style = {{float : 'right' }} 
                                            >
                                            {I18n.t("tmeAdminMaster.cancel")}
                                        </button>                        
                                    </div>
                                    : "" }
                                </div>
                            </div>
                        </div>
                    </div>
                </Col>                 
            </div>
        </div>);
    }

    render(){
        return (
            <div className="treeBeard">
                {this.state.questionLeftPane.length !== 0?
                    < Treebeard
                        {...this.props}
                        data={this.state.questionLeftPane}
                        onToggle={this.onToggle}
                        decorators={decorators}
                    />:""
                }  
                 {this.state.toggleComments ? 
                    <AddComments 
                        toggleComments={this.toggleCommentsAcceptRejectLaunch} 
                        toggleCommentsCancel={this.toggleCommentsCancel} 
                        toggleReject={this.state.toggleReject} 
                        submitComment={this.submitComment}
                        kpiEvaluationId={this.state.row.kpiEvaluationId}
                        isCommentSave={(this.state.ceaNmscUser || this.state.ceaCeaUser || (this.state.ceaBoRole && this.state.row.isEditable===false)) ? false : true}
                    /> : ""}              
            </div>            
        );
    }
}

const mapStateToProps = state => {
    return {
        user: state.loginReducer,
        searchTargetDetails: state.userManagementReducer.searchTargetDetails,  
    };
};

export default withRouter(
    connect(mapStateToProps)(withTheme(TargetSetting))
);
