import axios from "axios";
import { toastr } from "react-redux-toastr";
import FileDownload from "js-file-download";
import { searchActivity, fetchActivityDetails, fetchActionPlans } from "./activityAction.jsx";
import { getCallWithReducer, postCallWithReducer } from "./commonAction";
import { getToken } from '../oidc/AuthService';
var I18n = require("react-redux-i18n").I18n;

/**
 * Fetch kpi List from server.
 */
// export function fetchMasterKpiList(packageProgramMappingId, flag) {
//   return function (dispatch) {
//     //dispatch(setBlockingState(true));
//     axios
//       .get("/kpi/" + packageProgramMappingId + "/masterKPI/" + flag, {
//         headers: {
//           "Content-type": "application/json",
//           Accept: "application/json",
//           Pragma: "no-cache"
//         }
//       })
//       .then(response => {
//         //dispatch(setBlockingState(false));
//         dispatch({
//           type: "MASTER_KPI_DATA",
//           payload: response.data
//         });
//       })
//       .catch(function (error) {
//         //dispatch(setBlockingState(false));
//         console.error(error);
//       });
//   };
// }

export function fetchMasterKpiList(packageProgramMappingId, flag) {
  return function (dispatch) {
  dispatch(getCallWithReducer("/kpi/" + packageProgramMappingId + "/masterKPI/"+flag,null,"MASTER_KPI_DATA"))
  };
}

/**
 * Fetch local kpi List from server.
 */
// export function fetchNmscList(toolboxId, userMdl) {
//   return function (dispatch) {
//     //dispatch(setBlockingState(true));
//     axios
//       .get(
//         "/kpi/" + toolboxId + "/localKPI/" + userMdl.nmsc + "/" + userMdl.lang,
//         {
//           headers: {
//             "Content-type": "application/json",
//             Accept: "application/json",
//             Pragma: "no-cache"
//           }
//         }
//       )
//       .then(response => {
//         //dispatch(setBlockingState(false));
//         dispatch({
//           type: "LOCAL_KPI_DATA",
//           payload: response.data
//         });
//       })
//       .catch(function (error) {
//         //dispatch(setBlockingState(false));
//         console.error(error);
//       });
//   };
// }


export function fetchNmscList(toolboxId, userMdl) {
  return function (dispatch) {
  dispatch(getCallWithReducer("/kpi/" + toolboxId + "/localKPI/" + userMdl.nmsc + "/" + userMdl.lang,null,"LOCAL_KPI_DATA"))
  };
}
/**
 * Save/update kpi on DB.
 */
export function saveKpi(kpiDto) {
  return function (dispatch) {
    //dispatch(setBlockingState(true));
    kpiDto.createdTimestamp = new Date();

    axios
      .post("/kpi/masterKPI", kpiDto, {
        headers: {
          'Authorization': 'Bearer ' + getToken(),
          "Content-type": "application/json",
          Accept: "application/json",
          Pragma: "no-cache"
        }
      })
      .then(response => {
        //dispatch(setBlockingState(false));
        if (response.data.statusCode === "400") {
          toastr.error(I18n.t("toastr.duplicate"));
        } else {
          dispatch({
            type: "SAVE_KPI_DATA",
            payload: response.data
          });
        }

        dispatch(fetchMasterKpiList(kpiDto.packageProgramMappingId, true));
      })
      .catch(function (error) {
        //dispatch(setBlockingState(false));
        console.error(error);
      });
  };
}

/**
 * Save/update local kpi on DB.
 */
export function importLocalKpi(masterKpiIds, toolboxId, userMdl) {
  return function (dispatch) {
    //dispatch(setBlockingState(true));

    axios
      .post(
        "/kpi/localKPI/" + userMdl.nmsc + "/" + userMdl.lang,
        masterKpiIds,
        {
          headers: {
            'Authorization': 'Bearer ' + getToken(),
            "Content-type": "application/json",
            Accept: "application/json",
            Pragma: "no-cache"
          }
        }
      )
      .then(response => {
        //dispatch(setBlockingState(false));
        dispatch({
          type: "IMPORT_LOCAL_KPI_DATA",
          payload: response.data
        });
        dispatch(fetchNmscList(toolboxId, userMdl));
      })
      .catch(function (error) {
        //dispatch(setBlockingState(false));
        console.error(error);
      });
  };
}

/**
 * Delete kpi from DB.
 */
export function deleteKpi(packageProgramMappingId, masterKpiId) {
  return function (dispatch) {
    //dispatch(setBlockingState(true));
    axios
      .delete("/kpi/" + packageProgramMappingId + "/masterKPI/" + masterKpiId, {
        headers: {
          'Authorization': 'Bearer ' + getToken(),
          "Content-type": "application/json",
          Accept: "application/json",
          Pragma: "no-cache"
        }
      })
      .then(response => {
        //dispatch(setBlockingState(false));
        dispatch({
          type: "DELETE_KPI_DATA",
          payload: response.data
        });
        dispatch(fetchMasterKpiList(packageProgramMappingId, true));
      })
      .catch(function (error) {
        //dispatch(setBlockingState(false));
        console.error(error);
      });
  };
}

/**
 * Publish selected kpis.
 */
export function publishKPIs(masterKpiIdList, packageProgramMappingId, callback) {
  return function (dispatch) {
    //dispatch(setBlockingState(true));
    axios
      .put("/kpi/masterKPI/publish", masterKpiIdList, {
        headers: {
          'Authorization': 'Bearer ' + getToken(),
          "Content-type": "application/json",
          Accept: "application/json",
          Pragma: "no-cache"
        }
      })
      .then(response => {
        //dispatch(setBlockingState(false));
        dispatch({
          type: "PUBLISH_KPI",
          payload: response.data
        });
        dispatch(fetchMasterKpiList(packageProgramMappingId, true));
        callback();
      })
      .catch(function (error) {
        //dispatch(setBlockingState(false));
        console.error(error);
      });
  };
}

/**
 * Delete Local kpi from DB.
 */
export function deleteLocalKpi(toolboxId, localKpiId, userMdl) {
  return function (dispatch) {
    //dispatch(setBlockingState(true));
    axios
      .delete("/kpi/localKPI/" + localKpiId, {
        headers: {
          'Authorization': 'Bearer ' + getToken(),
          "Content-type": "application/json",
          Accept: "application/json",
          Pragma: "no-cache"
        }
      })
      .then(response => {
        if (response.data.statusCode === "206") {
          toastr.error(I18n.t("tabsDetails.errorRetireMsg"));
        }
        //dispatch(setBlockingState(false));
        dispatch(fetchNmscList(toolboxId, userMdl));
      })
      .catch(function (error) {
        //dispatch(setBlockingState(false));
        console.error(error);
      });
  };
}

/**
 * Publish selected local kpis.
 */
export function publishLocalKPIs(toolboxId, userMdl,saveActivityDTO) {
  return function (dispatch) {
    //dispatch(setBlockingState(true));
    axios
      .put("/kpi/localKPI/publish",saveActivityDTO, {
        headers: {
          'Authorization': 'Bearer ' + getToken(),
          "Content-type": "application/json",
          Accept: "application/json",
          Pragma: "no-cache"
        }
      })
      .then(response => {
        //dispatch(setBlockingState(false));
      
        dispatch({
          type: "PUBLISH_KPI",
          payload: response.data
        });

       /*
        *   Jira: KETVC-2492
        *   Descriptin: Automatic creation of activities for BRiT to be suppressed
        */
        // if (userMdl.activeProgram === "BRiT") {
        //   toastr.success(response.data.desc+" "+I18n.t('activity.activityCreated'));
        // }
        dispatch(fetchNmscList(toolboxId, userMdl));
    
      
      })
      .catch(function (error) {
        //dispatch(setBlockingState(false));
        console.error(error);
      });
  };
}

/**
 * Fetch kpi Units List from server.
 */

export function getUnitsList() {
  return function (dispatch) {
  dispatch(getCallWithReducer("/kpi/units",null,"UNITS"))
  };
}
/**
 * Fetch kpi List from server.
 */
export function getEaUnitsList() {
  return function (dispatch) {
  dispatch(getCallWithReducer("/kpi/eaUnits",null,"EAUNITS"))
  };
}


/**
 * Fetch All local Language kpi List from server.
 */
export function fetchAllNmscLangList(masterKpiId, localRefId, userMdl) {
  return function (dispatch) {
  dispatch(getCallWithReducer("/kpi/" + masterKpiId + "/allLocalLangKPI/" + localRefId +  "/" +
        userMdl.nmsc,null,"LOCAL_LANG_KPI_DATA"))
  };
}


/**
 * Save/update Local kpi on DB.
 */
export function saveLocalKpi(kpiDto, toolboxId, userMdl, this_) {
  return function (dispatch) {

    //dispatch(setBlockingState(true));

    axios
      .post("/kpi/localKPI/save", kpiDto, {
        headers: {
          'Authorization': 'Bearer ' + getToken(),
          "Content-type": "application/json",
          Accept: "application/json",
          Pragma: "no-cache"
        }
      })
      .then(response => {
        if(response.data.desc==="KPI_PRESENT_IN_ONGOING/CLOSED_ACTIVITY"){
        //   dispatch({
        //     type:  'KPI_PRESENT_IN_ONGOING/CLOSED_ACTIVITY',
        //     payload: true
        // });
        
        console.log("Payload------ sent to reducer")
        console.log("KPI_PRESENT_IN_ONGOING/CLOSED_ACTIVITY--KPI cannot be updated");
        toastr.error(I18n.t("toastr.KPIinOngoingClosedActivity"))
        }
       
        //dispatch(setBlockingState(false));
        if (response.data.statusCode === "400") {
          toastr.error(I18n.t("toastr.duplicate"));
        } else {
          this_.props.popUpClose(this_.props.modalFor);
          dispatch({
            type: "SAVE_KPI_DATA",
            payload: response.data
          });
        }
        dispatch(fetchNmscList(toolboxId, userMdl));
      })
      .catch(function (error) {
        //dispatch(setBlockingState(false));
        console.error(error);
      });
  };
}

/**
 * Fetch All local Language kpi List from server.
 */
export function fetchEmpltyNmscLangList() {
  return function (dispatch) {
    dispatch({
      type: "LOCAL_LANG_KPI_DATA",
      payload: null
    });
  };
}

export function getQuestionnaire(filePath) {
  return function (dispatch) {

    filePath = filePath.replace(/\//g, '^');
    filePath = filePath.replace(/\./g, '*');
    //dispatch(setBlockingState(true));
    axios({
      method: "get",
      contentType: "application/octet-stream",
      url:
        "/questionnaire/download/" + filePath,
      responseType: "arraybuffer"
    })
      .then(function (response) {
        //dispatch(setBlockingState(false));
        FileDownload(response.data, decodeURI(response.headers["filename"].replace(/\+/g, " ")));
      })
      .catch(function (error) {
        //dispatch(setBlockingState(false));
        console.error(error);
      });
  };
}

export function getFileName(activityId, toolBoxName, nmsc, lang) {
  return function (dispatch) {
    var toolBoxName = toolBoxName.replace(/_/g, ' ');
  dispatch(getCallWithReducer( "/questionnaire/filename/" + activityId + "/" + toolBoxName + "/" + nmsc +
    "/" + lang,null,"KPI_FILE_NAME"))
  };
}

export function getToolBoxFileList(toolBoxName, loggedInUserNmsc, isTMEUser) {
  return function (dispatch) {
    var toolBoxName = toolBoxName.replace(/_/g, ' ');
  dispatch(getCallWithReducer("/questionnaire/toolboxfilelist/" + toolBoxName + "/" + loggedInUserNmsc + "/" + isTMEUser,null,"TME_FILE_NAME_LIST"))
  };
}


/**
 * update Activity on DB.
 */
export function updateActivity(nmscList, toolboxId, userMdl) {
  return function (dispatch) {
    //dispatch(setBlockingState(true));

    axios
      .post("/kpi/activity/save", nmscList, {
        headers: {
          'Authorization': 'Bearer ' + getToken(),
          "Content-type": "application/json",
          Accept: "application/json",
          Pragma: "no-cache"
        }
      })
      .then(response => {
        //dispatch(setBlockingState(false));
        if (response.data.statusCode === "400") {
          toastr.error(I18n.t("toastr.master_kpi_saved"));
        } else {
          dispatch({
            type: "SAVE_ACTIVITY_DATA",
            payload: response.data
          });
        }
        dispatch(fetchNmscList(toolboxId, userMdl));
      })
      .catch(function (error) {
        //dispatch(setBlockingState(false));
        console.error(error);
      });
  };
}

export function fetchSubQuestions(questionId) {
  return function (dispatch) {
  dispatch(getCallWithReducer("/question/tmeSubQuestionnaire/" + questionId,null,"GET_SUB_QUESTIONS"))
  };
}

export function fetchNmscSubQuestions(questionId, isPublished) {

  return function (dispatch) {
    //dispatch(setBlockingState(true));
    var url = "/question/nmscSubQuestionnaire/" + questionId;
    if (isPublished) {
      url = "/question/nmscPublishedSubQuestionnaire/" + questionId;
    }
    axios
      .get(url, {
        headers: {
          'Authorization': 'Bearer ' + getToken(),
          "Content-type": "application/json",
          Accept: "application/json",
          Pragma: "no-cache"
        }
      })
      .then(response => {
        //dispatch(setBlockingState(false));

        dispatch({
          type: "GET_NMSC_SUB_QUESTIONS",
          payload: response.data
        });
      })
      .catch(function (error) {
        //dispatch(setBlockingState(false));
        console.error(error);
      });
  };
}
export function fetchQuestionContent(chapterId) {
  return function (dispatch) {
    //dispatch(setBlockingState(true));
    axios
      .get("/question/tmeQuestionnaire/" + chapterId, {
        headers: {
          'Authorization': 'Bearer ' + getToken(),
          "Content-type": "application/json",
          Accept: "application/json",
          Pragma: "no-cache"
        }
      })
      .then(response => {
        //dispatch(setBlockingState(false));

        dispatch({
          type: "MAIN_QUESTION_CONTENT",
          payload: response.data
        });
      })
      .catch(function (error) {
        //dispatch(setBlockingState(false));
        console.error(error);
      });
  };
}

export function fetchNmscQuestionContent(chapterId, isPublished) {

  return function (dispatch) {
    var url = "/question/nmscQuestionnaire/" + chapterId;
    if (isPublished) {
      url = "/question/nmscPublishedQuestionnaire/" + chapterId;
    }
    //dispatch(setBlockingState(true));
    axios
      .get(url, {
        headers: {
          'Authorization': 'Bearer ' + getToken(),
          "Content-type": "application/json",
          Accept: "application/json",
          Pragma: "no-cache"
        }
      })
      .then(response => {
        //dispatch(setBlockingState(false));

        dispatch({
          type: "NMSC_MAIN_QUESTION_CONTENT",
          payload: response.data
        });
      })
      .catch(function (error) {
        //dispatch(setBlockingState(false));
        console.error(error);
      });
  };
}
export function fetchChapterSequence(chapterId) {
  return function (dispatch) {
    if (chapterId) {
      //dispatch(setBlockingState(true));
      axios
        .get("/question/tmeChapterSeq/" + chapterId, {
          headers: {
'Authorization': 'Bearer ' + getToken(),
            "Content-type": "application/json",
            Accept: "application/json",
            Pragma: "no-cache"
          }
        })
        .then(response => {
          //dispatch(setBlockingState(false));

          dispatch({
            type: "MAIN_CHAPTER_SEQ",
            payload: response.data
          });
        })
        .catch(function (error) {
          //dispatch(setBlockingState(false));
          console.error(error);
        });
    }
    else {
      dispatch({
        type: "MAIN_CHAPTER_SEQ",
        payload: null
      });
    }
  };
}

export function fetchNmscChapterSequence(chapterId, isPublished) {
  return function (dispatch) {
    if (chapterId) {
      //dispatch(setBlockingState(true));
      axios
        .get("/question/nmscChapterSeq/" + chapterId,{
          headers: {
            'Authorization': 'Bearer ' + getToken(),
            "Content-type": "application/json",
            Accept: "application/json",
            Pragma: "no-cache"
          }
        })
        .then(response => {
          //dispatch(setBlockingState(false));

          dispatch({
            type: "NMSC_MAIN_CHAPTER_SEQ",
            payload: response.data
          });
        })
        .catch(function (error) {
          //dispatch(setBlockingState(false));
          console.error(error);
        });
    }
    else {
      dispatch({
        type: "NMSC_MAIN_CHAPTER_SEQ",
        payload: null
      });
    }
  };
}

export function saveActivity(activityDTO, activityDto1, this_) {
  return function (dispatch) {
    //dispatch(setBlockingState(true));
    var valueAssignee = this_.state.valueAssignee;
    if (valueAssignee === "") {
      valueAssignee = "noAssignee";
    }
    axios
      .post("/activity/save/" + valueAssignee, activityDTO, {
        headers: {
          'Authorization': 'Bearer ' + getToken(),
          "Content-type": "application/json",
          Accept: "application/json",
          Pragma: "no-cache"
        }
      })
      .then(response => {
        //dispatch(setBlockingState(false));
        this_.toChangeStatusFlag(true);
        if (response.data.desc === "activity_invalid_dealer") {
          toastr.error(I18n.t("toastr.dealerValidation"));
          return;
        }
        if (response.data.desc === "activity_invalid_assignee") {
          toastr.error(I18n.t("toastr.assigneeValidation"));
          return;
        }
        if (response.data.desc === "no_published_kpi_for_activity") {
          toastr.error(I18n.t("toastr.noPublishKpi"));
        } else if (response.data.desc === "duplicate_activity") {
          toastr.error(I18n.t("toastr.activityDuplicate"));
        } else if(response.data.desc === "activity_submited_for_certificate_dealer"){
          toastr.error(I18n.t("toastr.activity_submited_for_certificate_dealer"));

        }
        
        else {
          dispatch({
            type: "SAVE_ACTIVITY_DATA",
            payload: response.data
          });
          this_.props.toggle(true);
          dispatch(fetchActionPlans(activityDTO.activityId));
          if (this_.props.goTo === "actionPlan") {
            dispatch(fetchActivityDetails(activityDTO.activityId, activityDTO, activityDto1));
          } else {
            dispatch(searchActivity(activityDto1));
          }
        }
       // dispatch(
        //   (activityDTO.dealerId, activityDTO.userId, activityDTO.toolboxId)
        // );
      })
      .catch(function (error) {
        //dispatch(setBlockingState(false));
        console.error(error);
      });
  };
}