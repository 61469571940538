// import { push } from "connected-react-router";
import axios from "axios";
import { setBlockingState } from "./loginAction.jsx";
import { toastr } from "react-redux-toastr";
import { getToken } from '../oidc/AuthService';
var I18n = require("react-redux-i18n").I18n;


export function fetchKpiEntryFilters(kpiEntrySearchDTO, searchFor) {
    return function (dispatch) {
      //dispatch(setBlockingState(true));
      axios
        .post("/kpiEntry/filters/" + searchFor, kpiEntrySearchDTO, {
          headers: {
            "Authorization": `Bearer ` + getToken(),
            "Content-type": "application/json",
            Accept: "application/json",
            Pragma: "no-cache"
          }
        })
        .then(response => {
          var type =
            searchFor === "nmscCode"
              ? "NMSC_CODE_DATA"
              : searchFor === "brand"
                ? "KPI_BRAND_DATA"
                  : searchFor === "businessFunction"
                    ? "BUSINESS_FUNCTION_DATA"
                    : searchFor === "kpi"
                      ? "KPI_DATA"
                      : searchFor === "subPillar"
                        ? "SUBPILLAR_DATA"  
                        : searchFor === "activityStatus"
                        ? "ACTIVITY_STATUS"
                        : ""
  
          //dispatch(setBlockingState(false));
          dispatch({
            type: type,
            payload: response.data
          });
        })
        .catch(function (error) {
          //dispatch(setBlockingState(false));
          toastr.error(I18n.t("kpiEntry.serverErrorMsg"));
        });
    };
  }


  export function searchBulkKpiDetails(kpiEntrySearchDTO,searchFor){
    return function (dispatch) {
      //dispatch(setBlockingState(true));
       axios
         .post("/kpiEntry/search/"+searchFor ,kpiEntrySearchDTO, {
           headers: {
            "Authorization": `Bearer ` + getToken(),
             'Access-Control-Allow-Origin': '*',
             "Content-type": "application/json",
             Accept: "application/json",
             Pragma: "no-cache"
           }
         })
         .then(response => {
           dispatch(setBlockingState(false));
           console.log(response.data)

           if(response.data.length === 0){
            //toastr.error(I18n.t("kpiEntry.standardMessage"));
           }
           if(searchFor === 'KpiActuals'){
            dispatch({
              type: "KPI_ACTUALS_DETAILS",
              payload: response.data
            })
           }else{
            dispatch({
              type: "BULK_KPI_ENTRY_DETAILS",
              payload: response.data
            })
           }
         
  
         })
         .catch(function (error) {
           dispatch(setBlockingState(false));
           toastr.error(I18n.t("kpiEntry.serverErrorMsg"));
         });
     };
    
  }



  export function saveBulkKpiActuals(saveKpiBulkActualsDTO,kpiEntrySearchDTO,saveFor, completeStatusFlag){
    return function (dispatch) {
     // dispatch(setBlockingState(true));
       axios
         .put("/kpiEntry/saveKpiBulkActuals" ,saveKpiBulkActualsDTO, {
           headers: {
            "Authorization": `Bearer ` + getToken(),
             'Access-Control-Allow-Origin': '*',
             "Content-type": "application/json",
             Accept: "application/json",
             Pragma: "no-cache"
           }
         })
         .then(response => {
            dispatch(setBlockingState(false));
            console.log(response.data)
            toastr.success(I18n.t("kpiEntry.actualsDataSaved"));
            dispatch(searchBulkKpiDetails(kpiEntrySearchDTO,saveFor));
            if(completeStatusFlag){
              let ongoingActivitiesList = [kpiEntrySearchDTO.activityId]
              dispatch(completeEvaluation(ongoingActivitiesList));
            }
         })
         .catch(function (error) {
           dispatch(setBlockingState(false));
           toastr.error(I18n.t("kpiEntry.serverErrorMsg"));
         });
     };
    
  }

  export function completeEvaluation(ongoingActivitiesList) {
    return function (dispatch) {
      axios
        .post("/activity/closeEvaluationUpdate", ongoingActivitiesList, {
          headers: {
            "Authorization": `Bearer ` + getToken(),
            "Content-type": "application/json",
            Accept: "application/json",
            Pragma: "no-cache"
          }
        })
        .then(response => {})
        .catch(function (error) {
          toastr.error(I18n.t("UserManagement.error_message"));
        });
    };
  }

  export function saveMonthlyComment(saveCommentDTO) {
    return function (dispatch) {
      axios
        .post("/kpiEntry/saveMonthlyComment", saveCommentDTO, {
          headers: {
            "Authorization": `Bearer ` + getToken(),
            "Content-type": "application/json",
            Accept: "application/json",
            Pragma: "no-cache"
          }
        })
        .then(response => {})
        .catch(function (error) {
          toastr.error(I18n.t("UserManagement.error_message"));
        });
    };
  }
  
  

  export function searchEmptyCEASearchFilters() {
    return function (dispatch) {
      dispatch({
        type: "FETCH_TARGET_REFRESH",
        payload: null
      });    
    };
  }

export function restDataKpiEntry(){
  return function (dispatch) {
    dispatch({
      type: "BULK_KPI_ENTRY_DETAILS",
      payload: []
    })
  }
}

export function getMonthlyCommentHistory(activityId) {
  return function (dispatch) {
    //dispatch(setBlockingState(true));
    axios
      .get("/kpiEntry/getCommentHistory/" + activityId, {
        headers: {
          "Authorization": `Bearer ` + getToken(),
          Accept: "application/json",
          Pragma: "no-cache"
        }
      })
      .then(response => {
        console.log(response)
        dispatch(setBlockingState(false));
        dispatch({
          type: "KPI_MONTHLY_COMMENT",
          payload: response.data
        });
      })
      .catch(function (error) {
        dispatch(setBlockingState(false));
        console.error(error);
      });
  };
}

export function getCurrentMonthLatestComment(activityId, monthName) {
  return function (dispatch) {
    //dispatch(setBlockingState(true));
    axios
      .get("/kpiEntry/getCurrentMonthLatestComment/" + activityId+"/"+ monthName, {
        headers: {
          "Authorization": `Bearer ` + getToken(),
          Accept: "application/json",
          Pragma: "no-cache"
        }
      })
      .then(response => {
        console.log(response)
        dispatch(setBlockingState(false));
        dispatch({
          type: "KPI_CURRENT_MONTHLY_COMMENT",
          payload: response.data
        });
      })
      .catch(function (error) {
        dispatch(setBlockingState(false));
        console.error(error);
      });
  };
}

   
