import React from 'react';
import { connect } from 'react-redux';
import { Container, Row, Col } from 'reactstrap';
import { Tabs, Tab } from 'react-bootstrap-tabs';
import { withRouter } from 'react-router-dom';
import ActivityModal from '../activityModal';
import ActionKPITable from '../actionKpiTable';
import ActionPlanTable from '../actionPlanTable';
import { getQuestionnaire } from '../../actions/tabAction.jsx';
import { fetchActivityKpis, searchActivity ,saveVisibilityOnUpdate} from '../../actions/activityAction.jsx';
import { withTheme } from '../../themes/theming';
import { push } from "connected-react-router";
import Evaluation from '../evaluation';
import { getUserDetails } from "../../actions/loginAction";
import './index.css';
var I18n = require('react-redux-i18n').I18n;
var baseUrl="/ket/v1/questionnaire/download/";
//var baseUrl="http://localhost:9090/ket/v1/questionnaire/download/";

class ActionPlan extends React.Component {
    constructor(props) {
        super(props);
        
        if (
            this.props.user.userMdl === undefined ||
            this.props.user.userMdl === null ||
            this.props.user.userMdl.expired === undefined ||
            this.props.user.userMdl.expired
        ) {
            // get user Details from token.
            this.props.dispatch(getUserDetails());
        }
        // console.log(JSON.parse(this.props.location.query.selectedActivity).updateViewed);
  
        this.state = {
            selectedRow: {
                activityId: this.props.location.query.selectedActivity.activityId,
                dealerId: "" + this.props.location.query.selectedActivity.dealerId,
                assignee:  this.props.location.query.assignee,
                dealer: "",
                city: "",
                userId: "" + this.props.location.query.selectedActivity.userId,
                toolbox: this.props.location.query.selectedActivity.toolbox,
                toolboxId: this.props.location.query.selectedActivity.toolboxId,
                firstVisit: this.props.location.query.selectedActivity.firstVisit,
                completionOn: this.props.location.query.selectedActivity.completionOn,
                updatedTimestampStr: "",
                createdBy: "",
                status: this.props.location.query.selectedActivity.status,
                reminderOn: "",
                userMdl: "",
                nmscCode: "",
                langCode: "",
                loginId: "",
                updateViewed:JSON.parse(this.props.location.query.selectedActivity).updateViewed,
                kpiUpdated:JSON.parse(this.props.location.query.selectedActivity).kpiUpdated,
                questionnaireUpdated:JSON.parse(this.props.location.query.selectedActivity).questionnaireUpdated,
                actionPlanUpdated:JSON.parse(this.props.location.query.selectedActivity).actionPlanUpdated,
                firstAudit:this.props.location.query.selectedActivity.firstAudit

            },
            translatedStatus: "",
            toolboxKey: null,
            toolboxLocation: "",
            isLoading: false,
            isNmsc: false,
        };
      
        this.viewStatus =this.viewStatus.bind(this);
    }

    

   
    
    componentWillMount() {
       
        var isNMSCAdminUser = false;
        if (this.props.user.userMdl.roles !== undefined) {
          var roles = this.props.user.userMdl.roles;
          for (var i = 0; i < roles.length; i++) {
            if (roles[i].toUpperCase().indexOf("KET NMSC ADMIN") !== -1) {
              isNMSCAdminUser = true;
              break;
            }
          }
        }
        this.setState({ isNmsc: isNMSCAdminUser });

        if (
            this.props.user.userMdl === undefined ||
            this.props.user.userMdl === null ||
            this.props.user.userMdl.expired === undefined ||
            this.props.user.userMdl.expired
        ) {
            // get user Details from token.
            this.props.dispatch(getUserDetails());
        }

        var row = JSON.parse(this.props.location.query.selectedActivity);
        // console.log(this.props.user.userMdl);
        // console.log(this.state.selectedRow)
        this.setState({ selectedActivity: row });
        this.props.dispatch(fetchActivityKpis(row.activityId, this.props.user.userMdl.lang, this.props.user.userMdl.nmsc, this.props.user.userMdl.activeProgramID, this.state.selectedRow, this.props.location.query.searchCriteria));
        // this.props.dispatch(getFileName(row.activityId, row.toolbox, this.props.user.userMdl.nmsc, this.props.user.userMdl.lang));
    
   
    }

    
     

    labelForKpi =()=>{
        console.log(this.props.user.userMdl );
        var tabdetail = I18n.t('tabsDetails.kpi');
        var evidence_class =  this.state.isNmsc===true ?(this.state.selectedRow.updateViewed  && this.state.selectedRow.updateViewed==="Y" && this.state.selectedRow.kpiUpdated==="True") || ( this.state.selectedRow.kpiUpdated==="True") ?"evidenceUploaded":"evidenceNotUploaded":"" ;
        return (<div>{tabdetail}<span className={evidence_class}></span></div>);
    }
    
    labelForQuestionnaire =()=>{
        // console.log(actionPlanUpdated );
          return (<div> {this.props.user.userMdl.businessAreaKey==="TSP"? "1st Audit": I18n.t("tabsDetails.questionnaire") }
         <span className={this.state.isNmsc===true ?(this.state.selectedRow.updateViewed  && this.state.selectedRow.updateViewed==="Y" && this.state.selectedRow.questionnaireUpdated==="True") || ( this.state.selectedRow.questionnaireUpdated==="True")  ?"evidenceUploaded":"evidenceNotUploaded":""}></span>
        </div>);
    }
    
    labelForActionPlan =()=>{
        return (<div>{this.props.user.userMdl.businessAreaKey==="TSP"? I18n.t("tabsDetails.kaizen") : I18n.t("tabsDetails.actionPlan") }
        <span className={this.state.isNmsc===true ?(this.state.selectedRow.updateViewed  && this.state.selectedRow.updateViewed==="Y" && this.state.selectedRow.actionPlanUpdated==="True") || ( this.state.selectedRow.actionPlanUpdated==="True") ?"evidenceUploaded":"evidenceNotUploaded":""}></span>
        </div>);
    }

    
    labelForKaizen =()=>{
        return (<div>{this.props.user.userMdl.activeProgramNas==="NAS"? I18n.t("tabsDetails.reevaluation") : "" }
        <span></span>
        </div>);
    }


    componentWillReceiveProps(nextProps) {
        this.setState({ fileName: nextProps.fileName });
        if (nextProps.fileName && nextProps.fileName[0] && nextProps.fileName[0].fileName === "NO_LANG") {
            console.log(" ------- Questioner:This user do not have any language.  ------- ");
            this.setState({ fileName: [] });
        }

        if (nextProps.selectedRow) {
            const selectedRow = { ...this.state.selectedRow };
            selectedRow['activityId'] = nextProps.selectedRow.activityId;
            selectedRow['dealerId'] = "" + nextProps.selectedRow.dealerId;
            selectedRow['dealer'] = "" + nextProps.selectedRow.dealer;
            selectedRow['city'] = "" + nextProps.selectedRow.city;
            selectedRow['userId'] = "" + nextProps.selectedRow.assigneeId;
            selectedRow['toolbox'] = nextProps.selectedRow.toolbox;
            selectedRow['toolboxId'] = nextProps.selectedRow.toolboxId;
            selectedRow['firstVisit'] = nextProps.selectedRow.firstVisit;
            selectedRow['completionOn'] = nextProps.selectedRow.completionDateStr;
            selectedRow['status'] = nextProps.selectedRow.status;
            selectedRow['reminderOn'] = nextProps.selectedRow.reminderOnStr;
            selectedRow['createdBy'] = nextProps.selectedRow.createdBy;
            selectedRow['updatedTimestampStr'] = nextProps.selectedRow.updatedTimestampStr;
            // selectedRow['updateViewed'] = nextProps.selectedRow.updateViewed;

            // selectedRow['userMdl'] = this.props.user.userMdl;
            // selectedRow['nmscCode'] = this.props.user.userMdl.nmsc;
            // selectedRow['langCode'] = this.props.user.userMdl.lang;
            // selectedRow['loginId'] = this.props.user.userMdl.username;
            this.setState({toolboxKey: nextProps.selectedRow.toolboxKey, toolboxLocation:nextProps.selectedRow.location})
            this.setState({ selectedRow: selectedRow, translatedStatus: I18n.t(nextProps.selectedRow.status) });

        
        }

    }
    selectedTab = (index, label) => {
        this.setState({ selectedTab: index })
    }


    viewStatus = (activityData) =>{
        this.setState({ isLoading: true });
        // console.log(activityData);
        var viewStatusDTO = {
            activityId: activityData.activityId,
            loginId: this.props.user.userMdl.username,
            updateViewed:"Y",
            kpiUpdated:"False",
            questionnaireUpdated:"False",
            actionPlanUpdated :"False",

        }
        this.props.dispatch(saveVisibilityOnUpdate(viewStatusDTO,this.props.location.query.searchCriteria));
        // console.log(...this.state.selectedRow);
        var selectedRow = {...this.state.selectedRow}
        selectedRow.updateViewed = "Y";
        selectedRow.kpiUpdated = "False";
        selectedRow.questionnaireUpdated = "False";
        selectedRow.actionPlanUpdated = "False";
        this.setState({selectedRow})
        
    }

    getQuestionnaire(filePath) {
        var row = JSON.parse(this.props.location.query.selectedActivity);
        this.setState({ selectedActivity: row });
        this.props.dispatch(getQuestionnaire(filePath))
    }




    toggleModal = (action) => {
        this.setState({
            modal: !this.state.modal,
            modalFor: action,
            selectedRow: {
                activityId: this.state.selectedRow.activityId,
                dealerId: "" + this.state.selectedRow.dealerId,
                dealer: this.state.selectedRow.dealer,
                city: this.state.selectedRow.city,
                userId: "" + this.state.selectedRow.userId,
                toolbox: this.state.selectedRow.toolbox,
                toolboxId: this.state.selectedRow.toolboxId,
                firstVisit: this.state.selectedRow.firstVisit,
                completionOn: this.state.selectedRow.completionOn,
                createdBy: this.state.selectedRow.createdBy,
                status: this.state.selectedRow.status,
                reminderOn: this.state.selectedRow.reminderOn,
                userMdl: this.props.user.userMdl,
                nmscCode: this.props.user.userMdl.nmsc,
                langCode: this.props.user.userMdl.lang,
                loginId: this.props.user.userMdl.username,
                updatedTimestampStr: this.state.selectedRow.updatedTimestampStr,
                // updateViewed:this.state.selectedRow.updateViewed,
            },
            translatedStatus: I18n.t(this.state.selectedRow.status)
        });
    }



 
    backToActivity = () => {
       // console.log("this.props.location.query.searchCriteria",this.props.location.query.searchCriteria);
        if (this.props.location.query.searchCriteria.program) {
            this.props.dispatch(searchActivity(this.props.location.query.searchCriteria));
            this.props.dispatch(
                push({
                    pathname: "/activity",
                    query: { activityDto: this.props.location.query.searchCriteria }
                })
            );
        }
        else {
            this.props.dispatch(
                push({pathname: "/certification"})
            );
        }
    }
    
    render() {
    
      
        let accessType = '';
        let isEvaluationAccessible = false;
        if (this.props.user.userMdl && this.props.user.userMdl.accessControl) {
            accessType = this.props.user.userMdl.accessControl.filter(obj => obj.function === 'DOWNLOAD_Q')[0] ? this.props.user.userMdl.accessControl.filter(obj => obj.function === 'DOWNLOAD_Q')[0].accessType : null
        }
        
        if (this.props.user.userMdl && this.props.user.userMdl.accessControl) {
            isEvaluationAccessible = this.props.user.userMdl.accessControl.filter(obj => obj.function === 'ACTIVITY_EVALUATION')[0] && (this.props.user.userMdl.activeProgram === "TSM" ||  this.props.user.userMdl.activeProgram === "NAS" || this.props.user.userMdl.activeProgram === "TSM_BNP" || this.props.user.userMdl.activeProgram === "TSM_USED_CAR" || this.props.user.userMdl.activeProgram === "TSM_SURVEYS")? true : false
            console.log(isEvaluationAccessible );
        }



        //this.props.user.userMdl.accessControl.filter(obj => obj.function === 'DOWNLOAD_Q')[0].accessType;
        // let toolbox = this.state.selectedActivity.toolbox;
        let iconClass = "_icon";
        var srt =this.state.toolboxLocation;
        srt=srt.replace(/\//g, '$');
        srt = srt.replace(/\./g, '_red*');
        return (
            <div className="activityDiv">
                <Container className={"b-1 actionPlanContainer actionPlanContainer-" + this.props.theme.name}
                    style={{ minHeight: "70em", backgroundColor: "rgba(247, 247, 247, 1)" }}>
                    <Row className="activityTabsHeaderRed">
                        <Col md="6" style={{ fontSize: "20px", textTransform: 'uppercase' }}>{this.state.selectedRow.dealer}</Col>
                        <Col md="1"></Col>
                        <Col md="5">
                            <div style={{ paddingLeft: "3rem", float: "right", fontSize: "20px", fontWeight: "bold" }}>
                                <img src={baseUrl + srt} style={{ marginRight: "5px"}} className={iconClass} alt=""/>
                                {this.state.selectedRow.toolbox}
                            </div></Col>
                    </Row>
                    <Row className="activityTabsHeader pl-5 pr-5" >
                        <Col md="1">{I18n.t('Table.city')}</Col>
                        <Col md="2">{I18n.t('Table.assignee')}</Col>
                        <Col md="2">{I18n.t('Table.createdBy')}</Col>
                        <Col md="1">{I18n.t('Table.startDate')}</Col>
                        <Col md="2">{I18n.t('Table.completionDate')}</Col>
                        <Col md="1">{I18n.t('Table.status')}</Col>
                        <Col md="2">{I18n.t('Table.updatedOn')}</Col>
                       
                    </Row>
                    <Row className="selectedCriteria activityTabsHeader pl-5 pr-5">
                        <Col md="1">{this.state.selectedRow.city}</Col>
                        <Col md="2">{this.state.selectedRow.assignee}</Col>
                        <Col md="2">{this.state.selectedRow.createdBy}</Col>
                        <Col md="1">{this.state.selectedRow.firstVisit}</Col>
                        <Col md="2">{this.state.selectedRow.completionOn}</Col>
                        <Col md="1">{this.state.translatedStatus}</Col>
                        <Col md="2">{this.state.selectedRow.updatedTimestampStr}</Col>
                        <Col md="1" float="right">
                            {(this.state.selectedRow.status === "Planned" || this.state.selectedRow.status === "Closed" ||
                                this.state.selectedRow.status === "Ongoing") && accessType === "R/W" ?
                                <a onClick={() => this.toggleModal("Edit")} style={{ float: "right" }}><div title={I18n.t('tmeAdminMaster.edit')} className="edit-icon-white" /></a> : ""}
                        </Col>
                    </Row>
                    
                    <Col md="12" className="activityTabs mt-2">
                        {( this.state.isNmsc===true )?this.state.selectedRow.updateViewed && this.state.selectedRow.updateViewed!==null && this.state.selectedRow.updateViewed==="Y" && this.state.selectedRow.actionPlanUpdated!=="True" &&  this.state.selectedRow.kpiUpdated!=="True" && this.state.selectedRow.questionnaireUpdated!=="True" ?    <i class="fa fa-check " alt="Back"   style={{float:"right" ,width:"3%",height:"3%",color:"green"}}></i>:<i class="fa fa-check " alt="Back" onClick={()=>this.viewStatus(this.state.selectedRow)}  style={{float:"right" ,width:"3%",height:"3%",color:"red"}} ></i>:" "}
                 
                        {(this.state.selectedRow.toolboxId === 1) ?
                            !isEvaluationAccessible ?
                                <Tabs onSelect={this.selectedTab} selected={this.state.selectedTab}>
                                    <Tab label={this.labelForActionPlan()}>
                                        <ActionPlanTable activity={this.state.selectedRow} searchCriteria={this.props.location.query.searchCriteria} />
                                    </Tab>
                                </Tabs> :
                                <Tabs onSelect={this.selectedTab} selected={this.state.selectedTab}>
                                    <Tab label={this.labelForQuestionnaire()} >
                                        <Evaluation
                                            toolbox={this.state.selectedRow.toolboxId}
                                            selectTab={this.selectedTab}
                                            activityId={this.state.selectedActivity.activityId} activityStatus={this.state.selectedRow.status} firstAudit ={this.state.selectedRow.firstAudit}/>
                                        <hr />

                                        <img src={'images/back-' + this.props.theme.name + '.png'} alt="Back" onClick={this.backToActivity} style={{ cursor: "pointer" }} />


                                    </Tab>
                                    <Tab label={this.labelForActionPlan()} >

                                        <ActionPlanTable activity={this.state.selectedRow} searchCriteria={this.props.location.query.searchCriteria} />
                                    </Tab>
                                </Tabs>
                                
                                 :
                                 
                            isEvaluationAccessible?
                            this.props.user.userMdl.activeProgram ==="NAS"?
                                <Tabs onSelect={this.selectedTab} selected={this.state.selectedTab}>
                                       <Tab  label={this.labelForQuestionnaire()} > 
                                        <Evaluation
                                            toolbox={this.state.selectedRow.toolboxId}
                                            selectTab={this.selectedTab}
                                            activityId={this.state.selectedActivity.activityId} activityStatus={this.state.selectedRow.status} />
                                        <hr />

                                        <img src={'images/back-' + this.props.theme.name + '.png'} alt="Back" onClick={this.backToActivity} style={{ cursor: "pointer" }} />

                                    </Tab>
                                    <Tab label={this.labelForActionPlan()}>
                                        {/* <ActionPlanTable activity={this.state.selectedRow} searchCriteria={this.props.location.query.searchCriteria} /> */}
                                    </Tab>
                                </Tabs> :
                                <Tabs onSelect={this.selectedTab} selected={this.state.selectedTab} >
                                  
                                    <Tab label={this.labelForKpi()}> 
                                        <ActionKPITable activity={this.state.selectedRow} selectedActivity={this.state.selectedActivity} searchCriteria={this.props.location.query.searchCriteria} />
                                  
                                        </Tab>
                                        
                                    <Tab  label={this.labelForQuestionnaire()} > 
                                        <Evaluation
                                            toolbox={this.state.selectedRow.toolboxId}
                                            selectTab={this.selectedTab}
                                            activityId={this.state.selectedActivity.activityId} activityStatus={this.state.selectedRow.status} />
                                        <hr />

                                        <img src={'images/back-' + this.props.theme.name + '.png'} alt="Back" onClick={this.backToActivity} style={{ cursor: "pointer" }} />

                                    </Tab>
                                    <Tab label={this.labelForActionPlan()}>
                                        <ActionPlanTable activity={this.state.selectedRow} searchCriteria={this.props.location.query.searchCriteria} />
                                    </Tab>
                                </Tabs> :   
                                <Tabs onSelect={this.selectedTab} selected={this.state.selectedTab}>
                                          <Tab  label={this.labelForQuestionnaire()} > 
                                        <Evaluation
                                            toolbox={this.state.selectedRow.toolboxId}
                                            selectTab={this.selectedTab}
                                            activityId={this.state.selectedActivity.activityId} activityStatus={this.state.selectedRow.status} />
                                        <hr />

                                        <img src={'images/back-' + this.props.theme.name + '.png'} alt="Back" onClick={this.backToActivity} style={{ cursor: "pointer" }} />

                                    </Tab>
                                    {/* <Tab label={this.labelForQuestionnaire()} >
                                        <ActionKPITable activity={this.state.selectedRow} selectedActivity={this.state.selectedActivity} searchCriteria={this.props.location.query.searchCriteria} />

                                    </Tab> */}
                                    <Tab label={this.labelForActionPlan()}>
                                        <ActionPlanTable activity={this.state.selectedRow} searchCriteria={this.props.location.query.searchCriteria} />
                                    </Tab>
                                </Tabs>}

                    </Col>
                    {this.state.modal === true ?
                        <ActivityModal
                            modal={this.state.modal}
                            goTo="actionPlan"
                            toggle={this.toggleModal}
                            modalFor={this.state.modalFor}
                            selectedRow={this.state.selectedRow}
                            activityDto={this.props.location.query.searchCriteria} /> : null}
                </Container>
            </div>
        );
    }
}
const mapStateToProps = (state) => {
    return {
        user: state.loginReducer,
        blocking: state.loginReducer,
        router: state.routing,
        fileName: state.tabReducer.fileName,
        selectedRow: state.activityReducer.selectedRow
    }
}
export default withRouter(connect(mapStateToProps)(withTheme(ActionPlan)));