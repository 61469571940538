import React from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import "./index.css";
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import { withTheme } from '../../../themes/theming';
import { fetchPublishedSubQuestions, importAllSubQuestions } from '../../../actions/subQuestionAction';
import { Modal, ModalBody, ModalHeader, ModalFooter } from "reactstrap";
import { toastr } from "react-redux-toastr";
import { confirmAlert } from 'react-confirm-alert'; // Import
var I18n = require('react-redux-i18n').I18n;



class importTMEQuestionnaireModal extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            publishedSubQuestions: [],
            selectedForImport: []
        }
    }

    componentWillMount() {
        this.props.dispatch(fetchPublishedSubQuestions(this.props.toolboxId, this.props.user.userMdl.lang, this.props.user.userMdl.nmsc));
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.publishedSubQuestions) {
            this.setState({ publishedSubQuestions: nextProps.publishedSubQuestions });
        }

    }
    preventDefault() { }
    importNMSCQuestion = () => {
        if (this.state.selectedForImport && this.state.selectedForImport.length > 0) {
            confirmAlert({
                customUI: ({ title, message, onClose }) => {
                    return (
                        <div className="deleteConfirmBox">
                            <div className={"titleClass-" + this.props.theme.name}>
                            {I18n.t("standardMessages.importQuestion")}
                      </div>
                            <div className="modal-footer" style={{ borderTop: "0px" }}>
                                <button className={"buttonCancel-" + this.props.theme.name} onClick={() => onClose()}>
                                    {I18n.t("standardMessages.no")}
                                </button>
                                <button
                                    className={"button-" + this.props.theme.name}
                                    onClick={() => {
                                        this.import();
                                        onClose();
                                    }}
                                >
                                    {I18n.t("standardMessages.yes")}
                                </button>
                            </div>
                        </div>
                    );
                }
            });
           
        }
        else {
            toastr.error(I18n.t('toastr.atleastOneQuestion'));
        }
       
    }

    import() {
        var importSubQuestionnaireDTO = {
            masterSubQuestionnaireList: this.state.selectedForImport,
            username: this.props.user.userMdl.username
        }
        this.props.dispatch(importAllSubQuestions(this.props.toolboxId, this.props.activeTabNmscLang, this.props.user.userMdl.nmsc, importSubQuestionnaireDTO));
        this.props.toggle();
    }

    render() {
        const columns = [{
            dataField: 'seqNum',
            text: I18n.t("questionnaire.subQuestionTable.No"),
            sort: true,
            style: { width: "5%" },
            headerStyle: { width: "6.7%" },

        },
        {
            dataField: 'tmeSubQuestion',
            text: I18n.t("questionnaire.subQuestionTable.subQuestion")+" (TME) ",
            sort: true,
            style: { width: "48%" , textAlign: "left"},
            headerStyle: { width: "48%", textAlign: "left" },

        }
            , {
            dataField: 'nmscSubQuestion',
            text: I18n.t("questionnaire.subQuestionTable.subQuestion")+" (NMSC)" ,
            sort: true,
            style: { width: "47%" , textAlign: "left"},
            headerStyle: { width: "49%", textAlign: "left" },

        }];

        const selectRow = {
            mode: 'checkbox',
            selected: this.state.selectedForImport,
            onSelectAll: (isSelect, rows, e) => {
                let selectedRows = [];
                rows.forEach((row) => {
                    selectedRows.push(row.subQuestionId);
                });
                if (isSelect && selectedRows.length > 0) {
                    this.setState({ selectedForImport: selectedRows });
                    return selectedRows;
                }
                else {
                    this.setState({ selectedForImport: [] });
                }
            }
        };
        const rowEvents = {
            onClick: (e, row, rowIndex) => {
                if (e.target.type === "checkbox") {
                    e.target.checked = true;
                    var index = this.state.selectedForImport.indexOf(row.subQuestionId);
                    if (index > -1) {
                        this.state.selectedForImport.splice(index, 1);
                    } else {
                        this.state.selectedForImport.push(row.subQuestionId);
                    }
                }
            }
        };

        return (
            <Modal
                className={"importQuestionModal importQuestionModal-" + this.props.theme.name}
                isOpen={true}
                toggle={() => this.preventDefault()}
            >
                <ModalHeader toggle={() => this.props.toggle()}>
                    {I18n.t("questionnaire.subQuestion")}
                </ModalHeader>

                <ModalBody>
                    <BootstrapTable
                        keyField='subQuestionId'
                        data={this.state.publishedSubQuestions}
                        columns={columns}
                        selectRow={selectRow}
                        hover
                        condensed
                        rowEvents={rowEvents}
                        noDataIndication={I18n.t('standardMessages.noQuestion')}
                    />
                </ModalBody>
                <ModalFooter>
                    <button
                        className={"buttonCancel-" + this.props.theme.name}
                        onClick={() => this.props.toggle()}
                    >
                        {I18n.t("tmeAdminMaster.cancel")}
                    </button>
                    <button className={'button-' + this.props.theme.name} style={{ fontSize: "1rem" }} onClick={this.importNMSCQuestion}> {I18n.t('tabsDetails.import')} </button>
                </ModalFooter>
            </Modal>
        );
    }
}
const mapStateToProps = (state) => {
    return {
        publishedSubQuestions: state.questionSummaryReducer.publishedSubQuestions,
        user: state.loginReducer,
    }
}
export default withRouter(connect(mapStateToProps)(withTheme(importTMEQuestionnaireModal)));