const translationsIT = {
  it: {
    Policy: {
      HeaderMsg: "Informativa sulla privacy per il trattamento dei dati personali nell'utilizzo dello strumento Kaizen Expert  Tool",
      Message1: "La presente Informativa sulla privacy si riferisce al trattamento dei dati personali dell'utente nell'ambito di utilizzo del Kaizen Expert Tool. Devono sempre essere considerati insieme alla Politica generale sulla privacy e sulla protezione dei dati personali di Toyota in cui sono descritte le modalità generali di trattamento dei dati personali e che è necessario accettare per poter procedere ulteriormente.",
      Message2: "Toyota Motor Europe NV/SA, con sede in Avenue du Bourget/Bourgetlaan 60, 1140 Brussels, Belgio, come Data Controller, raccoglie i dati personali (come indicato di seguito) per lo scopo descritto di seguito.",
      Message3: "Quali dei tuoi dati personali saranno raccolti?",
      Message4: "Raccoglieremo le seguenti categorie di dati personali in riferimento a Kaizen Expert Tool:",
      Table1: {
        tr1: "Categorie di dati personali:",
        tr2: "User Id",
        tr3: "User Name (nome completo)",
        tr4: "Brand (Toyota, Lexus)",
        tr5: "Lingua preferita",
        tr6: "Organizzazione affiliata"
      },

      Message5: "Scopo e norme legali del trattamento dei dati personali.",
      Message6: "Tratteremo i tuoi dati personali per le finalità e in base alle norme legali descritte di seguito:",
      Table2: {
        tr1: {
          th1: "Scopo:",
          th2: "Norme Legali:"
        },
        tr2: {
          td1: "KET – 1: dealer Performance Kaizen",
          td2: "Interesse legittimo perseguito dal titolare dei dati"
        },
        tr3: {
          td1: "KET – 2: Support IT",
          td2: "Interesse legittimo perseguito dal titolare dei dati"
        }
      },
      Message7: "Tempo di mantenimento dei dati personali",
      Message8: "Ii dati personali saranno conservati per la durata indicata di seguito:",
      Table3: {
        tr1: {
          th1: "Durata:",
          th2: "Categorie di dati personali:"
        },
        tr2: {
          td1: "Qualsiasi informazione personale come sopra descritta sarà conservata per un massimo di 5 anni + 1 mese dal giorno della chiusura dello strumento Kaizen Expert Tool.",
          td2: "Nome e cognome dell'utente, lingua preferita e azienda"
        }
      },
      Message9: "Facoltativo: in caso di divulgazione / trasferimento dei dati personali in paesi al di fuori dello Spazio economico europeo (EEA)",
      Message10: "I dati personali saranno resi accessibili ai destinatari nei paesi al di fuori del EEA come indicato di seguito:",
      Table4: {
        tr1: {
          td1: "Identità",
          td2: "Paese",
          td3: ""
        },
        tr2: {
          td1: "Destinatari non-EEA",
          td2: "Infosys",
          td3: "India"
        }
      },
      Message11: "Con la presente confermo di aver compreso questo avviso; Ho letto e accettato la Politica generale sulla privacy e sulla protezione dei dati personali di Toyota."
    },
    ACCESSORIES: "Accessori",
    activity: {
      Assignee: "Assegnatario",
      CompletionDate: "Data di completamento",
      Dealer: "Concessionario",
      FirstVisit: "Prima visita",
      kpi: {
        kpi: "Il KPI può solo essere un numero positivo o negativo",
        kpiLength: "La lunghezza massima per il kpi è 10 caratteri",
        mandatory: "Immettere dati validi in tutti i campi obbligatori",
        mandatoryTargets: "Inserire tutti i KPI",
        target: "Il Target può solo essere un numero positivo o negativo",
        targetLength: "La lunghezza massima per il Target è 10 caratteri"
      },
      ReminderOn: "Avviso attivo",
      statusDD: "Stato",
      Toolbox: "Toolbox",
      activityCreation: "Activity Creation process has been started",
      activityCreated: "activity(s) have been created"
    },
    DPOK: "DPOK",
    EFFICIENCY: "Efficienza",
    FIX_IT_RIGHT: "Fix It Right",
    footer: {
      message: "Campi obbligatori"
    },
    FOUNDATION: "Foundation",
    MAINTENANCE_REMINDERS: "Remind di Manutenzione",
    EMPLOYEE_ENGAGEMENT: "Employee Engagement",
    CUSTOMER_CENTRICITY: "Customer Centricity",
    TOYOTA_WAY: "Toyota Way",
    BUSINESS_SUSTAINABILITY: "Business Sustainability",
    FUNDAMENTALS: "Fundamentals",
    FIX_IT_RIGHT_BnP: "Fix it Right (B&P)",
    UTILIZATION_AND_EFFICIENCY: "Utilization and Efficiency",
    CUSTOMER_EXPERIENCE: "Customer Experience",
    UPSELLING_OPPORTUNITIES: "Upselling Opportunities",
    MANAGING_CAPACITY: "Managing Capacity",
    VEHICLE_TRADE_IN: "Vehicle Trade-In",
    STOCK_MANAGEMENT: "Stock Management",
    REFURBISHMENT: "Refurbishment",
    months: {
      apr: "Apr.",
      aug: "Ago.",
      dec: "Dic.",
      feb: "Feb.",
      jan: "Gen.",
      jul: "Lug.",
      jun: "Giu.",
      mar: "Mar.",
      may: "Mag.",
      nov: "Nov.",
      oct: "Ott.",
      sep: "Set."
    },
    others: {
      back: "Indietro",
      closed: "Chiuso",
      completed: "Completato ",
      countryLanguageCode: "Paese/Codice lingua ",
      createActivity: "Crea attività",
      createdDate: "Data di creazione",
      dataUneditableNote:
        "Nota: I dati una volta salvati non potranno più essere modificati. Per modificare i dati contattare TMI",
      date: "Data",
      downloadQuestionnaire: "Scarica questionario",
      editActivity: "Modifica attività",
      initialHelpText: "La misurazione iniziale del KPI deve corrispondere al valore medio dei sei mesi precedenti",
      inProgress: "In corso",
      name: "Nome",
      next: "Avanti",
      noActivities: "Non sono state trovate attività",
      noResults: "Nessun risultato",
      of: "di",
      ongoing: "Avviato",
      open: "Apri",
      page: "Pagina",
      planned: "Pianificato",
      problemDescription: "Descrizione del problema",
      signature: "Firma",
      select: "Seleziona",
      bulkAction: "Bulk Action",
      bulkActions: "Bulk Actions"
    },
    ServerMessage: {
      error_message: "Errore durante il download.",
      no_published_subquestion: "Pubblicare il questionario dell'attuale toolbox per iniziare l'attività.",
      activity_upload_error_msg: "Errore nel caricamento dei dettagli delle attività.",
      action_plan_created: "Il Piano d'azione è stato creato con successo",
      action_plan_deleted: "Il Piano d'azione è stato cancellato con successo",
      action_plan_saved: "Il Piano d'azione è stato salvato con successo",
      activity_kpi_saved: "I KPI delle attività sono stati salvati con successo",
      master_kpi_confirm_delete: "Sei sicuro di voler cancellare i KPI selezionati?",
      master_kpi_created: "Creazione del Master KPI completata",
      master_kpi_deleted: "Il Master KPI è stato cancellato",
      master_kpi_not_found: "Master KPI non trovato",
      master_kpi_published: "Il Master KPI selezionato è stato pubblicato correttamente",
      master_kpi_retired: "Il Master KPI è stato rimosso",
      master_kpi_saved: "Salvataggio del Master KPI completato",
      subquestion_created: "La domanda è stata salvata correttamente.",
      subquestion_updated: "La domanda è stata aggiornata correttamente.",
      subquestionnaire_not_found: "La domanda non è stata trovata.",
      subquestion_retired: "La domanda è stata ritirata.",
      subquestion_deleted: "La domanda è stata cancellata.",
      subquestion_published: "Le domande sono state pubblicate con successo.",
      mainquestion_created: "La domanda principale è stata creata.",
      mainquestion_deleted: "La domanda principale è stata cancellata.",
      mainquestion_updated: "La domanda principale è stata aggiornata.",
      duplicateMsg: "Documenti duplicati in Excel.",
      deleteErr: "Errore nella cancellazione del file.",
      toolbox_deleted: "Toolbox has been deleted successfully",
      toolbox_already_exists: "This toolbox already exists.",
      toolbox_saved: "Toolbox saved",
      toolbox_created: "Toolbox Created",
      toolbox_not_found: "Toolbox not found",
      published_content_found: "This toolbox cannot be deleted as it has some published content",
      dealers_already_mapped: " Dealers already mapped ",
      dealer_already_mapped: " Dealer already mapped ",
      dealer_added: "  Dealer added. ",
      dealers_added: "  Dealer added. ",
      total_dealers_mapped_now: "Total dealers mapped now are ",
      no_dealer_available: "No Dealer available for this assign criteria",
      proper_dealer_selected: "Proper dealer shall be selected",
      user_referenced_ongoing_activity: "User is referenced in Ongoing activity/activities. ",
      want_to_remove_mapping: "Are you sure you want to remove mapping?",
      user_mapping_removed: "Selected dealers unmapped successfully",
      dealer_closed_in_A2D: "Dealer not active in A2D",
      network_level_not_found: "Please select valid Network levels",
      duplicate_dealer_uuid: "Dealer already available",
      dealer_created: "Dealer Created",
      dealer_updated: "Dealer Updated",
      dealer_not_present: "Dealer not present in A2D",
      dealer_uuid_other_nmsc: "Please Enter Dealer Uuid for Same NMSC",
      target_kpi_updated: "The KPI's status have been updated successfully.",
      target_updated: "The KPI's target have been updated successfully.",
      kpi_launched: "The KPI's bulk launch successfully.",
      targetUpdate:"Application doesn't allow target to be modified for accepted status.",
    },
    standardMessages: {
      delete: "Sei sicuro di voler cancellare ? ",
      addActionPlanNotAllowed: "Il piano d'azione non può essere creato o modificato dopo che l'attività è stata chiusa, completata o rifiutata.",
      addToolboxNotAllowed: "Cannot create more than 19 toolbox",
      britRejectMsg: "Sei sicuro di voler rifiutare questa attività? Dopo il rifiuto modifiche non sono più possibili.",
      rejectMsg: "Sei sicuro di voler rifiutare questa attività? Dopo il rifiuto modifiche non sono più possibili.",
      selectNMSCTab: "Please select nmsc language to publish",
      all: "Tutti",
      deleteConfirm: "Sei sicuro di voler cancellare i KPI selezionati?",
      no: "No",
      retireConfirm: "Rimuovere questo KPI? Impossibile riattivare il KPI una volta rimosso",
      warning: "Attenzione",
      yes: "Sì",
      saveDataConfirmation: "Confermare il salvataggio dei dati?",
      saveKPIWarning: "Nota: I dati una volta salvati non potranno più essere modificati. Per modificare i dati contattare l'amministratore",
      addKpiNotAllowed: "I KPI del mese successivo possono essere inseriti dopo la fine del mese corrente.",
      activityLaunch: "Sei sicuro di voler avviare questa attività? Dopo l'avvio, l'attività non potrà tornare allo stato Pianificato o cancellata.",
      deleteConfirmSubQuestionNaire: "Sei sicuro di voler cancellare la domanda selezionata?",
      retireConfirmSubQuestionNaire: "Sei sicuro di voler ritirare la domanda selezionata?",
      deleteConfirmMainQuestion: "Si prega di confermare se si desidera eliminare la domanda principale e le relative domande secondarie correlate.",
      deleteConfirmChapter: "Sei sicuro di voler eliminare il capitolo?",
      deleteLibraryFolder: "Sei sicuro di voler eliminare questa cartella?",
      deletePublishedFolder: "Alcuni file all'interno di questa cartella potrebbero essere collegati al questionario. Sei sicuro di voler eliminare definitivamente questa cartella e rimuovere i collegamenti dal questionario?",
      importQuestion: "Sei sicuro di voler importare questa domanda secondaria? L'attuale domanda sarà sostituita.",
      noQuestion: "Nessuna domanda disponibile",
      MandatoryWarning: "Completare tutti i campi obbligatori per tutte le lingue locali.",
      certificationSubmission:
        "Toolbox is submitted to TME for dealer certification",
      sameToolBox: "You cannot submit same Toolbox more than once. Please select different toolbox",
      deleteConfirmDealer: "Are you sure you want to delete this dealer ?",
      updateVcConfirmDealer: "All Activities created for this dealer in 'Value Chain' will be removed. Are you sure you want to continue?",
      updateBritConfirmDealer: "All Activities created for this dealer in 'BRiT' will be removed. Are you sure you want to continue?",
      completed_activity_delete:"Data cannot be retrieved once deleted. Are you sure you want to delete ?",
      deleteAllQuestionary: "Are you sure you want to delete the entire Questionnaire?",
      deleteAllLibraries: "Are you sure you want to delete all folders and files in the Library?",
      selectAll: "Seleziona tutti",
      unSelectAll: "Deseleziona tutti",
      select:"Seleziona.. ",
      warningMsg:"Ci sono Action Plan aperti in questa attività. Desideri completarla?"
    },
    status: {
      published: "Pubblicato",
      retired: "Ritirato",
      saved: "Salvato",
      rejected: "Rifiutati",
      open: "Apri",
      closed: "Chiuso",
      close: "Chiuso",
      ongoing: "Avviato",
      completed: "Completato",
      complete: "Completato",
      planned: "Pianificato",
      overdue: "Tardato",
      due: "In scadenza",
      pending: "Pending",
    },
    rejected: "Rifiutati",
    Open: "Apri",
    Closed: "Chiuso",
    Close: "Chiuso",
    Ongoing: "Avviato",
    Completed: "Completato",
    Complete: "Completato",
    Planned: "Pianificato",
    tabDetails: {
      noActionAvailable: "Nessuna azione disponibile",
      activeDealer: "Dealer is active.",
      dealerDeleted: "Dealer is deleted."
    },
    Table: {
      action: "",
      activity: "Attività",
      activityCompletionDate: "Data di completamento attività",
      activityCompletionDateFrom: "Data di completamento dell'attività dal",
      activityCompletionDateTo: "Data di completamento dell'attività al",
      activityStartDate: "Data di inizio attività",
      activityStartDateFrom: "Data di inizio dell'attività dal",
      activityStartDateTo: "Data di inizio dell'attività al",
      assignedTo: "Assegnato a",
      assignee: "Assegnatario",
      benchmark: "Benchmark",
      city: "Città",
      completedBy: "Completato da ",
      completionBy: "Completato da ",
      completionDate: "Data di completamento",
      counterMeasure: "Contromisura",
      country: "Paese",
      createdBy: "Creato da",
      dealer: "Concessionario",
      dealerNameAD: "Dealer name (A2D)",
      dealerName: "Nome concessionaria",
      definition: "Definizione",
      description: "Descrizione",
      dueDate: "Scadenza",
      endDate: "Scadenza",
      firstVisit: "Prima visita",
      followup: "Follow up",
      franchise: "Franchise",
      history: "Storia",
      id: "ID",
      initial: "Iniziale",
      kpi: "KPI",
      launch: "Lancio",
      mandatory: "Obbligatorio",
      nmsc: "NMSC",
      problem: "Problema",
      publishedOn: "Pubblicato il",
      questionReference: "Riferimento domanda",
      reference: "Riferimenti",
      reminderOn: "Avviso attivo",
      score: "Punteggio",
      startDate: "Data di inizio",
      status: "Stato",
      target: "Target",
      toolbox: "Toolbox",
      view: "Vista",
      kpiResult: "KPI attuali",
      nmscTarget: "NMSC Target",
      penEbenchmark: "PanE Benchmark",
      kpiField: "KPI",
      location: "Location",
      ActionItemReport: "Settori d'azione in corso",
      certification: "Stato della certificazione",
      noOfToolbox: "Numero di Toolbox completate",
      program: "Programma",
      certificationDate: "Data di certificazione",
      activityId : 'Activity ID',
      approvedBy:"Approved By",
      kpis:"KPIs",
      lastmonthKpis:"Last Month KPIs",
      questionPercent:"Completion %",
      actionPlan: "Piano d'Azione",
      monthlyKpi:"Monthly KPIs",
      initialKpi:"Initial KPIs",
      targetKpi:"Target KPIs",
     },
     tabsDetails: {
      actionPlan: "Piano d'Azione",
      confirmDeleteMessage: "Sei sicuro di voler cancellare i KPI selezionati?",
      confirmRetireMessage: "Confermare per rimuovere il KPI selezionato in quanto già pubblicato precedentemente",
      errorRetireMsg: "Impossibile rimuovere questo KPI in quanto si tratta di un KPI obbligatorio",
      import: "Importa",
      importKpiMessage: "Importare KPI dalla lista KPI indicata da TME",
      kpi: "Principali indicatori delle performance (KPI)",
      library: "Libreria",
      noActionAvailable: "Nessuna azione disponibile",
      NoKPI: "KPI non disponibile",
      publish: "Pubblica",
      questionnaire: "Questionario",
      tmeKPIList: "Lista KPI indicati da TME",
      toyotaCityBrussels: "TOYOTA CITY BRUSSELS",
      attachment: "Attachment",
      attachments: "Attachment(s)",
      uploadAttachment: "Upload Attachment",
      noAttachment: "No attachments available",
    },
    tmeAdminMaster: {
      ToolboxName: "Nome del toolbox",
      newToolboxName: "Nuovo nome",
      deleteToolbox: "Cancellare il toolbox?",
      programName: "Nome del programma",
      ToolboxName_EN: "Nome del toolbox (EN)",
      deleteTool: "Sei sicuro di voler cancellare questo toolbox?",
      selectToolbox: "Selezionare il toolbox da cancellare.",
      editToolBox: "Inserire il toolbox",
      addToolbox: "Aggiungere il toolbox",
      toolbox: "Inserire il nome per questo toolbox.",
      addActionPlan: "Add Piano d'Azione",
      actionPlanItem: "Azioni correttive - Elemento#",
      add: "Aggiungere",
      addActivity: "Aggiungi attività",
      additionalInformation: "Informazioni aggiuntive",
      benchmark: "Benchmark",
      cancel: "Annulla",
      createEditActivity: "Crea / Modifica attività",
      createKPI: "Crea KPI",
      delete: "Elimina",
      download: "Download",
      edit: "Modifica",
      editKPI: "Modifica KPI",
      keyPerformanceIndicator: "Indicatore chiave della performance",
      kpiDefinition: "Definizione KPI",
      mandatoryKpi: "KPI obbligatorio",
      save: "Salva",
      search: "Ricerca",
      unit: "Unità",
      update: "Aggiornamento",
      validData: "Immettere dati validi",
      hide: "Nascondi",
      show: "Mostra",
      upload_dealer_network: "Caricare la lista dei concessionari",
      upload_question: "Caricare il questionario",
      upload_user: "Caricare i dettagli dell'utente",
      upload_activity: "Caricare le attività",
      remove: "Remove",
      toyota: "Toyota",
      lexus: "Lexus",
      launch: "Launch",
      close: "Close",
      generate: "Generate",
      dueDateExceed:"Data di scadenza superata",
      delQuestionnaire:"Delete questionnaire"
    },
    toastr: {
      activityStatus_condition: "Rispondere a tutte le domante per poter concludere questa attività.",
      emptyTarget: "Please enter the NMSC target to publish the KPI",
      retailerActual: "Please enter the Retailer Actual",
      initialTargetMonthly: "Introdurre i KPI iniziali, i Target e almeno i dati di 1 mese per continuare.",
      initialTarget: "Inserire i KPI attuali e i KPI target .",
      upload_error: "Errore nel caricamento della lista dei concessionari.",
      action_item_create_warning: "Possono essere inseriti 5 documenti in una azione.",
      actionPlanStartDateValidation: "La data di termine non può essere antecedente alla data di inizio",
      actionsNotClosed: "Ci sono azioni in corso nel piano d'azione; chiudere tutte le azioni prima di terminare l'attività",
      activityDuplicate: "Esiste già un'altra attività per la stessa Concessionaria e per lo stesso Toolboox",
      assigneeNotExist: "L'assegnatario selezionato non è stato registrato nel sistema",
      assigneeValidation: "Assegnatario non valido",
      atleastOnePublish: "Selezionare almeno un KPI da pubblicare",
      cannotRetireActive: "Questo è un KPI obbligatorio e non può essere rimosso ",
      completionDate: "La data di completamento non può essere antecedente alla data della prima visita",
      confirDeletePlannedActivity: "Cancellare l'attività pianificata ? ",
      DateformatValidation: "Date should be in format DD/MM/YYYY",
      DateValidation: "From Date Should Be Less Than To Date",
      dealerValidation: "Concessionaria non valida",
      deleteSelectedConfirm: "Sei sicuro di voler cancellare i KPI selezionati?",
      duplicate: "Esiste già un altro KPI con lo stesso nome e la stessa definizione",
      modalMandatory: "Immettere dati validi in tutti i campi obbligatori",
      noPublishKpi: "I KPI non sono stati ancora pubblicati per il Toobox selezionato",
      validSearchCriteria: "Immettere criteri di ricerca validi",
      multiSelectPlaceHolder: "Inserire almeno 5 caratteri",
      multiSelectPlaceHolderUser: "Inserire almeno 3 caratteri",
      startDateValidation: "Start date cannot be greater than the End date",
      fileSelectionErr: "Selezionare prima il file",
      atleastOneQuestion: "Seleziona almeno una domanda da importare.",
      atleastOneSubQues: "Selezionare almeno una sottodomanda da pubblicare. ",
      uploadErr: "Numero di file che non è stato possibile caricare:.",
      uploadSuccess: "Lista dei concessionari caricata con successo.",
      file_upload_success: "I file sono stati caricati con successo.",
      KPIinOngoingClosedActivity: "This KPI is present in one, or more, Ongoing/Closed Activities.Please Complete these Activities before making any change to the KPI.",
      Role_Error: "The user setup is incomplete, please contact the NMSC KET Admin.",
      points_allocation_incorrect: "Points allocation is incorrect. Please modify.",
      activity_deleted:"Activity deleted successfully",
      activity_certified_dealer:"Dealer certified for the selected Activity, hence cannot be deleted",
      activity_submited_for_certificate_dealer:"The status of toolbox cannot be changed as request for certification has been sent to TME"
    },
    TopNav: {
      ActivityLog: "Attività",
      ApplicationName: "Kaizen Expert Tool",
      Home: "Home",
      logout: "Logout",
      MasterDataUpload: "Carica Master Data",
      Toolbox: "Toolbox",
      welcome: "Benvenuto",
      Admin: "Admin"
    },
    questionnaire: {
      addSubQuestion: "Aggiungi una domanda",
      editSubQuestion: "Inserisci una domanda",
      subQuestionTitle: "Richiesta",
      additionalInfo: "Informazioni aggiuntive",
      na: "Aggiungi'non applicabile' come possibile risposta a questa domanda. ",
      question: "Domanda",
      questionTitle: "Titolo della domanda",
      why: "Perché?",
      addMainQuestion: "Aggiungi una domanda principale",
      editMainQuestion: "Inserisci una domanda principale",
      previousQuestion: "Domanda precedente",
      nextQuestion: "Domanda successiva",
      what: "Cosa?",
      library: "Libreria",
      subQuestionTable: {
        no: "No.",
        subQuestion: "Domanda secondaria",
        status: "Stato",
        publishedDate: "Data di pubblicazione",
        notApplicable: "Non applicabile"
      },
      links: {
        question_link_button: "Collegamento",
        question_link_include_folder: "Includere una cartella",
        question_link_add_link: "Collegamento dalla libreria ai file",
        question_link_view_folder: "Vedere la libreria"
      },
      mainQuestion: "Nuova domanda principale",
      subQuestion: "Nuova domanda secondaria",
      retiredQuestion: "Domande ritirate:",
      libraries: "Nuovo file nella libreria",
      noFiles: "Nessun file disponibile."
    },
    library: {
      selectFile: "Selezionare almeno un file.",
      fileSelected: "File selezionati",
      publish_library_file: "Selezionare almeno un file da pubblicare",
      library_files_deleted: "Are you sure you want to delete this file?",
      library_files_linked_deleted: "Sei sicuro di voler eliminare questo file e i collegamenti creati nel questionario?",
      max_size_error: "La dimensione dei file eccede il limite di 1 GB.",
      empty_file_desc: "Inserire la descrizione del file.",
      file_not_selected: "Selezionare il/i file da caricare (max 1 GB)",
      file_type_error: "Questo tipo di file non è consentito.",
      file_name_error: "Carattere non valido nel nome del fiel",
      file_name_lenght_error: "Il nome del file può avere massimo 50 caratteri",
      addFolder: "Aggiungi una cartella",
      editFolder: "Inserisci la cartella",
      currentFolder: "Nome cartella",
      parentFolder: "Nome cartella di riferimento",
      emptyFolderName: "Il nome cartella non può essere vuoto",
      invalidFolderName: "The Folder Name cannot contain '/'.",
      folder_created: "La cartella è stata creata con successo",
      folder_updated: "La cartella è stata aggiornata con successo",
      folder_not_found: "Cartella non trovata",
      lib_invalid_toolbox: "Toolbox selezionato non valido",
      lib_folder_already_exists: "La cartella esiste già.",
      lib_folder_deleted: "La cartella è stata cancellata con successo",
      library_files_published: "Il file è stato pubblicato",
      addFile: "Aggiungi file",
      editFile: "Inserisci file",
      fileName: "Nome file",
      upload: "Carica",
      desc: "Descrizione",
      folderCreationErr: "Gerarchia limitata a 2 livelli.",
      subFolderWarning: "Selezionare la cartella per aggiungere il file.",
      deleteWarning: "Selezionare la cartella da cancellare.",
      beforeAfterNotSelected: "Selezionare se le prove risalgono a prima o dopo kaizen",
    },
    chapter: {
      chapter_exist: "Il capitolo esiste già",
      chapter_saved: "Capitolo salvato",
      chapter_cannot_delete: "Il capitolo non può essere cancellato, è già pubblicato come domanda secondaria",
      failed: "Fallito",
      chapter_deleted: "Capitolo cancellato",
      chapter_nonEmpty: "Inserire il nome del capitolo.",
      addchapter: "Aggiungere il capitolo",
      editChapter: "Edit chapter",
      DELETE_SUCC:"Questionnaires deleted successfully.",
      DELETE_SUCC_LIB:"Libraries deleted successfully.",
      DELETE_ERR : "Something Went wrong."
    },
    evaluation: {
      attachments: "Allegati",
      comments: "Commenti",
      evidence: "Evidenza",
      evidences: "Evidenze",
      yes: "Sì",
      no: "No",
      na: "N/A",
      uploadEvidence: "Caricare l'evidenza",
      fileUpload: "Selezionare il/i file da caricare (max 1 GB)",
      who: "Chi?",
      answer: "Risposta",
      comment: "Comment",
      date: "Data",
      submit: "Sottomettere",
      commentMsg: "Inserire i commenti.",
      noCommentAvailable: "Nessun commento disponibile.",
      uploadSuccessMsg: "File(s)  have been uploaded successfully",
      uploadErrorMsg: "Number of files failing to upload:",
      fileWithSameName: "Esiste un altro file con lo stesso nome.",
      noEvidence: "No evidences available",
      createAction: "Creare l'azione",
      chapterName: "Nome del capitolo",
      questionNo: "Domanda n°",
      when: "Quando?",
      noRecords: "Nessun documento disponibile.",
      kaizenPoints: "Punti di miglioramento",
      renameSuccessMsg: "File has been renamed successfully",
      deleteSuccessMsg: "File has been deleted successfully",
      question: "Domanda",
      before:"Before",
      after:"After",
      beforeAfterLabel:"Le prove risalgono a prima o dopo kaizen?",
      move:"Sposta",
      moveMsg: "File rimosso correttamente",
      noToYesMsg:"Previous answer was NO"
    },
    WALK_AROUND_CHECK: "Walk Around Check",
    ketProgram: {
      TSM: "TSM/LSM",
      BRiT: "BRiT RI",
      TSM_BNP: "Body & Paint",
      TSM_USED_CAR: "Used Car",
      value_chain: "Value Chain",
      brit: "BRiT",
      ea: "EA",
      per:"Performance",
      nettrans:"Network Transformation",
    },
    dashboard: {
      notice_board: "Bacheca",
      kpi_target_not_set: "KPI's targets not set",
      kpi_initial_not_set: "KPI's initial measurement not set",
      kpi_monthly_not_set: "KPI's monthly measurement not set",
      kpi_below_target: "KPI's below targets present",
      activation: "Attivazione",
      users: "Utenti",
      nmscs: "NMSC's",
      activeRetailer: "Concessionari attivi",
      certificationRate: "Tasso di certificazione",
      reCertificationRate: "Tasso di ri-certificazione",
      activityDue: "Attività in scadenza",
      avgdays: "Media dei giorni/Toolbox",
      overDueAct: "Attività scadute",
      actionItem: "Articoli di azione",
      bussResult: "Risultati aziendali",
      toolboxImpl: "Implementazione del Toolbox",
      statusCompletedL:"Status - Completed, Progress - ",
      statusClosedL:"Status - Closed, Progress - ",
      statusOngoingL:"Status - Ongoing, Progress - ",
      recentUpdates: "Aggiornamenti recenti",
      rolloutTopNmsc: "Risultati del Rollout - Top NMSC's Top NMSC",
      bestPractice: "Consiglio delle migliori pratiche",
      availableKpi: "KPI disponibili",
      selectedKpi: "KPI selezionati",
      dealerBestPractice: "Dealer best practice board",
      kpiList: "Elenco KPI",
      go: "VAI!",
      nodata: "No data available",
      businessResultTop: "Risultati di business - Top Concessonari",
      businessResultNMSC: "Risultati aziendali - Top NMSC's Top NMSC"


    },
    dealerCertification: {
      dealerCertification: "Certificazione del concessionario",
      readyForCertification: "Pronto per la certificazione",
      certification: "Certificazione",
      submittedOn: "Presentato su",
      certificateDate: "Data di certificazione",
      reCertificateDate: "Data di ricertificazione",
      toolboxDetails: "Dettagli Toolbox",
      approve: "Approvare",
      reject: "Rifiuta",
      pending: "Pending",
      region: "Regione"



    },
    uploadBulk: {
      browse: "Browse",
      executionId: "Execution Id",
      uploadedOn: "Uploaded On",
      lastUpdatedOn: "Last updated on",
      noDataTable: "No data to view",
      kpiUpload: "KPI Upload",
      action: "Action",
      processed: "Processed",
      error: "Error",
      received: "Received",
      createActivity: "Create Activities",
      fileUpload: "File Upload",
      targetSetting: "Target Setting",
      kpiEntry:"KPI Entry",
      accepted:"Accepted",
      launchNmsc:"Launch for NMSC"
    },

    userManagement: {
      userManagement: "Users management",
      createUser: "Create User",
      editUser: "Edit User",
      viewUser: "View User",
      nmscId: "NMSC ID",
      tarsId: "TARS ID",
      firstName: "First Name",
      lastName: "Last Name",
      emailId: "E-Mail ID",
      britDealerChamp: "Brit Dealer Champion",
      invalidEmail: "Please enter valid E-Mail ID",
      invalidTars: "Please enter valid TARS ID",
      invalidFirstName: "Please enter valid First Name",
      invalidLastName: "Please enter valid Last Name",
      user_created: "User Created",
      user_updated: "User updated",
      duplicate_tarsuser: "This TARS id is already registered in the KET database.",
      user_define_in_tool: "User is defined as Business owner in Active Business Function. Hence, could not be made Inactive",
      user_refer_on: "User is referenced in Ongoing activity",
      pbiAccount : "PBI Account",
      invalidPbiAccount : "PBI Account should contain minimum 3 characters.",
      businessArea: "Business Area",
      valueChainAndBRiT: "Value Chain & BRiT",
      only:"Only",
      optionText:"Please select at least one of the two options below",
      valueChainOnly: "Value Chain Only",
      britOnly: "BRiT Only"

    },
    UserManagement: {
      nmscId: "NMSC ID",
      tarsId: "TARS ID",
      firstName: "First Name",
      lastName: "Last Name",
      emailId: "E-Mail ID",
      languageCode: "Language Code",
      ACTIVE: "Active",
      INACTIVE: "Inactive",
      addUser: "Add User",
      user: "User",
      error_message: "An Error Occurred. Please try again later",
      role: "Role",
      standardMessage: "No User Found",
      dealerCode: "Dealer Code",
      a2d: "A2D UUID",
      britReady: "Retailer participated in Brit Ready",
      britLive: "Retailer participated in Brit Live",
      britJapan: "Retailer participated in Brit Discovery Japan",
      britEss:"Retailer has updated EES Action Plan",
      addDealer: "Add Dealer",
      noDealer: "No Dealer Found",
      dealerUuid: "Dealer uuid",
      language: "Language",
      L3: "L3",
      L4: "L4",
      L5: "L5",
      L6: "L6",
    },
    CeaKpi: {
      survey: "Survey",
      evaluationItem: "Evaluation Item",
      kpiMetric: "KPI Evaluation Method",
      stratagicPillar: "Strategic Pillar",
      addKPI: "Add KPI",
      edit: "Edit",
      delete: "Delete",
      subPillar: "Sub-Pillar",
      kaizenImp_impact_def: "Kaizen Importance / Impact / Definition",
      evalMethod: "Evaluation Method",
      scoreMethod: "Scoring Method",
      typeOftarget: "Type of Target",
      target: "Target",
      mode: "Mode",
      points: "Points",
      inputFreq: "Input Frequency",
      cea_kpi_created: "CEA KPI has been created successfully",
      noPointsAllocated:"No Points defined for selected Pillar/Business function mapping",
      targetIncorrect:"Target value is Invalid",
      viewKpi:"View KPI",
      general:"General",
      targetScoring:"Targets & Scoring",
      inputs:"Input Parameters",
      maxFiveParam:"Maximum 5 parameters for a KPI can be added",
      validParam:"Please Enter Valid Parameter",
      validDesc:"Please Enter Valid Parameter Description",
      dataEntry:"Data Entry in KET",
      paramater:"Parameter",
      paramDesc:"Parameter Description",
      noParamAvai:"No Parameters Available",
      kpiDeleted:"KPI deleted Successfully",
      kpiDeleteError:"KPI Not found",
      kpiActualsError:"Actuals present. KPI cannot be deleted",
      cea_kpi_updated: "CEA KPI has been updated successfully",
      addParam:"Add Parameters"

    },
    dealerManagement: {
      invalidUuid: "Please enter valid Uuid",
      invalidDealerName: "Please enter valid Dealer Name",
      invalidCity: "Please enter valid City",
      invaliDealerCode: "Please enter valid Dealer Code",
      existingUuid: "Existing Uuid",
      updateUuid: "Update Uuid",
      newUuid: "New Uuid",
    },
    networkManagement: {
      networkManagement: "Network management"
    },
    kpiEntry: {
      cancel : "Cancel",
      save : "Save",
      no : "No",
      yes : "Yes",
      hide : "Hide",
      show : "Show",
      dataNotSaved : "Data is not saved. Entered data may be lost. Do you want to Proceed?",
      wantToProceed : "Do you want to proceed?",
      status: 'Status',
      evaluationYear: 'Evaluation Year',
      frequency: 'Frequency',
      typeOfTarget: 'Type of Target',
      target: 'Target',
      unit: 'Unit',
      standardMessage: 'No Kpi Actuals Data Found',
      srNo: 'S.No.',
      actuals: 'Actuals',
      subPillar: 'Sub Pillar',
      businessFunc: 'Business Function',
      brand: 'Brand',
      Jan : "Jan",
      Feb : "Feb",
      Mar : "Mar",
      Apr : "Apr",
      May : "May",
      Jun : "Jun",
      Jul : "Jul",
      Aug : "Aug",
      Sep : "Sep",
      Oct : "Oct",
      Nov : "Nov",
      Dec : "Dec",
      q1 : "Q1",
      q2 : "Q2",
      q3 : "Q3",
      q4 : "Q4",
      h1 : "H1",
      h2 : "H2",
      cy : "CY",
      kpi : "Key Performance Indicators",
      actuals1 : "Actuals should not be more than allotted points. Please re-enter",
      actionPlan	: "Action Plan",
      enterActualsData : "Please enter actuals data.",
      notNumber : "Please enter valid number.",
      serverErrorMsg : "An error occured, please try again later.",
      launchEvaluationError : "Please launch evaluations.",
      actualsDataSaved : "Actuals data uploaded successfully.",
      inputTxt:"Inputs"
    },
    ceaEvaluation :{
      nmsc : "NMSC",
      search : "Search",
      all : "All",
      status : "Status",
      businessFunction :"Business Function",
      noEvaluationsFound : "No Evalutions Found",
      evaluationYear : "Evalution Year",
      view : "View",
      evaluations : "Evaluations",
      launchTargetSetting: "Launch Target Setting",
      launchEvaluation : "Launch Evaluation",
    },
    britDashboard:{
      noVideo:"No videos available",
      playVideo:"Play Video",
      trainingVideos:"Training  Videos",
      download: "Download Video",
      back:"Back"
    },
    ceaActionPlan:{
      id: "ID",
      counterMeasure: "Countermeasure",
      questionReference: "KPI Reference",
      status: "Status",
      startDate: "Start Date",
      dueDate: "Due Date",
      assignedTo: "Assigned To",
      noActionAvailable: "No Actions available",
      add: "Add",
      download: "Download",
      edit: "Edit",
      delete: "Delete",
      deleteMsg: "Are you sure you want to delete ?",
      no: "No",
      yes: "Yes",
      addActionPlanNotAllowed: "The Action Plan cannot be created, or edited after the Activity has been Closed, Completed, or Rejected.",
      actionPlanItem: "Action Plan - Item #",
      addActionPlan: "Add Action Plan",
      open: "Open",
      actionPlanStartDateValidation:"End Date cannot be earlier than the Start Date",
      modalMandatory: "Please enter valid data in all the mandatory fields",
      problem: "Problem",
      description: "Description",
      followup: "Follow Up",
      history: "History",
      cancel: "Cancel",
      save: "Save",
      update: "Update",
      assignee: "Assignee",
      endDate: "End Date", 
      select: "Select",
      notApplicablePer:"Not Applicable for CEA, Networks and Used car"
    },
    reachItDashboard:{
      topNavReachIT:"ReachIT",
      downloads:"Downloads",
      historyFiles:"History of previous filesCronologia file precedenti",
      reachText1:"Benvenuti nella homepage di REACH IT. Il team della divisione di gestione ambientale di Toyota Motor Europe utilizzera' questa pagina per condividere informazioni rilevanti sulla SCIP: Sostenze pericolose in articoli finiti o in oggetti complessi (Prodotti), stabilita attraverso the Direttiva Europea riguardi il framework dei rifiuti (WFD). Per otteperare ai requisiti di legge e procesare la vostra notifica SCIP semplificata. ",
      reachText2:"In caso di domande, per favore contattare il team di gestione ambientale di TME al seguente",
      reachText3:"In caso di domande, per favore contattare la tua NMSC",
      envMngt:"Gestione Ambientale",
      fileStatistics: "File statistiche per il dowload",
      Downloaded: "Downloaded",
      not: "No",
      exportToExcel:"Esporta in Excel"
    },
    usedCarComment:{
      ongoing:"Ongoing",
      completed:"Completed",
      commentHistory:"Monthly Comments History",
      addComment:"Add Monthly Comment",
      month:"Month",
      comment:"Comment",
      username:"Username",
      email:"Email",
      createdDate:"Created Date"
    },
  }
};

export default translationsIT;
