import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import { fetchQuestionContent, fetchSubQuestions, fetchChapterSequence,fetchNmscChapterSequence, fetchNmscQuestionContent, fetchNmscSubQuestions } from '../../actions/tabAction.jsx';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css' // Import css
import { withTheme } from '../../themes/theming';
import './index.css';
import { Treebeard, decorators } from 'react-treebeard';
import { getQuestionSummary, deleteChapter, removeChapterReducer, getNmscQuestionSummary, setFirstPageLoad, deleteNmscChapter, deleteAllQuestionary } from '../../actions/questionSummaryAction.jsx';
import LeftPaneModel from '../questionnaire/LeftPaneModel.js';
import { Scrollbars } from 'react-custom-scrollbars';
import { toSetSelectedQuestionId } from '../../actions/mainQuestionAction';
import * as AppConst from '../../AppConstant';
const $ = require('jquery');
var I18n = require('react-redux-i18n').I18n;
window.jQuery = $;



decorators.Header = ({ style, node }) => {
    // const iconType = node.children ? 'folder' : 'file-text';
    // const iconClass = `fa fa-${iconType}`;
    // const iconStyle = { marginRight: '5px' };

    var activeClass = node.active ? "activeQuestion" : "";

    return (
        <span>
            {node.children ?
                <span className={activeClass} title={node.name} style={{ display: 'inline-block',margin: '-7px 10px',textOverflow: "ellipsis", overflow: "hidden", whiteSpace: "nowrap", wordBreak: 'break-all', maxWidth: "82%" }}>
                    {/* <i className={iconClass} style={iconStyle}/> */}
                    {node.seqNumber}. {node.name}
                </span> : <span className={activeClass} title={node.name} style={{ display:"block", margin: '0 10px',textOverflow: "ellipsis", overflow: "hidden", whiteSpace: "nowrap", wordBreak: 'break-all' }}>
                    {/* <i className={iconClass} style={iconStyle}/> */}
                    {node.name}
                </span>}
        </span>
    );
};
var isRotate = false;
decorators.Toggle = ({ style }) => {
    isRotate = isRotate ? false : true;
    // var isRotateVal = "rotate-" + isRotate;
    return (
        <span style={{ display: 'inline-block', height: '20px' }} >
            <img className="toggleTreebeard toyota" src="/images/T_Artboard 21mdpi.png"  height={'20px'} width={'20px'} alt=""/>
        </span >


    );
};



class QuestionSummary extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            data: props.data,
            rotate: "0",
            questionLeftPane: [],
            addModal: false,
            showDelete: false,
            modalFor: "",
            showEdit: false
        }

        this.onToggle = this.onToggle.bind(this);
    }


    componentWillMount() {
        var isNMSCAdminUser = false;
        var isTMEAdminUser = false;
        var isNotAdmin = false;
        if (this.props.user.userMdl.roles !== undefined) {
            var roles = this.props.user.userMdl.roles;
            for (var i = 0; i < roles.length; i++) {
                if (roles[i].toUpperCase().indexOf("KET NMSC ADMIN") !== -1) {
                    isNMSCAdminUser = true;
                    isNotAdmin = false;
                    break;
                }
                else if (roles[i].toUpperCase() === "TSM KET USER" || roles[i].toUpperCase() === "KET TME USER" || roles[i].toUpperCase() === "KET TME ADMIN" || roles[i] === "KET-TSP Auditor" || this.props.user.userMdl.businessAreaKey==="TSP") {
                    isTMEAdminUser = true;
                    isNotAdmin = false;
                    break;
                }
                isNotAdmin = true;

            }
        }
        this.setState({ isNmsc: isNMSCAdminUser });
        this.setState({ isNotAdmin: isNotAdmin });
        if (isNMSCAdminUser) {
            // fetching NMSC Chapter list based on loggedin user nmsc and language, because No tab is selected while mounting component.
            this.props.dispatch(getNmscQuestionSummary(this.props.toolboxId, this.props.user.userMdl.nmsc, this.props.activeTabNmscLang, null, null, true));
        }
        else  if (isTMEAdminUser){
            this.props.dispatch(getQuestionSummary(this.props.toolboxId, null, null, true));
        }
        else{
            this.props.dispatch(getNmscQuestionSummary(this.props.toolboxId, this.props.user.userMdl.nmsc, this.props.user.userMdl.lang, null, null, true,true));
        }
    }


    componentWillReceiveProps(nextProps) {
        if (nextProps.tabList) {
            console.log("list of languages" + nextProps.tabList)

        }
        if (nextProps.questionSummary) {
            this.setState({ questionLeftPane: nextProps.questionSummary })
            if (nextProps.selectedQuestionId) {

                this.props.mainQSelectionId(nextProps.selectedQuestionId, nextProps.chapterSequence, nextProps.questionSummary);
            }
            else if (nextProps.chapterSequence) {
               

                this.props.mainQSelectionId('', nextProps.chapterSequence, nextProps.questionSummary);
            }
            else {
                this.props.mainQSelectionId('', '', nextProps.questionSummary);
            }
        }

        if (nextProps.firstLoad) {
           
            //to pre-select the first chapter on right pane
            let firstChild = {};
            let firstChildChapter = '';
            if (nextProps.questionSummary.length > 0) {
                if (nextProps.questionSummary[0].children && nextProps.questionSummary[0].children.length > 0) {
                    firstChildChapter = nextProps.questionSummary[0].chapterId;
                    firstChild = nextProps.questionSummary[0].children[0];
                }
            }

            if (firstChild.mainQId) {
                this.props.mainQSelectionId(firstChild.mainQId, firstChildChapter, nextProps.questionSummary);
            }
            else {
                this.props.mainQSelectionId('','', nextProps.questionSummary);
            }
        }

        //To hide delete/edit when chapter is saved or edited successfully
        if (nextProps.chapterSaved === "CHAPTER_SAVED") {
            
            this.setState({ showDelete: false, showEdit: false })
            if (nextProps.chapterId) {
                //console.log('nextProps.chapterId:'+nextProps.chapterId);
                this.setState({ activeChapterId: nextProps.chapterId });
                this.props.mainQSelectionId('', nextProps.chapterId, nextProps.questionSummary);             
            }
        }

        //To hide delete/edit when sub question is published
         if (nextProps.subQPublished === "SUB_Q_PUBLISHED") {
           console.log('published');
            this.setState({ showDelete: false, showEdit: false })
            // Reset dispatch to empty else
            //"nextProps.subQPublished === "SUB_Q_PUBLISHED" will always be true
            // and edit delete icon will not visible for any sub question
            this.props.dispatch({
                type: "SUB_Q_PUBLISHED",
                payload: ""
              });

         }
     
    }
    componentDidUpdate(prevProps, prevState) {
        if (prevProps.toolboxId !== this.props.toolboxId) {
            this.setState({ showDelete: false, showEdit: false })

            // this.props.mainQSelectionId('', '', this.state.questionLeftPane);
            this.props.dispatch(toSetSelectedQuestionId(null));

        }
    }

    deactiveNode = (currentNode) => {
        var flag = false;
        var nodeList = this.state.questionLeftPane;
        for (var i = 0; i < nodeList.length; i++) {
            var chapt = nodeList[i];
            var node = nodeList[i].children;
            for (var j = 0; j < node.length; j++) {
                var question = node[j];
                if (question.mainQId !== currentNode.mainQId) {
                    question.active = false;
                }
                else {
                    flag = true
                }
            }
            if (!flag) {
                chapt.toggled = false;
                chapt.active = false;
            }

        }
        this.setState({ questionLeftPane: nodeList })
    }
    onToggle(node, toggled) {
        this.props.dispatch(removeChapterReducer());
        this.props.dispatch(setFirstPageLoad());
        this.deactiveNode(node);
        //Child Node(Question Title)
        let selectedChapterId = node.chapterId;
        if (this.state.cursor) { this.state.cursor.active = false; } // eslint-disable-line
        if (node.mainQId) {
            //To hide delete/edit on title click
            this.setState({ showDelete: false, showEdit: false })
            this.props.mainQSelectionId(node.mainQId, selectedChapterId, this.state.questionLeftPane);
            //this.props.dispatch(fetchChapterSequence(node.mainQId))
            console.log("for main ques",this.props.isNMSCAdminUser)
            this.props.dispatch(toSetSelectedQuestionId(node.mainQId));
            if (this.props.isNMSCAdminUser || this.state.isNotAdmin ) {
                this.props.dispatch(fetchNmscChapterSequence(null));
                this.props.dispatch(fetchNmscChapterSequence(node.mainQId))
                this.props.dispatch(fetchNmscQuestionContent(node.mainQId,!this.props.isNMSCAdminUser))
                this.props.dispatch(fetchNmscSubQuestions(node.mainQId,!this.props.isNMSCAdminUser))
            }
            else {
                this.props.dispatch(fetchChapterSequence(node.mainQId))
                this.props.dispatch(fetchQuestionContent(node.mainQId))
                this.props.dispatch(fetchSubQuestions(node.mainQId))
            }
            node.active = true;
        }
        //Parent Node (Chapter)
        else {
            //To show delete/edit on chapter click
            this.setState({ showDelete: node.isDeletable, showEdit: true })
            //to check whether chapter is added or not
            if (!node.children || node.children.length === 0) {
                this.props.dispatch({
                    type: "MAIN_CHAPTER_SEQ",
                    payload: selectedChapterId
                  });
                this.props.mainQSelectionId('', selectedChapterId, this.state.questionLeftPane);
                this.props.dispatch(toSetSelectedQuestionId(null));
                node.active = true;

            }
            else {

                var mainQId = node.children[0].mainQId;
                this.props.mainQSelectionId(mainQId, selectedChapterId, this.state.questionLeftPane);
               // this.props.dispatch(fetchChapterSequence(mainQId))
                this.props.dispatch(toSetSelectedQuestionId(mainQId))
                if (this.props.isNMSCAdminUser || this.state.isNotAdmin) {
                    this.props.dispatch(fetchNmscChapterSequence(null));
                    this.props.dispatch(fetchNmscChapterSequence(mainQId))
                    this.props.dispatch(fetchNmscQuestionContent(mainQId,!this.props.isNMSCAdminUser))
                    this.props.dispatch(fetchNmscSubQuestions(mainQId,!this.props.isNMSCAdminUser))
                }
                else {
                    this.props.dispatch(fetchChapterSequence(mainQId))
                    this.props.dispatch(fetchQuestionContent(mainQId))
                    this.props.dispatch(fetchSubQuestions(mainQId))
                }
                node.children[0].active = true;
            }
            this.setState({ selectedChapterRow: node });
            this.setState({ activeChapterId: node.chapterId });

        }
        if (node.children && node.children.length > 0) { node.toggled = toggled; }
        this.setState({ cursor: node });

    }
    leftPaneAddToggle = (modalFor) => {
console.log(modalFor);
        this.setState({ modalFor: modalFor })
        this.setState({
            addModal: !this.state.addModal
        });
    }


    deleteConfirmation = () => {
        confirmAlert({
            customUI: ({ title, message, onClose }) => {
                return (
                    <div className="deleteConfirmBox">
                        <div className={"titleClass-" + this.props.theme.name}>
                            {I18n.t('standardMessages.deleteConfirmChapter')}
                        </div>
                        <div className="modal-footer" style={{ borderTop: "0px" }}>
                            <button className={"buttonCancel-" + this.props.theme.name} onClick={() => onClose()}>
                                {I18n.t("standardMessages.no")}
                            </button>
                            <button
                                className={"button-" + this.props.theme.name}
                                onClick={() => {
                                    this.deleteChapter();
                                    onClose();
                                }}
                            >
                                {I18n.t("standardMessages.yes")}
                            </button>
                        </div>
                    </div>
                );
            }
        });

    }

    deleteChapter = () => {
        if (this.state.selectedChapterRow.chapterId) {
            let chapterIdToBeDeleted = this.state.selectedChapterRow.chapterId;
            if (this.state.activeChapterId && this.state.selectedChapterRow.chapterId !== this.state.activeChapterId) {
                chapterIdToBeDeleted = this.state.activeChapterId;
            }
            if (this.props.isNMSCAdminUser) {    
                this.props.dispatch(deleteNmscChapter(chapterIdToBeDeleted, this.props.toolboxId, this.props.user.userMdl.nmsc,this.props.activeTabNmscLang));
                this.props.dispatch(fetchNmscChapterSequence(null));                
            } else {
                this.props.dispatch(deleteChapter(chapterIdToBeDeleted, this.props.toolboxId));
            }
            this.setState({ showDelete: false, showEdit: false });
        }
    }

    deleteQuestionnaireConfirm = () => {
        confirmAlert({
            customUI: ({ title, message, onClose }) => {
                return (
                    <div className="deleteConfirmBox">
                        <div className={"titleClass-" + this.props.theme.name}>
                            {I18n.t('standardMessages.deleteAllQuestionary')}
                        </div>
                        <div className="modal-footer" style={{ borderTop: "0px" }}>
                            <button className={"buttonCancel-" + this.props.theme.name} onClick={() => onClose()}>
                                {I18n.t("standardMessages.no")}
                            </button>
                            <button
                                className={"button-" + this.props.theme.name}
                                onClick={() => {
                                    this.deleteQuestionnaire();
                                    onClose();
                                }}
                            >
                                {I18n.t("standardMessages.yes")}
                            </button>
                        </div>
                    </div>
                );
            }
        });
    
    }
    
    deleteQuestionnaire = () => {
        this.props.dispatch(deleteAllQuestionary(this.props.user.userMdl.activeProgramID,this.props.toolboxId));
    }
    
    render() {
        if(this.props.theme.name==="lexus" && $('span.toyota')){
            $(".toggleTreebeard").removeClass("toyota");
            $(".toggleTreebeard").addClass("lexus");
            $(".toggleTreebeard").attr("src","/images/upArrow-lexus.png");
        }
        else if(this.props.theme.name==="toyota" && $('span.lexus')){
            $(".toggleTreebeard").removeClass("lexus");
            $(".toggleTreebeard").addClass("toyota");
            $(".toggleTreebeard").attr("src","/images/T_Artboard 21mdpi.png");
        }else {
            //..
        }
        let accessType = '';
        if (this.props.user.userMdl && this.props.user.userMdl.accessControl) {
            accessType = this.props.user.userMdl.accessControl.filter(obj =>
                obj.function === 'QUESTIONNAIRE_CHAPTER')[0] ? this.props.user.userMdl.accessControl.filter(obj =>
                    obj.function === 'QUESTIONNAIRE_CHAPTER')[0].accessType : "";
        }

        return (

            <div className="questionSummary">
                {this.props.user.userMdl.roles.includes(AppConst.KET_TME_ADMIN) &&
                this.state.questionLeftPane.length !== 0 ? (
                <button
                    className={"button-addfile-" + this.props.theme.name}
                    title={I18n.t("tmeAdminMaster.delQuestionnaire")}
                    onClick={() => this.deleteQuestionnaireConfirm()}
                >
                    {I18n.t("tmeAdminMaster.delete")}{" "}
                </button>
                ) : (
                ""
                )}
           
                {accessType === "R/W" && this.props.activeTabNmscLang!=='TME' && !(this.props.user.userMdl.roles.includes("KET-TSP Auditor")) ?
                    <div className="questionSummaryAction">
                        {!this.props.isNMSCAdminUser || this.props.type==="NMSC" || this.state.questionLeftPane.length  > 0 ?
                            <a onClick={() => this.leftPaneAddToggle("addChapter")}><div title={I18n.t('tmeAdminMaster.add')} className="add-icon mainQueAdd" /></a> : null}
                        {this.state.showDelete ?
                            <a onClick={() => this.deleteConfirmation()}><div title={I18n.t('tmeAdminMaster.delete')} className="delete-icon" /></a> : null}
                        {this.state.showEdit ?
                            <a onClick={() => this.leftPaneAddToggle("editChapter")}><div title={I18n.t('tmeAdminMaster.edit')} className="edit-icon" /></a> :
                            null}
                    </div> : null}

                <div className="treeBeardContainter">
                    <Scrollbars universal
                        thumbSize={300}
                        hideTracksWhenNotNeeded={true}
                        //autoHide
                        //autoHideTimeout={1000}
                        //autoHideDuration={200}
                        renderThumbVertical={({ style, ...props }) =>
                            <div {...props} style={{ ...style, backgroundColor: this.props.theme.primaryColor, width: "4px", opacity: "0.7", cursor: "pointer" }} />
                        }>
                        {this.state.questionLeftPane.length !== 0?
                            < Treebeard
                            {...this.props}
                            data={this.state.questionLeftPane}
                        onToggle={this.onToggle}
                        decorators={decorators}

                        />:""
                    }
                    </Scrollbars>
                </div>

                <div>
                    {this.state.addModal === true ?
                        <LeftPaneModel
                            mainModal={this.state.mainModal}
                            leftPaneAddToggle={this.leftPaneAddToggle}
                            modalFor={this.state.modalFor}
                            toolBoxId={this.props.toolboxId}
                            selectedChapterRow={this.state.selectedChapterRow}
                            isNMSCAdminUser={this.props.isNMSCAdminUser}
                            activeTabNmscLang = {this.props.activeTabNmscLang}

                        /> : null}
                </div>


            </div >
        );
    }
}
const mapStateToProps = (state) => {
    return {
        user: state.loginReducer,
        questionContent: state.tabReducer.questionContent,
        chapterSequence: state.tabReducer.chapterSequence,
        subQuestions: state.tabReducer.subQuestions,
        questionSummary: state.questionSummaryReducer.questionResponse,
        chapterSaved: state.questionSummaryReducer.chapterSaved,
        chapterId: state.questionSummaryReducer.chapterId,
        selectedQuestionId: state.questionSummaryReducer.selectedQuestionId,
        tabList: state.questionSummaryReducer.tabList,
        firstLoad: state.questionSummaryReducer.firstLoad,
        nmscQuestionContent: state.tabReducer.nmscQuestionContent,
        nmscSubQuestions: state.tabReducer.nmscSubQuestions,
        nmscQuestionSummary: state.questionSummaryReducer.nmscQuestionResponse,
        subQPublished:state.questionSummaryReducer.subQPublished
        
    }
}
export default withRouter(connect(mapStateToProps)(withTheme(QuestionSummary)));
