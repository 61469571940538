import axios from "axios";
import { toastr } from "react-redux-toastr";
import { getQuestionSummary, getNmscQuestionSummary } from "./questionSummaryAction.jsx";
import { getCallWithReducer, postCallWithReducer } from "./commonAction";
import { getToken } from '../oidc/AuthService';

export function saveMainQuestion(mainQuestionDto, questionId, packageProgramMappingId) {
  return function (dispatch) {
    //dispatch(setBlockingState(true));
    let url = "/question/save/MainQuestionnaire";
    axios
      .post(url, mainQuestionDto, {
        headers: {
          'Authorization': 'Bearer ' + getToken(),
          "Content-type": "application/json",
          Accept: "application/json",
          Pragma: "no-cache"
        }
      })
      .then(response => {
        //dispatch(setBlockingState(false));
        //  toastr.success(I18n.t("ServerMessage." + response.data.desc));

        dispatch({
          type: "QUESTION_ID",
          payload: response.data.desc
        });
        dispatch(getQuestionSummary(packageProgramMappingId, mainQuestionDto.chapterId, response.data.desc));
        dispatch(toSetSelectedQuestionId(response.data.desc));
      })
      .catch(function (error) {
        //dispatch(setBlockingState(false));
        toastr.error("" + error);
      });
  };
}



export function deleteMainQuestion(id, userId, packageProgramMappingId, nextQuestionId, chapterId) {
  return function (dispatch) {
    //dispatch(setBlockingState(true));

    let url = "/question/mainQuestionnaire/" + id + "/" + userId;

    
    axios
      .delete(url, {
        headers: {
          'Authorization': 'Bearer ' + getToken(),
          "Content-type": "application/json",
          Accept: "application/json",
          Pragma: "no-cache"
        }
      })
      .then(response => {
        //dispatch(setBlockingState(false));
        // toastr.success(I18n.t("ServerMessage." + response.data.desc));
        if (response.data.desc && response.data.desc !== 0) {
          dispatch(getQuestionSummary(packageProgramMappingId, chapterId, response.data.desc));
        }
        else {
          dispatch(getQuestionSummary(packageProgramMappingId));
        }
      })
      .catch(function (error) {
        //dispatch(setBlockingState(false));
        toastr.error("" + error);
      });
  };
}


export function deleteNmscMainQuestion(id, userId, packageProgramMappingId, nextQuestionId, chapterId,nmscCode,nmscLang) {
  return function (dispatch) {
    let url = "/question/delete/nmscMainQuestionnaire/" + id + "/" + userId +"/"+nmscCode+"/"+nmscLang;

      axios
        .delete(url, {
          headers: {
            'Authorization': 'Bearer ' + getToken(),
            "Content-type": "application/json",
            Accept: "application/json",
            Pragma: "no-cache"
          }
        })
        .then(response => {
          //dispatch(setBlockingState(false));
          // toastr.success(I18n.t("ServerMessage." + response.data.desc));
          if (response.data.desc && response.data.desc !== 0) {
            dispatch(getNmscQuestionSummary(packageProgramMappingId,nmscCode, nmscLang,chapterId, response.data.desc,false));
          }
          else {
            dispatch(getNmscQuestionSummary(packageProgramMappingId,nmscCode, nmscLang));
          }

        })
        .catch(function (error) {
          //dispatch(setBlockingState(false));
          toastr.error("" + error);
        });
    }
  };


export function toSetSelectedQuestionId(questionId) {
  return function (dispatch) {
    dispatch({
      type: "QUESTION_ID",
      payload: questionId
    });
  };
}

export function getStatCount(packageProgramMappingId, nmscCode) {
  return function (dispatch) {
    //dispatch(setBlockingState(true));
    axios
      .get(
        "/question/statCount/" + packageProgramMappingId + "/" + nmscCode,
        {
          headers: {
            'Authorization': 'Bearer ' + getToken(),
            "Content-type": "application/json",
            Accept: "application/json",
            Pragma: "no-cache"
          }
        }
      )
      .then(response => {
        //dispatch(setBlockingState(false));
        dispatch({
          type: "STAT_COUNT",
          payload: response.data
        });
      })
      .catch(function (error) {
        //dispatch(setBlockingState(false));
        console.error(error);
      });
  };
}
export function saveNmscMainQuestion(nmscQuestionDto, questionId, packageProgramMappingId) {
  return function (dispatch) {
    //dispatch(setBlockingState(true));
    let url = "/question/save/NmscMainQuestionnaire";
    axios
      .post(url, nmscQuestionDto, {
        headers: {
          'Authorization': 'Bearer ' + getToken(),
          "Content-type": "application/json",
          Accept: "application/json",
          Pragma: "no-cache"
        }
      })
      .then(response => {
        //dispatch(setBlockingState(false));
        //  toastr.success(I18n.t("ServerMessage." + response.data.desc));

        dispatch({
          type: "QUESTION_ID",
          payload: response.data.desc
        });
        dispatch(getNmscQuestionSummary(packageProgramMappingId, nmscQuestionDto.nmscCode, nmscQuestionDto.nmscLang, nmscQuestionDto.chapterId, response.data.desc));
      })
      .catch(function (error) {
        //dispatch(setBlockingState(false));
        toastr.error("" + error);
      });
  };

}

export function saveLocalisedMainQuestionnaire(nmscQuestionDto, questionId, packageProgramMappingId) {
  return function (dispatch) {
    //dispatch(setBlockingState(true));
    let url = "/question/save/NmscLocalisedMainQuestionnaire";
    axios
      .post(url, nmscQuestionDto, {
        headers: {
          'Authorization': 'Bearer ' + getToken(),
          "Content-type": "application/json",
          Accept: "application/json",
          Pragma: "no-cache"
        }
      })
      .then(response => {
        //dispatch(setBlockingState(false));
        //  toastr.success(I18n.t("ServerMessage." + response.data.desc));

        // dispatch({
        //   type: "QUESTION_ID",
        //   payload: response.data.desc
        // });
        dispatch(getNmscQuestionSummary(packageProgramMappingId, nmscQuestionDto.nmscCode, nmscQuestionDto.nmscLang, nmscQuestionDto.chapterId, response.data.desc));
      })
      .catch(function (error) {
        //dispatch(setBlockingState(false));
        toastr.error("" + error);
      });
  };



}
