import React from "react";
import { connect } from "react-redux";
import { Container, Row, Col } from "reactstrap";
import { withRouter } from "react-router-dom";
import { withTheme } from "../../../themes/theming";
import "./../index.css";
import * as AppConst from "../../../AppConstant";
import ReachItStatisticsModal from "./reachItStatisticsModal";
import { downloadMonthlyFile } from "../../../actions/dashboardAction";
let I18n = require("react-redux-i18n").I18n;

class LatestFilePanel extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: false,
      firstItem: {
        fileId: 0,
        fileName: "File not processed for month January-2023",
        downloadCount: 0,
        totalNmscOrDealerCount: 0,
        totalDealerCount: 0,
        validFile: false,
      },
    };
  }
  componentWillReceiveProps(nextProps) {
    if (nextProps.monthlyFileData && nextProps.monthlyFileData.length) {
      let fileList = nextProps.monthlyFileData;
      let firstItem = fileList[0];
      this.setState({ firstItem: firstItem });
    }
  }
  modalOpen = () => {
    this.setState({ modal: !this.state.modal });
  };

  downloadMonthlyFile = () => {
    let statusDTO = this.state.firstItem.statusDTO;
    let fileDto = {
      fileId: this.state.firstItem.fileId,
      nmsc: this.props.user.userMdl.nmsc,
      userId: this.props.user.userMdl.username,
      userRole: this.props.user.userMdl.roles.includes(
        AppConst.REACHIT_ROLES.KET_REACHIT_Admin
      )
        ? "KET-ReachIT Admin"
        : this.props.user.userMdl.roles.includes(
            AppConst.REACHIT_ROLES.KET_REACHIT_NMSC_Admin
          )
        ? "KET-ReachIT NMSC User"
        : "KET-ReachIT Dealer",
    };
    if (
      typeof statusDTO !== "undefined" &&
      statusDTO !== null &&
      statusDTO !== undefined
    ) {
      if (Object.keys(statusDTO).length) {
        this.props.dispatch(downloadMonthlyFile(statusDTO, fileDto));
      }
    }
  };

  render() {
    return (
      <Container
        fluid
        className="ml-0 mb-2 pb-0"
        style={{ border: "1px solid grey", borderRadius: "10px" }}
      >
        <Row>
          <span className="sectionLabel"></span>
        </Row>
        <Row className="mt-2 mb-2">
          <Col md="2" className="textDisplay">
            {this.state.firstItem.fileName}
          </Col>
          <Col md="2">
            <button
              className={"button-" + this.props.theme.name}
              disabled={this.state.firstItem.validFile ? false : true}
              style={{ padding: "0.3rem 25px 0.3rem 25px" }}
              onClick={() => this.downloadMonthlyFile()}
            >
              {I18n.t("tmeAdminMaster.download")}
            </button>
          </Col>
          {this.props.user.userMdl.roles.includes(
            AppConst.REACHIT_ROLES.KET_REACHIT_Dealer_Champion
          ) || this.state.firstItem.validFile === false ? (
            ""
          ) : (
            <Col md="3" className="textDisplay">
              {this.props.user.userMdl.roles.includes(
                AppConst.REACHIT_ROLES.KET_REACHIT_NMSC_Admin
              )
                ? I18n.t("Table.dealer") +
                  " " +
                  I18n.t("reachItDashboard.downloads")
                : I18n.t("Table.nmsc") +
                  " " +
                  I18n.t("reachItDashboard.downloads")}
              &nbsp;&nbsp;
              
              {this.state.firstItem.downloadCount}
              &nbsp; / &nbsp;
              {this.state.firstItem.totalNmscOrDealerCount === 0 ? (
                "0"
              ) : (
                <a
                  style={{
                    textDecoration: "underline",
                    color: "cornflowerblue",
                  }}
                  onClick={(e) => this.modalOpen()}
                >
                    {this.state.firstItem.totalNmscOrDealerCount}
                </a>
              )}
            
            </Col>
          )}
        </Row>
        {this.state.modal === true ? (
          <ReachItStatisticsModal
            modal={this.state.modal}
            toggle={this.modalOpen}
            fileData={this.state.firstItem}
            reachItRoleList={
              this.props.user.userMdl.roles.includes(
                AppConst.REACHIT_ROLES.KET_REACHIT_Admin
              )
                ? AppConst.REACHIT_ROLES.KET_REACHIT_NMSC_Admin
                : AppConst.REACHIT_ROLES.KET_REACHIT_Dealer_Champion
            }
          />
        ) : null}
      </Container>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.loginReducer,
    blocking: state.loginReducer,
    router: state.routing,
    monthlyFileData: state.dashboardReducer.monthlyFileData,
  };
};
export default withRouter(connect(mapStateToProps)(withTheme(LatestFilePanel)));
