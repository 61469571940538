import React from 'react';
import { connect } from 'react-redux';
import BootstrapTable2 from 'react-bootstrap-table-next';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import cellEditFactory from 'react-bootstrap-table2-editor';
import { withRouter } from 'react-router-dom';
import { Row, Col } from 'mdbreact';
import { withTheme } from '../../themes/theming';
import { push } from "connected-react-router";
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css' // Import css
import './index.css';
import ActionKpiModal from './actionKpiModal'
import { fetchActivityKpis, saveActivity, searchActivity,saveVisibilityOnUpdate } from '../../actions/activityAction';
import { toastr } from "react-redux-toastr";
import moment from 'moment';
var I18n = require('react-redux-i18n').I18n;

class ActionKpiTable extends React.Component {
    /* eslint-disable */
    constructor(props) {
        super(props);
        this.state = {
            nmscList: [],
            selected: [],
            selectedMandatory: [],
            localKpiIdList: [],
            user: [],
            nmscLocalLangList: [],
            toolboxId: '5',
            updatedActivityIds: [],
            activityKpis: [],
            monthlyTarget: [],
            modal: false,
            initialChangeFlag: false,
			mandatory: true,
        };
    }
    componentWillMount() {
        if (this.props.activity !== undefined && this.props.activity.activityId !== undefined) {
            this.props.dispatch(fetchActivityKpis(this.props.activity.activityId, this.props.user.userMdl.lang, this.props.user.userMdl.nmsc, this.props.user.userMdl.activeProgramID, this.props.activity, this.props.searchCriteria));
        }
    }
    componentWillReceiveProps(nextProps) {
        if (nextProps.user) {
            this.setState({ user: nextProps.user });
        }
        if (nextProps.nmscList) {
            this.setState({ nmscList: nextProps.nmscList });
        }
        if (nextProps.activityKpis) {
            this.setState({ activityKpis: nextProps.activityKpis });
        }

        if (nextProps.refreshRequired) {
            this.props.dispatch(fetchActivityKpis(this.props.activity.activityId, this.props.user.userMdl.lang, this.props.user.userMdl.nmsc, this.props.user.userMdl.activeProgramID, this.props.activity, this.props.searchCriteria));
            this.props.dispatch({
                type: "REFRESH_DONE"
            });
        }
        //    
        // let activityKpisArr = [];    
        // if (this.state.activityKpis[0] && this.state.activityKpis[0].monthlyTarget && this.state.activityKpis[0].monthlyTarget.length > 0) {
        //     this.state.activityKpis.map(r => {
        //         for (const item in this.state.activityKpis[0].monthlyTarget) {                
        //             r.monthlyTarget[item].dealerActivityId = r.dealerActivityId
        //             r.monthlyTarget[item].unit = r.unit
        //         }
        //         activityKpisArr.push(r)
        //     });
        //     this.setState({ activityKpis : activityKpisArr});
        // }
        //
    }

    save = () => {
        let data = {
            userId: this.props.user.userMdl.username,
            kpiDTOList: this.state.activityKpis
        };
        this.setState({ initialChangeFlag: false });
        this.props.dispatch(saveActivity(this.props.activity.activityId, this.props.user.userMdl.activeProgramID, data, this.props.activity, this.props.searchCriteria));
    //check Dealer Champion Role
    var isDealerUser = false;
    if (this.props.user.userMdl.roles !== undefined) {
      var roles = this.props.user.userMdl.roles;
      for (var i = 0; i < roles.length; i++) {
        if (roles[i].toUpperCase() ==="KET DEALER CHAMPION" || roles[i].toUpperCase() === "KET DEALER INTERNAL" || roles[i].toUpperCase() === "KET NMSC External" || this.props.user.userMdl.roles[i] === "KET NMSC User" || this.props.user.userMdl.roles[i] === "KET NMSC Field") {
            isDealerUser = true;
          break;
        }
      }
    }
    console.log(isDealerUser);
    if(isDealerUser===true){
        var viewStatusDTO = {
            activityId:  this.props.activity.activityId,
            loginId: this.props.user.userMdl.username,
            kpiUpdated :"True",
        }
        this.props.dispatch(saveVisibilityOnUpdate(viewStatusDTO));
    }

    }
    reRenderTable = () => {
        this.props.dispatch(fetchActivityKpis(this.props.activity.activityId, this.props.user.userMdl.lang, this.props.user.userMdl.nmsc,  this.props.user.userMdl.activeProgramID,this.props.activity, this.props.searchCriteria));
    }
    saveAction = () => {
        //check for NMSC Admin Role
        var isAdminUser = false;
        if (this.props.user.userMdl.roles !== undefined) {
            var roles = this.props.user.userMdl.roles;
            for (var i = 0; i < roles.length; i++) {
                if (roles[i].toUpperCase().indexOf('KET NMSC ADMIN') !== -1) {
                    isAdminUser = true;
                    break;
                }
            }
        }
        var initial = false;
        var initialCount = 0;
        var target = false;
        var targetCount = 0;
        var retailerTarget = false;
        var retailerCount = 0;
        // Check if all Initial and Target values are present.
        if(this.props.user.userMdl.activeProgram === "TSM" ||  this.props.user.userMdl.activeProgram === "TSM_BNP" || this.props.user.userMdl.activeProgram === "TSM_USED_CAR" || this.props.user.userMdl.activeProgram === "TSM_SURVEYS"){
        for (var i = 0; i < this.state.activityKpis.length; i++) {
            if (this.state.activityKpis[i].initial === null ||
                this.state.activityKpis[i].initial === '') {
                initial = true;
                initialCount++;
            }
        }
    }
        for (var i = 0; i < this.state.activityKpis.length; i++) {
            if (this.state.activityKpis[i].target === null ||
                this.state.activityKpis[i].target === "") {
                target = true;
                targetCount++;
            }
        }

        for (var i = 0; i < this.state.activityKpis.length; i++) {
            if (this.state.activityKpis[i].retailerTarget === null ||
                this.state.activityKpis[i].retailerTarget === '' || this.state.activityKpis[i].retailerTarget === "") {
                retailerTarget = true;
                retailerCount++;
            }
        }

        if (target && initial && retailerTarget) {
            toastr.error(I18n.t('activity.kpi.mandatory'));
            return;
        } else if (target && targetCount !== this.state.activityKpis.length) {
            toastr.error(I18n.t('activity.kpi.mandatory'));
            return;
        } else if (retailerTarget && retailerCount !== this.state.activityKpis.length) {
            toastr.error(I18n.t('activity.kpi.mandatory'));
            return;
        }
        else if (initial && initialCount !== this.state.activityKpis.length) {
            toastr.error(I18n.t('activity.kpi.mandatory'));
            return;
        }
        for (var i = 0; i < this.state.activityKpis.length; i++) {
            /*   if (this.state.activityKpis[i].initial === null ||
                  this.state.activityKpis[i].initial === '' ||
                  this.state.activityKpis[i].target === null ||
                  this.state.activityKpis[i].target === '') {
                  toastr.error(I18n.t('activity.kpi.mandatory'));
                  return;
              } */
            /*    else { */
            if (this.state.activityKpis[i].monthlyTarget && this.state.activityKpis[i].monthlyTarget[0]) {
                /*  for (var j = 0; j < this.state.activityKpis[i].monthlyTarget.length; j++) { */
                let val = this.state.activityKpis[i].monthlyTarget[0].target && this.state.activityKpis[i].monthlyTarget[0].target !== '' ? this.state.activityKpis[i].monthlyTarget[0].target.replace('' + this.state.activityKpis[i].unit, '').trim() : this.state.activityKpis[i].monthlyTarget[0].target;
                if (val === null ||
                    val === '') {
                    toastr.error(I18n.t('activity.kpi.mandatoryTargets'));
                    return;
                }
                // }
            }
            // }
        }
        if (this.props.activity.kpiAvailable || isAdminUser) {
            this.save();
        } else {
            if (this.state.initialChangeFlag) {
                confirmAlert({
                    customUI: ({ title, message, onClose }) => {
                        return (
                            <div className="saveConfirmBox">
                                <div className={"titleClass-" + this.props.theme.name}>{I18n.t('standardMessages.saveDataConfirmation')}</div>
                                <div className="smallFont"><i>{I18n.t('standardMessages.saveKPIWarning')}</i></div>
                                <div className="modal-footer" style={{ borderTop: '0px' }}>
                                    <button className={"buttonCancel-" + this.props.theme.name} onClick={() => {
                                        this.reRenderTable()
                                        onClose()
                                    }}>{I18n.t('tmeAdminMaster.cancel')}</button>
                                    <button className={"button-" + this.props.theme.name} onClick={() => {
                                        this.save()
                                        onClose()
                                    }}>{I18n.t('standardMessages.yes')}</button>
                                </div>
                            </div>
                        )
                    }
                })
            }
            else {
                this.save();
            }
        }

    }

    updateIdList = (id) => {
        if (this.state.updatedActivityIds.indexOf(id) < 0) {
            this.setState({ updatedActivityIds: [...this.state.updatedActivityIds, id] });
        }
        this.setState({ initialChangeFlag: true });
    }

    backToActivity = () => {
        if (this.props.searchCriteria.program) {
            this.props.dispatch(searchActivity(this.props.searchCriteria));
            this.props.dispatch(
                push({
                    pathname: "/activity",
                    query: { activityDto: this.props.searchCriteria }
                })
            );
        }
        else {
            this.props.dispatch(
                push({pathname: "/certification"})
            );
        }
      
    }
    toggleModal = () => {
        this.setState({
            modal: !this.state.modal,
        });
    }

    getMonthNameFromNumber = (number) => {
        var mlist = [I18n.t('months.jan'), I18n.t('months.feb'), I18n.t('months.mar'), I18n.t('months.apr'), I18n.t('months.may'), I18n.t('months.jun'), I18n.t('months.jul'), I18n.t('months.aug'), I18n.t('months.sep'), I18n.t('months.oct'), I18n.t('months.nov'), I18n.t('months.dec')];
        return mlist[number];
    }

    updateValue(event, props, item) {
        this.state.activityKpis.map(r => {
            if (props.row.dealerActivityId !== 0) {
                if (r.dealerActivityId === props.row.dealerActivityId) {
                    r.monthlyTarget[item].target = event.target.value;
                    this.forceUpdate();
                }
            }
            else {
                if (r.definition === props.row.definition && r.name === props.row.name) {
                    r.monthlyTarget[item].target = event.target.value;
                    this.forceUpdate();
                }
            }
        });
    }

         
    onToggleRegion = (event, props, item) => {
        let val =  event.target.value.replace(' ' + props.row.unit, '');
        val =  event.target.value.replace('' + props.row.unit, '');
        console.log("val",val)
        var Number = /^[\d\/\(\)\- \.]+$/;
        if (Number.test(val) || val === "") {
            event.target.value = val
            if (val.length > 10) {
                toastr.error(I18n.t('activity.kpi.kpiLength'));
                event.target.value =""
                props.row.monthlyTarget[item].target = "";
            }else{        
                console.log("props.row.",props.row)   
                props.row.monthlyTarget[item].target = (val); 
                this.state.activityKpis.map(r => {
                    if (props.row.dealerActivityId != 0) {
                        if (r.dealerActivityId === props.row.dealerActivityId) {
                            r.monthlyTarget[item].target = (val);
                            this.forceUpdate();
                        }
                    }
                    else {
                        if (props.row.dealerActivityId !== 0) {
                            if (r.dealerActivityId === props.row.dealerActivityId) {
                                r.monthlyTarget[item].target = (val);
                                this.forceUpdate();
                            }
                        }
                        else {
                            if (r.definition === props.row.definition && r.name === props.row.name) {
                                r.monthlyTarget[item].target = (val);
                                this.forceUpdate();
                            }
                        }
                    }
                });
            }
        } else {
            toastr.error(I18n.t('activity.kpi.kpi'));
            event.target.value = props.row.target
        }
    }


    buttonFormatter(cell, row) {
        return (
            <div className="btn-toolbar">
                {row.additionalInfo && row.additionalInfo.length > 0 ?
                    <a><div style={{ whiteSpace: 'pre' }} title={row.additionalInfo} className="info-icon" /></a> : ""}
            </div>
        );
    }
    formatEditor = (props, item) => {
        console.log("props.row.monthlyTarget[item].target",props.row.monthlyTarget[item])
        return (
            <input type="text" 
            autoFocus
            defaultValue={props.row.monthlyTarget[item].target}
            className="form-control editor edit-text"
            onChange={(e) => this.onToggleRegion(e, props, item)} 
            onKeyDown={e => this.autoTabMonth(e, props, item)}
            onBlur={(e) => this.onToggleRegion(e, props, item)} 
            />
        );
    }
    
    autoTabMonth = (e, props, item) => {
        if (e.keyCode === 9) {
          this.onToggleRegion(e, props, item)
        }
    }; 
    autoMonthTab = (e, cell, item) => {
        if (e.keyCode === 9) {
            this.onToggleRegionTab(e, cell, item)
          }
    }; 
    onToggleRegionTab = (event, props, item) => {
        console.log("props",props)
        console.log("item",item)
        // let val = event.target.value.replace(/[^0-9\.]+/g, "");
        let val =  event.target.value.replace(' ' + props[item].unit, '');
        var Number = /^[\d\/\(\)\- \.]+$/;
        console.log("val",val)
        if (Number.test(val) || val === "") {
            event.target.value = val
            if (val.length > 10) {
                toastr.error(I18n.t('activity.kpi.targetLength'));
                event.target.value =""
                props[item].target = "";
            }else{        
                console.log("props.row.",props)   
                props[item].target = (val); 
                this.state.activityKpis.map(r => {
                    if (props[item].dealerActivityId !== 0) {
                        if (r.dealerActivityId === props[item].dealerActivityId) {
                            r.monthlyTarget[item].target = (val);
                            this.forceUpdate();
                        }
                    }
                    else {
                        if (props[item].dealerActivityId !== 0) {
                            if (r.dealerActivityId === props[item].dealerActivityId) {
                                r.monthlyTarget[item].target = (val);
                                this.forceUpdate();
                            }
                        }
                        else {
                            if (r.definition === props[item].definition && r.name === props[item].name) {
                                r.monthlyTarget[item].target = (val);
                                this.forceUpdate();
                            }
                        }
                    }
                });
            }
        } else {
            toastr.error(I18n.t('activity.kpi.target'));
            event.target.value = ""
        }
    };  
    onChangeTextBox = (e, rowIndex) => {


    }

    inputFormatter = (cell, row, rowIndex) => {
        return (
            <input type="text" value={cell ? cell : ""} onChange={(e) => this.onChangeTextBox(e, rowIndex)} className="form-control editableColumn" />
        );
    }
    toPoint = (cell, row, rowIndex) => {
        return (
            <input
                type="text"
                defaultValue={(cell && cell !== undefined) ? cell : ""}
                onChange={e => this.onChangeTextBox(e, row, cell)}
                className="form-control"
                onKeyDown={e => this.autoTab(e, row, cell)}
                onBlur={e => this.onTabChange(e, row, cell)}
                disabled={false}
            />
        );
      };

    onChangeTextBox = (e, row, cell) => {
    }

    autoTab = (e, row, cell) => {
        if (e.keyCode === 9) {
          this.onTabChange(e, row, cell)
        }
    };  

    onTabChange = (e, row, cell) => {
        let val = e.target.value ? e.target.value.trim().replace(' ' + row.unit, '') : e.target.value;
        var Number = /^[\d\/\(\)\- \.]+$/;
        console.log("val",val)
        if (Number.test(val) || val === "") {
            e.target.value = val
            if (val.length > 10) {
                toastr.error(I18n.t('activity.kpi.targetLength'));
                e.target.value = row.target
            }
            row.target = val  
            this.state.activityKpis.map(obj=>{
                if(obj.kpiId === row.kpiId){
                    obj.target = val
                }
            })
        } else {
            toastr.error(I18n.t('activity.kpi.target'));
            e.target.value = ""
        }
    };    
    toPointRetailer = (cell, row, rowIndex) => {
        return (
            <input
                type="text"
                defaultValue={(cell && cell !== undefined) ? cell : ""}
                onChange={e => this.onChangeTextBox(e, row, cell)}
                className="form-control"
                onKeyDown={e => this.autoTab(e, row, cell)}
                onBlur={e => this.onTabChangeRetailer(e, row, cell)}
                disabled={false}
            />
        );
      };
      autoTab = (e, row, cell) => {
        if (e.keyCode == 9) {
          this.onTabChangeRetailer(e, row, cell)
        }
    };  

    onTabChangeRetailer = (e, row, cell) => {
        console.log(" onTabChangeRetailer e",e)
        let val = e.target.value ? e.target.value.trim().replace(' ' + row.unit, '') : e.target.value;
        var Number = /^[\d\/\(\)\- \.]+$/;
        console.log("val",val)
        if (Number.test(val) || val === "") {
            e.target.value = val
            if (val.length > 10) {
                toastr.error(I18n.t('activity.kpi.targetLength'));
                e.target.value = row.retailerTarget
            }
            row.retailerTarget = val    
            this.state.activityKpis.map(obj=>{
                if(obj.kpiId === row.kpiId){
                    obj.retailerTarget = val
                }
            })
        } else {
            toastr.error(I18n.t('activity.kpi.target'));
            e.target.value = row.retailerTarget
        }
    };
    toPointInitial = (cell, row, rowIndex) => {
        return (
            <input
                type="text"
                defaultValue={(cell && cell !== undefined) ? cell : ""}
                onChange={e => this.onChangeTextBox(e, row, cell)}
                className="form-control"
                onKeyDown={e => this.autoTab(e, row, cell)}
                onBlur={e => this.onTabChangeInitial(e, row, cell)}
                disabled={false}
            />
        );
      };
      autoTab = (e, row, cell) => {
        if (e.keyCode === 9) {
          this.onTabChangeInitial(e, row, cell)
        }
    };  

    onTabChangeInitial = (e, row, cell) => {
        console.log(" onTabChangeInitial e",e)
        let val = e.target.value ? e.target.value.trim().replace(' ' + row.unit, '') : e.target.value;
        var Number = /^[\d\/\(\)\- \.]+$/;
        console.log("val",val)
        if (Number.test(val) || val === "") {
            e.target.value = val
            if (val.length > 10) {
                toastr.error(I18n.t('activity.kpi.targetLength'));
                e.target.value = row.initial
            }
            row.initial = val     
            this.state.activityKpis.map(obj=>{
                if(obj.kpiId === row.kpiId){
                    obj.initial = val
                }
            })
        } else {
            toastr.error(I18n.t('activity.kpi.target'));
            e.target.value = ""
        }
    };
	flagFormatter(cell, row, rowIndex) {
        return (
            <div>
                {cell === true ?
                    <div title={I18n.t('Table.mandatory')}  className="active-flag" /> :
                    <div title="Not Mandatory" className="inactive-flag" size="2x" />
                }
            </div>
        );

    }

    render() {
        console.log("this.state.activityKpis",this.state.activityKpis)
        let roles = [];
        let isAdminUser = false;
        let isKaizenChamp = false;
        if (this.props.user.userMdl.roles !== undefined) {
            roles = this.props.user.userMdl.roles;
            for (var i = 0; i < roles.length; i++) {
                if (roles[i].toUpperCase().indexOf('ADMIN') !== -1) {
                    isAdminUser = true;
                    break;
                }
                else if (roles[i].toUpperCase().indexOf('KET DEALER CHAMPION') !== -1) {
                    isKaizenChamp = true;
                }
            }
        }

        let isRetailerEditable = false;
        if (this.props.user.userMdl.accessControl !== undefined && this.props.user.userMdl.accessControl.length > 0) {
            let editAccess = this.props.user.userMdl.accessControl.filter(access => access.function === 'ACTIVITY_DETAIL_RETAILER_ACTUAL');
            if (editAccess !== undefined && editAccess.length > 0 && Object.assign({}, editAccess)[0].accessType === 'R/W') {
                isRetailerEditable = true;
            }
        }

        let isTargetEditable = false;
        if (this.props.user.userMdl.accessControl !== undefined && this.props.user.userMdl.accessControl.length > 0) {
            let editAccess = this.props.user.userMdl.accessControl.filter(access => access.function === 'ACTIVITY_DETAIL_TARGET');
            if (editAccess !== undefined && editAccess.length > 0 && Object.assign({}, editAccess)[0].accessType === 'R/W') {
                isTargetEditable = true;
            }
        }

        let isPaneBenchmarkEditable = false;
        if (this.props.user.userMdl.accessControl !== undefined && this.props.user.userMdl.accessControl.length > 0) {
            let editAccess = this.props.user.userMdl.accessControl.filter(access => access.function === 'ACTIVITY_DETAIL_PanE_BENCHMARK');
            if (editAccess !== undefined && editAccess.length > 0 && Object.assign({}, editAccess)[0].accessType === 'R/W') {
                isPaneBenchmarkEditable = true;
            }
        }

        let isRetailerTargetEditable = false;
        if (this.props.user.userMdl.accessControl !== undefined && this.props.user.userMdl.accessControl.length > 0) {
            let editAccess = this.props.user.userMdl.accessControl.filter(access => access.function === 'ACTIVITY_DETAIL_RETAILERTARGET');
            if (editAccess !== undefined && editAccess.length > 0 && Object.assign({}, editAccess)[0].accessType === 'R/W') {
                isRetailerTargetEditable = true;
            }
        }

        let isBRitUser = false;
        {
            this.props.user.userMdl.activeProgram  ===  "BRiT"   ? isBRitUser = true : false
        }
        let editAccess = false;
        if (this.props.user.userMdl.accessControl !== undefined && this.props.user.userMdl.accessControl.length > 0) {
            let activityAccess = this.props.user.userMdl.accessControl.filter(access => access.function === 'ACTIVITY');
            if (activityAccess !== undefined && activityAccess.length > 0 && Object.assign({}, activityAccess)[0].accessType === 'R/W' && this.props.activity.status !== "Completed" && this.props.activity.status !== "Rejected" && this.props.activity.status !== "Closed") {
                editAccess = true;
            }
        }

        let editTargetAccess = false;
        if (this.props.user.userMdl.accessControl !== undefined && this.props.user.userMdl.accessControl.length > 0) {
            let activityAccess = this.props.user.userMdl.accessControl.filter(access => access.function === 'ACTIVITY_DETAIL');
            if (activityAccess !== undefined && activityAccess.length > 0 && Object.assign({}, activityAccess)[0].accessType === 'R/W' && this.props.activity.status !== "Completed" && this.props.activity.status !== "Rejected" && this.props.activity.status !== "Closed") {
                editTargetAccess = true;
            }
        }

        let addTargetAccess = false;
        if (this.props.activity.firstVisit) {
            // console.log("activitykpi",this.state.activityKpis[0],this.state.activityKpis[0].monthlyTarget);
            var addMonths = this.state.activityKpis[0] && this.state.activityKpis[0].monthlyTarget && this.state.activityKpis[0].monthlyTarget.length > 0 ? (this.state.activityKpis[0].monthlyTarget.length) : 0;
            var dateParts = this.props.activity.firstVisit.split("/");
            // month is 0-based, that's why we need dataParts[1] - 1
            var firstVisit = new Date(+dateParts[2], dateParts[1] - 1, +dateParts[0]);
            firstVisit = moment(firstVisit)// moment(...) can also be used to parse dates in string format
            firstVisit.add(addMonths, 'months');
            firstVisit = moment(firstVisit).format("MM/DD/YYYY");
            firstVisit = new Date(firstVisit)
            var currentDate = new Date();
            currentDate.setDate(0); // 0 will result in the last day of the previous month
            //currentDate.setDate(1);
            var status = this.props.activity.status ? this.props.activity.status : this.props.selectedActivity.status;
            if (status && status === "Ongoing" && currentDate >= firstVisit) {
                addTargetAccess = true;
            }
        }

        let allInitialTarget = true;
        if(this.props.user.userMdl.activeProgram  ===  "BRiT"){
            for (var i = 0; i < this.state.activityKpis.length; i++) {
                if (this.state.activityKpis[i].target === null ||
                    this.state.activityKpis[i].target === '') {
                    allInitialTarget = false;
                    break;
                }
            }
        }

        if(this.props.user.userMdl.activeProgram === "TSM" ||  this.props.user.userMdl.activeProgram === "TSM_BNP" || this.props.user.userMdl.activeProgram === "TSM_USED_CAR" || this.props.user.userMdl.activeProgram === "TSM_SURVEYS"){
        for (var i = 0; i < this.state.activityKpis.length; i++) {
            if (this.state.activityKpis[i].initial === null ||
                this.state.activityKpis[i].initial === '' ||
                this.state.activityKpis[i].target === null ||
                this.state.activityKpis[i].target === '') {
                allInitialTarget = false;
                break;
            }
        }
    }

        if(this.props.user.userMdl.activeProgram === "EA"){
            for (var i = 0; i < this.state.activityKpis.length; i++) {
                //Validating if KPIs got their evaluation entries created or not
                if (!this.state.activityKpis[i].dealerActivityId || 
                    this.state.activityKpis[i].initial === null ||
                    this.state.activityKpis[i].initial === '' ||
                    this.state.activityKpis[i].target === null ||
                    this.state.activityKpis[i].target === '') {
                    allInitialTarget = false;
                    break;
                }
            }
        }

        const columns = [
            {
                dataField: 'additionalInfo',
                text: "",
                formatter: this.buttonFormatter,
                editable: false,
                title: true,
                style: { width: "5%" },
                headerStyle: { width: "5%" }
            },
            {
                // dataField: 'name',
                dataField:this.state.activityKpis[0] && this.state.activityKpis[0].showEngData?'nameEn':'name',
                text: I18n.t('Table.kpi'),
                /*  sort: true, */
                style: { width: "23%", textAlign: "left", whiteSpace: "nowrap" },
                headerStyle: { width: "22%", textAlign: "left", wordWrap: "break-word", whiteSpace: "nowrap", paddingLeft: "3px" },
                editable: false,
                title: true,
                headerTitle: true

            }, {
                dataField: this.state.activityKpis[0] && this.state.activityKpis[0].showEngData?'definitionEn':'definition',
                text: I18n.t('Table.definition'),
                /* sort: true, */
                style: { width: "15%", textAlign: "left", wordWrap: "break-word", whiteSpace: "nowrap" },
                headerStyle: { width: "17%", textAlign: "left", wordWrap: "break-word", whiteSpace: "nowrap" },
                editable: false,
                title: true,
                headerTitle: true
            }, {
                dataField: 'benchmark',
                text: I18n.t('Table.benchmark'),
                hidden: isBRitUser ? true : false,
                /*   sort: true, */
                style: { width: "15%", textAlign: "center", whiteSpace: "nowrap" },
                headerStyle: { width: "13%", textAlign: "center", wordWrap: "break-word", whiteSpace: "nowrap" },
                editable: false,
                title: true,
                headerTitle: true
            }, {
                dataField: 'benchmark',
                text: I18n.t('Table.penEbenchmark'),
                hidden: (!isBRitUser || this.props.user.userMdl.roles.includes("KET-BRiT Dealer Champion")||this.props.user.userMdl.roles.includes("KET-BRiT Dealer Internal")) ? true : false,
                /*  sort: true, */
                title: true,
                headerTitle: true,
                formatter: this.toPoint,
                style: { width: "12%", textAlign: "center", whiteSpace: "nowrap" },
                editable: isPaneBenchmarkEditable ? true : false,
                headerStyle: { width: "12%", textAlign: "center", wordWrap: "break-word", whiteSpace: "pre-line" },                
            }, {
                dataField: 'mandatory',
                text: I18n.t('Table.mandatory'),
                editable: false,
                //sort: true,
                formatter: this.flagFormatter,
                title:  true,
                headerTitle:  true,
                style: { width: "10%" },
                headerStyle: { width: "12%", textAlign:  "center",  wordWrap:  "break-word",  whiteSpace:  "nowrap"  }				
			}, {
                dataField: 'target',
                text: isBRitUser? I18n.t('Table.nmscTarget'): I18n.t('Table.target'),
                /*  sort: true, */
                title: true,
                headerTitle: true,
                formatter: this.toPoint,
                style: { width: "13%", textAlign: "center", whiteSpace: "nowrap" },
                editable: (cell, row, rowIndex, colIndex) => {
                    if ((editTargetAccess && (isAdminUser || cell === null || cell === undefined || cell.length === 0)) || isTargetEditable) {
                        return true;
                    }
                    return false;
                },
                headerStyle: { width: "13%", textAlign: "center", wordWrap: "break-word", whiteSpace: "nowrap" },

            },{
                dataField: 'retailerTarget',
                text: "Retailer Target",
                hidden: !isBRitUser ? true : false,
                /*  sort: true, */
                title: true,
                headerTitle: true,
                formatter: this.toPointRetailer,
                style: { width: "11%", textAlign: "center", whiteSpace: "nowrap" },
                headerStyle: { width: "9%", textAlign: "center", wordWrap: "break-word", whiteSpace: "pre-line" },
                editable: (isBRitUser || isRetailerTargetEditable) ? true : false,
                // editable: isRetailerTargetEditable ? true : false                
            }, {
                dataField:  'initial',
                text: I18n.t('Table.initial'),
                hidden: isBRitUser ? true : false,
                formatter: this.toPointInitial,
                title:  true,
                headerTitle:  true,
                style:  {  width:  "13%",  textAlign:  "center",  whiteSpace:  "nowrap"  },
                headerStyle:  {  width:  "11%",  textAlign:  "center",  wordWrap:  "break-word",  whiteSpace:  "nowrap"  },
                editable:  (cell,  row,  rowIndex,  colIndex)  =>  {
                    if  ((isKaizenChamp  ||  editTargetAccess)  &&  (isAdminUser  ||  cell  ===  null  ||  cell  ===  undefined  ||  cell.length  ===  0)) {
                        return  true;
                    }
                    return  false;
                },
            }, {
                dataField: 'initial',
                text: "Retailer Actual",
                hidden: !isBRitUser ? true : false,
                formatter: this.toPointInitial,
                title: true,
                headerTitle: true,
                style: { width: "11%", textAlign: "center", whiteSpace: "nowrap" },
                headerStyle: { width: "10%", textAlign: "center", wordWrap: "break-word", whiteSpace: "pre-line" },
                editable: (!isBRitUser || isRetailerEditable) ? true : false,                
            }
        ];



        const thc = [];
        if (this.state.activityKpis[0]) {
            for (const item in this.state.activityKpis[0].monthlyTarget) {
                // if (item != 0) {
                //     thc.push(
                //         <TableHeaderColumn key={item} headerAlign='center' dataAlign='center' columnTitle={true} editable={false} dataField='monthlyTarget' dataFormat={cell => cell[item].target} >{this.getMonthNameFromNumber(this.state.activityKpis[0].monthlyTarget[item].monthName) + " " + this.state.activityKpis[0].monthlyTarget[item].monthYear}</TableHeaderColumn>
                //     );
                // }
                // else {
                    thc.push(
                        <TableHeaderColumn 
                        key={item} 
                        headerAlign='center' 
                        dataAlign='center' 
                        dataField='monthlyTarget' 
                        dataFormat=
                            {cell => <div title={cell[item].target}>
                            <input type="text" value={cell ? cell[item].target : ""}
                            onChange={(e) => this.onToggleRegionTab(e, cell, item)} 
                            onKeyDown={e => this.autoMonthTab(e, cell, item)}
                            onBlur={(e) => this.onToggleRegionTab(e, cell, item)}
                            className="form-control editor edit-text editableColumn" />
                            </div>} 
                        customEditor={{ getElement: (onUpdate, props) => this.formatEditor(props, item) }}>
                            {this.getMonthNameFromNumber(this.state.activityKpis[0].monthlyTarget[item].monthName) + " " + this.state.activityKpis[0].monthlyTarget[item].monthYear}
                        </TableHeaderColumn>
                    );
                // }
            }
        }

        const cellEditProp = {
            mode: 'click',
            blurToSave: true
        };
        return (
            <div>
                {this.props.user.userMdl.activeProgram  !==  "BRiT"   ?
                    <div>
                        {editAccess && addTargetAccess && allInitialTarget ?
                            <a><div title={I18n.t('tmeAdminMaster.add')} onClick={this.toggleModal} className="add-icon" /></a> : <a><div title={I18n.t('standardMessages.addKpiNotAllowed')} className="add-icon-disabled" /></a>}
                    </div> : ""}
                <div className="actionKpiContainer" >
                    <div className="row">
                        <div className="col-md-12 mainContainer">
                            <div className='activityinitialTable'>
                                 <BootstrapTable2
                                    classes="activityKpiTable"
                                    keyField="kpiId"
                                    noDataIndication={I18n.t('tabsDetails.NoKPI')}
                                    data={this.state.activityKpis}
                                    columns={columns}
                                    cellEdit={cellEditFactory({
                                        beforeSaveCell(oldValue, newValue, row, column, done) {                                           
                                            return { async: true };
                                        }
                                    })}
                                />
                            </div>
                            <div className='activitytargetTable'>
                                {this.state.activityKpis[0] && this.state.activityKpis[0].monthlyTarget && this.state.activityKpis[0].monthlyTarget.length > 0 ?
                                    <BootstrapTable data={this.state.activityKpis}
                                        cellEdit={editAccess ? cellEditProp : false}>
                                        <TableHeaderColumn isKey={true} hidden={true} dataField='dealerActivityId'></TableHeaderColumn>
                                        {thc}
                                    </BootstrapTable>
                                    : ""}
                            </div>
                        </div>

                    </div>
                    <hr />
                    <Row>
                        <Col md="6" style={{ textAlign: "left" }}>
                            <img src={'images/back-' + this.props.theme.name + '.png'} alt="Back" onClick={this.backToActivity} className={this.props.theme.iconColor} style={{ cursor: "pointer" }} />
                        </Col>
                        <Col md="6" style={{ textAlign: "right" }}>
                            {editAccess ?
                                <div>
                                    <button className={"buttonCancel-" + this.props.theme.name} onClick={this.reRenderTable}>{I18n.t('tmeAdminMaster.cancel')}</button>
                                    <button className={"button-" + this.props.theme.name} onClick={this.saveAction}>{I18n.t('tmeAdminMaster.save')}</button>
                                </div>
                                : <div> </div>}
                        </Col>
                    </Row>
                    {this.state.modal === true ? (
                        <ActionKpiModal
                            modal={this.state.modal}
                            toggle={this.toggleModal}
                            activity={this.props.activity}
                            activityKpis={this.state.activityKpis}
                        />
                    ) : null}
                </div>
            </div>
        );
    }
}
const mapStateToProps = (state) => {
    return {
        user: state.loginReducer,
        blocking: state.loginReducer,
        router: state.routing,
        nmscList: state.tabReducer.nmscList,
        activityKpis: state.activityReducer.activityKpis,
        refreshRequired: state.activityReducer.refreshRequired
    }
}
export default withRouter(connect(mapStateToProps)(withTheme(ActionKpiTable)));