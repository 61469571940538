import React from "react";
import { withTheme } from "../../../themes/theming";
import '../index.css';
import $ from 'jquery';
import Select from "react-select";
import 'react-datepicker/dist/react-datepicker.css';
import { connect } from "react-redux";
import {MDBIcon } from "mdbreact";
import { withRouter } from "react-router-dom";
import { fetchNmscCertification ,filtersCertification, searchEmptyNmscCertification} from '../../../actions/certificationAction';
import ReactMultiSelectCheckboxes from 'react-multiselect-checkboxes';
import { fetchActivityFilters, fetchDealer, fetchDealerCode} from '../../../actions/activityAction.jsx';
import Autosuggest from 'react-autosuggest';
var I18n = require("react-redux-i18n").I18n;

class SearchFilter extends React.Component {
    /* eslint-disable */
    constructor(props) {
        super(props);
        this.state = {
            modal: false,
            show: false,
            nmscFlag: false,
            suggestions: [],
            suggestionsCode: [],
            valueDealer: '',
            valueDealerCode: '',
            nmscList: [],
            dealerList: [],
            dealerCodeList: [],
            brandList: [],
            countryList: [],
            programList: [{ "value": "All", "label": I18n.t('standardMessages.selectAll') },
            { "value": "None", "label": I18n.t('standardMessages.unSelectAll') },
            { value: "TSM", label: "TSM/LSM" },
            { value: "TSM_BNP", label: "Body & Paint" },
            { value: "TSM_USED_CAR", label: "Used Car" }],
            statusList: [{ label: "Yes", value: "Yes" }, { label: "No", value: "No" }],
            noOfCompletedToolBox: [{ "value": "All", "label": I18n.t('standardMessages.selectAll') },
            { "value": "None", "label": I18n.t('standardMessages.unSelectAll') },
            { value: "1", label: "1" },
            { value: "2", label: "2" },
            { value: "3", label: "3" },
            { value: "4", label: "4" },
            { value: "5", label: "5" },
            { value: "6", label: "6" },
            { value: "7", label: "7" },
            { value: "8", label: "8" },
            { value: "9", label: "9" },
            { value: "10", label: "10" },
            { value: ">10", label: ">10" }],
            selectedNmsc: [],
            selectedBrand: [],
            selectedCountry: [],
            selectedProgram: [],
            selectedStatus: [],
            selectedToolboxNo: [],
            dealerDto: {
                nmscCode: null,
                userId: this.props.user.userMdl.username,
                brand: null,
                country: null,
                dealer: null,
                status: null,
                program: null,
                toolboxNo: null,
                businessArea: this.props.user.userMdl.businessAreaKey ==="EA" ? "VALUE_CHAIN,BRiT" : this.props.user.userMdl.businessAreaKey
            },
            defaultAllObj: [{ "value": "All", "label": I18n.t('standardMessages.selectAll') },{ "value": "None", "label": I18n.t('standardMessages.unSelectAll') }],
        };
    }
    componentWillReceiveProps(nextProps) {
        let roles = [];
        roles = nextProps.user.userMdl.roles;

        //If user role is NMSC or DEALER then set flag as true
        // This will set the search activity toolbox drop down for nmsc user
        if (nextProps.user.userMdl.roles) {
            for (var i = 0; i < roles.length; i++) {
                if (roles[i].toUpperCase().indexOf("NMSC") !== -1 || roles[i].toUpperCase().indexOf("DEALER") !== -1) {
                    this.setState({ nmscFlag: true });
                    break;
                }
            }
        }
        if (nextProps.nmscList.nmscList) {
            if(this.state.nmscFlag){
                this.setState({ nmscList: nextProps.nmscList.nmscList });
            }else{
                this.setState({ nmscList: this.state.defaultAllObj.concat( nextProps.nmscList.nmscList) });
            }
        }
        if (nextProps.brandList.brandList) {
            this.setState({ brandList:nextProps.brandList.brandList });
        }
        if (nextProps.countryList.countryList) {
            this.setState({ countryList: this.state.defaultAllObj.concat(nextProps.countryList.countryList) });
        }

        if (nextProps.dealerList.dealerList) {
            this.setState({ dealerList: nextProps.dealerList.dealerList });
        }
        if (nextProps.dealerCodeData.dealerCodeData) {
            this.setState({ dealerCodeList: nextProps.dealerCodeData.dealerCodeData });
        }

        if (nextProps.flag) {
            $("#searchId").show();
            $("#hrId").hide();
        } else {
            $("#searchId").hide();
            $("#hrId").show();
        }

    }

    componentWillMount() {
        var isNMSCAdminUser = false;
        var isTMEAdminUser = false;
        var isNotAdmin = false;
        if (this.props.user.userMdl.roles !== undefined) {
            var roles = this.props.user.userMdl.roles;
            for (var i = 0; i < roles.length; i++) {
                if (roles[i].toUpperCase().indexOf('KET NMSC ADMIN') !== -1 || roles[i].toUpperCase().indexOf('KET-BRIT NMSC Admin') !== -1) {
                    isNMSCAdminUser = true;
                    break;
                }
                else if (roles[i].toUpperCase() === "KET TME USER" || roles[i].toUpperCase() === "TSM KET USER" || roles[i].toUpperCase() === "KET-BRIT TME ADMIN" || roles[i].toUpperCase() === "KET-BRIT TME USER" || roles[i].toUpperCase() === "KET TME ADMIN") {
                    isTMEAdminUser = true;
                    break;
                }
                isNotAdmin = true;

            }
        }
        // if(this.props.SelectedCertiFilters !==undefined){
        //     this.setState({  selectedProgram: this.props.SelectedCertiFilters.selectedProgram,
        //         selectedStatus: this.props.SelectedCertiFilters.selectedStatus,
        //         selectedToolboxNo: this.props.SelectedCertiFilters.selectedToolboxNo,
        //         valueDealer:this.props.SelectedCertiFilters.selectedDealer})
        // }
    
        if (this.props.searchDto !== undefined) {            
            // let selectedNmscList = this.getSelectedList(this.props.searchDto.nmscCodeList);
            // let selectedBrandList = this.getSelectedList(this.props.searchDto.brandList);
            // let selectedCountryList = this.getSelectedList(this.props.searchDto.countryList);
            // this.setState({
            //     dealerDto: this.props.searchDto,
            //     selectedNmsc: selectedNmscList,
            //     selectedBrand: selectedBrandList,
            //     selectedCountry: selectedCountryList,
            // });
            this.props.dispatch(fetchActivityFilters(this.props.searchDto, "nmscCode"));
            this.props.dispatch(fetchActivityFilters(this.props.searchDto, "l1"));
            this.props.dispatch(fetchActivityFilters(this.props.searchDto, "l2"));
            this.props.dispatch(fetchDealer(this.props.searchDto));
            this.props.dispatch(fetchDealerCode(this.props.searchDto));
            this.props.dispatch(fetchActivityFilters(this.props.searchDto, "userId"));
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevState.nmscList !== this.state.nmscList) {
            if (this.state.nmscFlag && this.state.nmscList[0]) {
                const dealerDto = { ...this.state.dealerDto };
                dealerDto["nmscCodeList"] = [this.state.nmscList[0].value];
               // this.props.dispatch(fetchActivityFilters(dealerDto, "userId"));
            }
        }
    }

    getSelectedList(selectedList) {
        let filteredList = [];
        let obj;
        var Number = /^[\d\/\(\)\- \.]+$/;
        if (selectedList !== undefined) {
            selectedList.map(value => {
                if (Number.test(value)) {
                    obj = {
                        label: value,
                        value: value,
                        selected: 'selected'
                    };
                }
                else {
                    obj = {
                        // label: I18n.t("status." + value.toUpperCase()),
                        label: I18n.t("status." + value),

                        value: value,
                        selected: 'selected'
                    };
                }
                filteredList.push(obj);
            });
        }
        return filteredList;
    }

    getSuggestions = value => {   
        const inputValue = value.trim().toLowerCase();
        const inputLength = inputValue.length;   
        return inputLength > 4 && this.state.dealerList !== undefined ? (this.state.dealerList.filter(lang =>
            lang.label.toLowerCase().slice(0, inputLength) === inputValue)
        ) : [];
    };
    getCodeSuggestions = valueDealerCode => {   
        const inputValue = valueDealerCode.trim().toLowerCase();
        const inputLength = inputValue.length;
        let suggestions = [];
        if(this.state.dealerCodeList !== undefined){
            if(inputLength > 0 && inputLength < 3){
                suggestions = this.state.dealerCodeList.filter(code => code.label.toLowerCase() === inputValue);
            } else if (inputLength > 2) {
                suggestions = this.state.dealerCodeList.filter(code => code.label.toLowerCase().indexOf(inputValue) !== -1);
            }
        }   
        return suggestions;
    };
    onDealerChange = (event, { newValue }) => {
        var modifiedValue, modifiedId;
        if (newValue.indexOf("_") > -1) {
            modifiedValue = newValue.substr(0, newValue.indexOf("_"));
            modifiedId = newValue.substr(newValue.indexOf("_") + 1);
        } else {
            modifiedValue = newValue;
            modifiedId = 0;
        }
        const dealerDto = { ...this.state.dealerDto };
        dealerDto["dealer"] = modifiedValue;
        dealerDto["dealerId"] = modifiedId;
        dealerDto["dealerList"] = [modifiedId];
        this.setState({ dealerDto });
        this.setState({
            valueDealer: modifiedValue,
            id: modifiedId
        });
    };
    onDealerCodeChange = e => {
        this.setState({ valueDealerCode: e.target.value });
        const dealerDto = { ...this.state.dealerDto };
        dealerDto["dealerCode"] = e.target.value;
        this.setState({ dealerDto });
    }
    // onDealerCodeChange = (event, { newValue }) => {
    //     var modifiedValue, modifiedId;
    //     if (newValue.indexOf("_") > -1) {
    //         modifiedValue = newValue.substr(0, newValue.indexOf("_"));
    //         modifiedId = newValue.substr(newValue.indexOf("_") + 1);
    //     } else {
    //         modifiedValue = newValue;
    //         modifiedId = 0;
    //     }
    //     const dealerDto = { ...this.state.dealerDto };        
    //     dealerDto["dealerCode"] = modifiedValue;
    //     dealerDto["dealerCodeId"] = modifiedId;
    //     this.setState({ dealerDto });
    //     this.setState({
    //         valueDealerCode: modifiedValue,
    //         id: modifiedId
    //     });
    // };  
    onNmscChange = (selectedOption,event) => {
        
        var valueOptions = [];
        if(event.action === "select-option" && event.option.value === "All"){  
            this.setState({ selectedNmsc: this.state.nmscList})  
        }else if(event.action === "select-option" && event.option.value === "None"){
            this.setState({ selectedNmsc: [] })
        }else if(event.action === "deselect-option" && (event.option.value === "None" || event.option.value === "All")){
            this.setState({ selectedNmsc: [] })
        }else if(event.action === "deselect-option") {            
            selectedOption.map(function (item) {
                if(item.value !== "All" && item.value !== "None"){
                    valueOptions.push(item.value);
                }
            })            
            if (selectedOption.length === 1 && selectedOption[0].value === "None") {
                this.setState({ selectedNmsc: [] })
            }else{
                this.setState({  selectedNmsc: selectedOption.filter(o => o.value !== "All") })
            } 
        }
        else if (selectedOption.length === this.state.nmscList.length - 1) {
            this.setState({ selectedNmsc : this.state.nmscList}) 
        }
        else{
            var selectedOpt = [];
            selectedOption.map(function (item) {
                if(item.value !== "All" && item.value !== "None"){
                    valueOptions.push(item.value);
                    selectedOpt.push(item);
                }
            })     
            selectedOpt.push({ "value": "None", "label": I18n.t('standardMessages.unSelectAll') });    
            this.setState({ selectedNmsc: selectedOpt})          
        } 
        const dealerDto = { ...this.state.dealerDto };
        dealerDto["nmscCodeList"] = valueOptions;
        this.setState({ dealerDto });
        // this.setState({ selectedNmsc: selectedOption })
        if(event.action === "select-option" && event.option.value === "None"){}else{
            this.reloadDropDowns(dealerDto);
        }
    }

    reloadDropDowns = (dealerDto) => {
        dealerDto["userId"] = this.props.user.userMdl.username;
        dealerDto["type"] = this.props.user.userMdl.nmsc;
        dealerDto["loggedInUser"] = this.props.user.userMdl.username;        
        this.props.dispatch(fetchActivityFilters(dealerDto, "nmscCode"));
        this.props.dispatch(fetchActivityFilters(dealerDto, "l1"));
        this.props.dispatch(fetchActivityFilters(dealerDto, "l2"));
        this.props.dispatch(fetchDealer(dealerDto));
        this.props.dispatch(fetchDealerCode(dealerDto));
        this.props.dispatch(fetchActivityFilters(dealerDto, "userId"));
    }
    onBrandChange = (selectedOption) => {
        var valueOptions = [];
        selectedOption.map(function (item) {
            valueOptions.push(item.value);
        })
        const dealerDto = { ...this.state.dealerDto };
        dealerDto["brandList"] = valueOptions;
        this.setState({ dealerDto });
        this.setState({ selectedBrand: selectedOption })
        this.reloadDropDowns(dealerDto);
    }
    onCountryChange = (selectedOption,event) => {
        var valueOptions = [];
        if(event.action === "select-option" && event.option.value === "All"){  
            this.setState({ selectedCountry: this.state.countryList})  
        }else if(event.action === "select-option" && event.option.value === "None"){
            this.setState({ selectedCountry: [] })
        }else if(event.action === "deselect-option" && (event.option.value === "None" || event.option.value === "All")){
            this.setState({ selectedCountry: [] })
        }else if(event.action === "deselect-option") {            
            selectedOption.map(function (item) {
                if(item.value !== "All" && item.value !== "None"){
                    valueOptions.push(item.value);
                }
            })
            if (selectedOption.length === 1 && selectedOption[0].value === "None") {
                this.setState({ selectedCountry: [] })
            }else{
                this.setState({  selectedCountry: selectedOption.filter(o => o.value !== "All") })
            }            
        }
        else if (selectedOption.length === this.state.countryList.length - 1) {
            this.setState({ selectedCountry : this.state.countryList}) 
        }
        else{
            var selectedOpt = [];
            selectedOption.map(function (item) {
                if(item.value !== "All" && item.value !== "None"){
                    valueOptions.push(item.value);
                    selectedOpt.push(item);
                }
            }) 
            selectedOpt.push({ "value": "None", "label": I18n.t('standardMessages.unSelectAll') });    
            this.setState({ selectedCountry: selectedOpt})          
        }
        const dealerDto = { ...this.state.dealerDto };
        dealerDto["countryList"] = valueOptions;
        this.setState({ dealerDto });
        // this.setState({ selectedCountry: selectedOption })
        if(event.action === "select-option" && event.option.value === "None"){}else{
            this.reloadDropDowns(dealerDto);
        }
    }

    onStatusChange = (selectedOption) => {
        var valueOptions = [];
        selectedOption.map(function (item) {
            valueOptions.push(item.value);
        })
        const dealerDto = { ...this.state.dealerDto };
        dealerDto["statusList"] = valueOptions;
        this.setState({ dealerDto });
        this.setState({ selectedStatus: selectedOption })
    }

    onProgramChange = (selectedOption,event) => {
        var valueOptions = [];
        if(event.action === "select-option" && event.option.value === "All"){  
            this.setState({ selectedProgram: this.state.programList})  
        }else if(event.action === "select-option" && event.option.value === "None"){
            this.setState({ selectedProgram: [] })
        }else if(event.action === "deselect-option" && (event.option.value === "None" || event.option.value === "All")){
            this.setState({ selectedProgram: [] })
        }else if(event.action === "deselect-option") {            
            selectedOption.map(function (item) {
                if(item.value !== "All" && item.value !== "None"){
                    valueOptions.push(item.value);
                }
            })
            if (selectedOption.length === 1 && selectedOption[0].value === "None") {
                this.setState({ selectedProgram: [] })
            }else{
                this.setState({  selectedProgram: selectedOption.filter(o => o.value !== "All") })
            }            
        }
        else if (selectedOption.length === this.state.programList.length - 1) {
            this.setState({ selectedProgram : this.state.programList}) 
        }
        else{
            var selectedOpt = [];
            selectedOption.map(function (item) {
                if(item.value !== "All" && item.value !== "None"){
                    valueOptions.push(item.value);
                    selectedOpt.push(item);
                }
            })    
            selectedOpt.push({ "value": "None", "label": I18n.t('standardMessages.unSelectAll') });    
            this.setState({ selectedProgram: selectedOpt})           
        } 
        const dealerDto = { ...this.state.dealerDto };
        dealerDto["programList"] = valueOptions;
        this.setState({ dealerDto });
        if(event.action === "select-option" && event.option.value === "None"){}else{
            this.reloadDropDowns(dealerDto);
        }
        // this.setState({ selectedProgram: selectedOption })
    }

    onToolboxNoChange = (selectedOption,event) => {
        var valueOptions = [];
        if(event.action === "select-option" && event.option.value === "All"){  
            this.setState({ selectedToolboxNo: this.state.noOfCompletedToolBox})  
        }else if(event.action === "select-option" && event.option.value === "None"){
            this.setState({ selectedToolboxNo: [] })
        }else if(event.action === "deselect-option" && (event.option.value === "None" || event.option.value === "All")){
            this.setState({ selectedToolboxNo: [] })
        }else if(event.action === "deselect-option") {            
            selectedOption.map(function (item) {
                if(item.value !== "All" && item.value !== "None"){
                    valueOptions.push(item.value);
                }
            })
            if (selectedOption.length === 1 && selectedOption[0].value === "None") {
                this.setState({ selectedToolboxNo: [] })
            }else{
                this.setState({  selectedToolboxNo: selectedOption.filter(o => o.value !== "All") })
            }
        }
        else if (selectedOption.length === this.state.noOfCompletedToolBox.length - 1) {
            this.setState({ selectedToolboxNo : this.state.noOfCompletedToolBox}) 
        }
        else{
            var selectedOpt = [];
            selectedOption.map(function (item) {
                if(item.value !== "All" && item.value !== "None"){
                    valueOptions.push(item.value);
                    selectedOpt.push(item);
                }
            }) 
            selectedOpt.push({ "value": "None", "label": I18n.t('standardMessages.unSelectAll') });    
            this.setState({ selectedToolboxNo: selectedOpt})            
        } 
        const dealerDto = { ...this.state.dealerDto };
        dealerDto["noOfCompletedToolBox"] = valueOptions;
        this.setState({ dealerDto });
        if(event.action === "select-option" && event.option.value === "None"){}else{
            this.reloadDropDowns(dealerDto);
        }
        // this.setState({ selectedToolboxNo: selectedOption })
    }
    onSuggestionsFetchRequested = ({ value }) => {
        this.setState({
            suggestions: this.getSuggestions(value)
        });
    };
    // onSuggestionsFetchRequestedCode = ({ value }) => {
    //     this.setState({
    //         suggestionsCode: this.getCodeSuggestions(value)
    //     });
    // };
    onSuggestionsClearRequested = () => {
        this.setState({
            suggestions: []
        });
    };
    // onSuggestionsClearRequestedCode = () => {
    //     this.setState({
    //         suggestionsCode: []
    //     });
    // };
    getSuggestionValue = suggestion => suggestion.label + "_" + suggestion.value;
    // getSuggestionValueCode = suggestionsCode => suggestionsCode.label + "_" + suggestionsCode.value;
    renderSuggestion = suggestion => (
        <div>
            {suggestion.label}
        </div>
    );
    // renderSuggestionCode = suggestionsCode => (
    //     <div>
    //         {suggestionsCode.label}
    //     </div>
    // );
    search = (e) => {
        e.preventDefault();
        this.props.selectedProgram(this.state.selectedProgram)

        const dealerDto = { ...this.state.dealerDto };
        if(dealerDto.nmscCodeList && dealerDto.nmscCode && (dealerDto.nmscCodeList !==null || dealerDto.nmscCode !==null)){
            dealerDto["nmscCodeList"]= [dealerDto.nmscCode];
            dealerDto["nmscCode"] = [dealerDto.nmscCode];
        }
        else{
            dealerDto["nmscCodeList"]=dealerDto.nmscCodeList;
            dealerDto["nmscCode"] = dealerDto.nmscCode;
        }
        dealerDto["userId"] = this.props.user.userMdl.username;
        dealerDto["type"] = this.props.user.userMdl.nmsc;

      

        // Dealer code change start
        // if(dealerDto.dealerId === undefined || (dealerDto.dealerId !== undefined && dealerDto.dealerId === "")){
        //     if(dealerDto.dealerCodeId !== undefined && dealerDto.dealerCodeId === 0){
        //         dealerDto["dealerId"] = 0;
        //         dealerDto["dealerList"] = [0];
        //     }            
        // }
        // if(dealerDto.dealerCodeId !== undefined && dealerDto.dealerCodeId !== "" && dealerDto.dealerCodeId !== 0){
        //     if(dealerDto.dealerId !== undefined && dealerDto.dealerId !== "" && dealerDto.dealerId !== 0 && dealerDto.dealerId !== dealerDto.dealerCodeId){
        //         this.props.dispatch(searchEmptyNmscCertification());
        //         return false;          
        //     }else{
        //         dealerDto["dealerId"] = dealerDto.dealerCodeId;
        //         dealerDto["dealerList"] = [dealerDto.dealerCodeId];
        //     }
        // }
        if((dealerDto.dealer !== undefined && dealerDto.dealer!== "" && dealerDto.dealer!== null && dealerDto.dealerId === 0)){
            this.props.dispatch(searchEmptyNmscCertification());
            return false;   
        }  
        //Dealer code change end
    
        this.props.dispatch(fetchNmscCertification(dealerDto));
        this.props.dispatch(filtersCertification({selectedProgram: this.state.selectedProgram,
            selectedStatus:this.state.selectedStatus,
            selectedToolboxNo: this.state.selectedToolboxNo,selectedDealer:this.state.valueDealer},this.state.dealerDto,true))
    }

    render() {
        const { suggestions, valueDealer } = this.state;
        const { suggestionsCode, valueDealerCode } = this.state;
        // Autosuggest will pass through all these props to the input.
        const inputProps = {
            placeholder: I18n.t('toastr.multiSelectPlaceHolder'),
            value: valueDealer,
            onChange: this.onDealerChange
        };
        // const inputPropsCode = {
        //     placeholder: I18n.t('toastr.multiSelectPlaceHolderDealerCode'),
        //     value: valueDealerCode,
        //     onChange: this.onDealerCodeChange
        // };
        return (
            <div className="col-md-12" style={{ padding: "0px" }}>
                <div id="searchId" style={{ display: "none", borderColor: this.props.theme.primaryColor }} className="col-md-12 searchActivity">
                    <div className="col-md-12 row control-label">
                        <label className="col-md-1 control-label" style={{ paddingLeft: '0rem', width:'9rem', margin : '4px' }}>{I18n.t('Table.nmsc')}</label>
                        <label className="col-md-1.5 control-label" style={{ paddingLeft: '0rem', width:'9rem', margin : '4px' }}>{I18n.t('Table.franchise')}</label>
                        <label className="col-md-1 control-label" style={{ paddingLeft: '0rem', width:'9rem', margin : '4px' }}>{I18n.t('Table.country')}</label>
                        <label className="col-md-2 control-label" style={{ padding: '5px 0px 0px 5px', width:'9rem' }}>{I18n.t('Table.dealer')}</label>
                        <label className="col-md-1 control-label" style={{ padding: '5px 0px 5px 0px', width:'9rem' }}>{I18n.t('UserManagement.dealerCode')}</label>
                        <label className="col-md-2.5 control-label" style={{ paddingLeft: '0rem', width:'13rem', margin : '4px -60px 4px 65px' }}>{I18n.t('Table.certification')}</label>
                        <label className="col-md-1.5 control-label" style={{ paddingLeft: '0rem', width:'9rem', margin : '4px' }}>{I18n.t('Table.program')}</label>
                        <label className="col-md-1.5 control-label" style={{ paddingLeft: '0rem', width:'9rem', margin : '4px' }}>{I18n.t('Table.noOfToolbox')}</label>
                    </div>
                    <div className="col-md-12 input-group control-label">
                        <div className={"col-md-1 noPadding smallDD-" + this.props.theme.name}>
                            {this.state.nmscFlag ? <Select
                                value={this.state.nmscList[0]}
                                onChange={this.onNmscChange}
                                options={this.state.nmscList}
                                isDisabled={true}
                            /> : <ReactMultiSelectCheckboxes
                                    value={this.state.selectedNmsc}
                                    options={this.state.nmscList}
                                    onChange={this.onNmscChange}
                                    placeholderButtonLabel={I18n.t('standardMessages.all')}
                                    isDisabled={this.state.nmscFlag ? false : false}
                                />}

                        </div>
                        <div style = {{margin : '4px'}}/>
                        {/* &nbsp;&nbsp;&nbsp; */}
                        <div className={"col-md-1.5 noPadding smallDD-" + this.props.theme.name}>
                            <ReactMultiSelectCheckboxes
                                value={this.state.selectedBrand}
                                options={this.state.brandList}
                                onChange={this.onBrandChange}
                                placeholderButtonLabel={I18n.t('standardMessages.all')}
                            />
                        </div>
                        {/* &nbsp;&nbsp;&nbsp; */}
                        <div style = {{margin : '4px'}}/>
                        <div className={"col-md-1 noPadding smallDD-" + this.props.theme.name}>
                            <ReactMultiSelectCheckboxes
                                value={this.state.selectedCountry}
                                options={this.state.countryList}
                                onChange={this.onCountryChange}
                                placeholderButtonLabel={I18n.t('standardMessages.all')}
                            />
                        </div>
                        {/* &nbsp;&nbsp;&nbsp; */}
                        <div style = {{margin : '4px'}}/>
                        <div className={"col-md-2 noPadding bigDD-" + this.props.theme.name}>
                                <Autosuggest class="form-control"
                                    suggestions={suggestions}
                                    onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
                                    onSuggestionsClearRequested={this.onSuggestionsClearRequested}
                                    getSuggestionValue={this.getSuggestionValue}
                                    renderSuggestion={this.renderSuggestion}
                                    inputProps={inputProps}
                                />
                            
                        </div>
                        <div style = {{margin : '-4px'}}/>
                        <div className={"col-md-1 noPadding bigDD-" + this.props.theme.name}>
                                {/* <Autosuggest class="form-control"
                                    suggestions={suggestionsCode}
                                    onSuggestionsFetchRequested={this.onSuggestionsFetchRequestedCode}
                                    onSuggestionsClearRequested={this.onSuggestionsClearRequestedCode}
                                    getSuggestionValue={this.getSuggestionValueCode}
                                    renderSuggestion={this.renderSuggestionCode}
                                    inputProps={inputPropsCode}
                                /> */}
                                <input type="text"
                                    className="form-control"
                                    maxLength="50"
                                    style={{width:'10.2rem',height:'calc(1.5em + .75rem + 5px)'}}
                                    value={this.state.valueDealerCode} 
                                    onChange={this.onDealerCodeChange} />
                            </div>
                        <div style = {{margin : '4px 35px'}}/>
                        <div className={"col-md-2.5 noPadding smallDD-" + this.props.theme.name}>
                            <ReactMultiSelectCheckboxes
                                value={this.state.selectedStatus}
                                options={this.state.statusList}
                                onChange={this.onStatusChange}
                                placeholderButtonLabel={I18n.t('standardMessages.all')}
                            />
                        </div>
                        {/* &nbsp;&nbsp;&nbsp; */}
                        <div style = {{margin : '4px'}}/>
                        <div className={"col-md-1.5 noPadding smallDD-" + this.props.theme.name}>
                            <ReactMultiSelectCheckboxes
                                value={this.state.selectedProgram}
                                options={this.state.programList}
                                onChange={this.onProgramChange}
                                placeholderButtonLabel={I18n.t('standardMessages.all')}
                            />
                        </div>
                        {/* &nbsp;&nbsp;&nbsp; */}
                        <div style = {{margin : '4px'}}/>
                        <div className={"col-md-1.5 noPadding smallDD-" + this.props.theme.name}>
                            <ReactMultiSelectCheckboxes
                                value={this.state.selectedToolboxNo}
                                options={this.state.noOfCompletedToolBox}
                                onChange={this.onToolboxNoChange}
                                placeholderButtonLabel={I18n.t('standardMessages.all')}
                            />
                        </div>
                    </div>
                    <div className="col-md-12 row control-label">
                        <div className={"col-md searchIcon-"+ this.props.theme.name} style={{ borderTop: '0px', padding: '10px 0px 0px 0px', margin:'0px -20px 0px 20px'}}>
                            <a 
                                onClick={(e) =>this.search(e)}>
                                <MDBIcon icon={"search"} fixed size="3x" title={I18n.t("Search")} 
                                style = {{float : 'right' }}
                                /> 
                            </a>
                        </div>
                    </div>
                    <div id="hrId" className="col-md-12 hrClass">
                        <hr style={{ margin: '0px', borderTop: "1px solid" }} />
                    </div>
                </div>
            </div>
        );
    }
}
const mapStateToProps = (state) => {
    return {
        user: state.loginReducer,
        blocking: state.loginReducer,
        router: state.routing,
        nmscList: state.activityReducer,
        brandList: state.activityReducer,
        countryList: state.activityReducer,
        dealerList: state.activityReducer,
        dealerCodeData: state.activityReducer,
        certiFilters: state.certificationReducer.certiFilters,
        SelectedCertiFilters: state.certificationReducer.SelectedCertiFilters
    }
}
export default withRouter(connect(mapStateToProps)(withTheme(SearchFilter)));