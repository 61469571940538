import React from "react";
import {
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
  Row,
  Col
} from "reactstrap";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { withTheme } from "../../../themes/theming";
import { Container } from "react-bootstrap";
import { toastr } from "react-redux-toastr";
import { confirmAlert } from "react-confirm-alert";
import BootstrapTable2 from "react-bootstrap-table-next";
import cellEditFactory from "react-bootstrap-table2-editor";
import "../index.css";
import {
  saveEvidences,
  swapEvidences,
  swapAttachments,
  fetchEvidenceFiles,
  getDownloadEvidencePreSignedUrl,
  fetchAttachFilesActionPlan,
  deleteEvidence,
  renameEvidence,
  emptyEvidanceData,
  renameAttachment,
} from "../../../actions/libraryAction";
var I18n = require("react-redux-i18n").I18n;
const $ = require("jquery");

class uploadEvidence extends React.Component {
  /* eslint-disable */
  constructor(props) {
    super(props);
    this.state = {
      fileName: "",
      fileDesc: "",
      selectedFile: "",
      fileId: "",
      description: "",
      nmscFileId: "",
      evidenceFiles: [],
      referEvidenceFiles: [],
      totalEvidenceFiles: [],
      successUploadedFileCount: 0,
      totalUploadedFileCount: 0,
      failureUploadedFileCount: 0,
      toBeRenamed: null,
      questionReference: "0.0.0",
      beforeOrAfter: "",
      afterChecked: false,
      beforeChecked: false,
      moveBefore: false,
      moveAfter: false,
      beforeArr: [],
      afterArr: [],
      selectedBeforeMove:[],
      selectedAfterMove:[],
      // beforeArr: [{fileId:1,fileName:'KET_TARS Application Integration Specification V9 (2).docx',dataEntryKet: false},{fileId:2,fileName:'KET_TARS-Application Integration Specification_V9 (2).docx',dataEntryKet: false}],
      // afterArr: [{fileId:1,fileName:'KET_TARS Application Integration Specification V9 (2).docx',dataEntryKet: false},{fileId:2,fileName:'KET_TARS-Application Integration Specification_V9 (2).docx',dataEntryKet: false},{fileId:3,fileName:'KET_TARS Application Integration Specification V9 (2).docx',dataEntryKet: false}]
    };
  }
  componentDidMount() {
    $("#libFileUploadTrigger").click(function () {
      $("#libFileUpload").trigger("click");
    });
    let questionReference =
      (this.props.chapterSequence.type === "LOCAL"
        ? this.props.chapterSequence.sequence + "#"
        : this.props.chapterSequence.sequence) +
      "." +
      (this.props.mainQuestionDetails.type === "LOCAL"
        ? this.props.mainQuestionDetails.seqNum + "#"
        : this.props.mainQuestionDetails.seqNum) +
      "." +
      this.props.subQuestion.seqNum;
    this.setState({
      questionReference: questionReference,
    });
    this.props.dispatch(
      fetchAttachFilesActionPlan(this.props.activityId, questionReference)
    );
    this.props.dispatch(
      fetchEvidenceFiles(this.props.subQuestion.evaluationId)
    );
    //before after code change added
    let selectedBefore = [];
    let selectedAfter = [];
    let selectedBeforeMove = [];
    let selectedAfterMove = [];
    if (this.state.beforeArr && this.state.beforeArr.length > 0) {
      this.state.beforeArr.map((obj) => {
        if (obj.dataEntryKet) {
          selectedBefore.push(obj.fileId);
          selectedBeforeMove.push(obj);
        }
      });
    }
    if (this.state.afterArr && this.state.afterArr.length > 0) {
      this.state.afterArr.map((obj) => {
        if (obj.dataEntryKet) {
          selectedAfter.push(obj.fileId);
          selectedAfterMove.push(obj);
        }
      });
    }
    this.setState({ selectedBefore, selectedAfter });
  }

  componentWillUnmount() {
    this.props.dispatch(emptyEvidanceData());
  }

  componentWillReceiveProps(nextProps) {
    console.log("componentWillReceiveProps");
    let totalEvidenceFiles = [];
    let evidenceFilesArr = [];
    let referEvidenceFilesArr = [];
    if (nextProps.evidence.evidenceFiles) {
      evidenceFilesArr = nextProps.evidence.evidenceFiles;
      this.setState({
        evidenceFiles: nextProps.evidence.evidenceFiles,
        toBeRenamed: null,
      });
    }

    if (nextProps.evidence.referEvidenceFiles) {
      referEvidenceFilesArr = nextProps.evidence.referEvidenceFiles;
      this.setState({
        referEvidenceFiles: nextProps.evidence.referEvidenceFiles,
      });
    }

    // Before and after code change
    let beforeArr = [];
    let afterArr = [];
    totalEvidenceFiles = evidenceFilesArr.concat(referEvidenceFilesArr);
    if (totalEvidenceFiles && totalEvidenceFiles.length) {
      totalEvidenceFiles.map((obj) => {
        if (obj.beforeOrAfter === "before") {
          beforeArr.push(obj);
        }
        if (obj.beforeOrAfter === "after") {
          afterArr.push(obj);
        }
      });      
    }
    this.setState({ beforeArr, afterArr });
    // To display success and Error message while uploading files
    if (nextProps.evidence.totalFileCount > 0) {
      if (
        nextProps.evidence.totalFileCount ===
        nextProps.evidence.failureFileCount +
          nextProps.evidence.successFileCount
      ) {
        if (
          nextProps.evidence.totalFileCount ===
          nextProps.evidence.successFileCount
        ) {
          toastr.success(I18n.t("evaluation.uploadSuccessMsg"));
        } else {
          toastr.error(
            I18n.t("evaluation.uploadErrorMsg") +
              nextProps.evidence.failureFileCount
          );
        }
        this.props.dispatch({
          type: "RESET_FILE_COUNT",
        });
      }
    }
  }

  thumbnailFormatter = (cell, row) => {
    let icon = "images/folder.png";
    if (row.fileType === "doc" || row.fileType === "docx") {
      icon = "images/word.png";
    }
    if (row.fileType === "xls" || row.fileType === "xlsx") {
      icon = "images/excel.png";
    }
    if (row.fileType === "pdf") {
      icon = "images/pdf.png";
    }
    if (
      row.fileType === "jpg" ||
      row.fileType === "jpeg" ||
      row.fileType === "gif" ||
      row.fileType === "bmp" ||
      row.fileType === "png" ||
      row.fileType === "tif"
    ) {
      icon = "images/image-icon.png";
    }

    if (row.fileType === "ppt" || row.fileType === "pptx") {
      icon = "images/ppt-icon.png";
    }

    if (
      row.fileType === "avi" ||
      row.fileType === "flv" ||
      row.fileType === "wmv" ||
      row.fileType === "mov" ||
      row.fileType === "mp4"
    ) {
      icon = "images/video-icon.png";
    }
    if (row.fileType === "txt") {
      icon = "images/text.png";
    }
    console.log("this.state", this.state);
    let toBeRenamed =
      this.state.toBeRenamed !== undefined ? this.state.toBeRenamed : null;
    if (!this.state.toBeRenamed) {
      toBeRenamed = row;
      toBeRenamed.newFileName = row.fileName.substr(
        0,
        row.fileName.lastIndexOf(".")
      );
    }
    console.log("row.thumbnail", row);
    let thumb = this.getFileImage(row);
    return (  
    <div className="iframe">
    <div className="wrapper">
        {(row.fileType === "jpg" ||
          row.fileType === "jpeg" ||
          row.fileType === "gif" ||
          row.fileType === "bmp" ||
          row.fileType === "png" ||
          row.fileType === "tif") &&
        JSON.stringify(row.statusDTO) !== "{}" ? (
          <img
            className="image" 
            src={row.statusDTO.paraentUrl}
            onClick={(e) => this.onImageClick(row)}
            alt=""
          />
        ) : (
          <img
          style={{ width: "91.19pt", height: "74.99pt" }}
          src={icon}
          alt=""
          />
        )}
    </div>
  </div>

  
    );
  };

  fileNameFormatter = (cell, row) => {
    let toBeRenamed =
      this.state.toBeRenamed !== undefined ? this.state.toBeRenamed : null;
    if (!this.state.toBeRenamed) {
      toBeRenamed = row;
      toBeRenamed.newFileName = row.fileName.substr(
        0,
        row.fileName.lastIndexOf(".")
      );
    }
    return (
      <div
        style={{ cursor: "pointer", paddingRight: "3px", marginRight: "5px" }}
      >
        {this.state.toBeRenamed &&
        this.state.toBeRenamed.fileId === row.fileId ? (
          <input
            type="text"
            className="form-control editor edit-text"
            style={{ display: "inline" }}
            defaultValue={toBeRenamed.newFileName}
            onChange={(e) => this.onChangeTextBox(e, row, cell)}
            onKeyDown={(e) => this.autoTab(e, row, cell)}
            onBlur={(e) => this.onTabChange(e, row, cell)}
          />
        ) : (
          <a
            style={{ textDecoration: "underline", color: "blue" }}
            onClick={(e) => this.downloadFile(row.fileId, row.tableRef)}
          >
            {row.fileName}
          </a>
        )}
      </div>
    );
  };

  onImageClick = (row) => {
    confirmAlert({
      customUI: ({ title, message, onClose }) => {
        return (
          <div className="deleteConfirmBox imageModalHt" style={{ width: "50rem" }}>
            
            <div className="modal-footer" style={{ borderTop: "0px" }}>
              <div className="iframeBg">
              {row.fileName}
              <div style={{float: "right"}}>
            <button
              className={"button-" + this.props.theme.name}
              style={{
                height: "20px",
                padding: "0rem 20px",
              }}
              onClick={() => {
                this.downloadFile(row.fileId, row.tableRef);
                onClose();
              }}
            >
              {I18n.t("tmeAdminMaster.download")}
            </button>
            <a
              onClick={() => {
                onClose();
              }}
              style={{ color: "red" }}
            >
              <div className="fa fa-close" />
            </a></div>
                <div className="wrapper">
                  <img
                    className="image" 
                    src={row.statusDTO.paraentUrl}
                    alt=""
                  />
                </div>
              </div>
            </div>
          </div>
        );
      },
    });
  };
  downloadFile = (fileId, tableRef) => {
    this.props.dispatch(getDownloadEvidencePreSignedUrl(fileId, tableRef));
  };
  handleFileNameChange(event) {
    let newFileName = event.target.value;
    let toBeRenamed = { ...this.state.toBeRenamed };
    toBeRenamed.newFileName = newFileName;
    this.setState({ toBeRenamed });
    console.log("filename change triggered...");
  }
  onChangeTextBox = (e, row, cell) => {};
  autoTab = (e, row, cell) => {
    if (e.keyCode === 9) {
      this.onTabChange(e, row, cell);
    }
  };
  onTabChange = (e, row, cell) => {
    this.state.toBeRenamed.newFileName = e.target.value;
  };

  onChangeRadio(event) {
    let after = true;
    let before = false;
    if (event.target.value === "before") {
      after = false;
      before = true;
    }
    this.setState({
      afterChecked: after,
      beforeChecked: before,
      beforeOrAfter: event.target.value,
    });
  }

  showFileNameEditor = (row) => {
    let toBeRenamed = { ...row };
    toBeRenamed.newFileName = row.fileName.substr(
      0,
      row.fileName.lastIndexOf(".")
    );
    this.setState({ toBeRenamed });
    console.log("Rename triggered..");
  };

  getFileImage = (row) => {
    let toBeRenamed = { ...row };
    // toBeRenamed.newFileName = row.fileName.substr(0, row.fileName.lastIndexOf("."));
    // this.setState({ toBeRenamed });
    console.log("thumbnail triggered..");
  };

  hideFileNameEditor = () => {
    this.setState({ toBeRenamed: null });
    console.log("hideFileNameEditor triggered..");
  };

  deleteEvidence = (row) => {
    row.activityId = this.props.activityId;
    row.questionReference = this.state.questionReference;
    this.props.dispatch(deleteEvidence(row,this.props.questionId));
    console.log("Delete triggered..");
  };

  renameEvidence = (row) => {
    let tobeSaved = { ...this.state.toBeRenamed };
    // let toBeRenamed = {...this.state.toBeRenamed};
    tobeSaved.fileName =
      tobeSaved.newFileName +
      row.fileName.substr(row.fileName.lastIndexOf("."));
    if (
      tobeSaved.fileName === row.fileName.substr(row.fileName.lastIndexOf("."))
    ) {
      this.setState({ toBeRenamed: null });
      toastr.error(I18n.t("library.file_name_error"));
      return;
    }
    if (tobeSaved.fileName === row.fileName) {
      this.setState({ toBeRenamed: null });
      console.log("The new file name is same as old one");
      return;
    }
    tobeSaved.activityId = this.props.activityId;
    // tobeSaved.fileName = this.props.activityId +"-"+ tobeSaved.newFileName + row.fileName.substr(row.fileName.lastIndexOf("."));
    // tobeSaved.newFileName = this.props.activityId +"-"+ tobeSaved.newFileName
    // toBeRenamed.newFileName = tobeSaved.newFileName;
    // this.setState({toBeRenamed});
    if (tobeSaved.tableRef === "SubQuestion") {
      this.props.dispatch(renameEvidence(tobeSaved, []));
    } else {
      tobeSaved.questionReference = this.state.questionReference;
      this.props.dispatch(renameAttachment(tobeSaved, true));
    }
    console.log("rename triggered..");
  };

  handleDeleteIconClick = (row) => {
    console.log("handleDeleteIconClick triggered..");
    confirmAlert({
      customUI: ({ title, message, onClose }) => {
        return (
          <div className="deleteConfirmBox">
            <div className={"titleClass-" + this.props.theme.name}>
              {I18n.t("standardMessages.delete")} {row.name}
            </div>
            <div className="modal-footer" style={{ borderTop: "0px" }}>
              <button
                className={"buttonCancel-" + this.props.theme.name}
                onClick={() => onClose()}
              >
                {I18n.t("standardMessages.no")}
              </button>
              <button
                className={"button-" + this.props.theme.name}
                onClick={() => {
                  this.deleteEvidence(row);
                  onClose();
                }}
              >
                {I18n.t("standardMessages.yes")}
              </button>
            </div>
          </div>
        );
      },
    });
  };

  saveFile = () => {
    if (this.state.selectedFile.length === 0) {
      toastr.error(I18n.t("library.file_not_selected"));
      return;
    }
    if (this.state.beforeOrAfter === "") {
      toastr.error(I18n.t("library.beforeAfterNotSelected"));
      return;
    }

    //To check if file is a script file.
    if (
      disallowedFileList.indexOf(
        this.state.selectedFile.split(".").pop().toUpperCase()
      ) !== -1
    ) {
      toastr.error(I18n.t("library.file_type_error"));
      return;
    }

    if (this.refs.libFileUpload && this.refs.libFileUpload.files[0]) {
      // Actual file with contents
      var fileContentList = this.refs.libFileUpload.files;
      const finalfileContentList = [...this.refs.libFileUpload.files];

      console.log("fileContentList", fileContentList);
      var fileObjList = [];
      var fileSizeInMB = 0;
      var fileSize = 0;
      for (var i = 0; i < fileContentList.length; i++) {
        //convert size to MB
        fileSizeInMB = fileContentList[i].size / 1024 / 1024;
        fileSize = fileSize + fileSizeInMB;

        //Restrict If total size of file upload is greater than 1 GB
        if (fileSize > 1024) {
          toastr.error(I18n.t("library.max_size_error"));
          return;
        }

        //Restrict file name length to 50 chars
        if (fileContentList[i].name.length > 50) {
          toastr.error(I18n.t("library.file_name_lenght_error"));
          return;
        }

        //To check if file is a script file.
        if (
          disallowedFileList.indexOf(
            fileContentList[i].name.split(".").pop().toUpperCase()
          ) !== -1
        ) {
          toastr.error(I18n.t("library.file_type_error"));
          return;
        }

        //Creating object from actual file object, so that can transfer to backend
        var file = {};
        file["name"] = fileContentList[i].name;
        file["size"] = fileContentList[i].size;
        fileObjList.push(file);
      }

      let s3Object = {
        // nmscFileId: this.props.nmscFileId ? this.props.nmscFileId : 0,
        //directory: this.props.parentFolder.name,
        fileName: this.state.selectedFile,
        fileList: fileObjList,
        //bucketName:"ket-library-dev",
        //nmscFolderId: this.props.parentFolder.id,
        //    description: this.state.description,
        uploadFile: true,
        nmscCode: this.props.user.userMdl.nmsc,
        nmscLang: this.props.activeTabNmscLang,
        evaluationId: this.props.subQuestion.evaluationId,
        //   type: this.props.type ? this.props.type : "Local",
        userId: this.props.user.userMdl.username,
        activityId: this.props.activityId,
        beforeOrAfter: this.state.beforeOrAfter,
      };
      this.props.dispatch(
        saveEvidences(
          s3Object,
          fileContentList,
          this.props.activityId,
          this.props.questionId
        )
      );

      this.setState({
        selectedFile: [],
        beforeOrAfter: "",
        afterChecked: false,
        beforeChecked: false,
      });
      $("#fileUploadBx").attr("placeholder", I18n.t("evaluation.fileUpload"));
    }
  };

  handleLibFileChange(file) {
    if (
      this.refs.libFileUpload &&
      this.refs.libFileUpload.files[0] &&
      this.refs.libFileUpload.files[0].name
    ) {
      var fileCount = this.refs.libFileUpload.files.length + " files";
      //var fileName = this.refs.libFileUpload.files[0].name;
      $("#fileUploadBx").attr("placeholder", fileCount);
      this.setState({ selectedFile: fileCount });
    }
  }
  headerMoveBefore = (cell, row) => {
    return (
      <button
        className={"moveBtn button-" + this.props.theme.name}
        onClick={() => this.moveEvidences(this.state.selectedBeforeMove, "after")}
        disabled={
          this.state.selectedBefore !== undefined &&
          this.state.selectedBefore.length
            ? false
            : true
        }
        style={{ display: this.state.beforeArr.length ? "block" : "none" }}
      >
        {I18n.t("evaluation.move")}
      </button>
    );
  };
  headerMoveAfter = (cell, row) => {
    return (
      <button
        className={"moveBtn button-" + this.props.theme.name}
        onClick={() => this.moveEvidences(this.state.selectedAfterMove, "before")}
        disabled={
          this.state.selectedAfter !== undefined &&
          this.state.selectedAfter.length
            ? false
            : true
        }
        style={{ display: this.state.afterArr.length ? "block" : "none" }}
      >
        {I18n.t("evaluation.move")}
      </button>
    );
  };
  moveEvidences = (moveData, beforeOrAfter) => {
    console.log("tableRef moveData",moveData)
    let s3Object = [];
    let evidenceFiles = [];
    let attachmentFiles = [];
    let activityId = this.props.activityId;
    let evaluationId = this.props.subQuestion.evaluationId;
    let userId = this.props.user.userMdl.username;
    let questionReference = this.state.questionReference;
    if(moveData.length){
        moveData.map(function (obj) {
            if(obj.tableRef === "SubQuestion"){
                evidenceFiles.push(obj.fileId);
            }else{
                attachmentFiles.push(obj.fileId);
            }
        });
    }else{
        if(moveData.tableRef === "SubQuestion"){
            s3Object.push({
                activityId: activityId,
                evaluationId: evaluationId,
                beforeOrAfter: beforeOrAfter,
                evaluationFileId: moveData.fileId,
                userId: userId,
              });
              this.props.dispatch(
                swapEvidences(
                  s3Object,
                  this.props.subQuestion.evaluationId, activityId,
                  this.props.questionId, true
                )
              );
        }else{
            s3Object.push({
                activityId: activityId,
                beforeOrAfter: beforeOrAfter,
                attachmentFileId: moveData.fileId,
                userId: userId,              
              });
              this.props.dispatch(swapAttachments(s3Object, 1, activityId, questionReference, false));
        }
    }
    
    if (evidenceFiles.length) {
        s3Object = []
        evidenceFiles.map(function (fileId) {
            s3Object.push({
              activityId: activityId,
              evaluationId: evaluationId,
              beforeOrAfter: beforeOrAfter,
              evaluationFileId: fileId,
              userId: userId,
            });
          });
          this.props.dispatch(
            swapEvidences(
              s3Object,
              this.props.subQuestion.evaluationId, activityId,
              this.props.questionId, true
            )
          );
    } 
    if (attachmentFiles.length) {
        s3Object = []
        attachmentFiles.map(function (fileId) {
            s3Object.push({
              activityId: activityId,
              beforeOrAfter: beforeOrAfter,
              attachmentFileId: fileId,
              userId: userId,              
            });
          });
          this.props.dispatch(swapAttachments(s3Object, 1, activityId, questionReference, false));
    }
    this.setState({ selectedBefore: [],selectedBeforeMove:[], selectedAfter: [], selectedAfterMove:[] });
  };
  buttonFormatter = (cell, row) => {
    return (
      <span>
        {this.state.toBeRenamed &&
        this.state.toBeRenamed.fileId === row.fileId ? (
          <div className="btn-toolbar">
            <button
              title={I18n.t("tmeAdminMaster.close")}
              className={"buttonCancel-" + this.props.theme.name}
              onClick={() => this.hideFileNameEditor()}
              style={{
                width: "18px",
                height: "19px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                fontSize: "18px",
                padding: 0,
                margin: "0 0.3rem",
              }}
            >
              <i className="fa fa-close"></i>
            </button>
            <button
              title={I18n.t("tmeAdminMaster.save")}
              className={"button-" + this.props.theme.name}
              onClick={() => this.renameEvidence(row)}
              style={{
                width: "18px",
                height: "19px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                fontSize: "16px",
                padding: 0,
                margin: "0 0.3rem",
              }}
            >
              <i className="fa fa-save"></i>
            </button>
          </div>
        ) : (
          <div className="btn-toolbar">
            <a onClick={() => this.showFileNameEditor(row)}>
              <div
                title={I18n.t("tmeAdminMaster.edit")}
                className="edit-icon"
              />
            </a>
            <a onClick={() => this.handleDeleteIconClick(row)}>
              <div
                title={I18n.t("tmeAdminMaster.delete")}
                className="delete-icon"
              />
            </a>
          </div>
        )}
      </span>
    );
  };
  preventDefault() {}
  render() {
    var beforeColumns = [
      {
        dataField: "",
        style: {
          width: "30%",
          textAlign: "center",
        },
        formatter: this.thumbnailFormatter,
        editable: false,
        headerStyle: { width: "40%", textAlign: "left" },
      },
      {
        dataField: "fileName",
        text:
          I18n.t("evaluation.before") + " " + I18n.t("evaluation.evidences"),
        style: {
          width: "40%",
          textAlign: "left",
          wordWrap: "break-word",
          overflow: "hidden",
          textOverflow: "ellisis",
        },
        formatter: this.fileNameFormatter,
        editable: false,
        headerStyle: { width: "40%", textAlign: "left" },
      },
      {
        dataField: "",
        text: I18n.t("Table.action"),
        headerFormatter: this.headerMoveBefore,
        formatter: this.buttonFormatter,
        style: { width: "20%" },
        headerStyle: { margin: "-3px 0 0 30px" },
        editable: false,
        hidden: false,
      },
    ];
    var afterColumns = [
      {
        dataField: "",
        style: {
          width: "30%",
          textAlign: "center",
          wordWrap: "break-word",
          overflow: "hidden",
          textOverflow: "ellisis",
        },
        formatter: this.thumbnailFormatter,
        editable: false,
        headerStyle: { width: "40%", textAlign: "left" },
      },
      {
        dataField: "fileName",
        text: I18n.t("evaluation.after") + " " + I18n.t("evaluation.evidences"),
        style: {
          width: "40%",
          textAlign: "left",
          wordWrap: "break-word",
          overflow: "hidden",
          textOverflow: "ellisis",
        },
        formatter: this.fileNameFormatter,
        editable: false,
        headerStyle: { width: "40%", textAlign: "left" },
      },
      {
        dataField: "",
        headerFormatter: this.headerMoveAfter,
        formatter: this.buttonFormatter,
        style: { width: "20%" },
        headerStyle: {  margin: "-3px 0 0 30px" },
        editable: false,
        hidden: false,
      },
    ];
    const selectRowBefore = {
      mode: "checkbox",
      clickToSelect: false,
      clickToEdit: false,
      selected: this.state.selectedBefore,
      nonSelectable: [],
      hideSelectAll: this.state.beforeArr.length ? false : true,
      onSelect: (row, isSelect, rowIndex, e) => {
        console.log("checkbox",row)
        if (isSelect) {
          let beforeMoveArr = this.state.selectedBeforeMove
          this.setState(() => ({
            selectedBefore: [...this.state.selectedBefore, row.fileId],selectedBeforeMove:[...beforeMoveArr,row]
          }));
        } else {
          this.setState(() => ({
            selectedBefore: this.state.selectedBefore.filter(
              (x) => x !== row.fileId
            ),selectedBeforeMove: this.state.selectedBeforeMove.filter(
              (obj) => obj.fileId !== row.fileId
            )
          }));
        }
        let beforeArr = [...this.state.beforeArr];
        beforeArr = beforeArr.map((obj, i) => {
          if (rowIndex === i) {
            obj.dataEntryKet = isSelect;
            return obj;
          } else {
            return obj;
          }
        });
        this.setState({ beforeArr });
      },
      onSelectAll: (isSelect, rows, e) => {
        let selectedRows = [];
        rows.forEach((row) => {
          selectedRows.push(row.fileId);
        });
        if (isSelect && selectedRows.length > 0) {
          this.setState({ selectedBefore: selectedRows,selectedBeforeMove: rows });
          return selectedRows;
        } else {
          this.setState({ selectedBefore: [], selectedBeforeMove:[]});
        }
      },
    };
    const selectRowAfter = {
      mode: "checkbox",
      clickToSelect: false,
      clickToEdit: false,
      selected: this.state.selectedAfter,
      nonSelectable: [],
      hideSelectAll: this.state.afterArr.length ? false : true,
      onSelect: (row, isSelect, rowIndex, e) => {
        console.log("checkbox",row)
        if (isSelect) {
          let afterMoveArr = this.state.selectedAfterMove
          this.setState(() => ({
            selectedAfter: [...this.state.selectedAfter, row.fileId],selectedAfterMove:[...afterMoveArr,row]
          }));
        } else {
          this.setState(() => ({
            selectedAfter: this.state.selectedAfter.filter(
              (x) => x !== row.fileId
            ),selectedAfterMove: this.state.selectedAfterMove.filter(
              (obj) => obj.fileId !== row.fileId
            )
          }));
        }
        let afterArr = [...this.state.afterArr];
        afterArr = afterArr.map((obj, i) => {
          if (rowIndex === i) {
            obj.dataEntryKet = isSelect;
            return obj;
          } else {
            return obj;
          }
        });
        this.setState({ afterArr });
      },
      onSelectAll: (isSelect, rows, e) => {
        let selectedRows = [];
        rows.forEach((row) => {
          selectedRows.push(row.fileId);
        });
        if (isSelect && selectedRows.length > 0) {
          this.setState({ selectedAfter: selectedRows,selectedAfterMove: rows});
          return selectedRows;
        } else {
          this.setState({ selectedAfter: [],selectedAfterMove:[] });
        }
      },
    };
    let totalEvidenceFiles = [];
    totalEvidenceFiles = this.state.evidenceFiles.concat(
      this.state.referEvidenceFiles
    );
    let accessType = "";
    if (this.props.user.userMdl && this.props.user.userMdl.accessControl) {
      accessType = this.props.user.userMdl.accessControl.filter(
        (obj) => obj.function === "ACTIVITY_EVALUATION"
      )[0]
        ? this.props.user.userMdl.accessControl.filter(
            (obj) => obj.function === "ACTIVITY_EVALUATION"
          )[0].accessType
        : "";
    }
    var hasAccess =
      accessType === "R/W" && this.props.activityStatus === "Ongoing"
        ? true
        : false;
    return (
      <Modal
        centered
        className={"uploadModal-" + this.props.theme.name}
        isOpen={true}
        style={{ maxWidth: "30%!important" }}
        toggle={() => this.preventDefault()}
      >
        <ModalHeader toggle={() => this.props.toggleEvidence()}>
          {I18n.t("evaluation.evidence")}
        </ModalHeader>
        <ModalBody>
          <Container className="evidenceFooter">
            <label className={"questionLabel"}>
              {I18n.t("evaluation.uploadEvidence")}
              <span className="redFont"> *</span>
            </label>
            <Row>
              <Col md="8" className="pl-0">
                <input
                  id="fileUploadBx"
                  ref="fileUploadBx"
                  type="text"
                  className="form-control"
                  placeholder={I18n.t("evaluation.fileUpload")}
                  readOnly
                />
              </Col>
              <Col md="1" className="ml-0 pl-0">
                <img
                  id="libFileUploadTrigger"
                  src="images/folder.png"
                  style={{ cursor: "pointer" }}
                  alt=""
                />

                <input
                  id="libFileUpload"
                  type="file"
                  onChange={(e) => {
                    this.handleLibFileChange(e.target);
                  }}
                  ref="libFileUpload"
                  accept={".xlsx,.xls,.doc,.docx,.pdf,.jpg,.jpeg,.png,.txt"}
                  style={{
                    display: "none",
                  }}
                  multiple
                  disabled={!hasAccess}
                />
              </Col>
              <Col md="3" className="ml-0 pl-0">
                {this.state.selectedFile &&
                this.state.selectedFile.length > 0 &&
                this.state.beforeOrAfter !== "" ? (
                  <button
                    className={"button-" + this.props.theme.name}
                    onClick={this.saveFile}
                  >
                    {I18n.t("library.upload")}
                  </button>
                ) : (
                  <button
                    className={"button-" + this.props.theme.name}
                    disabled
                  >
                    {I18n.t("library.upload")}
                  </button>
                )}
              </Col>
            </Row>
            <label className={"questionLabel"}>
              {I18n.t("evaluation.beforeAfterLabel")}
              <span className="redFont"> *</span>
            </label>
            <Row>
              <Col
                md="8"
                className="pl-0"
                style={{ margin: "0px 0px 0px -12px" }}
              >
                <label className="col-md-3 control-label">
                  <input
                    type="radio"
                    id="before"
                    value="before"
                    checked={this.state.beforeChecked}
                    onChange={(e) => this.onChangeRadio(e)}
                  />{" "}
                  {I18n.t("evaluation.before")}
                </label>
                <label className="col-md-3 control-label">
                  <input
                    type="radio"
                    id="after"
                    value="after"
                    checked={this.state.afterChecked}
                    onChange={(e) => this.onChangeRadio(e)}
                  />{" "}
                  {I18n.t("evaluation.after")}
                </label>
              </Col>
            </Row>
          </Container>
        </ModalBody>
        <ModalFooter>
          <Container className="viewLibraries evidenceFooter" style={{ padding: "0rem" }}>
            <Row
              style={{
                overflowX: "scroll",
                overflowY: "auto",
                maxHeight: "50vh",
              }}
            >
              <Col xs="6" sm="6" md="6" lg="6">
                <div
                  className="parameterTableKaizen"
                  style={{
                    paddingTop: this.state.beforeArr.length ? "0px" : "20px",
                  }}
                >
                  <BootstrapTable2
                    keyField="fileId"
                    data={this.state.beforeArr}
                    noDataIndication={I18n.t("evaluation.noEvidence")}
                    columns={beforeColumns}
                    cellEdit={cellEditFactory({
                      mode: "click",
                    })}
                    selectRow={selectRowBefore}
                  />
                </div>
              </Col>
              <Col xs="6" sm="6" md="6" lg="6">
                <div
                  className="parameterTableKaizen"
                  style={{
                    paddingTop: this.state.afterArr.length ? "0px" : "20px",
                  }}
                >
                  <BootstrapTable2
                    keyField="fileId"
                    data={this.state.afterArr}
                    noDataIndication={I18n.t("evaluation.noEvidence")}
                    columns={afterColumns}
                    cellEdit={cellEditFactory({
                      mode: "click",
                    })}
                    selectRow={selectRowAfter}
                  />
                </div>
              </Col>
            </Row>
          </Container>
        </ModalFooter>
      </Modal>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    user: state.loginReducer,
    evidence: state.evaluationReducer,
  };
};
export default withRouter(connect(mapStateToProps)(withTheme(uploadEvidence)));

let disallowedFileList =
  "0XE,73K,89K,8CK,A6P,A7R,AC,ACC,ACR,ACTC,ACTION,ACTM,AHK,AIR,APK,APP,APP,APP,APPLESCRIPT,ARSCRIPT,ASB,AZW2,BA_,BAT,BEAM,BIN,BIN,BTM,CACTION,CEL,CELX,CGI,CMD,COF,COFFEE,COM,COMMAND,CSH,CYW,DEK,DLD,DMC,DS,DXL,E_E,EAR,EBM,EBS,EBS2,ECF,EHAM,ELF,EPK,ES,ESH,EX4,EX5,EXE,EXE,EXE1,EXOPC,EZS,EZT,FAS,FAS,FKY,FPI,FRS,FXP,GADGET,GPE,GPU,GS,HAM,HMS,HPF,HTA,ICD,IIM,IPA,IPF,ISU,ITA,JAR,JS,JSE,JSF,JSX,KIX,KSH,KX,LO,LS,M3G,MAC,MAM,MCR,MCR,MEL,MEM,MIO,MLX,MM,MPX,MRC,MRP,MS,MS,MSL,MXE,N,NCL,NEXE,ORE,OSX,OTM,OUT,PAF,PAFEXE,PEX,PHAR,PIF,PLSC,PLX,PRC,PRG,PRG,PS1,PVD,PWC,PYC,PYO,QIT,QPX,RBF,RBX,RFU,RGS,ROX,RPJ,RUN,RXE,S2A,SBS,SCA,SCAR,SCB,SCPT,SCPTD,SCR,SCRIPT,SCT,SEED,SERVER,SHB,SMM,SPR,TCP,THM,TIAPP,TMS,U3P,UDF,UPX,VBE,VBS,VBSCRIPT,VDO,VEXE,VLX,VPM,VXP,WCM,WIDGET,WIDGET,WIZ,WORKFLOW,WPK,WPM,WS,WSF,WSH,X86,XAP,XBAP,XLM,XQT,XYS,ZL9";