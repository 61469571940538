import React from "react";
import { Row} from "reactstrap";
import PropTypes from 'prop-types';
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { withStyles } from '@material-ui/core/styles';
import Chip from '@material-ui/core/Chip';
import Paper from '@material-ui/core/Paper';
const $ = require('jquery')
window.jQuery = $;
var styles = theme => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
    boxShadow:'unset',
	marginLeft:'12px',
    marginRight:'12px',
    // padding: theme.spacing.unit / 2,
  },
  chip: {
    fontFamily: "inherit",
    fontSize:"12px",
    height:"20px",
	backgroundColor: "#e50000",
	color: "white",
    marginRight:"3px",
    transition:"unset",

    // margin: theme.spacing.unit / 2,
  },
});

class ChipsFile extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            fileList: [],
            selected: [],
            chipData: [],
            theme:'toyota'
        }
        this.handleDelete = this.handleDelete.bind(this);
    }
    
    componentWillMount() {
        
    }
    componentWillReceiveProps(nextProps) {
        if(nextProps.chipData){
            this.setState({chipData:nextProps.chipData});
        }
        if(nextProps.theme){
            this.setState({theme:nextProps.theme});
        }
    }
    componentDidUpdate(prevProps,prevState) {
        if(prevProps.chipData!==this.props.chipData){
            this.setState({chipData:this.props.chipData});
        }
    }
    handleDelete = data => () => {
        this.setState(state => {
        const chipData = [...state.chipData];
        const chipToDelete = chipData.indexOf(data);
        chipData.splice(chipToDelete, 1);
        this.props.handleChipsData(data);
        return { chipData };
        });
    };
    render(){
        if(this.state.theme==="lexus" && $('div.toyotaChips')){
            $(".toggleChip").removeClass("toyotaChips");
            $(".toggleChip").addClass("lexusChips");
        }
        else if(this.state.theme==="toyota" && $('div.lexusChips')){
            $(".toggleChip").removeClass("lexusChips");
            $(".toggleChip").addClass("toyotaChips");
        }else {
            //..
        }
        var styleComp=styles;
        console.log(styleComp);
        const { classes } = this.props;
        return(<div>
            <Row className="chipsRender">
                    <Paper className={classes.root}>
                        {this.state.chipData.map(data => {
                        return (
                            <Chip
                            key={data.key}
                            label={data.label}
                            onDelete={this.handleDelete(data)}
                            className={classes.chip+" toyotaChips toggleChip"}
                            />
                        );
                        })}
                    </Paper>
                </Row>
        </div>);
    }
}
ChipsFile.propTypes = {
  classes: PropTypes.object.isRequired,
};
const mapStateToProps = state => {
    return {
        user: state.loginReducer,
    };
};
export default withRouter(connect(mapStateToProps)(withStyles(styles, { withTheme: true })(ChipsFile)
));