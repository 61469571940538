const translationsDA = {
  da: {
    Policy: {
      HeaderMsg: "Beskyttelse af personlige oplysninger ved brug af dine personlige data i Kaizen Expert Tool",
      Message1: "Denne fortrolighedserklæring vedrører behandlingen af dine personlige data i Kaizen Expert Tool. Det skal altid læses sammen med den generelle Toyota-politik for beskyttelse af personlige data, hvori de generelle regler for behandlingen af dine personoplysninger beskrives, og som du skal acceptere for at kunne fortsætte.",
      Message2: "Toyota Motor Europe NV / SA, med hovedkontor i Avenue du Bourget / Bourgetlaan 60, 1140 Bruxelles, Belgien, som datakontroller, indsamler dine personlige data (som angivet nedenfor) med det formål som beskrevet nedenfor.",
      Message3: "Hvilke persondata vil blive indsamlet?",
      Message4: "Vi indsamler følgende kategori / er af dine persondata til brug i Kaizen Expert Tool:",
      Table1: {
        tr1: "Kategori/er af Personlige Data:",
        tr2: "Bruger Id",
        tr3: "Bruger navn (fulde navn(",
        tr4: "Mærker (Toyota, Lexus)",
        tr5: "Foretrukket sprog",
        tr6: "Tilknyttet organisation"
      },

      Message5: "Formål og retsgrundlag for behandlingen af dine personlige data.",
      Message6: "Vi behandler dine personlige data med det formål og på de retsgrundlag, der er beskrevet nedenfor:",
      Table2: {
        tr1: {
          th1: "Formål:",
          th2: "Retsgrundlag:"
        },
        tr2: {
          td1: "KET - 1: Forhandler Performance Kaizen",
          td2: "Legitim interesse for databehandleren"
        },
        tr3: {
          td1: "KET – 2:IT Support",
          td2: "Legitim interesse for databehandleren"
        }
      },
      Message7: "Varighed af opbevaring af dine personlige data",
      Message8: "Dine personlige data vil blive opbevaret i den angivne periode:",
      Table3: {
        tr1: {
          th1: "Opbevarings periode:",
          th2: "Kategori/er af Personlige Data:"
        },
        tr2: {
          td1: "Eventuelle personlige oplysninger som beskrevet ovenfor bevares i maksimalt 5 år + 1 måned fra lukningen af Kaizen Expert Tool.",
          td2: "Bruger fornavn, Efternavn, Sprogpræference, organisation"
        }
      },
      Message9: "Valgfrit: I tilfælde af offentliggørelse / overførsel af dine personoplysninger til lande uden for Det Europæiske Økonomiske Samarbejdsområde (EØS)",
      Message10: "Dine personoplysninger vil blive gjort tilgængelige for modtagere i lande uden for EØS som angivet nedenfor:",
      Table4: {
        tr1: {
          td1: "Identitet",
          td2: "Land",
          td3: ""
        },
        tr2: {
          td1: "Ikke-EEA modtager(e) ",
          td2: "Infosys",
          td3: "Indien"
        }
      },
      Message11: "Jeg bekræfter hermed at have forstået denne meddelelse Jeg har læst og accepteret den generelle Toyota Privacy Policy og Persondata beskyttelses Politikken."
    },
    ACCESSORIES: "Tilbehør",
    activity: {
      Assignee: "Tildelt",
      CompletionDate: "Gennemført dato",
      Dealer: "Forhandler",
      FirstVisit: "Første besøg",
      kpi: {
        kpi: "KPI skal være tal (+ve eller -ve)",
        kpiLength: "Maximum længde for et KPI er på 10 cifre",
        mandatory: "Angiv gyldige data i de obligatoriske felter",
        mandatoryTargets: "Indtast alle KPI'er",
        target: "Mål kan kun være tal",
        targetLength: "Maximum længde for et mål er på 10 cifre"
      },
      ReminderOn: "Påmindelse til",
      statusDD: "Status",
      Toolbox: "Værktøjskasse",
      activityCreation: "Processen til oprettelse af aktivitet er startet.",
      activityCreated: "Aktivitet(er) er oprettet"
    },
    DPOK: "Lager",
    EFFICIENCY: "Effektivitet",
    FIX_IT_RIGHT: "Fix It Right",
    footer: {
      message: "Obligatoriske felter"
    },
    FOUNDATION: "Fundament",
    MAINTENANCE_REMINDERS: "Eftersynspåmindelse",
    EMPLOYEE_ENGAGEMENT: "Employee Engagement",
    CUSTOMER_CENTRICITY: "Customer Centricity",
    TOYOTA_WAY: "Toyota Way",
    BUSINESS_SUSTAINABILITY: "Business Sustainability",
    FUNDAMENTALS: "Grundlæggende",
    FIX_IT_RIGHT_BnP: "Fix it Right (B&P)",
    UTILIZATION_AND_EFFICIENCY: "Utilization and Efficiency",
    CUSTOMER_EXPERIENCE: "Customer Experience",
    UPSELLING_OPPORTUNITIES: "Upselling Opportunities",
    MANAGING_CAPACITY: "Managing Capacity",
    VEHICLE_TRADE_IN: "Vehicle Trade-In",
    STOCK_MANAGEMENT: "Stock Management",
    REFURBISHMENT: "Refurbishment",
    months: {
      apr: "Apr",
      aug: "Aug",
      dec: "Dec",
      feb: "Feb",
      jan: "Jan",
      jul: "Jul",
      jun: "Jun",
      mar: "Mar",
      may: "Maj",
      nov: "Nov",
      oct: "Okt",
      sep: "Sep"
    },
    others: {
      back: "Tilbage",
      closed: "Lukket",
      completed: "Gennemført ",
      countryLanguageCode: "Land/landekode ",
      createActivity: "Opret aktivitet",
      createdDate: "Oprettelsesdato",
      dataUneditableNote:
        "Bemærk: Gemte data kan ikke redigeres. Hvis du vil redigere data, skal du kontakte NMSC-admin",
      date: "Dato",
      downloadQuestionnaire: "Download spørgeskema",
      editActivity: "Rediger aktivitet",
      initialHelpText:
        "KPI-før-målingen skal være gennemsnitsværdien af de forrige seks hele måneder",
      inProgress: "Igangværende",
      name: "Navn",
      next: "Næste",
      noActivities: "Ingen aktiviteter fundet",
      noResults: "Ingen resultater",
      of: "af",
      ongoing: "Igangværende",
      open: "Åbn",
      page: "Side",
      planned: "Planlagt",
      problemDescription: "Problembeskrivelse",
      signature: "Underskrift",
      select: "Vælg",
      bulkAction: "Bulk Action",
      bulkActions: "Bulk Actions"
    },
    ServerMessage: {
      error_message: "En fejl opstod under download.",
      no_published_subquestion: "Udgiv spørgeskemaet til denne toolbox for at starte denne aktivitet.",
      activity_upload_error_msg: "Der opstod en fejl under upload af aktivitetsoplysninger.",
      action_plan_created: "Handlingsplan oprettet",
      action_plan_deleted: "Handlingsplan slettet",
      action_plan_saved: "Handlingsplan gemt ",
      activity_kpi_saved: "Aktivitetes KPI gemt",
      master_kpi_confirm_delete:
        "Er du sikker på at du vil slette det valgte KPI?",
      master_kpi_created: "Master-KPI blev oprettet",
      master_kpi_deleted: "Master-KPI blev slettet",
      master_kpi_not_found: "Master-KPI blev ikke fundet",
      master_kpi_published: "De markerede Master-KPI'er blev udgivet",
      master_kpi_retired: "Master-KPI blev udfaset",
      master_kpi_saved: "Master-KPI blev gemt",
      subquestion_created: "Undersøgelsen er blevet gemt.",
      subquestion_updated: "Underspørgsmålet er blevet opdateret.",
      subquestionnaire_not_found: "Underspørgsmålet er ikke fundet.",
      subquestion_retired: "Underspørgsmålet er blevet gemt.",
      subquestion_deleted: "Underspørgsmålet er slettet.",
      subquestion_published: "The sub-questions have been successfully published.",
      mainquestion_created: "Hovedspørgeskemaet er oprettet.",
      mainquestion_deleted: "Hovedspørgeskemaet er blevet slettet.",
      mainquestion_updated: "Hovedspørgsmålet er blevet opdateret.",
      duplicateMsg: "Duplikerer optegnelser i Excel-filen.",
      deleteErr: "Fejl mens filen blev slettet",
      toolbox_deleted: "Toolboxen er blevet slettet succesfuldt",
      toolbox_already_exists: "Denne toolbox eksisterer allerede",
      toolbox_saved: "Toolbox gemt",
      toolbox_created: "Toolbos oprettet",
      toolbox_not_found: "Toolbox ikke fundet",
      published_content_found: "Denne toolbox kan ikke slettes, da noget af indholdet er offentligjort",
      dealers_already_mapped: " De valgte forhandlere er allerede kortlagt for denne bruger. ",
      dealer_already_mapped: " Dealer already mapped ",
      dealer_added: "  Forhandler tilføjet. ",
      dealers_added: "  Forhandler tilføjet. ",
      total_dealers_mapped_now: "Det samlede antal forhandlere, der i øjeblikket er tilknyttet denne bruger, er. ",
      no_dealer_available: "Ingen forhandler (e) til rådighed for de valgte kriterier.",
      proper_dealer_selected: "Vælg en passende forhandler.",
      user_referenced_ongoing_activity: "Brugeren er i øjeblikket tildelt igangværende aktiviteter. Udskift modtager i disse aktiviteter, og prøv igen. ",
      want_to_remove_mapping: "Er du sikker på, at du vil fjerne brugerens kortlægning for disse forhandlere?",
      user_mapping_removed: "De valgte forhandlere er blevet fjernet med succes fra brugerens kortlægning.",
      dealer_closed_in_A2D: "Denne forhandler er registreret som 'lukket' i A2D.",
      network_level_not_found: "Vælg en gyldig kombination af netværksniveauer i henhold til din KET-netværksopsætning.",
      duplicate_dealer_uuid: "Forhandler allerede til stede i KET-databasen.",
      dealer_created: "Forhandler oprettet.",
      dealer_updated: "Denne forhandlers attributter er blevet opdateret.",
      dealer_not_present: "Denne forhandler er ikke registreret i A2D.",
      dealer_uuid_other_nmsc: "Den valgte A2D uuid-kode er ikke tilgængelig for dit netværk.",
      target_kpi_updated: "The KPI's status have been updated successfully.",
      target_updated: "The KPI's target have been updated successfully.",
      kpi_launched: "The KPI's bulk launch successfully.",
      targetUpdate:"Application doesn't allow target to be modified for accepted status.",
    },
    standardMessages: {
      delete: "Er du sikker på at du vil slette?",
      addActionPlanNotAllowed: "Handlingsplanen kan ikke oprettes eller redigeres efter at aktiviteten er lukket, Gennemført eller afvist.",
      addToolboxNotAllowed: "Cannot create more than 19 toolbox",
      britRejectMsg: "Er du sikker på, at du vil afvise denne aktivitet? Ændringer er ikke mulige efter afvisning",
      rejectMsg: "Er du sikker på, at du vil afvise denne aktivitet? Ændringer er ikke mulige efter afvisning",
      selectNMSCTab: "Vælg sprog for at offentliggøre",
      all: "Alle",
      deleteConfirm: "Er du sikker på, at du vil slette den valgte KPI?",
      no: "Nej",
      retireConfirm:
        "Er du sikker på, at du vil tilbagetrække denne KPI? Efter tilbagetrækning kan KPI'en ikke aktiveres igen",
      warning: "Advarsel",
      yes: "Ja",
      saveDataConfirmation: "Er du sikker på, at du vil gemme disse data?",
      saveKPIWarning: "Bemærk: Data kan ikke redigeres, når de er gemt. Rediger data ved at kontakte KET-administratoren ved TDK",
      addKpiNotAllowed: "Den næste måneds KPI input kan tilføjes, når den nuværende måned slutter.",
      activityLaunch: "Er du sikker på, at du vil lancere denne aktivitet? Efter lanceringen kan denne aktivitet ikke trækkes  tilbage til status 'planlagt' eller slettes.",
      deleteConfirmSubQuestionNaire: "Are you sure you want to delete the selected sub-question?",
      retireConfirmSubQuestionNaire: "Are you sure you want to retire the selected sub-question?",
      deleteConfirmMainQuestion: "Please confirm if you want to delete the Main question and its related sub-questions.",
      deleteConfirmChapter: "Er du sikker på, at du vil slette kapitlet?",
      deleteLibraryFolder: "Er du sikker på, at du vil slette denne mappe?",
      deletePublishedFolder: "Nogle filer i denne mappe kan være knyttet til evalueringen. Er du sikker på, at du vil slette denne undermappe permanent og fjerne linket / linkene fra spørgeskemaet?",
      importQuestion: "Er du sikker på, at du vil importere dette Underspørgsmål? Nuværende spørgsmål erstattes.",
      noQuestion: "Ingen spørgsmål tilgængelige.",
      MandatoryWarning: "Udfyld alle obligatoriske felter for alle lokale sprog.",
      certificationSubmission:
        "Toolbox er sendt til TME for forhandler certificering",
      sameToolBox: "Du kan ikke sende samme Toolbox mer end en gang. Vælg en anden Toolbox.",
      deleteConfirmDealer: "Are you sure you want to delete this dealer ?",
      updateVcConfirmDealer: "Alle aktiviteter for denne forhandler i Value Chain vil blive fjernet. Er du sikker på at du vil fortsætte? ",
      updateBritConfirmDealer: "Alle aktiviteter for denne forhandler i BRiT vil blive fjernet. Er du sikker på at du vil fortsætte? ",
      completed_activity_delete:"Data kan ikke modtages igen når de er slettet. Er du sikker på at du vil fortsætte?",
      deleteAllQuestionary: "Are you sure you want to delete the entire Questionnaire?",
      deleteAllLibraries: "Are you sure you want to delete all folders and files in the Library?",
      selectAll: "Vælg alle",
      unSelectAll: "Fravælg alle",
      select:"Vælg",
      warningMsg:"Der er åbne handlingsplaner i denne aktivitet. Vil du afslutte aktiviteten?"
    },
    status: {
      published: "Udgivet",
      retired: "Udfaset",
      saved: "Gemt",
      rejected: "Afvist",
      open: "Åbn",
      closed: "Lukket",
      close: "Lukket",
      ongoing: "Igangværende",
      completed: "Gennemført",
      complete: "Gennemført",
      planned: "Planlagt",
      overdue: "Forsinket",
      due: "Forfalder",
      pending: "Pending",
    },
    Rejected: "Afvist",
    Open: "Åbn",
    Closed: "Lukket",
    Close: "Lukket",
    Ongoing: "Igangværende",
    Completed: "Afsluttet",
    Complete: "Afsluttet",
    Planned: "Planlagt",
    tabDetails: {
      noActionAvailable: "Ingen tilgængelige handlinger",
      activeDealer: "Denne forhandler er i øjeblikket registreret som aktiv i A2D og kan ikke slettes.",
      dealerDeleted: "Dealer is deleted."
    },
    Table: {
      action: "",
      activity: "Aktivitet",
      activityCompletionDate: "Aktivitet gennemført dato",
      activityCompletionDateFrom: "Aktivitet gennemføres fra dato",
      activityCompletionDateTo: "Aktivitet gennemføres til dato",
      activityStartDate: "Startdato for aktivitet",
      activityStartDateFrom: "Aktivitet starter fra dato",
      activityStartDateTo: "Aktivitet starter til dato",
      assignedTo: "Tildelt",
      assignee: "Tildelt",
      benchmark: "BM",
      city: "By",
      completedBy: "Fuldført af ",
      completionBy: "Gennemføres til",
      completionDate: "Gennemført dato",
      counterMeasure: "Afhjælpning",
      country: "Land",
      createdBy: "Oprettet af",
      dealer: "Forhandler",
      dealerNameAD: "Forhandlernavn (A2D)",
      dealerName: "Forhandlernavn",
      definition: "Definition",
      description: "Beskriv]'else",
      dueDate: "Forfaldsdato",
      endDate: "Slutdato",
      firstVisit: "Første besøg",
      followup: "Opfølgning",
      franchise: "Franchise",
      history: "Historik",
      id: "ID",
      initial: "Indledende",
      kpi: "KPI",
      launch: "Lancering",
      mandatory: "Obligatorisk",
      nmsc: "NMSC",
      problem: "Problem",
      publishedOn: "Udgivet pr.",
      questionReference: "Spørgsmålsreference",
      reference: "Reference",
      reminderOn: "Påmindelse til",
      score: "Resultat",
      startDate: "Startdato",
      status: "Status",
      target: "Target",
      toolbox: "Værktøjskasse",
      view: "Vis",
      kpiResult: "KPI resultat",
      nmscTarget: "NMSC Target",
      penEbenchmark: "PanE Benchmark",
      kpiField: "KPI",
      location: "Lokation",
      ActionItemReport: "Igangværende handlingselementer",
      certification: "Certificeringsstatus",
      noOfToolbox: "Antal gennemført værktøjskasser",
      program: "Program",
      certificationDate: "Certificeret dato",
      activityId : 'Aktivitets ID',
      approvedBy:"Approved By",
      kpis:"KPIs",
      lastmonthKpis:"Last Month KPIs",
      questionPercent:"Completion %",
      actionPlan: "Handlingsplan",
      monthlyKpi:"Monthly KPIs",
      initialKpi:"Initial KPIs",
      targetKpi:"Target KPIs",
    },
    tabsDetails: {
      actionPlan: "Handlingsplan",
      confirmDeleteMessage: "Er du sikker på, at du vil slette den valgte KPI?",
      confirmRetireMessage:
        "Bekræft, at du vil udfase det markerede KPI, da det allere tidligere er udgivet",
      errorRetireMsg:
        "KPI'en kan ikke tilbagetrækkes, da den er en aktiv TME-obligatorisk KPI",
      import: "Importer",
      importKpiMessage: "Importer dine KPI'er fra listen over TME Master - KPI'er",
      kpi: "Key Performance Indicators",
      library: "Bibliotek",
      noActionAvailable: "Ingen tilgængelige handlinger",
      NoKPI: "Ingen tilgængelige KPI'er",
      publish: "Udgiv",
      questionnaire: "Spørgeskema",
      tmeKPIList: "Liste over TME Master-KPI'er",
      toyotaCityBrussels: "TOYOTA CITY BRUSSELS",
      attachment: "Attachment",
      attachments: "Attachment(s)",
      uploadAttachment: "Upload Attachment",
      noAttachment: "No attachments available",
    },
    tmeAdminMaster: {
      ToolboxName: "Toolbox navn",
      newToolboxName: "Nyt navn",
      deleteToolbox: "Slet toolbox?",
      programName: "Program navn",
      ToolboxName_EN: "Toolbox navn",
      deleteTool: "Er du sikker på at du vil slette denne toolbox?",
      selectToolbox: "Vælg en toolbox som skal slettes",
      editToolBox: "Tilret toolbox",
      addToolbox: "Tilføj toolbox",
      toolbox: "Indtast et navn til denne toolbox",
      addActionPlan: "Add Handlingsplan",
      actionPlanItem: "Handlingssplan – element nr.",
      add: "Tilføj",
      addActivity: "Tilføj aktivitet",
      additionalInformation: "Yderligere Information",
      benchmark: "BM",
      cancel: "Annuller",
      createEditActivity: "Opret/rediger aktivitet",
      createKPI: "Opret KPI",
      delete: "Slet",
      download: "Hent",
      edit: "Rediger",
      editKPI: "Rediger KPI",
      keyPerformanceIndicator: "Key Performance Indicator",
      kpiDefinition: "KPI-definition",
      mandatoryKpi: "Obligatorisk KPI",
      save: "Gem",
      search: "Søg",
      unit: "Enhed",
      update: "Opdater",
      validData: "Indtast gyldige data",
      hide: "Skjul",
      show: "Vis",
      upload_dealer_network: "Upload forhandler netværk",
      upload_question: "Upload evaluerings spørgsmål",
      upload_user: "Upload bruger detaljer",
      upload_activity: "Upload activiteter",
      remove: "Remove",
      toyota: "Toyota",
      lexus: "Lexus",
      launch: "Launch",
      close: "Close",
      generate: "Generate",
      dueDateExceed:"Due date exceeded",
      delQuestionnaire:"Delete questionnaire"
    },
    toastr: {
      activityStatus_condition: "Besvar alle spørgsmål for at kunne lukke denne aktivitet.",
      emptyTarget: "Please enter the NMSC target to publish the KPI",
      retailerActual: "Please enter the Retailer Actual",
      initialTargetMonthly: "Indtast den indledende KPI, KPI mål og mindst 1 måneds KPI for at fortsætte.",
      initialTarget: "Indtast de nuværende KPI målinger samt KPI mål for at fortsætte.",
      upload_error: "Der opstod en fejl under upload af forhandlernetværket.",
      action_item_create_warning: "Maksimum fem notater kan fusioneres til én handling.",
      actionPlanStartDateValidation:
        "Slutdatoen kan ikke være tidligere end startdatoen",
      actionsNotClosed:
        "Der er igangværende handlinger i handlingsplanen. Luk alle handlinger, før en aktivitet lukkes",
      activityDuplicate:
        "En anden aktivitet med samme forhandler og værktøjskasse findes allerede",
      assigneeNotExist: "Den valgte person er ikke registreret i systemet",
      assigneeValidation: "Ugyldig person",
      atleastOnePublish: "Vælg mindst én KPI at offentliggøre",
      cannotRetireActive:
        "Der er en aktiv TME-obligatorisk KPI, der ikke kan tilbagetrækkes ",
      completionDate:
        "Fuldførelsesdatoen kan ikke være tidligere end datoen for første besøg",
      confirDeletePlannedActivity:
        "Er du sikker på, at du vil slette den planlagte aktivitet?",
      DateformatValidation: "Dato skal være i formatet DD/MM/YYYY",
      DateValidation: "Start dato skal være før Slut dato",
      dealerValidation: "Ugyldig forhandler",
      deleteSelectedConfirm:
        "Er du sikker på, at du vil slette den valgte KPI?",
      duplicate: "En anden KPI med samme navn og definition findes allerede",
      modalMandatory: "Angiv gyldige data i de obligatoriske felter",
      noPublishKpi:
        "KPI'erne er endnu ikke offentliggjort for den valgte værktøjskasse",
      validSearchCriteria: "Angiv et gyldigt søgekriterium",
      multiSelectPlaceHolder: "Indtast mindst 5 karakterer",
      multiSelectPlaceHolderUser: "Indtast mindst 3 karakterer",
      startDateValidation: "Start dato kan ikke være højere end Slut Dato",
      fileSelectionErr: "Vælg filen først",
      atleastOneQuestion: "Please select at least one Sub Question to import",
      atleastOneSubQues: "Vælg mindst et under-spørgsmål for at offentligøre.",
      uploadErr: "Antal filer, som ikke blev uploadede:",
      uploadSuccess: "Forhandler Netværk uploadet succesfuldt.",
      file_upload_success: "Filen / filerne er blevet uploadet.",
      KPIinOngoingClosedActivity: "Dette KPI findes in en, eller flere, igangværen/lukkede aktiviteter. Afslut disse aktiviteter inden KPI'et ændres.",
      Role_Error: "Brugeropsætningen er ufuldstændig, du bedes kontakte NMSC KET-administratoren.",
      points_allocation_incorrect: "Forkert pointallokering, rediger venligst.",
      activity_deleted:"Aktivitet slettet",
      activity_certified_dealer:"Forhandleren er certificeret for den valgteaktivitet, den kan ikke slettes.",
      activity_submited_for_certificate_dealer:"Denne aktivitet er inkluderet i certificerings anmodningen til TME og kan derfor ikke slettes."
    },
    TopNav: {
      ActivityLog: "Aktiviteter",
      ApplicationName: "Kaizen Expert Tool",
      Home: "Startside",
      logout: "Log af",
      MasterDataUpload: "Upload master-data",
      Toolbox: "Toolbox",
      welcome: "Velkommen",
      Admin: "Admin"
    },
    questionnaire: {
      addSubQuestion: "Tilføj underspørgsel",
      editSubQuestion: "Rediger underspørgsmålet",
      subQuestionTitle: "Underspørgsmål",
      additionalInfo: "Yderligere Information",
      na: "Tilføj 'Ikke relevant' som et muligt svar på dette spørgsmål",
      question: "Spørgsmål",
      questionTitle: "Spørgsmålets titel",
      why: "Hvorfor?",
      addMainQuestion: "Tilføj Hovedspørgsmålet",
      editMainQuestion: "Rediger Hovedspørgsmålet",
      previousQuestion: "Forrige spørgsmål",
      nextQuestion: "Næste spørgsmål",
      what: "Hvad?",
      library: "Bibliotek",
      subQuestionTable: {
        no: "Nej",
        subQuestion: "Underspørgsmål",
        status: "Status",
        publishedDate: "Udgivet dato",
        notApplicable: "Ikke anvendelig"
      },
      links: {
        question_link_button: "Link",
        question_link_include_folder: "Inkluder en undermappe",
        question_link_add_link: "Link fil(er) fra biblioteket",
        question_link_view_folder: "Se bibliotek"
      },
      mainQuestion: "Nye / opdaterede hovedspørgsmål:",
      subQuestion: "Nye / opdaterede Underspørgsmål:",
      retiredQuestion: "Gemte Underspørgsmål:",
      libraries: "Nye / opdaterede filer i biblioteker:",
      noFiles: "Ingen filer tilgængelige."
    },
    library: {
      selectFile: "Vælg mindst en fil",
      fileSelected: "Filer valgt",
      publish_library_file: "Vælg mindst en fil",
      library_files_deleted: "Er dusikker på at du vil slettet denne fil?",
      library_files_linked_deleted: "Er du sikker på at du vil slette filen og links i spørgeskemaet?",
      max_size_error: "Filstørrelsen overstiger grænsen på 1 GB pr. Upload.",
      empty_file_desc: "Indtast en fil beskrivelse.",
      file_not_selected: "Vælg den/de fil/filer, der skal uploades (maks. 1 GB)",
      file_type_error: "Denne filtype er ikke tilladt.",
      file_name_error: "Ugyldigt tegn i filnavnet.",
      file_name_lenght_error: "Filnavnet kan have maksimalt 50 tegn.",
      addFolder: "Tilføj mappe",
      editFolder: "Rediger mappe",
      currentFolder: "Mappenavn",
      parentFolder: "Navn på 'moder' mappe",
      emptyFolderName: "Mappenavnet kan ikke være tomt.",
      invalidFolderName: "Mappenavnet kan ikke indeholde '/'.",
      folder_created: "Mappen er blevet oprettet succesfuldt.",
      folder_updated: "Mappen er blevet opdateret.",
      folder_not_found: "Mappe ikke fundet",
      lib_invalid_toolbox: "Valgt ugyldig værktøjskasse",
      lib_folder_already_exists: "Denne mappe eksisterer allerede.",
      lib_folder_deleted: "Mappen er blevet slettet korrekt.",
      library_files_published: "Filerne er publiceret.",
      addFile: "Tilføj fil",
      editFile: "Rediger fil",
      fileName: "Filnavn",
      upload: "Upload",
      desc: "Beskrivelse",
      folderCreationErr: "Hiraki begrænset til to niveauer.",
      subFolderWarning: "Vælg en under-folder for at tilføje fil(er)",
      deleteWarning: "Vælg en mappe, der skal slettes.",
      beforeAfterNotSelected: "Vælg om beviset er fra før eller efter kaizen",
    },
    chapter: {
      chapter_exist: "Dette kapitel findes allerede.",
      chapter_saved: "Kapitel gemt",
      chapter_cannot_delete: "This Chapter cannot be deleted as sub-questions have already been published.",
      failed: "Mislykkedes !",
      chapter_deleted: "Kapitel slettet",
      chapter_nonEmpty: "Opret venligst er navn til kapitlet",
      addchapter: "Tilføj kapitel",
      editChapter: "Ret kapitel",
      DELETE_SUCC:"Questionnaires deleted successfully.",
      DELETE_SUCC_LIB:"Libraries deleted successfully.",
      DELETE_ERR : "Something Went wrong."
    },
    evaluation: {
      attachments: "Vedhæftede filer",
      comments: "Kommentarer",
      evidence: "Bevis",
      evidences: "Bevis (er)",
      yes: "Ja",
      no: "Nej",
      na: "N/A",
      uploadEvidence: "Upload bevis",
      fileUpload: "Vælg den/de fil/filer, der skal uploades (maks. 1 GB)",
      who: "Hvem?",
      answer: "Svar",
      comment: "Comment",
      date: "Dato",
      submit: "Indsend",
      commentMsg: "Indtast kommentarer.",
      noCommentAvailable: "Ingen kommentarer tilgængelige.",
      uploadSuccessMsg: "File(s)  have been uploaded successfully",
      uploadErrorMsg: "Number of files failing to upload:",
      fileWithSameName: "Der eksisterer allerede en fil med samme navn.",
      noEvidence: "Ingen beviser tilgængelige",
      createAction: "Opret handling",
      chapterName: "Kapitel navn",
      questionNo: "Spørgsmål nummer.",
      when: "Hvornår?",
      noRecords: "Ingen tilgængelige poster.",
      kaizenPoints: "Kaizen punkter",
      renameSuccessMsg: "File has been renamed successfully",
      deleteSuccessMsg: "File has been deleted successfully",
      question: "Spørgsmål",
      before:"Before",
      after:"After",
      beforeAfterLabel:"Er beviset fra før eller efter kaizen?",
      move:"Flyt",
      moveMsg: "Filen/filerne er flyttet",
      noToYesMsg:"Previous answer was NO",
    },
    WALK_AROUND_CHECK: "Walk Around Check",
    ketProgram: {
      TSM: "TSM/LSM",
      BRiT: "BRiT RI",
      TSM_BNP: "Body & Paint",
      TSM_USED_CAR: "Brugte biler",
      value_chain: "Værdi Kæde",
      brit: "BRiT",
      ea: "EA",
      per:"Performance",
      nettrans:"Network Transformation",
    },
    dashboard: {
      notice_board: "Opslagstavle",
      kpi_target_not_set: "KPI mål ikke er ikke sat",
      kpi_initial_not_set: "KPI første måling er ikke sat",
      kpi_monthly_not_set: "KPI månedlige måling er ikke sat",
      kpi_below_target: "KPI under mål",
      activation: "Aktivering",
      users: "Brugere",
      nmscs: "NMSC's",
      activeRetailer: "Aktive forhandlere",
      certificationRate: "Certificeringsgrad",
      reCertificationRate: "Re-certificeringsgrad",
      activityDue: "Forfaldne aktiviteter",
      avgdays: "Gennemsnitlige dage / værktøjskasse",
      overDueAct: "Forfaldne aktiviteter",
      actionItem: "Action ting",
      bussResult: "Forretningsresultater",
      toolboxImpl: "Implementering af værktøjskasse",
      statusCompletedL:"Status - Completed, Progress - ",
      statusClosedL:"Status - Closed, Progress - ",
      statusOngoingL:"Status - Ongoing, Progress - ",
      recentUpdates: "Seneste opdateringer",
      rolloutTopNmsc: "Udrullningsresultater - Top NMSC",
      bestPractice: "Best Practices tavle",
      availableKpi: "Tilgængelige KPIer",
      selectedKpi: "Valgte KPI'er",
      dealerBestPractice: "Forhandler best practice tavle",
      kpiList: "KPI liste",
      go: "GO",
      nodata: "Ingen data til rådighed",
      businessResultTop: "Forretningsresultater - Topforhandlere",
      businessResultNMSC: "Forretningsresultater - Top NMSC"


    },
    dealerCertification: {
      dealerCertification: "Forhandlercertificering",
      readyForCertification: "Klar til certificering",
      certification: "Certificering",
      submittedOn: "Indsendt den",
      certificateDate: "Certifikat dato",
      reCertificateDate: "Re-Certificate Date",
      toolboxDetails: "Detaljer om værktøjskasse",
      approve: "Godkende",
      reject: "Afvis",
      pending: "Verserende",
      region: "Område"



    },
    uploadBulk: {
      browse: "Browse",
      executionId: "Udførelses-id",
      uploadedOn: "Uploadet den",
      lastUpdatedOn: "Sidste opdatering den",
      noDataTable: "No data to view",
      kpiUpload: "KPI er uploadet",
      action: "Handling",
      processed: "Behandlet",
      error: "Fejl",
      received: "Received",
      createActivity: "Opret aktiviteter",
      fileUpload: "File Upload",
      targetSetting: "Target Setting",
      kpiEntry:"KPI Entry",
      accepted:"Accepted",
      launchNmsc:"Launch for NMSC"
    },

    userManagement: {
      userManagement: "Brugersadministration",
      createUser: "Create User",
      editUser: "Edit User",
      viewUser: "View User",
      nmscId: "NMSC ID",
      tarsId: "TARS ID",
      firstName: "Fornavn",
      lastName: "Efternavn",
      emailId: "E-mail",
      britDealerChamp: "Brit Dealer Champion",
      invalidEmail: "Please enter valid E-Mail ID",
      invalidTars: "Please enter valid TARS ID",
      invalidFirstName: "Please enter valid First Name",
      invalidLastName: "Please enter valid Last Name",
      user_created: "User Created",
      user_updated: "User updated",
      duplicate_tarsuser: "This TARS id is already registered in the KET database.",
      user_define_in_tool: "User is defined as Business owner in Active Business Function. Hence, could not be made Inactive",
      user_refer_on: "User is referenced in Ongoing activity",
      pbiAccount : "PBI konto",
      invalidPbiAccount : "PBI kontoen skal bestå af min 3 karakterer",
      businessArea: "Forretningsområde",
      valueChainAndBRiT: "Value Chain & BRiT",
      only:"Only",
      optionText:"Please select at least one of the two options below",
      valueChainOnly: "Kun Value Chain",
      britOnly: "Kun BRiT"

    },
    UserManagement: {
      nmscId: "NMSC ID",
      tarsId: "TARS ID",
      firstName: "Fornavn",
      lastName: "Efternavn",
      emailId: "E-mail",
      languageCode: "Sprogkode",
      ACTIVE: "Active",
      INACTIVE: "Inaktiv",
      addUser: "Tilføj bruger",
      Assign: "Tildel",
      List: "Liste",
      user: "Bruger",
      error_message: "Der opstod en fejl. Prøv igen senere.",
      role: "Brugerrolle",
      standardMessage: "No User Found",
      dealerCode: "Forhandlerkode",
      a2d: "A2D UUID",
      britReady: "Forhandler deltog i Brit Ready.",
      britLive: "Forhandler deltog i Brit Live.",
      britJapan: "Forhandler deltog i Brit Discovery Japan.",
      britEss:"Forhandleren har opdateret EES handlingsplanen",
      addDealer: "Tilføj forhandler (fra A2D)",
      noDealer: "Ingen forhandler (e) fundet.",
      dealerUuid: "Forhandlerens UUID kode (A2D)",
      language: "Sprog",
      L3: "L3",
      L4: "L4",
      L5: "L5",
      L6: "L6",
    },
    CeaKpi: {
      survey: "Survey",
      evaluationItem: "Evaluation Item",
      kpiMetric: "KPI Evaluation Method",
      stratagicPillar: "Strategic Pillar",
      addKPI: "Add KPI",
      edit: "Edit",
      delete: "Delete",
      subPillar: "Sub-Pillar",
      kaizenImp_impact_def: "Kaizen Importance / Impact / Definition",
      evalMethod: "Evaluation Method",
      scoreMethod: "Scoring Method",
      typeOftarget: "Type of Target",
      target: "Target",
      mode: "Mode",
      points: "Points",
      inputFreq: "Input Frequency",
      cea_kpi_created: "CEA KPI has been created successfully",
      noPointsAllocated:"No Points defined for selected Pillar/Business function mapping",
      targetIncorrect:"Target value is Invalid",
      viewKpi:"View KPI",
      general:"General",
      targetScoring:"Targets & Scoring",
      inputs:"Input Parameters",
      maxFiveParam:"Maximum 5 parameters for a KPI can be added",
      validParam:"Please Enter Valid Parameter",
      validDesc:"Please Enter Valid Parameter Description",
      dataEntry:"Data Entry in KET",
      paramater:"Parameter",
      paramDesc:"Parameter Description",
      noParamAvai:"No Parameters Available",
      kpiDeleted:"KPI deleted Successfully",
      kpiDeleteError:"KPI Not found",
      kpiActualsError:"Actuals present. KPI cannot be deleted",
      cea_kpi_updated: "CEA KPI has been updated successfully",
      addParam:"Add Parameters"

    },
    dealerManagement: {
      invalidUuid: "Indtast venligst en gyldig A2D-vejledning.",
      invalidDealerName: "Indtast et gyldigt forhandlernavn.",
      invalidCity: "Indtast venligst en gyldig placering.",
      invaliDealerCode: "Indtast venligst en gyldig forhandlerkode.",
      existingUuid: "A2D uuid-koden findes allerede i KET-databasen.",
      updateUuid: "Opdater A2D uuid-kode",
      newUuid: "Ny A2D uuid-kode",
    },
    networkManagement: {
      networkManagement: "Netværksadministration",
    },
    kpiEntry: {
      cancel : "Cancel",
      save : "Save",
      no : "No",
      yes : "Yes",
      hide : "Hide",
      show : "Show",
      dataNotSaved : "Data is not saved. Entered data may be lost. Do you want to Proceed?",
      wantToProceed : "Do you want to proceed?",
      status: 'Status',
      evaluationYear: 'Evaluation Year',
      frequency: 'Frequency',
      typeOfTarget: 'Type of Target',
      target: 'Target',
      unit: 'Unit',
      standardMessage: 'No Kpi Actuals Data Found',
      srNo: 'S.No.',
      actuals: 'Actuals',
      subPillar: 'Sub Pillar',
      businessFunc: 'Business Function',
      brand: 'Brand',
      Jan : "Jan",
      Feb : "Feb",
      Mar : "Mar",
      Apr : "Apr",
      May : "May",
      Jun : "Jun",
      Jul : "Jul",
      Aug : "Aug",
      Sep : "Sep",
      Oct : "Oct",
      Nov : "Nov",
      Dec : "Dec",
      q1 : "Q1",
      q2 : "Q2",
      q3 : "Q3",
      q4 : "Q4",
      h1 : "H1",
      h2 : "H2",
      cy : "CY",
      kpi : "Key Performance Indicators",
      actuals1 : "Actuals should not be more than allotted points. Please re-enter",
      actionPlan	: "Action Plan",
      enterActualsData : "Please enter actuals data.",
      notNumber : "Please enter valid number.",
      serverErrorMsg : "An error occured, please try again later.",
      launchEvaluationError : "Please launch evaluations.",
      actualsDataSaved : "Actuals data uploaded successfully.",
      inputTxt:"Inputs"
    },
    ceaEvaluation :{
      nmsc : "NMSC",
      search : "Search",
      all : "All",
      status : "Status",
      businessFunction :"Business Function",
      noEvaluationsFound : "No Evalutions Found",
      evaluationYear : "Evalution Year",
      view : "View",
      evaluations : "Evaluations",
      launchTargetSetting: "Launch Target Setting",
      launchEvaluation : "Launch Evaluation",
    },
    britDashboard:{
      noVideo:"No videos available",
      playVideo:"Play Video",
      trainingVideos:"Training  Videos",
      download: "Download Video",
      back:"Back"
    },
    ceaActionPlan:{
      id: "ID",
      counterMeasure: "Countermeasure",
      questionReference: "KPI Reference",
      status: "Status",
      startDate: "Start Date",
      dueDate: "Due Date",
      assignedTo: "Assigned To",
      noActionAvailable: "No Actions available",
      add: "Add",
      download: "Download",
      edit: "Edit",
      delete: "Delete",
      deleteMsg: "Are you sure you want to delete ?",
      no: "No",
      yes: "Yes",
      addActionPlanNotAllowed: "The Action Plan cannot be created, or edited after the Activity has been Closed, Completed, or Rejected.",
      actionPlanItem: "Action Plan - Item #",
      addActionPlan: "Add Action Plan",
      open: "Open",
      actionPlanStartDateValidation:"End Date cannot be earlier than the Start Date",
      modalMandatory: "Please enter valid data in all the mandatory fields",
      problem: "Problem",
      description: "Description",
      followup: "Follow Up",
      history: "History",
      cancel: "Cancel",
      save: "Save",
      update: "Update",
      assignee: "Assignee",
      endDate: "End Date", 
      select: "Select",
      notApplicablePer:"Not Applicable for CEA, Networks and Used car"
    },
    reachItDashboard:{
      topNavReachIT:"ReachIT",
      downloads:"Downloads",
      historyFiles:"Historik/tidligere filer",
      reachText1:"Velkommen til REACH IT hjemmeside. TMEs miljøafdeling vil bruge denne side til at dele relevant information i forhold til SCIP: Bekymringsstoffer i artikler som sådan eller i komplekse genstande (produkter) etableret i henhold til affaldsrammedirektivet (WFD) for at du kan opfylde din pligt til at behandle med din forenklede SCIP-meddelelse.",
      reachText2:"I tilfælde af forespørgsler, kontakt venligst TME Environmental Management-teamet via nedenstående link",
      reachText3:"I tilfælde af forespørgsler bedes du kontakte Toyota Danmark",
      envMngt:"Miljøhåndtering",
      fileStatistics: "Fil Downloadede Statistik",
      Downloaded: "Downloadede",
      not: "Ikke",
      exportToExcel:"Eksporter til Excel"
    },
    usedCarComment:{
      ongoing:"Ongoing",
      completed:"Completed",
      commentHistory:"Monthly Comments History",
      addComment:"Add Monthly Comment",
      month:"Month",
      comment:"Comment",
      username:"Username",
      email:"Email",
      createdDate:"Created Date"
    },
  }
};

export default translationsDA;
