import React from "react";
import moment from 'moment';
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import {  Fa,MDBCol, MDBRow,MDBDropdown, MDBDropdownItem, MDBDropdownMenu, MDBDropdownToggle, } from 'mdbreact';
// import DatePicker from 'react-datepicker';
import { toastr } from 'react-redux-toastr';
import { fetchScrollableWidgetData } from '../../../actions/dashboardAction';
import LoadingOverlay from 'react-loading-overlay';
import '../index.css';
import { withTheme } from "../../../themes/theming";
import * as AppConst from '../../../AppConstant';
import Slider from "react-slick";
import Arrow from '../arrow';
var I18n = require("react-redux-i18n").I18n;

class ReCertificationRate extends React.Component {
    constructor(props) {
        super(props);
        const d = new Date();
        var currentYear = d.getFullYear().toString();
        this.state = {
            isLoading: false,
            data: {},
            totalValue: null,
            status: null,
            toyotaValue: null,
            lexusValue: null,
            fromDate: null,
            toDate: null,
            filters:[
                {value: "CY", label:(currentYear)},{value: "All", label: "ALL"}
            ],
            leftArrow:"widget_filter_arrow_disable",
            rightArrow:"widget_filter_arrow"
        };
        console.log(this.state.filters);
    }
   

    componentWillMount() {
        let activeProgram = this.props.user.userMdl.activeProgram;
        let nmscCode = this.props.user.userMdl.nmsc;
        let langCode = this.props.user.userMdl.lang;
        const d = new Date();
        let currentMonth = (d.getMonth()+1).toString().padStart(2, "0");
        let endDate = d.getFullYear() + "-" + currentMonth+ "-" + d.getDate();
        let startDate =  d.getFullYear() +  "-01-01";
        // Fetch reCertificationRate.
        let queryDto = {
            userNmscCode: nmscCode,
            userLangCode: langCode,
            programList: [activeProgram],
            nmscCode: this.props.nmscFlag ? [nmscCode.toUpperCase()] : this.props.nmscFilter,
            brand: this.props.brandFilter,
            country: this.props.countryFilter,
            region: this.props.regionFilter,
            // nmscFlag: true,
            userId: this.props.userMdl.username,
            businessArea: this.props.userMdl.businessAreaKey,
            fromDate: startDate,
            toDate: endDate,
        };
        this.setState({ isLoading: true });
        this.props.dispatch(fetchScrollableWidgetData("reCertificationRate", AppConst.recertificationRate, queryDto));
    }
    
    getQueryDTO = (startDate, endDate) => {
        let activeProgram = this.props.userMdl.activeProgram;
        let nmscCode = this.props.userMdl.nmsc;
        let langCode = this.props.userMdl.lang;
        // let userId = this.props.userMdl.username;
        if (startDate !== null && startDate instanceof Date) {
            let dt = moment(startDate);
            startDate = dt.format("YYYY-MM-DD");
        }
        if (endDate !== null && endDate instanceof Date) {
            let dt = moment(endDate);
            endDate = dt.format("YYYY-MM-DD");
        }
      
        return {
            userNmscCode: nmscCode,
            userLangCode: langCode,
            programList: [activeProgram],
            nmscCode: this.props.nmscFlag ? [nmscCode.toUpperCase()] : this.props.nmscFilter,
            brand: this.props.brandFilter,
            country: this.props.countryFilter,
            region: this.props.regionFilter,
            userId: this.props.userMdl.username,
            fromDate: startDate,
            toDate: endDate,
            businessArea: this.props.userMdl.businessAreaKey
        };
    }
    applyDateFilter = (status) => {
        console.log("status",status);
        let startDate = '';
        let endDate = '';
        if(status==="CY"){
            const d = new Date();
            startDate =  d.getFullYear() +  "-01-01";
            let currentMonth = (d.getMonth()+1).toString().padStart(2, "0");
            endDate = d.getFullYear() + "-" + currentMonth + "-" + d.getDate();
            this.setState({ isLoading: true });
            this.props.dispatch(fetchScrollableWidgetData("reCertificationRate", AppConst.recertificationRate, this.getQueryDTO(startDate, endDate)));
        }
        else if(status==="All"){
            console.log("status",status);
            startDate = null;
            endDate = null;
            this.setState({ isLoading: true });
            this.props.dispatch(fetchScrollableWidgetData("reCertificationRate", AppConst.recertificationRate, this.getQueryDTO(startDate, endDate)));
        }
       else{
        if (this.state.fromDate !== null) {
            let dt = moment(this.state.fromDate);
            startDate = dt.format("YYYY-MM-DD");
        }
        if (this.state.toDate !== null) {
            let dt = moment(this.state.toDate);
            endDate = dt.format("YYYY-MM-DD");
        }
        // Reload, if both dates are empty or both are not empty.
        if ((startDate !== '' && endDate !== '') || (startDate === '' && endDate === '')) {
            // End date should be greater than Start Date.
            if (startDate !== '' && endDate !== '' && this.state.fromDate>= this.state.toDate) {
                toastr.error("End date should be greater than Start date.")
            } else {
                this.setState({ isLoading: true });
                this.props.dispatch(fetchScrollableWidgetData("reCertificationRate", AppConst.recertificationRate, this.getQueryDTO(this.state.fromDate, this.state.toDate)));
            }
        }
    }
    }
    componentWillReceiveProps(nextProps) {
      
        if (nextProps.reCertificationRate) {
            this.setState({
                totalValue: nextProps.reCertificationRate.value,
                toyotaValue: nextProps.reCertificationRate.toyotaValue,
                lexusValue: nextProps.reCertificationRate.lexusValue, isLoading: false
            });
          

        }
       
    }
    render() {
        var totalValue = null;
        var toyotaValue = null;
        var lexusValue = null;
        if (this.state.totalValue != null) {
            totalValue = this.state.totalValue + "%"
        }
        if (this.state.toyotaValue != null) {
            toyotaValue = this.state.toyotaValue + "%"
        }
        if (this.state.lexusValue != null) {
            lexusValue = this.state.lexusValue + "%"
        }
        let filters = [...this.state.filters]; 
            // filters= filters.slice(1)
        const settings = {
             dots: false,
             infinite: false,
             speed: 500,
             slidesToShow: 1,
             slidesToScroll: 1,
            beforeChange: (current, next) => {
                let filter = this.state.filters[next];
                if(filter){
                    this.applyDateFilter(filter.value);
                }
                if(next===0){
                    this.setState({leftArrow:"widget_filter_arrow_disable",rightArrow:"widget_filter_arrow"})
                }
                else if(next===(this.state.filters.length-1)){
                    this.setState({rightArrow:"widget_filter_arrow_disable",leftArrow:"widget_filter_arrow"})
                }else{
                    this.setState({rightArrow:"widget_filter_arrow",leftArrow:"widget_filter_arrow"})
 
                }
            },
        
            prevArrow: <Arrow icon="chevron-left" parentClass="previousBtnClass" BtnClass={this.state.leftArrow}/>,
            nextArrow: <Arrow icon="chevron-right" parentClass="nextBtnClass" BtnClass={this.state.rightArrow}/>
        };

        return (


            <div>
                {/* <div className={"widget-header widget-header cert-" + this.props.theme.name}  title={I18n.t('dashboard.reCertificationRate')} style={{overflow: 'visible !important'}}>{I18n.t('dashboard.reCertificationRate')} */}

                <div className={"certificate-" + this.props.theme.name} title={I18n.t('dashboard.reCertificationRate')} >{I18n.t('dashboard.reCertificationRate')} 
                
                <MDBDropdown className="widget-filter-icon-container">
                        <MDBDropdownToggle tag="div" style={{ display: 'inline' }}>
                            <Fa icon="filter" className="widget-filter-icon" />
                        </MDBDropdownToggle>
                        <MDBDropdownMenu tag="div" className="widget-filter-dd-menu widgetTransform" style={{left: "-28px !important",minWidth: "15rem !important"}}>
                          
                            <MDBDropdownItem toggle={false} tag="div">
                                <MDBRow>
                                    <MDBCol md="3" lg="4" className="widget-filter-dd-header">{I18n.t('Table.startDate') + ":"}</MDBCol>
                                    <MDBCol md="3" lg="8" className="pl-2">
                                    <input type="date" id="fromDate" name="fromDate" className="" value={this.state.fromDate}
                                             onChange={(e) => this.setState({ fromDate: e.target.value })}/>

                                    </MDBCol>
                                </MDBRow>
                                <div className="marginTopDashboad"></div>
                                <MDBRow>
                                    <MDBCol md="3" lg="4" className="widget-filter-dd-header">{I18n.t('Table.endDate') + ":"}</MDBCol>
                                    <MDBCol md="3" lg="8" className="pl-2">
                                    <input type="date" id="toDate" name="toDate" class="" value={this.state.toDate}
                                             onChange={(e) => this.setState({ toDate: e.target.value })}/>

                                    </MDBCol>
                                </MDBRow>
                            </MDBDropdownItem>
                            <MDBDropdownItem toggle={true} tag="div">
                                <MDBRow style={{paddingLeft:"111px"}}>
                                    <MDBCol>
                                        <div>
                                            <button className={"filter-button filter-button-" + this.props.theme.name}
                                                onClick={() => this.applyDateFilter()}>
                                                {I18n.t('dashboard.go')}
                                            </button>
                                        </div>
                                    </MDBCol>
                                </MDBRow>
                            </MDBDropdownItem>
                        </MDBDropdownMenu>
                    </MDBDropdown>
                
                
                </div>

                <LoadingOverlay active={this.state.isLoading} spinner text='Loading...'>
                    <div className="scrollable-widget-content">
                        <MDBRow style={{ minHeight: "3rem" }}>
                            <MDBCol lg="12" md="12">
                                <div className="widget_count_class">
                                    {totalValue}
                                </div>
                            </MDBCol>
                        </MDBRow>
                        <MDBRow className="row widget_brand_count_section">
                            <MDBCol lg="6" md="6" style={{display: "flex"}}>
                                <img src="images/TMEToyotaEllipse.png" alt="logo" height="21px" className="theme_logo" />
                               <span  className="widget_brand_count" style={{ paddingLeft: "7px"}}> {toyotaValue} </span>
                            </MDBCol>
                            <MDBCol lg="6" md="6" style={{display: "flex"}}>
                                <img src="images/Lexus2D.jpg" alt="logo" height="20px" className="theme_logo" />
                                <span className="widget_brand_count" style={{ paddingLeft: "7px"}}> {lexusValue} </span>
                            </MDBCol>
                        </MDBRow>
                        <MDBRow style={{minHeight: "1.7rem"}}>
                        <MDBCol  lg="12" md="12" className="widget_slider_class">

                        <Slider {...settings}>
                            {filters.map((t, i) => {
                               return (<div key={i}>
                                    <span className="widget_filter" style={{fontWeight: "500"}} title={I18n.t(t.label)}>{I18n.t(t.label)}</span>
                                </div>);
                            })}
                        </Slider>
                        </MDBCol>
                        </MDBRow>
                    </div>
                </LoadingOverlay>
            </div>
        );
    }
}
const mapStateToProps = state => {
    return {
        user: state.loginReducer,
        // blocking: state.loginReducer,
        router: state.routing,
        reCertificationRate: state.dashboardReducer.reCertificationRate,

    };
};
export default withRouter(connect(mapStateToProps)(withTheme(ReCertificationRate)));