import React from "react";
import { connect } from "react-redux";
import { withTheme } from "../../../themes/theming";
import Select from "react-select";
import { fetchActivityFilters, fetchDealer, fetchActivityStatus,fetchToolBoxForTme } from '../../../actions/activityAction';
import { fetchNmscToolboxByType } from "../../../actions/leftNavAction.jsx";
import { downloadCreateActivityTemplate } from "../../../actions/bulkActivitiesAction.jsx";
import { downloadQuestionnaireTemplate } from "../../../actions/bulkUploadAction.jsx";
import { withRouter } from "react-router-dom";
import { toastr } from 'react-redux-toastr';
import $ from "jquery";
import "./../index.css";
import * as AppConst from '../../../AppConstant';
import DownloadPanel from "./downloadPanel";
var I18n = require("react-redux-i18n").I18n;

class SearchFilterQuestionnaire extends React.Component{
    /* eslint-disable */
    constructor(props){
        super(props);
        this.state ={
            showTableFlag: false,
            //showSearchFlag: false
            suggestions: [],
            valueDealer:'',
            nmscList: [],
            dealerList: [],
            activityStatus: [],
            toolboxList: [],
            countryList: [],
            selectedNmsc: [],
            selectedToolbox: [],
            selectedStatus: '',
            selectedCountry: [],
            selectedDealer: [],
            activityToolBox: [],
            nmscFlag: false,
            downloadDto: {
                role: this.props.user.userMdl.roles,
                roleFlag: false,
                nmscCode: this.props.user.userMdl.nmsc,
                loggedInUser: this.props.user.userMdl.username,
                toolboxId: null,
                toolboxName:null,
                userId: this.props.user.userMdl.username,
                status: null,
                score: null,
                activityId: null,
                loginId: null,
                dealerId: null,
                type: 'TME',
                langCode: this.props.user.userMdl.lang,
                rows: 10,
                page: 1,
                programID:this.props.user.userMdl.activeProgramID,
                program: this.props.user.userMdl.activeProgram,
                isValueChain: this.props.user.userMdl.flagValueChain,
                isBRiT: this.props.user.userMdl.flagBRiT,
                isCEA: this.props.user.userMdl.flagCEA,
                isEA: this.props.user.userMdl.flagEA,                
                businessArea: this.props.user.userMdl.businessAreaKey ==="EA" ? "VALUE_CHAIN,BRiT" : this.props.user.userMdl.businessAreaKey
              },
            searchDto: {
                nmscCode: null,
                userId: this.props.user.userMdl.username,
                country: null,
                dealer: null,
                toolbox: null,
            },
            defaultAllObj:[{ "value": "All", "label": I18n.t('standardMessages.selectAll') },{ "value": "None", "label": I18n.t('standardMessages.unSelectAll') }], 
        };
    }

    componentWillMount(){
        var isNMSCAdminUser = false;
        var isTMEAdminUser = false;
        var isNotAdmin = false;
        if (this.props.user.userMdl.roles !== undefined) {
          var roles = this.props.user.userMdl.roles;
          for (var i = 0; i < roles.length; i++) {
            if (roles[i].toUpperCase().indexOf('KET NMSC ADMIN') !== -1 || roles[i].toUpperCase().indexOf('KET-BRIT NMSC Admin') !== -1 || roles[i].toUpperCase().indexOf('KET-EA NMSC ADMIN') !== -1) {
                isNMSCAdminUser = true;
                break;
              }
              else if (roles[i].toUpperCase() === "TSM KET USER" || roles[i].toUpperCase() === "KET TME USER" || roles[i].toUpperCase() === "KET-BRIT TME ADMIN" || roles[i].toUpperCase() === "KET-BRIT TME USER" || roles[i].toUpperCase() === "KET TME ADMIN" || roles[i].toUpperCase() === "KET-EA ADMIN" || roles[i] === "KET-TSP Auditor" || this.props.user.userMdl.businessAreaKey==="TSP") {
                isTMEAdminUser = true;
                break;
            }
            isNotAdmin = true;
    
          }
        }
        if (this.state.downloadDto !== undefined) {
            let selectedNmscList = this.getSelectedList(this.state.downloadDto.nmscCodeList);
            let selectedDealerList = this.getSelectedList(this.state.downloadDto.dealerList);
            let selectedCountryList = this.getSelectedList(this.state.downloadDto.countryList);
            let selectedToolboxList = this.getSelectedList(this.state.downloadDto.toolboxList);
            let selectedStatus = this.state.downloadDto.status;
            this.setState({
                downloadDto: this.state.downloadDto,
                selectedNmsc: selectedNmscList,
                selectedDealer: selectedDealerList,
                selectedCountry: selectedCountryList,
                selectedToolbox: selectedToolboxList,
                selectedStatus: selectedStatus,
                valueDealer: this.state.downloadDto.dealer !== undefined ? this.state.downloadDto.dealer : '',
            });
            this.props.dispatch(fetchActivityFilters(this.state.downloadDto, "nmscCode"));
            this.props.dispatch(fetchActivityFilters(this.state.downloadDto, "l2"));
            this.props.dispatch(fetchDealer(this.state.downloadDto));
            this.props.dispatch(fetchActivityStatus());
            if(!isTMEAdminUser){
                this.props.dispatch(fetchNmscToolboxByType(this.props.user.userMdl.activeProgramID,this.props.user.userMdl.nmsc,this.props.user.userMdl.lang));
            }
            else{
            this.props.dispatch(fetchToolBoxForTme(this.props.user.userMdl.activeProgramID));
            }
        }
    }

/**
     * Method to create selected option list for multiselect element.
     */
    getSelectedList(selectedList) {
        let filteredList = [];
        let obj;
        var Number = /^[\d\/\(\)\- \.]+$/;
        if (selectedList !== undefined) {
            selectedList.map(value => {
                if (Number.test(value)){
                     obj = { 
                        label: value,            
                        value: value,
                        selected: 'selected'
                    };
                }
                else{
                 obj = {             
                    label: I18n.t("status." + value.toLowerCase()),
                    value: value,
                    selected: 'selected'
                };
            }
                filteredList.push(obj);
            });
        }
        return filteredList;
    } 
    componentWillReceiveProps(nextProps) {
        if (nextProps.flag) {
            $("#searchId").show();
            $("#hrId").hide();
        } else {
            $("#searchId").hide();
            $("#hrId").show();
        }

        let roles = [];
        roles = nextProps.user.userMdl.roles;
        //If user role is NMSC or DEALER then set flag as true
        // This will set the search activity toolbox drop down for nmsc user
        if (nextProps.user.userMdl.roles) {
            for (var i = 0; i < roles.length; i++) {
                if (roles[i].toUpperCase().indexOf("NMSC") !== -1 || roles[i].toUpperCase().indexOf("DEALER") !== -1) {
                    this.setState({ nmscFlag: true });
                    const downloadDto = { ...this.state.downloadDto };
                    downloadDto["roleFlag"] = true;
                    this.setState({ downloadDto });        
                    break;
                }
                else{
                    const downloadDto = { ...this.state.downloadDto };
                    downloadDto["roleFlag"] = false;
                    this.setState({ downloadDto });        
                }
            }
                 
        }
        if (nextProps.dealerList.nmscList) {
            if(this.state.nmscFlag){
                this.setState({ nmscList: nextProps.dealerList.nmscList });
            }else{
                this.setState({ nmscList:  this.state.defaultAllObj.concat(nextProps.dealerList.nmscList) });
            }
            var nmscValue = [];
            nextProps.dealerList.nmscList.map(function (item) {
                nmscValue.push(item.value);
            })
            const downloadDto = { ...this.state.downloadDto };
            downloadDto["nmscCodeList"] = nmscValue;
            this.setState({ downloadDto }); 
        }

        if (nextProps.dealerList.countryList) {
            this.setState({ countryList: this.state.defaultAllObj.concat( nextProps.dealerList.countryList) });
            var countryValue = [];
            nextProps.dealerList.countryList.map(function (item) {
                countryValue.push(item.value);
            })
            const downloadDto = { ...this.state.downloadDto };
            downloadDto["countryList"] = countryValue;
            this.setState({ downloadDto });        
        }

        if (nextProps.dealerList.dealerList) {
            this.setState({ dealerList: nextProps.dealerList.dealerList });
            var dealerValue = [];
            nextProps.dealerList.dealerList.map(function (item) {
                dealerValue.push(item.value);
            })
            const downloadDto = { ...this.state.downloadDto };
            downloadDto["dealerList"] = dealerValue;
            this.setState({ downloadDto });          
        }

        if (nextProps.activityStatus.activityStatus) {           
            const options = []
            nextProps.activityStatus.activityStatus.map((d) => {                
                if(d.value !== AppConst.ACTIVITY_STATUS_REJECTED){
                    options.push({ value: d.value, label: I18n.t(d.label) })          
                }                                   
            });
            this.setState({ activityStatus: options });
        }

        if(!this.state.nmscFlag){
        if (nextProps.activityToolBox.activityToolBox) {
            const options =  nextProps.activityToolBox.activityToolBox.map((d) => ({
                value: d.value,
                label: d.label
            }));
            // Set Values for selectedToolbox.
            if (this.state.selectedToolbox.length > 0) {
                let modifiedList = [];
                for (var i = 0; i < this.state.selectedToolbox.length; i++) {
                    let value = this.state.selectedToolbox[i].value;
                    // let label = this.state.selectedToolbox[i].label;
                    let obj = options.filter(option => option.value === value);
                    let selectedObj = {
                        label: obj[0].label,
                        value: obj[0].value,
                        selected: 'selected'
                    };
                    modifiedList.push(selectedObj);
                }
                this.setState({ selectedToolbox: modifiedList });
            }
            this.setState({ activityToolBox: options });
            // var toolValue = [];
            //     nextProps.activityToolBox.activityToolBox.map(function (item) {
            //         toolValue.push(item.value);
            // })
            // const downloadDto = { ...this.state.downloadDto };
            // downloadDto["toolboxList"] = toolValue;
            // this.setState({ downloadDto });  
        } 
    }

        else{
        if (nextProps.toolboxByType.toolboxByType) {
            const options =[]
            options.push({ label: I18n.t('standardMessages.selectAll'),value:"All"},{"value": "None", "label": I18n.t('standardMessages.unSelectAll') })
            nextProps.toolboxByType.toolboxByType.map((d) => {
                options.push({
                    value: d.packageMapPrimaryKey,
                    label: d.toolboxName
                })
            });
            // Set Values for selectedToolbox.
            if (this.state.selectedToolbox.length > 0) {
                let modifiedList = [];
                for (var i = 0; i < this.state.selectedToolbox.length; i++) {
                    let value = this.state.selectedToolbox[i].value;
                    // let label = this.state.selectedToolbox[i].label;
                    let obj = options.filter(option => option.value === value);
                    let selectedObj = {
                        label: obj[0].label,
                        value: obj[0].value,
                        selected: 'selected'
                    };
                    modifiedList.push(selectedObj);
                }
                this.setState({ selectedToolbox: modifiedList });
            }
            console.log(options);
            this.setState({ activityToolBox: options });
       
        }
    }
    }

    getSuggestions = value => {  
        const inputValue = value.trim().toLowerCase();
        const inputLength = inputValue.length;
        return inputLength > 4 && this.state.dealerList !== undefined ? (this.state.dealerList.filter(lang =>
            lang.label.toLowerCase().slice(0, inputLength) === inputValue)
        ) : [];
    };
    onSuggestionsFetchRequested = ({ value }) => {
        this.setState({
            suggestions: this.getSuggestions(value)
        });
    };
    onSuggestionsClearRequested = () => {
        this.setState({
            suggestions: []
        });
    };
    getSuggestionValue = suggestion => suggestion.label + "_" + suggestion.value;

    renderSuggestion = suggestion => (
        <div>
            {suggestion.label}
        </div>
    );

    onToolBoxChange = toolboxOption => {
        const downloadDto = { ...this.state.downloadDto };
        downloadDto["toolboxId"] = toolboxOption !== null ? toolboxOption.value : '';
        downloadDto["toolboxName"] = toolboxOption !== null ? toolboxOption.label : '';
        this.setState({ selectedToolbox: toolboxOption !== null ? toolboxOption : '', downloadDto})
      };


    reloadDropDowns = (downloadDto) => {
        this.props.dispatch(fetchActivityFilters(downloadDto, "nmscCode"));
        this.props.dispatch(fetchActivityFilters(downloadDto, "l2"));
        this.props.dispatch(fetchDealer(downloadDto));
    }

    onClickUpload = (e) => {
                if(this.state.downloadDto.toolboxId=== undefined || this.state.downloadDto.toolboxId===null){
                   toastr.error("Toolbox should not be empty")
                }else{
                this.state.downloadDto.isValueChain = this.props.user.userMdl.flagValueChain;
                this.state.downloadDto.isBRiT= this.props.user.userMdl.flagBRiT;
                this.state.downloadDto.isCEA= this.props.user.userMdl.flagCEA;
                this.state.downloadDto.isEA= this.props.user.userMdl.flagEA;
                const downloadDto = { ...this.state.downloadDto };
                downloadDto["isEA"] = this.props.user.userMdl.flagEA;
                this.setState({ downloadDto });
                {this.props.createActivityFlag?
                this.props.dispatch(downloadCreateActivityTemplate(this.state.downloadDto))
                :
                this.props.dispatch(downloadQuestionnaireTemplate(this.state.downloadDto))
                }
                this.setState({showTableFlag:true});
            }
    }
    render(){
        return (
            <div className="col-md-12" style={{ padding: "0px" }}>
            <div id="searchId" style={{ display: "none", borderColor: this.props.theme.primaryColor }} className="col-md-12 searchActivity">
            <div>
                <div className="col-md-12 row control-label">
                              
                    <label className="col-md-2 control-label" style={{ paddingLeft: '1.1rem', fontWeight: '400' }}>{I18n.t('Toolbox')}</label>
               
                    
                </div>
                <div className="col-md-12 input-group control-label">
                            {this.props.user.userMdl.activeProgram === "BRiT" ?
                                <div className={"col-md-2 noPadding bigDD-" + this.props.theme.name}>
                                </div>
                            : ''}
                       
                            <div style = {{margin : '9px'}}/>

                            <div className={"col-md-2 noPadding bigDD-" + this.props.theme.name}>
                                <Select
                                    value={this.state.selectedToolbox}
                                    options={this.state.activityToolBox}
                                    onChange={this.onToolBoxChange}
                                    placeholderButtonLabel={I18n.t('standardMessages.all')}
                                />
                            </div>
                          
                            <div className="col-md-1">
                                <button className={"button-" + this.props.theme.name}
                                    onClick={() => this.onClickUpload() }>
                                    {I18n.t('tmeAdminMaster.generate')}
                        </button>
                            </div>
                            <DownloadPanel data={this.props.bulkUpload.uploadQuestionnaireFileList} downloadDto={this.state.downloadDto} createQuestionnaireFlag={this.props.createQuestionnaireFlag} />
                        </div>
                    </div>
                    </div>
                    

    <div id="hrId" className="col-md-12 hrClass">
        <hr style={{ margin: '0px', borderTop: "1px solid" }} />
    </div>
</div>

);

  }
}
const mapStateToProps = state => {
    return {
        user: state.loginReducer,
        blocking: state.loginReducer,
        router: state.routing,
        dealerList: state.activityReducer,
        activityStatus: state.activityReducer,
        activityToolBox: state.activityReducer,
        toolboxByType: state.leftNavReducer,
        bulkUpload: state.bulkUploadReducer

    };
  };
export default withRouter(connect(mapStateToProps)(withTheme(SearchFilterQuestionnaire)));
