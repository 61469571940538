const translationsNO = {
  no: {
    Policy: {
      HeaderMsg: "Personvermerknad for behandling av dine personlige data i Kaizen Expert Tool. ",
      Message1: "Denne personvernmerknaden beskriver behandlingen av dine persondata i Kaizen Expert Tool. Den må alltid leses sammen med Toyota sin generelle personvernerklæring som du må akseptere for å kunne gå videre. ",
      Message2: "Toyota Motor Europe NV/SA, Avenue du Bourget/Bourgetlaan 60, 1140 Brussels, Belgium er databehandler og vil samle inn og behandle  dine persondata (som angitt under) for de formål som er beskrevet under.",
      Message3: "Hvilke av dine personopplysninger vil bli samlet inn?",
      Message4: "Vi samler inn følgende kategori/ dine personlige data i forhold til Kaizen Expert Tool:",
      Table1: {
        tr1: "Kategori/personopplysninger:",
        tr2: "Bruker Id",
        tr3: "Brukernavn (Fullt navn)",
        tr4: "Merke (Toyota, Lexus) ",
        tr5: "Foretrukket språk",
        tr6: "Tilknyttet organisasjon"
      },

      Message5: "Formål og rettslig grunnlag for behandlingen av dine personopplysninger.",
      Message6: "Vi behandler dine personlige data for de formål og på de juridiske grunnlagene som er beskrevet nedenfor:",
      Table2: {
        tr1: {
          th1: "Formål:",
          th2: "Lovlig basis:"
        },
        tr2: {
          td1: "KET – 1: Forhandler Kaizen prestasjon",
          td2: "legitim interesse for databehandleren(e)"
        },
        tr3: {
          td1: "KET – 2: IT Systemstøtte",
          td2: "legitim interesse for databehandleren(e)"
        }
      },
      Message7: "Varighet på lagring av dine personlige data",
      Message8: "Personopplysningene dine vil bli lagret i tidsrommet som angitt nedenfor:",
      Table3: {
        tr1: {
          th1: "Lagringsperiode:",
          th2: "Kategori/personopplysninger:"
        },
        tr2: {
          td1: "Eventuelle personlige opplysninger som beskrevet ovenfor vil bli beholdt i maksimalt 5 år + 1 Måned fra dagen for den endelige avslutningen av Kaizen Expert Tool.",
          td2: "Fornavn, etternavn, Språkpreferanse,organisasjon"
        }
      },
      Message9: "Valgfritt: Ved offentliggjøring/overføring av personopplysninger til land utenfor EØS-området (EØS)",
      Message10: "Dine personopplysninger vil bli gjort tilgjengelig for mottaker (e) i land utenfor EØS som angitt nedenfor:",
      Table4: {
        tr1: {
          td1: "Identitet",
          td2: "Land",
          td3: ""
        },
        tr2: {
          td1: "Ikke-EØS-mottaker(e)",
          td2: "Infosys",
          td3: "India"
        }
      },
      Message11: "Jeg bekrefter herved å ha forstått denne kunngjøringen; Jeg har lest og akseptert Toyota Personvern og Personvernreglene."
    },
    ACCESSORIES: "Tilbehør",
    activity: {
      Assignee: "Oppdragsansvarlig",
      CompletionDate: "Gjennomført dato",
      Dealer: "Forhandler",
      FirstVisit: "Første besøk",
      kpi: {
        kpi: "KPI skal være tall (+ ve eller -ve)",
        kpiLength: "Maksimumlengden for KPI er 10 tegn",
        mandatory: "Angi gyldige opplysninger i alle de påkrevde feltene",
        mandatoryTargets: "Vennligst skriv inn alle KPI'er",
        target: "Målet skal bare være tall",
        targetLength: "Maksimal lengde for målet er 10 tegn"
      },
      ReminderOn: "Påminnelse på",
      statusDD: "Status",
      Toolbox: "Verktøykasse",
      activityCreation: "Prosessen med å opprette aktivitet er startet",
      activityCreated: "Aktivitet(er) er opprettet"
    },
    DPOK: "DPOK",
    EFFICIENCY: "Effektivitet",
    FIX_IT_RIGHT: "Fix It Right",
    footer: {
      message: "Obligatoriske felter"
    },
    FOUNDATION: "Grunnlag",
    MAINTENANCE_REMINDERS: "Vedlikeholdspåminnelse",
    EMPLOYEE_ENGAGEMENT: "Employee Engagement",
    CUSTOMER_CENTRICITY: "Customer Centricity",
    TOYOTA_WAY: "Toyota Way",
    BUSINESS_SUSTAINABILITY: "Business Sustainability",
    FUNDAMENTALS: "Grunnleggende",
    FIX_IT_RIGHT_BnP: "Fix it Right (B&P)",
    UTILIZATION_AND_EFFICIENCY: "Utilization and Efficiency",
    CUSTOMER_EXPERIENCE: "Customer Experience",
    UPSELLING_OPPORTUNITIES: "Upselling Opportunities",
    MANAGING_CAPACITY: "Managing Capacity",
    VEHICLE_TRADE_IN: "Vehicle Trade-In",
    STOCK_MANAGEMENT: "Stock Management",
    REFURBISHMENT: "Refurbishment",
    months: {
      apr: "Apr.",
      aug: "Aug.",
      dec: "Des.",
      feb: "Feb.",
      jan: "Jan.",
      jul: "Juli",
      jun: "Juni",
      mar: "Mar.",
      may: "Mai",
      nov: "Nov.",
      oct: "Okt.",
      sep: "Sep."
    },
    others: {
      back: "Tilbake",
      closed: "Lukket",
      completed: "Fullført ",
      countryLanguageCode: "Lands-/språkkode ",
      createActivity: "Opprett aktivitet",
      createdDate: "Opprettelsesdato",
      dataUneditableNote:
        "Merk: Data som er lagret kan ikke redigeres. Hvis du vil modifisere data, kan du kontakte NMSC-administratoren.",
      date: "Dato",
      downloadQuestionnaire: "Last ned spørreskjema",
      editActivity: "Rediger aktivitet",
      initialHelpText: "Den opprinnelige KPI-målingen skal være gjennomsnittsverdien av de seks forrige hele måneder",
      inProgress: "Pågår",
      name: "Navn",
      next: "Neste",
      noActivities: "Fant ingen aktiviteter",
      noResults: "Ingen resultater",
      of: "av",
      ongoing: "Pågående",
      open: "Åpne",
      page: "Side",
      planned: "Planlagt",
      problemDescription: "Problembeskrivelse",
      signature: "Underskrift",
      select: "Velg",
      bulkAction: "Bulk Action",
      bulkActions: "Bulk Actions"
    },
    ServerMessage: {
      error_message: "Det oppstod en feil under nedlasting",
      no_published_subquestion: "Publiser spørreskjemaet for denne verktøykassen for å starte denne aktiviteten",
      activity_upload_error_msg: "Feil under opplasting av handlingsdetaljer.",
      action_plan_created: "Handlingsplanen ble opprettet",
      action_plan_deleted: "Handlingsplanen er slettet ",
      action_plan_saved: "Handlingsplanen er lagret",
      activity_kpi_saved: "KPI aktivitet lagret ",
      master_kpi_confirm_delete:
        "Er du sikker på at du ønsker å slette valgt KPI?",
      master_kpi_created: "Master-KPI opprettet",
      master_kpi_deleted: "Master-KPI er slettet",
      master_kpi_not_found: "Fant ikke master-KPI",
      master_kpi_published: "Valgte master-KPI-er er publisert",
      master_kpi_retired: "Master-KPI er avviklet",
      master_kpi_saved: "Master-KPI lagret",
      subquestion_created: "Underspørsmål er lagret.",
      subquestion_updated: "Underspørsmål er oppdatert.",
      subquestionnaire_not_found: "Finner ikke underspørsmålene ",
      subquestion_retired: "Sub Question has been retired",
      subquestion_deleted: "Underspørsmål er slettet",
      subquestion_published: "Underspørsmål er publisert",
      mainquestion_created: "Hovedskjemaet er opprettet.",
      mainquestion_deleted: "Hovedskjemaet er slettet.",
      mainquestion_updated: "Hovedskjemaet er oppdatert",
      duplicateMsg: "Dupliser poster i Excel-filen.",
      deleteErr: "Feil under sletting av filen",
      toolbox_deleted: "Verktøykassen er slettet",
      toolbox_already_exists: "Verktøykasssen eksisterer allerde",
      toolbox_saved: "Verktøykasse lagret",
      toolbox_created: "Verktøykasse opprettet",
      toolbox_not_found: "Verktøykasse ikke funnet",
      published_content_found: "Denne verktøykassen kan ikke slettes, fordi den har publisert innhold",
      dealers_already_mapped: " De valgte forhandlerne er allerede kartlagt for denne brukeren. ",
      dealer_already_mapped: " Dealer already mapped ",
      dealer_added: "  Forhandler lagt til. ",
      dealers_added: "  Forhandler lagt til. ",
      total_dealers_mapped_now: "Totalt antall forhandlere som for øyeblikket er kartlagt for denne brukeren er ",
      no_dealer_available: "Ingen forhandler (e) tilgjengelig for de valgte kriteriene.",
      proper_dealer_selected: "Velg en passende forhandler.",
      user_referenced_ongoing_activity: "Brukeren er for tiden tilordnet pågående aktiviteter. Vennligst bytt mottaker i disse aktivitetene og prøv igjen. ",
      want_to_remove_mapping: "Er du sikker på at du vil fjerne brukerens kartlegging for disse forhandlerne?",
      user_mapping_removed: "De valgte forhandlerne er fjernet fra brukerens kartlegging.",
      dealer_closed_in_A2D: "Denne forhandleren er registrert som 'Stengt' i A2D.",
      network_level_not_found: "Velg en gyldig kombinasjon av nettverksnivåer i henhold til KET-nettverksoppsettet.",
      duplicate_dealer_uuid: "Forhandler er allerede i KET-databasen.",
      dealer_created: "Forhandler opprettet",
      dealer_updated: "Denne forhandlerens attributter er oppdatert.",
      dealer_not_present: "Denne forhandleren er ikke registrert i A2D.",
      dealer_uuid_other_nmsc: "Den valgte A2D uuid-koden er ikke tilgjengelig for ditt nettverk.",
      target_kpi_updated: "The KPI's status have been updated successfully.",
      target_updated: "The KPI's target have been updated successfully.",
      kpi_launched: "The KPI's bulk launch successfully.",
      targetUpdate:"Application doesn't allow target to be modified for accepted status.",
    },
    standardMessages: {
      delete: "Er du sikker på at du vil slette?",
      addActionPlanNotAllowed: "Handlingsplanen kan ikke opprettes, eller redigeres etter at aktiviteten er stengt, fullført eller avvist.",
      addToolboxNotAllowed: "Cannot create more than 19 toolbox",
      britRejectMsg: "Er du sikker på at du vil avvise denne aktiviteten? Endringer er ikke mulig etter avvisning.",
      rejectMsg: "Er du sikker på at du vil avvise denne aktiviteten? Endringer er ikke mulig etter avvisning.",
      selectNMSCTab: "Vennligst velg Norsk språk forå publisere",
      all: "alle",
      deleteConfirm: "Er du sikker på at du vil slette den valgte KPI-en?",
      no: "Nei",
      retireConfirm: "Er du sikker på at du vil fjerne denne KPI-en? Når den er fjernet kan denne KPI-en ikke aktiveres på nytt",
      warning: "Advarsel",
      yes: "Ja",
      saveDataConfirmation: "Er du sikker på at du vil lagre dataene?",
      saveKPIWarning: "Note:Data cannot be edited once saved. To modify the data please contact the NMSC KET admin",
      addKpiNotAllowed: "Registrering av den neste  KPI måned kan legges til når den nåværende måneden slutter.",
      activityLaunch: "Er du sikker på at du vil starte denne aktiviteten? Ved start av aktiviteten kan den ikke reverseres eller slettes.",
      deleteConfirmSubQuestionNaire: "Er du sikker på at du vil slette valgt tilleggsørsmål",
      retireConfirmSubQuestionNaire: "Are you sure you want to retire this selected Sub Question?",
      deleteConfirmMainQuestion: "Vennligst bekreft om du vil slette hovedspørsmålet og tilhørende underspørsmål.",
      deleteConfirmChapter: "Er du sikker på at du vil slette kapittelet?",
      deleteLibraryFolder: "Er du sikker på at du vil slette denne mappen?",
      deletePublishedFolder: "Noen filer i denne mappen kan være knyttet til spørreskjemaet. Er du sikker på at du vil slette denne undermappen permanent og fjerne linken / linkene fra spørreskjemaet?",
      importQuestion: "Er du sikker på at du vil importere dette underspørsmålet? Nåværende delspørsmål vil bli erstattet.",
      noQuestion: "Ingen spørsmål tilgjengelig.",
      MandatoryWarning: "Vennligst fyll ut alle obligatoriske feltene for alle lokale språk.",
      certificationSubmission:
        "Verktøykassen er sendt TME for forhandler sertifisering ",
      sameToolBox: "Du kan ikke sende inn den samme verktøykassen mer enn én gang. Velg en annen verktøykasse",
      deleteConfirmDealer: "Are you sure you want to delete this dealer ?",
      updateVcConfirmDealer: "All Activities created for this dealer in 'Value Chain' will be removed. Are you sure you want to continue?",
      updateBritConfirmDealer: "All Activities created for this dealer in 'BRiT' will be removed. Are you sure you want to continue?",
      completed_activity_delete:"Data cannot be retrieved once deleted. Are you sure you want to delete ?",
      deleteAllQuestionary: "Are you sure you want to delete the entire Questionnaire?",
      deleteAllLibraries: "Are you sure you want to delete all folders and files in the Library?",
      selectAll: "Velg alle",
      unSelectAll: "Avmarker alle",
      select:"Velg",
      warningMsg:"Det er åpen handlingsplan(er) i denne aktiviteten. Vil du fullføre aktiviteten?"
    },
    status: {
      published: "Publisert",
      retired: "Avviklet",
      saved: "Lagret",
      rejected: "avvist",
      open: "Åpne",
      closed: "Lukket",
      close: "Lukket",
      ongoing: "Pågående",
      completed: "Fullført",
      complete: "Fullført",
      planned: "Planlagt",
      overdue: "Forfalt",
      due: "Forventet",
      pending: "Pending",
    },
    Rejected: "avvist",
    Open: "Åpne",
    Closed: "Lukket",
    Close: "Lukket",
    Ongoing: "Pågående",
    Completed: "Fullført",
    Complete: "Fullført",
    Planned: "Planlagt",
    tabDetails: {
      noActionAvailable: "Ingen handlinger tilgjengelige",
      activeDealer: "Denne forhandleren er for øyeblikket registrert som Aktiv i A2D og kan ikke slettes.",
      dealerDeleted: "Dealer is deleted."
    },
    Table: {
      action: "",
      activity: "Aktivitet",
      activityCompletionDate: "Fullføringsdato for aktivitet",
      activityCompletionDateFrom: "Dato for gjennomføring av aktivitet fra",
      activityCompletionDateTo: "Dato for fullføring av aktivitet er satt til ",
      activityStartDate: "Startdato for aktivitet",
      activityStartDateFrom: "Dato for start av aktivitet fra",
      activityStartDateTo: "Dato for start av aktivitet er satt til ",
      assignedTo: "Tildelt",
      assignee: "Oppdragsansvarlig",
      benchmark: "Benchmark",
      city: "By",
      completedBy: "Fullført av ",
      completionBy: "Gjennomføring av",
      completionDate: "Gjennomført dato",
      counterMeasure: "Mottiltak",
      country: "Land",
      createdBy: "Opprettet av",
      dealer: "Forhandler",
      dealerNameAD: "Forhandlernavn (A2D)",
      dealerName: "Forhandlernavn",
      definition: "Definisjon",
      description: "Beskrivelse",
      dueDate: "Frist",
      endDate: "Sluttdato",
      firstVisit: "Første besøk",
      followup: "Oppfølging",
      franchise: "Franchise",
      history: "Historikk",
      id: "ID",
      initial: "Innledende",
      kpi: "KPI",
      launch: "Lansering",
      mandatory: "Obligatorisk",
      nmsc: "NMSC",
      problem: "Problem",
      publishedOn: "Publisert den",
      questionReference: "Spørsmålsreferanse",
      reference: "Referanse",
      reminderOn: "Påminnelse på",
      score: "Poengsum",
      startDate: "Startdato",
      status: "Status",
      target: "Target",
      toolbox: "Verktøykasse",
      view: "Visning",
      kpiResult: "KPI resultater",
      nmscTarget: "NMSC Target",
      penEbenchmark: "PanE Benchmark",
      kpiField: "KPI",
      location: "Plassering",
      ActionItemReport: "Action items in progress",
      certification: "Sertifiseringsstatus",
      noOfToolbox: "Antall ferdige verktøykasser",
      program: "Program",
      certificationDate: "Sertifisert dato",
      activityId : 'Activity ID',
      approvedBy:"Approved By",
      kpis:"KPIs",
      lastmonthKpis:"Last Month KPIs",
      questionPercent:"Completion %",
      actionPlan: "Tiltaksplan",
      monthlyKpi:"Monthly KPIs",
      initialKpi:"Initial KPIs",
      targetKpi:"Target KPIs",
     },
     tabsDetails: {
      actionPlan: "Tiltaksplan",
      confirmDeleteMessage: "Er du sikker på at du vil slette den valgte KPI-en?",
      confirmRetireMessage: "Bekreft at du ønsker å avvikle valgt KPI siden denne allerede er publisert",
      errorRetireMsg: "Denne KPI-en kan ikke fjernes da den er en aktiv TME påkrevd KPI",
      import: "Importer",
      importKpiMessage: "Importer KPI-ene fra TME-master-KPI-liste",
      kpi: "Viktige prestasjonsindikatorer",
      library: "Bibliotek",
      noActionAvailable: "Ingen handlinger tilgjengelige",
      NoKPI: "Ingen KPI-er tilgjengelig",
      publish: "Publiser",
      questionnaire: "Spørsmålsskjema",
      tmeKPIList: "TME-master-KPI-liste",
      toyotaCityBrussels: "TOYOTA CITY BRUSSELS",
      attachment: "Attachment",
      attachments: "Attachment(s)",
      uploadAttachment: "Upload Attachment",
      noAttachment: "No attachments available",
    },
    tmeAdminMaster: {
      ToolboxName: "Verktøykasse",
      newToolboxName: "Nytt navn",
      deleteToolbox: "Slette verktøykasse?",
      programName: "Programnavn",
      ToolboxName_EN: "Verktøykasse navn",
      deleteTool: "Er du sikker på at du ønsker å slette denne verktøykassen?",
      selectToolbox: "Velg en verktøykasse du vil slette",
      editToolBox: "Rediger verktøykasse",
      addToolbox: "Legg til verktøykasse",
      toolbox: "Angi et navn for denne verktøykassen",
      addActionPlan: "Add Tiltaksplan",
      actionPlanItem: "Tiltaksplan – elementnr.",
      add: "Legg til",
      addActivity: "Legg til aktivitet",
      additionalInformation: "Tilleggsinformasjon",
      benchmark: "Benchmark",
      cancel: "Avbryt",
      createEditActivity: "Opprett / Rediger aktivitet",
      createKPI: "Opprett KPI",
      delete: "Slett",
      download: "Last ned",
      edit: "Rediger",
      editKPI: "Rediger KPI",
      keyPerformanceIndicator: "Viktig prestasjonsindikator",
      kpiDefinition: "KPI-definisjon",
      mandatoryKpi: "Obligatorisk KPI",
      save: "Lagre",
      search: "Søk",
      unit: "Enhet",
      update: "Oppdater",
      validData: "Oppgi gyldig data",
      hide: "Skjul",
      show: "Vis",
      upload_dealer_network: "Last opp forhandler nettverk",
      upload_question: "Last opp spørreskjema",
      upload_user: "Last opp bruker detaljer",
      upload_activity: "Last opp aktiviteter",
      remove: "Remove",
      toyota: "Toyota",
      lexus: "Lexus",
      launch: "Launch",
      close: "Close",
      generate: "Generate",
      dueDateExceed:"Forfallsdato er overskredet",
      delQuestionnaire:"Delete questionnaire"
    },
    toastr: {
      activityStatus_condition: "Vennligst svar på alle spørsmålene for å lukke denne aktiviteten",
      emptyTarget: "Please enter the NMSC target to publish the KPI",
      retailerActual: "Please enter the Retailer Actual",
      initialTargetMonthly: "Oppgi den første KPI-målingen, KPI-målene og minst 1 måneds måling for å fortsette.",
      initialTarget: "Vennligst skriv inn den første KPI - målingen og KPI -målene for å fortsette",
      upload_error: "Feil under opplasting av forhandler-nettverket.",
      action_item_create_warning: "Maksimalt fem poster kan slås sammen i en handlingsplan",
      actionPlanStartDateValidation: "Sluttdato kan ikke være tidligere enn startdato",
      actionsNotClosed: "Det finnes pågående handlinger i handlingsplanen, lukk all handlinger før du lukker en aktivitet",
      activityDuplicate: "En annen aktivitet for samme forhandler og verktøykasse eksisterer allerede",
      assigneeNotExist: "Den valgte oppdragsansvarlig er i ikke registrert i systemet",
      assigneeValidation: "Ugyldig oppdragsansvarlig",
      atleastOnePublish: "Velg minst én KPI for publisering",
      cannotRetireActive: "Dette er en aktiv påkrevet TME KPI som ikke kan fjernes ",
      completionDate: "Fullføringsdato kan ikke være tidligere enn første besøksdato",
      confirDeletePlannedActivity: "Er du sikker på at du vil slette denne planlagte aktiviteten?",
      DateformatValidation: "Datoen skal være i formatet DD / MM / ÅÅÅÅ",
      DateValidation: "Startdatoen må være tidligere enn sluttdatoen",
      dealerValidation: "Ugyldig forhandler",
      deleteSelectedConfirm: "Er du sikker på at du vil slette den valgte KPI-en?",
      duplicate: "En annen KPI med samme navn og definisjon eksisterer allerede",
      modalMandatory: "Angi gyldige opplysninger i alle de påkrevde feltene",
      noPublishKpi: "KPI-ene er enda ikke publiserte for den valgte verktøykassen",
      validSearchCriteria: "Angi et gyldig søkekriterie",
      multiSelectPlaceHolder: "Skriv inn minst 5 tegn",
      multiSelectPlaceHolderUser: "Skriv inn minst 3 tegn",
      startDateValidation: "Startdatoen kan ikke være etter sluttdatoen",
      fileSelectionErr: "Vennligst velg filen først",
      atleastOneQuestion: "Vennligst velg minst ett underspørsmål for å importere.",
      atleastOneSubQues: "Velg minst ett underspørsmål du vil publisere.",
      uploadErr: "Antall filer som ikke lastet opp:",
      uploadSuccess: "Forhandler nettverk ble lastet opp.",
      file_upload_success: "Filen / filene er lastet opp.",
      KPIinOngoingClosedActivity: "Denne KPi-en er publisert i en eller flere pågående / lukkede aktiviteter. Fullfør disse aktivitetene før du gjør noen endringer i KPI.",
      Role_Error: "Brukeroppsettet er ufullstendig, vennligst kontakt NMSC KET Admin.",
      points_allocation_incorrect: "Feil poengfordeling, vennligst endre.",
      activity_deleted:"Activity deleted successfully",
      activity_certified_dealer:"Dealer certified for the selected Activity, hence cannot be deleted",
      activity_submited_for_certificate_dealer:"The status of toolbox cannot be changed as request for certification has been sent to TME"
    },
    TopNav: {
      ActivityLog: "Aktiviteter",
      ApplicationName: "Kaizen Expert Tool",
      Home: "Hjem",
      logout: "Logg av",
      MasterDataUpload: "Last opp hoveddata",
      Toolbox: "Verktøykasser",
      welcome: "Velkommen",
      Admin: "Admin"
    },
    questionnaire: {
      addSubQuestion: "Legg til underspørsmål",
      editSubQuestion: "Rediger underspørsmål",
      subQuestionTitle: "Underspørsmål",
      additionalInfo: "Tilleggsinformasjon",
      na: "Legg til 'Ikke aktuelt' som et mulig svar på dette spørsmålet",
      question: "Spørsmål",
      questionTitle: "Spørsmålstittel",
      why: "Hvorfor?",
      addMainQuestion: "Legg til hovedspørsmål",
      editMainQuestion: "rediger hovedspørsmålet",
      previousQuestion: "Forrige spørsmål",
      nextQuestion: "Neste spørsmål",
      what: "Hva?",
      library: "Bibliotek",
      subQuestionTable: {
        no: "Nei",
        subQuestion: "Underspørsmål",
        status: "Status",
        publishedDate: "Publisert dato",
        notApplicable: "Ikke aktuelt"
      },
      links: {
        question_link_button: "Link",
        question_link_include_folder: "Inkluder en undermappe",
        question_link_add_link: "Link fil (er) fra biblioteket",
        question_link_view_folder: "Vis bibliotek"
      },
      mainQuestion: "Nye/oppdaterte hovedspørsmål:",
      subQuestion: "Nye/oppdaterte underspørsmål:",
      retiredQuestion: "Skjult underspørsel ",
      libraries: "Nye/oppdaterte filer i biblioteker:",
      noFiles: "Ingen filer tilgjengelige"
    },
    library: {
      selectFile: "Vennligst velg minst én fil.",
      fileSelected: "Valgte filer",
      publish_library_file: "Vennligst velg minst én fil for å publisere",
      library_files_deleted: "Er du sikker på at du ønsker å slette denne filen",
      library_files_linked_deleted: "Er du sikker på at du vil slette denne filen og koblingene opprettet i spørreskjema",
      max_size_error: "Størrelsen på filene overskrider grensen på 1 GB per opplasting.",
      empty_file_desc: "Vennligst skriv inn en filbeskrivelse",
      file_not_selected: "Vennligst velg fil(er) som skal lastes opp (maks 1GB)",
      file_type_error: "Denne filtypen er ikke tillatt.",
      file_name_error: "Ugyldig tegn i filnavnet.",
      file_name_lenght_error: "Filnavnet kan ha maksimalt 50 tegn.",
      addFolder: "Legg til mappe",
      editFolder: "Rediger mappe",
      currentFolder: "Mappenavn",
      parentFolder: "overordnet mappenavn",
      emptyFolderName: "Mappenavnet kan ikke være tomt",
      invalidFolderName: "Mappenavnet kan ikke inneholde '/'.",
      folder_created: "Mappen er opprettet.",
      folder_updated: "Mappen er oppdatert",
      folder_not_found: "Mappe ikke funnet",
      lib_invalid_toolbox: "Ugyldig verktøykasse valgt",
      lib_folder_already_exists: "Denne mappen finnes allerede.",
      lib_folder_deleted: "Mappen er slettet.",
      library_files_published: "Filene er publisert.",
      addFile: "Legg til fil",
      editFile: "Rediger fil",
      fileName: "Filnavn",
      upload: "Laste opp",
      desc: "Beskrivelse",
      folderCreationErr: "Hierarki begrenset til to nivåer.",
      subFolderWarning: "Vennligst velg en undermappe for å legge til fil (er).",
      deleteWarning: "Vennligst velg en mappe for å slette.",
      beforeAfterNotSelected: "Velg om dokumentasjon er før eller etter kaizen",
    },
    chapter: {
      chapter_exist: "Dette kapittelet eksisterer allerede.",
      chapter_saved: "Kapittel Lagret",
      chapter_cannot_delete: "Dette kapittelet kan ikke slettes, da underspørsmål allerede er publisert.",
      failed: "Mislyktes!",
      chapter_deleted: "Kapittel Slettet",
      chapter_nonEmpty: "Vennligst opprett et kapittelnavn.",
      addchapter: "Legg til kapittel",
      editChapter: "Rediger kapittel",
      DELETE_SUCC:"Questionnaires deleted successfully.",
      DELETE_SUCC_LIB:"Libraries deleted successfully.",
      DELETE_ERR : "Something Went wrong."
    },
    evaluation: {
      attachments: "vedlegg",
      comments: "Kommentarer",
      evidence: "Dokumentasjon",
      evidences: "Dokumentasjon",
      yes: "Ja",
      no: "Nei",
      na: "N/A",
      uploadEvidence: "last opp bevis",
      fileUpload: "Vennligst velg fil(er) som skal lastes opp (maks 1GB)",
      who: "Hvem?",
      answer: "svar",
      comment: "Comment",
      date: "Dato",
      submit: "sende inn",
      commentMsg: "Skriv inn kommentarene",
      noCommentAvailable: "Ingen kommentarer tilgjengelig",
      uploadSuccessMsg: "File(s)  have been uploaded successfully",
      uploadErrorMsg: "Number of files failing to upload:",
      fileWithSameName: "En annen fil med samme navn eksisterer allerede",
      noEvidence: "Ingen dokumentasjon tilgjengelig",
      createAction: "Opprett handlingsplan",
      chapterName: "Kapittelnavn",
      questionNo: "Spørsmål nummer",
      when: "Når?",
      noRecords: "Ingen poster tilgjengelig",
      kaizenPoints: "Kaizen poeng",
      renameSuccessMsg: "File has been renamed successfully",
      deleteSuccessMsg: "File has been deleted successfully",
      question: "Spørsmål",
      before:"Before",
      after:"After",
      beforeAfterLabel:"Er denne dokumentasjonen før eller etter kaizen?",
      move:"flytte",
      moveMsg: "Fil(er) har blitt flyttet",
      noToYesMsg:"Previous answer was NO"
    },
    WALK_AROUND_CHECK: "Walk Around Check",
    ketProgram: {
      TSM: "TSM/LSM",
      BRiT: "BRiT RI",
      TSM_BNP: "Karosseri og lakk",
      TSM_USED_CAR: "Bruktbiler",
      value_chain: "Verdikjede",
      brit: "BRiT",
      ea: "EA",
      per:"Performance",
      nettrans:"Network Transformation",
    },
    dashboard: {
      notice_board: "Oppslagstavle",
      kpi_target_not_set: "KPI mål er ikke registrert",
      kpi_initial_not_set: "Innledende KPI måling er ikke registrert",
      kpi_monthly_not_set: "Månedlig KPI måling er ikke registrert",
      kpi_below_target: "KPI lavere enn målene",
      activation: "aktivisering",
      users: "Brukere",
      nmscs: "NMSC's",
      activeRetailer: "Aktive forhandlere",
      certificationRate: "Sertifiseringsgrad",
      reCertificationRate: "Re - sertifiseringsgrad",
      activityDue: "På grunn av aktiviteter",
      avgdays: "Gjennimsnittelige dager/Verktøykasse",
      overDueAct: "Forfalte aktiviteter",
      actionItem: "Handlingselementer",
      bussResult: "Forretningsresultater",
      toolboxImpl: "Implementering av verktøykasse",
      statusCompletedL:"Status - Completed, Progress - ",
      statusClosedL:"Status - Closed, Progress - ",
      statusOngoingL:"Status - Ongoing, Progress - ",
      recentUpdates: "Nylige oppdateringer",
      rolloutTopNmsc: "Utrullningsresultater - Topp NMSC-er",
      bestPractice: "Best praksis tavle",
      availableKpi: "Tilgjengelige KPIer",
      selectedKpi: "Valgte KPIer",
      dealerBestPractice: "Forhandler's Best Practice ",
      kpiList: "KPI liste",
      go: "GÅ",
      nodata: "Ingen data tilgjengelig",
      businessResultTop: "Forretningsresultater - Topp forhandlere",
      businessResultNMSC: "Forretningsresultater - Topp NMSC-er"


    },
    dealerCertification: {
      dealerCertification: "Forhandlersertifisering",
      readyForCertification: "Klar for sertifisering",
      certification: "Sertifisering",
      submittedOn: "Sendt inn",
      certificateDate: "Sertifikat dato",
      reCertificateDate: "Re-sertifikat dato",
      toolboxDetails: "Verktøykasse detaljer",
      approve: "Godkjenn",
      reject: "Avvist",
      pending: "Avventer",
      region: "Region"



    },
    uploadBulk: {
      browse: "Browse",
      executionId: "Utførelses-id",
      uploadedOn: "Lastet opp den",
      lastUpdatedOn: "Siste oppdatering ",
      noDataTable: "No data to view",
      kpiUpload: "KPI er lastet opp",
      action: "Handling",
      processed: "Bearbeidet",
      error: "Feil",
      received: "Received",
      createActivity: "opprett aktiviteter",
      fileUpload: "File Upload",
      targetSetting: "Target Setting",
      kpiEntry:"KPI Entry",
      accepted:"Accepted",
      launchNmsc:"Launch for NMSC"
    },
    userManagement: {
      userManagement: "Brukeradministrasjon",
      createUser: "Create User",
      editUser: "Edit User",
      viewUser: "View User",
      nmscId: "NMSC ID",
      tarsId: "TARS ID",
      firstName: "Fornavn",
      lastName: "Etternavn",
      emailId: "e-mail",
      britDealerChamp: "Brit Dealer Champion",
      invalidEmail: "Please enter valid E-Mail ID",
      invalidTars: "Please enter valid TARS ID",
      invalidFirstName: "Please enter valid First Name",
      invalidLastName: "Please enter valid Last Name",
      user_created: "User Created",
      user_updated: "User updated",
      duplicate_tarsuser: "This TARS id is already registered in the KET database. {0}",
      user_define_in_tool: "User is defined as Business owner in Active Business Function. Hence, could not be made Inactive",
      user_refer_on: "User is referenced in Ongoing activity",
      pbiAccount : "PBI Account",
      invalidPbiAccount : "PBI Account should contain minimum 3 characters.",
      businessArea: "Business Area",
      valueChainAndBRiT: "Value Chain & BRiT",
      only:"Only",
      optionText:"Please select at least one of the two options below",
      valueChainOnly: "Value Chain Only",
      britOnly: "BRiT Only"
    },
    UserManagement: {
      nmscId: "NMSC ID",
      tarsId: "TARS ID",
      firstName: "Fornavn",
      lastName: "Etternavn",
      emailId: "e-mail",
      languageCode: "Språkkode",
      ACTIVE: "Active",
      INACTIVE: "Inaktiv",
      addUser: "Legg til bruker",
      Assign: "Tildele",
      List: "liste",
      user: "Bruker",
      error_message: "Det oppsto en feil. Prøv igjen senere.",
      role: "Brukerrolle",
      standardMessage: "No User Found",
      dealerCode: "Forhandlerkode",
      a2d: "A2D UUID",
      britReady: "Forhandler deltok i Brit Ready.",
      britLive: "Forhandler deltok i Brit Live.",
      britJapan: "Forhandler deltok i Brit Discovery Japan.",
      britEss:"Retailer has updated EES Action Plan",
      addDealer: "Legg til forhandler (fra A2D)",
      noDealer: "Ingen forhandler(e) funnet ",
      dealerUuid: "Forhandler uuid kode (A2D)",
      language: "Språk",
      L3: "L3",
      L4: "L4",
      L5: "L5",
      L6: "L6",
    },
    CeaKpi: {
      survey: "Survey",
      evaluationItem: "Evaluation Item",
      kpiMetric: "KPI Evaluation Method",
      stratagicPillar: "Strategic Pillar",
      addKPI: "Add KPI",
      edit: "Edit",
      delete: "Delete",
      subPillar: "Sub-Pillar",
      kaizenImp_impact_def: "Kaizen Importance / Impact / Definition",
      evalMethod: "Evaluation Method",
      scoreMethod: "Scoring Method",
      typeOftarget: "Type of Target",
      target: "Target",
      mode: "Mode",
      points: "Points",
      inputFreq: "Input Frequency",
      cea_kpi_created: "CEA KPI has been created successfully",
      noPointsAllocated:"No Points defined for selected Pillar/Business function mapping",
      targetIncorrect:"Target value is Invalid",
      viewKpi:"View KPI",
      general:"General",
      targetScoring:"Targets & Scoring",
      inputs:"Input Parameters",
      maxFiveParam:"Maximum 5 parameters for a KPI can be added",
      validParam:"Please Enter Valid Parameter",
      validDesc:"Please Enter Valid Parameter Description",
      dataEntry:"Data Entry in KET",
      paramater:"Parameter",
      paramDesc:"Parameter Description",
      noParamAvai:"No Parameters Available",
      kpiDeleted:"KPI deleted Successfully",
      kpiDeleteError:"KPI Not found",
      kpiActualsError:"Actuals present. KPI cannot be deleted",
      cea_kpi_updated: "CEA KPI has been updated successfully",
      addParam:"Add Parameters"

    },
    dealerManagement: {
      invalidUuid: "Vennligst skriv inn en gyldig A2D-guide.",
      invalidDealerName: "Vennligst skriv inn et gyldig forhandlernavn.",
      invalidCity: "Vennligst skriv inn et gyldig sted.",
      invaliDealerCode: "Vennligst skriv inn en gyldig forhandlerkode.",
      existingUuid: "A2D uuid-koden er allerede i KET-databasen.",
      updateUuid: "Oppdater A2D uuid-kode",
      newUuid: "Ny A2D uuid-kode",
    },
    networkManagement: {
      networkManagement: "Nettverksadministrasjon",
    },
    kpiEntry: {
      cancel : "Cancel",
      save : "Save",
      no : "No",
      yes : "Yes",
      hide : "Hide",
      show : "Show",
      dataNotSaved : "Data is not saved. Entered data may be lost. Do you want to Proceed?",
      wantToProceed : "Do you want to proceed?",
      status: 'Status',
      evaluationYear: 'Evaluation Year',
      frequency: 'Frequency',
      typeOfTarget: 'Type of Target',
      target: 'Target',
      unit: 'Unit',
      standardMessage: 'No Kpi Actuals Data Found',
      srNo: 'S.No.',
      actuals: 'Actuals',
      subPillar: 'Sub Pillar',
      businessFunc: 'Business Function',
      brand: 'Brand',
      Jan : "Jan",
      Feb : "Feb",
      Mar : "Mar",
      Apr : "Apr",
      May : "May",
      Jun : "Jun",
      Jul : "Jul",
      Aug : "Aug",
      Sep : "Sep",
      Oct : "Oct",
      Nov : "Nov",
      Dec : "Dec",
      q1 : "Q1",
      q2 : "Q2",
      q3 : "Q3",
      q4 : "Q4",
      h1 : "H1",
      h2 : "H2",
      cy : "CY",
      kpi : "Key Performance Indicators",
      actuals1 : "Actuals should not be more than allotted points. Please re-enter",
      actionPlan	: "Action Plan",
      enterActualsData : "Please enter actuals data.",
      notNumber : "Please enter valid number.",
      serverErrorMsg : "An error occured, please try again later.",
      launchEvaluationError : "Please launch evaluations.",
      actualsDataSaved : "Actuals data uploaded successfully.",
      inputTxt:"Inputs"
    },
    ceaEvaluation :{
      nmsc : "NMSC",
      search : "Search",
      all : "All",
      status : "Status",
      businessFunction :"Business Function",
      noEvaluationsFound : "No Evalutions Found",
      evaluationYear : "Evalution Year",
      view : "View",
      evaluations : "Evaluations",
      launchTargetSetting: "Launch Target Setting",
      launchEvaluation : "Launch Evaluation",
    },
    britDashboard:{
      noVideo:"No videos available",
      playVideo:"Play Video",
      trainingVideos:"Training  Videos",
      download: "Download Video",
      back:"Back"
    },
    ceaActionPlan:{
      id: "ID",
      counterMeasure: "Countermeasure",
      questionReference: "KPI Reference",
      status: "Status",
      startDate: "Start Date",
      dueDate: "Due Date",
      assignedTo: "Assigned To",
      noActionAvailable: "No Actions available",
      add: "Add",
      download: "Download",
      edit: "Edit",
      delete: "Delete",
      deleteMsg: "Are you sure you want to delete ?",
      no: "No",
      yes: "Yes",
      addActionPlanNotAllowed: "The Action Plan cannot be created, or edited after the Activity has been Closed, Completed, or Rejected.",
      actionPlanItem: "Action Plan - Item #",
      addActionPlan: "Add Action Plan",
      open: "Open",
      actionPlanStartDateValidation:"End Date cannot be earlier than the Start Date",
      modalMandatory: "Please enter valid data in all the mandatory fields",
      problem: "Problem",
      description: "Description",
      followup: "Follow Up",
      history: "History",
      cancel: "Cancel",
      save: "Save",
      update: "Update",
      assignee: "Assignee",
      endDate: "End Date", 
      select: "Select",
      notApplicablePer:"Not Applicable for CEA, Networks and Used car"
    },
    reachItDashboard:{
      topNavReachIT:"ReachIT",
      downloads:"Downloads",
      historyFiles:"History of previous files",
      reachText1:"Welcome to the REACH IT homepage. The TME Environmental Management team will use this page to share relevant  information related to SCIP : Substances of Concern In articles as such or in complex objects (Products) established under the Waste Framework Directive (WFD) in order for you to fulfill your duty to process with your SCIP simplified notification.",
      reachText2:"In case of query, please contact the TME Environmental Management team via below link.",
      reachText3:"In case of query, please contact your NMSC.",
      envMngt:"Environmental Management",
      fileStatistics: "File Downloaded Statistics",
      Downloaded: "Downloaded",
      not: "Not",
      exportToExcel:"Export To Excel"
    },
    usedCarComment:{
      ongoing:"Ongoing",
      completed:"Completed",
      commentHistory:"Monthly Comments History",
      addComment:"Add Monthly Comment",
      month:"Month",
      comment:"Comment",
      username:"Username",
      email:"Email",
      createdDate:"Created Date"
    },
  }
};

export default translationsNO;
