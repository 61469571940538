import React from 'react';
import { connect } from 'react-redux';
import { fetchAccessControlList } from '../../../actions/loginAction.jsx';
import {
    Container, Row, Col, Input, Fa
} from 'mdbreact';
import { toastr } from 'react-redux-toastr';
import { withRouter } from 'react-router-dom';
import ReactMultiSelectCheckboxes from 'react-multiselect-checkboxes';
import './index.css';
const $ = require('jquery')
window.jQuery = $;


class LoginForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            username: 'OCI7842@TMME',
            password: '123',
            roles: ['KET-UC Admin'],
            lang: 'en',
            nmsc: 'tme',
            role: [{ label: "KET-UC Admin", value: "KET-UC Admin" }]
        };
    }

    submitForm(e) {
        e.preventDefault();

        if (this.state.username.length === 0) {
            toastr.error('Please enter username.');
            return;
        }

        if (this.state.password.length === 0) {
            toastr.error('Please enter password.');
            return;
        }


        // this.props.dispatch(authenticateUser(this.state));
        this.props.dispatch(fetchAccessControlList(this.state));
        // this.props.history.push('/');
    }

    handleUsernameChange = e => {
        this.setState({ username: e });
    };

    handlePasswordChange = e => {
        this.setState({ password: e });
    };

    changeLang = e => {
        this.setState({
            lang: e.target.value
        });
    }

    changeNMSC = e => {
        this.setState({
            nmsc: e.target.value
        });
    }

    changeUserRole = (selectedOption) => {
        console.log("selectedOption");
        console.log(selectedOption);
        var valueOptions = [];
        selectedOption.map(function (item) {
            valueOptions.push(item.value);
            return null;
        })
        this.setState({
            roles: valueOptions
        });

        this.setState({ role: selectedOption })
    }
    componentDidMount() {
        $('.navbar-nav').hide();
        // const cookies = new Cookies();
        // let jwtHeader = cookies.get('jwt');
        // var usrMdl = cookies.get('usrMdl');
        // if (jwtHeader && jwtHeader.indexOf("Bearer") != -1 && usrMdl) {
        //     console.log("valid session");
        //     this.props.dispatch(setUserRoles(usrMdl));
        //     axios.defaults.headers.common['Authorization'] = usrMdl.tokenType + ' ' + usrMdl.accessToken;
        //     axios.defaults.baseURL = '/pmsc';
        // }
        // else {
        //this.props.dispatch(push('/'));
        // }
    }

    componentWillUnmount() {
        $('.navbar-nav').show();
    }


    render() {
        const options = [
            { label: 'KET-UC Admin', value: 'KET-UC Admin' },
            { label: 'KET-UC NMSC User', value: 'KET-UC NMSC User' },
            { label: 'KET-UC Dealer', value: 'KET-UC Dealer' },
            { label: 'KET-NET Admin', value: 'KET-NET Admin' },
            { label: 'KET-NET NMSC User', value: 'KET-NET NMSC User' },
            { label: 'KET-NET User', value: 'KET-NET User' },
            { label: 'KET TME Admin', value: 'KET TME Admin' },
            { label: 'KET TME User', value: 'KET TME User' },
            { label: 'KET NMSC Admin', value: 'KET NMSC Admin' },
            { label: 'KET NMSC User', value: 'KET NMSC User' },
            { label: 'KET NMSC Field', value: 'KET NMSC Field' },
            { label: 'KET NMSC External', value: 'KET NMSC External' },
            { label: 'KET Dealer Champion', value: 'KET Dealer Champion' },
            { label: 'KET Dealer Internal', value: 'KET Dealer Internal' },
            { label: 'KET-BRiT TME Admin', value: 'KET-BRiT TME Admin' },
            { label: 'KET-BRiT NMSC Admin', value: 'KET-BRiT NMSC Admin' },
            { label: 'KET-BRiT TME User', value: 'KET-BRiT TME User' },
            { label: 'KET-BRiT NMSC User', value: 'KET-BRiT NMSC User' },
            { label: 'KET-BRiT NMSC Field', value: 'KET-BRiT NMSC Field' },
            { label: 'KET-BRiT Dealer Champion', value: 'KET-BRiT Dealer Champion' },
            { label: 'KET-BRiT Dealer Internal', value: 'KET-BRiT Dealer Internal' },
            { label: 'KET-CEA Admin', value: 'KET-CEA Admin' },
            { label: 'KET-CEA Business Owner', value: 'KET-CEA Business Owner' },
            { label: 'KET-CEA NMSC Admin', value: 'KET-CEA NMSC Admin' },
            { label: 'KET-CEA NMSC User', value: 'KET-CEA NMSC User' },
            { label: 'KET-CEA User', value: 'KET-CEA User' },
            { label: 'KET-EA Admin', value: 'KET-EA Admin' },
            { label: 'KET-EA NMSC Admin', value: 'KET-EA NMSC Admin' },
            { label: 'KET-EA Dealer Champion', value: 'KET-EA Dealer Champion' },
            { label: 'KET-ReachIT Admin', value: 'KET-ReachIT Admin' },
            { label: 'KET-ReachIT NMSC User', value: 'KET-ReachIT NMSC User' },
            { label: 'KET-ReachIT Dealer', value: 'KET-ReachIT Dealer' },
            { label: 'KET-LEXUS Admin', value: 'KET-LEXUS Admin' },
            { label: 'KET-LEXUS User', value: 'KET-LEXUS User' },
            { label: 'KET-LEXUS NMSC User', value: 'KET-LEXUS NMSC User' },
            {label:'KET-TSP Auditor' , value :'KET-TSP Auditor'}
        ];

        return (
            <Container className="loginForm d-flex p-2 justify-content-center mt-5 pt-5">
                <Row className="rounded mb-0 border border-light" style={{ width: "30%", backgroundColor: "white" }}>
                    <Col md="12">
                        <div className="h5 text-center m-4">Sign in to KET System</div>
                        <div className="grey-text">
                            <Input label="Username" icon="user" group type="text" validate error="wrong" success="right"
                                value={this.state.username} getValue={(e) => { this.handleUsernameChange(e) }} />
                            <Input label="Password" icon="lock" group type="password" validate
                                value={this.state.password} getValue={(e) => { this.handlePasswordChange(e) }} />
                            <div className="md-form form-group">
                                <Fa icon="globe" size="lg" className="prefix p-1 active" />
                                <select className="browser-default custom-select pl-5" value={this.state.nmsc} onChange={(e) => { this.changeNMSC(e) }}>
                                    <option value="tgb">TME</option>
                                    <option value="tgb">TGB</option>
                                    <option value="tdg">TDG</option>
                                    <option value="tsw">TSW</option>
                                    <option value="tba">TBA</option>
                                    <option value="tcap">TCAP</option>
                                    <option value="tmr">TMR</option>
                                    <option value="TBAL">TBAL</option>
                                    <option value="tag">TAG</option>
                                    <option value="tro">TRO</option>
                                </select>
                            </div>
                            <div className="md-form form-group">
                                <Fa icon="language" size="lg" className="prefix p-1 active" />
                                <select className="browser-default custom-select pl-5" value={this.state.lang} onChange={(e) => { this.changeLang(e) }}>
                                    <option value="sv">Swedish (SV)</option>
                                    <option value="et">Estonian (ET)</option>
                                    <option value="lt">Lithuanian (LT)</option>
                                    <option value="lv">Latvian (LV)</option>
                                    <option value="pt">Portuguese (PT)</option>
                                    <option value="ru">Russian (RU)</option>
                                    <option value="en">English (EN)</option>
                                    <option value="de">German (DE)</option>
                                    <option value="az">Azerbaijan (AZ)</option>
                                    <option value="hb">Hebru (HB)</option>
                                    <option value="ro">RO (RO)</option>
                                    <option value="fr">FR (FR)</option>
                                    <option value="it">IT (IT)</option>
                                    <option value="bg">BG (BG)</option>
                                    <option value="bs">BS (BS)</option>
                                    <option value="cs">CS (CS)</option>
                                    <option value="da">DA (DA)</option>
                                    <option value="el">EL (EL)</option>
                                    <option value="es">ES (ES)</option>
                                    <option value="hr">HR (HR)</option>
                                    <option value="hu">HU (HU)</option>
                                    <option value="is">IS (IS)</option>
                                    <option value="nl">NL (NL)</option>
                                    <option value="no">NO (NO)</option>
                                    <option value="pl">PL (PL)</option>
                                    <option value="sk">SK (SK)</option>
                                    <option value="sl">SL (SL)</option>
                                    <option value="sr">SR (SR)</option>
                                    <option value="tr">TR (TR)</option>
                                    <option value="uk">UK (UK)</option>
                                    <option value="fi">FI (FI)</option>

                                </select>
                            </div>
                            <div className="multiple-roles">
                                {/*  <Fa icon="language" size="lg" className="prefix p-1 active" /> */}
                                {/*   <select className="browser-default custom-select pl-5" value={this.state.role} onChange={(e) => { this.changeUserRole(e) }}>
                                    <option value="KET TME Admin">KET TME Admin</option>
                                    <option value="KET TME User">KET TME User</option>
                                    <option value="KET NMSC Admin">KET NMSC Admin</option>
                                    <option value="KET NMSC User">KET NMSC User</option>
                                    <option value="KET NMSC Field">KET NMSC FIELD</option>
                                    <option value="KET NMSC External">KET NMSC EXTERNAL</option>
                                    <option value="KET Dealer Champion">KET DEALER KAIZEN CHAMPION</option>
                                    <option value="KET Dealer Internal">KET Dealer Internal</option>
                                    <option value="KET-BRiT TME Admin">KET-BRiT TME Admin</option>
                                    
                                </select> */}
                                <ReactMultiSelectCheckboxes
                                    value={this.state.role}
                                    options={options}
                                    onChange={this.changeUserRole}
                                />
                            </div>
                        </div>
                        <div className="text-center mb-3">
                            <button type="button" className="btn btn-md btn-primary" onClick={this.submitForm.bind(this)}>Login</button>
                        </div>
                    </Col>

                </Row>
            </Container>
        );
    }
}

const mapStateToProps = state => ({
    user: state.loginReducer,
    blocking: state.loginReducer
});
export default withRouter(connect(mapStateToProps, null, null, { pure: false })(LoginForm));