const translationsPT = {
  pt: {
    Policy: {
      HeaderMsg: "Política de Privacidade para o tratamento dos seus dados pessoais no enquadramento do KET",
      Message1: "Esta Política de Privacidade remete para o tratamento dos seus Dados Pessoais no enquadramento do KET. Tem de ser considerada em conjunto com a Política de Privacidade.",
      Message2: "Toyota Motor Europe NV/SA, com sede na Avenue du Bourget/Bourgetlaan 60, 1140 Brussels, Belgium, como Responsável de Tratamento de Dados, recolhe os seus Dados Pessoais (tal como indicado abaixo) para os efeitos descritos abaixo.",
      Message3: "Quais os seus Dados Pessoais que serão recohidos?",
      Message4: "Iremos recolher a(s) seguinte(s) categoria(s) dos seus Dados Pessoais, com relação ao KET:",
      Table1: {
        tr1: "Categoria(s) de Dados Pessoais:",
        tr2: "User Id",
        tr3: "Nome do Utilizador (Nome Completo)",
        tr4: "Marca (Toyota, Lexus)",
        tr5: "Idioma preferencial",
        tr6: "Organização associada"
      },

      Message5: "Propósito e bases legais do tratamento dos seus dados pessoais.",
      Message6: "Iremos tratar os seus Dados Pessoais para os efeitos e nas bases legais descritas abaixo:",
      Table2: {
        tr1: {
          th1: "Objetivo:",
          th2: "Base legal:"
        },
        tr2: {
          td1: "KET – 1: Performance Kaizen Oficina",
          td2: "Interesses legítimos do Responsável pelo Tratamento de Dados"
        },
        tr3: {
          td1: "KET – 2: Apoio Informático",
          td2: "Interesses legítimos do Responsável pelo Tratamento de Dados"
        }
      },
      Message7: "Duração da conservação dos seus Dados Pessoais",
      Message8: "Os seus Dados Pessoais serão mantidos durante o período indicado abaixo:",
      Table3: {
        tr1: {
          th1: "Período de conservação:",
          th2: "Categoria(s) de Dados Pessoais:"
        },
        tr2: {
          td1: "Qualquer informação pessoal tal como descrita acima, será mantida por um máximo de 5 anos + 1 mês, a partir do dia do encerramento final do KET.",
          td2: "Primeiro nome, Último nome, Idioma preferencial, Organização do utilizador"
        }
      },
      Message9: "Opcional: No caso de transferência dos seus Dados Pessoais para países fora do Espaço Económico Europeu (EEE)",
      Message10: "Os seus Dados Pessoais estarão disponíveis para destinatário(s) fora do EEE, tal como indicado abaixo:",
      Table4: {
        tr1: {
          td1: "Identidade",
          td2: "País",
          td3: ""
        },
        tr2: {
          td1: "Destinatário(s) não-EEE",
          td2: "Infosys",
          td3: "Índia"
        }
      },
      Message11: "Confirmo que compreendi a Política de Privacidade; Li e aceitei a Política Geral e de Proteção de Dados Pessoais da Toyota."
    },
    ACCESSORIES: "Acessórios",
    activity: {
      Assignee: "Titular",
      CompletionDate: "Data de fim",
      Dealer: "Concessionário",
      FirstVisit: "Primeira visita",
      kpi: {
        kpi: "O KPI só pode ser um número positivo ou negativo",
        kpiLength: "Máximo 10 caractéres para o KPI",
        mandatory: "Por favor introduza dados válidos em todos os campos obrigatórios",
        mandatoryTargets: "Por favor inserir todos os KPI's.",
        target: "O objetivo só pode ser um número positivo ou negativo",
        targetLength: "Máximo 10 caractéres para o objetivo"
      },
      ReminderOn: "Lembrete ativo",
      statusDD: "Estado",
      Toolbox: "Toolbox",
      activityCreation: "Foi iniciado o processo de criação da Atividade.",
      activityCreated: "Atividade(s) criada(s)",
    },
    DPOK: "Kaizen Peças",
    EFFICIENCY: "Eficiência",
    FIX_IT_RIGHT: "Fix-it-Right",
    footer: {
      message: "Campos obrigatórios"
    },
    FOUNDATION: "Pilares",
    MAINTENANCE_REMINDERS: "Programa de Avisos",
    EMPLOYEE_ENGAGEMENT: "Employee Engagement",
    CUSTOMER_CENTRICITY: "Customer Centricity",
    TOYOTA_WAY: "Toyota Way",
    BUSINESS_SUSTAINABILITY: "Business Sustainability",
    FUNDAMENTALS: "Pilares",
    FIX_IT_RIGHT_BnP: "Fix it Right (B&P)",
    UTILIZATION_AND_EFFICIENCY: "Utilization and Efficiency",
    CUSTOMER_EXPERIENCE: "Customer Experience",
    UPSELLING_OPPORTUNITIES: "Upselling Opportunities",
    MANAGING_CAPACITY: "Managing Capacity",
    VEHICLE_TRADE_IN: "Vehicle Trade-In",
    STOCK_MANAGEMENT: "Stock Management",
    REFURBISHMENT: "Refurbishment",
    months: {
      apr: "Abr.",
      aug: "Ago.",
      dec: "Dez.",
      feb: "Fev.",
      jan: "Jan.",
      jul: "Jul.",
      jun: "Jun.",
      mar: "Mar.",
      may: "Mai.",
      nov: "Nov.",
      oct: "Out.",
      sep: "Set."
    },
    others: {
      back: "Retroceder",
      closed: "Fechada",
      completed: "Concluída ",
      countryLanguageCode: "País/Código do idioma ",
      createActivity: "Criar Atividade",
      createdDate: "Data de criação",
      dataUneditableNote: "Nota: Não é possível editar os dados depois de guardados. Para alterar dados, contacte o admin da NMSC.",
      date: "Data",
      downloadQuestionnaire: "Download do Questionário",
      editActivity: "Editar Atividade",
      initialHelpText: "A medição inicial do KPI deve ser o valor médio dos seis meses completos anteriores",
      inProgress: "Em progresso",
      name: "Nome",
      next: "Seguinte",
      noActivities: "Nenhuma Atividade encontrada",
      noResults: "Sem resultados",
      of: "de",
      ongoing: "Em curso",
      open: "Em curso",
      page: "Página",
      planned: "Planeado",
      problemDescription: "Descrição da situação",
      signature: "Assinatura",
      select: "Selecione"
    },
    ServerMessage: {
      error_message: "Ocorreu um erro durante o download.",
      no_published_subquestion: "Por favor publique o Questionário para esta toolbox para poder iniciar esta Atividade.",
      activity_upload_error_msg: "Erro durante o carregamento dos Detalhes da Atividade.",
      action_plan_created: "Plano de ação criado com sucesso",
      action_plan_deleted: "Plano de ação eliminado com sucesso",
      action_plan_saved: "Plano de ação guardado com sucesso",
      activity_kpi_saved: "KPI's da atividade guardados com sucesso",
      master_kpi_confirm_delete: "Tem a certeza que deseja eliminar os KPI's selecionados?",
      master_kpi_created: "KPI criado com êxito",
      master_kpi_deleted: "KPI foi eliminado",
      master_kpi_not_found: "KPI não encontrado",
      master_kpi_published: "KPI selecionado foi publicado com êxito",
      master_kpi_retired: "KPI foi retirado",
      master_kpi_saved: "KPI guardado com êxito",
      subquestion_created: "A sub-questão foi guardada com sucesso.",
      subquestion_updated: "A sub-questão foi atualizada com sucesso.",
      subquestionnaire_not_found: "A sub-questão não foi encontrada.",
      subquestion_retired: "A sub-questão foi retirada.",
      subquestion_deleted: "A sub-questão foi eliminada.",
      subquestion_published: "As sub-questões foram publicadas com sucesso.",
      mainquestion_created: "O Questionário Principal foi criado.",
      mainquestion_deleted: "O Questionário Principal foi eliminado.",
      mainquestion_updated: "A Questão Principal foi atualizada.",
      duplicateMsg: "Registos duplicados no ficheiro Excel.",
      deleteErr: "Erro ao eliminar o ficheiro",
      toolbox_deleted: "A toolbox foi eliminada com sucesso",
      toolbox_already_exists: "A toolbox já existe.",
      toolbox_saved: "Toolbox guardada",
      toolbox_created: "Toolbox criada",
      toolbox_not_found: "Toolbox não encontrada",
      published_content_found: "Esta toolbox não pode ser eliminada por conter conteúdo publicado",
      dealers_already_mapped: " As Oficinas selecionadas já foram mapeadas para este utilizador. ",
      dealer_already_mapped: " Dealer already mapped ",
      dealer_added: "  Oficina adicionada. ",
      dealers_added: "  Oficina adicionada. ",
      total_dealers_mapped_now: "O número total de Oficinas atualmente mapeadas para este utilizador é . ",
      no_dealer_available: "Não existem Oficinas para os critérios selecionados.",
      proper_dealer_selected: "Por favor selecione uma Oficina adequada.",
      user_referenced_ongoing_activity: "O utilizador está atualmente atribuído a Atividades em curso. Por favor substitua o Responsável nestas Atividades e tente novamente. ",
      want_to_remove_mapping: "Tem a certeza que deseja remover o mapeamento do utilizador para estas Oficinas?",
      user_mapping_removed: "As Oficinas selecionadas foram removidas com sucesso do mapeamento do utilizador.",
      dealer_closed_in_A2D: "Esta Oficina está registada como 'Encerrada' no A2D.",
      network_level_not_found: "Por favor selecione uma combinação válida de níveis da Rede de acordo com as sua configuração da Rede no KET.",
      duplicate_dealer_uuid: "Oficina já existe na base de dados do KET.",
      dealer_created: "Oficina criada.",
      dealer_updated: "Os atributos desta Oficina foram atualizados.",
      dealer_not_present: "Esta Oficina não está registada no A2D.",
      dealer_uuid_other_nmsc: "O código UUID do A2D não está disponível para a sua Rede."
    },
    standardMessages: {
      delete: "Tem a certeza que deseja eliminar?",
      addActionPlanNotAllowed: "O Plano de Ação não pode ser criado ou editado depois da Atividade estar Fechada, Completa, ou Rejeitada.",
      addToolboxNotAllowed: "Cannot create more than 19 toolbox",
      britRejectMsg: "De certeza que deseja Rejeitar esta Atividade? Não são permitidas alterações depois de rejeitar.",
      rejectMsg: "De certeza que deseja Rejeitar esta Atividade? Não são permitidas alterações depois de rejeitar.",
      selectNMSCTab: "Por favor selecione o idioma da NMSC para publicar",
      all: "Tudo",
      deleteConfirm: "Tem a certeza de que deseja eliminar o KPI selecionado?",
      no: "Não",
      retireConfirm: "Tem a certeza de que deseja retirar este KPI? Depois de retirado, este KPI não pode voltar a ser ativado",
      warning: "Aviso",
      yes: "Sim",
      saveDataConfirmation: "Tem a certeza de que deseja guardar os dados?",
      saveKPIWarning: "Nota: Não é possível editar os dados depois de guardados. Para alterar dados, contacte o admin da NMSC.",
      addKpiNotAllowed: "Os KPIs do próximo mês podem ser adicionados quando o mês atual terminar.",
      activityLaunch: "Tem a certeza que deseja Iniciar esta Atividade? Depois de iniciar, a Atividade não pode ser eliminada ou regressar ao estado de Planeada.",
      deleteConfirmSubQuestionNaire: "Tem a certeza que deseja eliminar a sub-questão selecionada?",
      retireConfirmSubQuestionNaire: "Tem a certeza que deseja retirar a sub-questão selecionada?",
      deleteConfirmMainQuestion: "Por favor confirme que deseja eliminar a Questão Principal e as respetivas sub-questões associadas?",
      deleteConfirmChapter: "Tem a certeza que deseja eliminar o capítulo?",
      deleteLibraryFolder: "Tem a certeza que deseja eliminar esta pasta?",
      deletePublishedFolder: "Alguns ficheiros desta pasta podem estar ligados ao Questionário. Tem a certeza que deseja eliminar permanentemente esta subpasta e remover a(s) ligação(ões) do Questionário?",
      importQuestion: "Tem a certeza que deseja importar esta sub-questão? A sub-questão atual será substituída.",
      noQuestion: "Sem Questões disponíveis.",
      MandatoryWarning: "Por favor preencha todos os campos obrigatórios para todos os idiomas locais.",
      certificationSubmission:
        "A toolbox foi submetida à TME para certificação da Oficina",
      sameToolBox: "Não é possível submeter a mesma toolbox mais de uma vez. Por favor selecione outra toolbox",
      deleteConfirmDealer: "Are you sure you want to delete this dealer ?",
      updateVcConfirmDealer: "Todas as Atividades criadas para este Concessionário/RTA na 'Cadeia de Valor' serão removidas. Tem a certeza que quer continuar?",
      updateBritConfirmDealer: "Todas as Atividades criadas para este Concessionário/RTA no 'BRiT' serão removidas. Tem a certeza que quer continuar?",
      completed_activity_delete:"A informação não pode ser recuperada depois de eliminada. Tem a certeza que quer eliminar?",
      deleteAllQuestionary: "Are you sure you want to delete the entire Questionnaire?",
      deleteAllLibraries: "Are you sure you want to delete all folders and files in the Library?",
      selectAll: "Selecionar todos",
      unSelectAll: "Desmarcar todos",
      select:"Selecionar…",
      warningMsg:"Existem plano(s) de ação abertos nesta atividade. Deseja completar a atividade?"
    },
    status: {
      published: "Publicado",
      retired: "Retirado",
      saved: "Guardado",
      rejected: "Rejeitado",
      open: "Abrir",
      closed: "Fechada",
      close: "Fechada",
      ongoing: "Em curso",
      completed: "Concluída",
      complete: "Concluída",
      planned: "Planeado",
      overdue: "Fora do prazo",
      due: "Dentro do prazo",
      pending: "Pending",
    },
    Rejected: "Rejeitado",
    Open: "Abrir",
    Closed: "Fechada",
    Close: "Fechada",
    Ongoing: "Em curso",
    Completed: "Concluída",
    Complete: "Concluída",
    Planned: "Planeado",
    tabDetails: {
      noActionAvailable: "Nenhuma Ação disponível",
      activeDealer: "Esta Oficina está atualmente registada como Ativa no A2D e não pode ser eliminada.",
      dealerDeleted: "Dealer is deleted."
    },
    Table: {
      action: "",
      activity: "Atividades",
      activityCompletionDate: "Data de conclusão da ação",
      activityCompletionDateFrom: "Data de fim da atividade desde",
      activityCompletionDateTo: "Data de fim da atividade até",
      activityStartDate: "Data de início da ação",
      activityStartDateFrom: "Data de início da atividade desde",
      activityStartDateTo: "Data de início da atividade até",
      assignedTo: "Atribuído a",
      assignee: "Titular",
      benchmark: "Valor de referência",
      city: "Cidade",
      completedBy: "Concluído por ",
      completionBy: "Terminar até",
      completionDate: "Data de fim",
      counterMeasure: "Contramedida",
      country: "País",
      createdBy: "Criado por",
      dealer: "Concessionário",
      dealerNameAD: "Nome da Oficina (A2D)",
      dealerName: "Nome da Oficina",
      definition: "Definição",
      description: "Descrição",
      dueDate: "Data limite",
      endDate: "Data de conclusão",
      firstVisit: "Primeira visita",
      followup: "Follow Up",
      franchise: "Marca",
      history: "História",
      id: "ID",
      initial: "Inicial",
      kpi: "KPI",
      launch: "Iniciar",
      mandatory: "Obrigatório",
      nmsc: "NMSC",
      problem: "Problema",
      publishedOn: "Publicado em",
      questionReference: "Ref. da pergunta",
      reference: "Guia",
      reminderOn: "Lembrete ativo",
      score: "Pontuação",
      startDate: "Data de início",
      status: "Estado",
      target: "Target",
      toolbox: "Toolbox",
      view: "Vista",
      kpiResult: "Resultados dos KPIs",
      nmscTarget: "NMSC Target",
      penEbenchmark: "PanE Benchmark",
      kpiField: "KPI",
      location: "Localização",
      ActionItemReport: "Ações em progresso",
      certification: "Estado de Certificação",
      noOfToolbox: "Nº de Toolboxes Concluídas",
      program: "Programa",
      certificationDate: "Data certificada",
      activityId : 'ID da Atividade',
      approvedBy:"Approved By",
      kpis:"KPIs",
      lastmonthKpis:"Last Month KPIs",
      questionPercent:"Completion %",
      actionPlan: "Plano de ação",
      monthlyKpi:"Monthly KPIs",
      initialKpi:"Initial KPIs",
      targetKpi:"Target KPIs",
     },
     tabsDetails: {
      actionPlan: "Plano de ação",
      confirmDeleteMessage: "Tem a certeza de que deseja eliminar o KPI selecionado?",
      confirmRetireMessage: "Confirme se pretende Retirar o KPI selecionado, uma vez que este já foi publicado",
      errorRetireMsg: "Este KPI não pode ser retirado uma vez que se trata de um KPI obrigatório da TME",
      import: "Importar",
      importKpiMessage: "Importe o seu KPI da lista de KPI's da TME",
      kpi: "KPI's",
      library: "Biblioteca",
      noActionAvailable: "Nenhuma Ação disponível",
      NoKPI: "Sem KPI's disponíveis",
      publish: "Publicar",
      questionnaire: "Questionário",
      tmeKPIList: "Lista de KPI's da TME",
      toyotaCityBrussels: "TOYOTA CITY BRUSSELS",
      attachment: "Attachment",
      attachments: "Attachment(s)",
      uploadAttachment: "Upload Attachment",
      noAttachment: "No attachments available",
    },
    tmeAdminMaster: {
      ToolboxName: "Nome da toolbox",
      newToolboxName: "Novo nome",
      deleteToolbox: "Eliminar toolbox?",
      programName: "Nome do Programa",
      ToolboxName_EN: "Nome da toolbox (EN)",
      deleteTool: "Tem a certeza que deseja eliminar esta toolbox?",
      selectToolbox: "Por favor selecione uma toolbox para eliminar.",
      editToolBox: "Editar Toolbox",
      addToolbox: "Adicionar Toolbox",
      toolbox: "Por favor insira um nome para esta Toolbox.",
      addActionPlan: "Adicionar Plano de ação",
      actionPlanItem: "Plano de ação – Item #",
      add: "Adicionar",
      addActivity: "Adicionar atividade",
      additionalInformation: "Informação adicional",
      benchmark: "Valor de referência",
      cancel: "Cancelar",
      createEditActivity: "Criar / Editar ação",
      createKPI: "Criar KPI",
      delete: "Eliminar",
      download: "Download",
      edit: "Editar",
      editKPI: "Editar KPI",
      keyPerformanceIndicator: "KPI",
      kpiDefinition: "Definição de KPI",
      mandatoryKpi: "KPI obrigatório",
      save: "Guardar",
      search: "Pesquisar",
      unit: "Unidade",
      update: "Atualizar",
      validData: "Introduza dados válidos",
      hide: "Ocultar",
      show: "Mostrar",
      upload_dealer_network: "Carregar Rede de Concessionários/RTAs",
      upload_question: "Carregar Questionário",
      upload_user: "Carregar Detalhes do Utilizador",
      upload_activity: "Carregar Atividades",
      remove: "Remove",
      toyota: "Toyota",
      lexus: "Lexus",
      dueDateExceed:"Data limite ultrapassada",
      delQuestionnaire:"Delete questionnaire"
    },
    toastr: {
      activityStatus_condition: "Por favor responda a todas as questões do Questionário para poder fechar esta Atividade.",
      emptyTarget: "Please enter the NMSC target to publish the KPI",
      retailerActual: "Please enter the Retailer Actual",
      initialTargetMonthly: "Introduza p.f. os indicadores iniciais, os objetivos e pelo menos um mês de indicadores para continuar.",
      initialTarget: "Por favor insira os KPI's Iniciais e os Objetivos para continuar.",
      upload_error: "Erro durante o carregameno da Rede de Concessionários/RTAs.",
      action_item_create_warning: "Máximo de 5 registos podem ser associados ao mesmo item de Ação.",
      actionPlanStartDateValidation: "A data de conclusão não pode ser anterior à data de início",
      actionsNotClosed: "Existem Ações em progresso no Plano de Ação; feche todas as Ações antes de encerrar uma Atividade",
      activityDuplicate: "Já existe outra Atividade para a mesma Oficina e Toolbox",
      assigneeNotExist: "O Titular selecionado não foi registado no sistema",
      assigneeValidation: "Titular inválido",
      atleastOnePublish: "Selecione pelo menos um KPI para publicar",
      cannotRetireActive: "Existe um KPI obrigatório da TME ativo que não pode ser retirado ",
      completionDate: "A data de conclusão não pode ser anterior à data da primeira visita",
      confirDeletePlannedActivity: "Tem a certeza de que deseja eliminar esta Atividade planeada?",
      DateformatValidation: "O formato da data deve ser DD/MM/AAAA",
      DateValidation: "Data de Início deverá ser anterior à Data de Fim",
      dealerValidation: "Oficina inválida",
      deleteSelectedConfirm: "Tem a certeza de que deseja eliminar o KPI selecionado?",
      duplicate: "Já existe outro KPI com o mesmo nome e definição",
      modalMandatory: "Por favor introduza dados válidos em todos os campos obrigatórios",
      noPublishKpi: "Os KPI's ainda não foram publicados para a Toolbox selecionada",
      validSearchCriteria: "Introduza um critério de pesquisa válido",
      multiSelectPlaceHolder: "Inserir pelo menos 5 caractéres",
      multiSelectPlaceHolderUser: "Inserir pelo menos 3 caractéres",
      startDateValidation: "Data de Início não pode ser posterior à Data de Fim",
      fileSelectionErr: "Por favor selecione o ficheiro",
      atleastOneQuestion: "Por favor selecione pelo menos uma sub-questão a importar.",
      atleastOneSubQues: "Por favor selecione pelo menos uma sub-questão para publicar.",
      uploadErr: "Número de ficheiros com falha no carregamento:",
      uploadSuccess: "Rede de Concessionários/RTAs carregada com sucesso.",
      file_upload_success: "Ficheiro(s) carregado(s) com sucesso",
      KPIinOngoingClosedActivity: "Este KPI está presente numa ou mais Atividades em curso/fechadas. Por favor Conclua estas Atividades antes de alterar o KPI.",
      Role_Error: "A configuração do utilizador está incompleta, por favor contacte o NMSC KET Admin.",
      points_allocation_incorrect: "Alocação de pontos incorreta, por favor altere.",
      activity_deleted:"Atividade eliminada com sucesso",
      activity_certified_dealer:"O Concessionário/RTA foi certificado para a Atividade selecionada, ela não pode ser eliminada.",
      activity_submited_for_certificate_dealer:"A Atividade foi incluida no pedido de Certificação enviado para a TME, logo, o estado não pode ser alterado."
    },
    TopNav: {
      ActivityLog: "Atividades",
      ApplicationName: "Kaizen Expert Tool",
      Home: "Início",
      logout: "Terminar sessão",
      MasterDataUpload: "Carregar dados globais",
      Toolbox: "Toolbox",
      welcome: "Bem-vindo!",
      Admin: "Admin"
    },
    questionnaire: {
      addSubQuestion: "Adicionar sub-questão",
      editSubQuestion: "Editar sub-questão",
      subQuestionTitle: "Sub-questão",
      additionalInfo: "Informação adicional",
      na: "Adicionar 'Não Aplicável' como resposta possível a esta questão",
      question: "Questão",
      questionTitle: "Título da Questão",
      why: "Porquê?",
      addMainQuestion: "Adicionar Questão Principal",
      editMainQuestion: "Editar Questão Principal",
      previousQuestion: "Questão anterior",
      nextQuestion: "Próxima questão",
      what: "O quê?",
      library: "Biblioteca",
      subQuestionTable: {
        no: "Nº",
        subQuestion: "Sub-questões",
        status: "Estado",
        publishedDate: "Data de Publicação",
        notApplicable: "Não Aplicável"
      },
      links: {
        question_link_button: "Ligação",
        question_link_include_folder: "Incluir uma subpasta",
        question_link_add_link: "Ligar ficheiro(s) da Biblioteca",
        question_link_view_folder: "Ver Biblioteca"
      },
      mainQuestion: "Questões Principais Novas/Atualizadas:",
      subQuestion: "Sub-questões Novas/Atualizadas:",
      retiredQuestion: "Sub-questões Retiradas:",
      libraries: "Ficheiros Novos/Atualizados na Biblioteca:",
      noFiles: "Sem ficheiros disponíveis."
    },
    library: {
      selectFile: "Por favor selecione pelo menos um ficheiro.",
      fileSelected: "Ficheiros selecionados",
      publish_library_file: "Por favor selecione pelo menos um ficheiro para publicar",
      library_files_deleted: "Tem a certeza que deseja eliminar este ficheiro?",
      library_files_linked_deleted: "Tem a certeza que deseja eliminar este ficheiro e os links criados no Questionário?",
      max_size_error: "O tamanho do(s) ficheiro(s) excede o limite de 1GB por carregamento.",
      empty_file_desc: "Por favor insira uma descrição do ficheiro",
      file_not_selected: "Por favor selecione o(s) ficheiro(s) para carregar (max. 1GB)",
      file_type_error: "Este tipo de ficheiro não é permitido.",
      file_name_error: "Caractere inválido no nome do ficheiro.",
      file_name_lenght_error: "O nome do ficheiro pode ter um máximo de 50 caracteres.",
      addFolder: "Adicionar Pasta",
      editFolder: "Editar Pasta",
      currentFolder: "Nome da Pasta",
      parentFolder: "Nome da Pasta de Origem",
      emptyFolderName: "O Nome da Pasta não pode estar vazio.",
      invalidFolderName: "O nome da pasta não pode conter '/'.",
      folder_created: "A Pasta foi criada com sucesso.",
      folder_updated: "A Pasta foi atualizada com sucesso.",
      folder_not_found: "Pasta não encontrada",
      lib_invalid_toolbox: "Toolbox selecionada inválida",
      lib_folder_already_exists: "Esta Pasta já existe.",
      lib_folder_deleted: "Esta Pasta foi eliminada com sucesso.",
      library_files_published: "Os ficheiros foram Publicados.",
      addFile: "Adicionar Ficheiro",
      editFile: "Editar Ficheiro",
      fileName: "Nome do Ficheio",
      upload: "Upload",
      desc: "Descrição",
      folderCreationErr: "Hierarquia limitada a dois níveis.",
      subFolderWarning: "Por favor selecione uma subpasta para adicionar ficheiros.",
      deleteWarning: "Por favor selecione uma pasta para eliminar.",
      beforeAfterNotSelected: "Indique por favor se a evidência é antes ou depois do Kaizen",
    },
    chapter: {
      chapter_exist: "Este Capítulo já existe.",
      chapter_saved: "Capítulo Guardado",
      chapter_cannot_delete: "Este Capítulo não pode ser eliminado, pois as sub-questões já foram publicadas.",
      failed: "Falhou!",
      chapter_deleted: "Capítulo Eliminado",
      chapter_nonEmpty: "Por favor crie um nome do capítulo.",
      addchapter: "Adicionar capítulo",
      editChapter: "Editar capítulo",
      DELETE_SUCC:"Questionnaires deleted successfully.",
      DELETE_SUCC_LIB:"Libraries deleted successfully.",
      DELETE_ERR : "Something Went wrong."
    },
    evaluation: {
      attachments: "Anexos",
      comments: "Comentários",
      evidence: "Evidência",
      evidences: "Evidência(s)",
      yes: "Sim",
      no: "Não",
      na: "N/A",
      uploadEvidence: "Carregar evidência",
      fileUpload: "Por favor selecione o(s) ficheiro(s) para carregar (max. 1GB)",
      who: "Quem?",
      answer: "Responder",
      comment: "Comentário",
      date: "Data",
      submit: "Submeter",
      commentMsg: "Por favor insira os comentários.",
      noCommentAvailable: "Sem comentários disponíveis.",
      uploadSuccessMsg: "Ficheiro(s) carregado(s) com sucesso",
      uploadErrorMsg: "Número de ficheiros com falha no carregamento:",
      fileWithSameName: "Já existe um ficheiro com o mesmo nome.",
      noEvidence: "Sem evidências disponíveis",
      createAction: "Criar item de Ação",
      chapterName: "Nome do capítulo",
      questionNo: "Nº Questão",
      when: "Quando?",
      noRecords: "Sem registos disponíveis.",
      kaizenPoints: "Pontos Kaizen",
      renameSuccessMsg: "File has been renamed successfully",
      deleteSuccessMsg: "File has been deleted successfully",
      question: "Questão",
      before:"Before",
      after:"After",
      beforeAfterLabel:"Esta evidência é antes ou depois do Kaizen?",
      move:"Mover",
      moveMsg: "Os ficheiros foram movidos com sucesso",
      noToYesMsg:"Previous answer was NO"
    },
    WALK_AROUND_CHECK: "Processo de Receção",
    ketProgram: {
      TSM: "TSM/LSM",
      BRiT: "BRiT RI",
      TSM_BNP: "Chapa & Pintura",
      TSM_USED_CAR: "Toyota Usados de Confiança",
      value_chain: "Cadeia de Valor",
      brit: "BRiT",
      ea: "EA",
      per:"Performance",
      nettrans:"Network Transformation",
    },
    dashboard: {
      notice_board: "Quadro de Informações",
      kpi_target_not_set: "Targets dos KPI's não definidos",
      kpi_initial_not_set: "KPI's iniciais não definidos",
      kpi_monthly_not_set: "KPI's mensais não definidos",
      kpi_below_target: "Existem KPI's abaixo do target",
      activation: "Utilizadores Ativos",
      users: "Utilizadores",
      nmscs: "NMSC's",
      activeRetailer: "Oficinas ativas",
      certificationRate: "Taxa de Certificação",
      reCertificationRate: "Taxa de Re-Certificação",
      activityDue: "Atividades dentro do prazo",
      avgdays: "Média de Dias/Toolbox",
      overDueAct: "Atividades fora do prazo",
      actionItem: "Ações",
      bussResult: "Resultados de Negócio",
      toolboxImpl: "Implementação da Toolbox",
      statusCompletedL:"Status - Completed, Progress - ",
      statusClosedL:"Status - Closed, Progress - ",
      statusOngoingL:"Status - Ongoing, Progress - ",
      recentUpdates: "Atualizações recentes",
      rolloutTopNmsc: "Resultados Iniciais - Top NMSC's",
      bestPractice: "Quadro de Melhores Práticas",
      availableKpi: "KPI's disponíveis",
      selectedKpi: "KPI's selecionados",
      dealerBestPractice: "Quadro de boas práticas das Oficinas",
      kpiList: "Lista de KPI's",
      go: "Pesquisar",
      nodata: "Sem dados disponíveis",
      businessResultTop: "Resultados de Negócio - Top Oficinas",
      businessResultNMSC: "Resultados de Negócio - Top NMSC's"


    },
    dealerCertification: {
      dealerCertification: "Certificação da Oficina",
      readyForCertification: "Pronto para Certificação",
      certification: "Certificação",
      submittedOn: "Submetido",
      certificateDate: "Data de Certificado",
      reCertificateDate: "Data de Re-Certificado",
      toolboxDetails: "Detalhes da Toolbox",
      approve: "Aprovar",
      reject: "Rejeitar",
      pending: "Pendente",
      region: "Região"



    },

    uploadBulk: {
      browse: "Browse",
      executionId: "Id da Execução",
      uploadedOn: "Carregado em",
      lastUpdatedOn: "Última atualização em",
      noDataTable: "No data to view",
      kpiUpload: "Carregamento de KPI's",
      action: "Ação",
      processed: "Processado",
      error: "Erro",
      received: "Received",
      createActivity: "Criar Atividades",
      fileUpload: "File Upload"
    },
    userManagement: {
      userManagement: "Gestão de utilizadores",
      createUser:"Create User",
      editUser:"Edit User",
      viewUser:"View User",
      nmscId:"NMSC ID",
      tarsId:"TARS ID",
      firstName:"Primeiro nome",
      lastName:"Último nome",
      emailId:"E-mail",
      britDealerChamp:"Brit Dealer Champion",
      invalidEmail:"Please enter valid E-Mail ID",
      invalidTars:"Please enter valid TARS ID",
      invalidFirstName:"Please enter valid First Name",
      invalidLastName:"Please enter valid Last Name",
      user_created:"User Created",
      user_updated:"User updated",
      duplicate_tarsuser:"This TARS id is already registered in the KET database.",
      user_define_in_tool:"User is defined as Business owner in Active Business Function. Hence, could not be made Inactive",
      user_refer_on:"User is referenced in Ongoing activity",
      businessArea: "Área de Negócio",
      valueChainAndBRiT: "Cadeia de Valor & BRiT",
      only:"Only",
      optionText:"Please select at least one of the two options below",
      valueChainOnly: "Apenas Cadeia de Valor",
      britOnly: "Apenas BRiT"
    },
    UserManagement: {
      nmscId: "NMSC ID",
      tarsId: "TARS ID",
      firstName: "Primeiro nome",
      lastName: "Último nome",
      emailId: "E-mail",
      languageCode: "Código do idioma",
      ACTIVE: "Active",
      INACTIVE: "Inativo",
      addUser: "Adicionar utilizador",
      Assign: "Atribuir",
      List: "Lista",
      user: "Utilizador",
      error_message: "Ocorreu um erro, por favor tente novamente mais tarde.",
      role: "Perfil do utilizador",
      standardMessage: "No User Found",
      dealerCode: "Código de Oficina",
      a2d: "A2D UUID",
      britReady: "Concessionário participou no BRiT Ready.",
      britLive: "Concessionário participou no BRiT Live.",
      britJapan: "Concessionário participou no BRiT Discovery Japão.",
      britEss:"O Concessionário/RTA atualizou o Plano de Ação do EES",
      addDealer: "Adicionar Oficina (a partir do A2D)",
      noDealer: "Oficina(s) não encontrada(s).",
      dealerUuid: "Código UUID da Oficina (A2D)",
      language: "Idioma",
      L3: "L3",
      L4: "L4",
      L5: "L5",
      L6: "L6",
    },
    CeaKpi: {
      survey: "Survey",
      evaluationItem: "Evaluation Item",
      kpiMetric: "KPI Metric",
      stratagicPillar: "Strategic Pillar",
      addKPI: "Add KPI",
      edit: "Edit",
      delete: "Delete",
    },
    dealerManagement: {
      invalidUuid: "Por favor insira um UUID A2D válido.",
      invalidDealerName: "Por favor insira um nome de Oficina válido.",
      invalidCity: "Por favor insira uma localização válida.",
      invaliDealerCode: "Por favor insira um código de Oficina válido.",
      existingUuid: "O código UUID do A2D já existe na base de dados do KET.",
      updateUuid: "Atualizar código UUID A2D",
      newUuid: "Novo código UUID A2D",
    },
    networkManagement: {
      networkManagement: "Gestão da Rede",
    },
    kpiEntry: {
      status: 'Status',
      evaluationYear: 'Evaluation Year',
      frequency: 'Frequency',
      typeOfTarget: 'Type of Target',
      target: 'Target',
      unit: 'Unit',
      standardMessage: 'No Kpi Data Found',
      srNo: 'S.No.',
      kpi: 'KPI',
      actuals: 'Actuals',
      actuals1 : "Actuals should not be more than allotted points. Please re-enter",
      subPillar: 'Sub Pillar',
      businessFunc: 'Business Function',
      brand: 'Brand',
      inputTxt:"Inputs"
    },
    reachItDashboard:{
      topNavReachIT:"ReachIT",
      downloads:"Downloads",
      historyFiles:"History of previous files",
      reachText1:"Welcome to the REACH IT homepage. The TME Environmental Management team will use this page to share relevant  information related to SCIP : Substances of Concern In articles as such or in complex objects (Products) established under the Waste Framework Directive (WFD) in order for you to fulfill your duty to process with your SCIP simplified notification.",
      reachText2:"In case of query, please contact the TME Environmental Management team via below link.",
      reachText3:"In case of query, please contact your NMSC.",
      envMngt:"Environmental Management",
      fileStatistics: "File Downloaded Statistics",
      Downloaded: "Downloaded",
      not: "Not",
      exportToExcel:"Export To Excel"
    },
    usedCarComment:{
      ongoing:"Ongoing",
      completed:"Completed",
      commentHistory:"Monthly Comments History",
      addComment:"Add Monthly Comment",
      month:"Month",
      comment:"Comment",
      username:"Username",
      email:"Email",
      createdDate:"Created Date"
    },
  }
};

export default translationsPT;
