import { useEffect } from 'react';
import React, { Component }  from 'react';
import { completeAuthentication, setToken, setUserRoles,getToken } from "./AuthService"
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import {
  getUserDetails,
  updateGdprFlag,
  fetchAccessControlList,userInfoDetails
} from "../actions/loginAction";

export const getHeaders = () =>{
  let headers = {
    "Authorization": `Bearer ` + getToken()
  };
    headers["Content-type"] = "application/json";
    headers["Accept"] = "application/json";
  return headers;
}

const CallbackPage = props => {
  const { dispatch } = props;
  //const history = useHistory();
  const GET_USER_DATA = '/users/me';

  const userDto = {
              username: 'KET-TSP.Auditor@TMME ',
              roles: ['KET-TSP Auditor'],
              lang: 'en',
              nmsc: 'tme',
              role: [{label:'KET-TSP Auditor' , value :'KET-TSP Auditor'}]
          }

  const onSuccessGetUserData=(user,response)=>{
      let uContext = response;
      uContext['user'] = user;
      //dispatch(setUserContext(uContext));
      setUserRoles(JSON.stringify(response.data))
      //setUserTeamsAndRoles(response)
      //dispatch(setUserData(response.data));
      let userRolePermissions = response.data && response.data.loginDtoSet ? response.data.loginDtoSet : [];
      let setRoleSelected = [];
    }
    const onErrorGetUserData=(error,user)=>{
      let message=error && error.response && error.response.data && error.response.data.message?error.response.data.message:"error";
      //history.push({pathname:ROUTE_UNAUTHORIZED_PAGE,state:{unAuthorizedMessage:message}});
    }

    


  useEffect(() => {
    completeAuthentication().then(user => {
        console.log("completeAuthentication");
        setToken(user.id_token);
      console.log("id_token");
      console.log(user.id_token);
      if(user.id_token==undefined || user.id_token==null ){
        props.history.push("/login");
      }
      //props.dispatch(getCallWithoutReducer(GET_USER_DATA, null, onSuccessGetUserData, onErrorGetUserData,user))
      //this.props.dispatch(getUserDetails());
      //props.history.push("/login");
      props.dispatch(getUserDetails());
    
    })
  }, []);
  return (<div>Redirecting</div>);
}



const mapStateToProps = (state) => {
  return {
    userContext: state.loginReducer.userContext
  };
};
export default withRouter(connect(mapStateToProps)((CallbackPage)));