const translationsBS = {
  bs: {
    Policy: {
      HeaderMsg: "Obaveštenje o privatnosti za obradu vaših ličnih podataka u okviru Kaizen Expert alata",
      Message1: "Ova Izjava o privatnosti se odnosi na obradu vaših ličnih podataka u okviru Kaizen Expert alata. Uvijek se mora pročitati zajedno s Općom/Opštom politikom zaštite privatnosti i osobnih/ličnih podataka kompanije Toyota, u kojoj su opisani opći/opšti modaliteti obrade vaših osobnih/ličnih podataka i koje morate prihvatiti da biste mogli nastaviti dalje.",
      Message2: "Toyota Motor Europe NV / SA, sa sjedištem Avenue du Bourget / Bourgetlaan 60, 1140 Brisel, Belgija, kao kontrolor podataka, prikuplja vaše lične/osobne podatke (kao što je navedeno u nastavku) za svrhe opisane u nastavku.",
      Message3: "Koji će vaši lični/osobni podaci biti prikupljeni?",
      Message4: "Prikupićemo sljedeće kategorije vaših osobnih/ličnih podataka u odnosu na Kaizen Expert alat:",
      Table1: {
        tr1: "Kategorija / e osobnih podataka:",
        tr2: "Korisnički ID",
        tr3: "Korisničko ime (puno ime)",
        tr4: "Brand (Toyota, Lexus)",
        tr5: "Preferirani jezik",
        tr6: "Povezana organizacija"
      },

      Message5: "Svrha i pravne osnove obrade vaših ličnih podataka.",
      Message6: "Mi ćemo obraditi vaše lične podatke u svrhe i na pravnim osnovama opisanim u nastavku:",
      Table2: {
        tr1: {
          th1: "Svrha:",
          th2: "Pravna osnova:"
        },
        tr2: {
          td1: "KET - 1: Performanse trgovaca Kaizen",
          td2: "Legitimni interes kontrolora podataka"
        },
        tr3: {
          td1: "KET - 2: IT podrška",
          td2: "Legitimni interes kontrolora podataka"
        }
      },
      Message7: "Trajanje čuvanja vaših ličnih podataka",
      Message8: "Vaši lični podaci će se čuvati za vrijeme koje je navedeno u nastavku:",
      Table3: {
        tr1: {
          th1: "Period zadržavanja:",
          th2: "Kategorija / e osobnih podataka:"
        },
        tr2: {
          td1: "Svi lični podaci kao što je gore opisano će se čuvati najviše 5 godina + 1 mjesec od dana konačnog zatvaranja Kaizen Expert Tool.",
          td2: "Ime korisnika, Prezime, Preference jezika, organizacija"
        }
      },
      Message9: "Opciono: U slučaju otkrivanja / prijenosa vaših osobnih podataka u zemlje izvan Europskog ekonomskog prostora (EEA)",
      Message10: "Vaši lični podaci će biti dostupni primaocima u zemljama izvan EEA, kao što je navedeno u nastavku:",
      Table4: {
        tr1: {
          td1: "Identitet",
          td2: "Zemlja",
          td3: ""
        },
        tr2: {
          td1: "Primalac (e) koji nije EEA",
          td2: "Infosys",
          td3: "India"
        }
      },
      Message11: "Ovim potvrđujem da sam shvatio ovo obaveštenje; Pročitao sam i prihvatio politiku o privatnosti i zaštiti osobnih podataka General Toyota."
    },
    ACCESSORIES: "Dodatna oprema",
    activity: {
      Assignee: "Dodijeljeno",
      CompletionDate: "Datum završetka",
      Dealer: "Ovlašteni trgovac",
      FirstVisit: "Prva posjeta",
      kpi: {
        kpi: "KPI moraju biti brojevi",
        kpiLength: "Maksimalna dužina za KPI je 10 znakova",
        mandatory: "Unesite važeće podatke u sva obavezna polja",
        mandatoryTargets: "Unesite sve KPI-e",
        target: "Target treba biti iskazan samo u brojevima",
        targetLength: "Maksimalna dužina targeta je 10 znakova"
      },
      ReminderOn: "Podsjetnik On",
      statusDD: "Status",
      Toolbox: "Modul",
      activityCreation: "Pokrenut je postupak stvaranja aktivnosti.",
      activityCreated: "Stvorene su aktivnosti"
    },
    DPOK: "Skladište",
    EFFICIENCY: "Efikasnost",
    FIX_IT_RIGHT: "Popravak iz prve",
    footer: {
      message: "Obavezna polja"
    },
    FOUNDATION: "Osnova",
    MAINTENANCE_REMINDERS: "Servisni podsjetnik",
    EMPLOYEE_ENGAGEMENT: "Employee Engagement",
    CUSTOMER_CENTRICITY: "Customer Centricity",
    TOYOTA_WAY: "Toyota Way",
    BUSINESS_SUSTAINABILITY: "Business Sustainability",
    FUNDAMENTALS: "Osnove",
    FIX_IT_RIGHT_BnP: "Fix it Right (B&P)",
    UTILIZATION_AND_EFFICIENCY: "Utilization and Efficiency",
    CUSTOMER_EXPERIENCE: "Customer Experience",
    UPSELLING_OPPORTUNITIES: "Upselling Opportunities",
    MANAGING_CAPACITY: "Managing Capacity",
    VEHICLE_TRADE_IN: "Vehicle Trade-In",
    STOCK_MANAGEMENT: "Stock Management",
    REFURBISHMENT: "Refurbishment",
    months: {
      apr: "Apr",
      aug: "Aug",
      dec: "Dec",
      feb: "Feb",
      jan: "Jan",
      jul: "Jul",
      jun: "Jun",
      mar: "Mar",
      may: "Maj",
      nov: "Nov",
      oct: "Okt",
      sep: "Sep"
    },
    others: {
      back: "Nazad",
      closed: "Zatvoreno",
      completed: "Završeno ",
      countryLanguageCode: "Država / Jezični kod ",
      createActivity: "Kreiraj aktivnost",
      createdDate: "Datum kreiranja",
      dataUneditableNote:
        "Napomena: Jednom sačuvani podaci se ne mogu uređivati. Da biste izmijenili podatke, obratite se administratoru NMSC.",
      date: "Datum",
      downloadQuestionnaire: "Preuzmi upitnik",
      editActivity: "Uredi aktivnost",
      initialHelpText: "Početno KPI mjerenje treba biti prosječna vrijednost prethodnih šest punih mjeseci",
      inProgress: "U toku",
      name: "Ime i prezime",
      next: "Sljedeće",
      noActivities: "Nema pronađenih aktivnosti",
      noResults: "Nema rezultata",
      of: "od",
      ongoing: "U procesu",
      open: "Otvoreno",
      page: "Stranica",
      planned: "Planirano",
      problemDescription: "Opis problema",
      signature: "Potpis",
      select: "Odaberi",
      bulkAction: "Bulk Action",
      bulkActions: "Bulk Actions"
    },
    ServerMessage: {
      error_message: "Došlo je do pogreške tokom preuzimanja",
      no_published_subquestion: "Objavite Upitnik za ovaj alatni okvir kako biste započeli ovu aktivnost.",
      activity_upload_error_msg: "Pogreška prilikom prijenosa pojedinosti o aktivnosti.",
      action_plan_created: "Akcioni plan uspješno kreiran",
      action_plan_deleted: "Akcioni plan uspješno izbrisan",
      action_plan_saved: "Akcioni plan uspješno spašen",
      activity_kpi_saved: "KPI aktivnosti uspješno spašen",
      master_kpi_confirm_delete: "Da li ste sigurni da želite izbrisati označeni KPI?",
      master_kpi_created: "Glavni Ključni Parametar Uspješnosti uspješno kreiran",
      master_kpi_deleted: "Glavni Ključni Parametar Uspješnosti je obrisan",
      master_kpi_not_found: "Glavni Ključni Parametar Uspjepšnosti nije pronađen",
      master_kpi_published: "Odabrani glavni Ključni Parametri Uspješnosti su uspješno objavljeni",
      master_kpi_retired: "Glavni Ključni Parametar Uspješnsoti je arhiviran",
      master_kpi_saved: "Glavni Ključni Parametar Uspješnosti uspješno sačuvan",
      subquestion_created: "Pitanje je uspješno sačuvano.",
      subquestion_updated: "Podpitanje je uspješno ažurirano.",
      subquestionnaire_not_found: "Podispitanje nije pronađeno.",
      subquestion_retired: "Podpitanje je povučeno.",
      subquestion_deleted: "Podispit je izbrisan.",
      subquestion_published: "Pod-pitanja su uspješno objavljena.",
      mainquestion_created: "Kreiran je glavni upitnik.",
      mainquestion_deleted: "Glavni upitnik je izbrisan.",
      mainquestion_updated: "Glavno pitanje je ažurirano.",
      duplicateMsg: "Dvostruki zapisi u Excel datoteci.",
      deleteErr: "Greška prilikom brisanja datoteke",
      toolbox_deleted: "Toolbox uspješno je izbrisana",
      toolbox_already_exists: "Ovaj Toolbox već postoji.",
      toolbox_saved: "Toolbox spremljen",
      toolbox_created: "Toolbox stvoren",
      toolbox_not_found: "Toolbox nije pronađen",
      published_content_found: "Ovaj Toolbox se ne može izbrisati jer ima nekog objavljenog sadržaja",
      dealers_already_mapped: " Dealers already mapped ",
      dealer_already_mapped: " Dealer already mapped ",
      dealer_added: "  Dealer added. ",
      dealers_added: "  Dealer added. ",
      total_dealers_mapped_now: "Total dealers mapped now are ",
      no_dealer_available: "No Dealer available for this assign criteria",
      proper_dealer_selected: "Proper dealer shall be selected",
      user_referenced_ongoing_activity: "User is referenced in Ongoing activity/activities. ",
      want_to_remove_mapping: "Are you sure you want to remove mapping?",
      user_mapping_removed: "Selected dealers unmapped successfully",
      dealer_closed_in_A2D: "Dealer not active in A2D",
      network_level_not_found: "Please select valid Network levels",
      duplicate_dealer_uuid: "Dealer already available",
      dealer_created: "Dealer Created",
      dealer_updated: "Dealer Updated",
      dealer_not_present: "Dealer not present in A2D",
      dealer_uuid_other_nmsc: "Please Enter Dealer Uuid for Same NMSC",
      target_kpi_updated: "The KPI's status have been updated successfully.",
      target_updated: "The KPI's target have been updated successfully.",
      kpi_launched: "The KPI's bulk launch successfully.",
      targetUpdate:"Application doesn't allow target to be modified for accepted status.",
    },
    standardMessages: {
      delete: "Jeste li sigurni da želite izbrisati?",
      addActionPlanNotAllowed: "Akcijski plan ne može se izraditi ili urediti nakon što je aktivnost zatvorena, dovršena ili odbijena.",
      addToolboxNotAllowed: "Cannot create more than 19 toolbox",
      britRejectMsg: "Jeste li sigurni da želite odbiti ovu aktivnost? Promjene nisu moguće nakon odbijanja.",
      rejectMsg: "Jeste li sigurni da želite odbiti ovu aktivnost? Promjene nisu moguće nakon odbijanja.",
      selectNMSCTab: "Molimo odaberite NMSC jezik za objavljivanje",
      all: "Sve",
      deleteConfirm: "Jeste li sigurni da želite obrisati odabrani KPI?",
      no: "Ne",
      retireConfirm: "Jeste li sigurni da želite obrisati ovaj KPI? Ovaj KPI se neće moći ponovno aktivirati nakon brisanja",
      warning: "Upozorenje",
      yes: "Da",
      saveDataConfirmation: "Jeste li sigurni da želite sačuvati podatke?",
      saveKPIWarning: "Napomena: Sačuvani podaci se ne mogu uređivati. Da biste izmijenili podatke, obratite se administratoru NMSC KET",
      addKpiNotAllowed: "Sljedeći mjesec za unos KPI-a može se dodati nakon završetka tekućeg mjeseca.",
      activityLaunch: "Jeste li sigurni da želite pokrenuti ovu aktivnost? Nakon pokretanja, Aktivnost se ne može vratiti natrag u Planirani status ili izbrisati.",
      deleteConfirmSubQuestionNaire: "Jeste li sigurni da želite izbrisati odabrano pod-pitanje?",
      retireConfirmSubQuestionNaire: "Jeste li sigurni da želite povući odabrano pod-pitanje?",
      deleteConfirmMainQuestion: "Molimo vas da potvrdite da li želite da obrišete glavno pitanje i njegova povezana pitanja.",
      deleteConfirmChapter: "Jeste li sigurni da želite izbrisati poglavlje?",
      deleteLibraryFolder: "Jeste li sigurni da želite izbrisati ovu mapu?",
      deletePublishedFolder: "Neke datoteke unutar te mape mogu biti povezane s upitnikom. Jeste li sigurni da želite trajno izbrisati ovu podmapu i ukloniti veze iz upitnika?",
      importQuestion: "Jeste li sigurni da želite uvesti ovo pod-pitanje? Sadašnje podispitanje bit će zamijenjeno.",
      noQuestion: "Nema dostupnih pitanja.",
      MandatoryWarning: "Ispunite sva obavezna polja za sve lokalne jezike.",
      certificationSubmission:
        "Toolbox je predat TME-u na certificiranje distributera",
      sameToolBox: "Ne možete poslati isti Toolbox više od jednom. Odaberite drugi Toolbox.",
      deleteConfirmDealer: "Are you sure you want to delete this dealer ?",
      updateVcConfirmDealer: "All Activities created for this dealer in 'Value Chain' will be removed. Are you sure you want to continue?",
      updateBritConfirmDealer: "All Activities created for this dealer in 'BRiT' will be removed. Are you sure you want to continue?",
      completed_activity_delete:"Data cannot be retrieved once deleted. Are you sure you want to delete ?",
      deleteAllQuestionary: "Are you sure you want to delete the entire Questionnaire?",
      deleteAllLibraries: "Are you sure you want to delete all folders and files in the Library?",
      selectAll: "Označiti sve",
      unSelectAll: "Poništi odabir svih",
      select:"Označi...",
      warningMsg:"U ovoj aktivnosti postoje otvoreni akcijski planovi. Želite li dovršiti aktivnost?"
    },
    status: {
      published: "Objavljeno",
      retired: "Arhivirano",
      saved: "Sačuvano",
      rejected: "Odbijeno",
      open: "Otvoreno",
      closed: "Zatvoreno",
      close: "Zatvoreno",
      ongoing: "U procesu",
      completed: "Završeno",
      complete: "Završeno",
      planned: "Planirano",
      overdue: "Zakašnjelo",
      due: "Rok",
      pending: "Pending",
    },
    Rejected: "Odbijeno",
    Open: "Otvoreno",
    Closed: "Zatvoreno",
    Close: "Zatvoreno",
    Ongoing: "U procesu",
    Completed: "Završeno",
    Complete: "Završeno",
    Planned: "Planirano",
    tabDetails: {
      noActionAvailable: "Nema dostupnih radnji",
      activeDealer: "Dealer is active.",
      dealerDeleted: "Dealer is deleted."
    },
    Table: {
      action: "",
      activity: "Aktivnost",
      activityCompletionDate: "Datum završetka aktivnosti",
      activityCompletionDateFrom: "Datum završetka aktivnosti od",
      activityCompletionDateTo: "Datum završetka aktivnosti do",
      activityStartDate: "Datum početka aktivnosti",
      activityStartDateFrom: "Datum početka aktivnosti od",
      activityStartDateTo: "Datum početka aktivnosti do",
      assignedTo: "Dodijeljeno",
      assignee: "Dodijeljeno",
      benchmark: "Cilj",
      city: "Grad",
      completedBy: "Završio ",
      completionBy: "Završeno od strane",
      completionDate: "Datum završetka",
      counterMeasure: "Protumjera",
      country: "Zemlja",
      createdBy: "Kreirao",
      dealer: "Ovlašteni trgovac",
      dealerNameAD: "Dealer name (A2D)",
      dealerName: "Naziv distributera",
      definition: "Definicija",
      description: "Opis",
      dueDate: "Rok",
      endDate: "Datum završetka",
      firstVisit: "Prva posjeta",
      followup: "Kontrolni servis",
      franchise: "Franšiza",
      history: "Historija",
      id: "ID",
      initial: "Početno",
      kpi: "KPI - Ključni pokazatelji uspješnosti",
      launch: "Pokreni",
      mandatory: "Obavezno",
      nmsc: "NMSC",
      problem: "Problem",
      publishedOn: "Objavljeno",
      questionReference: "Referenca za pitanje",
      reference: "Referenca",
      reminderOn: "Podsjetnik On",
      score: "Ocjena",
      startDate: "Datum početka",
      status: "Status",
      target: "Target",
      toolbox: "Toolbox",
      view: "Prikaži",
      kpiResult: "KPI rezultati",
      nmscTarget: "NMSC Target",
      penEbenchmark: "PanE Benchmark",
      kpiField: "KPI",
      location: "Lokacija",
      ActionItemReport: "Stavke akcije u tijeku",
      certification: "Status certifikata",
      noOfToolbox: "Broj završenih modula",
      program: "Program",
      certificationDate: "Datum ovjere",
      activityId : 'Activity ID',
      approvedBy:"Approved By",
      kpis:"KPIs",
      lastmonthKpis:"Last Month KPIs",
      questionPercent:"Completion %",
      actionPlan: "Plan aktivnosti",
      monthlyKpi:"Monthly KPIs",
      initialKpi:"Initial KPIs",
      targetKpi:"Target KPIs",
    },
    tabsDetails: {
      actionPlan: "Plan aktivnosti",
      confirmDeleteMessage: "Jeste li sigurni da želite obrisati odabrani KPI?",
      confirmRetireMessage: "Potvrdite ako želite arhivirati odabrani KPI jer je već objavljen",
      errorRetireMsg: "Ovaj KPI se ne može brisati jer je aktivan obavezni KPI TME",
      import: "Uvezi",
      importKpiMessage: "Uvezite vaš Ključni Parametar Uspješnosti sa liste glavnih TME parametara",
      kpi: "Ključni pokazatelji uspješnosti",
      library: "Biblioteka",
      noActionAvailable: "Nema dostupnih radnji",
      NoKPI: "Nema dostupnih Ključnih Parametara Uspješnosti",
      publish: "Objavi",
      questionnaire: "Upitnik",
      tmeKPIList: "Spisak glavnih TME parametara uspješnosti",
      toyotaCityBrussels: "TOYOTA CITY BRUSSELS",
      attachment: "Attachment",
      attachments: "Attachment(s)",
      uploadAttachment: "Upload Attachment",
      noAttachment: "No attachments available",
    },
    tmeAdminMaster: {
      ToolboxName: "Naziv modula",
      newToolboxName: "Novi naziv",
      deleteToolbox: "Želite li izbrisati modul?",
      programName: "Naziv programa",
      ToolboxName_EN: "Naziv modula (EN)",
      deleteTool: "Jeste li sigurni da želite izbrisati ovaj alatni modul?",
      selectToolbox: "Odaberite modul za brisanje.",
      editToolBox: "Uređivanje modula",
      addToolbox: "Dodaj modul",
      toolbox: "Unesite naziv modula",
      addActionPlan: "Dodati Plan aktivnosti",
      actionPlanItem: "Plan akcije - Stavka #",
      add: "Dodati",
      addActivity: "Dodaj aktivnost",
      additionalInformation: "Dodatne informacije",
      benchmark: "Cilj",
      cancel: "Otkaži",
      createEditActivity: "Kreiraj/uredi aktivnost",
      createKPI: "Kreiraj Ključne Parametre Uspješnosti",
      delete: "Obriši",
      download: "Preuzmi",
      edit: "Uredi",
      editKPI: "Uredi Ključne Parametre Uspješnosti",
      keyPerformanceIndicator: "Ključni pokazatelj uspješnosti",
      kpiDefinition: "Definicija Ključnih Parametara Uspješnosti",
      mandatoryKpi: "Obavezni Ključni Parametri Uspješnosti",
      save: "Sačuvaj",
      search: "Pretraži",
      unit: "Jedinica",
      update: "Ažuriraj",
      validData: "Unesite važeće podatke",
      hide: "Sakrij",
      show: "Prikaži",
      upload_dealer_network: "Učitaj mrežu trgovaca",
      upload_question: "Učitaj Upitnik",
      upload_user: "Učitaj detalje korisnika",
      upload_activity: "Učitaj Aktivnosti",
      remove: "Remove",
      toyota: "Toyota",
      lexus: "Lexus",
      launch: "Launch",
      close: "Close",
      generate: "Generate",
      dueDateExceed:"Rok je prekoračen",
      delQuestionnaire:"Delete questionnaire"
    },
    toastr: {
      activityStatus_condition: "Molimo odgovorite na sva pitanja kako biste mogli zatvoriti ovu aktivnost.",
      emptyTarget: "Please enter the NMSC target to publish the KPI",
      retailerActual: "Please enter the Retailer Actual",
      initialTargetMonthly: "Unesite početno mjerenje KPI parametara, ciljeve KPI parametara i najmanje 1 mjesec mjerenja za nastavak.",
      initialTarget: "Unesite početno mjerenje KPI i ciljeve KPI da biste nastavili.",
      upload_error: "Pogreška prilikom učitavanja mreže distributera.",
      action_item_create_warning: "U jednoj stavci aktivnosti dopušteno je spojiti najviše pet zapisa.",
      actionPlanStartDateValidation: "Datum završetka ne može biti prije datuma početka",
      actionsNotClosed: "Plan akcije ima radnje u toku; zatvorite sve radnje prije zatvaranja aktivnosti",
      activityDuplicate: "Već postoji aktivnost za istog distributera i komplet alata",
      assigneeNotExist: "Odabrana osoba nije registrovana u sistemu",
      assigneeValidation: "Nevažeća osoba",
      atleastOnePublish: "Odaberite najmanje jedan KPI za objavljivanje",
      cannotRetireActive: "Ovo je aktivni obavezni KPI TME koji se ne može povući ",
      completionDate: "Datum završetka ne može biti prije datuma prve posjete",
      confirDeletePlannedActivity: "Jeste li sigurni da želite obrisati ovu planiranu aktivnost?",
      DateformatValidation: "Datum treba biti u formatu DD/MM/GGGG",
      DateValidation: "Datum početka mora biti prije datuma završetka",
      dealerValidation: "Nevažeći distributer",
      deleteSelectedConfirm: "Jeste li sigurni da želite obrisati odabrani KPI?",
      duplicate: "Već postoji KPI sa istim imenom i definicijom",
      modalMandatory: "Unesite važeće podatke u sva obavezna polja",
      noPublishKpi: "KPI još nisu objavljeni za odabrani komplet alata",
      validSearchCriteria: "Unesite važeće kriterije za pretraživanje",
      multiSelectPlaceHolder: "Unesite najmanje 5 znakova",
      multiSelectPlaceHolderUser: "Unesite najmanje 3 znakova",
      startDateValidation:
        "Datum početka ne može biti veći od datuma završetka",
      fileSelectionErr: "Molimo prvo odaberite datoteku",
      atleastOneQuestion: "Odaberite barem jedno pod-pitanje za uvoz.",
      atleastOneSubQues: "Odaberite najmanje jedno potpitanje za objavljivanje.",
      uploadErr: "Broj datoteka koje nije moguće učitati:",
      uploadSuccess: "Distribucijska mreža uspješno je učitana.",
      file_upload_success: "Datoteke su uspješno prenesene.",
      KPIinOngoingClosedActivity: "Ovaj KPI prisutan je u jednoj ili više tekućih / zatvorenih aktivnosti. Dovršite ove aktivnosti prije bilo kakve promjene KPI-a.",
      Role_Error: "The user setup is incomplete, please contact the NMSC KET Admin.",
      points_allocation_incorrect: "Points allocation is incorrect. Please modify.",
      activity_deleted:"Activity deleted successfully",
      activity_certified_dealer:"Dealer certified for the selected Activity, hence cannot be deleted",
      activity_submited_for_certificate_dealer:"The status of toolbox cannot be changed as request for certification has been sent to TME"
    },
    TopNav: {
      ActivityLog: "Aktivnosti",
      ApplicationName: "Kaizen Expert Tool",
      Home: "Početak",
      logout: "Odjava",
      MasterDataUpload: "Otpremi glavne podatke",
      Toolbox: "Toolbox",
      welcome: "Dobro došli",
      Admin: "Administrator"
    },
    questionnaire: {
      addSubQuestion: "Dodaj pod-pitanje",
      editSubQuestion: "Izmijenite pod pitanje",
      subQuestionTitle: "Pod-pitanje",
      additionalInfo: "Dodatne informacije",
      na: "Dodajte 'Nije primjenjivo' kao mogući odgovor na ovo pitanje",
      question: "Pitanje",
      questionTitle: "Naslov pitanja",
      why: "Zašto?",
      addMainQuestion: "Dodaj glavno pitanje",
      editMainQuestion: "Uredi Glavno pitanje",
      previousQuestion: "Prethodno pitanje",
      nextQuestion: "Sljedeće pitanje",
      what: "Šta?",
      library: "Biblioteka",
      subQuestionTable: {
        no: "Ne",
        subQuestion: "Potpitanja",
        status: "Status",
        publishedDate: "Datum objavljivanja",
        notApplicable: "Nije primjenjivo"
      },
      links: {
        question_link_button: "Veza",
        question_link_include_folder: "Uključite podmapu",
        question_link_add_link: "Povežite datoteke iz biblioteke",
        question_link_view_folder: "Prikaži biblioteku"
      },
      mainQuestion: "Nova / ažurirana glavna pitanja:",
      subQuestion: "Nova / ažurirana pod-pitanja:",
      retiredQuestion: "Umirovljena pod-pitanja:",
      libraries: "Nove / ažurirane datoteke u knjižnicama:",
      noFiles: "Nema dostupnih datoteka."
    },
    library: {
      selectFile: "Odaberite barem jednu datoteku.",
      fileSelected: "Odabrane datoteke",
      publish_library_file: "Odaberite barem jednu datoteku za objavljivanje",
      library_files_deleted: "Jeste li sigurni da želite izbrisati ovu datoteku?",
      library_files_linked_deleted: "Jeste li sigurni da želite izbrisati ovu datoteku i veze stvorene u Upitniku?",
      max_size_error: "Veličina datoteka premašuje ograničenje od 1 GB po učitavanju.",
      empty_file_desc: "Please enter a file description",
      file_not_selected: "Odaberite datoteke koje želite prenijeti (najviše 1 GB)",
      file_type_error: "Ova vrsta datoteke nije dopuštena.",
      file_name_error: "Nevažeći znak u nazivu datoteke.",
      file_name_lenght_error: "Naziv datoteke može imati najviše 50 znakova.",
      addFolder: "Dodaj mapu",
      editFolder: "Uredi mapu",
      currentFolder: "Ime datoteke",
      parentFolder: "Ime nadređene mape",
      emptyFolderName: "Naziv mape ne može biti prazan.",
      invalidFolderName: "Ime mape ne može sadržavati '/'.",
      folder_created: "Mapa je uspješno izrađena.",
      folder_updated: "Mapa je uspješno ažurirana.",
      folder_not_found: "Mapa nije pronađena",
      lib_invalid_toolbox: "Odabran je nevažeći okvir alata",
      lib_folder_already_exists: "Ova mapa već postoji.",
      lib_folder_deleted: "Mapa je uspješno izbrisana.",
      library_files_published: "Datoteke su objavljene.",
      addFile: "Dodaj datoteku",
      editFile: "Uredi datoteku",
      fileName: "Naziv datoteke",
      upload: "Učitaj",
      desc: "Opis",
      folderCreationErr: "Hijerarhija je ograničena na dvije razine.",
      subFolderWarning: "Odaberite podmapu za dodavanje datoteka.",
      deleteWarning: "Odaberite mapu za brisanje.",
      beforeAfterNotSelected: "Molim odaberite je li dokaz prije ili poslije Kaizen aktivnosti",
    },
    chapter: {
      chapter_exist: "Ovo poglavlje već postoji.",
      chapter_saved: "Poglavlje spremljeno",
      chapter_cannot_delete: "Ovo se poglavlje ne može izbrisati jer su već postavljena pitanja.",
      failed: "Neuspjeh !",
      chapter_deleted: "Poglavlje je izbrisano",
      chapter_nonEmpty: "Napravite naziv poglavlja.",
      addchapter: "Dodaj poglavlje",
      editChapter: "Uredi poglavlje",
      DELETE_SUCC:"Questionnaires deleted successfully.",
      DELETE_SUCC_LIB:"Libraries deleted successfully.",
      DELETE_ERR : "Something Went wrong."
    },
    evaluation: {
      attachments: "Prilozi",
      comments: "Komentari",
      evidence: "Dokaz",
      evidences: "Dokaz(i)",
      yes: "Da",
      no: "Ne",
      na: "N/A",
      uploadEvidence: "Učitati dokaze",
      fileUpload: "Odaberite datoteke koje želite prenijeti (najviše 1 GB)",
      who: "Ko?",
      answer: "Odgovor",
      comment: "Comment",
      date: "Datum",
      submit: "Poslati",
      commentMsg: "Unesite komentare.",
      noCommentAvailable: "Nema komentara.",
      uploadSuccessMsg: "Datoteke su uspješno prenesene.",
      uploadErrorMsg: "Broj datoteka koje nije moguće učitati:",
      fileWithSameName: "Još jedna datoteka s istim nazivom već postoji.",
      noEvidence: "Nema dostupnih dokaza",
      createAction: "Formiraj stavku aktivnosti",
      chapterName: "Naziv poglavlja",
      questionNo: "Pitanje broj",
      when: "Kada?",
      noRecords: "Nema dostupnih zapisa.",
      kaizenPoints: "Kaizen bodovi",
      renameSuccessMsg: "File has been renamed successfully",
      deleteSuccessMsg: "File has been deleted successfully",
      question: "Pitanje",
      before:"Before",
      after:"After",
      beforeAfterLabel:"Da li je ovo dokaz prije ili poslije Kaizen aktivnosti?",
      move:"Premjesti",
      moveMsg: "Datoteke su uspješno premještene",
      noToYesMsg:"Previous answer was NO",
    },
    WALK_AROUND_CHECK: "Vizuelna kontrola WAC",
    ketProgram: {
      TSM: "TSM/LSM",
      BRiT: "BRiT RI",
      TSM_BNP: "Body & Paint",
      TSM_USED_CAR: "Rabljeni automobili",
      value_chain: "Value Chain",
      brit: "BRiT",
      ea: "EA",
      per:"Performance",
      nettrans:"Network Transformation",
    },
    dashboard: {
      notice_board: "Obavijesti",
      kpi_target_not_set: "KPI ciljevi nisu postavljeni",
      kpi_initial_not_set: "KPI početno mjerenje nije postavljeno",
      kpi_monthly_not_set: "Mjesečno mjerenje KPI-a nije postavljeno",
      kpi_below_target: "KPI ispod trenutnog cilja",
      activation: "Aktiviranje",
      users: "Korisnici",
      nmscs: "NMSC's",
      activeRetailer: "Aktivni partneri",
      certificationRate: "Ocjena certifikata",
      reCertificationRate: "Ocjena ponovno izdanog certifikata",
      activityDue: "Očekivane aktivnosti",
      avgdays: "Prosječni dani/Modul",
      overDueAct: "Istekle aktivnosti",
      actionItem: "Stavke akcije",
      bussResult: "Poslovni rezultati",
      toolboxImpl: "Implementacija modula",
      statusCompletedL:"Status - Completed, Progress - ",
      statusClosedL:"Status - Closed, Progress - ",
      statusOngoingL:"Status - Ongoing, Progress - ",
      recentUpdates: "Nedavna ažuriranja",
      rolloutTopNmsc: "Rezultati prikazivanja - Top NMSC-a",
      bestPractice: "Redosljed najboljih praksi",
      availableKpi: "Dostupni KPI",
      selectedKpi: "Odabrani KPI",
      dealerBestPractice: "Dealer best practice ploca",
      kpiList: "KPI lista",
      go: "IDI",
      nodata: "Podaci nisu dostupni",
      businessResultTop: "Rezultati poslovanja - Top partneri",
      businessResultNMSC: "Rezultati poslovanja - Top NMSC-a"


    },
    dealerCertification: {
      dealerCertification: "Certifikat partnera",
      readyForCertification: "Spremni za certifikaciju",
      certification: "Certifikacija",
      submittedOn: "Zaprimljeno",
      certificateDate: "Datum certifikata",
      reCertificateDate: "Datum ponovnog certifikata",
      toolboxDetails: "Detalji modula",
      approve: "Odobreno",
      reject: "Odbaci",
      pending: "Na čekanju",
      region: "Regija"



    },
    uploadBulk: {
      browse: "Browse",
      executionId: "Execution Id",
      uploadedOn: "Uploaded On",
      lastUpdatedOn: "Last updated on",
      noDataTable: "No data to view",
      kpiUpload: "KPI Upload",
      action: "Action",
      processed: "Processed",
      error: "Error",
      received: "Received",
      createActivity: "Create Activities",
      fileUpload: "File Upload",
      targetSetting: "Target Setting",
      kpiEntry:"KPI Entry",
      accepted:"Accepted",
      launchNmsc:"Launch for NMSC"

    },
    userManagement: {
      userManagement: "Users management",
      createUser: "Create User",
      editUser: "Edit User",
      viewUser: "View User",
      nmscId: "NMSC ID",
      tarsId: "TARS ID",
      firstName: "First Name",
      lastName: "Last Name",
      emailId: "E-Mail ID",
      britDealerChamp: "Brit Dealer Champion",
      invalidEmail: "Please enter valid E-Mail ID",
      invalidTars: "Please enter valid TARS ID",
      invalidFirstName: "Please enter valid First Name",
      invalidLastName: "Please enter valid Last Name",
      user_created: "User Created",
      user_updated: "User updated",
      duplicate_tarsuser: "This TARS id is already registered in the KET database.",
      user_define_in_tool: "User is defined as Business owner in Active Business Function. Hence, could not be made Inactive",
      user_refer_on: "User is referenced in Ongoing activity",
      pbiAccount : "PBI Account",
      invalidPbiAccount : "PBI Account should contain minimum 3 characters.",
      businessArea: "Business Area",
      valueChainAndBRiT: "Value Chain & BRiT",
      only:"Only",
      optionText:"Please select at least one of the two options below",
      valueChainOnly: "Value Chain Only",
      britOnly: "BRiT Only"

    },
    UserManagement: {
      nmscId: "NMSC ID",
      tarsId: "TARS ID",
      firstName: "First Name",
      lastName: "Last Name",
      emailId: "E-Mail ID",
      languageCode: "Language Code",
      ACTIVE: "Active",
      INACTIVE: "Inactive",
      addUser: "Add User",
      user: "User",
      error_message: "An Error Occurred. Please try again later",
      role: "Role",
      standardMessage: "No User Found",
      dealerCode: "Dealer Code",
      a2d: "A2D UUID",
      britReady: "Retailer participated in Brit Ready",
      britLive: "Retailer participated in Brit Live",
      britJapan: "Retailer participated in Brit Discovery Japan",
      britEss:"Retailer has updated EES Action Plan",
      addDealer: "Add Dealer",
      noDealer: "No Dealer Found",
      dealerUuid: "Dealer uuid",
      language: "Language",
      L3: "L3",
      L4: "L4",
      L5: "L5",
      L6: "L6",
    },
    CeaKpi: {
      survey: "Survey",
      evaluationItem: "Evaluation Item",
      kpiMetric: "KPI Evaluation Method",
      stratagicPillar: "Strategic Pillar",
      addKPI: "Add KPI",
      edit: "Edit",
      delete: "Delete",
      subPillar: "Sub-Pillar",
      kaizenImp_impact_def: "Kaizen Importance / Impact / Definition",
      evalMethod: "Evaluation Method",
      scoreMethod: "Scoring Method",
      typeOftarget: "Type of Target",
      target: "Target",
      mode: "Mode",
      points: "Points",
      inputFreq: "Input Frequency",
      cea_kpi_created: "CEA KPI has been created successfully",
      noPointsAllocated:"No Points defined for selected Pillar/Business function mapping",
      targetIncorrect:"Target value is Invalid",
      viewKpi:"View KPI",
      general:"General",
      targetScoring:"Targets & Scoring",
      inputs:"Input Parameters",
      maxFiveParam:"Maximum 5 parameters for a KPI can be added",
      validParam:"Please Enter Valid Parameter",
      validDesc:"Please Enter Valid Parameter Description",
      dataEntry:"Data Entry in KET",
      paramater:"Parameter",
      paramDesc:"Parameter Description",
      noParamAvai:"No Parameters Available",
      kpiDeleted:"KPI deleted Successfully",
      kpiDeleteError:"KPI Not found",
      kpiActualsError:"Actuals present. KPI cannot be deleted",
      cea_kpi_updated: "CEA KPI has been updated successfully",
      addParam:"Add Parameters"

    },
    dealerManagement: {
      invalidUuid: "Please enter valid Uuid",
      invalidDealerName: "Please enter valid Dealer Name",
      invalidCity: "Please enter valid City",
      invaliDealerCode: "Please enter valid Dealer Code",
      existingUuid: "Existing Uuid",
      updateUuid: "Update Uuid",
      newUuid: "New Uuid",
    },
    networkManagement: {
      networkManagement: "Network management"
    },
    kpiEntry: {
      cancel : "Cancel",
      save : "Save",
      no : "No",
      yes : "Yes",
      hide : "Hide",
      show : "Show",
      dataNotSaved : "Data is not saved. Entered data may be lost. Do you want to Proceed?",
      wantToProceed : "Do you want to proceed?",
      status: 'Status',
      evaluationYear: 'Evaluation Year',
      frequency: 'Frequency',
      typeOfTarget: 'Type of Target',
      target: 'Target',
      unit: 'Unit',
      standardMessage: 'No Kpi Actuals Data Found',
      srNo: 'S.No.',
      actuals: 'Actuals',
      subPillar: 'Sub Pillar',
      businessFunc: 'Business Function',
      brand: 'Brand',
      Jan : "Jan",
      Feb : "Feb",
      Mar : "Mar",
      Apr : "Apr",
      May : "May",
      Jun : "Jun",
      Jul : "Jul",
      Aug : "Aug",
      Sep : "Sep",
      Oct : "Oct",
      Nov : "Nov",
      Dec : "Dec",
      q1 : "Q1",
      q2 : "Q2",
      q3 : "Q3",
      q4 : "Q4",
      h1 : "H1",
      h2 : "H2",
      cy : "CY",
      kpi : "Key Performance Indicators",
      actuals1 : "Actuals should not be more than allotted points. Please re-enter",
      actionPlan	: "Action Plan",
      enterActualsData : "Please enter actuals data.",
      notNumber : "Please enter valid number.",
      serverErrorMsg : "An error occured, please try again later.",
      launchEvaluationError : "Please launch evaluations.",
      actualsDataSaved : "Actuals data uploaded successfully.",
      inputTxt:"Inputs"
    },
    ceaEvaluation :{
      nmsc : "NMSC",
      search : "Search",
      all : "All",
      status : "Status",
      businessFunction :"Business Function",
      noEvaluationsFound : "No Evalutions Found",
      evaluationYear : "Evalution Year",
      view : "View",
      evaluations : "Evaluations",
      launchTargetSetting: "Launch Target Setting",
      launchEvaluation : "Launch Evaluation",
    },
    britDashboard:{
      noVideo:"No videos available",
      playVideo:"Play Video",
      trainingVideos:"Training  Videos",
      download: "Download Video",
      back:"Back"
    },
    ceaActionPlan:{
      id: "ID",
      counterMeasure: "Countermeasure",
      questionReference: "KPI Reference",
      status: "Status",
      startDate: "Start Date",
      dueDate: "Due Date",
      assignedTo: "Assigned To",
      noActionAvailable: "No Actions available",
      add: "Add",
      download: "Download",
      edit: "Edit",
      delete: "Delete",
      deleteMsg: "Are you sure you want to delete ?",
      no: "No",
      yes: "Yes",
      addActionPlanNotAllowed: "The Action Plan cannot be created, or edited after the Activity has been Closed, Completed, or Rejected.",
      actionPlanItem: "Action Plan - Item #",
      addActionPlan: "Add Action Plan",
      open: "Open",
      actionPlanStartDateValidation:"End Date cannot be earlier than the Start Date",
      modalMandatory: "Please enter valid data in all the mandatory fields",
      problem: "Problem",
      description: "Description",
      followup: "Follow Up",
      history: "History",
      cancel: "Cancel",
      save: "Save",
      update: "Update",
      assignee: "Assignee",
      endDate: "End Date", 
      select: "Select",
      notApplicablePer:"Not Applicable for CEA, Networks and Used car"
    },
    reachItDashboard:{
      topNavReachIT:"ReachIT",
      downloads:"Downloads",
      historyFiles:"History of previous files",
      reachText1:"Welcome to the REACH IT homepage. The TME Environmental Management team will use this page to share relevant  information related to SCIP : Substances of Concern In articles as such or in complex objects (Products) established under the Waste Framework Directive (WFD) in order for you to fulfill your duty to process with your SCIP simplified notification.",
      reachText2:"In case of query, please contact the TME Environmental Management team via below link.",
      reachText3:"In case of query, please contact your NMSC.",
      envMngt:"Environmental Management",
      fileStatistics: "File Downloaded Statistics",
      Downloaded: "Downloaded",
      not: "Not",
      exportToExcel:"Export To Excel"
    },
    usedCarComment:{
      ongoing:"Ongoing",
      completed:"Completed",
      commentHistory:"Monthly Comments History",
      addComment:"Add Monthly Comment",
      month:"Month",
      comment:"Comment",
      username:"Username",
      email:"Email",
      createdDate:"Created Date"
    },
  }
};

export default translationsBS;
