import React from 'react';
import * as AppConst from '../../AppConstant';
import {
    Modal,
    ModalBody,
    ModalHeader,
    ModalFooter
} from 'reactstrap';
import { withTheme } from '../../themes/theming';
import Select from 'react-select';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { fetchActionPlanStatus, saveActionPlans, getFollowUpList, downloadActionPlanDtls } from '../../actions/activityAction.jsx';
import { toastr } from "react-redux-toastr";
import './index.css';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import "moment-timezone";
import 'react-datepicker/dist/react-datepicker.css';
var I18n = require('react-redux-i18n').I18n;

class ActionPlanModal extends React.Component {
    /* eslint-disable */
    constructor(props) {
        super(props);
        this.state = {
            selectedRow: this.props.selectedRow,
            startDate: "",
            endDate: "",
            statusOptions: [],
            statusValue: { label: this.props.user.userMdl.flagCEA || this.props.user.userMdl.businessAreaKey === AppConst.businessAreaKeyPer ? I18n.t('ceaActionPlan.open'): I18n.t('others.open'), value: 'Open' },
            disabledStatus: true,
            followUpList: [],
            statusLoaded: "",
            disabledFieldForStartedActivity: false,
            redirectToActionPlan:false,
            isDisableSave:false

        };
        this.disableSave = this.disableSave.bind(this);
    }

    componentWillMount() {
        //check dealer
   

        this.props.dispatch(fetchActionPlanStatus());
        if (this.props.modalFor === "Edit") {

            //get followup comments
            // var firstVisit = this.props.activity.firstVisit.split("/");
            // var firstDate = new Date(firstVisit[2], firstVisit[1] - 1, firstVisit[0]);
            // if (firstDate <= new Date()) {

                //this.state.disabledFieldForStartedActivity=true;


            // }
            this.props.dispatch(getFollowUpList(this.state.selectedRow.actionPlanId));

            //set startdate in date format
            if (this.state.selectedRow.startDate.length > 0) {
                this.state.startDate = this.state.selectedRow.startDate;
                var startDate = this.state.selectedRow.startDate.split("/");
                this.state.selectedRow.startDate = new Date(startDate[2], startDate[1] - 1, startDate[0]);
                if (this.state.selectedRow.startDate <= new Date()) {
                    this.state.disabledStatus = false;
                    this.state.disabledFieldForStartedActivity = true;
                }
            }
            //set enddate in date format
            if (this.state.selectedRow.endDate.length > 0) {
                var endDate = this.state.selectedRow.endDate.split("/");
                this.state.selectedRow.endDate = new Date(endDate[2], endDate[1] - 1, endDate[0]);
            }
        }
    }
    componentWillReceiveProps(nextProps) {
        //let userRoles = ["KET NMSC Field","KET NMSC Admin"];
       let userRoles = [];
        userRoles = this.state.selectedRow.userMdl.roles;
        var isUser = false;
        var statusList = [];
        if (nextProps.followUpList) {
            this.setState({ followUpList: nextProps.followUpList });
        }
        if (nextProps.actionPlanStatus) {

            nextProps.actionPlanStatus.map((obj, index) => {
                if (this.props.modalFor === "Edit") {

                    if (obj.value === "Open") {
                        statusList.push(obj)
                    }
                    if (obj.label === "Close") {
                        statusList.push(obj)
                    }
                    if (obj.label === "Complete") {
                        if (userRoles) {
                            for (var i = 0; i < userRoles.length; i++) {
                                if (userRoles[i].toUpperCase().indexOf('KET NMSC ADMIN') !== -1 || userRoles[i].toUpperCase().indexOf('KET TME ADMIN') !== -1 || userRoles[i].toUpperCase().indexOf('KET NMSC FIELD') !== -1) {
                                    isUser = true;
                                    break;
                                }
                            // userRoles.map((d, i) => {
                            //     if (d.toUpperCase() === "KET NMSC FIELD" || d.toUpperCase() === "KET NMSC ADMIN" || d.toUpperCase() === "KET TME ADMIN") {
                            //         console.log("matched role");
                            //         isUser = true;
                            //         //statusList.push(obj);
                            //         //return;
                            //     }
                            // });
                        }
                        if(isUser){
                            statusList.push(obj);
                        }
                    }


                }
            }
                else {
                    if (obj.value === "Open") {
                        statusList.push(obj)
                    }

                }
            });const options = statusList.map((d) => ({
                value: d.value,
                label: I18n.t(d.label)
            }));
            this.setState({ statusOptions: options });


        }

        if (this.props.modalFor === "Edit") {
            if (this.state.statusOptions) {
                if (this.state.selectedRow.status !== "") {
                    this.state.statusOptions.map((obj, index) => {
                        if (obj.value === this.state.selectedRow.status) {
                            this.setState({ statusValue: obj });
                        }
                    });
                }
            }
        }
    }
    onStartDateChange = (date) => {
        const currentDate = new Date();
        if (date <= currentDate && this.props.modalFor === "Edit") {
            this.state.disabledStatus = false;
            this.state.disabledFieldForStartedActivity = true;
        } else {
            this.state.disabledStatus = true;
        }

        const selectedRow = { ...this.state.selectedRow };
        selectedRow["startDate"] = date;


        this.setState({ selectedRow });
        this.setState({ startDate: date })
    }
    onEndDateChange = (date) => {
        //const selectedRow = { ...this.state.selectedRow };

        const selectedRow = { ...this.state.selectedRow };
        selectedRow["endDate"] = date;
        if (this.state.selectedRow.startDate > date) {
            toastr.error(this.props.user.userMdl.flagCEA || this.props.user.userMdl.businessAreaKey === AppConst.businessAreaKeyPer ? I18n.t("ceaActionPlan.actionPlanStartDateValidation"):I18n.t("toastr.actionPlanStartDateValidation"));


            return;
        } else {
            //selectedRow["endDate"] = date;
            this.setState({ selectedRow });
            this.setState({ endDate: date })
        }
    }
    onDescriptionChange = (e) => {
        const selectedRow = { ...this.state.selectedRow };

        selectedRow["description"] = e.target.value;
        this.setState({ selectedRow });

    }
    onCounterMeasureChange = (e) => {

        const selectedRow = { ...this.state.selectedRow };
        selectedRow["counterMeasure"] = e.target.value;
        this.setState({ selectedRow });

    }
    onQuestionReferenceChange = (e) => {

        const selectedRow = { ...this.state.selectedRow };
        selectedRow["questionReference"] = e.target.value;
        this.setState({ selectedRow });
    }
    onAssigneeChange = (e) => {

        const selectedRow = { ...this.state.selectedRow };
        selectedRow["assignee"] = e.target.value;
        this.setState({ selectedRow });
    }
    onStatusChange = (e) => {
        this.state.statusValue = e;
        const selectedRow = { ...this.state.selectedRow };
        selectedRow["status"] = e.value;
        this.setState({ selectedRow });
    }
    onFollowupChange = (e) => {
        const selectedRow = { ...this.state.selectedRow };
        selectedRow["followUp"] = e.target.value;
        this.setState({ selectedRow });
    }
    disableSave() {
        this.setState({isDisableSave:!this.state.isDisableSave});
    }
    saveActionPlan = (e) => {
        e.preventDefault();
        const selectedRow = { ...this.state.selectedRow };
        var flag = true;
        if (
            this.state.selectedRow.counterMeasure.length === 0 ||
            (!this.props.user.userMdl.flagCEA && this.props.user.userMdl.businessAreaKey !== AppConst.businessAreaKeyPer && this.state.selectedRow.assignee.length === 0) ||
            this.state.selectedRow.description.length === 0 ||
            this.state.selectedRow.startDate.length === 0 ||
            this.state.selectedRow.endDate.length === 0 ||
            this.state.selectedRow.status.length === 0
        ) {
            // this.disableSave();
            toastr.error((this.props.user.userMdl.flagCEA || this.props.user.userMdl.businessAreaKey === AppConst.businessAreaKeyPer) ?I18n.t("ceaActionPlan.modalMandatory"):I18n.t("toastr.modalMandatory"));
            flag = false;
            return;
        }
        this.disableSave();
        if (this.state.selectedRow && this.state.selectedRow.startDate !== null && this.state.selectedRow.startDate !== "") {
            var startDateDt = moment(this.state.selectedRow.startDate);
            this.state.selectedRow.startDate = startDateDt.format("YYYY-MM-DD");
        }
        if (this.state.selectedRow && this.state.selectedRow.endDate !== null && this.state.selectedRow.endDate !== "") {
            var endDateDt = moment(this.state.selectedRow.endDate);
            this.state.selectedRow.endDate = endDateDt.format("YYYY-MM-DD");
        }
        if (flag) {
                 //check Dealer Champion Role
                 var isDealerUser = false;
                 if (this.props.user.userMdl.roles !== undefined) {
                   var roles = this.props.user.userMdl.roles;
                   for (var i = 0; i < roles.length; i++) {
                     if (roles[i].toUpperCase() ==="KET DEALER CHAMPION" || roles[i].toUpperCase() === "KET DEALER INTERNAL" || roles[i].toUpperCase() === "KET NMSC External" || this.props.user.userMdl.roles[i] === "KET NMSC User" || this.props.user.userMdl.roles[i] === "KET NMSC Field") {
                         isDealerUser = true;
                       break;
                     }
                   }
                 }
                 console.log(isDealerUser);
                if(isDealerUser===true){
                    var viewStatusDTO = {
                        activityId:  this.state.selectedRow.activityId,
                        loginId: this.props.user.userMdl.username,
                        actionPlanUpdated :"True",
                    }
                }
                else{
                    var viewStatusDTO = null;
                }
            this.props.dispatch(
                saveActionPlans(this.state.selectedRow, this.state.selectedRow.activityId,viewStatusDTO, this)
            );
       
    
        }
        if(this.props.evaluationCreateActionItemOpen){
            this.props.evaluationCreateActionItem();
        }
    }
    downloadAPDtls = (e) => {
        e.preventDefault();
        let lang = this.props.user.userMdl.lang
        let nmsc = this.props.user.userMdl.nmsc
        if(this.props.user.userMdl.flagCEA || this.props.user.userMdl.businessAreaKey === AppConst.businessAreaKeyPer){
            lang = "en"
            nmsc = this.state.selectedRow.nmscCode
        }
        this.props.dispatch(downloadActionPlanDtls(this.state.selectedRow.actionPlanId, lang ,nmsc, this.props.theme.name,this.props.user.userMdl.activeProgram,this.state.selectedRow.activityId));
    }
    preventDefault() { }
    render() {
        let isBRitUser = false;
        let isCeaUser = this.props.user.userMdl.flagCEA || this.props.user.userMdl.businessAreaKey === AppConst.businessAreaKeyPer;
        (this.props.user.userMdl.activeProgram  ===  "BRiT" || this.props.user.userMdl.activeProgram  ===  "EA")   ? isBRitUser = true : false
        let accessType = this.props.user.userMdl.accessControl.filter(obj => obj.function === 'ACTIVITY_DETAIL_RETAILER_ACTUAL')[0] ?
            this.props.user.userMdl.accessControl.filter(obj => obj.function === 'ACTIVITY_DETAIL_RETAILER_ACTUAL')[0].accessType : null;

        if (this.state.disabledFieldForStartedActivity === true) {
            //$("#startDate").removeClass('react-datepicker__close-icon');
        }
        return (
            <Modal className={"downloadClass actionPlanModal-" + this.props.theme.name} isOpen="true" toggle={() => this.preventDefault()} ref="download">
                {
                    isCeaUser ?
                    <ModalHeader toggle={() => this.props.toggle(this.props.modalFor)}> 
                        {this.state.selectedRow.actionPlanDisplayId === 0 ? I18n.t("ceaActionPlan.addActionPlan") : I18n.t("ceaActionPlan.actionPlanItem") + "" + this.state.selectedRow.actionPlanDisplayId}
                    </ModalHeader>
                    :
                    <ModalHeader toggle={() => this.props.toggle(this.props.modalFor)}> 
                        {this.state.selectedRow.actionPlanDisplayId === 0 ? I18n.t("tmeAdminMaster.addActionPlan") : I18n.t("tmeAdminMaster.actionPlanItem") + "" + this.state.selectedRow.actionPlanDisplayId}
                    </ModalHeader>
                }
                
                
                <ModalBody>
                    <form className="form-horizontal">
                        <label className="col-md-12 control-label"> <span className="textFont">{isCeaUser ?I18n.t("ceaActionPlan.problem"):I18n.t("Table.problem")}</span></label>
                        <label className="col-md-12 control-label">{isCeaUser ?I18n.t("ceaActionPlan.description"):I18n.t("Table.description")} <span className="redFont">*</span></label>
                        <div className="col-md-12">
                        <textarea className="form-control" rows="2" maxLength="2000" readOnly={(this.state.selectedRow.userMdl.roles.includes(AppConst.KET_TME_ADMIN) || this.state.selectedRow.userMdl.roles.includes(AppConst.KET_NMSC_ADMIN)) ? false : this.state.disabledFieldForStartedActivity}  onChange={this.onDescriptionChange} value={this.state.selectedRow.description}></textarea>
                        </div>
                        <br></br>
                        <label className="col-md-12 control-label">{isCeaUser ?I18n.t("ceaActionPlan.counterMeasure"):I18n.t("Table.counterMeasure")}<span className="redFont">*</span></label>
                        <div className="col-md-12">
                        <textarea className="form-control" rows="2" maxLength="2000" readOnly={(this.state.selectedRow.userMdl.roles.includes(AppConst.KET_TME_ADMIN) || this.state.selectedRow.userMdl.roles.includes(AppConst.KET_NMSC_ADMIN)) ? false : this.state.disabledFieldForStartedActivity}  onChange={this.onCounterMeasureChange} value={this.state.selectedRow.counterMeasure} ></textarea>
                        </div>
                        <br></br>
                        <div className="col-md-12 row control-label">
                            <label className="col-md-2 control-label" style={{ paddingLeft: '0.3rem' }}>{isBRitUser?I18n.t("Table.kpi"): isCeaUser ? I18n.t("ceaActionPlan.questionReference"): I18n.t("Table.questionReference")} </label>
                            <label className="col-md-3 control-label" style={{ paddingLeft: isCeaUser?'0.1rem':'0.5rem' }}>{isCeaUser?I18n.t("ceaActionPlan.assignee"):I18n.t("Table.assignee")}
                                {isCeaUser?"" : <span className="redFont">*</span>}
                            </label>
                            <label className="col-md-2 control-label" style={{ paddingLeft: '0.8rem' }}>{isCeaUser?I18n.t("ceaActionPlan.status"):I18n.t("Table.status")} </label>
                            <label className="col-md-2 control-label" style={{ paddingLeft: '2.8rem' }}>{isCeaUser?I18n.t("ceaActionPlan.startDate"):I18n.t("Table.startDate")}<span className="redFont">*</span></label>
                            <label className="col-md-2 control-label" style={{ paddingLeft: '4.5rem' }}>{isCeaUser?I18n.t("ceaActionPlan.endDate"):I18n.t("Table.endDate")}<span className="redFont">*</span></label>
                        </div>
                        <div className="col-md-12 input-group control-label">
                            <div title={this.state.selectedRow.questionReference}>
                            {isBRitUser?  <input
                                    type="text"
                                    className="form-control"
                                    maxLength="100"
                                    autoFocus
                                    style={{ paddingLeft: '0.3rem', width: '10rem' }}
                                    onChange={this.onQuestionReferenceChange}
                                    value={this.state.selectedRow.questionReference}
                                    disabled={this.state.disabledFieldForStartedActivity || accessType!=="R/W"}

                            /> :
                                <input
                                    type="text"
                                    className="form-control"
                                    maxLength="100"
                                    autoFocus
                                    style={{ paddingLeft: '0.3rem', width: '10rem' }}
                                    onChange={this.onQuestionReferenceChange}
                                    value={this.state.selectedRow.questionReference}
                                    disabled={this.state.disabledFieldForStartedActivity}

                            /> }
                            </div>
                            &nbsp;&nbsp;
                            <input
                                type="text"
                                className="form-control"
                                maxLength="100"
                                autoFocus
                                style={{ paddingLeft: '0.5rem', width: '12rem' }}
                                onChange={this.onAssigneeChange}
                                value={this.state.selectedRow.assignee}
                            />
                            &nbsp;&nbsp;
                            <Select
                                placeholder= {isCeaUser?I18n.t('ceaActionPlan.select'):I18n.t('others.select')}
                                className="statusDD"
                                value={this.state.statusValue}
                                style={{ paddingLeft: '0.5rem' }}
                                options={this.state.statusOptions}
                                isClearable={false}
                                onChange={this.onStatusChange}
                                //selected={{label: 'Open', value: 'Open' }}
                                isDisabled={this.state.disabledStatus}
                                id="actionStatusId"

                            />

                            &nbsp;&nbsp;

                            <DatePicker
                                className="form-control"
                                onChange={this.onStartDateChange}
                                placeholderText={isCeaUser?I18n.t("ceaActionPlan.startDate"):I18n.t("Table.startDate")}
                                dateFormat="dd/MM/yyyy"
                                isClearable={this.state.disabledFieldForStartedActivity ? false : true}
                                selected={
                                    this.state.selectedRow.startDate
                                        ? this.state.selectedRow.startDate
                                        : null
                                }
                                id="startDate"
                                name="startDate"
                                disabled={this.state.disabledFieldForStartedActivity ? true : false}
                            />

                            &nbsp;&nbsp;
                            <DatePicker
                                className="form-control"
                                onChange={this.onEndDateChange}
                                placeholderText={isCeaUser?I18n.t("ceaActionPlan.endDate"):I18n.t("Table.endDate")}
                                dateFormat="dd/MM/yyyy"
                                isClearable={true}
                                selected={
                                    this.state.selectedRow.endDate
                                        ? this.state.selectedRow.endDate
                                        : null
                                }
                                id="endDate"
                                name="endDate"
                            />
                        </div>

                        {this.props.modalFor !== "Create" ?
                            <div>
                                <hr></hr>

                                <label className="col-md-12 control-label"> <span className="textFont">{isCeaUser?I18n.t("ceaActionPlan.followup"):I18n.t("Table.followup")}</span></label>

                                <div className="col-md-12">

                                    <textarea className="form-control" rows="2" maxLength="2000" onChange={this.onFollowupChange}></textarea>
                                </div>

                                <br></br>


                                <label className="col-md-12 control-label"> <span className="textFont">{isCeaUser?I18n.t("ceaActionPlan.history"):I18n.t("Table.history")}</span></label>

                                <div className="col-md-12 ">

                                    <div className="historyDiv">
                                        {this.state.followUpList.length > 0 ?
                                            this.state.followUpList.map((obj, index) => {
                                                return <div id={obj.followUpId}>
                                                    <label className="textFontHistory">{obj.followUpDateStr} , {obj.followerName}</label>
                                                    <br />
                                                    <i>{obj.comment}</i>
                                                    <br />
                                                    <br />
                                                </div>
                                            })
                                            : ""}
                                    </div>
                                </div>

                            </div>
                            : ""}
                        <br></br>
                    </form>
                </ModalBody>
                <ModalFooter>
                    <div className="col-md-12 modal-footer" style={{ borderTop: '0px' }}>
                    {this.props.modalFor !== "Create" ?
                        <div className="col-md-6 redLine">
                            <a onClick={e => { this.downloadAPDtls(e); }}><div title={isCeaUser?I18n.t("ceaActionPlan.download"):I18n.t("tmeAdminMaster.download")} className="download-icon" /></a>
                        </div>
                        :""}
                        <div className="col-md-6" style={{ paddingLeft: '9rem' }}>
                            <button className={"buttonCancel-" + this.props.theme.name} onClick={() => this.props.toggle(this.props.modalFor)}> {isCeaUser?I18n.t("ceaActionPlan.cancel"):I18n.t("tmeAdminMaster.cancel")}</button>
                            {this.state.isDisableSave?
                                isCeaUser?
                                <button disabled className={"button-" + this.props.theme.name}>{this.props.modalFor === "Create" ? I18n.t("ceaActionPlan.save") : I18n.t("ceaActionPlan.update")}</button>
                                :
                                <button disabled className={"button-" + this.props.theme.name}>{this.props.modalFor === "Create" ? I18n.t("tmeAdminMaster.save") : I18n.t("tmeAdminMaster.update")}</button>
                            :
                            isCeaUser?
                            <button className={"button-" + this.props.theme.name} onClick={e => {
                                this.saveActionPlan(e, this.state.valueAssignee);
                            }}>{this.props.modalFor === "Create" ? I18n.t("ceaActionPlan.save") : I18n.t("ceaActionPlan.update")}
                            </button>
                            :
                            <button className={"button-" + this.props.theme.name} onClick={e => {
                                this.saveActionPlan(e, this.state.valueAssignee);
                            }}>{this.props.modalFor === "Create" ? I18n.t("tmeAdminMaster.save") : I18n.t("tmeAdminMaster.update")}
                            </button>
                            }
                        </div>
                    </div>
                </ModalFooter>
            </Modal >

        );
    }
}
const mapStateToProps = (state) => {
    return {
        user: state.loginReducer,
        blocking: state.loginReducer,
        followUpList: state.activityReducer.followUpList,
        actionPlanStatus: state.activityReducer.actionPlanStatus
    }
}
export default withRouter(connect(mapStateToProps)(withTheme(ActionPlanModal)));