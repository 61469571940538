import axios from 'axios';
import { getToken } from '../oidc/AuthService';
// export function getCallWithoutReducer(url, inputJson, onSuccess, onFailure, e, blockerType) {
//     return function (dispatch) {
//       if(blockerType === null){
//         dispatch(setBlockingState(true));
//       }else{
//         dispatch(setDivBlockingState(true));
//       }
//       axios.get(url, {
//         params: inputJson,
//         headers:{
//           'Content-type': 'application/json',
//           'Accept': 'application/json',
//           'Authorization': 'Bearer ' + getToken()
//         }
//       })
//         .then((response) => {
//           if(blockerType === null){
//             dispatch(setBlockingState(false));
//           }else{
//             dispatch(setDivBlockingState(false));
//           }
//           if (response.status === 200) {
//             if (onSuccess) {  
//               if (response !== null && typeof response ==='object') {
//                 const data = commonFunction.safeAccess(response, 'data', {});  
//                 if(e){
//                   onSuccess(e,data)
//                 } else {
//                   onSuccess(data)
//                 }
//               }
//             }
//           } else if(response.status === 503 || response.status === 505){
//             maintainencePage()
//           }
//           else {
//             //for other codes
//             if (onSuccess) {
//                e ? onSuccess(e, response) : onSuccess(response) ;
//             }
//           }
//         })
//         .catch((err) => {
//           if(blockerType === null){
//             dispatch(setBlockingState(false));
//           }else{
//             dispatch(setDivBlockingState(false));
//           }
//           if (onFailure) {
//              e ? onFailure(err, e) : onFailure(err) ;
//           }
//         })
//     }
//   }

  // export function postCallWithoutReducer(url, inputJson, onSuccess, onFailure, e, params , projectTaskId) {
  //   return function (dispatch) {
  //     dispatch(setBlockingState(true));
  //     axios.post(url, inputJson, {
  //       params:params,
  //       headers: projectTaskId?{
  //         'Content-Type': CONTENT_TYPE,
  //         'Accept': CONTENT_TYPE,
  //         'Authorization': 'Bearer ' + getToken(),
  //         'Project-Task-Id':projectTaskId
  //       }:{
  //         'Content-Type': CONTENT_TYPE,
  //         'Accept': CONTENT_TYPE,
  //         'Authorization': 'Bearer ' + getToken()
  //       }
  //     })
  //       .then((response) => {
  //         dispatch(setBlockingState(false));
  //         if (response.status === 200 || response.status === 201) {
  //           if (onSuccess) {
  //             if (response !== null && typeof response ==='object') {
  //               const data = commonFunction.safeAccess(response, 'data', {});  
  //               if(e){
  //                 onSuccess(e,data)
  //               } else {
  //                 onSuccess(data)
  //               }
  //             }
  //           }
  //         }else if(response.status === 503 || response.status === 505){
  //           maintainencePage()
  //         }
  //       })
  //       .catch((err) => {
  //         dispatch(setBlockingState(false));
  //         if (err.response ) {
  //            e ? onFailure(e, err.response) : onFailure(err.response) ;
  //         } else {
  //           let dummyResponseObject={
  //             "data":{
  //               "message":"Error while updating data.Please contact system administrator."
  //             }
  //           }
  //           e? onFailure(e,dummyResponseObject):onFailure(dummyResponseObject);
  //         }
  //       })
  //   }
  // }
  
//new code 
  export function getCallWithReducer(url,inputJson,reducerType) {
    return function (dispatch) {
      axios.get(url, {
        params: inputJson,
        headers:{
          'Content-type': 'application/json',
          'Accept': 'application/json',
          'Authorization': 'Bearer ' + getToken()
        }
      })
        .then(response => {
          dispatch({
            type: reducerType,
            payload: response.data
          });
        })
        .catch(function (error) {
          //dispatch(setBlockingState(false));
          console.error(error);
        })
    }
  }

  

  export function postCallWithReducer(URL,Dto,reducerType) {
    return function (dispatch) {
      axios
        .post(URL, Dto, {
          headers: {
            "Content-type": "application/json",
              'Accept': "application/json",
              'Pragma': "no-cache",
            'Authorization': 'Bearer ' + getToken()
          }
        })
        .then(response => {
          //dispatch(setBlockingState(false));
          dispatch({
            type: reducerType,
            payload: response.data
          });
        })
        .catch(function (error) {
          //dispatch(setBlockingState(false));
          console.error(error);
        });
    };
  }

