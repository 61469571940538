import axios from "axios";
import { setBlockingState } from "./loginAction.jsx";
import { toastr } from "react-redux-toastr";
import { getToken } from '../oidc/AuthService';
import { getCallWithReducer, postCallWithReducer } from "./commonAction";
var I18n = require("react-redux-i18n").I18n;
/**
 * Fetch local kpi List from server.
 */
export function fetchCeaNetKpiList(packageMapPrimaryKey, activeProgramID, userMdl) {
  //  console.log("user detail---",userMdl)
  return function (dispatch) {
    dispatch(setBlockingState(true));
    axios
      .post(
        "/kpi/ceaNetkpi/" + packageMapPrimaryKey+"/"+activeProgramID, userMdl,
        {
          headers: {
            'Authorization': 'Bearer ' + getToken(),
            "Content-type": "application/json",
            Accept: "application/json",
            Pragma: "no-cache"
          }
        }
      )
      .then(response => {
        dispatch(setBlockingState(false));
        dispatch({
          type: "CEA_NET_KPI_DATA",
          payload: response.data
        });
      })
      .catch(function (error) {
        dispatch(setBlockingState(false));
        console.error(error);
      });
  };
}


export function publishCeaKPIs(ceaKpiIdList, packageProgramMappingId, userMdl,activeProgramID) {
  return function (dispatch) {
    //dispatch(setBlockingState(true));
    axios
      .put("/kpi/ceaKpi/publish", ceaKpiIdList, {
        headers: {
          'Authorization': 'Bearer ' + getToken(),
          "Content-type": "application/json",
          Accept: "application/json",
          Pragma: "no-cache"
        }
      })
      .then(response => {
        //dispatch(setBlockingState(false));
        dispatch(fetchCeaNetKpiList(packageProgramMappingId,activeProgramID, userMdl));
        toastr.success(I18n.t("ServerMessage." + response.data.desc))
      })
      .catch(function (error) {
        //dispatch(setBlockingState(false));
        console.error(error);
      });
  };
}

export function publishNetUcKPIs(netUcKpiIdList, packageProgramMappingId, userMdl,activeProgramID,ceaYear, activeProgram) {
  let publishDto ={
    activeProgramID: activeProgramID,
    ceaYear: ceaYear,
    packageProgramMappingId: packageProgramMappingId,
    userId: userMdl.username,
    netUcKpiIdList: netUcKpiIdList,
    activeProgram:activeProgram
  }
  return function (dispatch) {
    //dispatch(setBlockingState(true));
    axios
      .post("/kpi/netUcKpi/publish", publishDto, {
        headers: {
          'Authorization': 'Bearer ' + getToken(),
          "Content-type": "application/json",
          Accept: "application/json",
          Pragma: "no-cache"
        }
      })
      .then(response => {
        //dispatch(setBlockingState(false));
        dispatch(fetchCeaNetKpiList(packageProgramMappingId,activeProgramID, userMdl));
        toastr.success(I18n.t("ServerMessage." + response.data.desc))
      })
      .catch(function (error) {
        //dispatch(setBlockingState(false));
        console.error(error);
        dispatch(setBlockingState(false));
        if (error.response) {
          console.log(error.response);
          toastr.error(error.response.data.errorMessage);
        }
      });
  };
}

// export function fetchBusinessFunctionPoints(packageMapPrimaryKey, activeProgramID) {

//   return function (dispatch) {
//     //dispatch(setBlockingState(true));
//     axios
//       .get(
//         "/kpi/ceakpi/" + packageMapPrimaryKey + "/" + activeProgramID,
//         {
//           headers: {
//             "Content-type": "application/json",
//             Accept: "application/json",
//             Pragma: "no-cache"
//           }
//         }
//       )
//       .then(response => {
//         //dispatch(setBlockingState(false));
//         dispatch({
//           type: "BUSINESS_POINTS",
//           payload: response.data
//         });
//       })
//       .catch(function (error) {
//         // dispatch(setBlockingState(false));
//         console.error(error);
//       });
//   };



// }

export function fetchBusinessFunctionPoints(packageMapPrimaryKey, activeProgramID) {
  return function (dispatch) {
  dispatch(getCallWithReducer("/kpi/ceakpi/" + packageMapPrimaryKey + "/" + activeProgramID,null,"BUSINESS_POINTS"))
  };
}
//get lexus published kpi on basis of input frequency
// export function fetchPublishedLexusKpi(inputFrequency, ceaYear) {
//   return function (dispatch) {
//     //dispatch(setBlockingState(true));
//     axios
//       .get(
//         "/kpi/getAllPublishedLexusKpi/" + inputFrequency + "/" + ceaYear,
//         {
//           headers: {
//             "Content-type": "application/json",
//             Accept: "application/json",
//             Pragma: "no-cache"
//           }
//         }
//       )
//       .then(response => {
//         //dispatch(setBlockingState(false));
//         dispatch({
//           type: "LEXUS_PUBLISHED_KPI",
//           payload: response.data
//         });
//       })
//       .catch(function (error) {
//         // dispatch(setBlockingState(false));
//         console.error(error);
//       });
//   };
// }

export function fetchPublishedLexusKpi(inputFrequency, ceaYear) {
  return function (dispatch) {
  dispatch(getCallWithReducer("/kpi/getAllPublishedLexusKpi/" + inputFrequency + "/" + ceaYear,null,"LEXUS_PUBLISHED_KPI"
  ))
  };
}
// export function fetchBusinessFunctionStatus(packageMapPrimaryKey, activeProgramID) {
//   return function (dispatch) {
//     axios
//       .get(
//         "/kpi/ceakpi/getBusinessFunctionStatus/" + packageMapPrimaryKey + "/" + activeProgramID,
//         {
//           headers: {
//             "Content-type": "application/json",
//             Accept: "application/json",
//             Pragma: "no-cache"
//           }
//         }
//       )
//       .then(response => {
//         //dispatch(setBlockingState(false));
//         dispatch({
//           type: "BUSINESS_STATUS",
//           payload: response.data
//         });
//       })
//       .catch(function (error) {
//         // dispatch(setBlockingState(false));
//         console.error(error);
//       });
//   };
// }

export function fetchBusinessFunctionStatus(packageMapPrimaryKey, activeProgramID) {
  return function (dispatch) {
  dispatch(getCallWithReducer("/kpi/ceakpi/getBusinessFunctionStatus/" + packageMapPrimaryKey + "/" + activeProgramID,null,"BUSINESS_STATUS"))
  };
}

export function fetchSubpillar(activeProgramID) {

  return function (dispatch) {
    //dispatch(setBlockingState(true));
    dispatch({
      type: "CEA_NET_SUBPILLAR",
      payload: []
    });
    axios
      .get(
        "/kpi/subPillar/" + activeProgramID,
        {
          headers: {
            'Authorization': 'Bearer ' + getToken(),
            "Content-type": "application/json",
            Accept: "application/json",
            Pragma: "no-cache"
          }
        }
      )
      .then(response => {
        dispatch(setBlockingState(false));
        dispatch({
          type: "CEA_NET_SUBPILLAR",
          payload: response.data
        });
      })
      .catch(function (error) {
        // dispatch(setBlockingState(false));
        console.error(error);
      });
  };
}


// export function fetchCeaDropdowns(activeProgram) {
//   return function (dispatch) {
//     //dispatch(setBlockingState(true));
//     axios
//       .get(
//         "/kpi/ceaKpiDropDowns/" + activeProgram,
//         {
//           headers: {
//             "Content-type": "application/json",
//             Accept: "application/json",
//             Pragma: "no-cache"
//           }
//         }
//       )
//       .then(response => {
//         //dispatch(setBlockingState(false));
//         dispatch({
//           type: "CEA_NET_DROPDOWNS",
//           payload: response.data
//         });
//       })
//       .catch(function (error) {
//         // dispatch(setBlockingState(false));
//         console.error(error);
      
//       });
//   };
// }
export function fetchCeaDropdowns(activeProgram) {
  return function (dispatch) {
  dispatch(getCallWithReducer("/kpi/ceaKpiDropDowns/" + activeProgram,null,"CEA_NET_DROPDOWNS"))
  };
}

export function saveCeaNetKPI(kpiDTO,businessAreaKey,userMdl,this_) {

  console.log(kpiDTO)
  return function (dispatch) {
    dispatch(setBlockingState(true));
    axios
      .post(
        "/kpi/ceaKPI/" + businessAreaKey, kpiDTO,
        {
          headers: {
            'Authorization': 'Bearer ' + getToken(),
            "Content-type": "application/json",
            Accept: "application/json",
            Pragma: "no-cache"
          }
        }
      )
      .then(response => {
        dispatch(setBlockingState(false));
        console.log(response.data)

        // toastr.success(response.data.desc)

        toastr.success(I18n.t("CeaKpi." + response.data.desc));

        this_.props.toggle(true);

        dispatch(fetchCeaNetKpiList(kpiDTO.packageProgramMappingId,this_.props.user.userMdl.activeProgramID,userMdl));
        
      })
      .catch(function (error) {
        dispatch(setBlockingState(false));
        if (error.response) {
          console.log(error.response);
          toastr.error(error.response.data.errorMessage);
        }
      });
  };
}

// export function fetchCeaNetKPI(kpiId) {

//   return function (dispatch) {

//     //dispatch(setBlockingState(true));
//     axios
//       .get(
//         "/kpi/getKpiDetails/"+ kpiId,
//         {
//           headers: {
//             "Content-type": "application/json",
//             Accept: "application/json",
//             Pragma: "no-cache"
//           }
//         }
//       )
//       .then(response => {
//         dispatch(setBlockingState(false));
//         dispatch({
//           type: "CEA_NET_KPI_DEATAILS",
//           payload: response.data
//         });
//       })
//       .catch(function (error) {
//         // dispatch(setBlockingState(false));
//         console.error(error);
//       });
//   };
// }
export function fetchCeaNetKPI(kpiId) {
  return function (dispatch) {
  dispatch(getCallWithReducer("/kpi/getKpiDetails/"+ kpiId,null,"CEA_NET_KPI_DEATAILS"))
  };
}
/**
 * Delete kpi from DB.
 */
 export function deleteCeaNetKpi(kpiId,packageProgramMappingId,activeProgramID,userMdl) {
  return function (dispatch) {
    dispatch(setBlockingState(true));
    axios
      .delete("/kpi/ceaKpi/" + kpiId, {
        headers: {
          'Authorization': 'Bearer ' + getToken(),
          "Content-type": "application/json",
          Accept: "application/json",
          Pragma: "no-cache"
        }
      })
      .then(response => {

        if(response.data.desc==='cea_kpi_deleted'){
          toastr.success(I18n.t("CeaKpi.kpiDeleted"));
        }
        else if(response.data.desc==='lexus_published/processed_kpi_and_linkedKpis_deleted'){
          toastr.success(I18n.t("LexusKpi.kpiDeleted"));
        }
        else if(response.data.desc==='published/processed kpi deleted successfully'){
          toastr.success(I18n.t("LexusKpi.kpiDeleted"));
        }
        else if(response.data.desc==='kpi_actuals_present'){
          toastr.error(I18n.t("CeaKpi.kpiActualsError"));

        }else if(response.data.desc==='cea_kpi_not_found'){
          toastr.error(I18n.t("CeaKpi.kpiDeleteError"));
        }
        dispatch(setBlockingState(false));
        
        dispatch(fetchBusinessFunctionPoints(packageProgramMappingId,activeProgramID ));
        dispatch(fetchCeaNetKpiList(packageProgramMappingId,activeProgramID,userMdl ));

      })
      .catch(function (error) {
        dispatch(setBlockingState(false));
        console.error(error);
      });
  };
}

export function copyCeaKpi(activeProgramID, programName, fromYear, toYear, userDetails) {

  return function (dispatch) {
    dispatch(setBlockingState(true));
    axios
      .post(
        "/kpi/copyCeaKpi/" + activeProgramID + "/" + fromYear + "/" + toYear , userDetails,
        {
          headers: {
            'Authorization': 'Bearer ' + getToken(),
            "Content-type": "application/json",
            Accept: "application/json",
            Pragma: "no-cache"
          }
        }
      )
      .then(response => {
        dispatch(setBlockingState(false));
        console.log(response.data)

        if(response.data.desc==='cea_kpi_already_copied'){
          toastr.error(I18n.t("Copy KPI already completed for " + programName + " and " + toYear));
        }else if(response.data.desc==='cea_kpi_already_available'){
          toastr.error(I18n.t("KPI(s) already available for " + programName + " and " + toYear));
        } else if(response.data.desc==='cea_no_points_for_from_year'){
          toastr.error(I18n.t("Please define points for business functions for " + programName + " and " + fromYear));
        } else if(response.data.desc==='cea_no_points_for_to_year'){
          toastr.error(I18n.t("Please define points for business functions for " + programName + " and " + toYear));
        } else if(response.data.desc==='cea_no_kpi_found'){
          toastr.error(I18n.t("No KPI(s) exists to copy from " + fromYear + " to " + toYear));
        } else if(response.data.desc==='cea_kpi_copied'){
          toastr.success(I18n.t("KPI(s) copied Successfully to " + toYear));
        }

        
        dispatch(fetchCopyKpiHistory(activeProgramID, 1, 10));
      })
      .catch(function (error) {
        dispatch(setBlockingState(false));
        console.error(error);
      });
  };
}

export function copyNetUcKpi(copyKpiDto, activeProgramID, programName, fromYear, toYear) {
  return function (dispatch) {
    dispatch(setBlockingState(true));
    axios
      .post("/kpi/copyNetUcKpi", copyKpiDto, {
        headers: {
          'Authorization': 'Bearer ' + getToken(),
          "Content-type": "application/json",
          Accept: "application/json",
          Pragma: "no-cache"
        }
      })
      .then(response => {
        dispatch(setBlockingState(false));
        console.log(response.data)
        if(response.data.desc==='cea_kpi_already_copied'){
          toastr.error(I18n.t("Copy KPI already completed for " + programName + " and " + toYear));
        }else if(response.data.desc==='cea_kpi_already_available'){
          toastr.error(I18n.t("KPI(s) already available for " + programName + " and " + toYear));
        } else if(response.data.desc==='cea_no_kpi_found'){
          toastr.error(I18n.t("No KPI(s) exists to copy from " + fromYear + " to " + toYear));
        } else if(response.data.desc==='cea_kpi_copied'){
          toastr.success(I18n.t("KPI(s) copied Successfully to " + toYear));
        } else if(response.data.desc==='toolbox_already_present'){
          toastr.error(I18n.t("Business Functions already present"));
        } else if(response.data.desc==='toolbox_not_present'){
          toastr.error(I18n.t("Data is not present to copy"));
        } else {
          toastr.error(I18n.t("Something Went Wrong"));
        }       
        dispatch(fetchCopyKpiHistory(activeProgramID, 1, 10));
      })
      .catch(function (error) {
        dispatch(setBlockingState(false));
        console.error(error);
      });
  };
}

export function copyLexusKpi(copyKpiDto, activeProgramID, programName, fromYear, toYear) {
  return function (dispatch) {
    dispatch(setBlockingState(true));
    axios
      .post("/kpi/copyLexusKpi", copyKpiDto, {
        headers: {
          'Authorization': 'Bearer ' + getToken(),
          "Content-type": "application/json",
          Accept: "application/json",
          Pragma: "no-cache"
        }
      })
      .then(response => {
        dispatch(setBlockingState(false));
        console.log(response.data)
        if(response.data.desc==='cea_kpi_already_copied'){
          toastr.error(I18n.t("Copy KPI already completed for " + programName + " and " + toYear));
        }else if(response.data.desc==='cea_kpi_already_available'){
          toastr.error(I18n.t("KPI(s) already available for " + programName + " and " + toYear));
        } else if(response.data.desc==='cea_no_kpi_found'){
          toastr.error(I18n.t("No KPI(s) exists to copy from " + fromYear + " to " + toYear));
        } else if(response.data.desc==='lexus_kpi_copied'){
          toastr.success(I18n.t("KPI(s) copied Successfully to " + toYear));
        } else if(response.data.desc==='toolbox_already_present'){
          toastr.error(I18n.t("Business Functions already present"));
        } else if(response.data.desc==='toolbox_not_present'){
          toastr.error(I18n.t("Data is not present to copy"));
        } else {
          toastr.error(I18n.t("Something Went Wrong"));
        }       
        dispatch(fetchCopyKpiHistory(activeProgramID, 1, 10));
      })
      .catch(function (error) {
        dispatch(setBlockingState(false));
        console.error(error);
      });
  };
}

// export function fetchCopyKpiHistory(activeProgramID, page, sizePerPage) {
//   return function (dispatch) {
//     dispatch(setBlockingState(true));
//     axios
//       .get(
//         "/kpi/copyKpiHistory/" + activeProgramID + "/" + page + "/" + sizePerPage,
//         {
//           headers: {
//             "Content-type": "application/json",
//             Accept: "application/json",
//             Pragma: "no-cache"
//           }
//         }
//       )
//       .then(response => {
//         dispatch(setBlockingState(false));
//         dispatch({
//           type: "COPY_KPI_HISTORY_DATA",
//           payload: response.data
//         });
//       })
//       .catch(function (error) {
//         dispatch(setBlockingState(false));
//         console.error(error);
//       });
//   };
// }
export function fetchCopyKpiHistory(activeProgramID, page, sizePerPage) {
  return function (dispatch) {
    dispatch(setBlockingState(true));
  dispatch(getCallWithReducer("/kpi/copyKpiHistory/" + activeProgramID + "/" + page + "/" + sizePerPage,null,"COPY_KPI_HISTORY_DATA"))
  };
}