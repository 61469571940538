import React from "react";
import { connect } from "react-redux";
import { Container, MDBIcon } from "mdbreact";
import { withRouter } from "react-router-dom";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from 'react-bootstrap-table2-paginator';
import moment from 'moment';
import {getBulkDownloadList,downloadTemplateFile,downloadData} from "../../../actions/bulkUploadAction";
import { withTheme } from "../../../themes/theming";
import * as AppConst from '../../../AppConstant';
import "./../index.css";
class DownloadPanel extends React.Component {
    constructor(props) {
        super(props);
        let fileType = "BRIT_DOWNLOAD_ACTIVITY";
        console.log(this.props.createQuestionnaireFlag);
        if(this.props.user.userMdl.flagValueChain){
            if(this.props.createActivityFlag){
                fileType = "VALUE_CHAIN_ACTIVITY_DOWNLOAD";
            }
            else if(this.props.createQuestionnaireFlag){
                fileType = "VC_QUESTIONNAIRE_DOWNLOAD";
            }
            else{
            fileType = "VALUE_CHAIN_KPI_DOWNLOAD";
            }        
        }
        if(this.props.user.userMdl.flagEA){
            if(this.props.createActivityFlag){
                fileType = "EA_ACTIVITY_DOWNLOAD";
            }
        }
        
        if(this.props.user.userMdl.flagTSP){
            if(this.props.createActivityFlag){
                fileType = "TSP_ACTIVITY_DOWNLOAD";
            }
        }
        

        if(this.props.user.userMdl.activeProgram === AppConst.activeProgramUc){
            fileType = "USED_CARS_BULK_DOWNLOAD";
        }
        if(this.props.user.userMdl.activeProgram === AppConst.activeProgramNet){
            fileType = "NETWORK_BULK_DOWNLOAD";
        }
        if(this.props.user.userMdl.activeProgram === AppConst.activeProgramLN){
            fileType = "LEXUS_NETWORK_BULK_DOWNLOAD";
        }
        this.state = {
            fileCriteria: {
                sizePerPage: 10,
                page: 1,
                fileType: fileType
            },
            totalDisplaySize: 0
        };
    }


    componentWillMount() {
       
    }

    componentDidMount() {
        console.log(this.props.user.userMdl.flagValueChain,this.props.createActivityFlag);
    //    this.props.dispatch(getBulkDownloadList(this.state.downloadDto));
       let fileCriteria = { ...this.state.fileCriteria };
       let fileType = "BRIT_DOWNLOAD_ACTIVITY";
        if(this.props.user.userMdl.flagValueChain){
            if(this.props.createActivityFlag){
                fileType = "VALUE_CHAIN_ACTIVITY_DOWNLOAD";
            }
            else if(this.props.createQuestionnaireFlag){
                fileType = "VC_QUESTIONNAIRE_DOWNLOAD";
            }
            else{
            fileType = "VALUE_CHAIN_KPI_DOWNLOAD";
            }        
        }
        if(this.props.user.userMdl.flagEA){
            if(this.props.createActivityFlag){
                fileType = "EA_ACTIVITY_DOWNLOAD";
            }
        }

        if(this.props.user.userMdl.flagTSP){
            if(this.props.createActivityFlag){
                fileType = "TSP_ACTIVITY_DOWNLOAD";
            }
        }
        

        if(this.props.user.userMdl.activeProgram === AppConst.activeProgramUc){
            fileType = "USED_CARS_BULK_DOWNLOAD";
        }
        if(this.props.user.userMdl.activeProgram === AppConst.activeProgramNet){
            fileType = "NETWORK_BULK_DOWNLOAD";
        }
        if(this.props.user.userMdl.activeProgram === AppConst.activeProgramLN){
            fileType = "LEXUS_NETWORK_BULK_DOWNLOAD";
        }
       fileCriteria["username"] = this.props.user.userMdl.username;
       fileCriteria["fileType"] = fileType;
       this.props.dispatch(downloadData(fileCriteria));
       this.props.dispatch(getBulkDownloadList(fileCriteria));

    }

    componentWillReceiveProps(nextProps) {
    }

    // topToBottom = () => {
    //     return (
    //         <Row>
    //             <div className="col-md-12 col-xs-12 col-sm-12 col-lg-12">
    //                 <Col md="2" style={{ marginTop: '-1px', float: "Right", width: "auto" }}>
    //                     <a onClick={() => { window.scrollTo(0, 0) }}>
    //                         <span style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>{I18n.t("standardMessages.backToTop")}
    //                             <Fa icon={"arrow-circle-up"} fixed size="3x" />
    //                         </span>
    //                     </a>
    //                 </Col>
    //             </div>
    //         </Row>
    //     )
    // }
    

    handleTableChange = (type, { page, sizePerPage, sortField, sortOrder }) => {

        console.log(page)
        console.log(sizePerPage)
        console.log(this.state.fileCriteria)


        let fileCriteria = { ...this.state.fileCriteria }
        fileCriteria["page"] = page;
        fileCriteria["sizePerPage"] = sizePerPage;
        fileCriteria["username"] = this.props.user.userMdl.username;
        // this.props.dispatch(getBulkUploadList(fileCriteria));
        this.setState({ fileCriteria });
    }

    dateFormatterUploaded = (columns, row) => {
        return moment(row.createdTimestamp).format("DD/MM/YYYY hh:mm:ss A")

    }

    dateFormatterUpdated = (columns, row) => {
        return moment(row.updatedTimestamp).format("DD/MM/YYYY hh:mm:ss A")

    }

    actionColumn = (cell, row, rowIndex) => {
        console.log(row.errorFilePath);
        let fileType = "BRIT_DOWNLOAD_ACTIVITY";
        if(this.props.user.userMdl.flagValueChain){
            if(this.props.createActivityFlag){
                fileType = "VALUE_CHAIN_ACTIVITY_DOWNLOAD";
            }
            else if(this.props.createQuestionnaireFlag){
                fileType = "VC_QUESTIONNAIRE_DOWNLOAD";
            }
            else{
            fileType = "VALUE_CHAIN_KPI_DOWNLOAD";
            }        
        }
        if(this.props.user.userMdl.flagEA){
            if(this.props.createActivityFlag){
                fileType = "EA_ACTIVITY_DOWNLOAD";
            }
        }

        if(this.props.user.userMdl.flagTSP){
            if(this.props.createActivityFlag){
                fileType = "TSP_ACTIVITY_DOWNLOAD";
            }
        
        }
        

        if(this.props.user.userMdl.activeProgram === AppConst.activeProgramUc){
            fileType = "USED_CARS_BULK_DOWNLOAD";
        }
        if(this.props.user.userMdl.activeProgram === AppConst.activeProgramNet){
            fileType = "NETWORK_BULK_DOWNLOAD";
        }
        if(this.props.user.userMdl.activeProgram === AppConst.activeProgramLN){
            fileType = "LEXUS_NETWORK_BULK_DOWNLOAD";
        }
        let downloadFileDTO={
            username:this.props.user.userMdl.username,
            nmsc:"",
            lang:"",
            fileType:fileType

        }
        return (
            <div className="btn-toolbar">
                <MDBIcon icon="download" className="customSundryTableIcon" size="lg" style={{ cursor: 'pointer' }} 
                 onClick={(e) => this.props.dispatch(downloadTemplateFile(row.id,row.fileName, downloadFileDTO))} 
                />
            </div>
        );
    }



    render() {

        console.log(this.props.bulkUpload)

        let data = this.props.bulkUpload.downloadActivityFileList && this.props.bulkUpload.downloadActivityFileList.dataList && this.props.bulkUpload.downloadActivityFileList.dataList.length > 0 ? this.props.bulkUpload.downloadActivityFileList.dataList : [];
        let page = this.state.fileCriteria.page;
        let sizePerPage = this.state.fileCriteria.sizePerPage;
        let totalSize = this.props.data ? this.props.data.recordCount : 10;

        console.log(page +":" +sizePerPage+":"+totalSize)
        const columns = [

            {
                dataField: "fileName",
                text: "File Name",
                sort: true,
                style: { width: "51%", textAlign: "left" },
                headerStyle: { width: "50.9%", textAlign: "left", paddingLeft: "80px" },
                title: true
            },
        
            {
                dataField: "updatedTimestamp",
                text: "Last updated on",
                sort: true,
                formatter: this.dateFormatterUpdated,
                style: { width: "29%" },
                headerStyle: { width: "28.9%" },
                // title: true
            },
            {
                dataField: "",
                text: "Action",
                sort: true,
                formatter: this.actionColumn,
                style: {
                    width: "19%", display: "flex",
                    justifyContent: "center"
                },
                headerStyle: {
                    width: "18.9%",
                },
                // title: true

            }
        ];

        return (

            <Container
                fluid
                className="ml-0 mb-2 pb-0"
                >

                <div className={'downloadTable parent-' + this.props.theme.name}>
                    <BootstrapTable
                        remote
                        keyField="id"
                        noDataIndication={"No data to view"}
                        data={data}
                        columns={columns}
                        pagination={paginationFactory({ page, sizePerPage, totalSize })}
                        //rowEvents={rowEvents}
                    />
                      {/* {this.topToBottom()} */}
                </div>

            </Container>


        );

    }

}


const mapStateToProps = state => {
    return {
        user: state.loginReducer,
        blocking: state.loginReducer,
        router: state.routing,
        bulkUpload: state.bulkUploadReducer
    };
};
export default withRouter(
    connect(mapStateToProps)(withTheme(DownloadPanel))
);

