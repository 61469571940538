import React from "react";
import { Modal, ModalBody, ModalHeader, ModalFooter, Row } from "reactstrap";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { withTheme } from "../../../themes/theming";
import { Container } from "react-bootstrap";
import { toastr } from "react-redux-toastr";
import {saveFolderToDB,saveNmscFolderToDB} from '../../../actions/libraryAction';
var I18n = require("react-redux-i18n").I18n;

class AddEditFolder extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            folderName: ''
        }
    }
    componentDidMount() {
        if (this.props.parentFolder && this.props.modalFor === 'Edit') {
            this.setState({ folderName: this.props.parentFolder.name });
        }
    }
    changeFolderName = e => {
        this.setState({ folderName: e.target.value });
    }
    saveFolder = () => {
        if (this.state.folderName.length === 0) {
            toastr.error(I18n.t('library.emptyFolderName'));
            return;
        }
		//Code change for QCCSSM-1808
		let letterPosition = this.state.folderName.indexOf('/');
		if (letterPosition > -1) {
			toastr.error(I18n.t('library.invalidFolderName'));
			return;
		}
        // Saving New Folder in database.
     // alert(this.props.parentFolder.id + "_" + this.state.folderName);
     if(this.props.isNmsc){
        let nmscFolderDTO = {
            parentFolderId : this.props.parentFolder ? this.props.parentFolder.id : 0,
            folderName: this.state.folderName,
            action: this.props.modalFor,
            userId: this.props.user.userMdl.username,
            toolboxId: this.props.toolboxId,
            nmscCode: this.props.nmscCode,
            nmscLang: this.props.langCode,
            packageMapPrimaryKey: this.props.packageMapPrimaryKey
        };
        this.props.dispatch(saveNmscFolderToDB(nmscFolderDTO));
        }
        else{
     let folderDTO = {
         parentFolderId : this.props.parentFolder ? this.props.parentFolder.id : 0,
         folderName: this.state.folderName,
         action: this.props.modalFor,
         userId: this.props.user.userMdl.username,
         toolboxId: this.props.toolboxId,
         packageMapPrimaryKey: this.props.packageMapPrimaryKey
     };
        this.props.dispatch(saveFolderToDB(folderDTO));
        }
    }
    preventDefault() {}
    render() {
        let isAddModal = this.props.modalFor && this.props.modalFor === "Add" ? true : false;
        return (
            <Modal centered
                className={"addEditModal-" + this.props.theme.name}
                isOpen={true} style={{ maxWidth: '20%!important' }}
                toggle={() => this.preventDefault()} >
                <ModalHeader>
                    {isAddModal ? I18n.t("library.addFolder") :
                        I18n.t("library.editFolder")}
                </ModalHeader>
                <ModalBody>
                    <Container>
                        {isAddModal && this.props.parentFolder ?
                            <div>
                                <Row>
                                    <label className={"questionLabel"}>
                                        {I18n.t("library.parentFolder")}
                                    </label>
                                    <input type="text"
                                        className="form-control black-font"
                                        defaultValue={this.props.parentFolder.name}
                                        disabled="disabled" />
                                </Row>
                                <br />
                            </div>
                            : <div></div>}
                        <br />
                        <Row>
                            <label className={"questionLabel"}>
                                {I18n.t("library.currentFolder")}
                                <span className="redFont"> *</span>
                            </label>
                            <input type="text"
                                className="form-control"
                                maxLength="50"
                                value={this.state.folderName} onChange={this.changeFolderName} />
                        </Row>
                        <br />
                    </Container>
                </ModalBody>
                <ModalFooter>
                    <button
                        className={"buttonCancel-" + this.props.theme.name}
                        onClick={() => this.props.toggle(this.props.modalFor)}
                    >
                        {I18n.t("tmeAdminMaster.cancel")}
                    </button>
                    <button
                        className={"button-" + this.props.theme.name}
                        onClick={this.saveFolder}
                    >
                        {this.props.modalFor === "Add" ?
                            I18n.t("tmeAdminMaster.save") :
                            I18n.t("tmeAdminMaster.update")}
                    </button>
                </ModalFooter>
            </Modal>
        );
    }
}
const mapStateToProps = state => {
    return {
        user: state.loginReducer
    };
};
export default withRouter(connect(mapStateToProps)(withTheme(AddEditFolder)));
