const translationsHB = {
  hb: {
    Policy: {
      HeaderMsg:
        "הערת פרטיות לעיבוד הדאטה במערכת זו",
      Message1:
        "הערת הפרטיות מתייחסת לעיבוד הדאטה במערכת זו. הערה זו חייבת להיות מתואמת עם טויוטה וחוקי הפרטיות וכדי להמשיך  ולעבד את המידע עליך לאשר.",
      Message2:
        "טויוטה אירופה, עם משרדי המטה הממוקמים בשדרת Bourget/Bourgetlaan 60, 1140, בבריסל, בלגיה, אוספת מידע פרטי (כפי שמצויין מטה) למטרות כפי שמתואר מטה.",
      Message3: "אילו פרטים אישיים יאספו?",
      Message4:
        "במערכת זו, אנו נאסוף את הפרטים האישיים לפי הקטיגוריות הבאות:",
      Table1: {
        tr1: "קטגוריות",
        tr2: " משתמש",
        tr3: "User Name (Full Name)",
        tr4: "Brand (Toyota, Lexus)",
        tr5: "Language preferred",
        tr6: "שיוך ארגוני"
      },

      Message5:
        "הסיבה לעיבוד פרטיך.",
      Message6:
        "פרטיך יעובדו לצורך ובהתאם להנחיות החוקיות לפי:",
      Table2: {
        tr1: {
          th1: "הסיבה:",
          th2: " בסיס חוקי:"
        },
        tr2: {
          td1: "KET -1: קייזן - ביצועי סוכנות",
          td2: "עניין חוקי תחת בקרת נתונים"
        },
        tr3: {
          td1: "KET -2: קייזן - תמיכת IT",
          td2: "עניין חוקי תחת בקרת נתונים"
        }
      },
      Message7: "משך שמירת הדאטה",
      Message8:
        "הדאטה שתשמר במשך הזמן שהוגדר תציין:",
      Table3: {
        tr1: {
          th1: "משך התקופה:",
          th2: "קטגוריות"
        },
        tr2: {
          td1: "הדאטה תשמר למקסימום 5 שנים + 1 חודש ממועד סגירת העבודה על המערכת (KET)",
          td2: "שם פרטי של המשתמש, שם משפחה, העדפת שפה, ארגון"
        }
      },
      Message9:
        "אפשרות: במקרה של חשיפת פרטייך האישיים שצויינו לעיל מחוץ לאירופה (EEA)",
      Message10:
        "פרטיך האישיים יהיו נגישים לגורמים מחוץ למדינות אירופה (EEA) לפי פרטי הזיהוי הבאים:",
      Table4: {
        tr1: {
          td1: "זיהוי",
          td2: "מדינה",
          td3: ""
        },
        tr2: {
          td1: "גורם מחוץ לאירופה (מחוץ ל EEA)",
          td2: "Infosys שם החברה:",
          td3: "הודו"
        }
      },
      Message11:
        "אני מאשר/ת שהבנתי הודעה זו; קראתי וקיבלתי את מדיניות הפרטיות הכללית של טויוטה והגנת הפרטיות."
    },
    ACCESSORIES: "אביזרים",
    activity: {
      Assignee: "מקבל ההקצאה",
      CompletionDate: "תאריך השלמה",
      Dealer: "סוכנות",
      FirstVisit: "ביקור ראשון",
      kpi: {
        kpi: "ה KPI יכול להיות עם מספרים חיוביים או שליליים",
        kpiLength: "מקסימום תווים ל KPI הינו 10",
        mandatory: "הזן נתונים חוקיים בכל שדות החובה",
        mandatoryTargets: "נא להכניס את כל ה KPI",
        target: "Target should be numbers (+ve or -ve).",
        targetLength: "The maximum length for target is 10 characters."
      },
      ReminderOn: "תזכורת בתאריך",
      statusDD: "מצב",
      Toolbox: "ארגז כלים",
      activityCreation: "Activity Creation process has been started",
      activityCreated: "activity(s) have been created"
    },
    DPOK: "חלפים",
    EFFICIENCY: "יעילות",
    FIX_IT_RIGHT: "תיקון נכון ",
    footer: {
      message: "שדות חובה"
    },
    FOUNDATION: "אבחון בסיסי",
    MAINTENANCE_REMINDERS: "זימון תורים",
    EMPLOYEE_ENGAGEMENT: "Employee Engagement",
    CUSTOMER_CENTRICITY: "Customer Centricity",
    TOYOTA_WAY: "Toyota Way",
    BUSINESS_SUSTAINABILITY: "Business Sustainability",
    FUNDAMENTALS: "Fundamentals",
    FIX_IT_RIGHT_BnP: "Fix it Right (B&P)",
    UTILIZATION_AND_EFFICIENCY: "Utilization and Efficiency",
    CUSTOMER_EXPERIENCE: "Customer Experience",
    UPSELLING_OPPORTUNITIES: "Upselling Opportunities",
    MANAGING_CAPACITY: "Managing Capacity",
    VEHICLE_TRADE_IN: "Vehicle Trade-In",
    STOCK_MANAGEMENT: "Stock Management",
    REFURBISHMENT: "Refurbishment",
    months: {
      apr: "אפר'",
      aug: "אוג'",
      dec: "דצמ'",
      feb: "פבר'",
      jan: "ינו'",
      jul: "יולי",
      jun: "יוני",
      mar: "מרץ",
      may: "מאי",
      nov: "נוב'",
      oct: "אוק'",
      sep: "ספט'"
    },
    others: {
      back: "חזרה",
      closed: "סגורה",
      completed: "הושלמה ",
      countryLanguageCode: "מדינה / קוד שפה ",
      createActivity: "צור פעילות",
      createdDate: "תאריך יצירה",
      dataUneditableNote:
        "תשומת לב: לא ניתן לערוך נתונים שכבר נשמרו. לשינוי נתונים אנא פנו למנהל המערכת ביבואן",
      date: "תאריך",
      downloadQuestionnaire: "הורד שאלון",
      editActivity: "ערוך פעילות",
      initialHelpText:
        "מדידת ה-KPI הראשונית צריכה להיות הערך הממוצע של ששת החודשים המלאים הקודמים",
      inProgress: "בתהליך",
      name: "שם",
      next: "הבא",
      noActivities: "לא נמצאו פעילויות",
      noResults: "אין תוצאות",
      of: "מתוך",
      ongoing: "פעילות מתמשכת ",
      open: "פתח",
      page: "עמוד",
      planned: "מתוכננת",
      problemDescription: "תיאור בעיה",
      signature: "חתימה",
      select: "סוכנות מוכנה להסמכה",
      bulkAction: "Bulk Action",
      bulkActions: "Bulk Actions"
    },
    ServerMessage: {
      error_message: "התרחשה תקלה בעת ההורדה ",
      no_published_subquestion: "כדי להתחיל את הפעילות נא לפרסם את השאלון ",
      activity_upload_error_msg: "תקלה בזמן העלאת פרטי הפעילות.",
      action_plan_created: "תכנית הפעולה נוצרה בהצלחה",
      action_plan_deleted: "תכנית הפעולה נמחקה בהצלחה",
      action_plan_saved: "תכנית הפעולה נשמרה בהצלחה",
      activity_kpi_saved: "פעילות ה KPI נשמרה בהצלחה",
      master_kpi_confirm_delete:
        "Confirm if you want to delete the selected KPI.",
      master_kpi_created: "ממד ראשי נוצר בהצלחה",
      master_kpi_deleted: "מדד ראשי נמחק",
      master_kpi_not_found: "מדד ראשי לא נמצא ",
      master_kpi_published: "מדדים ראשיים שנבחרו פורסמו בהצלחה",
      master_kpi_retired: "מדד ראשי הועבר ל'לא בשימוש'",
      master_kpi_saved: "מדד ראשי נשמר בהצלחה",
      subquestion_created: "הסעיף נשמר בהצלחה.",
      subquestion_updated: "הסעיף עודכן בהצלחה.",
      subquestionnaire_not_found: "הסעיף לא נמצא.",
      subquestion_retired: "הסעיף הוסר.",
      subquestion_deleted: "הסעיף נמחק.",
      subquestion_published: "הסעיף פורסם בהצלחה.",
      mainquestion_created: "השאלון הראשי נוצר.",
      mainquestion_deleted: "השאלון הראשי נמחק.",
      mainquestion_updated: "השאלה הראשית עודכנה.",
      duplicateMsg: "רשומה כפולה בקובץ האקסל.",
      deleteErr: "תקלה בזמן מחיקת הקובץ.",
      toolbox_deleted: "Toolbox has been deleted successfully",
      toolbox_already_exists: "This toolbox already exists.",
      toolbox_saved: "Toolbox saved",
      toolbox_created: "Toolbox Created",
      toolbox_not_found: "Toolbox not found",
      published_content_found: "This toolbox cannot be deleted as it has some published content",
      dealers_already_mapped: " Dealers already mapped ",
      dealer_already_mapped: " Dealer already mapped ",
      dealer_added: "  Dealer added. ",
      dealers_added: "  Dealer added. ",
      total_dealers_mapped_now: "Total dealers mapped now are ",
      no_dealer_available: "No Dealer available for this assign criteria",
      proper_dealer_selected: "Proper dealer shall be selected",
      user_referenced_ongoing_activity: "User is referenced in Ongoing activity/activities. ",
      want_to_remove_mapping: "Are you sure you want to remove mapping?",
      user_mapping_removed: "Selected dealers unmapped successfully",
      dealer_closed_in_A2D: "Dealer not active in A2D",
      network_level_not_found: "Please select valid Network levels",
      duplicate_dealer_uuid: "Dealer already available",
      dealer_created: "Dealer Created",
      dealer_updated: "Dealer Updated",
      dealer_not_present: "Dealer not present in A2D",
      dealer_uuid_other_nmsc: "Please Enter Dealer Uuid for Same NMSC",
      target_kpi_updated: "The KPI's status have been updated successfully.",
      target_updated: "The KPI's target have been updated successfully.",
      kpi_launched: "The KPI's bulk launch successfully.",
      targetUpdate:"Application doesn't allow target to be modified for accepted status.",
    },
    standardMessages: {
      delete: "?בטוח למחוק",
      addActionPlanNotAllowed: "תכנית הפעולה אינה יכולה להיווצר או להיערך כאשר היא סגורה, הושלמה, נדחתה ",
      addToolboxNotAllowed: "Cannot create more than 19 toolbox",
      britRejectMsg: "לדחות את הפעילות? שינויים לא אפשריים לאחר דחיה",
      rejectMsg: "לדחות את הפעילות? שינויים לא אפשריים לאחר דחיה",
      selectNMSCTab: "Please select nmsc language to publish",
      all: "הכל",
      deleteConfirm: "האם אתה בטוח שברצונך למחוק את מדד ה-KPI שנבחר?",
      no: "לא",
      retireConfirm:
        "האם אתה בטוח שברצונך להוציא משימוש את מדד ה-KPI שנבחר? לאחר הוצאתו משימוש, לא תוכל להפעיל מחדש מדד KPI זה",
      warning: "אזהרה",
      yes: "כן",
      saveDataConfirmation: "בטוח/ה שברצונך לשמור את הנתונים?",
      saveKPIWarning:
        "האם אתה בטוח שברצונך לשמור את הנתונים? שים לב: לא ניתן לערוך את הנתונים לאחר שמירתם. כדי לשנות את הנתונים, פנה למנהל מערכת של NMSC KET",
      addKpiNotAllowed: "ניתל לעדכן את מדדי ה KPI של החודש הבא בסיום החודש הנוכחי.",
      activityLaunch: "תרצו להתחיל את הפעילות? לאחר התחלת הפעולה לא ניתן לחזור אחורה, לסטטוס המתוכנן או מחיקה.",
      deleteConfirmSubQuestionNaire: "בטוח/ה שברצונך למחוק את הסעיף?",
      retireConfirmSubQuestionNaire: "בטוח/ה שברצונך להסיר את הסעיף?",
      deleteConfirmMainQuestion: "נא לאשר את מחיקת השאלה הראשית והסעיף הקשורים אליה.",
      deleteConfirmChapter: "בטוח/ה שברצונך למחוק את הפרק.",
      deleteLibraryFolder: "בטוח/ה שברצונך למחוק את התיקיה.",
      deletePublishedFolder: "יתכן שחלק מהקבצים בתיקיה זו מקושרים לשאלון. בטוח שתרצו למחוק את תת התיקיה ולהסיר את הקישורים מהשאלון?",
      importQuestion: "בטוח/ה שברצונך לייבא את הסעיף זה? הסעיף הנוכחי יוחלף.",
      noQuestion: "אין שאלות זמינות.",
      MandatoryWarning: "נא למלא את שדות החובה לכל השפות המקומיות.",
      certificationSubmission:
        "Toolbox is submitted to TME for dealer certification",
      sameToolBox: "You cannot submit same Toolbox more than once. Please select different toolbox",
      deleteConfirmDealer: "Are you sure you want to delete this dealer ?",
      updateVcConfirmDealer: "All Activities created for this dealer in 'Value Chain' will be removed. Are you sure you want to continue?",
      updateBritConfirmDealer: "All Activities created for this dealer in 'BRiT' will be removed. Are you sure you want to continue?",
      completed_activity_delete:"Data cannot be retrieved once deleted. Are you sure you want to delete ?",
      deleteAllQuestionary: "Are you sure you want to delete the entire Questionnaire?",
      deleteAllLibraries: "Are you sure you want to delete all folders and files in the Library?",
      selectAll: "לבחור הכל",
      unSelectAll: "לא לבחור הכל",
      select:"בחירה...",
      warningMsg:"ישנן תוכניות פעולה פתוחות בפעילות זו. האם להשלים את הפעילות?"
    },
    status: {
      published: "פורסם",
      retired: "לא בשימוש",
      saved: "נשמר",
      rejected: "נדחה",
      open: "פתח",
      closed: "סגורה",
      close: "סגורה",
      ongoing: "פעילות מתמשכת ",
      completed: "הושלמה",
      complete: "הושלמה",
      planned: "מתוכננת",
      overdue: "באיחור",
      due: "עד ל",
      pending: "Pending",
    },
    Rejected: "Rejected",
    Open: "פתח",
    Closed: "סגורה",
    Close: "סגורה",
    Ongoing: "פעילות מתמשכת ",
    Completed: "הושלמה",
    Complete: "הושלמה",
    Planned: "מתוכננת",
    tabDetails: {
      noActionAvailable: "אין פעולות זמינות",
      activeDealer: "Dealer is active.",
      dealerDeleted: "Dealer is deleted."
    },
    Table: {
      action: "",
      activity: "פעילות",
      activityCompletionDate: "תאריך סיום פעילות",
      activityCompletionDateFrom: " תאריך השלמת פעילות מ",
      activityCompletionDateTo: " תאריך השלמת פעילות ל",
      activityStartDate: "תאריך התחלת פעילות",
      activityStartDateFrom: "תאריך תחילת פעילות מ",
      activityStartDateTo: "תאריך התחלת פעילות ל",
      assignedTo: "מוקצה ל-",
      assignee: "מקבל ההקצאה",
      benchmark: "מדד להשוואה ",
      city: "עיר",
      completedBy: "הושלם על-ידי ",
      completionBy: "Completion by",
      completionDate: "תאריך השלמה",
      counterMeasure: "אמצעי נגד",
      country: "מדינה",
      createdBy: "נוצר על-ידי",
      dealer: "סוכנות",
      dealerNameAD: "Dealer name (A2D)",
      dealerName: "שם הסוכנות",
      definition: "הגדרה",
      description: "תיאור",
      dueDate: "תאריך יעד",
      endDate: "תאריך סיום",
      firstVisit: "ביקור ראשון",
      followup: "מעקב",
      franchise: "סוכנות ",
      history: "היסטוריה",
      id: "זיהוי ",
      initial: "ראשוני",
      kpi: "מדד ",
      launch: "התחלה ",
      mandatory: "חובה",
      nmsc: "יבואן",
      problem: "בעיה",
      publishedOn: "תאריך פרסום",
      questionReference: "סימוכין לשאלות",
      reference: "סימוכין",
      reminderOn: "תזכורת בתאריך",
      score: "ציון ",
      startDate: "תאריך התחלה",
      status: "מצב",
      target: "Target",
      toolbox: "ארגז כלים",
      view: "הצגה",
      kpiResult: "תוצאות KPI",
      nmscTarget: "NMSC Target",
      penEbenchmark: "PanE Benchmark",
      kpiField: "מדד",
      location: "מיקום",
      ActionItemReport: "משימות בתהליך",
      certification: "סטטוס הסמכה",
      noOfToolbox: "אין ארגזי כלים שהושלמו",
      program: "תכנית",
      certificationDate: "תאריך הסמכה",
      activityId : 'Activity ID',
      approvedBy:"Approved By",
      kpis:"KPIs",
      lastmonthKpis:"Last Month KPIs",
      questionPercent:"Completion %",
      actionPlan: "תוכנית פעילות",
      monthlyKpi:"Monthly KPIs",
      initialKpi:"Initial KPIs",
      targetKpi:"Target KPIs",
    },
    tabsDetails: {
      actionPlan: "תוכנית פעילות",
      confirmDeleteMessage: "נא לאשר את מחיקת המדד הנבחר",
      confirmRetireMessage: "אישור להסרת המדד שנבחר מפני שכבר פורסם קודם",
      errorRetireMsg:
        "לא ניתן להוציא משימוש מדד KPI זה מכיוון שהוא מדד KPI חובה ב-TME פעיל",
      import: "יבוא",
      importKpiMessage: "אנא ייבא את מדדיך מרשימת טויוטה אירופה",
      kpi: "ממדי ביצוע ",
      library: "ספרייה",
      noActionAvailable: "אין פעולות זמינות",
      NoKPI: "אין מדדים זמינים",
      publish: "פרסום",
      questionnaire: "שאלון",
      tmeKPIList: "רשימת ממדי טויוטה אירופה",
      toyotaCityBrussels: "TOYOTA CITY BRUSSELS",
      attachment: "Attachment",
      attachments: "Attachment(s)",
      uploadAttachment: "Upload Attachment",
      noAttachment: "No attachments available",
    },
    tmeAdminMaster: {
      ToolboxName: "שם חדש",
      newToolboxName: "שם ארגז הכלים ",
      deleteToolbox: "שם ארגז הכלים",
      programName: "שם התכנית ",
      ToolboxName_EN: "שם ארגז הכלים באנגלית",
      deleteTool: "בטוח שצריך למחוק את ארגז כלים?",
      selectToolbox: "נא לבחור את ארגז הכלים למחיקה",
      editToolBox: "עריכת ארגז כלים",
      addToolbox: "הוספת אז כלים",
      toolbox: "נא להכניס שם לארגז כלים זה",
      addActionPlan: "Add תוכנית פעילות",
      actionPlanItem: "תוכנית פעולה - פריט #",
      add: "הוספה",
      addActivity: "הוסף פעילות",
      additionalInformation: "מידע נוסף",
      benchmark: "בחינת ביצועים",
      cancel: "ביטול",
      createEditActivity: "יצירת / עריכת פעילות",
      createKPI: "יצירת מדד ",
      delete: "מחיקה",
      download: "הורדה",
      edit: "עריכה",
      editKPI: "עריכת מדד ",
      keyPerformanceIndicator: "מדד ביצוע",
      kpiDefinition: "הגדרת מדד ",
      mandatoryKpi: "מדד חובה",
      save: "שמירה",
      search: "חיפוש",
      unit: "יחידה",
      update: "עדכון",
      validData: "נא להזין נתונים חוקיים",
      hide: "הסתרה",
      show: "הצגה",
      upload_dealer_network: "העלאת רשת סוכנים",
      upload_question: "העלאת שאלון",
      upload_user: "העלאת פרטי משתמשים",
      upload_activity: "העלאת פעילויות",
      remove: "Remove",
      toyota: "Toyota",
      lexus: "Lexus",
      launch: "Launch",
      close: "Close",
      generate: "Generate",
      dueDateExceed:"חריגה מתאריך יעד",
      delQuestionnaire:"Delete questionnaire"
    },
    toastr: {
      activityStatus_condition: "בכדי לסיים את הפעילות נא לענות על כל השאלות ",
      emptyTarget: "Please enter the NMSC target to publish the KPI",
      retailerActual: "Please enter the Retailer Actual",
      initialTargetMonthly: "יש להזין את מדידת ה-KPI הראשונית ואת יעדי ה-KPI לפחות אחת לחודש כדי להמשיך",
      initialTarget: " ע'מ להמשיך נא להכניס את ראשי התיבות של המדדים ויעדיהם",
      upload_error: "תקלה בזמן העלאת רשת הסוכנים.",
      action_item_create_warning: "ניתן למזג עד 5 רישומים לכל פריט פעולה.",
      actionPlanStartDateValidation:
        "תאריך הסיום לא יכול להיות לפני תאריך ההתחלה",
      actionsNotClosed:
        "קיימות פעולות בביצוע בתוכנית הפעולה; סגור את כל הפעולות לפני סגירת פעילות",
      activityDuplicate: "כבר קיימת פעילות אחרת עם אותה סוכנות ואותו ארגז כלים",
      assigneeNotExist: "מקבל ההקצאה שנבחר לא נרשם במערכת",
      assigneeValidation: "מקבל הקצאה לא חוקי",
      atleastOnePublish: "בחר לפחות מדד KPI אחד לפרסום",
      cannotRetireActive:
        "זהו מדד KPI חובה של TME פעיל שלא ניתן להוציא משימוש ",
      completionDate: "תאריך הסיום לא יכול להיות לפני תאריך הביקור הראשון",
      confirDeletePlannedActivity:
        "האם אתה בטוח שברצונך למחוק פעילות מתוכננת זו?",
      DateformatValidation: "Date should be in format DD/MM/YYYY",
      DateValidation: "From Date Should Be Less Than To Date",
      dealerValidation: "סוכנות לא חוקית",
      deleteSelectedConfirm: "האם אתה בטוח שברצונך למחוק את מדד ה-KPI שנבחר?",
      duplicate: "כבר קיים מדד KPI אחר עם אותו שם ואותה הגדרה",
      modalMandatory: "הזן נתונים חוקיים בכל שדות החובה",
      noPublishKpi: "מדד ה-KPI לא פורסם עדיין עבור תיבת הכלים שנבחרה",
      validSearchCriteria: "הזן קריטריון חיפוש חוקי",
      multiSelectPlaceHolder: "נא להכניס לפחות 5 תווים",
      multiSelectPlaceHolderUser: "נא להכניס לפחות 3 תווים",
      startDateValidation: "תאריך התחלה אינו יכול להיות גדול מתאריך סיום",
      fileSelectionErr: "נא לבחור את הקובץ הראשון",
      atleastOneQuestion: "נא לבחור לפחות סעיף אחד לייבא.",
      atleastOneSubQues: "נא לבחור לפחות תת סעיף אחד לפירסום",
      uploadErr: "כמות קבצים שנכשלו בהעלאתם:",
      uploadSuccess: "רשת הסוכנים הועלתה בהצלחה.",
      file_upload_success: "הקבצים הועלו בהצלחה.",
      KPIinOngoingClosedActivity: "This KPI is present in one, or more, Ongoing/Closed Activities.Please Complete these Activities before making any change to the KPI.",
      Role_Error: "The user setup is incomplete, please contact the NMSC KET Admin.",
      points_allocation_incorrect: "Points allocation is incorrect. Please modify.",
      activity_deleted:"Activity deleted successfully",
      activity_certified_dealer:"Dealer certified for the selected Activity, hence cannot be deleted",
      activity_submited_for_certificate_dealer:"The status of toolbox cannot be changed as request for certification has been sent to TME"
    },
    TopNav: {
      ActivityLog: "פעילויות",
      ApplicationName: "Kaizen Expert Tool",
      Home: "בית",
      logout: "יציאה",
      MasterDataUpload: "העלה נתונים ראשיים",
      Toolbox: "ארגזי כלים",
      welcome: "ברוכים הבאים",
      Admin: "אדמיניסטרטור"
    },
    questionnaire: {
      addSubQuestion: "הוספת סעיף.",
      editSubQuestion: "עריכת סעיף.",
      subQuestionTitle: "סעיף.",
      additionalInfo: "מידע נוסף",
      na: 'הוספת "לא רלוונטי" כתשובה אפשרית לשאלה זו',
      question: "שאלה",
      questionTitle: "כותרת השאלה",
      why: "למה?",
      addMainQuestion: "הוספת שאלה ראשית",
      editMainQuestion: "עריכת שאלה ראשית",
      previousQuestion: "שאלה קודמת",
      nextQuestion: "שאלה הבאה",
      what: "מה?",
      library: "סיפריה",
      subQuestionTable: {
        no: "לא.",
        subQuestion: "סעיפים",
        status: "סטטוס",
        publishedDate: "תאריך פרסום",
        notApplicable: "לא רלוונטי"
      },
      links: {
        question_link_button: "קישור",
        question_link_include_folder: "כולל תת תיקיה",
        question_link_add_link: "קישורים מהסיפריה",
        question_link_view_folder: "צפיה בסיפריה"
      },
      mainQuestion: "שאלות ראשיות חדשות/מעודכנות:",
      subQuestion: "סעיפים חדשים/מעודכנים:",
      retiredQuestion: "הסרת סעיפים:",
      libraries: "קבצים חדשים/מעודכנים בסיפריות:",
      noFiles: "אין קבצים זמינים"
    },
    library: {
      selectFile: "נא לבחור קובץ אחד לפחות.",
      fileSelected: "בחירת קבצים",
      publish_library_file: "נא לבחור לפחות קובץ אחד לפרסום",
      library_files_deleted: "Are you sure you want to delete this file?",
      library_files_linked_deleted: "בטוח שצריך למחוק את הקובץ והקישורים שנוצרו עם בשאלון?",
      max_size_error: 'גודל הקבצים  חורג מ 1GB .',
      empty_file_desc: "הכנסו תיאור קובץ.",
      file_not_selected: "אנא בחרו את הקבצים שתרצו להעלות (מקסימום 1GB)",
      file_type_error: "סוג הקובץ לא מתאים.",
      file_name_error: "התווים לא תקפים בשם הקובץ.",
      file_name_lenght_error: "שם הקובץ יכול להכיל עד 50 תווים.",
      addFolder: "הוספת תיקיה",
      editFolder: "עריכת תיקיה",
      currentFolder: "שם תיקיה",
      parentFolder: "שם תיקיה ראשית",
      emptyFolderName: "שם התיקיה אינו יכול להיות ריק.",
      invalidFolderName: "The Folder Name cannot contain '/'.",
      folder_created: "התיקיה נוצרה בהצלחה.",
      folder_updated: "התיקיה עודכנה בהצלחה.",
      folder_not_found: "תיקיה לא נמצאה",
      lib_invalid_toolbox: "ארגז כלים שנבחר לא בתוקף",
      lib_folder_already_exists: "התיקיה הזו כבר קיימת.",
      lib_folder_deleted: "התיקיה נמחקה בהצלחה.",
      library_files_published: "הקבצים פורסמו.",
      addFile: "הוספת קובץ",
      editFile: "עריכת קובץ",
      fileName: "שם קובץ",
      upload: "העלאה",
      desc: "תיאור",
      folderCreationErr: "היררכיה מוגבלת לשני רמות",
      subFolderWarning: "נא לבחור תת תיקיה כדי להוסיף קבצים",
      deleteWarning: "נא לבחור תיקיה למחיקה",
      beforeAfterNotSelected: "יש לבחורהאם המצב לפני או אחרי kaizen",
    },
    chapter: {
      chapter_exist: "הפרק הזה כבר קיים.",
      chapter_saved: "הפרק נשמר",
      chapter_cannot_delete: "הפרק לא יכול להימחק מפני שהסעיפים כבר פורסמו.",
      failed: "נכשל !",
      chapter_deleted: "פרק נמחק",
      chapter_nonEmpty: "נא ליצור שם פרק",
      addchapter: "הוספת פרק",
      editChapter: "Edit chapter",
      DELETE_SUCC:"Questionnaires deleted successfully.",
      DELETE_SUCC_LIB:"Libraries deleted successfully.",
      DELETE_ERR : "Something Went wrong."
    },
    evaluation: {
      attachments: "קבצים מצורפים",
      comments: "הערות",
      evidence: "תיעוד",
      evidences: "תיעוד",
      yes: "YES",
      no: "לא",
      na: "לא זמין",
      uploadEvidence: "העלאת תיעוד",
      fileUpload: "אנא בחרו את הקבצים שתרצו להעלות (מקסימום 1GB)",
      who: "מי?",
      answer: "תשובה",
      comment: "Comment",
      date: "Date",
      submit: "הגשה",
      commentMsg: "נא להכניס את ההערות",
      noCommentAvailable: "אין הערות זמינות",
      uploadSuccessMsg: "File(s)  have been uploaded successfully",
      uploadErrorMsg: "כמות קבצים שנכשלו בהעלאתם:",
      fileWithSameName: "קיים קובץ עם שם זה",
      noEvidence: "No evidences available",
      createAction: "יצירת פריט פעולה",
      chapterName: "שם פרק",
      questionNo: "מספר שאלה",
      when: "מתי?",
      noRecords: "אין רשומות זמינות",
      kaizenPoints: "נקודות קייזן",
      renameSuccessMsg: "File has been renamed successfully",
      deleteSuccessMsg: "File has been deleted successfully",
      question: "שאלה",
      before:"Before",
      after:"After",
      beforeAfterLabel:"האם זה שינוי לפני או אחרי קייזן?",
      move:"עבור",
      moveMsg: "הקובצ.ים עברו בהצלחה",
      noToYesMsg:"Previous answer was NO",
    },
    WALK_AROUND_CHECK: "WAC",
    ketProgram: {
      TSM: "TSM/LSM",
      BRiT: "BRiT RI",
      TSM_BNP: "Body & Paint",
      TSM_USED_CAR: "Used Car",
      value_chain: "Value Chain",
      brit: "BRiT",
      ea: "EA",
      per:"Performance",
      nettrans:"Network Transformation",
    },
    dashboard: {
      notice_board: "הודעת הנהלה",
      kpi_target_not_set: "KPI's targets not set",
      kpi_initial_not_set: "KPI's initial measurement not set",
      kpi_monthly_not_set: "KPI's monthly measurement not set",
      kpi_below_target: "KPI's below targets present",
      activation: "הפעלה",
      users: "משתמשים",
      nmscs: "NMSC's",
      activeRetailer: "סוכנויות פעילות",
      certificationRate: "מדד הסמכה",
      reCertificationRate: "מדד הסמכה מחדש",
      activityDue: "מעקב פעילויות",
      avgdays: "ממוצע ימים לארגז כלים",
      overDueAct: "פעילויות באיחור",
      actionItem: "משימות",
      bussResult: "תוצאות עסקיות",
      toolboxImpl: "הטעמת ארגז כלים",
      statusCompletedL:"Status - Completed, Progress - ",
      statusClosedL:"Status - Closed, Progress - ",
      statusOngoingL:"Status - Ongoing, Progress - ",
      recentUpdates: "עדכונים אחרונים",
      rolloutTopNmsc: "תוצאות התכנית - הנהלה בכירה ביבואן",
      bestPractice: "פעילויות מומלצות",
      availableKpi: "מדדים זמינים",
      selectedKpi: "בחירת מדדים",
      dealerBestPractice: "Dealer best practice board",
      kpiList: "רשימת מדדים",
      go: "התחלה",
      nodata: "No data available",
      businessResultTop: "תוצאות עסקיות - סוכנים מובילים",
      businessResultNMSC: "תוצאות עסקיות - הנהלה בכירה ביבואן"


    },
    dealerCertification: {
      dealerCertification: "הסמכת סוכנות",
      readyForCertification: "מוכן להסמכה",
      certification: "הסמכה",
      submittedOn: "הוגש בתאריך",
      certificateDate: "תאריך הסמכה",
      reCertificateDate: "תאריך הסמכה מחדש",
      toolboxDetails: "פרטי ארגזי הכלים",
      approve: "אושר",
      reject: "נדחה",
      pending: "Pending",
      region: "אזור"



    },
    uploadBulk: {
      browse: "Browse",
      executionId: "Execution Id",
      uploadedOn: "Uploaded On",
      lastUpdatedOn: "Last updated on",
      noDataTable: "No data to view",
      kpiUpload: "KPI Upload",
      action: "Action",
      processed: "Processed",
      error: "Error",
      received: "Received",
      createActivity: "Create Activities",
      fileUpload: "File Upload",
      targetSetting: "Target Setting",
      kpiEntry:"KPI Entry",
      accepted:"Accepted",
      launchNmsc:"Launch for NMSC"
    },

    userManagement: {
      userManagement: "Users management",
      createUser: "Create User",
      editUser: "Edit User",
      viewUser: "View User",
      nmscId: "NMSC ID",
      tarsId: "TARS ID",
      firstName: "First Name",
      lastName: "Last Name",
      emailId: "E-Mail ID",
      britDealerChamp: "Brit Dealer Champion",
      invalidEmail: "Please enter valid E-Mail ID",
      invalidTars: "Please enter valid TARS ID",
      invalidFirstName: "Please enter valid First Name",
      invalidLastName: "Please enter valid Last Name",
      user_created: "User Created",
      user_updated: "User updated",
      duplicate_tarsuser: "This TARS id is already registered in the KET database.",
      user_define_in_tool: "User is defined as Business owner in Active Business Function. Hence, could not be made Inactive",
      user_refer_on: "User is referenced in Ongoing activity",
      pbiAccount : "PBI Account",
      invalidPbiAccount : "PBI Account should contain minimum 3 characters.",
      businessArea: "Business Area",
      valueChainAndBRiT: "Value Chain & BRiT",
      only:"Only",
      optionText:"Please select at least one of the two options below",
      valueChainOnly: "Value Chain Only",
      britOnly: "BRiT Only"

    },
    UserManagement: {
      nmscId: "NMSC ID",
      tarsId: "TARS ID",
      firstName: "First Name",
      lastName: "Last Name",
      emailId: "E-Mail ID",
      languageCode: "Language Code",
      ACTIVE: "Active",
      INACTIVE: "Inactive",
      addUser: "Add User",
      user: "User",
      error_message: "אירעה שגיאה, אנא נסה במועד מאוחר יותר",
      role: "Role",
      standardMessage: "No User Found",
      dealerCode: "Dealer Code",
      a2d: "A2D UUID",
      britReady: "Retailer participated in Brit Ready",
      britLive: "Retailer participated in Brit Live",
      britJapan: "Retailer participated in Brit Discovery Japan",
      britEss:"Retailer has updated EES Action Plan",
      addDealer: "Add Dealer",
      noDealer: "No Dealer Found",
      dealerUuid: "Dealer uuid",
      language: "Language",
      L3: "L3",
      L4: "L4",
      L5: "L5",
      L6: "L6",
    },
    CeaKpi: {
      survey: "Survey",
      evaluationItem: "Evaluation Item",
      kpiMetric: "KPI Evaluation Method",
      stratagicPillar: "Strategic Pillar",
      addKPI: "Add KPI",
      edit: "Edit",
      delete: "Delete",
      subPillar: "Sub-Pillar",
      kaizenImp_impact_def: "Kaizen Importance / Impact / Definition",
      evalMethod: "Evaluation Method",
      scoreMethod: "Scoring Method",
      typeOftarget: "Type of Target",
      target: "Target",
      mode: "Mode",
      points: "Points",
      inputFreq: "Input Frequency",
      cea_kpi_created: "CEA KPI has been created successfully",
      noPointsAllocated:"No Points defined for selected Pillar/Business function mapping",
      targetIncorrect:"Target value is Invalid",
      viewKpi:"View KPI",
      general:"General",
      targetScoring:"Targets & Scoring",
      inputs:"Input Parameters",
      maxFiveParam:"Maximum 5 parameters for a KPI can be added",
      validParam:"Please Enter Valid Parameter",
      validDesc:"Please Enter Valid Parameter Description",
      dataEntry:"Data Entry in KET",
      paramater:"Parameter",
      paramDesc:"Parameter Description",
      noParamAvai:"No Parameters Available",
      kpiDeleted:"KPI deleted Successfully",
      kpiDeleteError:"KPI Not found",
      kpiActualsError:"Actuals present. KPI cannot be deleted",
      cea_kpi_updated: "CEA KPI has been updated successfully",
      addParam:"Add Parameters"

    },
    dealerManagement: {
      invalidUuid: "Please enter valid Uuid",
      invalidDealerName: "Please enter valid Dealer Name",
      invalidCity: "Please enter valid City",
      invaliDealerCode: "Please enter valid Dealer Code",
      existingUuid: "Existing Uuid",
      updateUuid: "Update Uuid",
      newUuid: "New Uuid",
    },
    networkManagement: {
      networkManagement: "Network management"
    },
    kpiEntry: {
      cancel : "Cancel",
      save : "Save",
      no : "No",
      yes : "Yes",
      hide : "Hide",
      show : "Show",
      dataNotSaved : "Data is not saved. Entered data may be lost. Do you want to Proceed?",
      wantToProceed : "Do you want to proceed?",
      status: 'Status',
      evaluationYear: 'Evaluation Year',
      frequency: 'Frequency',
      typeOfTarget: 'Type of Target',
      target: 'Target',
      unit: 'Unit',
      standardMessage: 'No Kpi Actuals Data Found',
      srNo: 'S.No.',
      actuals: 'Actuals',
      subPillar: 'Sub Pillar',
      businessFunc: 'Business Function',
      brand: 'Brand',
      Jan : "Jan",
      Feb : "Feb",
      Mar : "Mar",
      Apr : "Apr",
      May : "May",
      Jun : "Jun",
      Jul : "Jul",
      Aug : "Aug",
      Sep : "Sep",
      Oct : "Oct",
      Nov : "Nov",
      Dec : "Dec",
      q1 : "Q1",
      q2 : "Q2",
      q3 : "Q3",
      q4 : "Q4",
      h1 : "H1",
      h2 : "H2",
      cy : "CY",
      kpi : "Key Performance Indicators",
      actuals1 : "Actuals should not be more than allotted points. Please re-enter",
      actionPlan	: "Action Plan",
      enterActualsData : "Please enter actuals data.",
      notNumber : "Please enter valid number.",
      serverErrorMsg : "An error occured, please try again later.",
      launchEvaluationError : "Please launch evaluations.",
      actualsDataSaved : "Actuals data uploaded successfully.",
      inputTxt:"Inputs"
    },
    ceaEvaluation :{
      nmsc : "NMSC",
      search : "Search",
      all : "All",
      status : "Status",
      businessFunction :"Business Function",
      noEvaluationsFound : "No Evalutions Found",
      evaluationYear : "Evalution Year",
      view : "View",
      evaluations : "Evaluations",
      launchTargetSetting: "Launch Target Setting",
      launchEvaluation : "Launch Evaluation",
    },
    britDashboard:{
      noVideo:"No videos available",
      playVideo:"Play Video",
      trainingVideos:"Training  Videos",
      download: "Download Video",
      back:"Back"
    },
    ceaActionPlan:{
      id: "ID",
      counterMeasure: "Countermeasure",
      questionReference: "KPI Reference",
      status: "Status",
      startDate: "Start Date",
      dueDate: "Due Date",
      assignedTo: "Assigned To",
      noActionAvailable: "No Actions available",
      add: "Add",
      download: "Download",
      edit: "Edit",
      delete: "Delete",
      deleteMsg: "Are you sure you want to delete ?",
      no: "No",
      yes: "Yes",
      addActionPlanNotAllowed: "The Action Plan cannot be created, or edited after the Activity has been Closed, Completed, or Rejected.",
      actionPlanItem: "Action Plan - Item #",
      addActionPlan: "Add Action Plan",
      open: "Open",
      actionPlanStartDateValidation:"End Date cannot be earlier than the Start Date",
      modalMandatory: "Please enter valid data in all the mandatory fields",
      problem: "Problem",
      description: "Description",
      followup: "Follow Up",
      history: "History",
      cancel: "Cancel",
      save: "Save",
      update: "Update",
      assignee: "Assignee",
      endDate: "End Date", 
      select: "Select",
      notApplicablePer:"Not Applicable for CEA, Networks and Used car"
    },
    reachItDashboard:{
      topNavReachIT:"ReachIT",
      downloads:"Downloads",
      historyFiles:"History of previous files",
      reachText1:"Welcome to the REACH IT homepage. The TME Environmental Management team will use this page to share relevant  information related to SCIP : Substances of Concern In articles as such or in complex objects (Products) established under the Waste Framework Directive (WFD) in order for you to fulfill your duty to process with your SCIP simplified notification.",
      reachText2:"In case of query, please contact the TME Environmental Management team via below link.",
      reachText3:"In case of query, please contact your NMSC.",
      envMngt:"Environmental Management",
      fileStatistics: "File Downloaded Statistics",
      Downloaded: "Downloaded",
      not: "Not",
      exportToExcel:"Export To Excel"
    },
    usedCarComment:{
      ongoing:"Ongoing",
      completed:"Completed",
      commentHistory:"Monthly Comments History",
      addComment:"Add Monthly Comment",
      month:"Month",
      comment:"Comment",
      username:"Username",
      email:"Email",
      createdDate:"Created Date"
    },
  }
};

export default translationsHB;