const translationsEN = {
  en: {
    Policy: {
      HeaderMsg:
        "Privacy notice for the processing of your personal data in the framework of the Kaizen Expert Tool",
      Message1:
        "This Privacy Notice relates to the Processing of your Personal Data in the framework of the Kaizen Expert Tool. It always has to be read together with the General Toyota Privacy and Personal Data Protection Policy in which the general modalities of the processing of your Personal Data are described and which you must accept in order to be able to proceed further.",
      Message2:
        "Toyota Motor Europe NV/SA, with the Head office at Avenue du Bourget/Bourgetlaan 60, 1140 Brussels, Belgium, as Data Controller, collects your Personal Data (as indicated below) for the purposes as described below.",
      Message3: "Which of your Personal Data will be collected?",
      Message4:
        "We will collect the following category/ies of your Personal Data with respect to Kaizen Expert Tool:",
      Table1: {
        tr1: "Category/ies of Personal Data:",
        tr2: "User Id",
        tr3: "User Name (Full Name)",
        tr4: "Brand (Toyota, Lexus)",
        tr5: "Language preferred",
        tr6: "Affiliated organisation"
      },

      Message5:
        "Purpose and legal bases of the processing of your personal data.",
      Message6:
        "We will process your Personal Data for the purposes and on the legal bases described below:",
      Table2: {
        tr1: {
          th1: "Purpose:",
          th2: "Legal basis:"
        },
        tr2: {
          td1: "KET – 1: Retailer Performance Kaizen",
          td2: "Legitimate interest pursued by the Data Controller(s)"
        },
        tr3: {
          td1: "KET – 2: IT Support",
          td2: "Legitimate interest pursued by the Data Controller(s)"
        }
      },
      Message7: "Duration of Retention of your Personal data",
      Message8:
        "Your Personal Data will be kept for the duration indicated below:",
      Table3: {
        tr1: {
          th1: "Retention period:",
          th2: "Category/ies of Personal Data:"
        },
        tr2: {
          td1:
            "Any personal information as described above will be retained for max of 5 years + 1 Month from the day of final closure of the Kaizen Expert Tool.",
          td2: "User first name, Last name, Language preference, organisation"
        }
      },
      Message9:
        "OPTIONAL: IN CASE OF DISCLOSURE/TRANSFERS OF YOUR PERSONAL DATA TO COUNTRIES OUTSIDE THE EUROPEAN ECONOMIC AREA (EEA)",
      Message10:
        "Your Personal Data will be made accessible to recipient(s) in countries outside the EEA as indicated below:",
      Table4: {
        tr1: {
          td1: "Identity",
          td2: "Country",
          td3: ""
        },
        tr2: {
          td1: "Non-EEA recipient(s)",
          td2: "Infosys",
          td3: "India"
        }
      },
      Message11:
        "I hereby confirm having understood this notice; I have read and accepted the General Toyota Privacy and Personal Data Protection Policy."
    },
    ACCESSORIES: "Accessories",
    activity: {
      Assignee: "Assignee",
      CompletionDate: "Completion Date",
      Dealer: "Dealer",
      FirstVisit: "First Visit",
      kpi: {
        kpi: "KPI can be only positive, or negative numbers.",
        kpiLength: "The maximum length for the KPI is 10 characters.",
        mandatory: "Please enter valid data in all the mandatory fields.",
        mandatoryTargets: "Please enter all KPI's.",
        target: "The Target can be only positive, or negative numbers.",
        targetLength: "The maximum length for the target is 10 characters. "
      },
      ReminderOn: "Reminder On",
      statusDD: "Status",
      Toolbox: "Toolbox",
      activityCreation: "Activity Creation process has been started",
      activityCreated: "activity(s) have been created"
    },
    DPOK: "DPOK",
    EFFICIENCY: "Efficiency",
    FIX_IT_RIGHT: "Fix It Right",
    footer: {
      message: "Mandatory Fields"
    },
    FOUNDATION: "Foundation",
    MAINTENANCE_REMINDERS: "Maintenance Reminder",
    EMPLOYEE_ENGAGEMENT: "Employee Engagement",
    CUSTOMER_CENTRICITY: "Customer Centricity",
    TOYOTA_WAY: "Toyota Way",
    BUSINESS_SUSTAINABILITY: "Business Sustainability",
    FUNDAMENTALS: "Fundamentals",
    FIX_IT_RIGHT_BnP: "Fix it Right (B&P)",
    UTILIZATION_AND_EFFICIENCY: "Utilization and Efficiency",
    CUSTOMER_EXPERIENCE: "Customer Experience",
    UPSELLING_OPPORTUNITIES: "Upselling Opportunities",
    MANAGING_CAPACITY: "Managing Capacity",
    VEHICLE_TRADE_IN: "Vehicle Trade-In",
    STOCK_MANAGEMENT: "Stock Management",
    REFURBISHMENT: "Refurbishment",
    months: {
      apr: "Apr",
      aug: "Aug",
      dec: "Dec",
      feb: "Feb",
      jan: "Jan",
      jul: "Jul",
      jun: "Jun",
      mar: "Mar",
      may: "May",
      nov: "Nov",
      oct: "Oct",
      sep: "Sep"
    },
    others: {
      back: "Back",
      closed: "Closed",
      completed: "Completed ",
      countryLanguageCode: "Country / Language Code ",
      createActivity: "Create Activity",
      createdDate: "Creation Date",
      dataUneditableNote:
        "Note: Data cannot be edited once saved. To modify the data, please contact the NMSC KET admin.",
      date: "Date",
      downloadQuestionnaire: "Download Questionnaire",
      editActivity: "Edit Activity",
      initialHelpText:
        "The initial KPI measurement should be the average value of the previous six full months",
      inProgress: "In Progress",
      name: "Name",
      next: "Next",
      noActivities: "No Activities found",
      noResults: "No Results",
      of: "Of",
      ongoing: "Ongoing",
      open: "Open",
      page: "Page",
      planned: "Planned",
      problemDescription: "Problem Description",
      signature: "Signature",
      select: "Select",
      bulkAction: "Bulk Action",
      bulkActions: "Bulk Actions"
    },
    ServerMessage: {
      error_message: "An error occurred during the download.",
      no_published_subquestion: "Please publish the Questionnaire for this toolbox in order to start this Activity.",
      activity_upload_error_msg: "Error while uploading Activity Details.",
      action_plan_created: "The Action Plan has been created successfully.",
      action_plan_deleted: "The Action Plan has been deleted successfully.",
      action_plan_saved: "The Action Plan has been saved successfully.",
      activity_kpi_saved: "The Activity's KPI's have been saved successfully.",
      master_kpi_confirm_delete:
        "Are you sure you want to delete the selected KPI's?",
      master_kpi_created: "Master KPI has been created successfully",
      master_kpi_deleted: "Master KPI has been deleted",
      master_kpi_not_found: "Master KPI not found",
      master_kpi_published: "Selected KPIs have been published successfully",
      master_kpi_retired: "Master KPI has been retired",
      master_kpi_saved: "Master KPI has been saved successfully",
      subquestion_created: "The sub -question has been successfully saved.",
      subquestion_updated: "The sub-question has been successfully updated.",
      subquestionnaire_not_found: "The sub-question has not been found.",
      subquestion_retired: "The sub-question has been retired.",
      subquestion_deleted: "The sub-question has been deleted.",
      subquestion_published:
        "The sub-questions have been successfully published.",
      mainquestion_created: "The Main Questionnaire has been created.",
      mainquestion_deleted: "The Main Questionnaire has been deleted.",
      mainquestion_updated: "The Main question has been updated.",
      duplicateMsg: "Duplicate records in the Excel file.",
      deleteErr: "Error while deleting the file.",
      toolbox_deleted: "The Toolbox has been deleted successfully",
      toolbox_already_exists: "This toolbox already exists.",
      toolbox_saved: "Toolbox saved",
      toolbox_created: "Toolbox Created",
      toolbox_not_found: "Toolbox not found",
      published_content_found: "This toolbox cannot be deleted as it has some published content",
      dealers_already_mapped: " The selected dealers have already been mapped for this user.",
      dealer_already_mapped: " The selected dealer has already been mapped for this user. ",
      dealer_added: "  Dealer added. ",
      dealers_added: "  Dealer(s) added. ",
      total_dealers_mapped_now: "The total number of dealers currently mapped to this user is .",
      no_dealer_available: "No dealer(s) available for the selected criteria.",
      proper_dealer_selected: "Please select a suitable dealer.",
      user_referenced_ongoing_activity: "The user is currently assigned to ongoing Activities. Please replace the Assignee in these Activities and try again. ",
      want_to_remove_mapping: "Are you sure you want to remove the user's mapping for these dealers?",
      user_mapping_removed: "The selected dealers have been removed successfully from the user's mapping.",
      dealer_closed_in_A2D: "This dealer is registered as 'Closed' in A2D.",
      network_level_not_found: "Please select a valid combination of network levels according to your KET network setup.",
      duplicate_dealer_uuid: "Dealer already present in the KET database.",
      dealer_created: "Dealer created.",
      dealer_updated: "This dealer's attributes have been updated.",
      dealer_not_present: "This dealer is not registered in A2D.",
      dealer_uuid_other_nmsc: "The A2D uuid code selected is not available for your network.",
      target_kpi_updated: "The KPI's status have been updated successfully.",
      target_updated: "The KPI's target have been updated successfully.",
      kpi_launched: "The KPI's bulk launch successfully.",
      targetUpdate:"Application doesn't allow target to be modified for accepted status.",
      bulkLaunchTargetMsg:"All Target should be filled for respective NMSC.",
      launchTargetMsg:"Please fill in the Target before launching."
    },
    standardMessages: {
      delete: "Are you sure you want to delete ?",
      addActionPlanNotAllowed: "The Action Plan cannot be created, or edited after the Activity has been Closed, Completed, or Rejected.",
      addToolboxNotAllowed: "Cannot create more than 19 toolbox",
      britRejectMsg: "Are you sure you want to Reject this Activity? Changes are not possible after rejection.",
      rejectMsg: "Are you sure you want to Reject this Activity? Changes are not possible after rejection.",
      selectNMSCTab: "Please select the NMSC language to publish",
      all: "All",
      deleteConfirm: "Are you sure want to delete the selected KPI?",
      deleteDependentConfirm: "Are you sure want to delete the selected KPI? Any other KPI which has value based on this KPI will also get deleted.",
      no: "No",
      retireConfirm:
        "Are you sure you want to retire this KPI? Once retired, this KPI cannot be re-activated",
      warning: "Warning",
      yes: "Yes",
      saveDataConfirmation: "Are you sure you want to save the data?",
      saveKPIWarning:
        "Note: Data cannot be edited once saved. To modify the data please contact the NMSC KET admin",
      addKpiNotAllowed:
        "The next month for the KPI input can be added when the current month ends.",
      activityLaunch:
        "Are you sure you want to launch this Activity? After launching, the Activity cannot be reversed back to the Planned status, or deleted.",
      deleteConfirmSubQuestionNaire:
        "Are you sure you want to delete the selected sub-question?",
      retireConfirmSubQuestionNaire:
        "Are you sure you want to retire the selected sub-question?",
      deleteConfirmMainQuestion:
        "Please confirm if you want to delete the Main question and its related sub-questions.",
      deleteConfirmChapter: "Are you sure you want to delete the chapter ?",
      deleteLibraryFolder: "Are you sure you want to delete this folder ?",
      deletePublishedFolder:
        "Some files within this folder might be linked to the Questionnaire. Are you sure you want to permanently delete this subfolder and remove the link(s) from the Questionnaire?",
      importQuestion:
        "Are you sure you want to import this sub-question? The current sub-question will be replaced.",
      noQuestion: "No Questions available.",
      MandatoryWarning:
        "Please fill out all the mandatory fields for all local languages.",
      certificationSubmission:
        "Toolbox has been submitted to TME for dealer certification",
      sameToolBox: "You cannot submit same Toolbox more than once. Please select different toolbox",
      deleteConfirmDealer: "Are you sure you want to delete this dealer ?",
      updateVcConfirmDealer: "All Activities created for this dealer in \"Value chain\" will be removed. Are you sure you want to continue?",
      updateBritConfirmDealer: "All Activities created for this dealer in \"BRiT\" will be removed. Are you sure you want to continue?",
      completed_activity_delete:"Data cannot be retrieved once deleted. Are you sure you want to delete ?",
      deleteAllQuestionary: "Are you sure you want to delete the entire Questionnaire?",
      deleteAllLibraries: "Are you sure you want to delete all folders and files in the Library?",
      selectAll: "Select All",
      unSelectAll: "Unselect All",
      select:"Select...",
      warningMsg:"There are Open Action plan(s) in this activity. Would you like to Complete the activity?",
      confirm:"Confirm",
      goBack:"Go Back",
      firstAuditComplete:"Warning: This action is irreversible, and you cannot go back. Please confirm that you want to proceed."
    },
    status: {
      published: "Published",
      retired: "Retired",
      saved: "Saved",
      rejected: "Rejected",
      open: "Open",
      closed: "Closed",
      close: "Closed",
      ongoing: "Ongoing",
      completed: "Completed",
      complete: "Completed",
      planned: "Planned",
      overdue: "Overdue",
      due: "Due",
      pending: "Pending",
    },
    Rejected: "Rejected",
    Open: "Open",
    Closed: "Closed",
    Close: "Closed",
    Ongoing: "Ongoing",
    Completed: "Completed",
    Complete: "Completed",
    Planned: "Planned",
    tabDetails: {
      noActionAvailable: "No Actions available",
      activeDealer: "This dealer is currently registered as Active in A2D and cannot be deleted.",
      dealerDeleted: "Dealer is deleted."
    },
    Table: {
      auditStatus:"Audit Status",
      firstAudit:"1st Audit",
      kaizen:"Kaizen",
      reAudit:"Re-Audit",
      audit:"Audit",
      action: "",
      activity: "Activity",
      activityCompletionDate: "Activity Completion Date",
      activityCompletionDateFrom: "Activity Completion Date From",
      activityCompletionDateTo: "Activity Completion Date To",
      activityStartDate: "Activity Start Date",
      activityStartDateFrom: "Activity Start Date From",
      activityStartDateTo: "Activity Start Date To",
      assignedTo: "Assigned To",
      assignee: "Assignee",
      benchmark: "Benchmark",
      city: "City",
      completedBy: "Completed By ",
      completionBy: "Completion By",
      completionDate: "Completion Date",
      counterMeasure: "Countermeasure",
      country: "Country",
      createdBy: "Created By",
      dealer: "Dealer",
      dealerNameAD: "Dealer name (A2D)",
      dealerName: "Dealer name",
      definition: "Definition",
      description: "Description",
      dueDate: "Due Date",
      endDate: "End Date",
      firstVisit: "First Visit",
      followup: "Follow Up",
      franchise: "Franchise",
      history: "History",
      id: "ID",
      initial: "Initial",
      kpi: "KPI",
      launch: "Launch",
      mandatory: "Mandatory",
      nmsc: "NMSC",
      problem: "Problem",
      publishedOn: "Published On",
      questionReference: "Question Reference",
      reference: "Reference",
      reminderOn: "Reminder On",
      score: "Score",
      startDate: "Start Date",
      status: "Status",
      target: "Target",
      toolbox: "Toolbox",
      view: "View",
      kpiResult: "KPI results",
      nmscTarget: "NMSC Target",
      penEbenchmark: "PanE Benchmark",
      kpiField: "KPI",
      location: "Location",
      ActionItemReport: "Action items in progress",
      certification: "Certification Status",
      noOfToolbox: "No of Completed Toolboxes",
      program: "Program",
      certificationDate: "Certified Date",
      activityId : 'Activity ID',
      new: "New",
      updatedOn: "Last Update",
      submittedBy:"Submitted By",
      approvedBy:"Approved By",
      kpis:"KPIs",
      lastmonthKpis:"Last Month KPIs",
      questionPercent:"Completion %",
      actionPlan:"Action Plan",
      monthlyKpi:"Monthly KPIs",
      initialKpi:"Initial KPIs",
      targetKpi:"Target KPIs",


      },
      tabsDetails: {
      actionPlan: "Action Plan",
      confirmDeleteMessage: "Are you sure you want to delete the selected KPI?",
      confirmRetireMessage:
        "Are you sure you want to retire (remove) the selected published KPI?",
      errorRetireMsg:
        "This KPI cannot be retired as it's an active TME mandatory KPI",
      import: "Import",
      importKpiMessage: "Please import your KPI from the TME Master KPI list",
      kpi: "Key Performance Indicators",
      library: "Library",
      noActionAvailable: "No Actions available",
      NoKPI: "No KPIs available",
      publish: "Publish",
      questionnaire: "Questionnaire",
      tmeKPIList: "TME Master KPI List",
      toyotaCityBrussels: "TOYOTA CITY BRUSSELS",
      attachment: "Attachment",
      attachments: "Attachment(s)",
      uploadAttachment: "Upload Attachment",
      noAttachment: "No attachments available",
    },
    tmeAdminMaster: {
      ToolboxName: "Toolbox name",
      newToolboxName: "New name",
      deleteToolbox: "Delete toolbox?",
      programName: "Program name",
      ToolboxName_EN: "Toolbox name (EN)",
      deleteTool: "Are you sure you want to delete this toolbox?",
      selectToolbox: "Please select a toolbox to delete.",
      editToolBox: "Edit Toolbox",
      addToolbox: "Add Toolbox",
      toolbox: "Please enter a name for this Toolbox.",
      addActionPlan: "Add Action Plan",
      actionPlanItem: "Action Plan - Item #",
      add: "Add",
      addActivity: "Add Activity",
      additionalInformation: "Additional Information",
      benchmark: "Benchmark",
      cancel: "Cancel",
      createEditActivity: "Create / Edit Activity",
      createKPI: "Create KPI",
      delete: "Delete",
      download: "Download",
      edit: "Edit",
      editKPI: "Edit KPI",
      keyPerformanceIndicator: "Key Performance Indicator",
      kpiDefinition: "KPI Definition",
      mandatoryKpi: "Mandatory KPI",
      save: "Save",
      search: "Search",
      unit: "Unit",
      update: "Update",
      validData: "Please enter valid data",
      hide: "Hide",
      show: "Show",
      upload_dealer_network: "Upload dealer Network",
      upload_question: "Upload Questionnaire",
      upload_user: "Upload User Details",
      upload_activity: "Upload Activities",
      toyota: "Toyota",
      lexus: "Lexus",
      remove: "Remove",
      launch: "Launch",
      close: "Close",
      generate: "Generate",
      dueDateExceed:"Due date exceeded",
      delQuestionnaire:"Delete questionnaire",

    },
    toastr: {
      activityStatus_condition: "Please answer all questions in the in order to be able to close this Activity.",
      emptyTarget: "Please enter the NMSC target to publish the KPI",
      retailerActual: "Please enter the Retailer Actual",
      initialTargetMonthly: "Please enter the initial KPI measurement, the KPI targets and at least 1 month of measurement to continue.",
      initialTarget: "Please enter the initial KPI measurement and the KPI targets to continue.",
      upload_error: "Error while uploading the Dealer Network.",
      action_item_create_warning: "Maximum five records are allowed to be merged within one Action item.",
      actionPlanStartDateValidation:
        "End Date cannot be earlier than the Start Date",
      actionsNotClosed:
        "There are Actions in progress in the Action Plan; please close all Actions before closing an Activity",
      activityDuplicate:
        "Another Activity for the same Dealer and Toolbox already exists",
      assigneeNotExist:
        "The selected Assignee has not been registered in the system",
      assigneeValidation: "Invalid Assignee",
      atleastOnePublish: "Please select at least one KPI to publish",
      cannotRetireActive:
        "This is an active TME mandatory KPI which cannot be retired ",
      completionDate: "Completion date cannot be earlier than Start date.",
      confirDeletePlannedActivity:
        "Are you sure you want to delete this planned Activity?",
      DateformatValidation: "Date should be in format DD/MM/YYYY",
      DateValidation: "From Date Should Be Less Than To Date",
      dealerValidation: "Invalid Dealer",
      deleteSelectedConfirm:
        "Are you sure you want to delete the selected KPI?",
      duplicate: "Another KPI with the same name and definition already exists",
      modalMandatory: "Please enter valid data in all the mandatory fields",
      noPublishKpi:
        "The KPI's have not been published yet for the selected Toolbox",
      validSearchCriteria: "Please enter a valid search criteria",
      multiSelectPlaceHolder: "Enter at least 5 characters",
      multiSelectPlaceHolderUser: "Enter at least 3 characters",
      multiSelectPlaceHolderDealerCode: "Enter at least 1 characters",
      startDateValidation: "Start date cannot be greater than the End date",
      fileSelectionErr: "Please select the file first.",
      atleastOneQuestion: "Please select at least one sub-question to import.",
      atleastOneSubQues: "Please select at least one sub-question to publish.",
      uploadErr: "Number of files failing to upload:",
      uploadSuccess: "Dealer Network uploaded successfully.",
      file_upload_success: "The file(s) have been uploaded successfully.",
      KPIinOngoingClosedActivity: "This KPI is present in one, or more, Ongoing/Closed Activities.Please Complete these Activities before making any change to the KPI.",
      Role_Error: "The user setup is incomplete, please contact the NMSC KET Admin.",
      points_allocation_incorrect: "Incorrect points allocation, please modify.",

      activity_deleted:"Activity deleted successfully",
      activity_certified_dealer:"The dealer was certified for the selected Activity, it cannot be deleted.",
      activity_submited_for_certificate_dealer:"This Activity has been included in the Certification request sent to TME, therefore the status cannot be changed."
    },
    TopNav: {
      ActivityLog: "Activities",
      ApplicationName: "Kaizen Expert Tool",
      Home: "Home",
      logout: "Log Out",
      MasterDataUpload: "Upload Master Data",
      Toolbox: "Toolboxes",
      welcome: "Welcome",
      Admin: "Admin",
      AuditsSetUp:"Audits Set-up",
      Audits:"Audits"

    },
    questionnaire: {
      addSubQuestion: "Add sub-question",
      editSubQuestion: "Edit Sub Question",
      subQuestionTitle: "Sub-question",
      additionalInfo: "Additional Information",
      na: "Add 'Not Applicable' as a possible answer for this question",
      cr:"Critical",
      question: "Question",
      questionTitle: "Question's Title",
      why: "Why?",
      addMainQuestion: "Add Main question",
      editMainQuestion: "Edit Main question",
      previousQuestion: "Previous question",
      nextQuestion: "Next question",
      what: "What?",
      library: "Library",
      subQuestionTable: {
        no: "No.",
        subQuestion: "Sub-questions",
        status: "Status",
        publishedDate: "Published Date",
        notApplicable: "Not Applicable"
      },
      links: {
        question_link_button: "Link",
        question_link_include_folder: "Include a subfolder",
        question_link_add_link: "Link file(s) from Library",
        question_link_view_folder: "View Library"
      },
      mainQuestion: "New/Updated Main Questions:",
      subQuestion: "New/Updated Sub-questions:",
      retiredQuestion: "Retired Sub-questions:",
      libraries: "New/Updated files in Libraries:",
      noFiles: "No files available."
    },
    library: {
      selectFile: "Please select atleast one file",
      fileSelected: "Files Selected",
      publish_library_file: "Please select at least one file to publish",
      library_files_deleted: "Are you sure you want to delete this file?",
      library_files_linked_deleted:
        "Are you sure you want to delete this file and the links created in the Questionnaire?",
      max_size_error:
        "The size of the files exceeds the limit of 1 GB per upload.",
      empty_file_desc: "Please enter a file description.",
      file_not_selected: "Please select the file(s) to be uploaded (max. 1GB)",
      file_type_error: "This file type is not allowed.",
      file_name_error: "Invalid character in the file name.",
      file_name_lenght_error: "The file name can have maximum 50 characters.",
      addFolder: "Add Folder",
      editFolder: "Edit Folder",
      currentFolder: "Folder Name",
      parentFolder: "Parent Folder Name",
      emptyFolderName: "The Folder Name cannot be empty.",
      invalidFolderName: "The Folder Name cannot contain '/'.",
      folder_created: "The Folder has been succesfully created.",
      folder_updated: "The Folder has been successfully updated.",
      folder_not_found: "Folder not found",
      lib_invalid_toolbox: "Invalid Toolbox selected",
      lib_folder_already_exists: "This Folder already exists.",
      lib_folder_deleted: "The Folder has been successfully deleted.",
      library_files_published: "The files have been Published.",
      addFile: "Add File",
      editFile: "Edit File",
      fileName: "File name",
      upload: "Upload",
      desc: "Description",
      folderCreationErr: "Hierarchy limited to two levels.",
      subFolderWarning: "Please select a subfolder to add file(s).",
      deleteWarning: "Please select a folder to delete.",
      beforeAfterNotSelected: "Please select if the evidence is before or after kaizen",
    },
    chapter: {
      chapter_exist: "This Chapter already exists.",
      chapter_saved: "Chapter Saved",
      chapter_cannot_delete:
        "This Chapter cannot be deleted as sub-questions have already been published.",
      failed: "Failed !",
      chapter_deleted: "Chapter Deleted",
      chapter_nonEmpty: "Please create a chapter name.",
      addchapter: "Add chapter",
      editChapter: "Edit chapter",
      DELETE_SUCC:"Questionnaires deleted successfully.",
      DELETE_SUCC_LIB:"Libraries deleted successfully.",
      DELETE_ERR : "Something Went wrong."
    },
    evaluation: {
      attachments: "Attachments",
      comments: "Comments",
      evidence: "Evidence",
      evidences: "Evidence(s)",
      yes: "YES",
      no: "NO",
      na: "N/A",
      uploadEvidence: "Upload evidence",
      fileUpload: "Select File to Upload",
      who: "Who?",
      answer: "Answer",
      comment: "Comment",
      date: "Date",
      submit: "Submit",
      commentMsg: "Please enter the comments.",
      noCommentAvailable: "No comments available.",
      uploadSuccessMsg: "File(s)  have been uploaded successfully",
      uploadErrorMsg: "Number of files failing to upload: ",
      fileWithSameName: "Another file with the same name already exists.",
      noEvidence: "No evidences available",
      createAction: "Create Action Item",
      chapterName: "Chapter name",
      questionNo: "Question No.",
      when: "When?",
      noRecords: "No records available.",
      kaizenPoints: "Kaizen Points",
      renameSuccessMsg: "File has been renamed successfully",
      deleteSuccessMsg: "File has been deleted successfully",
      question: "Question",   
      before:"Before",
      after:"After",
      beforeAfterLabel:"Is this evidence from before or after kaizen?",
      move:"Move",
      moveMsg: "File(s) has been moved successfully",
      noToYesMsg:"Previous answer was NO",
    },
    WALK_AROUND_CHECK: "Walk Around Check",
    ketProgram: {
      TSM: "TSM/LSM",
      BRiT: "BRiT RI",
      TSM_BNP: "Body & Paint",
      TSM_USED_CAR: "Used Car",
      value_chain: "Value Chain",
      brit: "BRiT",
      ea: "EA",
      per:"Performance",
      nettrans:"Network Transformation",
      TSP:"Customer Service Quality Audits"
    },
    dashboard: {
      notice_board: "Notice Board",
      kpi_target_not_set: "KPI's targets not set",
      kpi_initial_not_set: "KPI's initial measurement not set",
      kpi_monthly_not_set: "KPI's monthly measurement not set",
      kpi_below_target: "KPI's below targets present",
      activation: "Activation",
      users: "Users",
      nmscs: "NMSC's",
      activeRetailer: "Active Retailers",
      certificationRate: "Certification Rate",
      reCertificationRate: "Re-Certification Rate",
      activityDue: "Activities Due",
      avgdays: "Average Days/Toolbox",
      overDueAct: "Overdue Activities",
      actionItem: "Action Items",
      bussResult: "Business results",
      toolboxImpl: "Toolbox Implementation",
      statusCompletedL:"Status - Completed, Progress - ",
      statusClosedL:"Status - Closed, Progress - ",
      statusOngoingL:"Status - Ongoing, Progress - ",
      recentUpdates: "Recent Updates",
      rolloutTopNmsc: "Roll-out results - Top NMSC's",
      bestPractice: "Best Practices Board",
      availableKpi: "Available KPIs",
      selectedKpi: "Selected KPIs",
      dealerBestPractice: "Dealer best practice board",
      kpiList: "KPI's List",
      go: "GO",
      nodata: "No data available",
      businessResultTop: "Business results - Top Retailers",
      businessResultNMSC: "Business results - Top NMSC's"


    },
    dealerCertification: {
      dealerCertification: "Dealer certification",
      readyForCertification: "Ready for Certification",
      certification: "Certification",
      submittedOn: "Submitted On",
      certificateDate: "Certification Date",
      reCertificateDate: "Re-Certification Date",
      toolboxDetails: "Toolbox Details",
      approve: "Approve",
      reject: "Reject",
      pending: "Pending",
      region: "Region"

    },

    uploadBulk: {
      browse: "Browse",
      executionId: "Execution id",
      uploadedOn: "Uploaded on",
      lastUpdatedOn: "Last update on",
      noDataTable: "No data to view",
      kpiUpload: "KPI's upload",
      action: "Action",
      processed: "Processed",
      error: "Error",
      received: "Received",
      createActivity: "Create Activities",
      fileUpload: "File Upload",
      selectFile: "Select File to Upload",
      targetSetting: "Target Setting",
      kpiEntry:"KPI Entry",
      accepted:"Accepted",
      launchNmsc:"Save and Launch",    
      launchForAll: "Save and Launch All",
      questionnaireUpload: "Questionnaire Upload",
    },

    userManagement: {
      userManagement: "Users management",
      createUser: "Create User",
      editUser: "Edit User",
      viewUser: "View User",
      nmscId: "NMSC ID",
      tarsId: "TARS ID",
      firstName: "First name",
      lastName: "Last name",
      emailId: "E-mail",
      britDealerChamp: "Brit Dealer Champion",
      invalidEmail: "Please enter valid E-Mail ID",
      invalidTars: "Please enter valid TARS ID",
      invalidFirstName: "Please enter valid First Name",
      invalidLastName: "Please enter valid Last Name",
      user_created: "User Created",
      user_updated: "User updated",
      duplicate_tarsuser: "This TARS id is already registered in the KET database.",
      user_define_in_tool: "User is defined as Business owner in Active Business Function. Hence, could not be made Inactive",
      user_refer_on: "User is referenced in Ongoing activity",
      pbiAccount : "PBI Account",
      invalidPbiAccount : "The PBI Account should contain minimum 3 characters.",
      businessArea: "Business Area",
      valueChainAndBRiT: "Value Chain & BRiT",
      only:"Only",
      optionText:"Please select at least one of the three options below",
      valueChainOnly: "Value Chain Only",
      britOnly: "BRiT Only"
    },
    UserManagement: {
      nmscId: "NMSC ID",
      tarsId: "TARS ID",
      firstName: "First name",
      lastName: "Last name",
      emailId: "E-mail",
      languageCode: "Language code",
      ACTIVE: "Active",
      INACTIVE: "Inactive",
      addUser: "Add user",
      Assign: "Assign",
      List: "List",
      user: "User",
      error_message: "An error occured, please try again later.",
      role: "User role",
      standardMessage: "No user(s) found.",
      dealerCode: "Dealer code",
      a2d: "A2D UUID",
      britReady: "Retailer participated in Brit Ready.",
      britLive: "Retailer participated in Brit Live.",
      britJapan: "Retailer participated in Brit Discovery Japan.",
      britEss:"Retailer has updated EES Action Plan",
      addDealer: "Add dealer (from A2D)",
      noDealer: "No dealer(s) found.",
      dealerUuid: "Dealer uuid code (A2D)",
      language: "Language",
      L3: "L3",
      L4: "L4",
      L5: "L5",
      L6: "L6",
    },

    CeaKpi: {
      survey: "Survey",
      evaluationItem: "Evaluation Item",
      kpiMetric: "KPI Evaluation Method",
      stratagicPillar: "Strategic Pillar",
      addKPI: "Add KPI",
      edit: "Edit",
      delete: "Delete",
      subPillar: "Sub-Pillar",
      kaizenImp_impact_def: "Kaizen Importance / Impact / Definition",
      kaizenImp_impact_def1: "Definition",
      definition : "Definition",
      evalMethod: "Evaluation Method",
      scoreMethod: "Scoring Method",
      typeOftarget: "Type of Target",
      target: "Target",
      mode: "Mode",
      points: "Points",
      inputFreq: "Input Frequency",
      cea_kpi_created: "CEA KPI has been created successfully",
      noPointsAllocated:"No Points defined for selected Pillar/Business function mapping",
      targetIncorrect:"Target value is Invalid",
      viewKpi:"View KPI",
      general:"General",
      targetScoring:"Targets & Scoring",
      inputs:"Input Parameters",
      maxFiveParam:"Maximum 5 parameters for a KPI can be added",
      validParam:"Please Enter Valid Parameter",
      validDesc:"Please Enter Valid Parameter Description",
      dataEntry:"Data Entry in KET",
      paramater:"Parameter",
      paramDesc:"Parameter Description",
      noParamAvai:"No Parameters Available",
      kpiDeleted:"KPI deleted Successfully",
      kpiDeleteError:"KPI Not found",
      kpiActualsError:"Actuals present. KPI cannot be deleted",
      cea_kpi_updated: "CEA KPI has been updated successfully",
      addParam:"Add Parameters",
      pointIncorrect:"Point value is Invalid",
      net_kpi_created:" KPI has been created successfully",
      net_kpi_not_found:" KPI not found",
      net_kpi_deleted:" KPI has been deleted successfully",
      net_kpi_updated:" KPI has been updated successfully" 
    },
    dealerManagement: {
      invalidUuid: "Please enter a valid A2D uuid.",
      invalidDealerName: "Please enter a valid dealer name.",
      invalidCity: "Please enter a valid location.",
      invaliDealerCode: "Please enter a valid dealer code.",
      existingUuid: "The A2D uuid code is already present in the KET database.",
      updateUuid: "Update A2D uuid code",
      newUuid: "New A2D uuid code",
    },
    target: {
      subPillar: "Sub Pillar",
      businessFunc: "Business Function",
      brand: "Brand",
    },
    kpiSetUp: {
      businessFunction: "Business Function",
      divisionName: "Division Name",
      businessOwner: "Business Owner",
      businessFName: "Please enter a name for this Business Function.",
      selectBusinessOwner: "Please select a Business Owner.",
      toolbox_already_exists: "This Business function already exists.",
      toolbox_saved: "Business function saved",
      toolbox_created: "Business function Created",
      toolbox_not_found: "Business function not found",
    },
    networkManagement: {
      networkManagement: "Network management",
    },
    kpiEntry: {
      cancel : "Cancel",
      save : "Save",
      no : "No",
      yes : "Yes",
      hide : "Hide",
      show : "Show",
      dataNotSaved : "Data is not saved. Entered data may be lost. Do you want to Proceed?",
      wantToProceed : "Do you want to proceed?",
      status: 'Status',
      evaluationYear: 'Evaluation Year',
      frequency: 'Frequency',
      typeOfTarget: 'Type of Target',
      target: 'Target',
      unit: 'Unit',
      standardMessage: 'No Kpi Actuals Data Found',
      standardMsgKpiEntry: 'All/Some of parameters of the KPI’s do not need entry in KET and are greyed out',
      srNo: 'S.No.',
      actuals: 'Actuals',
      subPillar: 'Sub Pillar',
      businessFunc: 'Business Function',
      brand: 'Brand',
      Jan : "Jan",
      Feb : "Feb",
      Mar : "Mar",
      Apr : "Apr",
      May : "May",
      Jun : "Jun",
      Jul : "Jul",
      Aug : "Aug",
      Sep : "Sep",
      Oct : "Oct",
      Nov : "Nov",
      Dec : "Dec",
      q1 : "Q1",
      q2 : "Q2",
      q3 : "Q3",
      q4 : "Q4",
      h1 : "H1",
      h2 : "H2",
      cy : "CY",
      kpi : "Key Performance Indicators",
      actuals1  : "Actuals should not be more than allotted points. Please re-enter",
      actionPlan	: "Action Plan",
      enterActualsData : "Please enter actuals data.",
      notNumber : "Please enter valid number.",
      serverErrorMsg : "An error occured, please try again later.",
      launchEvaluationError : "Please launch evaluations.",
      actualsDataSaved : "Actuals data uploaded successfully.",
      inputTxt:"Inputs"
    },
    ceaEvaluation :{
      nmsc : "NMSC",
      search : "Search",
      all : "All",
      status : "Status",
      businessFunction :"Business Function",
      noEvaluationsFound : "No Evalutions Found",
      evaluationYear : "Evaluation Year",
      view : "View",
      evaluations : "Evaluations",
      launchTargetSetting: "Launch Target Setting",
      moveToLaunch: "Move To Launch",
      launchEvaluation : "Launch Evaluation",
      evaluationsAlreadyExist : "Evaluation items already exist.",
      evaluationPointsNotCorrect : "Either Sum of all KPI's is not equal to points allocated or some KPI(s) have't been published yet. Please correct the same.",
      targetSettingLaunched : "Evaluation launched",
      closeEvaluation: "Close Evaluation",
      pendingParameterMsg : "Some evaluations are pending for actuals entry. ",
      closeEvaluationConfirmMsg : "Do you wish to continue?",
      closeEvaluationCommonMsg :"Are you sure you want to Close the Evaluations?",
      evaluation_closed_succesfully: "Evaluations closed successfully for the selected Strategic Pillar and Year.",
      no_business_function_with_points: "Points not allocated to the business function for the selected year and strategic pillar. Please correct the same.",
      some_business_function_not_launched: "Target setting not launched for all Business functions in the ",
      some_business_function_not_launched_net: "All Business functions are not launched in the ",
      kpi_not_target_accepted : "Targets for some KPI's are not in 'Accepted' status for the ",
      previous_year_evaluation_not_completed : "Please complete Evaluations for the year ",
      some_error_occured : "Some error occurred.",
      evaluations_launched_succesfully : "Evaluations launched successfully for the selected Strategic Pillar and Year.",
      closeEvaluationMsg:"STOP, Only use in case of annual closure of all evaluations for all NMSC's"
    },
    britDashboard:{
      noVideo:"No videos available",
      playVideo:"Play Video",
      trainingVideos:"Training  Videos",
      download: "Download Video",
      back:"Back"
    },
    ceaActionPlan:{
      id: "ID",
      counterMeasure: "Countermeasure",
      questionReference: "KPI Reference",
      status: "Status",
      startDate: "Start Date",
      dueDate: "Due Date",
      assignedTo: "Assigned To",
      noActionAvailable: "No Actions available",
      add: "Add",
      download: "Download",
      edit: "Edit",
      delete: "Delete",
      deleteMsg: "Are you sure you want to delete ?",
      no: "No",
      yes: "Yes",
      addActionPlanNotAllowed: "The Action Plan cannot be created, or edited after the Activity has been Completed.",
      actionPlanItem: "Action Plan - Item #",
      addActionPlan: "Add Action Plan",
      open: "Open",
      actionPlanStartDateValidation:"End Date cannot be earlier than the Start Date",
      modalMandatory: "Please enter valid data in all the mandatory fields",
      problem: "Problem",
      description: "Description",
      followup: "Follow Up",
      history: "History",
      cancel: "Cancel",
      save: "Save",
      update: "Update",
      assignee: "Assignee",
      endDate: "End Date", 
      select: "Select",
      warning: "Warning",
      allocate:"Allocate",
      nodata: "No data available",
      notApplicablePer:"Not Applicable for CEA, Networks and Used car",
      cytotal: "CY Total",
      total: "Total"
    },
    reachItDashboard:{
      topNavReachIT:"ReachIT",
      downloads:"Downloads",
      historyFiles:"History of previous files",
      reachText1:"Welcome to the REACH IT homepage. The TME Environmental Management team will use this page to share relevant  information related to SCIP : Substances of Concern In articles as such or in complex objects (Products) established under the Waste Framework Directive (WFD) in order for you to fulfill your duty to process with your SCIP simplified notification.",
      reachText2:"In case of query, please contact the TME Environmental Management team via below link.",
      reachText3:"In case of query, please contact your NMSC.",
      envMngt:"Environmental Management",
      fileStatistics: "File Downloaded Statistics",
      Downloaded: "Downloaded",
      not: "Not",
      exportToExcel:"Export To Excel"
    },
    usedCarComment:{
      ongoing:"Ongoing",
      completed:"Completed",
      commentHistory:"Monthly Comments History",
      addComment:"Add Monthly Comment",
      month:"Month",
      comment:"Comment",
      username:"Username",
      email:"Email",
      createdDate:"Created Date"
    },
  },

};

export default translationsEN;
