const translationsSK = {
  sk: {
    Policy: {
      HeaderMsg: "Oznámenie o ochrane osobných údajov pri ich spracovaní  v rámci nástroja Kaizen Expert Tool",
      Message1: "Toto Vyhlásenie o ochrane osobných údajov sa týka spracovania Vašich osobných údajov v rámci nástroja Kaizen Expert Tool. Vždy sa musí čítať spolu so Všeobecnou politikou ochrany osobných údajov a ochrany osobných údajov spoločnosti Toyota, v ktorej sú opísané všeobecné spôsoby spracovania Vašich osobných údajov a ktoré musíte prijať, aby ste mohli pokračovať ďalej.",
      Message2: "Toyota Motor Europe NV / SA, so sídlom na Avenue du Bourget / Bourgetlaan 60, 1140 Brusel, Belgicko, ako Správca osobních údajov, spracováva vaše osobné údaje (ako je uvedené nižšie) na účely opísané nižšie.",
      Message3: "Ktoré z Vašich osobných údajov budú zaznamenávané?",
      Message4: "V súvislosti s nástrojom Kaizen Expert Tool budeme zaznamenávať nasledujúce kategórie vašich osobných údajov:",
      Table1: {
        tr1: "Kategória osobných údajov:",
        tr2: "Užívateľské ID",
        tr3: "Užívateľské meno (Celé meno)",
        tr4: "Značka (Toyota, Lexus)",
        tr5: "Preferovaný jazyk",
        tr6: "Organizácia"
      },

      Message5: "Účel a právne zásady spracovania vašich osobných údajov.",
      Message6: "V súlade s právnymi zásadami si vyhradzujeme právo spracovávame Vaše osobné údaje na účely popísané nižšie:",
      Table2: {
        tr1: {
          th1: "Účel:",
          th2: "Právne zásady:"
        },
        tr2: {
          td1: "KET – 1:Retailer Performance Kaizen",
          td2: "Oprávnenie Správcu zaobchádzať s osobnými údajmi"
        },
        tr3: {
          td1: "KET – 2:IT Support",
          td2: "Oprávnenie Správcu zaobchádzať s osobnými údajmi"
        }
      },
      Message7: "Doba uchovania Vašich osobných údajov",
      Message8: "Vaše osobné údaje budú uchovávané po dobu uvedenú nižšie:",
      Table3: {
        tr1: {
          th1: "Doba uchovania:",
          th2: "Kategória osobných údajov:"
        },
        tr2: {
          td1: "Všetky vyššie uvedené osobné údaje budú uchovávané maximálne 5 rokov + 1 mesiac odo dňa konečného uzavretia nástroja Kaizen Expert Tool.",
          td2: "Meno používateľa, Priezvisko, Jazyk, organizácia"
        }
      },
      Message9: "Nepovinné: V prípade zverejnenia / prenosu vašich osobných údajov do krajín mimo Európskeho hospodárskeho priestoru (EHP)",
      Message10: "Vaše osobné údaje budú sprístupnené príjemcom v krajinách mimo EHP, ako je uvedené nižšie:",
      Table4: {
        tr1: {
          td1: "Totožnosť",
          td2: "Krajina",
          td3: ""
        },
        tr2: {
          td1: "Príjemca (-i) mimo EHP",
          td2: "Infosys",
          td3: "India"
        }
      },
      Message11: "Týmto potvrdzujem, že som porozumel upozorneniu; Prečítal (a) som a akceptujem politiku ochrany osobných údajov a osobných údajov spoločnosti Toyota."
    },
    ACCESSORIES: "Príslušenstvo",
    activity: {
      Assignee: "Zodpovedná osoba",
      CompletionDate: "Dátum ukončenia",
      Dealer: "Dealer",
      FirstVisit: "Prvá návšteva",
      kpi: {
        kpi: "KPI by mali byť čísla (+ alebo -)",
        kpiLength: "Maximálny počet znakov pre KPI je 10 ",
        mandatory: "Prosím, do všetkých povinných polí zadajte platné údaje",
        mandatoryTargets: "Prosím, zadajte všetky KPI.",
        target: "Target by mal obsahovať iba čísla",
        targetLength: "Maximálny počet znakov pre target je 10 "
      },
      ReminderOn: "Pripomienka zapnutá",
      statusDD: "Stav",
      Toolbox: "Nástroje",
      activityCreation: "Proces vytvárania aktivity bol zahájený.",
      activityCreated: "Aktivita bola vytvorená"
    },
    DPOK: "DPOK",
    EFFICIENCY: "Efektívnosť",
    FIX_IT_RIGHT: "Oprava na prvýkrát",
    footer: {
      message: "Povinné polia"
    },
    FOUNDATION: "Základné informácie",
    MAINTENANCE_REMINDERS: "Pripomienka údržby",
    EMPLOYEE_ENGAGEMENT: "Employee Engagement",
    CUSTOMER_CENTRICITY: "Customer Centricity",
    TOYOTA_WAY: "Toyota Way",
    BUSINESS_SUSTAINABILITY: "Business Sustainability",
    FUNDAMENTALS: "Základné princípy",
    FIX_IT_RIGHT_BnP: "Fix it Right (B&P)",
    UTILIZATION_AND_EFFICIENCY: "Utilization and Efficiency",
    CUSTOMER_EXPERIENCE: "Customer Experience",
    UPSELLING_OPPORTUNITIES: "Upselling Opportunities",
    MANAGING_CAPACITY: "Managing Capacity",
    VEHICLE_TRADE_IN: "Vehicle Trade-In",
    STOCK_MANAGEMENT: "Stock Management",
    REFURBISHMENT: "Refurbishment",
    months: {
      apr: "Apr.",
      aug: "Aug.",
      dec: "Dec.",
      feb: "Feb.",
      jan: "Jan.",
      jul: "Júl",
      jun: "Jún",
      mar: "Mar.",
      may: "Máj",
      nov: "Nov.",
      oct: "Okt.",
      sep: "Sep."
    },
    others: {
      back: "Späť",
      closed: "Uzavreté",
      completed: "Dokončené ",
      countryLanguageCode: "Kód krajiny/jazyka ",
      createActivity: "Vytvoriť aktivitu",
      createdDate: "Dátum vytvorenia",
      dataUneditableNote:
        "Poznámka: Uložené údaje sa už nedajú upraviť. Pre úpravu údajov kontaktujte správcu distribútorskej siete.",
      date: "Dátum",
      downloadQuestionnaire: "Stiahnuť dotazník",
      editActivity: "Upraviť aktivitu",
      initialHelpText: "Počiatočné meranie KPI by sa malo rovnať priemernej hodnote z posledných šiestich mesiacov",
      inProgress: "Prebiehajúce",
      name: "Meno",
      next: "Ďalej",
      noActivities: "Neboli nájdené žiadne aktivity",
      noResults: "Žiadne výsledky",
      of: "z",
      ongoing: "Prebiehajúce",
      open: "Otvoriť",
      page: "Strana",
      planned: "Naplánované",
      problemDescription: "Popis problému",
      signature: "Podpis",
      select: "Vybrať",
      bulkAction: "Bulk Action",
      bulkActions: "Bulk Actions"
    },
    ServerMessage: {
      error_message: "V priebehu sťahovania došlo k chybe.",
      no_published_subquestion: "Ak chcete zahájiť túto aktivitu, zverejnite prosím dotazník pre toto nastavenie.",
      activity_upload_error_msg: "Chyba pri nahrávaní Activity.",
      action_plan_created: "Akčný plán bol úspešne vytvorený",
      action_plan_deleted: "Akčný plán bol vymazaný",
      action_plan_saved: "Akčný plán bol uložený",
      activity_kpi_saved: "Aktivity KPI boli uložené",
      master_kpi_confirm_delete:
        "Naozaj chcete vymazať vybrané KPI?",
      master_kpi_created: "Master KPI úspešne vytvorený",
      master_kpi_deleted: "Master KPI bol vymazaný",
      master_kpi_not_found: "Master KPIsa nenašiel",
      master_kpi_published: "Vybrané Master KPI boli úspešne zverejnené.",
      master_kpi_retired: "Master KPI bol vyradený",
      master_kpi_saved: "Master KPI úspešne uložený",
      subquestion_created: "Podotázka bola úspešne  uložená.",
      subquestion_updated: "Podotázka bola úspešne aktualizovaná.",
      subquestionnaire_not_found: "Podotázka nebola nájdená.",
      subquestion_retired: "Podotázka bola vyradená.",
      subquestion_deleted: "Podotázka bola vymazaná.",
      subquestion_published: "Podotázky boli úspešne zverejnené.",
      mainquestion_created: "Hlavný dotazník bol vytvorený.",
      mainquestion_deleted: "Hlavný dotazník bol vymazaný.",
      mainquestion_updated: "Otázka bola aktualizovaná",
      duplicateMsg: "Duplicitné záznamy v  Exceli.",
      deleteErr: "Chyba pri mazaní súboru",
      toolbox_deleted: "Modul bol úspešne odstránený",
      toolbox_already_exists: "Tento modul už existuje.",
      toolbox_saved: "Modul bol uložený",
      toolbox_created: "Modul bol vytvorený",
      toolbox_not_found: "Modul sa nenašiel",
      published_content_found: "Tento modul nemožno odstrániť, pretože obsahuje už zverejnený obsah",
      dealers_already_mapped: " Vybraní dealeri už boli mapovaní týmto užívateľom. ",
      dealer_already_mapped: " Dealer already mapped ",
      dealer_added: "  Dealer pridaný. ",
      dealers_added: "  Dealer pridaný. ",
      total_dealers_mapped_now: "Celkový počet dealerov aktuálne namapovaných týmto užívateľom je . ",
      no_dealer_available: "Pre zvolené kritérium nie je dostupný žiadny dealer.",
      proper_dealer_selected: "Prosím, vyberte vhodného dealera",
      user_referenced_ongoing_activity: "Užívateľ je momentálne priradený k prebiehajúcim aktivitám. Nahraďte v týchto aktivitách zástupcu a skúste to znova. ",
      want_to_remove_mapping: "Naozaj chcete odstrániť mapovanie užívateľa pre týchto dealerov?",
      user_mapping_removed: "Vybraní dealeri boli úspešne odstránení z mapovania užívateľa.",
      dealer_closed_in_A2D: "Tento dealer je registrovaný ako 'Closed' v A2D",
      network_level_not_found: "Vyberte platnú kombináciu úrovní siete podľa vášho KET nastavenia.",
      duplicate_dealer_uuid: "Dealer je už založený v KET databáze",
      dealer_created: "Dealer vytvorený",
      dealer_updated: "Atribúty tohto dealera boli aktualizované.",
      dealer_not_present: "Tento dealer nie je registrovaný v A2D.",
      dealer_uuid_other_nmsc: "Vybraný A2D uuid kód nie je pre vašu sieť k dispozícii.",
      target_kpi_updated: "The KPI's status have been updated successfully.",
      target_updated: "The KPI's target have been updated successfully.",
      kpi_launched: "The KPI's bulk launch successfully.",
      targetUpdate:"Application doesn't allow target to be modified for accepted status.",
    },
    standardMessages: {
      delete: "Naozaj chcete odstrániť ?",
      addActionPlanNotAllowed: "Akčný plán nie je možné vytvoriť ani upraviť po tom, čo bola aktivita ukončená, dokončená alebo zamietnutá .",
      addToolboxNotAllowed: "Cannot create more than 19 toolbox",
      britRejectMsg: "Naozaj chcete odmietnuť túto aktivitu? Zmeny nie sú možné po zamietnutí.",
      rejectMsg: "Naozaj chcete odmietnuť túto aktivitu? Zmeny nie sú možné po zamietnutí.",
      selectNMSCTab: "Vyberte jazyk NMSC, v ktorom má byť zverejnený obsah",
      all: "Všetko",
      deleteConfirm: "Naozaj chcete vymazať vybraný KPI?",
      no: "Nie",
      retireConfirm: "Naozaj chcete vyradiť tento KPI? Vyradený KPI, nie je možné znova aktivovať. ",
      warning: "Upozornenie",
      yes: "Áno",
      saveDataConfirmation: "Naozaj chcete uložiť údaje?",
      saveKPIWarning: "Poznámka: Údaje nie je možné po uložení upraviť. Ak chcete upraviť údaje, kontaktujte správcu NMSC KET",
      addKpiNotAllowed: "Nasledujúci mesiac pre vstup do KPI môže byť pridaný, keď končí aktuálny mesiac.",
      activityLaunch: "Naozaj chcete spustiť tieto Activity? Po spustení už nie je možné Activity vrátiť späť do stavu plánovania, alebo vymazať.",
      deleteConfirmSubQuestionNaire: "Naozaj chcete odstrániť vybranú podotázku?",
      retireConfirmSubQuestionNaire: "Naozaj chcete vyradiť vybranú podotázku?",
      deleteConfirmMainQuestion: "Potvrďte, prosím, či chcete vymazať otázku a súvisiace podotázky.",
      deleteConfirmChapter: "Naozaj chcete odstrániť vybranú kapitolu?",
      deleteLibraryFolder: "Naozaj chcete odstrániť vybraný priečinok?",
      deletePublishedFolder: "Niektoré súbory v tomto priečinku môžu byť prepojené s dotazníkom. Naozaj chcete natrvalo odstrániť tento podpriečinok a odstrániť odkaz /y z dotazníka?",
      importQuestion: "Naozaj chcete importovať túto podotázku? Aktuálna podotázka bude nahradená.",
      noQuestion: "Nie sú k dispozícii žiadne otázky.",
      MandatoryWarning: "Prosím vyplňte všetky povinné polia pre všetky miestne jazyky.",
      certificationSubmission:
        "Modul bol poslaný do TME za účelom certifikácie predajcu",
      sameToolBox: "Rovnaký modul nemôžete odoslať viackrát. Vyberte iný modul",
      deleteConfirmDealer: "Are you sure you want to delete this dealer ?",
      updateVcConfirmDealer: " Všetky aktivity vytvorené pre tohto dealera vo \"Value chain\" budú odstránené. Ste si istí, že chcete pokračovať?",
      updateBritConfirmDealer: "Všetky aktivity vytvorené pre tohto dealera vo \"Brit\" budú odstránené. Ste si istí, že chcete pokračovať?",
      completed_activity_delete:"Po vymazaní dát sa už nedajú tieto dáta načítať. Ste si istí, že chcete dáta vymazať?",
      deleteAllQuestionary: "Are you sure you want to delete the entire Questionnaire?",
      deleteAllLibraries: "Are you sure you want to delete all folders and files in the Library?",
      selectAll: "Select All",
      unSelectAll: "Unselect All",
      select:"Select...",
      warningMsg:"There are Open Action plan(s) in this activity. Would you like to Complete the activity?"
    },
    status: {
      published: "Zverejnené",
      retired: "Vyradené",
      saved: "Uložené",
      rejected: "Odmietnuté",
      open: "Otvoriť",
      closed: "Uzavreté",
      close: "Uzavreté",
      ongoing: "Prebiehajúce",
      completed: "Dokončené",
      complete: "Dokončené",
      planned: "Naplánované",
      overdue: "V oneskorení",
      due: "Očakávaný",
      pending: "Pending",
    },
    Rejected: "Odmietnuté",
    Open: "Otvoriť",
    Closed: "Uzavreté",
    Close: "Uzavreté",
    Ongoing: "Prebiehajúce",
    Completed: "Dokončené",
    Complete: "Dokončené",
    Planned: "Naplánované",
    tabDetails: {
      noActionAvailable: "Nie sú k dispozícii žiadne akcie",
      activeDealer: "Tento dealer je momentálne zaregistrovaný ako aktívny v A2D a nemožno ho vymazať.",
      dealerDeleted: "Dealer is deleted."
    },
    Table: {
      action: "",
      activity: "Aktivity",
      activityCompletionDate: "Dátum dokončenia aktivity",
      activityCompletionDateFrom: "Dátum ukončenia aktivity od",
      activityCompletionDateTo: "Dátum ukončenia aktivity do",
      activityStartDate: "Dátum začatia aktivity",
      activityStartDateFrom: "Dátum začatia aktivity od",
      activityStartDateTo: "Dátum začatia aktivity do",
      assignedTo: "Priradené",
      assignee: "Zodpovedná osoba",
      benchmark: "Benchmark",
      city: "Mesto",
      completedBy: "Dokončil/a ",
      completionBy: "Ukončené",
      completionDate: "Dátum ukončenia",
      counterMeasure: "Opatrenia",
      country: "Krajina",
      createdBy: "Vytvoril/a",
      dealer: "Dealer",
      dealerNameAD: "Meno dealera (A2D)",
      dealerName: "Názov predajcu",
      definition: "Definícia",
      description: "Popis",
      dueDate: "Termín dokončenia",
      endDate: "Dátum ukončenia",
      firstVisit: "Prvá návšteva",
      followup: "Následná kontrola",
      franchise: "Franchise",
      history: "História",
      id: "ID",
      initial: "Počiatočné",
      kpi: "KPI",
      launch: "Spustiť",
      mandatory: "Povinné",
      nmsc: "Dovozca",
      problem: "Problém",
      publishedOn: "Zverejnené dňa",
      questionReference: "Otázky",
      reference: "Referencia",
      reminderOn: "Pripomienka zapnutá",
      score: "Skóre",
      startDate: "Dátum zahájenia",
      status: "Stav",
      target: "Target",
      toolbox: "Modul",
      view: "Zobraziť",
      kpiResult: "KPI výsledky",
      nmscTarget: "NMSC Target",
      penEbenchmark: "PanE Benchmark",
      kpiField: "KPI",
      location: "Poloha",
      ActionItemReport: "Prebiehajúce akčné položky",
      certification: "Stav certifikácie",
      noOfToolbox: "Počet dokončených nastavení",
      program: "Program",
      certificationDate: "Dátum overenia",
      activityId : 'ID aktivita',
      approvedBy:"Approved By",
      kpis:"KPIs",
      lastmonthKpis:"Last Month KPIs",
      questionPercent:"Completion %",
      actionPlan: "Plán činnosti",
      monthlyKpi:"Monthly KPIs",
      initialKpi:"Initial KPIs",
      targetKpi:"Target KPIs",
    },
    tabsDetails: {
      actionPlan: "Plán činnosti",
      confirmDeleteMessage: "Naozaj chcete vymazať vybraný KPI?",
      confirmRetireMessage: "Potvrďte, či chcete vyradiť vybraný KPI, keďže už bol v minulosti vydaný",
      errorRetireMsg: "Tento KPI nie je možné vyradiť, jedná sa o povinný KPI ",
      import: "Importovať",
      importKpiMessage: "Naimportujte svoj KPI zo zoznamu TME Master KPI",
      kpi: "Key Performance Indicators",
      library: "Knižnica",
      noActionAvailable: "Nie sú k dispozícii žiadne akcie",
      NoKPI: "K dispozícii nie sú žiadne KPI",
      publish: "Zverejniť",
      questionnaire: "Dotazník",
      tmeKPIList: "Zoznam TME Master KPI",
      toyotaCityBrussels: "TOYOTA CITY BRUSSELS",
      attachment: "Attachment",
      attachments: "Attachment(s)",
      uploadAttachment: "Upload Attachment",
      noAttachment: "No attachments available",
    },
    tmeAdminMaster: {
      ToolboxName: "Názov nastavenia",
      newToolboxName: "Nový názov",
      deleteToolbox: "Vymazať nastavenie? ",
      programName: "Názov programu",
      ToolboxName_EN: "Názov nastavenia  (EN)",
      deleteTool: "Naozaj chcete odstrániť toto nastavenie?",
      selectToolbox: "Prosím vyberte nastavenie, ktoré chcete odstrániť.",
      editToolBox: "Upraviť nastavenie",
      addToolbox: "Pridať nastavenie",
      toolbox: "Prosím, vložte názov pre toto nastavenie",
      addActionPlan: "Add Plán činnosti",
      actionPlanItem: "Plán činnosti – položka č.",
      add: "Pridať",
      addActivity: "Pridať činnosť",
      additionalInformation: "Doplňujúce informácie",
      benchmark: "Benchmark",
      cancel: "Zrušiť",
      createEditActivity: "Vytvoriť/upraviť aktivitu",
      createKPI: "Vytvoriť KPI",
      delete: "Odstrániť",
      download: "Stiahnuť",
      edit: "Upraviť",
      editKPI: "Upraviť KPI",
      keyPerformanceIndicator: "Key Performance Indicator",
      kpiDefinition: "Definícia KPI",
      mandatoryKpi: "Povinné KPI",
      save: "Uložiť",
      search: "Hľadať",
      unit: "Jednotka",
      update: "Aktualizovať",
      validData: "Prosím zadajte platné údaje",
      hide: "Skryť",
      show: "Zobraziť",
      upload_dealer_network: "Nahrať dealerskú sieť",
      upload_question: "Nahrať dotazník",
      upload_user: "Nahrať údaje o užívateľovi",
      upload_activity: "Nahrať aktivity",
      remove: "Remove",
      toyota: "Toyota",
      lexus: "Lexus",
      launch: "Launch",
      close: "Close",
      generate: "Generate",
      dueDateExceed:"Due date exceeded",
      delQuestionnaire:"Delete questionnaire"
    },
    toastr: {
      activityStatus_condition: "Aby ste mohli túto aktivitu ukončiť, odpovedzte prosím na všetky otázky.",
      emptyTarget: "Please enter the NMSC target to publish the KPI",
      retailerActual: "Please enter the Retailer Actual",
      initialTargetMonthly: "Please enter the initial KPI measurement, the KPI targets and at least 1 month of measurement to continue.",
      initialTarget: "Ak chcete pokračovať, zadajte prosím počiatočné meranie KPI a ciele KPI.",
      upload_error: "Chyba pri nahrávaní dealerskej siete.",
      action_item_create_warning: "Do jedného Action súboru môže byť zlúčených maximálne 5 záznamov.",
      actionPlanStartDateValidation: "Dátum ukončenia nemôže byť skorší ako dátum začatia",
      actionsNotClosed: "Stále prebiehajú akcie; pred ukončením aktivity, prosím zatvorte všetky akcie",
      activityDuplicate: "Už existuje iná aktivita s rovnakým predajcom a nástrojmi",
      assigneeNotExist: "Vybraná osoba nie je v systéme registrovaná",
      assigneeValidation: "Zodpovedná osoba nebola nájdená",
      atleastOnePublish: "Vyberte aspoň jeden KPI na zverejnenie",
      cannotRetireActive: "Jedná sa o povinný KPI, ktorý nie je možné vyradiť ",
      completionDate: "Dátum ukončenia nemôže nastať skôr ako dátum prvej návštevy",
      confirDeletePlannedActivity: "Naozaj chcete vymazať plánovanú aktivitu?",
      DateformatValidation: "Dátum musí byť vo formáte DD/MM/RRRR",
      DateValidation: "Dátum začatia musí byť skorší ako dátum ukončenia",
      dealerValidation: "Predajca nebol nájdený",
      deleteSelectedConfirm: "Naozaj chcete vymazať vybraný KPI?",
      duplicate: "Už existuje iný KPI s rovnakým názvom a popisom",
      modalMandatory: "Prosím, do všetkých povinných polí zadajte platné údaje",
      noPublishKpi: " Pre vybrané nástroje neboli ešte KPI zverejnené ",
      validSearchCriteria: "Zadajte platné kritérium vyhľadávania",
      multiSelectPlaceHolder: "Zadajte aspoň 5 znakov",
      multiSelectPlaceHolderUser: "Zadajte aspoň 3 znakov",
      startDateValidation: "Dátum začatia nemôže byť neskorší ako dátum ukončenia",
      fileSelectionErr: "Prosím, najprv vyberte súbor.",
      atleastOneQuestion: "Prosím vyberte aspoň jednu podotázku na import.",
      atleastOneSubQues: "Vyberte aspoň jednu podotázku, ktorú chcete zverejniť.",
      uploadErr: "Počet súborov, ktoré sa nepodarilo nahrať:",
      uploadSuccess: "Dealerská sieť bola úspešne nahraná.",
      file_upload_success: "Súbor/y boli úspešne nahrané.",
      KPIinOngoingClosedActivity: "Tento KPi je prítomný minimálne v jednej prebiehajúcej / uzavretej aktivite. Pred vykonaním zmien v KPI, prosím, tieto aktivity dokončite.",
      Role_Error: "Nastavenie užívateľa nie je dokončené, kontaktujte správcu KET spoločnosti NMSC.",
      points_allocation_incorrect: "Nesprávne pridelenie bodov, prosím, upravte.",
      activity_deleted:"Aktivita je úspešne zrušená",
      activity_certified_dealer:"Dealer bol certifikovaný pre vybranú aktivitu, nedá sa vymazať.",
      activity_submited_for_certificate_dealer:"Táto aktivita bola zahrnutá do žiadosti o certifikáciu odoslanej do TME, preto sa stav nedá zmeniť."
    },
    TopNav: {
      ActivityLog: "Aktivity",
      ApplicationName: "Kaizen Expert Tool",
      Home: "Domov",
      logout: "Odhlásiť sa",
      MasterDataUpload: "Nahrať zákadné údaje",
      Toolbox: "Modul",
      welcome: "Vitajte",
      Admin: "Administrátor"
    },
    questionnaire: {
      addSubQuestion: "Pridať podotázku",
      editSubQuestion: "Upraviť podotázku",
      subQuestionTitle: "Podotázka",
      additionalInfo: "Doplňujúce informácie",
      na: "Na túto otázku je možné použiť odpoveď „Nevzťahuje sa“ ",
      question: "Otázka",
      questionTitle: "Názov otázky",
      why: "Prečo?",
      addMainQuestion: "Pridať otázku",
      editMainQuestion: "Upraviť otázku",
      previousQuestion: "Predchádzajúca otázka",
      nextQuestion: "Ďalšia otázka",
      what: "Čo?",
      library: "Knižnica",
      subQuestionTable: {
        no: "Nie",
        subQuestion: "Podotázky",
        status: "Stav",
        publishedDate: "Dátum zverejnenia",
        notApplicable: "Nevzťahuje sa"
      },
      links: {
        question_link_button: "Odkaz",
        question_link_include_folder: "Zahrnúť podpriečinok",
        question_link_add_link: "Prepojiť súbor/y z knižnice",
        question_link_view_folder: "Knižnica"
      },
      mainQuestion: "Nové/ aktualizované otázky :",
      subQuestion: "Nové/ aktualizované podotázky :",
      retiredQuestion: "Vyradené podotázky:",
      libraries: "Nové/ aktualizované súbory v Knižniciach:",
      noFiles: "Nie sú k dispozícii žiadne súbory."
    },
    library: {
      selectFile: "Prosím vyberte aspoň jeden súbor.",
      fileSelected: "Vybrané súbory",
      publish_library_file: "Prosím vyberte aspoň jeden súbor na nahratie.",
      library_files_deleted: "Chcete vymazať tento súbor?",
      library_files_linked_deleted: "Naozaj chcete odstrániť tento súbor a odkazy vytvorené v dotazníku?",
      max_size_error: "Veľkosť súborov presahuje limit 1 GB.",
      empty_file_desc: "Prosím, vložte popis súboru.",
      file_not_selected: "Vyberte prosím súbor/y na nahratie (max. 1 GB)",
      file_type_error: "Tento typ súboru nie je podporovaný.",
      file_name_error: "Neplatný znak v názve súboru.",
      file_name_lenght_error: "Názov súboru môže mať maximálne 50 znakov.",
      addFolder: "Pridať priečinok",
      editFolder: "Upraviť priečinok",
      currentFolder: "Názov priečinka",
      parentFolder: "Názov nadradeného priečinka",
      emptyFolderName: "Názov preičinka musí byť vyplnený",
      invalidFolderName: "Názov zložky nesmie obsahovať znak  '/'.",
      folder_created: "Priečinok bol úspešne vytvorený.",
      folder_updated: "Priečinok bol úspešne aktualizovaný.",
      folder_not_found: "Priečinok sa nenašiel",
      lib_invalid_toolbox: "Bol vybraný neplatný Toolbox",
      lib_folder_already_exists: "Priečinok už existuje",
      lib_folder_deleted: "Pričinok bol odstránený.",
      library_files_published: "Súbory boli nahrané",
      addFile: "Pridať súbor",
      editFile: "Upraviť súbor",
      fileName: "Názov súboru",
      upload: "Nahrať",
      desc: "Popis",
      folderCreationErr: "Hierarchia je obmedzená na dve úrovne.",
      subFolderWarning: "Vyberte podpriečinok, do ktorého chcete pridať súbory.",
      deleteWarning: "Vyberte priečinok, ktorý chcete odstrániť.",
      beforeAfterNotSelected: "Please select if the evidence is before or after kaizen",
    },
    chapter: {
      chapter_exist: "Táto kapitola už existuje.",
      chapter_saved: "Kapitola bola uložená",
      chapter_cannot_delete: "Túto kapitolu nemožno vymazať, pretože podotázky už boli zverejnené.",
      failed: "Chyba !",
      chapter_deleted: "Kapitola vymazaná",
      chapter_nonEmpty: "Prosím zadajte názov kapitoly",
      addchapter: "Pridať kapitolu",
      editChapter: "Upraviť kapitolu",
      DELETE_SUCC:"Questionnaires deleted successfully.",
      DELETE_SUCC_LIB:"Libraries deleted successfully.",
      DELETE_ERR : "Something Went wrong."
    },
    evaluation: {
      attachments: "Prílohy",
      comments: "Komentáre",
      evidence: "Evidencia",
      evidences: "Evidencia",
      yes: "Áno",
      no: "Nie",
      na: "N/A",
      uploadEvidence: "Nahrať evidenciu",
      fileUpload: "Vyberte prosím súbor/y na nahratie (max. 1 GB)",
      who: "Kto?",
      answer: "Odpoveď",
      comment: "Comment",
      date: "Dátum",
      submit: "Potvrdiť",
      commentMsg: "Prosím vložte komentár.",
      noCommentAvailable: "Nie sú k dispozícii žiadne komentáre.",
      uploadSuccessMsg: "File(s)  have been uploaded successfully",
      uploadErrorMsg: "Number of files failing to upload:",
      fileWithSameName: "Súbor s rovnakým názvom už existuje.",
      noEvidence: "Žiadne podklady k dispozícii",
      createAction: "Vytvoriť Action súbor",
      chapterName: "Názov kapitoly",
      questionNo: "Otázka č.",
      when: "Kedy?",
      noRecords: "Nie sú k dispozícii žiadne záznamy.",
      kaizenPoints: "Body Kaizen",
      renameSuccessMsg: "File has been renamed successfully",
      deleteSuccessMsg: "File has been deleted successfully",
      question: "Otázka",
      before:"Before",
      after:"After",
      beforeAfterLabel:"Is this evidence from before or after kaizen?",
      move:"Move",
      moveMsg: "File(s) has been moved successfully",
      noToYesMsg:"Previous answer was NO",
    },
    WALK_AROUND_CHECK: "Fyzický príjem vozidla",
    ketProgram: {
      TSM: "TSM/LSM",
      BRiT: "BRiT RI",
      TSM_BNP: "Body & Paint",
      TSM_USED_CAR: "Jazdené vozidlá",
      value_chain: "Value Chain",
      brit: "BRiT",
      ea: "EA",
      per:"Performance",
      nettrans:"Network Transformation",
    },
    dashboard: {
      notice_board: "Poznámková tabuľa",
      kpi_target_not_set: "Ciele KPI nie sú nastavené",
      kpi_initial_not_set: "Počiatočné hodnotenie KPI nie je nastavené",
      kpi_monthly_not_set: "Mesačné hodnotenie KPI nie je nastavené",
      kpi_below_target: "Nesplnené ciele KPI",
      activation: "Aktivácia",
      users: "Užívatelia",
      nmscs: "NMSC's",
      activeRetailer: "Aktívni partneri",
      certificationRate: "Certifikačné hodnotenie",
      reCertificationRate: "Recertifikačné hodnotenie",
      activityDue: "Plánované činnosti",
      avgdays: "Priemerný počet dní/ nastavenie",
      overDueAct: "Oneskorené aktivity",
      actionItem: "Akčné položky",
      bussResult: "Obchodné výsledky",
      toolboxImpl: "Implementácia nastavenia",
      statusCompletedL:"Status - Completed, Progress - ",
      statusClosedL:"Status - Closed, Progress - ",
      statusOngoingL:"Status - Ongoing, Progress - ",
      recentUpdates: "Posledné aktualizácie",
      rolloutTopNmsc: "Výsledky zavedenia - najlepší NMSC",
      bestPractice: "Tabuľa úspechov",
      availableKpi: "Dostupné KPI",
      selectedKpi: "Vybrané KPI",
      dealerBestPractice: "Panel najlepších postupov predajcu",
      kpiList: "Zoznam KPI",
      go: "Ísť",
      nodata: "Žiadné údaje k dispozícii",
      businessResultTop: "Obchodné výsledky - Top partneri",
      businessResultNMSC: "Obchodné výsledky - najlepší NMSC"


    },
    dealerCertification: {
      dealerCertification: "Certifikácia dealera",
      readyForCertification: "Pripravené na certifikáciu",
      certification: "Certifikácia",
      submittedOn: "Odoslané dňa",
      certificateDate: "Dátum certifikácie",
      reCertificateDate: "Dátum re-certifikácie",
      toolboxDetails: "Detaily nastavenia",
      approve: "Schváliť",
      reject: "Odmietnuť",
      pending: "Nevybavené",
      region: "Oblasť"



    },

    uploadBulk: {
      browse: "Browse",
      executionId: "Realizácia id",
      uploadedOn: "Nahrané dňa",
      lastUpdatedOn: "Posledná aktualizácia dňa",
      noDataTable: "No data to view",
      kpiUpload: "Načítanie KPI",
      action: "Činnosť",
      processed: "Spracované",
      error: "Chyba",
      received: "Received",
      createActivity: "Vytvoriť aktivity",
      fileUpload: "File Upload",
      targetSetting: "Target Setting",
      kpiEntry:"KPI Entry",
      accepted:"Accepted",
      launchNmsc:"Launch for NMSC"
    },
    userManagement: {
      userManagement: "Správa užívateľov",
      createUser: "Create User",
      editUser: "Edit User",
      viewUser: "View User",
      nmscId: "NMSC ID",
      tarsId: "TARS ID",
      firstName: "Krstné meno",
      lastName: "Priezvisko",
      emailId: "E-mail",
      britDealerChamp: "Brit Dealer Champion",
      invalidEmail: "Please enter valid E-Mail ID",
      invalidTars: "Please enter valid TARS ID",
      invalidFirstName: "Please enter valid First Name",
      invalidLastName: "Please enter valid Last Name",
      user_created: "User Created",
      user_updated: "User updated",
      duplicate_tarsuser: "This TARS id is already registered in the KET database.",
      user_define_in_tool: "User is defined as Business owner in Active Business Function. Hence, could not be made Inactive",
      user_refer_on: "User is referenced in Ongoing activity",
      pbiAccount : "PBI Account",
      invalidPbiAccount : "Účet PBI by mal obsahovať minimálne 3 znaky.",
      businessArea: "Business Area",
      valueChainAndBRiT: "Value Chain & BRiT",
      only:"Only",
      optionText:"Please select at least one of the two options below",
      valueChainOnly: "Len Value Chain",
      britOnly: "Len Brit"
    },
    UserManagement: {
      nmscId: "NMSC ID",
      tarsId: "TARS ID",
      firstName: "Krstné meno",
      lastName: "Priezvisko",
      emailId: "E-mail",
      languageCode: "Jazykový kód",
      ACTIVE: "Active",
      INACTIVE: "Neaktívne",
      addUser: "Pridať užívateľa",
      Assign: "Priradiť",
      List: "Zoznam",
      user: "Užívateľ",
      error_message: "Vyskytla sa chyba, skúste neskôr",
      role: "Rola užívateľa",
      standardMessage: "No User Found",
      dealerCode: "Kód dealera",
      a2d: "A2D UUID",
      britReady: "Predajca sa zúčastnil Brit Ready.",
      britLive: "Predajca sa zúčastnil Brit Live.",
      britJapan: "Predajca sa zúčastnil Brit Discovery Japan.",
      britEss:"Predajca aktualizoval EES akčný plán",
      addDealer: "Pridať dealera (z A2D)",
      noDealer: "Žiadny dealer nebol nájdený.",
      dealerUuid: "Kód dealera uuid (A2D)",
      language: "Jazyk",
      L3: "L3",
      L4: "L4",
      L5: "L5",
      L6: "L6",
    },
    CeaKpi: {
      survey: "Survey",
      evaluationItem: "Evaluation Item",
      kpiMetric: "KPI Evaluation Method",
      stratagicPillar: "Strategic Pillar",
      addKPI: "Add KPI",
      edit: "Edit",
      delete: "Delete",
      subPillar: "Sub-Pillar",
      kaizenImp_impact_def: "Kaizen Importance / Impact / Definition",
      evalMethod: "Evaluation Method",
      scoreMethod: "Scoring Method",
      typeOftarget: "Type of Target",
      target: "Target",
      mode: "Mode",
      points: "Points",
      inputFreq: "Input Frequency",
      cea_kpi_created: "CEA KPI has been created successfully",
      noPointsAllocated:"No Points defined for selected Pillar/Business function mapping",
      targetIncorrect:"Target value is Invalid",
      viewKpi:"View KPI",
      general:"General",
      targetScoring:"Targets & Scoring",
      inputs:"Input Parameters",
      maxFiveParam:"Maximum 5 parameters for a KPI can be added",
      validParam:"Please Enter Valid Parameter",
      validDesc:"Please Enter Valid Parameter Description",
      dataEntry:"Data Entry in KET",
      paramater:"Parameter",
      paramDesc:"Parameter Description",
      noParamAvai:"No Parameters Available",
      kpiDeleted:"KPI deleted Successfully",
      kpiDeleteError:"KPI Not found",
      kpiActualsError:"Actuals present. KPI cannot be deleted",
      cea_kpi_updated: "CEA KPI has been updated successfully",
      addParam:"Add Parameters"

    },
    dealerManagement: {
      invalidUuid: "Prosím, zadajte platné A2D uuid.",
      invalidDealerName: "Prosím, zadajte platné meno dealera.",
      invalidCity: "Prosím, zadajte platnú polohu.",
      invaliDealerCode: "Prosím, zadajte platný kód dealera.",
      existingUuid: "A2D uuid kód je už používaný v KET databáze.",
      updateUuid: "Aktualizujte A2D uuid kód ",
      newUuid: "Nový A2D uuid kód",
    },
    networkManagement: {
      networkManagement: "Správa siete",
    },
    kpiEntry: {
      cancel : "Cancel",
      save : "Save",
      no : "No",
      yes : "Yes",
      hide : "Hide",
      show : "Show",
      dataNotSaved : "Data is not saved. Entered data may be lost. Do you want to Proceed?",
      wantToProceed : "Do you want to proceed?",
      status: 'Status',
      evaluationYear: 'Evaluation Year',
      frequency: 'Frequency',
      typeOfTarget: 'Type of Target',
      target: 'Target',
      unit: 'Unit',
      standardMessage: 'No Kpi Actuals Data Found',
      srNo: 'S.No.',
      actuals: 'Actuals',
      subPillar: 'Sub Pillar',
      businessFunc: 'Business Function',
      brand: 'Brand',
      Jan : "Jan",
      Feb : "Feb",
      Mar : "Mar",
      Apr : "Apr",
      May : "May",
      Jun : "Jun",
      Jul : "Jul",
      Aug : "Aug",
      Sep : "Sep",
      Oct : "Oct",
      Nov : "Nov",
      Dec : "Dec",
      q1 : "Q1",
      q2 : "Q2",
      q3 : "Q3",
      q4 : "Q4",
      h1 : "H1",
      h2 : "H2",
      cy : "CY",
      kpi : "Key Performance Indicators",
      actuals1 : "Actuals should not be more than allotted points. Please re-enter",
      actionPlan	: "Action Plan",
      enterActualsData : "Please enter actuals data.",
      notNumber : "Please enter valid number.",
      serverErrorMsg : "An error occured, please try again later.",
      launchEvaluationError : "Please launch evaluations.",
      actualsDataSaved : "Actuals data uploaded successfully.",
      inputTxt:"Inputs"
    },
    ceaEvaluation :{
      nmsc : "NMSC",
      search : "Search",
      all : "All",
      status : "Status",
      businessFunction :"Business Function",
      noEvaluationsFound : "No Evalutions Found",
      evaluationYear : "Evalution Year",
      view : "View",
      evaluations : "Evaluations",
      launchTargetSetting: "Launch Target Setting",
      launchEvaluation : "Launch Evaluation",
    },
    britDashboard:{
      noVideo:"No videos available",
      playVideo:"Play Video",
      trainingVideos:"Training  Videos",
      download: "Download Video",
      back:"Back"
    },
    ceaActionPlan:{
      id: "ID",
      counterMeasure: "Countermeasure",
      questionReference: "KPI Reference",
      status: "Status",
      startDate: "Start Date",
      dueDate: "Due Date",
      assignedTo: "Assigned To",
      noActionAvailable: "No Actions available",
      add: "Add",
      download: "Download",
      edit: "Edit",
      delete: "Delete",
      deleteMsg: "Are you sure you want to delete ?",
      no: "No",
      yes: "Yes",
      addActionPlanNotAllowed: "The Action Plan cannot be created, or edited after the Activity has been Closed, Completed, or Rejected.",
      actionPlanItem: "Action Plan - Item #",
      addActionPlan: "Add Action Plan",
      open: "Open",
      actionPlanStartDateValidation:"End Date cannot be earlier than the Start Date",
      modalMandatory: "Please enter valid data in all the mandatory fields",
      problem: "Problem",
      description: "Description",
      followup: "Follow Up",
      history: "History",
      cancel: "Cancel",
      save: "Save",
      update: "Update",
      assignee: "Assignee",
      endDate: "End Date", 
      select: "Select",
      notApplicablePer:"Not Applicable for CEA, Networks and Used car"
    },
    reachItDashboard:{
      topNavReachIT:"ReachIT",
      downloads:"K stiahnutiu",
      historyFiles:"História predchádzajúcich súborov",
      reachText1:"Vitajte na domovskej stránke REACH IT. Tím environmentálneho manažmentu TME použije túto stránku na zdieľanie relevantných informácií týkajúcich sa SCIP: Látky vzbudzujúce obavy vo výrobkoch ako takých alebo v komplexných predmetoch (produktoch) ustanovených podľa rámcovej smernice o odpadoch (WFD), aby ste si splnili svoju povinnosť spracovania zjednodušeného upozornenia SCIP.",
      reachText2:"V prípade otázok kontaktujte tím environmentálneho manažmentu TME prostredníctvom nižšie uvedeného odkazu",
      reachText3:"V prípade otázok sa obráťte na svojho distribútora",
      envMngt:"Environmentálny manažment",
      fileStatistics: "Štatistika stiahnutých súborov",
      Downloaded: "Stiahnuté",
      not: "Nie",
      exportToExcel:"Export do Excelu"
    },
    usedCarComment:{
      ongoing:"Ongoing",
      completed:"Completed",
      commentHistory:"Monthly Comments History",
      addComment:"Add Monthly Comment",
      month:"Month",
      comment:"Comment",
      username:"Username",
      email:"Email",
      createdDate:"Created Date"
    },
  }
};

export default translationsSK;
