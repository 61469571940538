import axios from 'axios';

import { toastr } from 'react-redux-toastr';
import { getCallWithReducer, postCallWithReducer } from "./commonAction";
import { getToken } from '../oidc/AuthService';
var I18n = require('react-redux-i18n').I18n;

export function setBlockingState(isBlocked) {

    return function (dispatch) {
        dispatch({
            type: "SET_BLOCKING_STATE",
            payload: isBlocked
        })
    }
}

export function uploadNetworkFile(postData) {
    return function (dispatch) {
        //dispatch(setBlockingState(true));
        axios.post("/upload/dealerNetwork/", postData, {
                headers: {
                    'Authorization': 'Bearer ' + getToken(),
                    'Content-type': 'multipart/form-data',
                    'Accept': 'application/json',
                    'Pragma': 'no-cache'
                }
            })
            .then((response) => {
                dispatch(setBlockingState(false))
                if (response.data.statusCode === '200') {
                    dispatch({                   
                        type: "UPLOAD_NETWORK_DATA",
                        payload: true
                    })
                    toastr.success(I18n.t('toastr.uploadSuccess'));
                } else {
                    toastr.error(I18n.t('toastr.upload_error'));
                }                
            })
            .catch((err) => {
                if(err.response.status===504){
                    toastr.error('Session timeout');
                }
                else{
                    toastr.error(I18n.t('toastr.upload_error'));
                }
                console.log(err.response.status);
                dispatch(setBlockingState(false))
            })
    }
}

    export function uploadQuestionsFile(postData, srcKey) {
        return function (dispatch) {
            //dispatch(setBlockingState(true));
            axios.post("/upload/questionnaire/" + srcKey, postData, {
                    headers: {
                        'Authorization': 'Bearer ' + getToken(),
                        'Content-type': 'multipart/form-data',
                        'Accept': 'application/json',
                        'Pragma': 'no-cache'
                    }
                })
                .then((response) => {
                    dispatch(setBlockingState(false))
                    dispatch({                   
                        type: "UPLOAD_NETWORK_DATA",
                        payload: true
                    })
                    toastr.success(I18n.t('toastr.file_upload_success'));
                })
                .catch((err) => {
                    toastr.error(I18n.t('toastr.uploadErr'));
                    dispatch(setBlockingState(false))
                })
        }

}

export function deleteQuestionsFile(fileName, srcKey) {
    return function (dispatch) {
        //dispatch(setBlockingState(true));
        axios.get("/upload/questionnaire/delete/" + fileName + "/" + srcKey, {
            headers: {
                'Authorization': 'Bearer ' + getToken(),
                "Content-type": "application/json",
                Accept: "application/json",
                Pragma: "no-cache"
            }
        })
            .then(response => {
                dispatch(setBlockingState(false))
                if (response.data.statusCode === '200') {
                    toastr.success(response.data.desc);
                } else {
                    toastr.warning(response.data.desc);
                }

            })
            .catch(err => {
                toastr.error(I18n.t('ServerMessage.deleteErr' + err));
                dispatch(setBlockingState(false))
            })
    }

}

export function uploadUserFile(postData) {
    return function (dispatch) {
        //dispatch(setBlockingState(true));
        axios.post("/upload/userDetails/", postData, {
                headers: {
                    'Authorization': 'Bearer ' + getToken(),
                    'Content-type': 'multipart/form-data',
                    'Accept': 'application/json',
                    'Pragma': 'no-cache'
                }
            })
            .then((response) => {
                dispatch(setBlockingState(false))
                dispatch({                   
                    type: "UPLOAD_NETWORK_DATA",
                    payload: true
                })
                toastr.success(I18n.t('User details uploaded successfully'));
            })
            .catch((err) => {
                toastr.error(I18n.t('Error while uploading User Details'));
                dispatch(setBlockingState(false))
            })
    }
}

export function uploadActivityFile(postData) {
    return function (dispatch) {
        //dispatch(setBlockingState(true));
        axios.post("/upload/activityDetails/", postData, {
                headers: {
                    'Authorization': 'Bearer ' + getToken(),
                    'Content-type': 'multipart/form-data',
                    'Accept': 'application/json',
                    'Pragma': 'no-cache'
                }
            })
            .then((response) => {
                dispatch(setBlockingState(false))
                if (response.data.statusCode === '200') {
                    toastr.success(I18n.t('Activity Details uploaded successfully'));
                } else {
                    toastr.error(I18n.t('ServerMessage.duplicateMsg'));
                }
            })
            .catch((err) => {
                toastr.error(I18n.t("ServerMessage.activity_upload_error_msg"));
                dispatch(setBlockingState(false))
            })
    }
}