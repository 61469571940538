const translationsDE = {
  de: {
    Policy: {
      HeaderMsg: "Datenschutzerklärung für die Verarbeitung Ihrer personenbezogenen Daten im Rahmen des Kaizen Expert Tool",
      Message1: "Diese Datenschutzerklärung bezieht sich auf die Verarbeitung Ihrer persönlichen Daten im Rahmen des Kaizen Expert Tool. Es muss immer zusammen mit der allgemeinen.",
      Message2: "Toyota Motor Europe NV / SA, mit dem Hauptsitz in der Avenue du Bourget / Bourgetlaan 60, 1140 Brüssel, Belgien, als Datenverarbeiter, erfasst Ihre persönlichen Daten (wie unten angegeben) für die unten beschriebenen Zwecke.",
      Message3: "Welche Ihrer persönlichen Daten werden gespeichert?",
      Message4: "Wir erfassen die folgenden Kategorien Ihrer persönlichen Daten in Bezug auf das Kaizen Expert Tool:",
      Table1: {
        tr1: "Kategorie (n) der persönlichen Daten:",
        tr2: "Nutzer ID",
        tr3: "Benutzername (vollständiger Name)",
        tr4: "Marke (Toyota,Lexus)",
        tr5: "Bevorzugte Sprache",
        tr6: "Verbundene Organisation"
      },

      Message5: "Zweck und rechtliche Grundlagen der Verarbeitung Ihrer personenbezogenen Daten.",
      Message6: "Wir werden Ihre personenbezogenen Daten zu den unten beschriebenen rechtlichen Zwecken verarbeiten:",
      Table2: {
        tr1: {
          th1: "Zweck:",
          th2: "Rechtliche Grundlage:"
        },
        tr2: {
          td1: "KET - 1: Einzelhändlerleistung Kaizen",
          td2: "Berechtigtes Interesse des / der für die Datenverarbeitung Verantwortlichen"
        },
        tr3: {
          td1: "KET – 2: IT Support",
          td2: "Berechtigtes Interesse des / der für die Datenverarbeitung Verantwortlichen"
        }
      },
      Message7: "Dauer der Aufbewahrung Ihrer persönlichen Daten",
      Message8: "Ihre persönlichen Daten werden für die unten angegebene Dauer aufbewahrt:",
      Table3: {
        tr1: {
          th1: "Aufbewahrungsfrist:",
          th2: "Kategorie (n) der persönlichen Daten:"
        },
        tr2: {
          td1: "Alle oben beschriebenen persönlichen Informationen werden ab dem Tag der endgültigen Schließung des Kaizen Expert Tools für maximal 5 Jahre + 1 Monat aufbewahrt.",
          td2: "Nutzer Vorname, Nachname, bevorzugte Sprache, Organisation"
        }
      },
      Message9: "Optional: Im Falle der Weitergabe / Übermittlung Ihrer persönlichen Daten an Länder außerhalb des Europäischen Wirtschaftsraums (EWR)",
      Message10: "Ihre persönlichen Daten werden Empfängern in Ländern außerhalb des EWR wie unten angegeben zugänglich gemacht:",
      Table4: {
        tr1: {
          td1: "Identität",
          td2: "Land",
          td3: ""
        },
        tr2: {
          td1: "Nicht-EWR Empfänger",
          td2: "Infosystem",
          td3: "Indien"
        }
      },
      Message11: "Hiermit bestätige ich, diese Mitteilung verstanden zu haben; Ich habe die allgemeinen Datenschutzbestimmungen von Toyota gelesen und akzeptiere diese."
    },
    ACCESSORIES: "Zubehör",
    activity: {
      Assignee: "Verantwortlicher",
      CompletionDate: "Abschlussdatum",
      Dealer: "Händler",
      FirstVisit: "Erster Termin",
      kpi: {
        kpi: "KPIs als Zahl eingeben (+/-)",
        kpiLength: "Maximale Länge für KPI 10 Zeichen",
        mandatory: "Bitte gültige Daten in alle Pflichtfelder eingeben",
        mandatoryTargets: "Bitte alle KPI's eintragen",
        target: "Ziel im Zahlenformat eingeben",
        targetLength: "Maximale Länge für Ziel 10 Zeichen"
      },
      ReminderOn: "Wiedervorlage am ",
      statusDD: "Status",
      Toolbox: "Toolbox",
      activityCreation: "Prozess zur Anlage von Aktivitäten gestartet",
      activityCreated: "Aktivitäten wurden angelegt"
    },
    DPOK: "Lager Kaizen",
    EFFICIENCY: "Effizienz",
    FIX_IT_RIGHT: "Fix It Right",
    footer: {
      message: "Pflichtfelder"
    },
    FOUNDATION: "Basics",
    MAINTENANCE_REMINDERS: "PAKM",
    EMPLOYEE_ENGAGEMENT: "Employee Engagement",
    CUSTOMER_CENTRICITY: "Customer Centricity",
    TOYOTA_WAY: "Toyota Way",
    BUSINESS_SUSTAINABILITY: "Business Sustainability",
    FUNDAMENTALS: "Grundlagen",
    FIX_IT_RIGHT_BnP: "Fix it Right (B&P)",
    UTILIZATION_AND_EFFICIENCY: "Utilization and Efficiency",
    CUSTOMER_EXPERIENCE: "Customer Experience",
    UPSELLING_OPPORTUNITIES: "Upselling Opportunities",
    MANAGING_CAPACITY: "Managing Capacity",
    VEHICLE_TRADE_IN: "Vehicle Trade-In",
    STOCK_MANAGEMENT: "Stock Management",
    REFURBISHMENT: "Refurbishment",
    months: {
      apr: "Apr.",
      aug: "Aug.",
      dec: "Dez.",
      feb: "Feb.",
      jan: "Jan.",
      jul: "Jul.",
      jun: "Jun.",
      mar: "Mär.",
      may: "Mai",
      nov: "Nov.",
      oct: "Okt.",
      sep: "Sep."
    },
    others: {
      back: "Zurück",
      closed: "Geschlossen",
      completed: "Abgeschlossen ",
      countryLanguageCode: "Land / Sprachcode ",
      createActivity: "Maßnahme erstellen",
      createdDate: "Erstellungsdatum",
      dataUneditableNote: "Hinweis: Gespeicherte Daten können nicht mehr bearbeitet werden. Um die Daten anzupassen, setzen Sie sich bitte mit NMSC KET Admin in Verbindung.",
      date: "Datum",
      downloadQuestionnaire: "Fragebogen herunterladen",
      editActivity: "Aktivität bearbeiten",
      initialHelpText: "Die ursprüngliche KPI-Messung sollte dem Durchschnittswert der vergangenen vollen sechs Monate entsprechen",
      inProgress: "In Bearbeitung",
      name: "Name",
      next: "Weiter",
      noActivities: "Keine Maßnahme gefunden",
      noResults: "Keine Ergebnisse",
      of: "von",
      ongoing: "Laufend",
      open: "Offen",
      page: "Seite",
      planned: "Geplant",
      problemDescription: "Problembeschreibung",
      signature: "Unterschrift",
      select: "Wählen",
      bulkAction: "Bulk Action",
      bulkActions: "Bulk Actions"
    },
    ServerMessage: {
      error_message: "Es ist ein Fehler während des Downloads aufgetreten.",
      no_published_subquestion: "Bitte veröffentlichen Sie den Fragebogen für die Toolbox, um die Aktivität zu starten.",
      activity_upload_error_msg: "Fehler während des Uploads von Aktivitätsdetails ",
      action_plan_created: "Maßnahmenplan erfolgreich erstellt",
      action_plan_deleted: "Maßnahmenplan erfolgreich gelöscht",
      action_plan_saved: "Maßnahmenplan erfolgreich gespeichert",
      activity_kpi_saved: "KPIs erfolgreich gespeichert",
      master_kpi_confirm_delete: "Sind Sie sicher, dass sie die ausgewählten KPIs löschen möchten?",
      master_kpi_created: "Master-KPI erfolgreich erstellt",
      master_kpi_deleted: "Master-KPI gelöscht",
      master_kpi_not_found: "Master-KPI nicht gefunden",
      master_kpi_published: "Die ausgewählten Master-KPIs wurden erfolgreich veröffentlicht",
      master_kpi_retired: "Master-KPI ungültig",
      master_kpi_saved: "Master-KPI erfolgreich gespeichert",
      subquestion_created: "Die Unterfrage wurde erfolgreich gespeichert.",
      subquestion_updated: "Die Unterfragen wurde erfolgreich aktualisiert.",
      subquestionnaire_not_found: "Die Unterfrage wurde nicht gefunden.",
      subquestion_retired: "Die Unterfrage wurde zurückgezogen.",
      subquestion_deleted: "Die Unterfrage wurde gelöscht.",
      subquestion_published: "Die Unterfrage wurden erfolgreich veröffentlicht.",
      mainquestion_created: "Der Hauptfragebogen wurde erstellt.",
      mainquestion_deleted: "Der Hauptfragebogen wurde gelöscht.",
      mainquestion_updated: "Die Hauptfrage wurde aktualisiert",
      duplicateMsg: "Dubletten in der Excel Datei",
      deleteErr: "Fehler beim Löschen der Datei",
      toolbox_deleted: "Die Toolbox wurde erfolgreich gelöscht",
      toolbox_already_exists: "Diese Toolbox existiert bereits",
      toolbox_saved: "Toolbox gespeichert",
      toolbox_created: "Toolbox angelegt",
      toolbox_not_found: "Toolbox nicht gefunden",
      published_content_found: "Toolbox kann nicht gelöscht werden, da sie bereits veröffentlichten Inhalt hat",
      dealers_already_mapped: " Die ausgewählten Händler sind bereits diesem Nutzer zugewiesen. ",
      dealer_already_mapped: " Dealer already mapped ",
      dealer_added: "  Händler hinzugefügt. ",
      dealers_added: "  Händler hinzugefügt. ",
      total_dealers_mapped_now: "Die Gesamtanzahl der Händler, die diesem Nutzer zugewiesen sind ist: ",
      no_dealer_available: "Keine Händler für die ausgewählten Kriterien vorhanden.",
      proper_dealer_selected: "Bitte einen geeigneten Händler auswählen.",
      user_referenced_ongoing_activity: "Der Nutzer ist aktuell einer laufenden Aktivität zugewiesen. Bitte den Nutzer ersetzten und erneut versuchen. ",
      want_to_remove_mapping: "Sind sie sicher, dass sie die Nutzerzuweisung für diese Händler entfernen möchten?",
      user_mapping_removed: "Der Händler ist erfolgreich aus der Nutzerzuweisung entfernt worden.",
      dealer_closed_in_A2D: "Dieser Händler ist als \"geschlossen\" in A2D hinterlegt.",
      network_level_not_found: "Bitte eine gültige Kombination von Netzwerklevel auf Basis des KET Setups wählen.",
      duplicate_dealer_uuid: "Händler bereits in KET Daten vorhanden",
      dealer_created: "Händler angelegt",
      dealer_updated: "Händlerdetails sind aktualisiert worden.",
      dealer_not_present: "Dieser Händler ist nicht in A2D angelegt",
      dealer_uuid_other_nmsc: "Der ausgewählte A2D UUID Code ist für das Netzwerk nicht verfügbar. ",
      target_kpi_updated: "The KPI's status have been updated successfully.",
      target_updated: "The KPI's target have been updated successfully.",
      kpi_launched: "The KPI's bulk launch successfully.",
      targetUpdate:"Application doesn't allow target to be modified for accepted status.",
    },
    standardMessages: {
      delete: "Sind Sie sicher mit dem Löschen fortzufahren?",
      addActionPlanNotAllowed: "Der Maßnahmenplan kann nicht erstellt oder editiert werden nachdem einen Aktivität geschlossen, fertiggestellt oder abgelehnt wurde.",
      addToolboxNotAllowed: "Cannot create more than 19 toolbox",
      britRejectMsg: "Möchten Sie diese Aktivität wirklich ablehnen? Änderungen sind nach Ablehnung nicht möglich.",
      rejectMsg: "Möchten Sie diese Aktivität wirklich ablehnen? Änderungen sind nach Ablehnung nicht möglich.",
      selectNMSCTab: "Bitte wählen Sie die Sprache der NMSC für eine Veröffentlichung aus",
      all: "Alle",
      deleteConfirm: "Sind Sie sicher, dass Sie die ausgewählte KPI löschen möchten?",
      no: "Nein",
      retireConfirm: "Sind Sie sicher, dass Sie die ausgewählte KPI zurückziehen möchten? Nach dem Zurückziehen kann diese KPI nicht erneut aktiviert werden",
      warning: "Achtung",
      yes: "Ja",
      saveDataConfirmation: "Möchten Sie die Daten wirklich speichern?",
      saveKPIWarning: "Hinweis: Gespeicherte Daten können nicht mehr bearbeitet werden. Um die Daten anzupassen, setzen Sie sich bitte mit NMSC KET Admin in Verbindung.",
      addKpiNotAllowed: "Die Kennzahlen für den Folgemonat können hinzugefügt werden, wenn der aktuelle Monat endet.",
      activityLaunch: "Sind Sie sicher diese Aktivität zu starten? Nach dem Start ist es nicht mehr möglich den Status zu 'geplant' zu ändern oder die Aktivität zu löschen.",
      deleteConfirmSubQuestionNaire: "Möchten Sie die ausgewählten Unterfragen wirklich löschen?",
      retireConfirmSubQuestionNaire: "Möchten Sie die ausgewählten Unterfragen wirklich zurückziehen?",
      deleteConfirmMainQuestion: "Bitte bestätigen Sie, dass Sie die Hauptfrage und die dazugehörigen Unterfragen löschen möchten.",
      deleteConfirmChapter: "Möchten Sie dieses Kapitel wirklich löschen?",
      deleteLibraryFolder: "Möchten Sie diesen Ordner wirklich löschen?",
      deletePublishedFolder: "Dateien in diesem Ordner könnten in Verbindung zum Fragebogen stehen. Sind Sie sicher, dass Sie diesen Ordner löschen und Verlinkungen zum Fragebogen entfernen möchten?",
      importQuestion: "Möchten Sie diese Unterfrage wirklich importieren? Die aktuelle Unterfrage wird dann ersetzt.",
      noQuestion: "Keine Fragen verfügbar.",
      MandatoryWarning: "Bitte alle Pflichtfelder in der jeweiligen Landessprache ausfüllen.",
      certificationSubmission:
        "Toolbox wurde zur Zertifizierung an TME gesendet",
      sameToolBox: "Sie können die gleiche Toolbox nicht zweimal einreichen. Bitte wählen Sie eine andere Toolbox.",
      deleteConfirmDealer: "Are you sure you want to delete this dealer ?",
      updateVcConfirmDealer: "Alle Aktivitäten, die in \"Value Chain\" angelegt wurden, werden gelöscht. Sind Sie sicher, dass Sie fortfahren möchten?",
      updateBritConfirmDealer: "Alle Aktivitäten, die in \"BRiT\"angelegt wurden, werden gelöscht. Sind Sie sicher, dass Sie fortfahren möchten?",
      completed_activity_delete:"Daten können nicht wieder hergestellt werden, nach dem sie gelöscht wurden. Sind Sie sicher, dass Sie die Daten löschen möchten?",
      deleteAllQuestionary: "Are you sure you want to delete the entire Questionnaire?",
      deleteAllLibraries: "Are you sure you want to delete all folders and files in the Library?",
      selectAll: "Alle auswählen",
      unSelectAll: "Auswahl aufheben",
      select:"Wählen Sie..",
      warningMsg:"Es gibt aktuell noch offene Maßnahmenpläne in Ihren Aktivitäten. Möchten Sie die Aktivität abschließen?"
    },
    status: {
      published: "Veröffentlicht",
      retired: "Ungültig",
      saved: "Gespeichert",
      rejected: "Abgelehnt",
      open: "Offen",
      closed: "Erledigt",
      close: "Erledigt",
      ongoing: "Laufend",
      completed: "Abgeschlossen",
      complete: "Abgeschlossen",
      planned: "Geplant",
      overdue: "Überfällig",
      due: "Fällig",
      pending: "Ausstehend",
    },
    Rejected: "Abgelehnt",
    Open: "Offen",
    Closed: "Erledigt",
    Close: "Erledigt",
    Ongoing: "Laufend",
    Completed: "Abgeschlossen",
    Complete: "Abgeschlossen",
    Planned: "Geplant",
    tabDetails: {
      noActionAvailable: "Keine Maßnahme  verfügbar",
      activeDealer: "Der Händler ist als \"aktiv\" in A2D registriert und kann nicht gelöscht werden.",
      dealerDeleted: "Dealer is deleted."
    },
    Table: {
      action: "",
      activity: "Aktivitäten",
      activityCompletionDate: "Abschlussdatum der Aktivität",
      activityCompletionDateFrom: "Abschlussdatum Aktivität von ",
      activityCompletionDateTo: "Abschlussdatum Aktivität bis ",
      activityStartDate: "Startdatum der Aktivität",
      activityStartDateFrom: "Startdatum Aktivität von ",
      activityStartDateTo: "Startdatum Aktivität bis ",
      assignedTo: "Zugewiesen an",
      assignee: "Verantwortlicher",
      benchmark: "Benchmark",
      city: "Stadt",
      completedBy: "Durchgeführt von ",
      completionBy: "Abschluss bis",
      completionDate: "Abschlussdatum",
      counterMeasure: "Maßnahme",
      country: "Land",
      createdBy: "Erstellt von",
      dealer: "Händler",
      dealerNameAD: "Händlername (A2D)",
      dealerName: "Händler-Name",
      definition: "Definition",
      description: "Beschreibung",
      dueDate: "Fälligkeitsdatum",
      endDate: "Enddatum",
      firstVisit: "Erster Termin",
      followup: "Follow Up",
      franchise: "Marke",
      history: "Historie",
      id: "ID",
      initial: "Erstmessung",
      kpi: "Kennzahl",
      launch: "Start",
      mandatory: "Verpflichtende Kennzahl",
      nmsc: "NMSC",
      problem: "Kaizenpunkt",
      publishedOn: "Veröffentlicht am",
      questionReference: "Fragentext",
      reference: "Bezug",
      reminderOn: "Wiedervorlage am",
      score: "Punktzahl",
      startDate: "Startdatum",
      status: "Status",
      target: "Target",
      toolbox: "Toolbox",
      view: "Ansicht",
      kpiResult: "KPI Ergebnisse",
      nmscTarget: "NMSC Target",
      penEbenchmark: "PanE Benchmark",
      kpiField: "KPI",
      location: "Standort",
      ActionItemReport: "Aktionselemente in Bearbeitung",
      certification: "Zertifizierungsstatus",
      noOfToolbox: "Anzahl abgeschlossener Toolboxen",
      program: "Programm",
      certificationDate: "Zertifizierungsdatum",
      activityId : "Aktivität ID",
      approvedBy:"Approved By",
      kpis:"KPIs",
      lastmonthKpis:"Last Month KPIs",
      questionPercent:"Completion %",
      actionPlan: "Maßnahmenplan",
      monthlyKpi:"Monthly KPIs",
      initialKpi:"Initial KPIs",
      targetKpi:"Target KPIs",
    },
    tabsDetails: {
      actionPlan: "Maßnahmenplan",
      confirmDeleteMessage: "Sind Sie sicher, dass Sie die ausgewählte KPI löschen möchten?",
      confirmRetireMessage: "Bestätigen Sie, dass Sie die ausgewählte  Kennzahl zurücksetzten möchten, da sie bereits veröffentlicht wurde",
      errorRetireMsg: "Diese KPI kann nicht zurückgezogen werden, da es sich um eine aktive TME-pflichtige KPI handelt",
      import: "Importieren",
      importKpiMessage: "Bitte importieren Sie Ihre Kennzahl aus der TME Master KPI-Liste ",
      kpi: "Leistungskennzahlen",
      library: "Bibliothek",
      noActionAvailable: "Keine Maßnahme  verfügbar",
      NoKPI: "Keine Kennzahlen verfügbar",
      publish: "Veröffentlichen",
      questionnaire: "Fragebogen",
      tmeKPIList: "TME Master KPI-Liste",
      toyotaCityBrussels: "TOYOTA CITY BRUSSELS",
      attachment: "Attachment",
      attachments: "Attachment(s)",
      uploadAttachment: "Upload Attachment",
      noAttachment: "No attachments available",
    },
    tmeAdminMaster: {
      ToolboxName: "Toolboxname",
      newToolboxName: "Neuer Name",
      deleteToolbox: "Toolbox löschen?",
      programName: "Programmname",
      ToolboxName_EN: "Toolboxname (EN)",
      deleteTool: "Sind Sie sicher diese Toolbox zu löschen?",
      selectToolbox: "Bitte wählen Sie zum Löschen eine Toolbox aus",
      editToolBox: "Toolbox bearbeiten",
      addToolbox: "Toolbox hinzufügen",
      toolbox: "Bitte tragen Sie einen Namen für die Toolbox ein.",
      addActionPlan: "Maßnahmendefinition",
      actionPlanItem: "Maßnahme",
      add: "Hinzufügen",
      addActivity: "Aktivität hinzufügen",
      additionalInformation: "Zusätzliche Information",
      benchmark: "Benchmark",
      cancel: "Abbrechen",
      createEditActivity: "Aktivität erstellen / bearbeiten",
      createKPI: "Kennzahlen erstellen ",
      delete: "Löschen",
      download: "Download",
      edit: "Bearbeiten",
      editKPI: "Kennzahlen bearbeiten",
      keyPerformanceIndicator: "Kennzahl",
      kpiDefinition: "Kennzahlendefinition",
      mandatoryKpi: "Verpflichtende Kennzahl",
      save: "Speichern",
      search: "Suche",
      unit: "Einheit",
      update: "Aktualisieren",
      validData: "Bitte einen gültige Wert eingeben",
      hide: "Ausblenden",
      show: "Einblenden",
      upload_dealer_network: "Händlernetz hinzufügen",
      upload_question: "Fragebögen hinzufügen",
      upload_user: "Nutzerdetails hinzufügen",
      upload_activity: "Aktivitäten hinzufügen",
      remove: "Remove",
      toyota: "Toyota",
      lexus: "Lexus",
      launch: "Launch",
      close: "Close",
      generate: "Generate",
      dueDateExceed:"Fälligkeitsdatum überschritten",
      delQuestionnaire:"Delete questionnaire"
    },
    toastr: {
      activityStatus_condition: "Bitte beantworten Sie alle Fragen, um die Aktivität zu schließen.",
      emptyTarget: "Please enter the NMSC target to publish the KPI",
      retailerActual: "Please enter the Retailer Actual",
      initialTargetMonthly: "Bitte geben Sie eine Ausgangsmessung, ein Kennzahlenziel sowie mindestens eine weitere Monatsmessung ein um fortzufahren. ",
      initialTarget: "Bitte geben Sie die Start Kennzahl der ersten Messung und die Ziele ein um fortzufahren.",
      upload_error: "Fehler während des Händlernetzuploads.",
      action_item_create_warning: "Pro Maßnahme können maximal fünf Datensätze übernommen werden.",
      actionPlanStartDateValidation: "Enddatum darf nicht vor dem Startdatum liegen",
      actionsNotClosed: "Es befinden sich Maßnahmen in Bearbeitung im Maßnahmenplan; bitte vor dem Schließen einer Aktivität alle Maßnahmen schließen",
      activityDuplicate: "Es existiert bereits eine weitere Aktivität für denselben Händler und Toolbox",
      assigneeNotExist: "Der gewählte Verantwortliche ist im System nicht registriert",
      assigneeValidation: "Ungültiger Verantwortlicher",
      atleastOnePublish: "Bitte mindestens eine zu veröffentlichende KPI wählen",
      cannotRetireActive: "Dies ist eine aktive TME-KPI, die nicht zurückgezogen werden kann ",
      completionDate: "Abschlussdatum darf nicht vor dem Datum des ersten Besuchs liegen",
      confirDeletePlannedActivity: "Soll diese geplante Aktivität wirklich gelöscht werden?",
      DateformatValidation: "Datumsformat TT/MM/JJJJ",
      DateValidation: "Startdatum muss vor Enddatum liegen",
      dealerValidation: "Ungültiger Händler",
      deleteSelectedConfirm: "Sind Sie sicher, dass Sie die ausgewählte KPI löschen möchten?",
      duplicate: "Es existiert bereits eine weitere KPI mit gleicher Bezeichnung und Definition",
      modalMandatory: "Bitte gültige Daten in alle Pflichtfelder eingeben",
      noPublishKpi: "Für die gewählte Toolbox wurden die KPIs noch nicht veröffentlicht",
      validSearchCriteria: "Bitte gültige Suchkriterien eingeben",
      multiSelectPlaceHolder: "Eingabe von min. 5 Zeichen",
      multiSelectPlaceHolderUser: "Eingabe von min. 3 Zeichen",
      startDateValidation: "Startdatum darf nicht größer als Enddatum sein",
      fileSelectionErr: "Bitte zuerst Datei auswählen",
      atleastOneQuestion: "Bitte wählen Sie mindestens eine Unterfrage für den Import aus.",
      atleastOneSubQues: "Bitte wählen Sie mindestens eine Unterfrage zur Veröffentlichung aus",
      uploadErr: "Anzahl fehlgeschlagener Dateiuploads:",
      uploadSuccess: "Händlernetz erfolgreich hinzugefügt",
      file_upload_success: "Dateien erfolgreich hinzugefügt.",
      KPIinOngoingClosedActivity: "Diese Kennzahl ist in einer oder mehreren laufenden/erledigten Aktivitäten vorhanden. Bitte schließen Sie diese Aktivitäten ab, bevor die Kennzahl geändert wird.",
      Role_Error: "Die Nutzeranlage ist unvollständig. Bitte NMSC KET Admin kontaktieren.",
      points_allocation_incorrect: "Falsche Punktezuweisung. Bitte korrigieren.",
      activity_deleted:"Aktivität erfolgreich gelöscht",
      activity_certified_dealer:"Der Händler wurde für die Aktivität zertifiziert. Die Aktivität kann daher nicht gelöscht werden.",
      activity_submited_for_certificate_dealer:"Diese Aktivität wurde zur Zertifizierungsprüfung an TME gesendet. Der Status kann daher nicht geändert werden"
    },
    TopNav: {
      ActivityLog: "Aktivitäten",
      ApplicationName: "Kaizen Expert Tool",
      Home: "Startseite",
      logout: "Log Out",
      MasterDataUpload: "Stammdaten hochladen",
      Toolbox: "Toolboxen",
      welcome: "Willkommen",
      Admin: "Administrator"
    },
    questionnaire: {
      addSubQuestion: "Unterfrage hinzufügen",
      editSubQuestion: "Unterfrage bearbeiten",
      subQuestionTitle: "Unterfrage",
      additionalInfo: "Zusätzliche Information",
      na: "'Unzutreffend' als mögliche Antwort für diese Frage hinzufügen",
      question: "Frage",
      questionTitle: "Titel der Frage",
      why: "Warum?",
      addMainQuestion: "Hauptfrage hinzufügen",
      editMainQuestion: "Hauptfrage bearbeiten",
      previousQuestion: "Vorherige Frage",
      nextQuestion: "Nächste Frage",
      what: "Was?",
      library: "Bibliothek",
      subQuestionTable: {
        no: "Nein",
        subQuestion: "Unterfrage",
        status: "Status",
        publishedDate: "Veröffentlichungsdatum",
        notApplicable: "Unzutreffend"
      },
      links: {
        question_link_button: "Link",
        question_link_include_folder: "Einen Unterordner hinzufügen",
        question_link_add_link: "Dateien aus der Bibliothek verlinken",
        question_link_view_folder: "Bibliothek anzeigen"
      },
      mainQuestion: "Neue/Aktualisierte Hauptfrage",
      subQuestion: "Neue/Aktualisierte Unterfrage",
      retiredQuestion: "Zurückgestellte Unterfrage",
      libraries: "Neue/Aktualisierte Dateien in der Bibliothek:",
      noFiles: "Keine Dateien verfügbar."
    },
    library: {
      selectFile: "Bitte mindestens eine Datei auswählen",
      fileSelected: "Dateien ausgewählt",
      publish_library_file: "Bitte mindestens eine Datei zur Veröffentlichung auswählen",
      library_files_deleted: "Sind Sie sicher, dass Sie diese Datei löschen wollen?",
      library_files_linked_deleted: "Sind Sie sicher diese Datei zu löschen und den Link zum Fragebogen?",
      max_size_error: "Die Größe der Dateien überschreiten das Limit von 1GB pro Upload.",
      empty_file_desc: "Bitte eine Dateibeschreibung hinzufügen.",
      file_not_selected: "Bitte die Datei(en) zum Upload auswählen (max. 1GB)",
      file_type_error: "Dieser Dateityp ist nicht erlaubt",
      file_name_error: "Ungültiges Zeichen im Dateinamen.",
      file_name_lenght_error: "Der Dateiname kann maximal 50 Zeichen haben.",
      addFolder: "Ordner hinzufügen",
      editFolder: "Ordner bearbeiten",
      currentFolder: "Ordername",
      parentFolder: "Hauptordner",
      emptyFolderName: "Der Ordnername darf nicht leer bleiben.",
      invalidFolderName: "Der Dateiordnername darf '/' nicht enthalten",
      folder_created: "Der Ordner wurde erfolgreich erstellt.",
      folder_updated: "Der Ordner wurde erfolgreich aktualisiert.",
      folder_not_found: "Ordner nicht gefunden",
      lib_invalid_toolbox: "Ungültige Toolbox ausgewählt.",
      lib_folder_already_exists: "Dieser Ordner existiert bereits.",
      lib_folder_deleted: "Der Ordner wurde erfolgreich gelöscht.",
      library_files_published: "Die Dateien wurden veröffentlicht.",
      addFile: "Datei hinzufügen",
      editFile: "Datei bearbeiten",
      fileName: "Dateiname",
      upload: "Upload",
      desc: "Beschreibung",
      folderCreationErr: "Hierarchie besteht aus maximal zwei Ebenen",
      subFolderWarning: "Bitte einen Unterordner auswählen um Dateien hinzuzufügen.",
      deleteWarning: "Bitte einen Ordner zum Löschen auswählen.",
      beforeAfterNotSelected: "Bitte wählen Sie aus, ob dieser Nachweis vor oder nach erfolgten KAIZEN Aktivitäten erstellt wurde. ",
    },
    chapter: {
      chapter_exist: "Dieses Kapitel existiert bereits.",
      chapter_saved: "Kapitel gespeichert",
      chapter_cannot_delete: "Dieses Kapitel kann nicht gelöscht werden, da Unterfragen bereits veröffentlicht wurden.",
      failed: "Fehlgeschlagen!",
      chapter_deleted: "Kapitel gelöscht",
      chapter_nonEmpty: "Bitte einen Kapitelnamen erstellen",
      addchapter: "Kapitel hinzufügen",
      editChapter: "Kapitel anpassen",
      DELETE_SUCC:"Questionnaires deleted successfully.",
      DELETE_SUCC_LIB:"Libraries deleted successfully.",
      DELETE_ERR : "Something Went wrong."
    },
    evaluation: {
      attachments: "Anhänge",
      comments: "Kommentare",
      evidence: "Nachweis",
      evidences: "Nachweis",
      yes: "Ja",
      no: "Nein",
      na: "N/A",
      uploadEvidence: "Nachweis hinzufügen",
      fileUpload: "Bitte die Datei(en) zum Upload auswählen (max. 1GB)",
      who: "Wer?",
      answer: "Antwort",
      comment: "Kommentare",
      date: "Datum",
      submit: "Einreichen",
      commentMsg: "Bitte Kommentare eingeben.",
      noCommentAvailable: "Keine Kommentare verfügbar.",
      uploadSuccessMsg: "Dateien erfolgreich hinzugefügt.",
      uploadErrorMsg: "Anzahl fehlgeschlagener Dateiuploads:",
      fileWithSameName: "Es existiert bereits eine Datei mit diesem Namen.",
      noEvidence: "Kein Nachweis vorhanden",
      createAction: "Maßnahme erstellen",
      chapterName: "Kapitelname",
      questionNo: "Fragennummer",
      when: "Wann?",
      noRecords: "Keine Aufzeichnungen verfügbar.",
      kaizenPoints: "Kaizen Punkte",
      renameSuccessMsg: "File has been renamed successfully",
      deleteSuccessMsg: "File has been deleted successfully",
      question: "Frage",
      before:"Before",
      after:"After",
      beforeAfterLabel:"Wurde dieser Nachweis vor- oder nach erfolgten KAIZEN Aktivitäten erstellt?",
      move:"Verschieben",
      moveMsg: "Dateien wurden erfolgreich verschoben. ",
      noToYesMsg:"Previous answer was NO",
    },
    WALK_AROUND_CHECK: "Direktannahme",
    ketProgram: {
      TSM: "TSM/LSM",
      BRiT: "BRiT RI",
      TSM_BNP: "Karosserie & Lack",
      TSM_USED_CAR: "Gebrauchtwagen",
      value_chain: "Value Chain",
      brit: "BRiT",
      ea: "EA",
      per:"Performance",
      nettrans:"Network Transformation",
    },
    dashboard: {
      notice_board: "Notiztafel",
      kpi_target_not_set: "KPI Ziele nicht erfasst",
      kpi_initial_not_set: "KPI Erstmessung ist nicht erfolgt",
      kpi_monthly_not_set: "Monatliche KPI Erfassung ist nicht erfolgt",
      kpi_below_target: "KPI ist unter Erstmessung",
      activation: "Aktivierung",
      users: "Benutzer",
      nmscs: "NMSC's",
      activeRetailer: "Aktive Händler",
      certificationRate: "Zertifizierungsrate",
      reCertificationRate: "Rezertifizierungsrate",
      activityDue: "Fällige Aktivitäten",
      avgdays: "Durchschnittliche Tage/Toolbox",
      overDueAct: "Überfällige Aktivitäten",
      actionItem: "Aktionselemente",
      bussResult: "Geschäftsergebnisse",
      toolboxImpl: "Toolbox-Implementierung",
      statusCompletedL:"Status - Completed, Progress - ",
      statusClosedL:"Status - Closed, Progress - ",
      statusOngoingL:"Status - Ongoing, Progress - ",
      recentUpdates: "Kürzliche Updates",
      rolloutTopNmsc: "Rollout-Ergebnisse - Top-NM",
      bestPractice: "Best Practices Tafel",
      availableKpi: "Verfügbare Kennzahlen",
      selectedKpi: "Ausgewählte Kennzahlen",
      dealerBestPractice: "Händler Best Practice Wand",
      kpiList: "Kennzahlenliste",
      go: "Gehen",
      nodata: "Keine Daten verfügbar",
      businessResultTop: "Geschäftsergebnisse - Top-Händler",
      businessResultNMSC: "Geschäftsergebnisse - Top NMSC's"


    },
    dealerCertification: {
      dealerCertification: "Händlerzertifizierung",
      readyForCertification: "Bereit zur Zertifizierung",
      certification: "Zertifizierung",
      submittedOn: "Eingereicht am",
      certificateDate: "Datum des Zertifikats",
      reCertificateDate: "Datum der erneuten Zertifizierung",
      toolboxDetails: "Toolbox-Details",
      approve: "Genehmigen",
      reject: "Abgelehnt",
      pending: "Ausstehend",
      region: "Region"



    },
    uploadBulk: {
      browse: "Browse",
      executionId: "Ausführungs-ID",
      uploadedOn: "Hochgeladen am",
      lastUpdatedOn: "Letzte Aktualisierung am",
      noDataTable: "No data to view",
      kpiUpload: "KPI's Upload",
      action: "Aktion",
      processed: "Verarbeitet",
      error: "Fehler",
      received: "Received",
      createActivity: "Aktivitäten anlegen",
      fileUpload: "File Upload",
      targetSetting: "Target Setting",
      kpiEntry:"KPI Entry",
      accepted:"Accepted",
      launchNmsc:"Launch for NMSC",
      questionnaireUpload: "Questionnaire Upload",
    },

    userManagement: {
      userManagement: "Nutzer Management",
      createUser: "Create User",
      editUser: "Edit User",
      viewUser: "View User",
      nmscId: "NMSC ID",
      tarsId: "TARS ID",
      firstName: "Vorname",
      lastName: "Nachname",
      emailId: "E-Mail",
      britDealerChamp: "Brit Dealer Champion",
      invalidEmail: "Please enter valid E-Mail ID",
      invalidTars: "Please enter valid TARS ID",
      invalidFirstName: "Please enter valid First Name",
      invalidLastName: "Please enter valid Last Name",
      user_created: "User Created",
      user_updated: "User updated",
      duplicate_tarsuser: "This TARS id is already registered in the KET database.",
      user_define_in_tool: "User is defined as Business owner in Active Business Function. Hence, could not be made Inactive",
      user_refer_on: "User is referenced in Ongoing activity",
      pbiAccount : "PBI Account",
      invalidPbiAccount : "Der PBI Account muss mindestens 3 Zeichen enthalten",
      businessArea: "Geschäftsfeld",
      valueChainAndBRiT: "Value Chain & BRiT",
      only:"Only",
      optionText:"Please select at least one of the two options below",
      valueChainOnly: "Nur Value Chain",
      britOnly: "Nur BRiT"

    },
    UserManagement: {
      nmscId: "NMSC ID",
      tarsId: "TARS ID",
      firstName: "Vorname",
      lastName: "Nachname",
      emailId: "E-Mail",
      languageCode: "Sprachcode",
      ACTIVE: "Active",
      INACTIVE: "Inaktiv",
      addUser: "Nutzer hinzufügen",
      Assign: "Zuweisen",
      List: "Liste",
      user: "Nutzer",
      error_message: "Es ist ein Fehler aufgetreten. Bitte später versuchen.",
      role: "Nutzerrolle",
      standardMessage: "No User Found",
      dealerCode: "Händlernummer",
      a2d: "A2D UUID",
      britReady: "Händler hat an Brit Ready teilgenommen",
      britLive: "Händler hat an Brit Live teilgenommen",
      britJapan: "Händler hat an Brit Discovery Japan teilgenommen",
      britEss:"Händler hat den EES Maßnahmenplan aktualisiert",
      addDealer: "Händler hinzufügen (aus A2D)",
      noDealer: "Kein Händler gefunden",
      dealerUuid: "Händler UUID Code (A2D)",
      language: "Sprache",
      L3: "L3",
      L4: "L4",
      L5: "L5",
      L6: "L6",
    },
    CeaKpi: {
      survey: "Survey",
      evaluationItem: "Evaluation Item",
      kpiMetric: "KPI Evaluation Method",
      stratagicPillar: "Strategic Pillar",
      addKPI: "Add KPI",
      edit: "Edit",
      delete: "Delete",
      subPillar: "Sub-Pillar",
      kaizenImp_impact_def: "Kaizen Importance / Impact / Definition",
      evalMethod: "Evaluation Method",
      scoreMethod: "Scoring Method",
      typeOftarget: "Type of Target",
      target: "Target",
      mode: "Mode",
      points: "Points",
      inputFreq: "Input Frequency",
      cea_kpi_created: "CEA KPI has been created successfully",
      noPointsAllocated:"No Points defined for selected Pillar/Business function mapping",
      targetIncorrect:"Target value is Invalid",
      viewKpi:"View KPI",
      general:"General",
      targetScoring:"Targets & Scoring",
      inputs:"Input Parameters",
      maxFiveParam:"Maximum 5 parameters for a KPI can be added",
      validParam:"Please Enter Valid Parameter",
      validDesc:"Please Enter Valid Parameter Description",
      dataEntry:"Data Entry in KET",
      paramater:"Parameter",
      paramDesc:"Parameter Description",
      noParamAvai:"No Parameters Available",
      kpiDeleted:"KPI deleted Successfully",
      kpiDeleteError:"KPI Not found",
      kpiActualsError:"Actuals present. KPI cannot be deleted",
      cea_kpi_updated: "CEA KPI has been updated successfully",
      addParam:"Add Parameters"

    },
    dealerManagement: {
      invalidUuid: "Bitte gültige A2D UUID eingeben",
      invalidDealerName: "Bitte gültigen Händlernamen eingeben",
      invalidCity: "Bitte gültigen Ort angeben",
      invaliDealerCode: "Bitte gültige Händlernummer angeben",
      existingUuid: "Der A2D UUID Code ist bereits in der KET Datenbasis vorhanden.",
      updateUuid: "A2D UUID Code aktualisieren",
      newUuid: "Neuer A2D UUID Code",
    },
    networkManagement: {
      networkManagement: "Netzwerk Management",
    },
    kpiEntry: {
      cancel : "Cancel",
      save : "Save",
      no : "No",
      yes : "Yes",
      hide : "Hide",
      show : "Show",
      dataNotSaved : "Data is not saved. Entered data may be lost. Do you want to Proceed?",
      wantToProceed : "Do you want to proceed?",
      status: 'Status',
      evaluationYear: 'Evaluation Year',
      frequency: 'Frequency',
      typeOfTarget: 'Type of Target',
      target: 'Target',
      unit: 'Unit',
      standardMessage: 'No Kpi Actuals Data Found',
      srNo: 'S.No.',
      actuals: 'Actuals',
      subPillar: 'Sub Pillar',
      businessFunc: 'Business Function',
      brand: 'Brand',
      Jan : "Jan",
      Feb : "Feb",
      Mar : "Mar",
      Apr : "Apr",
      May : "May",
      Jun : "Jun",
      Jul : "Jul",
      Aug : "Aug",
      Sep : "Sep",
      Oct : "Oct",
      Nov : "Nov",
      Dec : "Dec",
      q1 : "Q1",
      q2 : "Q2",
      q3 : "Q3",
      q4 : "Q4",
      h1 : "H1",
      h2 : "H2",
      cy : "CY",
      kpi : "Key Performance Indicators",
      actuals1 : "Actuals should not be more than allotted points. Please re-enter",
      actionPlan	: "Action Plan",
      enterActualsData : "Please enter actuals data.",
      notNumber : "Please enter valid number.",
      serverErrorMsg : "An error occured, please try again later.",
      launchEvaluationError : "Please launch evaluations.",
      actualsDataSaved : "Actuals data uploaded successfully.",
      inputTxt:"Inputs"
    },
    ceaEvaluation :{
      nmsc : "NMSC",
      search : "Search",
      all : "All",
      status : "Status",
      businessFunction :"Business Function",
      noEvaluationsFound : "No Evalutions Found",
      evaluationYear : "Evalution Year",
      view : "View",
      evaluations : "Evaluations",
      launchTargetSetting: "Launch Target Setting",
      launchEvaluation : "Launch Evaluation",
    },
    britDashboard:{
      noVideo:"No videos available",
      playVideo:"Play Video",
      trainingVideos:"Training  Videos",
      download: "Download Video",
      back:"Back"
    },
    ceaActionPlan:{
      id: "ID",
      counterMeasure: "Countermeasure",
      questionReference: "KPI Reference",
      status: "Status",
      startDate: "Start Date",
      dueDate: "Due Date",
      assignedTo: "Assigned To",
      noActionAvailable: "No Actions available",
      add: "Add",
      download: "Download",
      edit: "Edit",
      delete: "Delete",
      deleteMsg: "Are you sure you want to delete ?",
      no: "No",
      yes: "Yes",
      addActionPlanNotAllowed: "The Action Plan cannot be created, or edited after the Activity has been Closed, Completed, or Rejected.",
      actionPlanItem: "Action Plan - Item #",
      addActionPlan: "Add Action Plan",
      open: "Open",
      actionPlanStartDateValidation:"End Date cannot be earlier than the Start Date",
      modalMandatory: "Please enter valid data in all the mandatory fields",
      problem: "Problem",
      description: "Description",
      followup: "Follow Up",
      history: "History",
      cancel: "Cancel",
      save: "Save",
      update: "Update",
      assignee: "Assignee",
      endDate: "End Date", 
      select: "Select",
      notApplicablePer:"Not Applicable for CEA, Networks and Used car"
    },
    reachItDashboard:{
      topNavReachIT:"ReachIT",
      downloads:"Downloads",
      historyFiles:"verlauf früherer Dateien",
      reachText1:"Willkommen auf der REACH IT Homepage. Das TME Umweltmanagement Team wird diese Seite nutzen, um relevante Information zu besorgniserregenden Stoffen in Erzeugnissen als solchen oder komplexen Gegenständen (Substances of Concern In Products) zu teilen.  Ziel ist es, Ihnen zu ermöglichen, die vereinfacte SCIP Anmeldung durchzuführen damit sie der aus der  Abfallrahmenrichtlinie entstehenden Verpflichtung nachkommen können. ",
      reachText2:"Bitte kontaktieren Sie das TME Umweltmanagement Team über den untenstehenden Link bei Fragen. (but if internal, then probably better to leave the ENG name of the division)//or: Fragen? Bitte kontaktieren Sie ihr…",
      reachText3:"Bitte kontaktieren Sie ihr nationales Marketing- und Vertriebsunternehmen (TDG?/or leave NMSC if audience Toyota) bei Fragen.// or: Fragen? Bitte kontaktieren Sie… (sounds a bit less 'robotic')",
      envMngt:"Umweltmanagement",
      fileStatistics: "Umweltmanagement",
      Downloaded: "heruntergeladen",
      not: "nicht",
      exportToExcel:"Als Excel-Doc exportieren"
    },
    usedCarComment:{
      ongoing:"Ongoing",
      completed:"Completed",
      commentHistory:"Monthly Comments History",
      addComment:"Add Monthly Comment",
      month:"Month",
      comment:"Comment",
      username:"Username",
      email:"Email",
      createdDate:"Created Date"
    },
  }
};

export default translationsDE;
