import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import {
  getUserDetails,
  updateGdprFlag
} from "../../actions/loginAction";
import KetLocale from "../../ketLocale";
import GDPR from "../gdpr";
import * as AppConst from "../../AppConstant";
import Dashboard from '../dashboard';
import ReachItDashboard from '../reachItDashboard';
import { login } from "../../oidc/AuthService";

class Home extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showSearchFlag: false,
      modal: true,
      isOldUser: false,
      gdprFlag: this.props.user.userMdl.gdprFlag
    };
  }

  componentWillMount() {
    if (
      this.props.user.userMdl === undefined ||
      this.props.user.userMdl === null ||
      this.props.user.userMdl.expired === undefined ||
      this.props.user.userMdl.expired
    ) {
      //login();
      // get user Details from token.
    this.props.dispatch(getUserDetails());
    }
  }
  componentDidMount() {
  }
  preventDefault() { }

  popUpClose = event => {
    const userMdl = this.props.user.userMdl;
    event.preventDefault();
    this.setState({ modal: !this.state.modal });
    userMdl["gdprFlag"] = 1;
    this.setState({ userMdl });
    this.props.dispatch(updateGdprFlag(userMdl));
  };

  componentDidUpdate(prevProps,prevState){
    if(this.props.location.query){
      if(prevState.flagCEA !== this.props.location.query.flagCEA){
      this.setState({flagCEA:this.props.location.query.flagCEA})
      }
      if(prevState.flagNET !== this.props.location.query.flagNET){
        this.setState({flagNET:this.props.location.query.flagNET})
        }
        if(prevState.flagUC !== this.props.location.query.flagUC){
          this.setState({flagUC:this.props.location.query.flagUC})
          }
    }
  }

  render() {
    const userMdl = this.props.user.userMdl;
    let loadContent = false;
    let isOldUser = false;
    if (
      userMdl !== undefined &&
      userMdl !== null &&
      userMdl.lang !== undefined &&
      userMdl.lang !== null
    ) {
      loadContent = true;
    }
    let filterData = {};
    if (this.props.location.state && this.props.location.state.filterData) {
      filterData = this.props.location.state.filterData;
    }
    return (
      <div>
        {loadContent === true ? (
          <KetLocale flagCEA={this.state.flagCEA} />
        ) : null}
        {(loadContent === true && userMdl["gdprFlag"]) === 0 ? (
          <GDPR
            modal={this.state.modal}
            popUpClose={this.popUpClose}
            isOldUser={isOldUser}
          />
        ) : userMdl.activeProgram === "EA" &&
          (this.props.user.userMdl.roles.includes(
            AppConst.REACHIT_ROLES.KET_REACHIT_Admin
          ) ||
            this.props.user.userMdl.roles.includes(
              AppConst.REACHIT_ROLES.KET_REACHIT_NMSC_Admin
            ) ||
            this.props.user.userMdl.roles.includes(
              AppConst.REACHIT_ROLES.KET_REACHIT_Dealer_Champion
            )) && (!this.props.user.userMdl.roles.includes(AppConst.EA_ROLES.KET_EA_Admin) && !this.props.user.userMdl.roles.includes(AppConst.EA_ROLES.KET_EA_NMSC_Admin)
            && !this.props.user.userMdl.roles.includes(AppConst.EA_ROLES.KET_EA_Dealer_Champion)) ? (
          <ReachItDashboard />
        ) : (
          <Dashboard filterData={filterData} />
        )}
      </div>
    );
  }
}
const mapStateToProps = state => {
  return {
    user: state.loginReducer,
    blocking: state.loginReducer,
    router: state.routing
  };
};
export default withRouter(connect(mapStateToProps)(Home));
