const translationsSV = {
  sv: {
    Policy: {
      HeaderMsg: "Sekretessmeddelande för behandling av dina personuppgifter inom ramen för Kaizen Expert Tool",
      Message1: "Denna sekretesspolicy gäller behandlingen av dina personuppgifter inom ramen för Kaizen Expert Tool. Det måste alltid läsas tillsammans med Toyotas allmänna policy om skyddande av personuppifter vilka du måste acceptera för att kunna fortsätta vidare.",
      Message2: "Toyota Motor Europe NV / SA, med huvudkontoret på Avenue du Bourget / Bourgetlaan 60, 1140 Bryssel, Belgien, som datakontrollant, samlar in dina personuppgifter (enligt nedan) för de ändamål som beskrivs nedan.",
      Message3: "Vilka av dina personuppgifter kommer att samlas in?",
      Message4: "Vi samlar in följande kategorier av dina personuppgifter med hänsyn till Kaizen Expert Tool:",
      Table1: {
        tr1: "Kategorier av personuppgifter:",
        tr2: "Användar ID",
        tr3: "Användarnamn (Fullständigt namn)",
        tr4: "Varumärke (Toyota, Lexus)",
        tr5: "Önskat språk",
        tr6: "Ansluten organisation"
      },

      Message5: "Syfte och rättsliga grunder för behandlingen av dina personuppgifter.",
      Message6: "Vi behandlar dina personuppgifter för de syften och de rättsliga grunderna som beskrivs nedan:",
      Table2: {
        tr1: {
          th1: "Ändamål:",
          th2: "Rättslig grund:"
        },
        tr2: {
          td1: "KET - 1: Återförsäljarens  Kaizenresultat",
          td2: "Lagligt intresse av datakontroller"
        },
        tr3: {
          td1: "KET - 2: IT-support",
          td2: "Lagligt intresse av datakontroller"
        }
      },
      Message7: "Varaktighet för lagring av dina personuppgifter",
      Message8: "Dina personuppgifter kommer att lagras under den tid som anges nedan:",
      Table3: {
        tr1: {
          th1: "Lagringstid:",
          th2: "Kategorier av personuppgifter:"
        },
        tr2: {
          td1: "Eventuell personlig information som beskrivs ovan kommer att lagras i högst 5 år + 1 månad från den sista borttagningen i Kaizen Expert Tool.",
          td2: "Användarens förnamn, efternamn, språkval, organisation"
        }
      },
      Message9: "Valfritt: Vid offentliggörande / överföring av dina personuppgifter till länder utanför Europeiska Ekonomiska Samarbets Området (EES)",
      Message10: "Dina personuppgifter kommer att göras tillgängliga för mottagare / mottagare i länder utanför EES som anges nedan:",
      Table4: {
        tr1: {
          td1: "Identitet",
          td2: "Land",
          td3: ""
        },
        tr2: {
          td1: "Icke-EES-mottagare",
          td2: "Infosys",
          td3: "Indien"
        }
      },
      Message11: "Jag bekräftar härmed att ha förstått den här informationen. Jag har läst och accepterat Toyotas allmänna  policy om skyddande av personuppgifter."
    },
    ACCESSORIES: "Tillbehör",
    activity: {
      Assignee: "Tilldelad",
      CompletionDate: "Avslutad datum",
      Dealer: "Återförsäljare",
      FirstVisit: "Första besök",
      kpi: {
        kpi: "Nyckeltal ska vara siffror",
        kpiLength: "Max längd för nyckeltalet är 10 tecken",
        mandatory: "Ange giltiga data i alla obligatoriska fält",
        mandatoryTargets: "Please enter all KPIs.",
        target: "Målet ska vara numeriskt",
        targetLength: "Max längd för målet är 10 tecken"
      },
      ReminderOn: "Påminnelse om",
      statusDD: "Status",
      Toolbox: "Verktygslåda",
      activityCreation: "Activity Creation process has been started",
      activityCreated: "activity(s) have been created"
    },
    DPOK: "DPOK",
    EFFICIENCY: "Effektivitet",
    FIX_IT_RIGHT: "Fix it Right",
    footer: {
      message: "Obligatoriska fält"
    },
    FOUNDATION: "Foundation",
    MAINTENANCE_REMINDERS: "PSB",
    EMPLOYEE_ENGAGEMENT: "Employee Engagement",
    CUSTOMER_CENTRICITY: "Customer Centricity",
    TOYOTA_WAY: "Toyota Way",
    BUSINESS_SUSTAINABILITY: "Business Sustainability",
    FUNDAMENTALS: "Fundamentals",
    FIX_IT_RIGHT_BnP: "Fix it Right (B&P)",
    UTILIZATION_AND_EFFICIENCY: "Utilization and Efficiency",
    CUSTOMER_EXPERIENCE: "Customer Experience",
    UPSELLING_OPPORTUNITIES: "Upselling Opportunities",
    MANAGING_CAPACITY: "Managing Capacity",
    VEHICLE_TRADE_IN: "Vehicle Trade-In",
    STOCK_MANAGEMENT: "Stock Management",
    REFURBISHMENT: "Refurbishment",
    months: {
      apr: "Apr",
      aug: "Aug",
      dec: "Dec",
      feb: "Feb",
      jan: "Jan",
      jul: "Jul",
      jun: "Jun",
      mar: "Mar",
      may: "Maj",
      nov: "Nov",
      oct: "Okt",
      sep: "Sep"
    },
    others: {
      back: "Tillbaka",
      closed: "Stängd",
      completed: "Slutförd ",
      countryLanguageCode: "Land/språkkod ",
      createActivity: "Skapa aktivitet",
      createdDate: "Skapad datum",
      dataUneditableNote:
        "Obs! Uppgifter som sparats kan inte redigeras. Kontakta TSW admin om du vill ändra uppgifterna.",
      date: "Datum",
      downloadQuestionnaire: "Ladda ned frågeformulär",
      editActivity: "Redigera aktivitet",
      initialHelpText: "Värden för de initiala nyckeltalen ska vara genomsnittet för de föregående sex månaderna",
      inProgress: "Pågående",
      name: "Namn",
      next: "Nästa",
      noActivities: "Inga aktiviteter hittades",
      noResults: "Inga resultat",
      of: "av",
      ongoing: "Pågående",
      open: "Öppet",
      page: "Sida",
      planned: "Planerad",
      problemDescription: "Problembeskrivning",
      signature: "Underskrift",
      select: "Välj",
      bulkAction: "Bulk Action",
      bulkActions: "Bulk Actions"
    },
    ServerMessage: {
      error_message: "Ett fel har inträffat vid nedladdning",
      no_published_subquestion: "Publicera frågeformuläret för den här verktyglådan om du vill starta aktiviteten.",
      activity_upload_error_msg: "Ett fel uppstod vid uppladdningen av detaljerna för aktiviteten",
      action_plan_created: "Handlingsplan skapad",
      action_plan_deleted: "Handlingsplan borttaget",
      action_plan_saved: "Handlingplan sparad",
      activity_kpi_saved: "Aktivitetens nyckeltal sparad",
      master_kpi_confirm_delete: "Är du säker på att du vill radera valt nyckeltal?",
      master_kpi_created: "Master nyckeltal har skapats",
      master_kpi_deleted: "Master nyckeltal har tagits bort",
      master_kpi_not_found: "Master nyckeltal hittades ej",
      master_kpi_published: "Valda Master nyckeltal har publicerats",
      master_kpi_retired: "Master nyckeltal har dragits tillbaka",
      master_kpi_saved: "Master nyckeltal har sparats",
      subquestion_created: "Delfrågan har sparats.",
      subquestion_updated: "Delfrågan har blivit uppdaterad.",
      subquestionnaire_not_found: "Delfrågan har inte hittats.",
      subquestion_retired: "Delfrågan har utgått.",
      subquestion_deleted: "Delfrågan har tagits bort.",
      subquestion_published: "Delfrågorna har publicerats.",
      mainquestion_created: "Huvud frågeformuläret har skapats.",
      mainquestion_deleted: "Huvud frågeformuläret har tagits bort.",
      mainquestion_updated: "Huvudfrågan har uppdaterats.",
      duplicateMsg: "Kopiera poster i Excelfilen",
      deleteErr: "Ett fel uppstod när filen togs bort",
      toolbox_deleted: "Toolbox has been deleted successfully",
      toolbox_already_exists: "This toolbox already exists.",
      toolbox_saved: "Toolbox saved",
      toolbox_created: "Toolbox Created",
      toolbox_not_found: "Toolbox not found",
      published_content_found: "This toolbox cannot be deleted as it has some published content",
      dealers_already_mapped: " Dealers already mapped ",
      dealer_already_mapped: " Dealer already mapped ",
      dealer_added: "  Dealer added. ",
      dealers_added: "  Dealer added. ",
      total_dealers_mapped_now: "Total dealers mapped now are ",
      no_dealer_available: "No Dealer available for this assign criteria",
      proper_dealer_selected: "Proper dealer shall be selected",
      user_referenced_ongoing_activity: "User is referenced in Ongoing activity/activities. ",
      want_to_remove_mapping: "Are you sure you want to remove mapping?",
      user_mapping_removed: "Selected dealers unmapped successfully",
      dealer_closed_in_A2D: "Dealer not active in A2D",
      network_level_not_found: "Please select valid Network levels",
      duplicate_dealer_uuid: "Dealer already available",
      dealer_created: "Dealer Created",
      dealer_updated: "Dealer Updated",
      dealer_not_present: "Dealer not present in A2D",
      dealer_uuid_other_nmsc: "Please Enter Dealer Uuid for Same NMSC",
      target_kpi_updated: "The KPI's status have been updated successfully.",
      target_updated: "The KPI's target have been updated successfully.",
      kpi_launched: "The KPI's bulk launch successfully.",
      targetUpdate:"Application doesn't allow target to be modified for accepted status.",
    },
    standardMessages: {
      delete: "Är du säker på att du vill ta bort?",
      addActionPlanNotAllowed: "Handlingsplaner kan inte skapas eller redigeras efter att aktiviteten har stängts, slutförts eller avvisats.",
      addToolboxNotAllowed: "Cannot create more than 19 toolbox",
      britRejectMsg: "Är du säker på att du vill avvisa den här aktiviteten? Ändringar är inte möjliga efter avslag.",
      rejectMsg: "Är du säker på att du vill avvisa den här aktiviteten? Ändringar är inte möjliga efter avslag.",
      selectNMSCTab: "Please select nmsc language to publish",
      all: "Alla",
      deleteConfirm: "Är du säker på att du vill radera valt nyckeltal?",
      no: "Nej",
      retireConfirm: "Är du säker på att du vill ta bort valt nyckeltal? När detta nyckeltalet har tagits bort, kan det inte aktiveras igen",
      warning: "Varning",
      yes: "Ja",
      saveDataConfirmation: "Är du säker på att du vill spara uppgifterna?",
      saveKPIWarning: "Obs! Data kan inte redigeras när de väl har sparats. Kontakta TSW KET admin om du vill ändra uppgifterna",
      addKpiNotAllowed: "Nästa månad för inmatning av nyckeltal kan läggas till när innevarande månad är slut.",
      activityLaunch: "Är du säker på att du vill starta den här aktiviteten? Efter starten går det inte att återvända till status 'planerad' eller radera aktiviteten.",
      deleteConfirmSubQuestionNaire: "Är du säker på att du vill ta bort den valda delfrågan?",
      retireConfirmSubQuestionNaire: "Är du säker på att du vill ta bort den valda delfrågan?",
      deleteConfirmMainQuestion: "Vänligen bekräfta om du vill ta bort huvudfrågan och dess relaterade delfrågor.",
      deleteConfirmChapter: "Är du säker på att du vill ta bort kapitlet?",
      deleteLibraryFolder: "Är du säker på att du vill arkivera?",
      deletePublishedFolder: "Vissa filer i den mappen kan vara länkade till frågeformuläret. Är du säker på att du vill radera den här undermappen och ta bort länken till frågeformuläret?",
      importQuestion: "Är du säker på att du vill importera denna delfråga? Nuvarande delfråga kommer att ersättas",
      noQuestion: "Inga frågor tillgängliga.",
      MandatoryWarning: "Fyll i alla obligatoriska fält för alla lokala språk",
      certificationSubmission:
        "Toolbox is submitted to TME for dealer certification",
      sameToolBox: "You cannot submit same Toolbox more than once. Please select different toolbox",
      deleteConfirmDealer: "Are you sure you want to delete this dealer ?",
      updateVcConfirmDealer: "All Activities created for this dealer in 'Value Chain' will be removed. Are you sure you want to continue?",
      updateBritConfirmDealer: "All Activities created for this dealer in 'BRiT' will be removed. Are you sure you want to continue?",
      completed_activity_delete:"Data cannot be retrieved once deleted. Are you sure you want to delete ?",
      deleteAllQuestionary: "Are you sure you want to delete the entire Questionnaire?",
      deleteAllLibraries: "Are you sure you want to delete all folders and files in the Library?",
      selectAll: "Välj alla",
      unSelectAll: "Avmarkera alla",
      select:"Välj…",
      warningMsg:"Det finns öppna handlingsplaner i denna aktivitet. Vill du slutföra aktiviteten?"
    },
    status: {
      published: "Publicerad",
      retired: "Utgången",
      saved: "Sparad",
      rejected: "Avvisade",
      open: "Öppet",
      closed: "Stängd",
      close: "Stängd",
      ongoing: "Pågående",
      completed: "Slutförd",
      complete: "Slutförd",
      planned: "Planerad",
      overdue: "Försenad",
      due: "Förfaller",
      pending: "Pending",
    },
    Rejected: "Avvisade",
    Open: "Öppet",
    Closed: "Stängd",
    Close: "Stängd",
    Ongoing: "Pågående",
    Completed: "Slutförd",
    Complete: "Slutförd",
    Planned: "Planerad",
    tabDetails: {
      noActionAvailable: "Ingen åtgärd tillgänglig",
      activeDealer: "Dealer is active.",
      dealerDeleted: "Dealer is deleted."
    },
    Table: {
      action: "",
      activity: "Aktiviteter",
      activityCompletionDate: "Slutdatum",
      activityCompletionDateFrom: "Aktivitet färdig datum från",
      activityCompletionDateTo: "Aktivitet färdig datum till",
      activityStartDate: "Startdatum",
      activityStartDateFrom: "Aktivitet start datum från",
      activityStartDateTo: "Aktivitet start datum till",
      assignedTo: "Tilldelad till",
      assignee: "Tilldelad",
      benchmark: "Idealläge",
      city: "Stad",
      completedBy: "Slutförd av ",
      completionBy: "Avslutat av",
      completionDate: "Avslutad datum",
      counterMeasure: "Motåtgärd",
      country: "Land",
      createdBy: "Skapad av",
      dealer: "Återförsäljare",
      dealerNameAD: "Dealer name (A2D)",
      dealerName: "Återförsäljare",
      definition: "Definition",
      description: "Beskrivning",
      dueDate: "Förfallodatum",
      endDate: "Slutdatum",
      firstVisit: "Första besök",
      followup: "Uppföljning",
      franchise: "Franchise",
      history: "Historik",
      id: "ID",
      initial: "Inledande",
      kpi: "Nyckeltal",
      launch: "Starta",
      mandatory: "Obligatorisk",
      nmsc: "NMSC",
      problem: "Problem",
      publishedOn: "Publicerades ",
      questionReference: "Frågans referens",
      reference: "Referens",
      reminderOn: "Påminnelse om",
      score: "Resultat",
      startDate: "Startdatum",
      status: "Status",
      target: "Target",
      toolbox: "Verktygslåda",
      view: "Granska",
      kpiResult: "Resultat nyckeltal",
      nmscTarget: "NMSC Target",
      penEbenchmark: "PanE Benchmark",
      kpiField: "KPI",
      location: "Plats",
      ActionItemReport: "Pågående åtgärder",
      certification: "Certifieringsstatus",
      noOfToolbox: "Antal färdiga verktygslådor",
      program: "Program",
      certificationDate: "Certifierings datum",
      activityId : 'Activity ID',
      approvedBy:"Approved By",
      kpis:"KPIs",
      lastmonthKpis:"Last Month KPIs",
      questionPercent:"Completion %",
      actionPlan: "Handlingsplan",
      monthlyKpi:"Monthly KPIs",
      initialKpi:"Initial KPIs",
      targetKpi:"Target KPIs",
     },
     tabsDetails: {
      actionPlan: "Handlingsplan",
      confirmDeleteMessage: "Är du säker på att du vill radera valt nyckeltal?",
      confirmRetireMessage: "Bekräfta att du vill dra tillbaka det valda nyckeltalet eftersom det har publicerats tidigare",
      errorRetireMsg: "Detta nyckeltalet kan inte tas bort eftersom det är ett obligatoriskt nyckeltal från TME",
      import: "Importera",
      importKpiMessage: "Importera nyckeltal från TME Master nyckeltalslista",
      kpi: "Nyckeltal",
      library: "Bibliotek",
      noActionAvailable: "Ingen åtgärd tillgänglig",
      NoKPI: "Inga nyckeltal tillgängliga",
      publish: "Publicera",
      questionnaire: "Frågeformulär",
      tmeKPIList: "TME Master nyckeltalslista",
      toyotaCityBrussels: "TOYOTA CITY BRUSSELS",
      attachment: "Attachment",
      attachments: "Attachment(s)",
      uploadAttachment: "Upload Attachment",
      noAttachment: "No attachments available",
    },
    tmeAdminMaster: {
      ToolboxName: "Verktyglådenamn",
      newToolboxName: "Nytt namn",
      deleteToolbox: "Ta bort verktyglåda?",
      programName: "Program namn",
      ToolboxName_EN: "Verktyglådenamn (EN)",
      deleteTool: "Är du säker på att du vill ta bort verktygslådan?",
      selectToolbox: "Välj en verktyglåda du vill ta bort",
      editToolBox: "Redigera verktyglåda",
      addToolbox: "Lägg till verktyglåda",
      toolbox: "Ange ett namn för verktyglådan",
      addActionPlan: "Lägg till Handlingsplan",
      actionPlanItem: "Handlingsplan - del nr",
      add: "Lägg till",
      addActivity: "Lägg till aktivitet",
      additionalInformation: "Ytterligare information",
      benchmark: "Idealläge",
      cancel: "Avbryt",
      createEditActivity: "Skapa/redigera aktivitet",
      createKPI: "Skapa nyckeltal",
      delete: "Radera",
      download: "Ladda ned",
      edit: "Redigera",
      editKPI: "Redigera nyckeltal",
      keyPerformanceIndicator: "Nyckeltal",
      kpiDefinition: "Definition av nyckeltal",
      mandatoryKpi: "Obligatoriska nyckeltal",
      save: "Spara",
      search: "Sök",
      unit: "Enhet",
      update: "Uppdatera",
      validData: "Ange giltiga data",
      hide: "Dölj",
      show: "Visa",
      upload_dealer_network: "Ladda upp återförsäljarnätverket",
      upload_question: "Ladda upp frågeformulären",
      upload_user: "Ladda upp användaruppgifterna",
      upload_activity: "Ladda upp aktiviteterna",
      remove: "Remove",
      toyota: "Toyota",
      lexus: "Lexus",
      launch: "Launch",
      close: "Close",
      generate: "Generate",
      dueDateExceed:"Förfallodatum har passerats",
      delQuestionnaire:"Delete questionnaire"
    },
    toastr: {
      activityStatus_condition: "Svara på alla frågor för att kunna stänga aktiviteten",
      emptyTarget: "Please enter the NMSC target to publish the KPI",
      retailerActual: "Please enter the Retailer Actual",
      initialTargetMonthly: "Ange den första KPI-mätningen, KPI-målen och minst 1 månads mätning för att fortsätta.",
      initialTarget: "Ange start KPI och mål KPI för att fortsätta",
      upload_error: "Ett fel uppstod vid uppladdningen av återförsäljarnätverkert",
      action_item_create_warning: "Max fem poster är tillåtna per åtgärd",
      actionPlanStartDateValidation: "Slutdatum får inte vara tidigare än startdatum",
      actionsNotClosed: "Det finns pågående åtgärder i åtgärdsplanen. Stäng alla innan du stänger en aktivitet",
      activityDuplicate: "En annan aktivitet med samma återförsäljare och verktygslåda finns redan",
      assigneeNotExist: "Den ansvarige har inte registrerats i systemet",
      assigneeValidation: "Ogiltig ansvarig",
      atleastOnePublish: "Välj minst ett nyckeltal att publicera",
      cannotRetireActive: "Det här är ett obligatoriskt nyckeltal från TME som inte kan tas bort ",
      completionDate: "Datum för slutförande får inte vara tidigare än datum för första besöket",
      confirDeletePlannedActivity: "Är du säker på att du vill ta bort vald aktivitet?",
      DateformatValidation: "Date should be in format DD/MM/YYYY",
      DateValidation: "From Date Should Be Less Than To Date",
      dealerValidation: "Ogiltig återförsäljare",
      deleteSelectedConfirm: "Är du säker på att du vill radera valt nyckeltal?",
      duplicate: "Ett annat nycketal med samma namn och definition finns redan",
      modalMandatory: "Ange giltiga data i alla obligatoriska fält",
      noPublishKpi: "Nyckeltalen har inte publicerats ännu för vald verktygslåda",
      validSearchCriteria: "Ange ett giltigt sökkriterium",
      multiSelectPlaceHolder: "Slå in minst 5 tecken",
      multiSelectPlaceHolderUser: "Slå in minst 3 tecken",
      startDateValidation: "Startdatum får inte vara större än slutdatum",
      fileSelectionErr: "Var vänlig och välj fil först",
      atleastOneQuestion: "Välj minst en delfråga att importera.",
      atleastOneSubQues: "Välj minst en delfråga som du vill publicera.",
      uploadErr: "Antal filer som inte laddades upp:",
      uploadSuccess: "Återförsäljarnätverket har laddats upp",
      file_upload_success: "Filen, filerna, har laddats upp",
      KPIinOngoingClosedActivity: "This KPI is present in one, or more, Ongoing/Closed Activities.Please Complete these Activities before making any change to the KPI.",
      Role_Error: "The user setup is incomplete, please contact the NMSC KET Admin.",
      points_allocation_incorrect: "Points allocation is incorrect. Please modify.",
      activity_deleted:"Activity deleted successfully",
      activity_certified_dealer:"Dealer certified for the selected Activity, hence cannot be deleted",
      activity_submited_for_certificate_dealer:"The status of toolbox cannot be changed as request for certification has been sent to TME"
    },
    TopNav: {
      ActivityLog: "Aktiviteter",
      ApplicationName: "Kaizen Expert Tool",
      Home: "Hem",
      logout: "Logga ut",
      MasterDataUpload: "Överföra huvuddata",
      Toolbox: "Verktygslådor",
      welcome: "Välkommen",
      Admin: "Administration"
    },
    questionnaire: {
      addSubQuestion: "Lägg till delfråga",
      editSubQuestion: "Redigera delfråga",
      subQuestionTitle: "Delfråga",
      additionalInfo: "Ytterligare information",
      na: "Lägg till 'Ej tillämpligt' som ett möjligt svar på den här frågan",
      question: "Fråga",
      questionTitle: "Frågans titel",
      why: "Varför?",
      addMainQuestion: "Lägg till huvudfråga",
      editMainQuestion: "Redigera huvudfråga",
      previousQuestion: "Föregående fråga",
      nextQuestion: "Nästa fråga",
      what: "Vad?",
      library: "Bibliotek",
      subQuestionTable: {
        no: "Nej",
        subQuestion: "Delfrågor",
        status: "Status",
        publishedDate: "Publicerad Datum",
        notApplicable: "Ej tillämpligt"
      },
      links: {
        question_link_button: "Länk",
        question_link_include_folder: "Inkludera en undermapp",
        question_link_add_link: "Länka fil/er från biblioteket",
        question_link_view_folder: "Se i biblioteket"
      },
      mainQuestion: "Nya / uppdaterade huvudfrågor:",
      subQuestion: "Nya/uppdaterade delfrågor:",
      retiredQuestion: "Annullerade delfrågor",
      libraries: "Nya / uppdaterade filer i bibliotek:",
      noFiles: "Inga filer tillgängliga"
    },
    library: {
      selectFile: "Välj minst en fil",
      fileSelected: "Valda filer",
      publish_library_file: "Välj minst en fil för att publicera",
      library_files_deleted: "Are you sure you want to delete this file?",
      library_files_linked_deleted: "Är du säker på att du vill ta bort filen och länkarna skapade i frågeformuläret?",
      max_size_error: "Filstorleken överstiger gränsen på 1 Gb",
      empty_file_desc: "Ange en filbeskrivning",
      file_not_selected: "Välj filen/filerna som ska laddas upp (max 1 Gb)",
      file_type_error: "Den här filtypen är inte tillåten.",
      file_name_error: "Ogiltigt tecken i filnamnet.",
      file_name_lenght_error: "Filnamnet kan innehålla högst 50 tecken.",
      addFolder: "Lägg till mapp",
      editFolder: "Redigera mapp",
      currentFolder: "Mapp namn",
      parentFolder: "Huvudmappsnamn",
      emptyFolderName: "Mappnamnet kan inte vara tomt.",
      invalidFolderName: "The Folder Name cannot contain '/'.",
      folder_created: "Mappen har skapats.",
      folder_updated: "Mappen har uppdaterats.",
      folder_not_found: "Mappen ej hittad.",
      lib_invalid_toolbox: "Felaktig verktygslåda vald",
      lib_folder_already_exists: "Den här mappen finns redan.",
      lib_folder_deleted: "Mappen har raderats.",
      library_files_published: "Filerna har publicerats.",
      addFile: "Lägg till fil",
      editFile: "Redigera fil",
      fileName: "Filnamn",
      upload: "Ladda upp",
      desc: "Beskrivning",
      folderCreationErr: "Hierarkin är begräsnad till två nivåer",
      subFolderWarning: "Välj en undermapp för att lägga till fil/er",
      deleteWarning: "Välj vilken mapp som ska raderas",
      beforeAfterNotSelected: "Välj om bevisen är före eller efter kaizen",
    },
    chapter: {
      chapter_exist: "Kapitlet finns redan.",
      chapter_saved: "Kapitel sparat",
      chapter_cannot_delete: "Kapitlet kan inte raderas eftersom delfrågor redan har publicerats.",
      failed: "Misslyckades",
      chapter_deleted: "Kapitel raderad",
      chapter_nonEmpty: "Skapa ett kapitelnamn",
      addchapter: "Lägg til kapitel",
      editChapter: "Edit chapter",
      DELETE_SUCC:"Questionnaires deleted successfully.",
      DELETE_SUCC_LIB:"Libraries deleted successfully.",
      DELETE_ERR : "Something Went wrong."
    },
    evaluation: {
      attachments: "Bilagor",
      comments: "Kommentarer",
      evidence: "Bevis",
      evidences: "Bevis",
      yes: "Ja",
      no: "Nej",
      na: "Inte tillgängligt",
      uploadEvidence: "Ladda upp bevis",
      fileUpload: "Välj filen/filerna som ska laddas upp (max 1 Gb)",
      who: "Vem?",
      answer: "Svar",
      comment: "Kommentarer",
      date: "Datum",
      submit: "Överlämna",
      commentMsg: "Ange kommentarerna",
      noCommentAvailable: "Inga kommentarer tillgängliga",
      uploadSuccessMsg: "Filen, filerna, har laddats upp",
      uploadErrorMsg: "Antal filer som inte laddades upp:",
      fileWithSameName: "En fil med samma namn finns redan",
      noEvidence: "No evidences available",
      createAction: "Skapa åtgärd",
      chapterName: "Kapitelnamn",
      questionNo: "Fråga nr.",
      when: "När?",
      noRecords: "Inga poster tillgängliga",
      kaizenPoints: "Kaizen poäng",
      renameSuccessMsg: "File has been renamed successfully",
      deleteSuccessMsg: "File has been deleted successfully",
      question: "Fråga",
      before:"Before",
      after:"After",
      beforeAfterLabel:"Är detta bevis från före eller efter kaizen?",
      move:"Flytta",
      moveMsg: "Fil(er) har flyttats ",
      noToYesMsg:"Previous answer was NO",
    },
    WALK_AROUND_CHECK: "Walk Around Check",
    ketProgram: {
      TSM: "TSM/LSM",
      BRiT: "BRiT RI",
      TSM_BNP: "Body & Paint",
      TSM_USED_CAR: "Used Car",
      value_chain: "Value Chain",
      brit: "BRiT",
      ea: "EA",
      per:"Performance",
      nettrans:"Network Transformation",
    },
    dashboard: {
      notice_board: "Anslagstavla",
      kpi_target_not_set: "KPI's targets not set",
      kpi_initial_not_set: "KPI's initial measurement not set",
      kpi_monthly_not_set: "KPI's monthly measurement not set",
      kpi_below_target: "KPI's below targets present",
      activation: "Aktivering",
      users: "användare",
      nmscs: "NMSC's",
      activeRetailer: "Aktiva återförsäljare",
      certificationRate: "Certifieringsgrad",
      reCertificationRate: "Omcertifieringsgrad",
      activityDue: "Förfallna aktiviteter",
      avgdays: "Genomsnittliga dagar / verktygslåda",
      overDueAct: "Förfallna aktiviteter",
      actionItem: "Åtgärdsposter",
      bussResult: "Affärsresultat",
      toolboxImpl: "Implementering av verktygslådan",
      statusCompletedL:"Status - Completed, Progress - ",
      statusClosedL:"Status - Closed, Progress - ",
      statusOngoingL:"Status - Ongoing, Progress - ",
      recentUpdates: "Senaste uppdateringar",
      rolloutTopNmsc: "Utrullningsresultat - Topp NMSC: er",
      bestPractice: "Bra exempel",
      availableKpi: "Tillgängliga nyckeltal",
      selectedKpi: "Valda nyckeltal",
      dealerBestPractice: "Dealer best practice board",
      kpiList: "Nycketalslista",
      go: "GÅ",
      nodata: "No data available",
      businessResultTop: "Affärsresultat - Topp återförsäljare",
      businessResultNMSC: "Affärsresultat - Topp NMSC: er"


    },
    dealerCertification: {
      dealerCertification: "Återförsäljarcertifiering",
      readyForCertification: "Klar för certifiering",
      certification: "certifiering",
      submittedOn: "Inlämnad den",
      certificateDate: "Certifikat datum",
      reCertificateDate: "Omcertifierings datum",
      toolboxDetails: "Detaljer verktygslåda",
      approve: "Godkänna",
      reject: "Avslå",
      pending: "Pending",
      region: "region"



    },
    uploadBulk: {
      browse: "Browse",
      executionId: "Execution Id",
      uploadedOn: "Uploaded On",
      lastUpdatedOn: "Last updated on",
      noDataTable: "No data to view",
      kpiUpload: "KPI Upload",
      action: "Action",
      processed: "Processed",
      error: "Error",
      received: "Received",
      createActivity: "Create Activities",
      fileUpload: "File Upload",
      targetSetting: "Target Setting",
      kpiEntry:"KPI Entry",
      accepted:"Accepted",
      launchNmsc:"Launch for NMSC"
    },
    userManagement: {
      userManagement: "Users management",
      createUser: "Create User",
      editUser: "Edit User",
      viewUser: "View User",
      nmscId: "NMSC ID",
      tarsId: "TARS ID",
      firstName: "First name",
      lastName: "Last name",
      emailId: "E-mail",
      britDealerChamp: "Brit Dealer Champion",
      invalidEmail: "Please enter valid E-Mail ID",
      invalidTars: "Please enter valid TARS ID",
      invalidFirstName: "Please enter valid First Name",
      invalidLastName: "Please enter valid Last Name",
      user_created: "User Created",
      user_updated: "User updated",
      duplicate_tarsuser: "This TARS id is already registered in the KET database.",
      user_define_in_tool: "User is defined as Business owner in Active Business Function. Hence, could not be made Inactive",
      user_refer_on: "User is referenced in Ongoing activity",
      pbiAccount : "PBI Account",
      invalidPbiAccount : "PBI Account should contain minimum 3 characters.",
      businessArea: "Business Area",
      valueChainAndBRiT: "Value Chain & BRiT",
      only:"Only",
      optionText:"Please select at least one of the two options below",
      valueChainOnly: "Value Chain Only",
      britOnly: "BRiT Only"
    },
    UserManagement: {
      nmscId: "NMSC ID",
      tarsId: "TARS ID",
      firstName: "First Name",
      lastName: "Last Name",
      emailId: "E-Mail ID",
      languageCode: "Language Code",
      ACTIVE: "Active",
      INACTIVE: "Inactive",
      addUser: "Add User",
      Assign: "Assign",
      List: "List",
      user: "User",
      error_message: "An Error Occurred. Please try again later",
      role: "Role",
      standardMessage: "No User Found",
      dealerCode: "Dealer Code",
      a2d: "A2D UUID",
      britReady: "Retailer participated in Brit Ready",
      britLive: "Retailer participated in Brit Live",
      britJapan: "Retailer participated in Brit Discovery Japan",
      britEss:"Retailer has updated EES Action Plan",
      addDealer: "Add Dealer",
      noDealer: "No Dealer Found",
      dealerUuid: "Dealer uuid",
      language: "Language",
      L3: "L3",
      L4: "L4",
      L5: "L5",
      L6: "L6",
    },
    CeaKpi: {
      survey: "Survey",
      evaluationItem: "Evaluation Item",
      kpiMetric: "KPI Evaluation Method",
      stratagicPillar: "Strategic Pillar",
      addKPI: "Add KPI",
      edit: "Edit",
      delete: "Delete",
      subPillar: "Sub-Pillar",
      kaizenImp_impact_def: "Kaizen Importance / Impact / Definition",
      evalMethod: "Evaluation Method",
      scoreMethod: "Scoring Method",
      typeOftarget: "Type of Target",
      target: "Target",
      mode: "Mode",
      points: "Points",
      inputFreq: "Input Frequency",
      cea_kpi_created: "CEA KPI has been created successfully",
      noPointsAllocated:"No Points defined for selected Pillar/Business function mapping",
      targetIncorrect:"Target value is Invalid",
      viewKpi:"View KPI",
      general:"General",
      targetScoring:"Targets & Scoring",
      inputs:"Input Parameters",
      maxFiveParam:"Maximum 5 parameters for a KPI can be added",
      validParam:"Please Enter Valid Parameter",
      validDesc:"Please Enter Valid Parameter Description",
      dataEntry:"Data Entry in KET",
      paramater:"Parameter",
      paramDesc:"Parameter Description",
      noParamAvai:"No Parameters Available",
      kpiDeleted:"KPI deleted Successfully",
      kpiDeleteError:"KPI Not found",
      kpiActualsError:"Actuals present. KPI cannot be deleted",
      cea_kpi_updated: "CEA KPI has been updated successfully",
      addParam:"Add Parameters"

    },
    dealerManagement: {
      invalidUuid: "Please enter valid Uuid",
      invalidDealerName: "Please enter valid Dealer Name",
      invalidCity: "Please enter valid City",
      invaliDealerCode: "Please enter valid Dealer Code",
      existingUuid: "Existing Uuid",
      updateUuid: "Update Uuid",
      newUuid: "New Uuid",
    },
    networkManagement: {
      networkManagement: "Network management"
    },
    kpiEntry: {
      cancel : "Cancel",
      save : "Save",
      no : "No",
      yes : "Yes",
      hide : "Hide",
      show : "Show",
      dataNotSaved : "Data is not saved. Entered data may be lost. Do you want to Proceed?",
      wantToProceed : "Do you want to proceed?",
      status: 'Status',
      evaluationYear: 'Evaluation Year',
      frequency: 'Frequency',
      typeOfTarget: 'Type of Target',
      target: 'Target',
      unit: 'Unit',
      standardMessage: 'No Kpi Actuals Data Found',
      srNo: 'S.No.',
      actuals: 'Actuals',
      subPillar: 'Sub Pillar',
      businessFunc: 'Business Function',
      brand: 'Brand',
      Jan : "Jan",
      Feb : "Feb",
      Mar : "Mar",
      Apr : "Apr",
      May : "May",
      Jun : "Jun",
      Jul : "Jul",
      Aug : "Aug",
      Sep : "Sep",
      Oct : "Oct",
      Nov : "Nov",
      Dec : "Dec",
      q1 : "Q1",
      q2 : "Q2",
      q3 : "Q3",
      q4 : "Q4",
      h1 : "H1",
      h2 : "H2",
      cy : "CY",
      kpi : "Key Performance Indicators",
      actuals1 : "Actuals should not be more than allotted points. Please re-enter",
      actionPlan	: "Action Plan",
      enterActualsData : "Please enter actuals data.",
      notNumber : "Please enter valid number.",
      serverErrorMsg : "An error occured, please try again later.",
      launchEvaluationError : "Please launch evaluations.",
      actualsDataSaved : "Actuals data uploaded successfully.",
      inputTxt:"Inputs"
    },
    ceaEvaluation :{
      nmsc : "NMSC",
      search : "Search",
      all : "All",
      status : "Status",
      businessFunction :"Business Function",
      noEvaluationsFound : "No Evalutions Found",
      evaluationYear : "Evalution Year",
      view : "View",
      evaluations : "Evaluations",
      launchTargetSetting: "Launch Target Setting",
      launchEvaluation : "Launch Evaluation",
    },
    britDashboard:{
      noVideo:"No videos available",
      playVideo:"Play Video",
      trainingVideos:"Training  Videos",
      download: "Download Video",
      back:"Back"
    },
    ceaActionPlan:{
      id: "ID",
      counterMeasure: "Countermeasure",
      questionReference: "KPI Reference",
      status: "Status",
      startDate: "Start Date",
      dueDate: "Due Date",
      assignedTo: "Assigned To",
      noActionAvailable: "No Actions available",
      add: "Add",
      download: "Download",
      edit: "Edit",
      delete: "Delete",
      deleteMsg: "Are you sure you want to delete ?",
      no: "No",
      yes: "Yes",
      addActionPlanNotAllowed: "The Action Plan cannot be created, or edited after the Activity has been Closed, Completed, or Rejected.",
      actionPlanItem: "Action Plan - Item #",
      addActionPlan: "Add Action Plan",
      open: "Open",
      actionPlanStartDateValidation:"End Date cannot be earlier than the Start Date",
      modalMandatory: "Please enter valid data in all the mandatory fields",
      problem: "Problem",
      description: "Description",
      followup: "Follow Up",
      history: "History",
      cancel: "Cancel",
      save: "Save",
      update: "Update",
      assignee: "Assignee",
      endDate: "End Date", 
      select: "Select",
      notApplicablePer:"Not Applicable for CEA, Networks and Used car"
    },
    reachItDashboard:{
      topNavReachIT:"ReachIT",
      downloads:"Nerladdningar",
      historyFiles:"Filhistorik",
      reachText1:"Välkommen till hemsidan för REACH IT. På denna sida kommer Toyota Motor Europes Environmental Management team dela relevant information avseende SCIP-databasen för att uppfylla den så kallade Förenklade SCIP-anmälan enligt EU:s avfallsdirektiv. SCIP = Substances of Concern In articles, as such or in complex objects (Products).",
      reachText2:"Vid eventuella frågor, vänligen kontakta Toyota Motor Europes Environmental Management team via länken nedan.",
      reachText3:"Vid eventuella frågor, vänligen kontakta Toyota Sweden.",
      envMngt:"Environmental Management",
      fileStatistics: "Statistik avseende nerladdade filer",
      Downloaded: "Nerladdade",
      not: "Nej",
      exportToExcel:"Exportera till Excel"
    },
    usedCarComment:{
      ongoing:"Ongoing",
      completed:"Completed",
      commentHistory:"Monthly Comments History",
      addComment:"Add Monthly Comment",
      month:"Month",
      comment:"Comment",
      username:"Username",
      email:"Email",
      createdDate:"Created Date"
    },
  }
};

export default translationsSV;
