import React from "react";
import BootstrapTable from "react-bootstrap-table-next";
import { connect } from "react-redux";
import { Collapse } from "react-bootstrap";
import { withRouter } from "react-router-dom";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import { withTheme } from "../../themes/theming";
import "./index.css";
import { Tab, Tabs } from "react-bootstrap-tabs";
import { toastr } from "react-redux-toastr";
import SubQuestionnairModal from "../questionnaireModal/subQuestionnairModal.js";
import StatCounter from "./components/statCounter.js";
import LibraryLink from "./components/libraryLink";
import AddLibraryFileModal from "./components/addLibraryFileModal";
import MainQuestion from "../questionnaireModal/MainQuestion.js";
import * as AppConst from '../../AppConstant';
import {
  deleteSubQuestion,
  publishSubQuestion,
  importAllSubQuestions,
  publishNmscSubQuestion,
  deleteNmscSubQuestion,
} from "../../actions/subQuestionAction";
import {
  deleteMainQuestion,
  deleteNmscMainQuestion,
  toSetSelectedQuestionId
} from "../../actions/mainQuestionAction";
import { getChapterSequence, getNmscChapterSequence, getNmscQuestionSummary, setFirstPageLoad } from "../../actions/questionSummaryAction";
import { deleteQuestionReference, deleteNmscQuestionLibraryLink } from "../../actions/libraryAction";
var I18n = require("react-redux-i18n").I18n;

const $ = require("jquery");
window.jQuery = $;

class QuestionContent extends React.Component {
  /* eslint-disable */
  constructor(props) {
    super(props);
    this.handleNextPage = this.handleNextPage.bind(this);
    this.handlePrevPage = this.handlePrevPage.bind(this);
    this.subQuestionFormatter = this.subQuestionFormatter.bind(this);
    this.handleCurrentPage = this.handleCurrentPage.bind(this);
    this.toggleQuestion = this.toggleQuestion.bind(this);
    this.toggleLibrary = this.toggleLibrary.bind(this);
    this.addLibraryLink = this.addLibraryLink.bind(this);
    this.selectLibraryLink = this.selectLibraryLink.bind(this);
    this.deleteLibraryLink = this.deleteLibraryLink.bind(this);
    this.saveLibraryLink = this.saveLibraryLink.bind(this);
    this.state = {
      openQuestion: true,
      openLibrary: false,
      chapterSequence: "",
      nmscChapterSequence: "",
      rotateQuestion: "rotate-true",
      rotateLibrary: "rotate-false",
      modal: false,
      lastQuestionDeleted: false,
      currentQuestion: this.props.questionId,
      mainModal: false,
      libraryModal: false,
      previousPage: true,
      nextPage: true,
      isNmsc: false,
      chapterId: "",
      selectedLibraryLinks: [],
      list: [],
      selectedRow: {
        questionId: 2,
        notApplicable: 0
      },
      mainQuestionDetails: {},
      libraryLinks: [],
      subQuestions: [],
      subQuestionFlag: false,
      questionNo: "",
      currentQuestionNumber: "",
      questionNumber: "",
      editQuestionNumber: "",
      mainQuestionType: null,
      tmeQuestions: [],
      subQuestionsChecked: [],
      subQuestionNonselectable: [],
      importDisable: true,
      tmeTableTME: "tmeTableTME"
    };
  }


  componentWillMount() {
    var isNMSCAdminUser = false;
    var isTMEAdminUser = false;
    var isNotAdmin = false;
    if (this.props.user.userMdl.roles !== undefined) {
      var roles = this.props.user.userMdl.roles;
      console.log("userMd1",this.props.user.userMdl)
      for (var i = 0; i < roles.length; i++) {
        if (roles[i].toUpperCase().indexOf('KET NMSC ADMIN') !== -1) {
          isNMSCAdminUser = true;
          isNotAdmin = false;
          break;
        }
        else if (roles[i].toUpperCase() === "TSM KET USER" || roles[i].toUpperCase() === "KET TME USER" || roles[i].toUpperCase() === "KET TME ADMIN"
        || roles[i].toUpperCase()=== "KET-TSP Auditor" || this.props.user.userMdl.businessAreaKey==="TSP"
          // || roles[i].toUpperCase() === "KET DEALER CHAMPION" || roles[i].toUpperCase() === "KET DEALER INTERNAL" ||
          // roles[i].toUpperCase() === "KET-BRIT DEALER CHAMPION" || roles[i].toUpperCase() === "KET-BRIT DEALER INTERNAL"
        ) {
          isTMEAdminUser = true;
          isNotAdmin = false;
          break;
        }
        isNotAdmin = true;

      }
    }
    if( this.props.user.userMdl.roles.includes('KET NMSC Admin'))
    {
      isNMSCAdminUser = true;
      isNotAdmin = false;
      this.setState({ isNmsc: isNMSCAdminUser });
      this.setState({ isNotAdmin: isNotAdmin });
    }

    // Reset 'activeTabNmscLang' value so that tme admin can edit
    isNMSCAdminUser || isNotAdmin ? null : this.props.activeNMSCTabLink("");
  
    if (isNMSCAdminUser || isNotAdmin) {
      let langCode = "TME";
      let selectedTabIndex = 0;
      this.props.user.userMdl.userLangList.map((obj, index) => {
       
        if (obj.language.toUpperCase() === this.props.user.userMdl.lang.toUpperCase()) {
          langCode = obj.language;
          selectedTabIndex = index + 1;
        }
      });
      this.setState({ selectedTab: selectedTabIndex });
      this.setState({ activeTabNmscLang: langCode });

    }

  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.questionId !== this.props.questionId) {
      this.setState({
        // currentQuestion: this.props.questionId,
        openQuestion: true,
        openLibrary: false,
        rotateQuestion: "rotate-true",
        rotateLibrary: "rotate-false"
      });
    }
    $(".lib-node")
      .unbind()
      .on("click", function () {
        let element = $(this).find(".libraryToggleArrow")[0];
        if (element !== undefined) {
          if (element.classList.contains("rotate-true")) {
            $(element)
              .removeClass("rotate-true")
              .addClass("rotate-false");
          } else {
            $(element)
              .removeClass("rotate-false")
              .addClass("rotate-true");
          }
        }
      });
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.selectedQuestionId) {
      this.setState({ currentQuestion: nextProps.selectedQuestionId });

    }
    else {
      this.setState({ currentQuestion: "" });
    }
    this.handleCurrentPage();
    this.setState({ currentQuestionNumber: "", subQuestions: [], questionNo: "", questionNumber: "" });
    let questionSequence = "";
    let chapterSequence = "";
    let nmscChapterSequence = "";
    let nmscChapterSequenceType="";
    let questionSequenceType="";
    let subQuestion = [];
    let mainQuestion = {};
    let No = "";
    let tmeQuestions = [];
    let subQuestionsChecked = [];
    let subQuestionNonselectable = [];
    if (this.props.isNMSCAdminUser || this.state.isNotAdmin) {
      this.setState({ tmeTableTME: "" });
      if (nextProps.nmscQuestionContent && nextProps.nmscQuestionContent.length > 0 && nextProps.nmscChapterSequence) {
        console.log("nextProps.nmscChapterSequence------------:",nextProps.nmscChapterSequence);
        //if (nextProps.nmscQuestionContent && nextProps.nmscChapterSequence) {
        let propsArray = nextProps.nmscQuestionContent.slice(0);
        mainQuestion = propsArray[0];
        questionSequence = mainQuestion.seqNum;
        nmscChapterSequence = nextProps.nmscChapterSequence.sequence;

         nmscChapterSequenceType = nextProps.nmscChapterSequence.type;
         questionSequenceType = mainQuestion.type;

        if (mainQuestion.type === "LOCAL") {
          No = nextProps.nmscChapterSequence.sequence + "." + questionSequence + "#" + " - ";
          this.setState({ mainQuestionType: "LOCAL" });
        }
        else {
          No = nextProps.nmscChapterSequence.sequence + "." + questionSequence + " - ";
          this.setState({ mainQuestionType: "TME" });
        }
        let currentQuestionNumber = nextProps.nmscChapterSequence.sequence + "." + questionSequence;
        let number = nextProps.nmscChapterSequence.sequence + "." + (questionSequence + 1);
        this.setState({
          questionNo: No,
          questionNumber: number,
          currentQuestionNumber: currentQuestionNumber,
          editQuestionNumber: questionSequence,
          chapterId: mainQuestion.nmscChapterId
        });
      }
    }
    else {
      if (nextProps.questionContent && nextProps.chapterSequence) {
        let propsArray = nextProps.questionContent.slice(0);
        mainQuestion = propsArray[0];
        questionSequence = mainQuestion.seqNum;
        chapterSequence = nextProps.chapterSequence;

        nmscChapterSequenceType = nextProps.type;
       questionSequenceType = mainQuestion.type;

        let No = nextProps.chapterSequence + "." + questionSequence + " - ";
        let currentQuestionNumber = nextProps.chapterSequence + "x" + questionSequence;
        let number = nextProps.chapterSequence + "." + (questionSequence + 1);
        this.setState({
          questionNo: No,
          questionNumber: number,
          currentQuestionNumber: currentQuestionNumber,
          editQuestionNumber: questionSequence,
          chapterId: mainQuestion.chapterId
        });
      }
    }
    if (this.props.isNMSCAdminUser || this.state.isNotAdmin) {
      this.setState({ isNmsc: true, uniqueKey: "nmscSubQuestionId" });
      this.state.subQuestionFlag = false;
      if (nextProps.nmscSubQuestions && Array.isArray(nextProps.nmscSubQuestions) && nextProps.nmscSubQuestions.length > 0) {
        subQuestion = [];
        nextProps.nmscSubQuestions.map(question => {
          subQuestion.push({ ...question });
          if ((question.type === "TME" || question.type === "LOCAL") && question.status === "Saved" && this.props.savedSubQuestions.includes(question.nmscSubQuestionId)) {
            // tmeQuestions.push(question.nmscSubQuestionId);
            subQuestionsChecked.push(question.nmscSubQuestionId);
          }
          if ((question.type === "TME" || question.type === "LOCAL") && (question.status === "Published" || question.status === "Retired")) {
            subQuestionNonselectable.push(question.nmscSubQuestionId)
          }

        });

        for (let i = 0, size = subQuestion.length; i < size; i++) {
          let item = subQuestion[i];
          if (
            item.status.toUpperCase() === "PUBLISHED" ||
            item.status.toUpperCase() === "RETIRED" || item.publishedOnDate !== ''
          ) {
            this.state.subQuestionFlag = true;
            break;
          }
        }
        subQuestion.map(que => {
          let sequence = que.seqNum;
          if (que.type === "TME") {
            que.seqNum = nmscChapterSequence + "." + questionSequence + "." + sequence;
          }
          else {
            que.seqNum = (nmscChapterSequenceType === "LOCAL" ? nmscChapterSequence + "#" : nmscChapterSequence) + "." + (questionSequenceType === "LOCAL" ? questionSequence + "#" : questionSequence) + "." + sequence + "#";
          }
        });
      }
      this.setState({
        mainQuestionDetails: mainQuestion,
        nmscChapterSequence: nmscChapterSequence,
        subQuestions: subQuestion,
        tmeQuestions: tmeQuestions,
        subQuestionsChecked: subQuestionsChecked,
        subQuestionNonselectable: subQuestionNonselectable
      });

    }
    else {
      this.setState({ uniqueKey: "subQuestionId", subQuestions: [] });
      if (nextProps.subQuestions && nextProps.subQuestions !== this.state.subQuestions) {
        subQuestion = [];
        this.state.subQuestionFlag = false;
        if (Array.isArray(nextProps.subQuestions) && nextProps.subQuestions.length > 0) {
          nextProps.subQuestions.map(question => {
            subQuestion.push({ ...question });
          });
          for (let i = 0, size = subQuestion.length; i < size; i++) {
            let item = subQuestion[i];
            if (
              item.status.toUpperCase() === "PUBLISHED" ||
              item.status.toUpperCase() === "RETIRED" || item.publishedOnDate !== ''
            ) {
              this.state.subQuestionFlag = true;
              break;
            }
          }
          subQuestion.map(que => {
            let sequence = que.seqNum;
            que.seqNum = chapterSequence + "." + questionSequence + "." + sequence;
          });
        }
      }
    }
    this.setState({
      mainQuestionDetails: mainQuestion,
      chapterSequence: chapterSequence,
      subQuestions: subQuestion
    });
    if (nextProps.selectedQuestionId) {
      this.setState({ selectedQuestionId: nextProps.selectedQuestionId });
    }

    if (this.props.user.userMdl.userLangList) {
      var list = [];
      this.props.user.userMdl.userLangList.map((obj, index) => {
        list.push(obj.country + " / " + obj.language);
      });
      this.setState({ list: list });
      //console.log("this list" + list);
    }
  }

  handleCurrentPage() {
    if (this.state.currentQuestion && this.props.questionList && Array.isArray(this.props.questionList)) {
      this.setState({ previousPage: true, nextPage: true });
      let chapterQ = [];
      let questionIndex = 0;
      this.props.questionList.map(que => {
        que.children.map(child => {
          if (child.mainQId == this.state.currentQuestion) {
            chapterQ = [...que.children];
            questionIndex = que.children.indexOf(child);
          }
        });
      });
      if (questionIndex === 0 && questionIndex === chapterQ.length - 1) {
        this.setState({ previousPage: false, nextPage: false });
      } else if (questionIndex === chapterQ.length - 1) {
        this.setState({ nextPage: false, previousPage: true });
      } else if (questionIndex === 0 && questionIndex !== chapterQ.length - 1) {
        this.setState({ previousPage: false, nextPage: true });
      } else {
        this.setState({ previousPage: true, nextPage: true });
      }
    }
  }

  handleNextPage() {
    let chapterQ = [];
    let questionIndex = 0;

    this.props.questionList.map(que => {
      que.children.map(child => {
        if (child.mainQId === this.state.currentQuestion) {
          chapterQ = [...que.children];
          questionIndex = que.children.indexOf(child);
        }
      });
    });
    questionIndex = questionIndex + 1;
    this.setState({ currentQuestion: chapterQ[questionIndex].mainQId });
    this.props.dispatch(
      toSetSelectedQuestionId(chapterQ[questionIndex].mainQId)
    );
    if (this.props.isNMSCAdminUser || this.state.isNotAdmin) {
      this.props.dispatch(
        getNmscChapterSequence(
          this.state.chapterId,
          chapterQ[questionIndex].mainQId,
          this.props.questionList, this.props.isNMSCAdminUser
        )
      );
    }
    else {
      this.props.dispatch(
        getChapterSequence(
          this.state.chapterId,
          chapterQ[questionIndex].mainQId,
          this.props.questionList
        )
      );
    }
    /* this.props.dispatch(fetchChapterSequence(chapterQ[questionIndex].mainQId))
        this.props.dispatch(fetchQuestionContent(chapterQ[questionIndex].mainQId))
        this.props.dispatch(fetchSubQuestions(chapterQ[questionIndex].mainQId)) */
    // this.handleCurrentPage();
  }
  handlePrevPage() {
    let chapterQ = [];
    let questionIndex = 0;
    this.props.questionList.map(que => {
      que.children.map(child => {
        if (child.mainQId === this.state.currentQuestion) {
          chapterQ = [...que.children];
          questionIndex = que.children.indexOf(child);
        }
      });
    });
    questionIndex = questionIndex - 1;

    this.setState({ currentQuestion: chapterQ[questionIndex].mainQId });
    this.props.dispatch(
      toSetSelectedQuestionId(chapterQ[questionIndex].mainQId)
    );
    if (this.props.isNMSCAdminUser || this.state.isNotAdmin) {
      this.props.dispatch(
        getNmscChapterSequence(
          this.state.chapterId,
          chapterQ[questionIndex].mainQId,
          this.props.questionList, this.props.isNMSCAdminUser
        )
      );
    }
    else {
      this.props.dispatch(
        getChapterSequence(
          this.state.chapterId,
          chapterQ[questionIndex].mainQId,
          this.props.questionList
        )
      );
    }
    /* this.props.dispatch(fetchChapterSequence(chapterQ[questionIndex].mainQId))
        this.props.dispatch(fetchQuestionContent(chapterQ[questionIndex].mainQId))
        this.props.dispatch(fetchSubQuestions(chapterQ[questionIndex].mainQId)) */
    // this.handleCurrentPage();
  }
  selectLibraryLink(files) {
    this.setState({ selectedLibraryLinks: files });
  }
  saveLibraryLink(files) {
    let links = [];
    if (files.length > 0) {
      files.map((file) => {
        links.push({ ...file });
      })
    }
    this.setState({ libraryLinks: links });
  }
  toggleQuestion = e => {
    if (this.state.rotateQuestion === "rotate-false") {
      this.setState({ rotateQuestion: "rotate-true" });
    } else {
      this.setState({ rotateQuestion: "rotate-false" });
    }
  };
  toggleLibrary = e => {
    if (this.state.rotateLibrary === "rotate-false") {
      this.setState({ rotateLibrary: "rotate-true" });
    } else {
      this.setState({ rotateLibrary: "rotate-false" });
    }
  };
  statusFormatter(cell, row) {
    return (
      <div >
        {I18n.t('status.' + row.status.toLowerCase())}
      </div>
    );
  }
  importNMSCQuestion = () => {
    var importSubQuestionnaireDTO = {
      masterSubQuestionnaireList: [],
      username: this.props.user.userMdl.username
    }
    this.props.dispatch(importAllSubQuestions(this.props.toolboxId, this.props.activeTabNmscLang, this.props.user.userMdl.nmsc, importSubQuestionnaireDTO));
  }

  subQuestionFormatter = (cell, row) => {
    let subQuestionNo = row.seqNum;
    this.setState({ subQuestionNo: subQuestionNo });
    return <p>{row.seqNum}</p>;
  };

  buttonFormatter = (cell, row) => {
    let subQuestionAccessType = "";
    if (this.props.user.userMdl && this.props.user.userMdl.accessControl) {
      subQuestionAccessType = this.props.user.userMdl.accessControl.filter(
        obj => obj.function === "SUB_QUESTIONNAIRE"
      )[0]
        ? this.props.user.userMdl.accessControl.filter(
          obj => obj.function === "SUB_QUESTIONNAIRE"
        )[0].accessType
        : null;
    }

    return (
      <div className="btn-toolbar float-right">
        {row.additionalInfo && !(this.props.user.userMdl.roles.includes("KET-TSP Auditor")) && row.additionalInfo.length > 0 ?
          <a>
            <div
              style={{ whiteSpace: "pre" }}
              title={row.additionalInfo}
              className="additionalInfo"
            />
          </a> : ""}

        {subQuestionAccessType === "R/W" &&
          row.status.toUpperCase() !== "RETIRED" && this.props.activeTabNmscLang !== 'TME' && !(this.props.user.userMdl.roles.includes("KET-TSP Auditor")) ? (
            <a onClick={() => this.toggle(row)}>
              <div title={I18n.t('tmeAdminMaster.edit')} className="edit-icon" />
            </a>
          ) : (
            ""
          )}
        {subQuestionAccessType === "R/W" &&
          row.status.toUpperCase() !== "RETIRED" && this.props.activeTabNmscLang !== 'TME' && this.state.isNmsc === false && !(this.props.user.userMdl.roles.includes("KET-TSP Auditor")) ? (
            <a onClick={() => this.onDeleteSubQuestionClick(row)}>
              <div title={I18n.t('tmeAdminMaster.delete')} className="delete-icon" />
            </a>
          ) : (
            ""
          )}
        {this.state.isNmsc === true &&
          row.status.toUpperCase() !== "RETIRED" && this.props.activeTabNmscLang !== 'TME' && subQuestionAccessType == "R/W" && row.type !== "TME" && !(this.props.user.userMdl.roles.includes("KET-TSP Auditor")) ? (
            <a onClick={() => this.onDeleteNmscSubQuestionClick(row)}>
              <div title={I18n.t('tmeAdminMaster.delete')} className="delete-icon" />
            </a>
          ) : (
            ""
          )}
      </div>
    );
  };

  addMainQuestion = row => {
    this.props.dispatch(setFirstPageLoad());
    if (!this.state.isNmsc) {
      if (row.chapterId) {
        this.setState({
          mainModal: !this.state.mainModal,
          selectedQuestion: row,
          modalFor: "Edit"
        });
      } else {
        this.setState({
          mainModal: !this.state.mainModal,
          selectedQuestion: null,
          modalFor: "Add"
        });
      }
    }
    else {
      if (row.nmscChapterId) {
        this.setState({
          mainModal: !this.state.mainModal,
          selectedQuestion: row,
          modalFor: "Edit"
        });
      } else {
        this.setState({
          mainModal: !this.state.mainModal,
          selectedQuestion: null,
          modalFor: "Add"
        });
      }
    }
  };
  addLibraryLink = row => {
    this.setState({ libraryModal: !this.state.libraryModal, modalFor: "Add" });
  };
  notApplicableFormatter = (cell, row) => {
    if (row.notApplicable === true) {
      return <input type="checkbox" checked disabled />;
    } else {
      return <input type="checkbox" disabled />;
    }
  };

  isCriticalFormatter = (cell, row) => {
    if (row.isCritical === true) {
      return <input type="checkbox" checked disabled />;
    } else {
      return <input type="checkbox" disabled />;
    }
  };

  toggle = row => {
    if (row) {
      this.setState({
        modal: !this.state.modal,
        selectedRow: row,
        modalFor: "Edit"
      });
    } else {
      this.setState({
        modal: !this.state.modal,
        selectedRow: null,
        modalFor: "Add"
      });
    }
  };

  publish = () => {
    this.props.dispatch(
      publishSubQuestion(
        this.props.user.userMdl.username,
        this.props.toolboxId,
        this.props.questionId,
        this.props.chapterId
      )
    );
  };

  Nmscpublish = () => {
    //  alert();
    //  console.log(this.props.user.userMdl);
    //  console.log(this.props.activeTabNmscLang);
    if (this.props.savedSubQuestions && this.props.savedSubQuestions.length > 0) {
      this.props.dispatch(
        publishNmscSubQuestion(
          this.props.user.userMdl.username,
          this.props.toolboxId,
          this.props.activeTabNmscLang, this.props.user.userMdl.nmsc, this.props.questionId,
          this.state.chapterId, this.props.savedSubQuestions
        )

      );
      this.setState({ subQuestionsChecked: [] });
    }
    else {
      toastr.error(I18n.t('toastr.atleastOneSubQues'));
    }

  };

  deleteLibraryLink() {
    //   console.log(this.state.selectedLibraryLinks);
    if (this.state.selectedLibraryLinks.length > 0) {
      if (this.state.isNmsc) {
        this.props.dispatch(deleteNmscQuestionLibraryLink(this.state.currentQuestion, this.state.selectedLibraryLinks));
      }
      else {
        this.props.dispatch(deleteQuestionReference(this.state.currentQuestion, this.state.currentQuestionNumber, this.state.selectedLibraryLinks));
      }
      this.setState({ selectedLibraryLinks: [] });
    }
  }
  deleteSubQuestion = id => {

    this.props.dispatch(
      deleteSubQuestion(
        id,
        this.props.user.userMdl.username,
        this.props.questionId
      )
    );

  };
  deleteNmscSubQuestion = nmscSubQuestionId => {
    this.props.dispatch(
      deleteNmscSubQuestion(
        nmscSubQuestionId,
        this.props.user.userMdl.username,
        this.props.questionId
      )
    );
  };
  deleteQuestion = (isNMSCAdminUser) => {
    let chapterQ = [];
    let questionIndex = 0;
    this.props.questionList.map(que => {
      que.children.map(child => {
        if (child.mainQId === this.props.questionId) {
          chapterQ = [...que.children];
          questionIndex = que.children.indexOf(child);
        }
      });
    });
    questionIndex = questionIndex - 1;

    confirmAlert({
      customUI: ({ title, message, onClose }) => {
        return (
          <div className="deleteConfirmBox">
            <div className={"titleClass-" + this.props.theme.name}>
              {I18n.t("standardMessages.deleteConfirmMainQuestion")}
            </div>
            <div className="modal-footer" style={{ borderTop: "0px" }}>
              <button
                className={"buttonCancel-" + this.props.theme.name}
                onClick={() => onClose()}
              >
                {I18n.t("standardMessages.no")}
              </button>
              {console.log(chapterQ[questionIndex])}
              <button
                className={"button-" + this.props.theme.name}
                onClick={() => {
                  let nextQuestionId = 0;
                  if (questionIndex !== "-1")
                    this.state.chapterId,
                    nextQuestionId = chapterQ[questionIndex].mainQId;
                  else {
                    nextQuestionId = "-1";
                    this.setState({ currentQuestion: "" });
                  }
                  if (
                    this.state.selectedQuestionId &&
                    this.props.questionId !== this.state.selectedQuestionId
                  ) {

                    if (isNMSCAdminUser) {
                      this.props.dispatch(
                        deleteNmscMainQuestion(
                          this.state.selectedQuestionId,
                          this.props.user.userMdl.username,
                          this.props.toolboxId,
                          nextQuestionId,
                          this.state.chapterId,
                          this.props.user.userMdl.nmsc,
                          this.props.activeTabNmscLang
                        )
                      );
                    }
                    else {
                      this.props.dispatch(
                        deleteMainQuestion(
                          this.state.selectedQuestionId,
                          this.props.user.userMdl.username,
                          this.props.toolboxId,
                          nextQuestionId,
                          this.state.chapterId

                        )
                      );
                    }
                  } else {

                    if (isNMSCAdminUser) {
                      this.props.dispatch(
                        deleteNmscMainQuestion(
                          this.props.questionId,
                          this.props.user.userMdl.username,
                          this.props.toolboxId,
                          nextQuestionId,
                          this.state.chapterId,
                          this.props.user.userMdl.nmsc,
                          this.props.activeTabNmscLang
                        )
                      );
                    }
                    else {
                      this.props.dispatch(
                        deleteMainQuestion(
                          this.props.questionId,
                          this.props.user.userMdl.username,
                          this.props.toolboxId,
                          nextQuestionId,
                          this.state.chapterId
                        )
                      );
                    }
                  }
                  onClose();
                }}
              >
                {I18n.t("standardMessages.yes")}
              </button>
            </div>
          </div>
        );
      }
    });
  };
  getQuestionContent(isNMSCAdminUser) {
    var isNMSCAdminUser = false;
    var isTMEAdminUser = false;
    var isNotAdmin = false;
    if (this.props.user.userMdl.roles !== undefined) {
      var roles = this.props.user.userMdl.roles;
      for (var i = 0; i < roles.length; i++) {
        if (roles[i].toUpperCase().indexOf('KET NMSC ADMIN') !== -1) {
          isNMSCAdminUser = true;
          isNotAdmin = false;
          break;
        }
        else if (roles[i].toUpperCase() === "TSM KET USER" || roles[i].toUpperCase() === "KET TME USER" || roles[i].toUpperCase() === "KET TME ADMIN"
          || roles[i].toUpperCase() === "KET DEALER CHAMPION" || roles[i].toUpperCase() === "KET DEALER INTERNAL" ||
          roles[i].toUpperCase() === "KET-BRIT DEALER CHAMPION" || roles[i].toUpperCase() === "KET-BRIT DEALER INTERNAL"
          || roles[i].toUpperCase() === "KET TSP Auditor"
        ) {
          isTMEAdminUser = true;
          isNotAdmin = false;
          break;
        }
        isNotAdmin = true;

      }
    }

    if (this.props.nmscQuestionContent && this.props.nmscQuestionContent.length === 0) {
      return (<div className="emptyDiv" ></div>)
    }
    let subQuestionAccessType = "";
    if (this.props.user.userMdl && this.props.user.userMdl.accessControl) {
      subQuestionAccessType = this.props.user.userMdl.accessControl.filter(
        obj => obj.function === "SUB_QUESTIONNAIRE"
      )[0]
        ? this.props.user.userMdl.accessControl.filter(
          obj => obj.function === "SUB_QUESTIONNAIRE"
        )[0].accessType
        : null;
    }
    const { openQuestion } = this.state;
    const { openLibrary } = this.state;
    let seqNumHeader = {};
    if (isTMEAdminUser) {
      seqNumHeader = {
        width: "5.83%",
        textAlign: "left",
        paddingLeft: "0.3rem"
      }

    } if (isNMSCAdminUser || isNotAdmin) {
      seqNumHeader = {
        width: "4.7%",
        textAlign: "left",
        paddingLeft: "0.3rem"
      }
    }
    let seqNumStyle = {};
    if (isTMEAdminUser) {
      seqNumStyle = {
        fontSize: "12px",
        width: "6%",
        textAlign: "left",
        wordWrap: "break-word"
      }

    } if (isNMSCAdminUser || isNotAdmin) {
      seqNumStyle = {
        fontSize: "12px",
        width: "6%",
        textAlign: "left",
        wordWrap: "break-word"
      }
    }

    let subQuestionNameHeaderStyle = {};
    if (isTMEAdminUser) {
      subQuestionNameHeaderStyle = {
        width: "20.637%", textAlign: "left"
      }
    } if (isNMSCAdminUser || isNotAdmin) {
      subQuestionNameHeaderStyle = {
        width: "14.0%", textAlign: "left"
      }
    }
    let subQuestionNameBodyStyle = {};
    if (isTMEAdminUser) {
      subQuestionNameBodyStyle = {
        fontSize: "12px",
        width: "20.5%",
        textAlign: "left",
        wordWrap: "break-word",
        whiteSpace: "pre-wrap"
      }
    } if (isNMSCAdminUser || isNotAdmin) {
      subQuestionNameBodyStyle = {
        fontSize: "12px",
        width: "13.5%",
        textAlign: "left",
        wordWrap: "break-word",
        whiteSpace: "pre-wrap"
      }
    }

    let buttonStyle = {};
    if (isTMEAdminUser) {
      buttonStyle = {
        width: "14.24%",
        paddingTop: "10px !important",
        marginLeft: "8px"
      }
    } else {
      buttonStyle = {
        width: "12.24%",
        paddingTop: "10px !important",
        marginLeft: "8px"
      }
    }
    const columns = [
      {
        dataField: "seqNum",
        text: I18n.t('questionnaire.subQuestionTable.no'),
        //isDummyField: true,
        // formatter: this.subQuestionFormatter,
        style: seqNumStyle,
        headerStyle: seqNumHeader
      },
      {
        dataField: "name",
        text: I18n.t('questionnaire.subQuestionTitle'),
        formatter: this.dataFormatter,
        style: subQuestionNameBodyStyle,
        headerStyle: subQuestionNameHeaderStyle
      },
      {
        dataField: "status",
        text: I18n.t('questionnaire.subQuestionTable.status'),
        sort: true,
        formatter: this.statusFormatter,
        classes: (cell, row, rowIndex, colIndex) => {
          if (cell === "Published") {
            return "greenColor";
          } else if (cell === "Saved") {
            return "orangeColor";
          } else {
            return "greyColor";
          }
        },
        style: { fontSize: "12px", width: "8%", textAlign: "center" },
        headerStyle: { width: "7.75%", textAlign: "center" }
      },
      {
        dataField: "publishedOnDate",
        text: I18n.t('questionnaire.subQuestionTable.publishedDate'),
        style: { fontSize: "12px", width: "17.5%", textAlign: "center" },
        formatter: this.benchMarkFormatter,
        headerStyle: { width: "17.19%", textAlign: "center" }
      },
      {
        dataField: "notApplicable",
        text: I18n.t('questionnaire.subQuestionTable.notApplicable'),
        sort: true,
        formatter: this.notApplicableFormatter,
        style: { fontSize: "11.8px", width: "11.8%", textAlign: "center" },
        headerStyle: { width: "12.51%", textAlign: "center" }
      },
      {
        dataField: "Critical",
        text: I18n.t('questionnaire.subQuestionTable.isCritical'),
        sort: true,
        formatter: this.isCriticalFormatter,
        style: { fontSize: "11.8px", width: "11.8%", textAlign: "center" },
        headerStyle: { width: "12.51%", textAlign: "center" },
        hidden:this.props.user.userMdl.activeProgram != AppConst.activeProgramNas
        ? true : false
      },
      {
        dataField: "type",
        text: I18n.t('Table.reference'),
        hidden: isTMEAdminUser,
        sort: true,
        // formatter: this.notApplicableFormatter,
        style: { fontSize: "12px", width: "8.8%", textAlign: "center" },
        headerStyle: { width: "6.51%", textAlign: "center" }
      },
      {
        dataField: "action",
        isDummyField: true,
        text: "",
        formatter: this.buttonFormatter,
        style: buttonStyle,
        headerStyle: { width: "9%" }
      }
    ];
    const rowEvents = {
      onClick: (e, row, rowIndex) => {
        if (isNMSCAdminUser && e.target.checked !== undefined) {
          let subQuestionsChecked = this.state.subQuestionsChecked;
          if (subQuestionsChecked.includes(row.nmscSubQuestionId)) {
            var index = this.state.subQuestionsChecked.indexOf(row.nmscSubQuestionId)
            subQuestionsChecked.splice(index, 1);
            this.setState({ subQuestionsChecked: subQuestionsChecked });
            this.props.handleSavedSubQuestions(row.nmscSubQuestionId);
          }
          else {
            if (!this.state.subQuestionNonselectable.includes(row.nmscSubQuestionId)) {
              var index = this.state.subQuestionsChecked.indexOf(row.nmscSubQuestionId)
              subQuestionsChecked.push(row.nmscSubQuestionId);
              this.setState({ subQuestionsChecked: subQuestionsChecked });
              this.props.handleSavedSubQuestions(row.nmscSubQuestionId);
            }
          }
        }
      }
    }

    var selectRow = {
      mode: 'checkbox',
      clickToSelect: false
    }
    var uniqueKey = this.state.uniqueKey;

    if (isNMSCAdminUser) {
      selectRow.hideSelectColumn = false;
      selectRow.selected = this.state.subQuestionsChecked
      selectRow.nonSelectable = this.state.subQuestionNonselectable
      selectRow.onSelectAll = (isSelect, rows, e) => {
        let selectRow = this.state.subQuestionsChecked;
        if (isSelect) {
          rows.forEach((row) => {
            if (row.status === 'Saved' && !selectRow.includes(row.nmscSubQuestionId)) {
              selectRow.push(row.nmscSubQuestionId);
              this.props.handleSavedSubQuestions(row.nmscSubQuestionId);
            }
          });
        } else {
          rows.forEach((row) => {
            if (row.status === 'Saved' && selectRow.includes(row.nmscSubQuestionId)) {
              var index = selectRow.indexOf(row.nmscSubQuestionId);
              selectRow.splice(index, 1);
              this.props.handleSavedSubQuestions(row.nmscSubQuestionId);
            }
          });
        }
        this.setState({ subQuestionsChecked: selectRow });
        return selectRow;
      }
    } else {
      selectRow.hideSelectColumn = true;
    }
    const questionData = [...this.state.subQuestions];

    const libraryContainer = (
      <LibraryLink questionId={this.state.currentQuestion}
        selectLibraryLinks={this.selectLibraryLink}
        checkedFiles={this.state.selectedLibraryLinks}
        saveLibraryLink={this.saveLibraryLink} />
    );



    return (
      <div className={"mainQuestionContent  mainQuestionContent-"+(this.state.isNmsc ? 'isNmsc':'')} >
       {console.log("WhileCheck "+ this.state.activeTabNmscLang)}
        { (this.state.activeTabNmscLang !== 'TME') || !(this.props.user.userMdl.roles.includes("KET-TSP Auditor"))  ?
          <div className="mainQuestionTools">
           
            {subQuestionAccessType === "R/W"  ? (
              <a onClick={this.addMainQuestion}>
                <div title={I18n.t('tmeAdminMaster.add')} className="mainQueAdd" />
              </a>
            ) : (
                ""
              )}
            {this.state.subQuestionFlag === false &&
              subQuestionAccessType === "R/W" && (isTMEAdminUser || this.state.mainQuestionType && this.state.mainQuestionType !== "TME")  ? (
                <a onClick={() => this.deleteQuestion(isNMSCAdminUser)}>
                  <div title={I18n.t('tmeAdminMaster.delete')} className="delete-icon" />
                </a>
              ) : (
                ""
              )}

            {subQuestionAccessType === "R/W"  ? (
              <a
                onClick={() =>
                  this.addMainQuestion(this.state.mainQuestionDetails)
                }
              >
                <div title={I18n.t('tmeAdminMaster.edit')} className="edit-icon" />
              </a>
            ) : (
                ""
              )}
            <a>
              <div title={I18n.t('tmeAdminMaster.add')} className="mainQuestionnaire" />
            </a>
          </div> : null}
        <div className="mainQuestionSection">
          <div className="col-md-12">
            <div className="questionTitle">
              {I18n.t('questionnaire.question')}{" " + this.state.questionNo}
              {this.state.mainQuestionDetails.description}
            </div>
          </div>
          <div className="col-md-11 whySection">
            <div style={{ position: "relative" }}>
              <span
                onClick={() => {
                  this.setState({ openQuestion: !openQuestion });
                  this.toggleQuestion();
                }}
                aria-expanded={openQuestion}
              >
                <img
                  src={"../../assets/images//upArrow-" + this.props.theme.name + ".png"}
                  className={this.state.rotateQuestion + " mainQuestionArrow toyota"}
                  alt=""
                />
              </span>
              <span>
                <div className={"buttonHeading-" + this.props.theme.name}>
                  {I18n.t("questionnaire.why")}
                </div>
              </span>
            </div>
            <Collapse in={this.state.openQuestion} className="mainQuestionWhy">
              <div id="collapseText">
                <div className="collapseContainer">
                  {this.state.mainQuestionDetails.reason}
                </div>
              </div>
            </Collapse>
          </div>
          <div>
            {this.state.mainModal === true ? (
              <MainQuestion
                questionId={
                  this.state.modalFor && this.state.modalFor === "Edit"
                    ? this.state.currentQuestion
                    : 0
                }
                toolboxId={this.props.toolboxId}
                chapterId={this.state.chapterId}
                questionNumber={this.state.questionNumber}
                editQuestionNumber={this.state.editQuestionNumber}
                mainModal={this.state.mainModal}
                addMainQuestion={this.addMainQuestion}
                modalFor={this.state.modalFor}
                selectedQuestion={this.state.selectedQuestion}
                activeTabNmscLang={this.props.activeTabNmscLang}
                isNmsc={this.state.isNmsc}
              />
            ) : null}
          </div>
          <div className="col-md-11 librarySection">
            <div style={{ position: "relative" }}>
              <span
                onClick={() => {
                  this.setState({ openLibrary: !openLibrary });
                  this.toggleLibrary();
                }}
                aria-expanded={openLibrary}
              >
                <img
                  src={"../../assets/images//upArrow-" + this.props.theme.name + ".png"}
                  className={this.state.rotateLibrary + " mainQuestionArrow toyota"}
                  alt=""
                />
              </span>
              <span>
                <div className={"buttonHeading-" + this.props.theme.name}>
                  {I18n.t("questionnaire.library")}
                </div>
                {subQuestionAccessType === "R/W" && this.props.activeTabNmscLang !== 'TME' && !(this.props.user.userMdl.roles.includes("KET-TSP Auditor")) ? <span>
                  <a>
                    <div
                      title={I18n.t('tmeAdminMaster.add')}
                      onClick={() => this.addLibraryLink(null)}
                      className="mainQueAdd"
                    />
                  </a>
                  <a> <div title={I18n.t('tmeAdminMaster.delete')} onClick={() => this.onDeleteLibraryLink()} className="delete-icon" /> 
                  </a></span> : null}
              </span>
              <span />
            </div>
            <Collapse
              in={this.state.openLibrary}
              className="mainQuestionLibrary"
            >
              <div id="collapseText" className="libraryLinkContainer">
                <div className="collapseContainerLibrary">
                  {libraryContainer}
                </div>
              </div>
            </Collapse>
          </div>
          <div>
            {this.state.libraryModal === true ? (
              <AddLibraryFileModal
                questionId={this.state.currentQuestion}
                questionNo={this.state.currentQuestionNumber}
                toolboxId={this.props.toolboxId}
                chapterId={this.state.chapterId}
                mainModal={this.state.libraryModal}
                alreadyLinked={this.state.libraryLinks}
                addMainQuestion={this.addLibraryLink}
                modalFor={this.state.modalFor}
                activeTabNmscLang={this.props.activeTabNmscLang}
                isNmsc={this.state.isNmsc}
              />
            ) : null}
          </div>
        </div>

        <div className="subQuestionSection">
          <div className={"buttonHeadingWhat-" + this.props.theme.name}>
            {I18n.t("questionnaire.what")}{" "}
          </div>
          {subQuestionAccessType === "R/W" && this.props.activeTabNmscLang !== 'TME' && !(this.props.user.userMdl.roles.includes("KET-TSP Auditor")) ?
            <a>
              <div
                title={I18n.t('tmeAdminMaster.add')}
                onClick={() => this.toggle(null)}
                className="mainQueAdd subQueAdd"
              />
            </a> : ""}
        </div>
        <div className="subQuestionTable">
          <div className="subQuestiontable tmeTable" id={this.state.tmeTableTME}>
            <BootstrapTable
              ref="table"
              keyField={uniqueKey}
              data={this.state.subQuestions}
              columns={columns}
              selectRow={selectRow}
              rowEvents={rowEvents}
              hover
              condensed
            />
          </div>
        </div>
        <br />
        {isNMSCAdminUser === false  ? (
          <div className="publishPanel">
            <div className="col-xs-12 col-sm-12 col-md-12">
              {subQuestionAccessType === "R/W" && !this.props.user.userMdl.roles.includes("KET-TSP Auditor") ?
                <button
                  className={"button-" + this.props.theme.name}
                  style={{ fontSize: "1rem" }}
                  onClick={this.publish}
                >
                  {" "}
                  {I18n.t("tabsDetails.publish")}{" "}
                </button> : null}
              {this.state.nextPage ? (
                <a>
                  <div
                    title={I18n.t("questionnaire.nextQuestion")}
                    className={"nextQuestion-" + this.props.theme.name}
                    onClick={this.handleNextPage}
                  />
                </a>
              ) : (
                  <a>
                    <div
                      title={I18n.t("questionnaire.nextQuestion")}
                      className={"nextQuestion-disabled-" + this.props.theme.name}
                    />
                  </a>
                )}
              {this.state.previousPage ? (
                <a>
                  <div
                    onClick={this.handlePrevPage}
                    title={I18n.t("questionnaire.previousQuestion")}
                    className={"prevQuestion-" + this.props.theme.name}
                  />
                </a>
              ) : (
                  <a>
                    <div
                      title={I18n.t("questionnaire.previousQuestion")}
                      className={"prevQuestion-disabled-" + this.props.theme.name}
                    />
                  </a>
                )}
            </div>
          </div>
        ) : (
            ""
          )}
        <div>
        {console.log(this.state.selectedRow)}
          {this.state.modal === true ? (
            <SubQuestionnairModal
              modal={this.state.modal}
              toggle={this.toggle}
              modalFor={this.state.modalFor}
              selectedRow={this.state.selectedRow}
              questionId={this.props.selectedQuestionId}
              subQuestionNo={this.state.subQuestionNo}
              isNmsc={this.state.isNmsc}
              activeTabNmscLang={this.props.activeTabNmscLang}
            />
          ) : null}
        </div>
      </div>
    );
  }

  onDeleteLibraryLink = () => {
    if (this.state.selectedLibraryLinks.length <= 0) {
      toastr.error(I18n.t('library.selectFile'));
    }
    else {
      confirmAlert({
        customUI: ({ title, message, onClose }) => {
          return (
            <div className="deleteConfirmBox">
              <div className={"titleClass-" + this.props.theme.name}>
                Do You Want to delete Library Link?
                          </div>
              <div className="modal-footer" style={{ borderTop: "0px" }}>
                <button className={"buttonCancel-" + this.props.theme.name} onClick={() => onClose()}>
                  {I18n.t("standardMessages.no")}
                </button>
                <button
                  className={"button-" + this.props.theme.name}
                  onClick={() => {
                    this.deleteLibraryLink();
                    onClose();
                  }}
                >
                  {I18n.t("standardMessages.yes")}
                </button>
              </div>
            </div>
          );
        }
      });
    }
  }
  onDeleteNmscSubQuestionClick = row => {
    confirmAlert({
      customUI: ({ title, message, onClose }) => {
        return (
          <div className="deleteConfirmBox">
            <div className={"titleClass-" + this.props.theme.name}>
              {I18n.t("standardMessages.deleteConfirmSubQuestionNaire")}
            </div>
            <div className="modal-footer" style={{ borderTop: "0px" }}>
              <button
                className={"buttonCancel-" + this.props.theme.name}
                onClick={() => onClose()}
              >
                {I18n.t("standardMessages.no")}
              </button>
              <button
                className={"button-" + this.props.theme.name}
                onClick={() => {
                  this.deleteNmscSubQuestion(row.nmscSubQuestionId);
                  onClose();
                }}
              >
                {I18n.t("standardMessages.yes")}
              </button>
            </div>
          </div>
        );
      }
    });
  };

  importDisable = flag => {
    this.setState({ importDisable: flag });
  }
  onDeleteSubQuestionClick = row => {
    confirmAlert({
      customUI: ({ title, message, onClose }) => {
        return (
          <div className="deleteConfirmBox">
            <div className={"titleClass-" + this.props.theme.name}>
              {row.status.toUpperCase() == "SAVED" &&
                (!row.publishedOnDate ||
                  row.publishedOnDate === null ||
                  row.publishedOnDate === "")
                ? I18n.t("standardMessages.deleteConfirmSubQuestionNaire")
                : I18n.t("standardMessages.retireConfirmSubQuestionNaire")}
            </div>
            <div className="modal-footer" style={{ borderTop: "0px" }}>
              <button
                className={"buttonCancel-" + this.props.theme.name}
                onClick={() => onClose()}
              >
                {I18n.t("standardMessages.no")}
              </button>
              <button
                className={"button-" + this.props.theme.name}
                onClick={() => {
                  this.deleteSubQuestion(row.id);
                  onClose();
                }}
              >
                {I18n.t("standardMessages.yes")}
              </button>
            </div>
          </div>
        );
      }
    });
  };

  selectedTab = (index, label) => {
    this.props.dispatch({
      type: "NMSC_MAIN_QUESTION_CONTENT",
      payload: []
    });

    let activeTabNmscLang = label.split(" / ")[1];

    //if selected tab is TME Master then set default language as 'TME'
    if (label === 'TME Master') { activeTabNmscLang = 'TME' }

    this.setState({ selectedTab: index });
    this.props.activeNMSCTabLink(activeTabNmscLang);
    this.props.dispatch(getNmscQuestionSummary(this.props.toolboxId,
      this.props.user.userMdl.nmsc, activeTabNmscLang, null, null, true));
   console.log("Selected tab" +activeTabNmscLang);

    //this.props.dispatch(fetchNmscQuestionContent(this.props.questionId))

  };
  render() {
    if (this.props.theme.name === "lexus" && $('img.toyota')) {
      $(".mainQuestionArrow").removeClass("toyota");
      $(".mainQuestionArrow").addClass("lexus");
      $(".mainQuestionArrow").attr("src", "../../assets/images//upArrow-lexus.png");
    }
    else if (this.props.theme.name === "toyota" && $('img.lexus')) {
      $(".mainQuestionArrow").removeClass("lexus");
      $(".mainQuestionArrow").addClass("toyota");
      $(".mainQuestionArrow").attr("src", "../../assets/images//upArrow-toyota.png");
    } else {
      //..
    }

    var isNMSCAdminUser = false;
    if (this.props.user.userMdl.roles !== undefined) {
      var roles = this.props.user.userMdl.roles;
      for (var i = 0; i < roles.length; i++) {
        if (roles[i].toUpperCase().indexOf("KET NMSC ADMIN") !== -1) {

          isNMSCAdminUser = true;
          break;
        }
      }
    }
    const dynamicKPITab = this.props.user.userMdl.userLangList.map(
      (obj, index) => {
        return <Tab label={obj.country + " / " + obj.language} key={index} />;
      }
    ); {
      this.props.type === 'TME' ?
        dynamicKPITab.unshift(<Tab label="TME Master" key='tmeMaster' />) : ""
    }

    return (
      <div>
        {isNMSCAdminUser ? <Tabs
          onSelect={this.selectedTab}
          selected={this.state.selectedTab}
          defaultActiveKey={1}
          className={"nmscTabs " + this.props.theme.name}
        >
          {dynamicKPITab}
        </Tabs> : ""}
        {this.state.currentQuestion === "" ? (
          <div>
            <div className="col-md-12 mainQuestionTools ">
              {this.props.chapterId === "" ? (
                <a>
                  <div
                    title={I18n.t('tmeAdminMaster.add')}
                    className="add-icon mainQueAddDisabled"
                  />
                </a>
              ) : (
                  <a onClick={this.addMainQuestion}>
                    <div
                      title={I18n.t('tmeAdminMaster.add')}
                      className="add-icon mainQueAdd"
                    />
                  </a>
                )}
            </div>
            {this.state.mainModal === true ? (
              <MainQuestion
                questionId={
                  this.state.modalFor && this.state.modalFor === "Edit"
                    ? this.props.questionId
                    : 0
                }
                toolboxId={this.props.toolboxId}
                chapterId={this.props.chapterId}
                mainModal={this.state.mainModal}
                addMainQuestion={this.addMainQuestion}
                modalFor={this.state.modalFor}
                selectedQuestion={this.state.selectedQuestion}
                activeTabNmscLang={this.props.activeTabNmscLang}


              />
            ) : null}
          </div>
        ) : (
            this.getQuestionContent(isNMSCAdminUser)
          )}
        {(this.state.currentQuestion === "") ?
          <div className="emptyDiv" ><label style={{ marginLeft: '420px' }}> {I18n.t("standardMessages.noQuestion")}</label></div> : ""}
        {isNMSCAdminUser === true ? (
          <div className="publishPanel">


            <div className="col-xs-12 col-sm-12 col-md-12">

              {/* This to to hide publish button when 'TME Master' tab is seleted */}
              {this.props.activeTabNmscLang !== "TME" ?
                <button
                  className={"button-" + this.props.theme.name}
                  style={{ fontSize: "1rem" }}
                  onClick={this.Nmscpublish}
                >

                  {I18n.t("tabsDetails.publish")}
                </button> : ""
              }{this.props.type !== "NMSC" ?
                <button
                  className={"button-" + this.props.theme.name}
                  style={{ fontSize: "1rem" }}
                  disabled={this.state.importDisable}
                  onClick={this.importNMSCQuestion}
                >

                  {I18n.t("tabsDetails.import")}
                </button> : ''
              }


              {this.state.nextPage ? <a><div title={I18n.t("questionnaire.nextQuestion")} className={"nextQuestion-" + this.props.theme.name} onClick={this.handleNextPage}>
              </div></a> : <a><div title={I18n.t("questionnaire.nextQuestion")} className={"nextQuestion-disabled-" + this.props.theme.name}>
              </div></a>}
              {this.state.previousPage ? <a><div onClick={this.handlePrevPage} title={I18n.t("questionnaire.previousQuestion")} className={"prevQuestion-" + this.props.theme.name} >
              </div></a> : <a><div title={I18n.t("questionnaire.previousQuestion")} className={"prevQuestion-disabled-" + this.props.theme.name} >
              </div></a>}
            </div>
          </div>
        ) : (
            ""
          )}

        {isNMSCAdminUser ? (
          <StatCounter toolboxId={this.props.toolboxId}
            activeTabNmscLang={this.props.activeTabNmscLang}
            importDisable={this.importDisable}
            type={this.props.type} />
        ) : (
            ""
          )}
      </div>
    );
  }
}
const mapStateToProps = state => {
  return {
    questionContent: state.tabReducer.questionContent,
    subQuestions: state.tabReducer.subQuestions,
    libraryReducer: state.libraryReducer,
    chapterSequence: state.tabReducer.chapterSequence,
    modalState: state.tabReducer.closeModal,
    user: state.loginReducer,
    selectedQuestionId: state.questionSummaryReducer.selectedQuestionId,
    tabList: state.questionSummaryReducer.tabList,
    nmscQuestionContent: state.tabReducer.nmscQuestionContent,
    nmscSubQuestions: state.tabReducer.nmscSubQuestions,
    nmscSubQuestions: state.tabReducer.nmscSubQuestions,
    nmscChapterSequence: state.tabReducer.nmscChapterSequence
  };
};
export default withRouter(connect(mapStateToProps)(withTheme(QuestionContent)));
