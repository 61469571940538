import React from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import { connect } from 'react-redux';
import { Row, Col } from "reactstrap";
import { fetchPillarPointsCea,fetchAllPillarPointsCea,saveAllocatedPoints,updateAllocatedPoints } from "../../actions/leftNavAction.jsx";
import { withRouter } from 'react-router-dom';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import 'react-confirm-alert/src/react-confirm-alert.css' // Import css
import { withTheme } from '../../themes/theming';
import './index.css';
import { toastr } from 'react-redux-toastr';
import cellEditFactory  from 'react-bootstrap-table2-editor';
import AllocateWarning from "./allocateWarning"
import * as AppConst from "../../AppConstant";
var I18n = require('react-redux-i18n').I18n;

class PointsAllocation extends React.Component {
    /* eslint-disable */
    constructor(props) {
        super(props);
        this.state = {
            allocateFlag: false,
            editablePoints: true,
            toolBoxList:[],
            fromYearOption: {},
            toYearOption: {},
            pointAllocateDTOList : [],
            pillarToYrTotalPt:0,
            toPtTotal:0,
            flagForToYear:'',
        };
    }

    componentDidMount() {     
        let ceaDefaultYear = this.props.user.userMdl.ceaDefaultYears;
        let fromYearOption = ceaDefaultYear.value;
        let toYearOption = ceaDefaultYear.value + 1;
        this.setState({fromYearOption, toYearOption});
        let ceaYear = new Date();
        ceaYear = this.props.user.userMdl.ceaDefaultYears ? this.props.user.userMdl.ceaDefaultYears.value : ceaYear.getfullYear();
        this.props.dispatch(fetchPillarPointsCea(this.props.user.userMdl.activeProgramID, this.props.user.userMdl.flagCEA, ceaYear, toYearOption));
        this.props.dispatch(fetchAllPillarPointsCea(this.props.user.userMdl.activeProgramID, this.props.user.userMdl.flagCEA, toYearOption));
    }

    componentWillReceiveProps(nextProps) {           
        if (nextProps.pillarData.pillarToolboxData) {
            if(nextProps.pillarData.pillarToolboxData.length){
                let selectedObj = {}
                let pointList = []
                nextProps.pillarData.pillarToolboxData.map(obj=>{
                    selectedObj = {
                        programID: this.props.user.userMdl.activeProgramID,
                        toolboxId: obj.toolboxId,                
                        points: obj.status !==undefined ? parseInt(obj.toPoints) : parseInt(obj.fromPoints),
                        year: this.state.toYearOption,
                        id: obj.id
                    }
                    pointList.push(selectedObj);
                })
                this.setState({ 
                    flagForToYear : nextProps.pillarData.pillarToolboxData[0].status,                                
                    toolBoxList: nextProps.pillarData.pillarToolboxData, pointAllocateDTOList: pointList });
            }            
        }
        if (nextProps.pillarData.pillarToYrTotalPt) {
            this.setState({ pillarToYrTotalPt: nextProps.pillarData.pillarToYrTotalPt })
        }
    }
   
    toPoint = (cell, row, rowIndex) => {
        return (
            <input
                type="text"
                defaultValue={(cell && cell !== undefined) ? cell : 0}
                onChange={e => this.onChangeTextBox(e, row, cell)}
                className="form-control"
                onKeyDown={e => this.autoTab(e, row, cell)}
                onBlur={e => this.onTabChange(e, row, cell)}
                disabled={!this.state.editablePoints || (this.state.flagForToYear!==undefined 
                    && this.state.flagForToYear==='SUBMITTED')? true : false}
            />
        );
      };

    onChangeTextBox = (e, row, cell) => {
    }

    autoTab = (e, row, cell) => {
        if (e.keyCode === 9) {
          this.onTabChange(e, row, cell)
        }
    };  

    onTabChange = (e, row, cell) => {
        let val = e.target.value.trim();
        var Number = /^[\d\/\(\)\- \.]+$/;
        if (Number.test(val) || val === "") {
            e.target.value = val  
            if(val === ""){
                val = 0;
            }
            row.toPoints = parseInt(val);      
            this.state.pointAllocateDTOList.map(obj=>{
                if(obj.toolboxId === row.toolboxId){
                    obj.points = parseInt(val)
                }
            })
        }else{
            toastr.error(I18n.t("CeaKpi.pointIncorrect")); 
            e.target.value = row.toPoints
        }
    };

    
    onClickAllocate = () => {
        let toPtTotal = this.getPointsCout()
        this.setState({ allocateFlag: !this.state.allocateFlag, toPtTotal:toPtTotal });
    }

    onClickAllocateSave = () => {
        this.setState({ editablePoints: false, allocateFlag: false,});
        this.commonSave(this.state.toPtTotal,'SUBMITTED')
    }

    onClickSave = () => {
        let toPtTotal = this.getPointsCout()
        this.commonSave(toPtTotal,'SAVED')
    }

    commonSave=(toPtTotal,status)=>{
        let savePointAllocateDTO = {
            userId :  this.props.user.userMdl.username,
            status : status,
            pointAllocateDTOList : this.state.pointAllocateDTOList
        }
        let extraDataObj = {
            activeProgramName : this.props.user.userMdl.activeProgramName,
            activeProgramID : this.props.user.userMdl.activeProgramID,
            pillarToYrTotalPt : this.state.pillarToYrTotalPt,
            toPtTotal : toPtTotal,
            fromYearOption :this.state.fromYearOption,
            toYearOption :this.state.toYearOption
        }
        if(this.state.flagForToYear!==undefined 
            && this.state.flagForToYear==='SAVED'){
            this.props.dispatch(updateAllocatedPoints(savePointAllocateDTO,extraDataObj))
        }else{
            this.props.dispatch(saveAllocatedPoints(savePointAllocateDTO,extraDataObj))
        }                
    }

    getPointsCout = () =>{
        let total = 0;
        this.state.pointAllocateDTOList.map(obj=>{
            total = parseInt(total) + parseInt(obj.points)
        })
        return total;
    }

    render() {    
        let flagCheck = !this.state.editablePoints || (this.state.flagForToYear!==undefined 
            && this.state.flagForToYear==='SUBMITTED') ? true : false;
        var columns = [{
            dataField: 'toolboxName',
            text: I18n.t('ceaEvaluation.businessFunction'),
            editable: false,
            sort: true,
            style: { width: "50%", textAlign: "left"},
            headerStyle: { width: "50%", textAlign: "left" },
        }, {
            dataField: 'fromPoints',
            text: this.state.fromYearOption +' (From)',
            editable: false,
            style: { width: "24%", textAlign: "center" },
            headerStyle: { width: "24%", textAlign: "center" }
        }, {
            dataField: 'toPoints',
            text: this.state.toYearOption + ' (To)',
            editable: flagCheck ? false : true,
            formatter: this.toPoint,
            style: { width: "24%", textAlign: "center" },
            headerStyle: { width: "24%", textAlign: "center" }
        }];

        return(<div>
            <div className = "col-md-12 listDealerContainer">
                <Col md="12" className="activityTableContainer">
                    <div className="targetSetting activity-content pointAllocate">
                        <div className="col-md-12 row control-label noPadding">
                            <div className="col-md-12">                                        
                                <BootstrapTable
                                    keyField="toolboxId"
                                    noDataIndication={I18n.t('ceaActionPlan.nodata')}
                                    data={this.state.toolBoxList}
                                    columns={columns}
                                    cellEdit={cellEditFactory({
                                        beforeSaveCell(oldValue, newValue, row, column, done) {                                           
                                            return { async: true };
                                        }
                                    })}
                                />
                            </div>
                        </div>
                    </div>
                    <Row>
                        <div className="col-md-12 col-xs-12 col-sm-12 col-lg-12">
                            <Col md="2" style={{ marginTop: '2px', float: "Right", width: "auto" }}>
                                <button className={"button-" + this.props.theme.name} 
                                    disabled={flagCheck ? true : false}
                                    onClick={() => flagCheck ? {} : this.onClickAllocate() }>
                                    {I18n.t('ceaActionPlan.allocate')}
                                </button>
                            </Col>
                            <Col md="2" style={{ marginTop: '2px',marginLeft: '-15px', width: "auto" }}>
                                <button className={"button-" + this.props.theme.name}       
                                    disabled={flagCheck ? true : false}
                                    onClick={() => flagCheck ? {} : this.onClickSave() }>  
                                    {I18n.t('ceaActionPlan.save')}
                                </button>
                            </Col>
                        </div>
                    </Row>
                </Col>                 
            </div>
            {this.state.allocateFlag ? 
                <AllocateWarning
                    warningMsg = {this.state.toPtTotal + AppConst.pointMsg1 + this.props.user.userMdl.activeProgramName + AppConst.pointMsg2 + 
                        parseInt(this.state.toPtTotal) + parseInt(this.state.pillarToYrTotalPt) + AppConst.pointMsg3 }
                    pillarToYrTotalPt = {this.state.pillarToYrTotalPt}
                    activeProgramName={this.props.user.userMdl.activeProgramName}
                    toPtTotal = {this.state.toPtTotal}
                    allocateModalNo = {this.onClickAllocate}
                    allocateModalYes= {this.onClickAllocateSave }
                    confirmtxt = {I18n.t("ceaActionPlan.yes")}
                    cancelTxt = {I18n.t("ceaActionPlan.no")}
                />
            : ""}
        </div>);
    }
}
const mapStateToProps = (state) => {
    return {
        user: state.loginReducer,
        pillarData: state.leftNavReducer,
    }
}
export default withRouter(connect(mapStateToProps)(withTheme(PointsAllocation)));