const translationsUK = {
  uk: {
    Policy: {
      HeaderMsg: "Повідомлення про конфіденційність для обробки Ваших персональних даних в рамках КЕТ",
      Message1: "Це Повідомлення про конфіденційність стосується обробки Ваших персональних даних в рамках КЕТ. З ним завжди необхідно ознайомлюватись разом із Загальною Політикою Toyota про Конфіденційність та Персональні дані, в якій описуються загальні способи обробки Ваших Персональних Даних, та які мають бути прийняті Вами для продовження далі.",
      Message2: "Компанія 'Toyota Motor Europe NV / SA', головний офіс якої знаходиться за адресою Бельгія, Брюссель, 1140, Авеню дю Бурже, 60, виступаючи у якості Володільця Даних, збирає Ваші персональні дані (як вказано нижче) з метою, що описано нижче.",
      Message3: "Які саме Ваші персональні Дані будуть збиратись?",
      Message4: "Ми збиратимемо наступну(і) категорію(ї) Ваших Персональних Даних відносно до КЕТ:",
      Table1: {
        tr1: "Категорія(ї) Персональних Даних:",
        tr2: "Обліковий запис користувача",
        tr3: "Повне ім'я користувача",
        tr4: "Бренд (Toyota, Lexus)",
        tr5: "Вибір мови",
        tr6: "Дочірня організація"
      },

      Message5: "Ціль та законні підстави обробки Ваших персональних даних",
      Message6: "Ми оброблятимемо Ваші Персональні Дані в цілях та на законних підставах, що наведено нижче:",
      Table2: {
        tr1: {
          th1: "Ціль:",
          th2: "Законна підстава:"
        },
        tr2: {
          td1: "КЕТ - 1: Кайзен діяльності Дилера",
          td2: "Законний інтерес, що переслідується Володільцем(ями) Даних"
        },
        tr3: {
          td1: "КЕТ - 2: ІТ-Підтримка",
          td2: "Законний інтерес, що переслідується Володільцем(ями) Даних"
        }
      },
      Message7: "Тривалість утримання Ваших Персональних Даних",
      Message8: "Ваші Персональні Дані будуть зберігатись із вказаною нижче тривалістю:",
      Table3: {
        tr1: {
          th1: "Період утримання:",
          th2: "Категорія(ї) Персональних Даних:"
        },
        tr2: {
          td1: "Будь-яка персональна інформація, як вказано вище, буде утримуватись щонайбільше 5 років + 1 місяць з дня остаточного припинення КЕТ",
          td2: "Ім'я, Прізвище Користувача, вибір мови, організація"
        }
      },
      Message9: "Опціонально: у разі розголошення/передачі Ваших персональних даних в країни поза межами Європейської Економічної Зони (ЄЕЗ)",
      Message10: "Ваші персональні дані будуть доступні отримувачам в країнах поза межами Європейської Економічної Зони як вказано нижче:",
      Table4: {
        tr1: {
          td1: "Ідентичність",
          td2: "Країна",
          td3: ""
        },
        tr2: {
          td1: "Отримувач з країни поза межами ЄЕЗ",
          td2: "Інформаційна система",
          td3: "Індія"
        }
      },
      Message11: "Цим підтверджую, що розумію дане повідомлення; я ознайомився та прийняв Загальну Політику Toyota про Конфіденційність та Персональні дані"
    },
    ACCESSORIES: "Аксесуари",
    activity: {
      Assignee: "Уповноважена особа",
      CompletionDate: "Дата завершення",
      Dealer: "Дилер",
      FirstVisit: "Перший візит",
      kpi: {
        kpi: "КПД повинні бути числа (додатне або від'ємне число) ",
        kpiLength: "Максимальна довжина КПД - 10 символів",
        mandatory: "Введіть припустимі дані в усі обов’язкові поля",
        mandatoryTargets: "Будь ласка, введіть усі показники.",
        target: "Цілі мають бути числовими",
        targetLength: "Максимальна довжина цілі - 10 символів"
      },
      ReminderOn: "Нагадування",
      statusDD: "Статус",
      Toolbox: "Модуль",
      activityCreation: "Розпочався процес створення Активності",
      activityCreated: "Активність(ності) була створена"
    },
    DPOK: "Запасні частини (DPOK)",
    EFFICIENCY: "Ефективність",
    FIX_IT_RIGHT: "Fix It Right",
    footer: {
      message: "Обов’язкові поля"
    },
    FOUNDATION: "Базові стандарти",
    MAINTENANCE_REMINDERS: "Нагадування про ТО",
    EMPLOYEE_ENGAGEMENT: "Employee Engagement",
    CUSTOMER_CENTRICITY: "Customer Centricity",
    TOYOTA_WAY: "Toyota Way",
    BUSINESS_SUSTAINABILITY: "Business Sustainability",
    FUNDAMENTALS: "Базові елементи",
    FIX_IT_RIGHT_BnP: "Fix it Right (B&P)",
    UTILIZATION_AND_EFFICIENCY: "Utilization and Efficiency",
    CUSTOMER_EXPERIENCE: "Customer Experience",
    UPSELLING_OPPORTUNITIES: "Upselling Opportunities",
    MANAGING_CAPACITY: "Managing Capacity",
    VEHICLE_TRADE_IN: "Vehicle Trade-In",
    STOCK_MANAGEMENT: "Stock Management",
    REFURBISHMENT: "Refurbishment",
    months: {
      apr: "Квіт.",
      aug: "Серп.",
      dec: "Груд.",
      feb: "Лют.",
      jan: "Січ.",
      jul: "Лип.",
      jun: "Черв.",
      mar: "Бер.",
      may: "Трав.",
      nov: "Лист.",
      oct: "Жовт.",
      sep: "Вер."
    },
    others: {
      back: "Назад",
      closed: "Закрите",
      completed: "Виконане ",
      countryLanguageCode: "Код країни або мови ",
      createActivity: "Створити завдання",
      createdDate: "Дата створення",
      dataUneditableNote:
        "Примітка: Збережені дані не можна змінити. Щоб внести зміни в дані, зверніться до адміністратора дистриб'ютора.",
      date: "Дата",
      downloadQuestionnaire: "Завантажити Перевірочний лист",
      editActivity: "Змінити завдання",
      initialHelpText: "Як початкове значення КПД слід указати середнє значення за попередні шість повних місяців",
      inProgress: "Триває",
      name: "П. І. Б.",
      next: "Далі",
      noActivities: "Завдань не знайдено",
      noResults: "Результатів не знайдено",
      of: "з",
      ongoing: "Поточне",
      open: "Відкрити",
      page: "Сторінка",
      planned: "Заплановане",
      problemDescription: "Опис проблеми",
      signature: "Підпис",
      select: "Обрати",
      bulkAction: "Bulk Action",
      bulkActions: "Bulk Actions"
    },
    ServerMessage: {
      error_message: "Під час завантаження виникла помилка.",
      no_published_subquestion: "Щоб розпочати цю Задачу, будь ласка, опублікуйте Опитувальний лист для цього модуля.",
      activity_upload_error_msg: "Помилка при завантажені Подробиць Діяльності",
      action_plan_created: "План дій створено",
      action_plan_deleted: "План дій видалено",
      action_plan_saved: "План дій збережено",
      activity_kpi_saved: "Ключові показники діяльності збережено",
      master_kpi_confirm_delete:
        "Впевнені, що хочете видалити обрані КПД?",
      master_kpi_created: "Основний КПД створено",
      master_kpi_deleted: "Основний КПД видалено",
      master_kpi_not_found: "Основний КПД не знайдено",
      master_kpi_published: "Обрані основні КПД опубліковано",
      master_kpi_retired: "Основний КПД відмінено",
      master_kpi_saved: "Основний КПД збережено",
      subquestion_created: "Підпитання було вдало збережено.",
      subquestion_updated: "Підпитання було вдало оновлено.",
      subquestionnaire_not_found: "Підпитання не було знайдено.",
      subquestion_retired: "Підпитання було виключено.",
      subquestion_deleted: "Підпитання було видалено.",
      subquestion_published: "Підпитання були вдало опубліковані.",
      mainquestion_created: "Основний Опитувальний лист було створено.",
      mainquestion_deleted: "Основний Опитувальний лист було видалено.",
      mainquestion_updated: "Основне питання було оновлено.",
      duplicateMsg: "Задублювати записи в файлі Excel",
      deleteErr: "Помилка під час видалення файлу",
      toolbox_deleted: "Операційний модуль успішно видалено",
      toolbox_already_exists: "Цей операційний модуль вже існує.",
      toolbox_saved: "Операційний модуль збережено",
      toolbox_created: "Операційний модуль створено",
      toolbox_not_found: "Операційний модуль не знайдено",
      published_content_found: "Цей модуль не можна видалити, він містить опублікований контент",
      dealers_already_mapped: " Обрані дилери вже були розподілені на цього користувача. ",
      dealer_already_mapped: " Dealer already mapped ",
      dealer_added: "  Дилера додано. ",
      dealers_added: "  Дилера додано. ",
      total_dealers_mapped_now: "Загальна кількість дилерів, яка віднесена до цього користувача, це . ",
      no_dealer_available: "Обраному критерії не відповідає жодний з дилерів.",
      proper_dealer_selected: "Будь ласка, оберіть відповідного дилера.",
      user_referenced_ongoing_activity: "Користувачу зараз призначено Активності, що виконуються. Замініть Відповідального за цими Активностями і спробуйте ще. ",
      want_to_remove_mapping: "Ви впевнені, що хочете видалити налаштування користувача для цих дилерів?",
      user_mapping_removed: "Обраних дилерів було успішно видалено з налаштувань користувача.",
      dealer_closed_in_A2D: "Цього дилера зареєстровано як 'Закритий' в A2D.",
      network_level_not_found: "Будь ласка, оберіть вірну комбінацію рівнів мережі відповідно до налаштування вашої мережі в КЕТ.",
      duplicate_dealer_uuid: "Дилер вже існує в базі даних КЕТ.",
      dealer_created: "Дилера створено.",
      dealer_updated: "Інформацію про цього дилера було оновлено.",
      dealer_not_present: "Цього дилера не зареєстровано в A2D.",
      dealer_uuid_other_nmsc: "Обраний код A2D uuid не є дійсним для вашої мережі.",
      target_kpi_updated: "The KPI's status have been updated successfully.",
      target_updated: "The KPI's target have been updated successfully.",
      kpi_launched: "The KPI's bulk launch successfully.",
      targetUpdate:"Application doesn't allow target to be modified for accepted status.",
    },
    standardMessages: {
      delete: "Ви впевнені, що хочете видалити?",
      addActionPlanNotAllowed: "План дій не може бути створено або змінено після того, як Діяльність Закрита, Завершена або Відхилена.",
      addToolboxNotAllowed: "Cannot create more than 19 toolbox",
      britRejectMsg: "Ви впевнені, що хочете Відхилити цю Задачу? Після відхилення зміни будуть неможливі.",
      rejectMsg: "Ви впевнені, що хочете Відхилити цю Задачу? Після відхилення зміни будуть неможливі.",
      selectNMSCTab: "Будь ласка, оберіть мову країни щоб опублікувати",
      all: "Усе",
      deleteConfirm: "Дійсно бажаєте видалити обраний КПД?",
      no: "Ні",
      retireConfirm:
        "Дійсно бажаєте скасувати цей КПД? Після скасування цей КПД не можна буде знов активувати",
      warning: "Попередження",
      yes: "Так",
      saveDataConfirmation: "Впевнені зберегти ці дані?",
      saveKPIWarning: "Примітка. Збережені дані не можна редагувати. Щоб внести зміни в дані, зверніться до адміністратора KET від дистриб'ютора",
      addKpiNotAllowed: "Внесення КПД наступного місяця буде додано після закінчення поточного місяця",
      activityLaunch: "Ви впевнені, що хочете розпочати виконаня? Після початку, Діяльність не може бути видалено або повернено до статусу 'Заплановано'.",
      deleteConfirmSubQuestionNaire: "Ви впевнені, що хочете видалити обране підпитання?",
      retireConfirmSubQuestionNaire: "Ви впевнені, що хочете виключити обране підпитання?",
      deleteConfirmMainQuestion: "Будь ласка, підтвердіть видалення Основного питання та пов'язаних із ним підпитань.",
      deleteConfirmChapter: "Ви впевнені, що хочете видалити розділ?",
      deleteLibraryFolder: "Ви впевнені, що хочете видалити цю папку?",
      deletePublishedFolder: "Деякі файли з цієї папки можуть мати посилання на Опитувальний лист. Ви впевнені, що хочете остаточно видалити цю підпапку та посилання з Опитувального листа?",
      importQuestion: "Ви впевнені, що хочете імпортувати це підпитання? Поточне підпитання буде замінено.",
      noQuestion: "Питання Відсутні",
      MandatoryWarning: "Заповніть, будь ласка, усі обов'язкові поля для всіх місцевих мов.",
      certificationSubmission:
        "Надіслано запит до ТМЕ для сертифікації дилера за операційним модулем",
      sameToolBox: "Ви не можете надсилати запит за одним модулем більше одного разу. Будь ласка, оберіть інший",
      deleteConfirmDealer: "Are you sure you want to delete this dealer ?",
      updateVcConfirmDealer: "Усі Активності, створені для дилера у \"Ланцюжку цінності\", будуть видалені. Ви впевнені, що бажаєте продовжити?",
      updateBritConfirmDealer: "Усі Активності, створені для дилера у \"BRIT\", будуть видалені. Впевнені, що бажаєте продовжити?",
      completed_activity_delete:"Дані не будуть доступні після видалення. Впевнені, що бажаєте видалити?",
      deleteAllQuestionary: "Are you sure you want to delete the entire Questionnaire?",
      deleteAllLibraries: "Are you sure you want to delete all folders and files in the Library?",
      selectAll: "Select All",
      unSelectAll: "Unselect All",
      select:"Select...",
      warningMsg:"There are Open Action plan(s) in this activity. Would you like to Complete the activity?"
    },
    status: {
      published: "Опубліковано",
      retired: "Скасовано",
      saved: "Збережено",
      rejected: "Відхилено",
      open: "Відкрити",
      closed: "Закрите",
      close: "Закрите",
      ongoing: "Поточне",
      completed: "Виконане",
      complete: "Виконане",
      planned: "Заплановане",
      overdue: "Прострочений",
      due: "Актуальний",
      pending: "Pending",
    },
    Rejected: "Відхилено",
    Open: "Відкрити",
    Closed: "Закрите",
    Close: "Закрите",
    Ongoing: "Поточне",
    Completed: "Виконане",
    Complete: "Виконане",
    Planned: "Заплановане",
    tabDetails: {
      noActionAvailable: "Немає доступних дій",
      activeDealer: "Цього дилера поточно зареєстровано як Активного в A2D і його не можна видалити.",
      dealerDeleted: "Dealer is deleted."
    },
    Table: {
      action: "",
      activity: "Дія",
      activityCompletionDate: "Дата завершення виконання",
      activityCompletionDateFrom: "Дата завершення дії з",
      activityCompletionDateTo: "Дата завершення дії до",
      activityStartDate: "Дата початку виконання",
      activityStartDateFrom: "Дата початку дії з",
      activityStartDateTo: "Дата початку дії до",
      assignedTo: "Кому призначено",
      assignee: "Уповноважена особа",
      benchmark: "Орієнтир",
      city: "Місто",
      completedBy: "Дата виконання ",
      completionBy: "Завершення не пізніше",
      completionDate: "Дата завершення",
      counterMeasure: "Коригувальна дія",
      country: "Країна",
      createdBy: "Автор",
      dealer: "Дилер",
      dealerNameAD: "Назва дилера (A2D)",
      dealerName: "Дилер",
      definition: "Визначення",
      description: "Опис",
      dueDate: "Кінцевий термін",
      endDate: "Дата завершення",
      firstVisit: "Перший візит",
      followup: "Подальші дії",
      franchise: "Франшиза",
      history: "Історія",
      id: "Ідентифікатор",
      initial: "Початкове значення",
      kpi: "КПД",
      launch: "Запустити",
      mandatory: "Обов’язково",
      nmsc: "Дистриб'ютор",
      problem: "Проблема",
      publishedOn: "Дата опублікування",
      questionReference: "Питання",
      reference: "Посилання",
      reminderOn: "Нагадування",
      score: "Оцінка",
      startDate: "Дата початку",
      status: "Статус",
      target: "Target",
      toolbox: "Модуль",
      view: "Переглянути",
      kpiResult: "Результати по КПД",
      nmscTarget: "NMSC Target",
      penEbenchmark: "PanE Benchmark",
      kpiField: "KPI",
      location: "Місцезнаходження",
      ActionItemReport: "Заплановані дії, що виконуються",
      certification: "Стан Сертифікації",
      noOfToolbox: "Кількість Завершених Операційних модулів",
      program: "Програма",
      certificationDate: "Дата Сертифікації",
      activityId : 'Номер Активності',
      approvedBy:"Approved By",
      kpis:"KPIs",
      lastmonthKpis:"Last Month KPIs",
      questionPercent:"Completion %",
      actionPlan: "План дій",
      monthlyKpi:"Monthly KPIs",
      initialKpi:"Initial KPIs",
      targetKpi:"Target KPIs",
      },
      tabsDetails: {
      actionPlan: "План дій",
      confirmDeleteMessage: "Дійсно бажаєте видалити обраний КПД?",
      confirmRetireMessage: "Підтвердіть відміну обраного КПД, оскільки його вже опубліковано",
      errorRetireMsg: "Це активний обов’язковий КПД TME, тому його не можна скасувати",
      import: "Імпортувати",
      importKpiMessage: "Внесіть ваші КПД зі списку основних КПД TME",
      kpi: "Ключові показники діяльності (КПД)",
      library: "Бібліотека",
      noActionAvailable: "Немає доступних дій",
      NoKPI: "Немає доступних КПД",
      publish: "Опублікувати",
      questionnaire: "Перевірочний аркуш",
      tmeKPIList: "Перелік основних КПД TME",
      toyotaCityBrussels: "TOYOTA CITY BRUSSELS",
      attachment: "Attachment",
      attachments: "Attachment(s)",
      uploadAttachment: "Upload Attachment",
      noAttachment: "No attachments available",
    },
    tmeAdminMaster: {
      ToolboxName: "Назва модуля",
      newToolboxName: "Нова назва",
      deleteToolbox: "Видалити модуль?",
      programName: "Назва програми",
      ToolboxName_EN: "Назва модуля (англ.)",
      deleteTool: "Ви впевнені, що хочете видалити цей модуль?",
      selectToolbox: "Будь ласка, оберіть модуль, який хочете видалити.",
      editToolBox: "Редагувати модуль",
      addToolbox: "Додати модуль",
      toolbox: "Будь ласка, введіть назву цього Модуля",
      addActionPlan: "Add План дій",
      actionPlanItem: "План дій – номер пункту",
      add: "Додати",
      addActivity: "Додати завдання",
      additionalInformation: "Додаткова інформація",
      benchmark: "Орієнтир",
      cancel: "Скасувати",
      createEditActivity: "Створити/змінити завдання",
      createKPI: "Створити КПД",
      delete: "Видалити",
      download: "Завантажити",
      edit: "Змінити",
      editKPI: "змінити КПД",
      keyPerformanceIndicator: "Ключовий показник діяльності (КПД)",
      kpiDefinition: "Визначення КПД",
      mandatoryKpi: "Обов’язковий КПД",
      save: "Зберегти",
      search: "Пошук",
      unit: "Одиниця вимірювання",
      update: "Оновити",
      validData: "Введіть дійсні дані",
      hide: "Сховати",
      show: "Відобразити",
      upload_dealer_network: "Завантажити дилерську Мережу",
      upload_question: "Завантажити Опитувальний лист",
      upload_user: "Завантажити Дані Користувача",
      upload_activity: "Завантажити Діяльності",
      remove: "Remove",
      toyota: "Toyota",
      lexus: "Lexus",
      launch: "Launch",
      close: "Close",
      generate: "Generate",
      dueDateExceed:"Due date exceeded",
      delQuestionnaire:"Delete questionnaire"
    },
    toastr: {
      activityStatus_condition: "Щоб закрити цю Задачу, будь ласка, дайте відповідь на усі питання.",
      emptyTarget: "Please enter the NMSC target to publish the KPI",
      retailerActual: "Please enter the Retailer Actual",
      initialTargetMonthly: "Please enter the initial KPI measurement, the KPI targets and at least 1 month of measurement to continue.",
      initialTarget: "Щоб продовжити, введіть, будь ласка, первинні і цільові КПД.",
      upload_error: "Помилка при завантаженні Дилерської Мережі",
      action_item_create_warning: "В межах однієї дії може бути об'єднано щонайбільше 5 записів.",
      actionPlanStartDateValidation: "Дата завершення не може передувати даті початку",
      actionsNotClosed: "План дій містить незавершені дії; перш ніж закривати завдання, закрийте всі дії",
      activityDuplicate: "Уже існує інше завдання для такого ж дилера та модуля",
      assigneeNotExist: "Призначеного виконавця не зареєстровано в системі",
      assigneeValidation: "Неприпустимий виконавець",
      atleastOnePublish: "Виберіть принаймні один КПД для публікації",
      cannotRetireActive: "Цей активний обов’язковий КПД TME не можна скасувати ",
      completionDate: "Дата виконання не може передувати даті першого візиту",
      confirDeletePlannedActivity: "Дійсно бажаєте видалити це заплановане завдання?",
      DateformatValidation: "Дата має бути у форматі ДД/ММ/РРРР",
      DateValidation: "Дата початку має передувати даті закінчення",
      dealerValidation: "Неприпустимий дилер",
      deleteSelectedConfirm: "Дійсно бажаєте видалити обраний КПД?",
      duplicate: "Вже існує інший КПД із такою самою назвою і визначенням",
      modalMandatory: "Введіть припустимі дані в усі обов’язкові поля",
      noPublishKpi: "Для вибраного модуля ще не опубліковано КПД",
      validSearchCriteria: "Введіть припустимі умови пошуку",
      multiSelectPlaceHolder: "Введіть щонайменше 5 символів",
      multiSelectPlaceHolderUser: "Введіть щонайменше 3 символів",
      startDateValidation: "Дата початку не може бути більше, ніж дата закінчення",
      fileSelectionErr: "Оберіть, будь ласка, файл спочатку",
      atleastOneQuestion: "Будь ласка, оберіть принаймні одне підпитання для імпорту.",
      atleastOneSubQues: "Будь ласка, оберіть принаймні одне підпитання для публікування.",
      uploadErr: "Кількість файлів, що не були завантажені:",
      uploadSuccess: "Дилерську Мережу успішно завантажено.",
      file_upload_success: "Файл(и) успішно завантажено.",
      KPIinOngoingClosedActivity: "Цей показник присутній в одній або більше поточних/закритих Активностях. Будь ласка, завершіть ці Активності перед його зміною.",
      Role_Error: "Налаштування користувача не завершено, зв'яжіться з адміністратором КЕТ дистриб'ютора.",
      points_allocation_incorrect: "Невірне нарахування баллів, будь ласка, змініть.",
      activity_deleted:"Активність успішно видалено",
      activity_certified_dealer:"Дилера було сертифіковано за обраною Активністю, її не можна видалити.",
      activity_submited_for_certificate_dealer:"Цю Активність було додано до запиту на Сертифікацію, що надіслано до ТМЕ, тому статус не можна змінити."
    },
    TopNav: {
      ActivityLog: "Завдання",
      ApplicationName: "Kaizen Expert Tool",
      Home: "На головну",
      logout: "Вийти",
      MasterDataUpload: "Передати основні дані",
      Toolbox: "Операційний модуль",
      welcome: "Вітаємо",
      Admin: "Адміністратор"
    },
    questionnaire: {
      addSubQuestion: "Додати підпитання",
      editSubQuestion: "Редагувати підпитання",
      subQuestionTitle: "Підпитання",
      additionalInfo: "Додаткова інформація",
      na: "Додати 'Не застосовується' як можливу відповідь на це питання",
      question: "Питання",
      questionTitle: "Назва питання",
      why: "Чому?",
      addMainQuestion: "Додати Основне питання",
      editMainQuestion: "Редагувати Основне питання",
      previousQuestion: "Попереднє питання",
      nextQuestion: "Наступне питання",
      what: "Що?",
      library: "Бібліотека",
      subQuestionTable: {
        no: "Ні",
        subQuestion: "Підпитання",
        status: "Статус",
        publishedDate: "Дата публікації",
        notApplicable: "Не застосовується"
      },
      links: {
        question_link_button: "Посилання",
        question_link_include_folder: "Включити підпапку",
        question_link_add_link: "Зробити посилання на файл(и) з Бібліотеки",
        question_link_view_folder: "Переглянути Бібліотеку"
      },
      mainQuestion: "Нові/Оновлені Основні Питання:",
      subQuestion: "Нові/Оновлені Підпитання:",
      retiredQuestion: "Виключені Підпитання:",
      libraries: "Нові/Оновлені файли бібліотек:",
      noFiles: "Файли відсутні."
    },
    library: {
      selectFile: "Оберіть, будь ласка, принаймні один файл",
      fileSelected: "Файли обрано",
      publish_library_file: "Оберіть, будь ласка, принаймні один файл для публікації",
      library_files_deleted: "Ви впевнені, що хочете видалити цей файл?",
      library_files_linked_deleted: "Ви впевнені, що хочете видалити цей файл і посилання на нього в Опитувальному листі?",
      max_size_error: "Розмір файлів перевищує обмеження завантаження у 1 Гб.",
      empty_file_desc: "Вкажіть, будь ласка, файл",
      file_not_selected: "Оберіть, будь ласка, файл(и) до завантаження (макс. 1 Гб)",
      file_type_error: "Такий тип файлу не прийнятний.",
      file_name_error: "Неприпустимий символ у назві файлу.",
      file_name_lenght_error: "Назва файлу може містити не більше 50 символів.",
      addFolder: "Додати Папку",
      editFolder: "Редагувати Папку",
      currentFolder: "Назва Папки",
      parentFolder: "Назва Загальної Папки",
      emptyFolderName: "Назва Папки не може бути порожньою.",
      invalidFolderName: "Назва папки не може містити '/'",
      folder_created: "Папку було вдало створено.",
      folder_updated: "Папку було вдало оновлено.",
      folder_not_found: "Папку не знайдено",
      lib_invalid_toolbox: "Обрано невірний Модуль",
      lib_folder_already_exists: "Така Папка вже існує.",
      lib_folder_deleted: "Папку було вдало видалено.",
      library_files_published: "Файли було опубліковано.",
      addFile: "Додати Файл",
      editFile: "Редагувати Файл",
      fileName: "Назва Файлу",
      upload: "Завантажити",
      desc: "Опис",
      folderCreationErr: "Ієрархія обмежена двома рівнями.",
      subFolderWarning: "Оберіть, будь ласка, підпапку для дадавання файлу(ів)",
      deleteWarning: "Оберіть, будь ласка, папку для видалення",
      beforeAfterNotSelected: "Please select if the evidence is before or after kaizen",
    },
    chapter: {
      chapter_exist: "Такий Розділ вже існує.",
      chapter_saved: "Розділ Збережено",
      chapter_cannot_delete: "Цей Розділ не можна видалити оскільки підпитання вже були опубліковані.",
      failed: "Не вдалося!",
      chapter_deleted: "Розділ Видалено",
      chapter_nonEmpty: "Дайте розділу найменування, будь ласка",
      addchapter: "Додати розділ",
      editChapter: "Відкоригувати розділ",
      DELETE_SUCC:"Questionnaires deleted successfully.",
      DELETE_SUCC_LIB:"Libraries deleted successfully.",
      DELETE_ERR : "Something Went wrong."
    },
    evaluation: {
      attachments: "Додатки",
      comments: "Коментар",
      evidence: "Підтвердження",
      evidences: "Підтвердження",
      yes: "Так",
      no: "Ні",
      na: "N/A",
      uploadEvidence: "Завантажити підтвердження",
      fileUpload: "Оберіть, будь ласка, файл(и) до завантаження (макс. 1 Гб)",
      who: "Хто?",
      answer: "Відповісти",
      comment: "Comment",
      date: "Дата",
      submit: "Подати",
      commentMsg: "Будь ласка, введіть коментар.",
      noCommentAvailable: "Коментар відсутній.",
      uploadSuccessMsg: "File(s)  have been uploaded successfully",
      uploadErrorMsg: "Number of files failing to upload:",
      fileWithSameName: "Вже існує інший файл з такою самою назвою.",
      noEvidence: "Підтвердження відсутні",
      createAction: "Створити дію",
      chapterName: "Назва розділу",
      questionNo: "Номер питання.",
      when: "Коли?",
      noRecords: "Записи відсутні.",
      kaizenPoints: "Пункти для Кайзен",
      renameSuccessMsg: "File has been renamed successfully",
      deleteSuccessMsg: "File has been deleted successfully",
      question: "Питання",
      before:"Before",
      after:"After",
      beforeAfterLabel:"Is this evidence from before or after kaizen?",
      move:"Move",
      moveMsg: "File(s) has been moved successfully",
      noToYesMsg:"Previous answer was NO",
    },
    WALK_AROUND_CHECK: "Круговий огляд",
    ketProgram: {
      TSM: "TSM/LSM",
      BRiT: "BRiT RI",
      TSM_BNP: "Відновлювальний",
      TSM_USED_CAR: "Вживані автомобілі",
      value_chain: "Ланцюжок цінності",
      brit: "BRiT",
      ea: "EA",
      per:"Performance",
      nettrans:"Network Transformation",
    },
    dashboard: {
      notice_board: "Дошка повідомлень",
      kpi_target_not_set: "Цілі по показникам не встановлено",
      kpi_initial_not_set: "Початковий рівень показників не внесено",
      kpi_monthly_not_set: "Фактичні показники за місяць не внесено",
      kpi_below_target: "Присутні показники нижче цілей",
      activation: "Активація",
      users: "Користувачі",
      nmscs: "NMSC's",
      activeRetailer: "Активні Дилери",
      certificationRate: "Відсоток сертифікації",
      reCertificationRate: "Відсоток ре-сертифікації",
      activityDue: "Задачі до виконання",
      avgdays: "Днів в середньому/Операційний модуль",
      overDueAct: "Прострочені Задачі",
      actionItem: "Заплановані дії",
      bussResult: "Бізнес-результати",
      toolboxImpl: "Впровадження Операційного модуля",
      statusCompletedL:"Status - Completed, Progress - ",
      statusClosedL:"Status - Closed, Progress - ",
      statusOngoingL:"Status - Ongoing, Progress - ",
      recentUpdates: "Нещодавні оновлення",
      rolloutTopNmsc: "Результати впровадження - Кращі дистриб'ютори",
      bestPractice: "Дошка Кращіх практик",
      availableKpi: "Доступні КПД",
      selectedKpi: "Обрані КПД",
      dealerBestPractice: "Дошка кращих практик дилера",
      kpiList: "Перелік КПД",
      go: "ВПЕРЕД",
      nodata: "Дані відсутні",
      businessResultTop: "Бізнес-результати - Кращі дилери",
      businessResultNMSC: "Бізнес-результати - Кращі дистриб'ютори"


    },
    dealerCertification: {
      dealerCertification: "Сертифікація Дилера",
      readyForCertification: "Готовий до сертифікації",
      certification: "Сертифікація",
      submittedOn: "Поданий на дату",
      certificateDate: "Дата Сертифікації",
      reCertificateDate: "Дата Ре-сертифікації",
      toolboxDetails: "Подробиці Операційного модуля",
      approve: "Підтвердити",
      reject: "Відхилити",
      pending: "В очікуванні",
      region: "Регіон"



    },

    uploadBulk: {
      browse: "Browse",
      executionId: "Ідентифікатор виконання",
      uploadedOn: "Дата завантаження",
      lastUpdatedOn: "Дата останнього оновлення",
      noDataTable: "No data to view",
      kpiUpload: "Завантаження показників",
      action: "Дія",
      processed: "Оброблено",
      error: "Помилка",
      received: "Received",
      createActivity: "Створити Активності",
      fileUpload: "File Upload",
      targetSetting: "Target Setting",
      kpiEntry:"KPI Entry",
      accepted:"Accepted",
      launchNmsc:"Launch for NMSC"
    },
    userManagement: {
      userManagement: "Управління користувачами",
      createUser: "Create User",
      editUser: "Edit User",
      viewUser: "View User",
      nmscId: "NMSC ID",
      tarsId: "TARS ID",
      firstName: "Ім'я",
      lastName: "Прізвище",
      emailId: "Електронна пошта",
      britDealerChamp: "Brit Dealer Champion",
      invalidEmail: "Please enter valid E-Mail ID",
      invalidTars: "Please enter valid TARS ID",
      invalidFirstName: "Please enter valid First Name",
      invalidLastName: "Please enter valid Last Name",
      user_created: "User Created",
      user_updated: "User updated",
      duplicate_tarsuser: "This TARS id is already registered in the KET database.",
      user_define_in_tool: "User is defined as Business owner in Active Business Function. Hence, could not be made Inactive",
      user_refer_on: "User is referenced in Ongoing activity",
      pbiAccount : "Обліковий запис Power BI",
      invalidPbiAccount : "PBI Account should contain minimum 3 characters.",
      businessArea: "Напрямок діяльності",
      valueChainAndBRiT: "Ланцюжок цінності і BRIT",
      only:"Only",
      optionText:"Please select at least one of the two options below",
      valueChainOnly: "Лише Ланцюжок цінності",
      britOnly: "Лише BRIT"
    },
    UserManagement: {
      nmscId: "NMSC ID",
      tarsId: "TARS ID",
      firstName: "Ім'я",
      lastName: "Прізвище",
      emailId: "Електронна пошта",
      languageCode: "Код мови",
      ACTIVE: "Active",
      INACTIVE: "Неактивний",
      addUser: "Додати користувача",
      Assign: "Призначити",
      List: "Перелік",
      user: "Користувач",
      error_message: "Виникла помилка, будь ласка, спробуйте пізніше.",
      role: "Роль користувача",
      standardMessage: "No User Found",
      dealerCode: "Код дилера",
      a2d: "A2D UUID",
      britReady: "Дилер приймав участь у BRIT Ready.",
      britLive: "Дилер приймав участь у BRIT Live.",
      britJapan: "Дилер приймав участь у BRIT Discovery в Японії.",
      britEss:"Дилер оновив План дій щодо EES",
      addDealer: "Додати дилера (з A2D)",
      noDealer: "Дилера(-ів) не знайдено.",
      dealerUuid: "Код дилера uud (A2D)",
      language: "Мова",
      L3: "L3",
      L4: "L4",
      L5: "L5",
      L6: "L6",
    },
    CeaKpi: {
      survey: "Survey",
      evaluationItem: "Evaluation Item",
      kpiMetric: "KPI Evaluation Method",
      stratagicPillar: "Strategic Pillar",
      addKPI: "Add KPI",
      edit: "Edit",
      delete: "Delete",
      subPillar: "Sub-Pillar",
      kaizenImp_impact_def: "Kaizen Importance / Impact / Definition",
      evalMethod: "Evaluation Method",
      scoreMethod: "Scoring Method",
      typeOftarget: "Type of Target",
      target: "Target",
      mode: "Mode",
      points: "Points",
      inputFreq: "Input Frequency",
      cea_kpi_created: "CEA KPI has been created successfully",
      noPointsAllocated:"No Points defined for selected Pillar/Business function mapping",
      targetIncorrect:"Target value is Invalid",
      viewKpi:"View KPI",
      general:"General",
      targetScoring:"Targets & Scoring",
      inputs:"Input Parameters",
      maxFiveParam:"Maximum 5 parameters for a KPI can be added",
      validParam:"Please Enter Valid Parameter",
      validDesc:"Please Enter Valid Parameter Description",
      dataEntry:"Data Entry in KET",
      paramater:"Parameter",
      paramDesc:"Parameter Description",
      noParamAvai:"No Parameters Available",
      kpiDeleted:"KPI deleted Successfully",
      kpiDeleteError:"KPI Not found",
      kpiActualsError:"Actuals present. KPI cannot be deleted",
      cea_kpi_updated: "CEA KPI has been updated successfully",
      addParam:"Add Parameters"

    },
    dealerManagement: {
      invalidUuid: "Будь ласка, введіть вірний A2D uuid.",
      invalidDealerName: "Будь ласка, введіть вірну назву дилера.",
      invalidCity: "Будь ласка, введіть вірне місцезнаходження.",
      invaliDealerCode: "Будь ласка, введіть вірний код дилера.",
      existingUuid: "Код uud (A2D) вже присутній в базі даних КЕТ.",
      updateUuid: "Оновіть код A2D uuid",
      newUuid: "Новий код A2D uuid",
    },
    networkManagement: {
      networkManagement: "Управління мережею",
    },
    kpiEntry: {
      cancel : "Cancel",
      save : "Save",
      no : "No",
      yes : "Yes",
      hide : "Hide",
      show : "Show",
      dataNotSaved : "Data is not saved. Entered data may be lost. Do you want to Proceed?",
      wantToProceed : "Do you want to proceed?",
      status: 'Status',
      evaluationYear: 'Evaluation Year',
      frequency: 'Frequency',
      typeOfTarget: 'Type of Target',
      target: 'Target',
      unit: 'Unit',
      standardMessage: 'No Kpi Actuals Data Found',
      srNo: 'S.No.',
      actuals: 'Actuals',
      subPillar: 'Sub Pillar',
      businessFunc: 'Business Function',
      brand: 'Brand',
      Jan : "Jan",
      Feb : "Feb",
      Mar : "Mar",
      Apr : "Apr",
      May : "May",
      Jun : "Jun",
      Jul : "Jul",
      Aug : "Aug",
      Sep : "Sep",
      Oct : "Oct",
      Nov : "Nov",
      Dec : "Dec",
      q1 : "Q1",
      q2 : "Q2",
      q3 : "Q3",
      q4 : "Q4",
      h1 : "H1",
      h2 : "H2",
      cy : "CY",
      kpi : "Key Performance Indicators",
      actuals1 : "Actuals should not be more than allotted points. Please re-enter",
      actionPlan	: "Action Plan",
      enterActualsData : "Please enter actuals data.",
      notNumber : "Please enter valid number.",
      serverErrorMsg : "An error occured, please try again later.",
      launchEvaluationError : "Please launch evaluations.",
      actualsDataSaved : "Actuals data uploaded successfully.",
      inputTxt:"Inputs"
    },
    ceaEvaluation :{
      nmsc : "NMSC",
      search : "Search",
      all : "All",
      status : "Status",
      businessFunction :"Business Function",
      noEvaluationsFound : "No Evalutions Found",
      evaluationYear : "Evalution Year",
      view : "View",
      evaluations : "Evaluations",
      launchTargetSetting: "Launch Target Setting",
      launchEvaluation : "Launch Evaluation",
    },
    britDashboard:{
      noVideo:"No videos available",
      playVideo:"Play Video",
      trainingVideos:"Training  Videos",
      download: "Download Video",
      back:"Back"
    },
    ceaActionPlan:{
      id: "ID",
      counterMeasure: "Countermeasure",
      questionReference: "KPI Reference",
      status: "Status",
      startDate: "Start Date",
      dueDate: "Due Date",
      assignedTo: "Assigned To",
      noActionAvailable: "No Actions available",
      add: "Add",
      download: "Download",
      edit: "Edit",
      delete: "Delete",
      deleteMsg: "Are you sure you want to delete ?",
      no: "No",
      yes: "Yes",
      addActionPlanNotAllowed: "The Action Plan cannot be created, or edited after the Activity has been Closed, Completed, or Rejected.",
      actionPlanItem: "Action Plan - Item #",
      addActionPlan: "Add Action Plan",
      open: "Open",
      actionPlanStartDateValidation:"End Date cannot be earlier than the Start Date",
      modalMandatory: "Please enter valid data in all the mandatory fields",
      problem: "Problem",
      description: "Description",
      followup: "Follow Up",
      history: "History",
      cancel: "Cancel",
      save: "Save",
      update: "Update",
      assignee: "Assignee",
      endDate: "End Date", 
      select: "Select",
      notApplicablePer:"Not Applicable for CEA, Networks and Used car"
    },
    reachItDashboard:{
      topNavReachIT:"ReachIT",
      downloads:"Downloads",
      historyFiles:"History of previous files",
      reachText1:"Welcome to the REACH IT homepage. The TME Environmental Management team will use this page to share relevant  information related to SCIP : Substances of Concern In articles as such or in complex objects (Products) established under the Waste Framework Directive (WFD) in order for you to fulfill your duty to process with your SCIP simplified notification.",
      reachText2:"In case of query, please contact the TME Environmental Management team via below link.",
      reachText3:"In case of query, please contact your NMSC.",
      envMngt:"Environmental Management",
      fileStatistics: "File Downloaded Statistics",
      Downloaded: "Downloaded",
      not: "Not",
      exportToExcel:"Export To Excel"
    },
    usedCarComment:{
      ongoing:"Ongoing",
      completed:"Completed",
      commentHistory:"Monthly Comments History",
      addComment:"Add Monthly Comment",
      month:"Month",
      comment:"Comment",
      username:"Username",
      email:"Email",
      createdDate:"Created Date"
    },
  }
};

export default translationsUK;
