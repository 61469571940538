const translationsFI = {
  fi: {
    Policy: {
      HeaderMsg: "Tietosuojakäytäntö henkilötietojesi käsittelystä KET-järjestelmässä",
      Message1: "Tämä tietosuojailmoitus liittyy henkilötietojesi käsittelyyn KET-järjestelmässä. Sitä on aina luettava yhdessä yleisen Toyota-tietosuoja- ja henkilötietosuojapolitiikan kanssa, jossa kuvaillaan henkilötietojesi käsittelyn yleisiä yksityiskohtia ja jotka sinun on hyväksyttävä, jotta voisit edetä edelleen.",
      Message2: "Toyota Motor Europe NV/SA,  pääkonttori, osoite: Avenue du Bourget/Bourgetlaan 60, 1140 Brussels, Belgium, tietovalvojana kerää henkilötietojasi (kuten alla on kuvattu) alla kuvattuihin tarkoituksiin.",
      Message3: "Mitä henkilötietojasi kerätään?",
      Message4: "Keräämme seuraavat henkilötiedot KET-järjestelmään:",
      Table1: {
        tr1: "Henkilötietojen luokka:",
        tr2: "Käyttäjätunnus",
        tr3: "Käyttäjän nimi (koko nimi)",
        tr4: "Brändi (Toyota, Lexus)",
        tr5: "Kieli",
        tr6: "Yritys"
      },

      Message5: "Henkilötietojen käsittelyn tarkoitus ja oikeudelliset perusteet.",
      Message6: "Käsittelemme henkilötietojasi seuraaviin tarkoituksiin ja oikeudellisiin perusteisiin:",
      Table2: {
        tr1: {
          th1: "Tarkoitus:",
          th2: "Oikeudelliset perusteet:"
        },
        tr2: {
          td1: "KET - 1: Jälleenmyyjän Kaizen-suorituskyky",
          td2: "Rekisterinpitäjän (oikeuksien) oikeutettu etu"
        },
        tr3: {
          td1: "KET - 2: tietohallintotuki",
          td2: "Rekisterinpitäjän (oikeuksien) oikeutettu etu"
        }
      },
      Message7: "Henkilötietojesi säilyttämisen kesto",
      Message8: "Henkilötietojasi säilytetään jäljempänä mainitun ajan.",
      Table3: {
        tr1: {
          th1: "Säilytysaika:",
          th2: "Henkilötietojen luokka:"
        },
        tr2: {
          td1: "Kaikki yllä kuvatut henkilötiedot säilytetään enintään 5 vuotta + 1 kuukausi KET-järjestelmän lopullisesta sulkemisesta.",
          td2: "Käyttäjän etu- ja sukunimi, kieli, yritys"
        }
      },
      Message9: "Valinnainen: Jos henkilötietoja luovutetaan / siirretään Euroopan talousalueen (ETA) ulkopuolisille maille",
      Message10: "Henkilötietojasi saavat vastaanottajan (t) käyttöön ETA-alueen ulkopuolisissa maissa seuraavasti",
      Table4: {
        tr1: {
          td1: "Identiteetti",
          td2: "Maa",
          td3: ""
        },
        tr2: {
          td1: "ETA: n ulkopuolinen vastaanottaja (t)",
          td2: "Infosys",
          td3: "Intia"
        }
      },
      Message11: "Vahvistan ymmärtäneeni tämän ilmoituksen; olen lukenut ja hyväksynyt yleisen Toyota-tietosuoja- ja henkilötietosuojapolitiikan."
    },
    ACCESSORIES: "Lisävarusteet",
    activity: {
      Assignee: "Vastuuhenkilö",
      CompletionDate: "Valmistumispäivä",
      Dealer: "Jälleenmyyjä",
      FirstVisit: "Ensimmäinen käynti",
      kpi: {
        kpi: "Tunnusluvun tulee olla numeroita (joko + tai - )",
        kpiLength: "Tunnusluvun enimmäispituus on 10 merkkiä",
        mandatory: "Anna voimassa olevat tiedot kaikkiin pakollisiin kenttiin",
        mandatoryTargets: "Ole hyvä ja lisää kaikki tunnusluvut.",
        target: "Tavoitteen tulee olla vain numeroita (joko + tai - )",
        targetLength: "Tavoitteen enimmäispituus on 10 merkkiä"
      },
      ReminderOn: "Muistutus käytössä",
      statusDD: "Tila",
      Toolbox: "Osa-alue",
      activityCreation: "Toiminto on aloitettu.",
      activityCreated: "Toiminto (toiminnot) on luotu."
    },
    DPOK: "Varaosatoim. kehitt.",
    EFFICIENCY: "Tehokkuus",
    FIX_IT_RIGHT: "Fix It Right",
    footer: {
      message: "Pakolliset kentät"
    },
    FOUNDATION: "Perusta",
    MAINTENANCE_REMINDERS: "Huoltomuistutus",
    EMPLOYEE_ENGAGEMENT: "Employee Engagement",
    CUSTOMER_CENTRICITY: "Customer Centricity",
    TOYOTA_WAY: "Toyota Way",
    BUSINESS_SUSTAINABILITY: "Business Sustainability",
    FUNDAMENTALS: "Perusta",
    FIX_IT_RIGHT_BnP: "Fix it Right (B&P)",
    UTILIZATION_AND_EFFICIENCY: "Utilization and Efficiency",
    CUSTOMER_EXPERIENCE: "Customer Experience",
    UPSELLING_OPPORTUNITIES: "Upselling Opportunities",
    MANAGING_CAPACITY: "Managing Capacity",
    VEHICLE_TRADE_IN: "Vehicle Trade-In",
    STOCK_MANAGEMENT: "Stock Management",
    REFURBISHMENT: "Refurbishment",
    months: {
      apr: "Huh",
      aug: "Elo",
      dec: "Jou",
      feb: "Hel",
      jan: "Tam",
      jul: "Hei",
      jun: "Kesä",
      mar: "Maa",
      may: "Tou",
      nov: "Mar",
      oct: "Loka",
      sep: "Syys"
    },
    others: {
      back: "Takaisin",
      closed: "Päätetty",
      completed: "Valmis ",
      countryLanguageCode: "Maa/kielikoodi ",
      createActivity: "Luo toiminto",
      createdDate: "Luontipäivä",
      dataUneditableNote:
        "Huom: Tallennettua tietoa ei voi muokata. Muuttaaksesi tietoja, ota yhteyttä maahantuojan pääkäyttäjään.",
      date: "Päiväys",
      downloadQuestionnaire: "Lataa kysely",
      editActivity: "Muokkaa toimintoa",
      initialHelpText: "Lähtötason tunnusluvut on oltava edeltävien kuuden kuukauden keskiarvo",
      inProgress: "Käynnissä",
      name: "Nimi",
      next: "Seuraava",
      noActivities: "Toimintoja ei löytynyt",
      noResults: "Ei tuloksia",
      of: "/",
      ongoing: "Käynnissä",
      open: "Auki",
      page: "Sivu",
      planned: "Suunniteltu",
      problemDescription: "Ongelman kuvaus",
      signature: "Allekirjoitus",
      select: "Valitse",
      bulkAction: "Bulk Action",
      bulkActions: "Bulk Actions"
    },
    ServerMessage: {
      error_message: "Latauksen aikana tapahtui virhe.",
      no_published_subquestion: "Ole hyvä ja julkaise tämän osa-alueen kysely tämän toiminnon käynnistämiseksi.",
      activity_upload_error_msg: "Virhe toimintojen lataamisessa.",
      action_plan_created: "Toimintasuunnitelma luotu onnistuneesti",
      action_plan_deleted: "Toimintasuunnitelma poistettu onnistuneesti",
      action_plan_saved: "Toimintasuunnitelma tallennettu",
      activity_kpi_saved: "Tunnusluvut tallennettu onnistuneesti",
      master_kpi_confirm_delete:
        "Haluatko varmasti poistaa valitun tunnusluvun?",
      master_kpi_created: "Tunnusluku luotu",
      master_kpi_deleted: "Tunnusluku poistettu",
      master_kpi_not_found: "Tunnuslukua ei löydy",
      master_kpi_published: "Valitut tunnusluvut on julkaistu",
      master_kpi_retired: "Tunnusluku on siirretty taustalle",
      master_kpi_saved: "Tunnusluku tallennettu",
      subquestion_created: "Alakohta on tallennettu.",
      subquestion_updated: "Alakohta on päivitetty.",
      subquestionnaire_not_found: "Alakohtaa ei löydy.",
      subquestion_retired: "Alakohta on siirretty taustalle.",
      subquestion_deleted: "Alakohta on poistettu.",
      subquestion_published: "Alakohdat on julkaistu.",
      mainquestion_created: "Kysely on luotu.",
      mainquestion_deleted: "Kysely on poistettu.",
      mainquestion_updated: "Kysymys on päivitetty.",
      duplicateMsg: "Kaksoiskappaleet Excel-tiedostossa.",
      deleteErr: "Virhe poistettaessa tiedostoa.",
      toolbox_deleted: "Lisäosa on poistettu onnistuneesti",
      toolbox_already_exists: "Lisäosa on jo olemassa.",
      toolbox_saved: "Lisäosa tallennettu",
      toolbox_created: "Lisäosa luotu",
      toolbox_not_found: "Lisäosaa ei löydy",
      published_content_found: "Tätä lisäosaa ei voi poistaa, sisältää julkaistua sisältöä.",
      dealers_already_mapped: " Valitut jälleenmyyjät on jo lisätty jo käyttäjälle. ",
      dealer_already_mapped: " Dealer already mapped ",
      dealer_added: "  Jälleenmyyjä lisätty. ",
      dealers_added: "  Jälleenmyyjä lisätty. ",
      total_dealers_mapped_now: "Käyttäjälle ohjattujen jälleenmyyjien lukumäärä on  ",
      no_dealer_available: "Jälleenmyyjiä ei saatavilla valituilla kriteereillä",
      proper_dealer_selected: "Valitse soveltuva jälleenmyyjä",
      user_referenced_ongoing_activity: "Käyttäjä on nimetty käynnissä oleviin aktiviteetteihin. Korjaa käyttäjä ja yritä uudelleen. ",
      want_to_remove_mapping: "Oletko varma että haluat poistaa käyttäjän näiltä jälleenmyyjiltä?",
      user_mapping_removed: "Valitut jälleenmyyjät on poistettu käyttäjältä",
      dealer_closed_in_A2D: "Tämä jälleenmyyjä on suljettu tilassa A2D järjestelmässä",
      network_level_not_found: "Valitse liikkeen taso",
      duplicate_dealer_uuid: "Jälleenmyyjä jo KET kannassa",
      dealer_created: "Jälleenmyyjä luotu",
      dealer_updated: "Jälleenmyyjän tiedot päivitetty",
      dealer_not_present: "Jälleenmyyjä ei rekisteröity A2D järjestelmään",
      dealer_uuid_other_nmsc: "A2D UUID koodi  ei ole saatavilla verkostolle",
      target_kpi_updated: "The KPI's status have been updated successfully.",
      target_updated: "The KPI's target have been updated successfully.",
      kpi_launched: "The KPI's bulk launch successfully.",
      targetUpdate:"Application doesn't allow target to be modified for accepted status.",
    },
    standardMessages: {
      delete: "Haluatko varmasti poistaa?",
      addActionPlanNotAllowed: "Toimintasuunnitelmaa ei voi luoda tai muokata toiminnon sulkemisen, valmistumisen tai hylkäämisen jälkeen.",
      addToolboxNotAllowed: "Cannot create more than 19 toolbox",
      britRejectMsg: "Haluatko varmasti hylätä tämän toiminnon? Muutokset eivät ole mahdollisia hylkäyksen jälkeen.",
      rejectMsg: "Haluatko varmasti hylätä tämän toiminnon? Muutokset eivät ole mahdollisia hylkäyksen jälkeen.",
      selectNMSCTab: "Valitse julkaistava kieli",
      all: "Kaikki",
      deleteConfirm: "Haluatko varmasti poistaa valitun tunnusluvun?",
      no: "Ei",
      retireConfirm: "Haluatko varmasti siirtää taustalle valitun tunnusluvun? Taustalle siirrettyä tunnuslukua ei voi aktivoida uudelleen",
      warning: "Varoitus",
      yes: "Kyllä",
      saveDataConfirmation: "Haluatko varmasti tallentaa tiedot?",
      saveKPIWarning: "Huomautus: Tallennettuja tietoja ei voi enää muokata. Jos haluat muokata tietoja, ota yhteys maahantuojan pääkäyttäjään.",
      addKpiNotAllowed: "Nykyisten/tulevien kuukausien KPI -syöttö ei ole sallittua",
      activityLaunch: "Haluatko varmasti käynnistää tämän toiminnon? Käynnistämisen jälkeen toimintoa ei voi peruuttaa suunnitellulle tilalle tai poistaa.",
      deleteConfirmSubQuestionNaire: "Haluatko varmasti poistaa valitun alakohdan?",
      retireConfirmSubQuestionNaire: "Haluatko varmasti siirtää taustalle valitun alakohdan?",
      deleteConfirmMainQuestion: "Ole hyvä ja vahvista,  mikäli haluat poistaa kysymyksen ja sen alakohdat.",
      deleteConfirmChapter: "Haluatko varmasti poistaa luvun?",
      deleteLibraryFolder: "Haluatko varmasti poistaa tämän kansion?",
      deletePublishedFolder: "Jotkin tämän kansion tiedostot saattavat olla yhteydessä kyselylomakkeeseen. Haluatko varmasti poistaa tämän alikansion pysyvästi ja poistaa linkin (kysymykset) kyselystä?",
      importQuestion: "Haluatko varmasti tuoda tämän alakohdan? Nykyinen alakohta korvataan.",
      noQuestion: "Kysymyksiä ei saatavilla.",
      MandatoryWarning: "Täytä kaikki pakolliset kentät kaikille paikallisille kielille.",
      certificationSubmission:
        "Lisäosa on lähetetty TME:lle sertifioitavaksi.",
      sameToolBox: "Lisäosan voi lähettää vain kerran. Valitse toinen lisäosa.",
      deleteConfirmDealer: "Are you sure you want to delete this dealer ?",
      updateVcConfirmDealer: "Kaikki tälle jälleenmyyjälle kestävyysindeksiin luodut toiminnot poistetaan. Oletko varma, että haluat jatkaa?",
      updateBritConfirmDealer: "Kaikki tälle jälleenmyyjälle BRiT:iin luodut toiminnot poistetaan. Oletko varma, että haluat jatkaa?",
      completed_activity_delete:"Tietoja ei voi palauttaa poistamisen jälkeen. Haluatko varmasti poistaa ?",
      deleteAllQuestionary: "Are you sure you want to delete the entire Questionnaire?",
      deleteAllLibraries: "Are you sure you want to delete all folders and files in the Library?",
      selectAll: "Valitse kaikki",
      unSelectAll: "Poista valinnat",
      select:"Valitse",
      warningMsg:"Toiminnossa on avoimia toimintasuunnitelmia. Haluatko merkitä toiminnon valmiiksi?"
    },
    status: {
      published: "Julkaistu",
      retired: "Taustalla",
      saved: "Tallennettu",
      rejected: "Hylätty",
      open: "Auki",
      closed: "Päätetty",
      close: "Päätetty",
      ongoing: "Käynnissä",
      completed: "Valmis",
      complete: "Valmis",
      planned: "Suunniteltu",
      overdue: "Myöhässä",
      due: "Erääntyy",
      pending: "Pending",
    },
    Rejected: "Hylätty",
    Open: "Auki",
    Closed: "Päätetty",
    Close: "Päätetty",
    Ongoing: "Käynnissä",
    Completed: "Valmis",
    Complete: "Valmis",
    Planned: "Suunniteltu",
    tabDetails: {
      noActionAvailable: "Toimintoja ei käytettävissä",
      activeDealer: "Jälleenmyyjä on rekisteröity aktiiviseksi A2D:ssä ja sitä ei voida poistaa",
      dealerDeleted: "Dealer is deleted."
    },
    Table: {
      action: "",
      activity: "Toiminto",
      activityCompletionDate: "Valmis",
      activityCompletionDateFrom: "Toiminnon päättymispäivä alkaen",
      activityCompletionDateTo: "Toiminnon päättymispäivä",
      activityStartDate: "Aloitettu",
      activityStartDateFrom: "Toiminnon aloituspäivä alkaen",
      activityStartDateTo: "Aloitettu",
      assignedTo: "Vastuuhenkilö",
      assignee: "Vastuuhenkilö",
      benchmark: "Vertailuarvo",
      city: "Kaupunki",
      completedBy: "Täydentänyt ",
      completionBy: "Valmistuminen mennessä",
      completionDate: "Valmistumispäivä",
      counterMeasure: "Toimenpide",
      country: "Maa",
      createdBy: "Luonut",
      dealer: "Jälleenmyyjä",
      dealerNameAD: "Jälleenmyyjän nimi",
      dealerName: "Jälleenmyyjä",
      definition: "Määrittely",
      description: "Kuvaus",
      dueDate: "Eräpäivä",
      endDate: "Päättymispäivä",
      firstVisit: "Ensimmäinen käynti",
      followup: "Seuranta",
      franchise: "Merkit",
      history: "Historia",
      id: "Tunniste",
      initial: "Lähtötaso",
      kpi: "Tunnusluku",
      launch: "Aloita",
      mandatory: "Pakollinen",
      nmsc: "Maahantuoja ",
      problem: "Ongelma",
      publishedOn: "Julkaistu",
      questionReference: "Kysymysviite",
      reference: "Viite",
      reminderOn: "Muistutus käytössä",
      score: "Tulos",
      startDate: "Aloituspäivä",
      status: "Tila",
      target: "Target",
      toolbox: "Osa-alue",
      view: "Katso",
      kpiResult: "Tunnuslukutulokset",
      nmscTarget: "NMSC Target",
      penEbenchmark: "PanE Benchmark",
      kpiField: "KPI",
      location: "Sijainti",
      ActionItemReport: "Toimenpiteitä käynnissä",
      certification: "Sertifioinnin tila",
      noOfToolbox: "Valmistuneiden lisäosien lukumäärä",
      program: "Ohjelma",
      certificationDate: "Sertifioitu",
      activityId : 'Toimintatunnus',
      approvedBy:"Approved By",
      kpis:"KPIs",
      lastmonthKpis:"Last Month KPIs",
      questionPercent:"Completion %",
      actionPlan: "Toimintasuunnitelma",
      monthlyKpi:"Monthly KPIs",
      initialKpi:"Initial KPIs",
      targetKpi:"Target KPIs",
    },
    tabsDetails: {
      actionPlan: "Toimintasuunnitelma",
      confirmDeleteMessage: "Haluatko varmasti poistaa valitun tunnusluvun?",
      confirmRetireMessage: "Vahvista, jos haluat siirtää taustalle aiemmin julkaistun tunnusluvun",
      errorRetireMsg: "Tätä tunnuslukua ei voi poistaa käytöstä, sillä se on TME:n pakollinen tunnusluku",
      import: "Tuo",
      importKpiMessage: "Tuo tunnusluku TME:n tunnuslukulistasta",
      kpi: "Tunnusluvut",
      library: "Kirjasto",
      noActionAvailable: "Toimintoja ei käytettävissä",
      NoKPI: "Tunnuslukuja ei saatavilla",
      publish: "Julkaista",
      questionnaire: "Kysely",
      tmeKPIList: "TME:n tunnuslukulista",
      toyotaCityBrussels: "TOYOTA CITY BRUSSELS",
      attachment: "Attachment",
      attachments: "Attachment(s)",
      uploadAttachment: "Upload Attachment",
      noAttachment: "No attachments available"
    },
    tmeAdminMaster: {
      ToolboxName: "Osa-alueen nimi",
      newToolboxName: "Uusi nimi",
      deleteToolbox: "Poista osa-alue?",
      programName: "Ohjelman nimi",
      ToolboxName_EN: "Osa-alueen nimi (EN)",
      deleteTool: "Haluatko poistaa tämän osa-alueen?",
      selectToolbox: "Valitse poistettava osa-alue.",
      editToolBox: "Muokkaa osa-aluetta",
      addToolbox: "Lisää osa-alue",
      toolbox: "Anna nimi tälle osa-alueelle.",
      addActionPlan: "Lisää Toimintasuunnitelma",
      actionPlanItem: "Toimintasuunnitelma – kohta nro",
      add: "Lisää",
      addActivity: "Lisää toiminto",
      additionalInformation: "Lisätietoja",
      benchmark: "Vertailuarvo",
      cancel: "Peruuta",
      createEditActivity: "Luo/muokkaa toimintoa",
      createKPI: "Luo  tunnuslukua",
      delete: "Poista",
      download: "Lataa",
      edit: "Muokkaa",
      editKPI: "Muokkaa tunnuslukua",
      keyPerformanceIndicator: "Tunnusluku",
      kpiDefinition: "Laskentakaava",
      mandatoryKpi: "Pakollinen tunnusluku",
      save: "Tallenna",
      search: "Etsi",
      unit: "Yksikkö",
      update: "Päivitä",
      validData: "Lisää voimassa olevat tiedot",
      hide: "Piilota",
      show: "Näytä",
      upload_dealer_network: "Lataa jälleenmyyjäverkosto",
      upload_question: "Lataa kysely",
      upload_user: "Lataa käyttäjän tiedot",
      upload_activity: "Lataa toiminnot",
      remove: "Remove",
      toyota: "Toyota",
      lexus: "Lexus",
      launch: "Launch",
      close: "Close",
      generate: "Generate",
      dueDateExceed:"Määräaika ylitetty",
      delQuestionnaire:"Delete questionnaire"
    },
    toastr: {
      activityStatus_condition: "Vastaa kaikkiin kysymyksiin, jotta voit lopettaa tämän toiminnon.",
      emptyTarget: "Please enter the NMSC target to publish the KPI",
      retailerActual: "Please enter the Retailer Actual",
      initialTargetMonthly: "Syötäthän tunnuslukujen lähtötilanteen, tavoitteet ja vähintään yhden kuukauden seurantaluvut jatkaaksesi eteenpäin.",
      initialTarget: "Lisää lähtötason ja tavoitteiden tunnusluvut jatkaaksesi.",
      upload_error: "Virhe jälleenmyyjäverkoston lataamisessa.",
      action_item_create_warning: "Yhteen toimenpiteeseen voi yhdistää enintään viisi tietuetta.",
      actionPlanStartDateValidation: "Lopetuspäivä ei voi olla varhaisempi kuin aloituspäivä",
      actionsNotClosed: "Toimintasuunnitelmassa on käynnissä olevia toimintoja; sulje kaikki toiminnot ennen toimen sulkemista",
      activityDuplicate: "On olemassa jo toinen toiminto samalle jälleenmyyjälle ja osa-alueelle",
      assigneeNotExist: "Vastuuhenkilöä ei ole rekisteröity järjestelmään",
      assigneeValidation: "Virheellinen vastuuhenkilö",
      atleastOnePublish: "Julkaise valitsemalla vähintään yksi tunnusluku",
      cannotRetireActive: "Tämä on TME:n määrittämä pakollinen tunnusluku, jota ei voi siirtää taustalle ",
      completionDate: "Valmistumispäivä ei voi olla varhaisempi kuin ensimmäisen käynnin päivä",
      confirDeletePlannedActivity: "Haluatko varmasti poistaa tämän suunnitellun toiminnon?",
      DateformatValidation: "Päivämäärä tulee olla muodossa pp/kk/vuosi",
      DateValidation: "Aloituspäivän pitää olla aikaisemmin kuin lopetuspäivän",
      dealerValidation: "Virheellinen jälleenmyyjä",
      deleteSelectedConfirm: "Haluatko varmasti poistaa valitun tunnusluvun?",
      duplicate: "Toinen tunnusluku samalla nimellä ja määrittelyllä on jo olemassa",
      modalMandatory: "Anna voimassa olevat tiedot kaikkiin pakollisiin kenttiin",
      noPublishKpi: "Näitä tunnuslukuja ei ole vielä julkaistu valituille osa-alueille",
      validSearchCriteria: "Anna kelvolliset hakuehdot",
      multiSelectPlaceHolder: "Syötä vähintään 5 merkkiä",
      multiSelectPlaceHolderUser: "Syötä vähintään 3 merkkiä",
      startDateValidation: "Aloituspäivä ei voi olla suurempi kuin lopetuspäivä",
      fileSelectionErr: "Valitse ensin tiedosto",
      atleastOneQuestion: "Valitse vähintään yksi alakohta, jonka haluat tuoda.",
      atleastOneSubQues: "Valitse ainakin yksi kysymys julkaistavaksi.",
      uploadErr: "Lataamattomien tiedostojen määrä:",
      uploadSuccess: "Jälleenmyyjäverkosto ladattu.",
      file_upload_success: "Tiedosto (-t) ladattu.",
      KPIinOngoingClosedActivity: "Tämä tunnusluku on käytössä yhdessä tai useammassa käynnissä olevassa / suljetussa tunnuslukuseurannassa. Päätä nämä toiminnot ennen kuin teet mitään muutoksia tunnuslukuun. (tunnuslukuun ja sen tietoihin ei voi tehdä muutoksia ennen päättämistä).",
      Role_Error: "Käyttäjärekisteri on puutteellinen, ota yhteyttä maahantuojan KET ylläpitoon",
      points_allocation_incorrect: "Pisteet syötetty väärin, korjaa",
      activity_deleted:"Toiminto poistettiin onnistuneesti.",
      activity_certified_dealer:"Jälleenmyyjä on sertifioitu valitulle toiminnolle, sitä ei voi poistaa.",
      activity_submited_for_certificate_dealer:"Tämä toiminto on sisällytetty TME: lle lähetettyyn sertifiointipyyntöön, joten tilaa ei voi muuttaa."
    },
    TopNav: {
      ActivityLog: "Toiminnot",
      ApplicationName: "Kaizen Expert Tool",
      Home: "Etusivu",
      logout: "Uloskirjautuminen",
      MasterDataUpload: "Lataa päätiedot",
      Toolbox: "Lisäosa",
      welcome: "Tervetuloa",
      Admin: "Pääkäyttäjä"
    },
    questionnaire: {
      addSubQuestion: "Lisää alakohta",
      editSubQuestion: "Muokkaa alakohtaa",
      subQuestionTitle: "Alakohta",
      additionalInfo: "Lisätietoja",
      na: "Lisää mahdollinen vastaus tähän kysymykseen lisäämällä 'Ei sovellettavissa",
      question: "Kysymys",
      questionTitle: "Kysymyksen otsikko",
      why: "Miksi?",
      addMainQuestion: "Lisää kysymys",
      editMainQuestion: "Muokkaa kysymystä",
      previousQuestion: "Edellinen kysymys",
      nextQuestion: "Seuraava kysymys",
      what: "Mitä?",
      library: "Kirjasto",
      subQuestionTable: {
        no: "Ei",
        subQuestion: "Alakohdat ",
        status: "Tila",
        publishedDate: "Julkaisupäivä",
        notApplicable: "Ei sovellettavissa"
      },
      links: {
        question_link_button: "Linkki",
        question_link_include_folder: "Lisää alikansio",
        question_link_add_link: "Linkitä tiedosto (-t) kirjastosta",
        question_link_view_folder: "Näytä kirjasto"
      },
      mainQuestion: "Uudet/päivitetyt kysymykset:",
      subQuestion: "Uudet/päivitetyt alakohdat:",
      retiredQuestion: "Taustalle siirretyt alakohdat:",
      libraries: "Uudet/päivitetyt tiedostot kirjastoissa:",
      noFiles: "Tiedostoja ei ole saatavana "
    },
    library: {
      selectFile: "Valitse ainakin yksi tiedosto.",
      fileSelected: "Valitut tiedostot",
      publish_library_file: "Valitse vähintään yksi julkaistava tiedosto",
      library_files_deleted: "Haluatko varmasti poistaa tämän tiedoston?",
      library_files_linked_deleted: "Haluatko varmasti poistaa tämän tiedoston ja kyselyyn luodut linkit?",
      max_size_error: "Tiedostojen koko ylittää 1 Gt: n latauksen.",
      empty_file_desc: "Lisää tiedoston kuvaus",
      file_not_selected: "Valitse ladattava tiedosto (-t) (enintään 1 Gt)",
      file_type_error: "Tätä tiedostotyyppiä ei sallita.",
      file_name_error: "Virheellinen merkki tiedoston nimessä.",
      file_name_lenght_error: "Tiedoston nimessä voi olla max. 50 merkkiä.",
      addFolder: "Lisää kansio",
      editFolder: "Muokkaa kansiota",
      currentFolder: "Kansion nimi",
      parentFolder: "Vanhemman kansion nimi",
      emptyFolderName: "Kansion nimi ei voi olla tyhjä.",
      invalidFolderName: "Kansion nimi ei voi sisältää / merkkiä.",
      folder_created: "Kansio on luotu.",
      folder_updated: "Kansio on päivitetty.",
      folder_not_found: "Kansiota ei löydy",
      lib_invalid_toolbox: "Virheellinen osa-alue valittu",
      lib_folder_already_exists: "Tämä kansio on jo olemassa.",
      lib_folder_deleted: "Tämä kansio on poistettu.",
      library_files_published: "Tiedostot on julkaistu.",
      addFile: "Lisää tiedosto",
      editFile: "Muokkaa tiedostoa",
      fileName: "Tiedoston nimi",
      upload: "Lataa",
      desc: "Kuvaus",
      folderCreationErr: "Hierarkia on rajoitettu kahteen tasoon.",
      subFolderWarning: "Valitse alikansio, johon tiedosto (-t) lisätään.",
      deleteWarning: "Valitse poistettava kansio.",
      beforeAfterNotSelected: "Merkitsethän, onko todiste tilanteesta ennen vai jälkeen Kaizen-toimenpiteen.",
    },
    chapter: {
      chapter_exist: "Tämä luku on jo olemassa.",
      chapter_saved: "Luku tallennettu",
      chapter_cannot_delete: "Tätä lukua ei voi poistaa, koska alakohdat on jo julkaistu.",
      failed: "Epäonnistui!",
      chapter_deleted: "Luku poistettu",
      chapter_nonEmpty: "Luo luvun nimi.",
      addchapter: "Lisää luku",
      editChapter: "Muokkaa",
      DELETE_SUCC:"Questionnaires deleted successfully.",
      DELETE_SUCC_LIB:"Libraries deleted successfully.",
      DELETE_ERR : "Something Went wrong."
    },
    evaluation: {
      attachments: "Liitteet",
      comments: "Huomautukset",
      evidence: "Todiste",
      evidences: "Todiste (todisteet)",
      yes: "Kyllä",
      no: "Ei",
      na: "Ei saatavilla",
      uploadEvidence: "Lataa todiste",
      fileUpload: "Valitse ladattava tiedosto (-t) (enintään 1 Gt)",
      who: "Kuka?",
      answer: "Vastaus",
      comment: "Huomautukset",
      date: "Päiväys",
      submit: "Lähetä",
      commentMsg: "Lisää  huomautukset.",
      noCommentAvailable: "Ei huomautuksia.",
      uploadSuccessMsg: "Tiedosto (-t) ladattu.",
      uploadErrorMsg: "Lataamattomien tiedostojen määrä:",
      fileWithSameName: "Toinen samanniminen tiedosto on jo olemassa.",
      noEvidence: "Todisteita ei saatavilla",
      createAction: "Luo toimenpide",
      chapterName: "Luvun nimi",
      questionNo: "Kysymysnumero",
      when: "Milloin?",
      noRecords: "Tietoja ei ole saatavilla.",
      kaizenPoints: "Kaizen-kohdat",
      renameSuccessMsg: "File has been renamed successfully",
      deleteSuccessMsg: "File has been deleted successfully",
      question: "Kysymys",
      before:"Before",
      after:"After",
      beforeAfterLabel:"Onko todista tilanteesta ennen vain jälkeen Kaizen-toimenpidettä?",
      move:"Siirrä",
      moveMsg: "Tiedosto(t) on siirretty onnistuneesti",
      noToYesMsg:"Previous answer was NO",
    },
    WALK_AROUND_CHECK: "Huollon vast.ottotark",
    ketProgram: {
      TSM: "TSM/LSM",
      BRiT: "BRiT RI",
      TSM_BNP: "Kori & maalaus",
      TSM_USED_CAR: "Käytetyt autot",
      value_chain: "Value Chain",
      brit: "BRiT",
      ea: "EA",
      per:"Performance",
      nettrans:"Network Transformation",
    },
    dashboard: {
      notice_board: "Ilmoitustaulu",
      kpi_target_not_set: "Tunnuslukujen tavoitteita ei asetettu",
      kpi_initial_not_set: "Tunnulukujen lähtötasoa ei asetettu",
      kpi_monthly_not_set: "Tunnulukujen kuukausiseurantaa ei asetettu",
      kpi_below_target: "Tunnusluvut alle tavoitteen tällä hetkellä",
      activation: "Aktiiviset",
      users: "Käyttäjät",
      nmscs: "NMSC's",
      activeRetailer: "Aktiiviset jälleenmyyjät",
      certificationRate: "Sertifiointien määrä",
      reCertificationRate: "Uudelleen sertifiointien määrä",
      activityDue: "Toimintoja erääntymässä",
      avgdays: "Päiviä keskimäärin / osa-alue",
      overDueAct: "Viivästyneet toiminnot",
      actionItem: "Aktiiviset toimenpiteet",
      bussResult: "Liiketoiminnalliset tulokset",
      toolboxImpl: "Lisäosien toteutus",
      statusCompletedL:"Status - Completed, Progress - ",
      statusClosedL:"Status - Closed, Progress - ",
      statusOngoingL:"Status - Ongoing, Progress - ",
      recentUpdates: "Viimeisimmät päivitykset",
      rolloutTopNmsc: "Käyttöönoton tulokset - parhaat maahantuojat",
      bestPractice: "Parhaat käytänteet",
      availableKpi: "Käytettävissä olevat tunnusluvut",
      selectedKpi: "Valitut tunnusluvut",
      dealerBestPractice: "Jälleenmyyjän parhaat käytänteet ",
      kpiList: "Tunnuslukulista",
      go: "Aloita",
      nodata: "Tietoja ei saatavilla",
      businessResultTop: "Liiketoiminnalliset tulokset - parhaat jälleenmyyjät",
      businessResultNMSC: "Liiketoiminnalliset tulokset - parhaat maahantuojat"


    },
    dealerCertification: {
      dealerCertification: "Jälleenmyyjän sertifiointi",
      readyForCertification: "Valmis sertifiointiin",
      certification: "Sertifiointi",
      submittedOn: "Lähetetty",
      certificateDate: "Sertifiointipäivä",
      reCertificateDate: "Uudelleen sertifiointipäivä",
      toolboxDetails: "Lisäosan tiedot",
      approve: "Hyväksy",
      reject: "Hylkää",
      pending: "Odottaa",
      region: "Alue"



    },
    uploadBulk: {
      browse: "Browse",
      executionId: "Toiminnon ID",
      uploadedOn: "Ladattu ",
      lastUpdatedOn: "Viimeisin päivitys",
      noDataTable: "No data to view",
      kpiUpload: "KPI data lataaminen",
      action: "Toiminto",
      processed: "Käsitelty",
      error: "Virhe",
      received: "Received",
      createActivity: "Luo Aktiviteetteja",
      fileUpload: "File Upload",
      targetSetting: "Target Setting",
      kpiEntry:"KPI Entry",
      accepted:"Accepted",
      launchNmsc:"Launch for NMSC"
    },

    userManagement: {
      userManagement: "Käyttäjähallinto",
      createUser: "Create User",
      editUser: "Edit User",
      viewUser: "View User",
      nmscId: "NMSC ID",
      tarsId: "TARS ID",
      firstName: "Etunimi",
      lastName: "Sukunimi",
      emailId: "Sähköposti",
      britDealerChamp: "Brit Dealer Champion",
      invalidEmail: "Please enter valid E-Mail ID",
      invalidTars: "Please enter valid TARS ID",
      invalidFirstName: "Please enter valid First Name",
      invalidLastName: "Please enter valid Last Name",
      user_created: "User Created",
      user_updated: "User updated",
      duplicate_tarsuser: "This TARS id is already registered in the KET database.",
      user_define_in_tool: "User is defined as Business owner in Active Business Function. Hence, could not be made Inactive",
      user_refer_on: "User is referenced in Ongoing activity",
      pbiAccount : "Power BI tili",
      invalidPbiAccount : "Power BI tilin tunnuksen pitää sisältää väh. 3 merkkiä",
      businessArea: "Osa-alue",
      valueChainAndBRiT: "Kestävyysindeksi & BRiT",
      only:"Only",
      optionText:"Please select at least one of the two options below",
      valueChainOnly: "Kestävyysindeksi & BRiT",
      britOnly: "BRiT"

    },
    UserManagement: {
      nmscId: "NMSC ID",
      tarsId: "TARS ID",
      firstName: "Etunimi",
      lastName: "Sukunimi",
      emailId: "Sähköposti",
      languageCode: "Kielikoodi",
      ACTIVE: "Active",
      INACTIVE: "Ei käytössä",
      addUser: "Lisää käyttäjä",
      Assign: "Valitse",
      List: "Listaus",
      user: "Käyttäjä",
      error_message: "Tapahtui virhe, yritä uudelleen myöhemmin",
      role: "Käyttäjän rooli",
      standardMessage: "No User Found",
      dealerCode: "Jälleenmyyjä koodi",
      a2d: "A2D UUID",
      britReady: "Jälleenmyyjä osallistui BRiT Ready koulutukseen",
      britLive: "Jälleenmyyjä osallistui BRiT Live koulutukseen",
      britJapan: "Jälleenmyyjä osallistui BRiT Discovery koulutukseen",
      britEss:"Jälleenmyyjä on päivittänyt EES toimintasuunnitelmaansa",
      addDealer: "Lisää jälleenmyyjä (A2D järjestelmästä)",
      noDealer: "Jälleenmyyjiä ei löydetty",
      dealerUuid: "JM UUID koodi (A2D)",
      language: "Kieli",
      L3: "L3",
      L4: "L4",
      L5: "L5",
      L6: "L6",
    },
    CeaKpi: {
      survey: "Survey",
      evaluationItem: "Evaluation Item",
      kpiMetric: "KPI Evaluation Method",
      stratagicPillar: "Strategic Pillar",
      addKPI: "Add KPI",
      edit: "Edit",
      delete: "Delete",
      subPillar: "Sub-Pillar",
      kaizenImp_impact_def: "Kaizen Importance / Impact / Definition",
      evalMethod: "Evaluation Method",
      scoreMethod: "Scoring Method",
      typeOftarget: "Type of Target",
      target: "Target",
      mode: "Mode",
      points: "Points",
      inputFreq: "Input Frequency",
      cea_kpi_created: "CEA KPI has been created successfully",
      noPointsAllocated:"No Points defined for selected Pillar/Business function mapping",
      targetIncorrect:"Target value is Invalid",
      viewKpi:"View KPI",
      general:"General",
      targetScoring:"Targets & Scoring",
      inputs:"Input Parameters",
      maxFiveParam:"Maximum 5 parameters for a KPI can be added",
      validParam:"Please Enter Valid Parameter",
      validDesc:"Please Enter Valid Parameter Description",
      dataEntry:"Data Entry in KET",
      paramater:"Parameter",
      paramDesc:"Parameter Description",
      noParamAvai:"No Parameters Available",
      kpiDeleted:"KPI deleted Successfully",
      kpiDeleteError:"KPI Not found",
      kpiActualsError:"Actuals present. KPI cannot be deleted",
      cea_kpi_updated: "CEA KPI has been updated successfully",
      addParam:"Add Parameters"

    },
    dealerManagement: {
      invalidUuid: "Syötä A2D UUID",
      invalidDealerName: "Syötä jälleenmyyjän nimi",
      invalidCity: "Syötä paikkakunta",
      invaliDealerCode: "Syötä jälleenmyyjänumero",
      existingUuid: "A2D UUID koodi on jo KET tietokannassa",
      updateUuid: "Ylläpidä A2D UUID koodia",
      newUuid: "Uusi A2D UUID koodi",
    },
    networkManagement: {
      networkManagement: "Verkoston hallinta",
    },
    kpiEntry: {
      cancel : "Cancel",
      save : "Save",
      no : "No",
      yes : "Yes",
      hide : "Hide",
      show : "Show",
      dataNotSaved : "Data is not saved. Entered data may be lost. Do you want to Proceed?",
      wantToProceed : "Do you want to proceed?",
      status: 'Status',
      evaluationYear: 'Evaluation Year',
      frequency: 'Frequency',
      typeOfTarget: 'Type of Target',
      target: 'Target',
      unit: 'Unit',
      standardMessage: 'No Kpi Actuals Data Found',
      srNo: 'S.No.',
      actuals: 'Actuals',
      subPillar: 'Sub Pillar',
      businessFunc: 'Business Function',
      brand: 'Brand',
      Jan : "Jan",
      Feb : "Feb",
      Mar : "Mar",
      Apr : "Apr",
      May : "May",
      Jun : "Jun",
      Jul : "Jul",
      Aug : "Aug",
      Sep : "Sep",
      Oct : "Oct",
      Nov : "Nov",
      Dec : "Dec",
      q1 : "Q1",
      q2 : "Q2",
      q3 : "Q3",
      q4 : "Q4",
      h1 : "H1",
      h2 : "H2",
      cy : "CY",
      kpi : "Key Performance Indicators",
      actuals1 : "Actuals should not be more than allotted points. Please re-enter",
      actionPlan	: "Action Plan",
      enterActualsData : "Please enter actuals data.",
      notNumber : "Please enter valid number.",
      serverErrorMsg : "An error occured, please try again later.",
      launchEvaluationError : "Please launch evaluations.",
      actualsDataSaved : "Actuals data uploaded successfully.",
      inputTxt:"Inputs"
    },
    ceaEvaluation :{
      nmsc : "NMSC",
      search : "Search",
      all : "All",
      status : "Status",
      businessFunction :"Business Function",
      noEvaluationsFound : "No Evalutions Found",
      evaluationYear : "Evalution Year",
      view : "View",
      evaluations : "Evaluations",
      launchTargetSetting: "Launch Target Setting",
      launchEvaluation : "Launch Evaluation",
    },
    britDashboard:{
      noVideo:"No videos available",
      playVideo:"Play Video",
      trainingVideos:"Training  Videos",
      download: "Download Video",
      back:"Back"
    },
    ceaActionPlan:{
      id: "ID",
      counterMeasure: "Countermeasure",
      questionReference: "KPI Reference",
      status: "Status",
      startDate: "Start Date",
      dueDate: "Due Date",
      assignedTo: "Assigned To",
      noActionAvailable: "No Actions available",
      add: "Add",
      download: "Download",
      edit: "Edit",
      delete: "Delete",
      deleteMsg: "Are you sure you want to delete ?",
      no: "No",
      yes: "Yes",
      addActionPlanNotAllowed: "The Action Plan cannot be created, or edited after the Activity has been Closed, Completed, or Rejected.",
      actionPlanItem: "Action Plan - Item #",
      addActionPlan: "Add Action Plan",
      open: "Open",
      actionPlanStartDateValidation:"End Date cannot be earlier than the Start Date",
      modalMandatory: "Please enter valid data in all the mandatory fields",
      problem: "Problem",
      description: "Description",
      followup: "Follow Up",
      history: "History",
      cancel: "Cancel",
      save: "Save",
      update: "Update",
      assignee: "Assignee",
      endDate: "End Date", 
      select: "Select",
      notApplicablePer:"Not Applicable for CEA, Networks and Used car"
    },
    reachItDashboard:{
      topNavReachIT:"ReachIT",
      downloads:"Lataukset",
      historyFiles:"Tiedostohistoria",
      reachText1:"Tervetuloa REACH IT:n kotisivulle. TME:n ympäristötiimi jakaa tällä sivustolla hyödyllistä tietoa WFD-direktiiviin (Waste Framework Directive) perustuvien SCIP-ilmoitusten (Substances of Concern In articles as such or in complex objects (Products)) täyttämisessä.",
      reachText2:"Halutessasi lisätietoja ota yhteyttä TAF-maahantuontiin",
      reachText3:"Halutessasi lisätietoja ota yhteyttä TAF-maahantuontiin",
      envMngt:"Ympäristötiimi",
      fileStatistics: "Tiedostojen lataustiedot",
      Downloaded: "Ladattu",
      not: "Ei",
      exportToExcel:"Vie Exceliin"
    },
    usedCarComment:{
      ongoing:"Ongoing",
      completed:"Completed",
      commentHistory:"Monthly Comments History",
      addComment:"Add Monthly Comment",
      month:"Month",
      comment:"Comment",
      username:"Username",
      email:"Email",
      createdDate:"Created Date"
    },
  }
};

export default translationsFI;
