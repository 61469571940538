import axios from 'axios';
import { toastr } from 'react-redux-toastr';
import { fetchKaizenPoints} from "./kaizenAction.jsx";
import { getCallWithReducer, postCallWithReducer } from "./commonAction";
import { getToken } from '../oidc/AuthService';
export const ADD_MODAL = 'ADD_MODAL';

/* eslint-disable */

export function getActivityDetails(activityId) {
  return function (dispatch) {
  dispatch(getCallWithReducer("/evaluation/getActivityDetails/" + activityId,null,"ACTIVITY_DETAILS"))
  };
}

export function getNmscQuestionId(versionSubQuestionId) {
  return function (dispatch) {
  dispatch(getCallWithReducer("/evaluation/getNmscQuestionId/" + versionSubQuestionId,null,"EVALUATION_NMSC_QUESTION"))
  };
}
export function fetchEvaluationChapterSequence(chapterId, isPublished) {
  return function (dispatch) {
    if (chapterId) {
      //dispatch(setBlockingState(true));
      axios
        .get("/evaluation/evaluationChapterSeq/" + chapterId, {
          headers: {
          'Authorization': 'Bearer ' + getToken(),
          Accept: "application/json",
          Pragma: "no-cache"
          }
        })
        
        .then(response => {
          //dispatch(setBlockingState(false));

          dispatch({
            type: "EVALUATION_MAIN_CHAPTER_SEQ",
            payload: response.data
          });
        })
        .catch(function (error) {
          //dispatch(setBlockingState(false));
          console.error(error);
        });
    }
    else {
      dispatch({
        type: "EVALUATION_MAIN_CHAPTER_SEQ",
        payload: null
      });
    }
  };
}

export function getEvaluationChapters(versionId, activityId, chapterId, questionId, firstLoadFlag, afterEvaluation) {

  return function (dispatch) {
    //dispatch(setBlockingState(true));
    axios
      .get("/evaluation/getChapters/" + versionId + "/" + activityId, {
        headers: {
          'Authorization': 'Bearer ' + getToken(),
          Accept: "application/json",
          Pragma: "no-cache"
        }
      })
      .then(response => {
        //dispatch(setBlockingState(false));
        var nodeList = response.data;
        if (firstLoadFlag) {
          var chapt = nodeList[0];
          nodeList.map((node) => {
            node.toggled = true;
          })
          if (chapt) {
            var node = chapt.children;
            var question = node[0];
            if (question) {
              question.active = true;
              // chapt.toggled = true;
              dispatch(fetchEvaluationChapterSequence(question.mainQId));
              dispatch(getDealerMainQuestion(question.mainQId));
              dispatch(getDealerSubQuestion(question.mainQId, activityId));
            }
            else {
              //  node.toggled = true;
            }
          }
          dispatch({
            type: "FIRST_LOAD",
            payload: true
          });

        }
        else if (afterEvaluation) {
          for (var i = 0; i < nodeList.length; i++) {
            var chapt = nodeList[i];
            if (chapt.chapterId === chapterId) {
              var node = nodeList[i].children;
              for (var j = 0; j < node.length; j++) {
                var question = node[j];
                if (question.mainQId === questionId) {
                  question.active = true;
                }
                else {
                  question.active = false;
                }
              }
              chapt.toggled = true;
            }
            else {
              chapt.toggled = true;
            }

          }

        }
        else {
          if (chapterId && (questionId && questionId != 0)) {
            for (var i = 0; i < nodeList.length; i++) {
              var chapt = nodeList[i];
              if (chapt.chapterId === chapterId) {
                var node = nodeList[i].children;
                for (var j = 0; j < node.length; j++) {
                  var question = node[j];
                  if (question.mainQId === questionId) {
                    question.active = true;
                  }
                  else {
                    question.active = false;
                  }
                }
                chapt.toggled = true;
              }
              else {
                chapt.toggled = true;
              }

            }
            dispatch(fetchEvaluationChapterSequence(questionId));
            dispatch(getDealerMainQuestion(questionId));
            dispatch(getDealerSubQuestion(questionId, activityId));
          }
          else if (chapterId && !questionId) {
            for (var i = 0; i < nodeList.length; i++) {
              var node = nodeList[i];
              if (node.chapterId === chapterId) {
                node.active = true;
              }
              else {
                node.active = false;
              }
            }
          }
          else if (questionId && questionId === 0) {
          }
          else {
            dispatch(fetchEvaluationChapterSequence(null));
          }
        }

        dispatch({
          type: "EVALUATION_CHAPTERS",
          payload: nodeList
        });

      })
      .catch(function (error) {
        //dispatch(setBlockingState(false));
        console.error(error);
      });
  };
}

export function getEvaluationVersion(activityId) {
  return function (dispatch) {
    //dispatch(setBlockingState(true));
    axios
      .get("/evaluation/getEvaluationVersion/" + activityId, {
        headers: {
          'Authorization': 'Bearer ' + getToken(),
          Accept: "application/json",
          Pragma: "no-cache"
        }
      })
      .then(response => {
        //dispatch(setBlockingState(false));
        dispatch({
          type: "EVALUATION_VERSION",
          payload: response.data
        });
        if (response.data !== 9999) {
          dispatch(getEvaluationChapters(response.data, activityId, null, null, true));
          // dispatch(getEvaluationAnsweredByActivity(activityId));
          dispatch(getEvaluationProgressBar(activityId));
        }

      })
      .catch(function (error) {
        //dispatch(setBlockingState(false));
        console.error(error);
      });
  };
}
/*   export function getEvaluationAnsweredByActivity(activityId) {
    return function (dispatch) {
      //dispatch(setBlockingState(true));
      axios
        .get("/evaluation/getEvaluationAnsweredByActivity/" + activityId, {
          headers: {
            Accept: "application/json",
            Pragma: "no-cache"
          }
        })
        .then(response => {
          //dispatch(setBlockingState(false));
          dispatch({
            type: "EVALUATION_ACTIVITY_COMPLETED",
            payload: response.data
          });
        })
        .catch(function (error) {
          //dispatch(setBlockingState(false));
          console.error(error);
        });
    };
  } */


export function getEvaluationProgressBar(activityId) {
  return function (dispatch) {
  dispatch(getCallWithReducer("/evaluation/getEvaluationProgressBar/"+ activityId,null,"EVALUATION_PROGRESSBAR"))
  };
}


export function getDealerMainQuestion(questionId) {
  return function (dispatch) {
  dispatch(getCallWithReducer("/evaluation/getMainQuestion/" + questionId,null,"EVALUATION_MAIN_QUESTION"))
  };
}

export function getDealerSubQuestion(questionId, activityId) {
  return function (dispatch) {
    //dispatch(setBlockingState(true));
      axios
        .get("/evaluation/getSubQuestion/" + questionId + "/" + activityId, {
        headers: {
          'Authorization': 'Bearer ' + getToken(),
          Accept: "application/json",
          Pragma: "no-cache"
        }
      })
      .then(response => {
        //dispatch(setBlockingState(false));
        var versionSubQuestionId = null;
        if (response.data.length > 0) {
          response.data.map((ele) => {
            if (ele.nmscSubQuestionId !== null && versionSubQuestionId === null) {
              versionSubQuestionId = ele.versionSubQuestionId;
            }
          })
        }
        if (versionSubQuestionId !== null) {
          dispatch(getNmscQuestionId(response.data[0].versionSubQuestionId))
        }
        dispatch({
          type: "EVALUATION_SUB_QUESTION",
          payload: response.data
        });
      })
      .catch(function (error) {
        //dispatch(setBlockingState(false));
        console.log(error);
      });
  };
}

export function submitEvaluation(evaluationDTO, versionId, chapterId, questionId, refreshHistory) {
  return function (dispatch) {
    let url = "/evaluation/submitEvaluation";
    axios
      .post(url, evaluationDTO, {
        headers: {
          'Authorization': 'Bearer ' + getToken(),
          "Content-type": "application/json",
          Accept: "application/json",
          Pragma: "no-cache"
        }
      })
      .then(response => {
        dispatch({
          type: "EVALUATION_ID",
          payload: response.data.desc
        });
        dispatch(getEvaluationChapters(versionId, evaluationDTO.activityId, chapterId, questionId,false,true));
        dispatch(getDealerSubQuestion(questionId, evaluationDTO.activityId));
        dispatch(fetchKaizenPoints(evaluationDTO.activityId))
        if (refreshHistory) {
          dispatch(getCommentsHistory(response.data.desc));
        }
        dispatch(getEvaluationProgressBar(evaluationDTO.activityId));
        // dispatch(getEvaluationAnsweredByActivity(evaluationDTO.activityId));
      })
      .catch(function (error) {
        toastr.error("" + error);
      });
  };
}

export function getCommentsHistory(evaluationId) {
  return function (dispatch) {
  dispatch(getCallWithReducer("/evaluation/commentHistory/" + evaluationId,null,"EVALUATION_HISTORY_COMMENT"))
  };
}

export function completeFirstAudit(completeAuditDto) {
 
  return function (dispatch) {
     axios
       .post("/activity/completeAudit", completeAuditDto,{
         headers: {
           'Authorization': 'Bearer ' + getToken(),
           Accept: "application/json",
           Pragma: "no-cache"
         }
       })
       .then(response => {
         dispatch({
           type:"COMPLETE_AUDIT" ,
           payload:response.data
         });
          if (response.data.statusCode === "200") {
                     toastr.success("First Audit has been completed successfullt");
          }
          dispatch(getEvaluationProgressBar(completeAuditDto.activityId));
       })
       .catch(function (error) {
        toastr.error("" + error);
       });
   };
 }
