import React from 'react';
import {
    Modal,
    ModalBody,
    ModalHeader
} from 'reactstrap';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
// import { saveKpi } from '../../actions/tabAction.jsx';
import ImportTablePage from '../nmscImportTable';
import './index.css';
import { withTheme } from '../../themes/theming';
var I18n = require('react-redux-i18n').I18n;

class ImportModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    }
    componentWillMount() {

    }
    preventDefault() { }
    render() {
        return (
            <Modal className={"importClass importClass-" + this.props.theme.name} isOpen="true" toggle={() => this.preventDefault()} ref="import">
                <ModalHeader toggle={this.props.toggle}>{I18n.t("tabsDetails.tmeKPIList")}</ModalHeader>
                <ModalBody>
                    <ImportTablePage toolboxId={this.props.toolboxId} toggle={this.props.toggle} masterKpi={this.props.masterKpiList} />
                </ModalBody>
            </Modal >
        );
    }
}
const mapStateToProps = (state) => {
    return {

    }
}
export default withRouter(connect(mapStateToProps)(withTheme(ImportModal)));