import React from "react";
import { withRouter } from "react-router-dom";
import { withTheme } from '../../themes/theming';
// import VideoPlayer from "react-video-js-player";


class BritDashboard extends React.Component {
    /* eslint-disable */
    constructor(props) {
        super(props);
    }
    render() {
        console.log(this.props.video)
        return (
            <React.Fragment>
                <div>
{/*                     <VideoPlayer className="britVideoPlayer"
                        src={this.props.video.filePath}
                        //  src={videoSrc}
                        width="720"
                        height="420"
                    /> */}
                </div>
                < img className="backToBritVideoTable" src={'images/back-' + this.props.theme.name + '.png'} alt="Back" onClick={this.props.onToggle} style={{
                    cursor: "pointer", position: "absolute",
                    bottom: "0rem"
                }} />
            </React.Fragment>
        )
    }
}

export default withRouter((withTheme(BritDashboard)));
