var I18n = require("react-redux-i18n").I18n;

export const Dashboard  = "Dashboard";
export const KPISetUp = "KPI Set-Up"; 
export const Evaluation  = "Evaluation";
export const BulkActions = "Bulk Actions"; 
export const Administration  = "Administration";
export const CEA_Admin_MENU = "CEA_Admin_MENU";
export const CEA_BO_MENU = "CEA_BO_MENU";
export const CEA_NMSC_MENU = "CEA_NMSC_MENU";
export const CEA_NMSC_USER_MENU = "CEA_NMSC_USER_MENU";
export const CEA_USER_MENU = "CEA_USER_MENU";


export const CEA_ROLES = {
    KET_CEA_Admin : "KET-CEA Admin",
    KET_CEA_BO : "KET-CEA Business Owner",
    KET_CEA_NMSC_Admin : "KET-CEA NMSC Admin",
    KET_NMSC_User : "KET-CEA NMSC User",
    KET_CEA_User : "KET-CEA User"
};

export const NET_ROLES = {
    KET_NET_Admin : "KET-NET Admin",
    KET_NET_BO : "KET-NET Business Owner",
    KET_NET_NMSC_Admin : "KET-NET NMSC Admin",
    KET_NET_NMSC_User : "KET-NET NMSC User",
    KET_NET_User : "KET-NET User"
};

export const UC_ROLES = {
  KET_UC_Admin : "KET-UC Admin",
  KET_UC_NMSC_User : "KET-UC NMSC User",
  KET_UC_User : "KET-UC Dealer"
};

export const LN_ROLES = {
  KET_LEXUS_Admin : "KET-LEXUS Admin",
  KET_LEXUS_User : "KET-LEXUS User",
  KET_LEXUS_NMSC_User : "KET-LEXUS NMSC User"
};

export const REACHIT_ROLES = {
  KET_REACHIT_Admin: "KET-ReachIT Admin",
  KET_REACHIT_NMSC_Admin: "KET-ReachIT NMSC User",
  KET_REACHIT_Dealer_Champion: "KET-ReachIT Dealer",
};

export const EA_ROLES = {
  KET_EA_Admin: "KET-EA Admin",
  KET_EA_NMSC_Admin: "KET-EA NMSC Admin",
  KET_EA_Dealer_Champion: "KET-EA Dealer Champion"
};
export const TSP_ROLES ={
  KET_TSP_Auditor: "KET-TSP Auditor"
}
// need to check
export const activeProgramCea = "CEA_"
export const activeProgramNet = "NET"
export const activeProgramUc = "UC"
export const activeProgramLN = "LN"
export const activeProgramEa = "EA"
export const activeProgramNas = "NAS"
export const DEFAULT_ALL_OPTION = {"value": "All" , "label":I18n.t('standardMessages.all'),"selected":"selected"}
export const DEFAULT_ALL_OBJ = {"value": "All" , "label":I18n.t('standardMessages.all')}
export const DEFAULT_ALL = "All"
export const BRAND_TOYOTA = "Toyota"
export const BRAND_LEXUS = "Lexus"
export const ACTIVITY_STATUS = "CLOSED"
export const ACTIVITY_STATUS_REJECTED = "REJECTED"
export const TYPE_CEA = "CEA"
export const TYPE_NET = "NET"
export const TYPE_UC = "UC"
export const NMSC_STATUS_LAUNCH = "FOR ACCEPTANCE"
export const NMSC_STATUS_ACCEPT = "ACCEPTED"
export const NMSC_STATUS_REJECT = "REJECTED"
export const MIN_YEAR_OFFSET = 0;
export const MAX_YEAR_OFFSET = 5;

// export const BUSINESS_AREA_VALUE_CHAIN = "Value Chain"
// export const BUSINESS_AREA_BRIT = "BriT"

export const BUSINESS_AREA_VALUE_CHAIN = "VALUE_CHAIN"
export const BUSINESS_AREA_BRIT = "BRiT"
export const BUSINESS_AREA_EA = "EA"
export const KET_TME_ADMIN = "KET TME Admin"
export const KET_NMSC_ADMIN = "KET NMSC Admin"
export const businessAreaKeyPer = "PER"
export const businessAreaKeyEa = "EA"
export const programNameNet = "Networks"
export const programNameUc = "Used Cars"
export const programNameLN = "LEXUS NETWORK"
export const paramKey = "total"
export const pointMsg1 =" Points will be allocated for the "
export const pointMsg2 =" Total points allocated will be "
export const pointMsg3 =" Points once allocated cannot be changed. Are you sure you want to continue? "
export const yearWarningMsg ="Warning, you are entering previous years with already published data. "
export const confirmtxt = "Confirm"
export const cancelTxt = "Cancel"
export const kpiKey = "total"
export const recertificationRate ="RE_CERTIFICATION_RATE"
export const KET_TSP_AUDITOR ="KET TSP Auditor"
export const BUSINESS_AREA_TSP = "TSP"
export const programNameTSP="Customer Service Quality Audit"
export const KET_Dealer_Champion = "KET Dealer Champion"