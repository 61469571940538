import React from "react";
import { connect } from "react-redux";
import { Container, Row, Col } from "reactstrap";
import { withRouter } from "react-router-dom";
import { withTheme } from "../../themes/theming";
import "./index.css";
import * as AppConst from "../../AppConstant";
import LatestFilePanel from "./components/latestFilePanel";
import HistoryFileTable from "./components/historyFileTable";
import { getMonthlyUploadedFilesData } from "../../actions/dashboardAction";
let I18n = require("react-redux-i18n").I18n;
class ReachItDashboard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      monthlyFileList: [],
    };
  }
  componentDidMount() {
    let userRole = this.props.user.userMdl.roles.includes(
      AppConst.REACHIT_ROLES.KET_REACHIT_Admin
    )
      ? "admin"
      : this.props.user.userMdl.roles.includes(
          AppConst.REACHIT_ROLES.KET_REACHIT_NMSC_Admin
        )
      ? this.props.user.userMdl.nmsc
      : "dealer";
    this.props.dispatch(getMonthlyUploadedFilesData(userRole));
  }
  redirectFunc = () => {
    window.open('https://teams.microsoft.com/l/channel/19%3a514bc430ede443888353181f276bc8b6%40thread.tacv2/Compliance?groupId=66cc630d-0c22-442e-89c6-18e649f39a9d&tenantId=52b742d1-3dc2-47ac-bf03-609c83d9df9f')  
  };
  render() {
    return (
      <Container fluid className="ml-0 mb-1">
        <Row className="mr-0">
          <Col md="12" className="paddingTab uploadContainer">
            <div className="monthlyFileContainer">
              {this.props.user.userMdl.roles.includes(
                AppConst.REACHIT_ROLES.KET_REACHIT_Admin
              ) ? (
                ""
              ) : (
                <div>
                  <Row className="mt-2 mb-2">
                    <Col>{I18n.t("reachItDashboard.reachText1")}</Col>
                  </Row>
                  <Row className="mt-2 mb-2">
                    <Col>
                      {this.props.user.userMdl.roles.includes(
                        AppConst.REACHIT_ROLES.KET_REACHIT_NMSC_Admin
                      )
                        ? I18n.t("reachItDashboard.reachText2")
                        : I18n.t("reachItDashboard.reachText3")}
                    </Col>
                  </Row>
                  <Row className="mt-2 mb-2">
                    <Col>
                      {this.props.user.userMdl.roles.includes(
                        AppConst.REACHIT_ROLES.KET_REACHIT_NMSC_Admin
                      ) ? (
                        <a
                          style={{
                            textDecoration: "underline",
                            color: "cornflowerblue",
                          }}
                          onClick={(e) => this.redirectFunc()}
                        >
                          {
                            "https://teams.microsoft.com/l/channel/19%3a514bc430ede443888353181f276bc8b6%40thread.tacv2/Compliance?groupId=66cc630d-0c22-442e-89c6-18e649f39a9d&tenantId=52b742d1-3dc2-47ac-bf03-609c83d9df9f"
                          }
                        </a>
                      ) : (
                        ""
                      )}
                    </Col>
                  </Row>
                </div>
              )}
              <div>
                <LatestFilePanel />
                <HistoryFileTable />
              </div>
            </div>
            <br />
          </Col>
        </Row>
      </Container>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    user: state.loginReducer,
    blocking: state.loginReducer,
    router: state.routing,
  };
};
export default withRouter(
  connect(mapStateToProps)(withTheme(ReachItDashboard))
);
