const translationsNL = {
  nl: {
    Policy: {
      HeaderMsg: "Privacymededeling verband houdende met het verwerken van uw persoonlijke gegevens in het kader van de Kaizen Expert Tool.",
      Message1: "Deze privacymededeling staat in verband met het verwerken van uw persoonlijke gegevens in het kader van de Kaizen Expert Tool. Het moet gezien worden in relatie met het algemeen Toyota privacy beleid waarin de algemene bepalingen over persoonlijke gegevensverwerking  omschreven staan, en die u dient te acepteren voor u de volgende stap neemt.",
      Message2: "Toyota Motor Europe NV, met hoofdkantoor in Bourgetlaan 60, 1140 Brussel, België, vergaart uw persoonlijke gegevens als gegevensbeheerder - zoals hieronder vermeld - met als doeleind zoals beschreven hieronder.",
      Message3: "Welke persoonlijke gegevens zullen worden opgehaald?",
      Message4: "Volgende categoriën van uw persoonlijke gegevens worden verzameld met betrekking tot de Kaizen Expert Tool.",
      Table1: {
        tr1: "Persoonlijke gegevens categorie(ën)",
        tr2: "Gebruikersnaam",
        tr3: "Volledige naam gebruilker",
        tr4: "Merk (Toyota, Lexus)",
        tr5: "Voorkeurtaal",
        tr6: "Aanverwant bedrijf"
      },

      Message5: "Doelstelling en legale grond van de verwerking van uw persoonlijke gegevens.",
      Message6: "Wij zullen de vlogende categorieën van uw persoonlijke gegevens verzamelen gerelateerd aan de Kaizen Expert Tool:",
      Table2: {
        tr1: {
          th1: "Doelstelling:",
          th2: "Legale grond:"
        },
        tr2: {
          td1: "KET - 1: Garagebedrijf Prestatie Kaizen",
          td2: "Gelegitimeerd belang nagestreefd door de gegevensbeheerders:"
        },
        tr3: {
          td1: "KET - 2: Informatica ondersteuning",
          td2: "Gelegitimeerd belang nagestreefd door de gegevensbeheerders:"
        }
      },
      Message7: "Duur van behouden van uw persoonlijke gegevens",
      Message8: "Uw persoonlijke gegevens zullen bewaard blijven voor de volgende tijdspanne:",
      Table3: {
        tr1: {
          th1: "Behoudperiode:",
          th2: "Persoonlijke gegevens categorie(ën)"
        },
        tr2: {
          td1: "De persoonlijke gegevens zoals hoger omschreven zal voor maximaal 61 maanden vanaf de dag van het stopzetten van de Kaizen Expert Tool, behouden blijven.",
          td2: "Gebruikersnaam, achternaam, voorkeurtaal, organisatie"
        }
      },
      Message9: "Facultatief: in het geval van openbaarmaking / overbrenging van uw persoonlijke gegevens naar landen buiten de Europese Economische Ruimte (EER)",
      Message10: "Uw persoonlijke gegevens zullen toegankelijk gemaakt worden aan begunstigden in landen buiten de Europese Econimische Ruimte (EER)",
      Table4: {
        tr1: {
          td1: "Indentiteit",
          td2: "Land",
          td3: ""
        },
        tr2: {
          td1: "Niet-EER begunstigde(n)",
          td2: "Infosys",
          td3: "India"
        }
      },
      Message11: "Bij deze bevestig ik deze mededeling begrepen te hebben; ik heb zowel het algemeen Toyota privacy beleid als persoonlijke gegevensbecherming beleid gelezen en goedgekeurd."
    },
    ACCESSORIES: "Accessoires",
    activity: {
      Assignee: "Toegekend aan",
      CompletionDate: "Vervolledigd op",
      Dealer: "Servicepunt",
      FirstVisit: "Eerste bezoek",
      kpi: {
        kpi: "KPI zijn positieve of negatieve getallen",
        kpiLength: "Maximum lengte van de KPI is 10 cijfers (en komma)",
        mandatory: "Voer geldige gegevens in alle verplichte velden in",
        mandatoryTargets: "Gelieve alle KPI in te geven",
        target: "Doelstelling moet een getal zijn",
        targetLength: "Maximum lengte van de doelstelling is 10 cijfers (en komma)"
      },
      ReminderOn: "Herinnering op",
      statusDD: "Stand van zaken",
      Toolbox: "Toolbox",
      activityCreation: "Activiteitscreatie proces is gestart",
      activityCreated: "Activiteit(en) is (zijn) gecreëerd"
    },
    DPOK: "DPOK",
    EFFICIENCY: "Efficiëntie",
    FIX_IT_RIGHT: "Fix-it-Right",
    footer: {
      message: "Verplichte velden"
    },
    FOUNDATION: "Basis",
    MAINTENANCE_REMINDERS: "Maintenance Reminder",
    EMPLOYEE_ENGAGEMENT: "Employee Engagement",
    CUSTOMER_CENTRICITY: "Customer Centricity",
    TOYOTA_WAY: "Toyota Way",
    BUSINESS_SUSTAINABILITY: "Business Sustainability",
    FUNDAMENTALS: "Basis",
    FIX_IT_RIGHT_BnP: "Fix it Right (B&P)",
    UTILIZATION_AND_EFFICIENCY: "Utilization and Efficiency",
    CUSTOMER_EXPERIENCE: "Customer Experience",
    UPSELLING_OPPORTUNITIES: "Upselling Opportunities",
    MANAGING_CAPACITY: "Managing Capacity",
    VEHICLE_TRADE_IN: "Vehicle Trade-In",
    STOCK_MANAGEMENT: "Stock Management",
    REFURBISHMENT: "Refurbishment",
    months: {
      apr: "apr",
      aug: "aug",
      dec: "dec",
      feb: "feb",
      jan: "jan",
      jul: "jul",
      jun: "jun",
      mar: "mrt",
      may: "mei",
      nov: "nov",
      oct: "okt",
      sep: "sep"
    },
    others: {
      back: "Terug",
      closed: "Afgesloten",
      completed: "Voltooid ",
      countryLanguageCode: "Land / Taalcode ",
      createActivity: "Activiteit creëren",
      createdDate: "Datum aangemaakt",
      dataUneditableNote:
        "N.B.: Eenmaal bewaard kunnen de  gegevens niet meer worden bewerkt. Om de gegevens weer te kunnen bewerken, moet u contact opnemen met NMSC admin.",
      date: "Datum",
      downloadQuestionnaire: "Vragenlijst downloaden",
      editActivity: "Activiteit bewerken",
      initialHelpText:
        "De initiële KPI-meting dient de gemiddelde waarde te zijn van de voorafgaande zes afgesloten maanden",
      inProgress: "Lopend",
      name: "Naam",
      next: "Volgende",
      noActivities: "Geen Activiteiten gevonden",
      noResults: "Geen Resultaten",
      of: "van",
      ongoing: "Bezig",
      open: "Openen",
      page: "Bladzijde",
      planned: "Gepland",
      problemDescription: "Probleemomschrijving",
      signature: "Handtekening",
      select: "Selecteer",
      bulkAction: "Bulk Action",
      bulkActions: "Bulk Actions"
    },
    ServerMessage: {
      error_message: "Er is iets fout gegaan tijdens het dwonloaden.",
      no_published_subquestion: "Gelieve voor deze toolbox de Vragenlijst uit te brengen zodat u kan starten met deze Aktiviteit.",
      activity_upload_error_msg: "Fout tijdens het uploaden van Aktiviteitengegevens.",
      action_plan_created: "Actieplan succesvol gecreëerd",
      action_plan_deleted: "Actieplan succesvol verwijderd",
      action_plan_saved: "Actieplan succesvol bewaard",
      activity_kpi_saved: "Activiteiten KPI succesvol bewaard",
      master_kpi_confirm_delete: "Are you sure you want to delete the selected KPI's?",
      master_kpi_created: "Stam KPI aangemaakt",
      master_kpi_deleted: "Stam KPI gewist",
      master_kpi_not_found: "Stam KPI niet gevonden",
      master_kpi_published: "Geselecteerde Master KPI’s gepubliceerd",
      master_kpi_retired: "Stam KPI teruggetrokken",
      master_kpi_saved: "Stam KPI opgeslagen",
      subquestion_created: "De deelvraag is bewaard.",
      subquestion_updated: "De deelvraag is bijgewerkt.",
      subquestionnaire_not_found: "De deelvraag is niet teruggevonden.",
      subquestion_retired: "De deelvraag is teruggetrokken.",
      subquestion_deleted: "De deelvraag is verwijderd.",
      subquestion_published: "De deelvragen zijn uitgegeven.",
      mainquestion_created: "De hoofdvragenlijst is gecreëerd.",
      mainquestion_deleted: "De hoofdvragenlijst is gecreëerd.",
      mainquestion_updated: "De hoofdvraag is bijgewerkt.",
      duplicateMsg: "Dupliceer records in het Excelbestand.",
      deleteErr: "Er is een fout opgetreden tijdens het wissen van het bestand",
      toolbox_deleted: "De toolbox is succesvol gewist",
      toolbox_already_exists: "Deze toolbox bestaat al",
      toolbox_saved: "Toolbox bewaard",
      toolbox_created: "Toolbox gecreëerd",
      toolbox_not_found: "Toolbox niet gevonden",
      published_content_found: "Deze toolbox kan niet gewist worden omdat het reeds gepubliceerde inhoud heeft",
      dealers_already_mapped: " Dealers already mapped ",
      dealer_already_mapped: " Dealer already mapped ",
      dealer_added: "  Dealer added. ",
      dealers_added: "  Dealer added. ",
      total_dealers_mapped_now: "Total dealers mapped now are ",
      no_dealer_available: "No Dealer available for this assign criteria",
      proper_dealer_selected: "Proper dealer shall be selected",
      user_referenced_ongoing_activity: "User is referenced in Ongoing activity/activities. ",
      want_to_remove_mapping: "Are you sure you want to remove mapping?",
      user_mapping_removed: "Selected dealers unmapped successfully",
      dealer_closed_in_A2D: "Dealer not active in A2D",
      network_level_not_found: "Please select valid Network levels",
      duplicate_dealer_uuid: "Dealer already available",
      dealer_created: "Dealer Created",
      dealer_updated: "Dealer Updated",
      dealer_not_present: "Dealer not present in A2D",
      dealer_uuid_other_nmsc: "Please Enter Dealer Uuid for Same NMSC",
      target_kpi_updated: "The KPI's status have been updated successfully.",
      target_updated: "The KPI's target have been updated successfully.",
      kpi_launched: "The KPI's bulk launch successfully.",
      targetUpdate:"Application doesn't allow target to be modified for accepted status.",
    },
    standardMessages: {
      delete: "Zeker om te wissen?",
      addActionPlanNotAllowed: "Het Aktieplan kan niet worden gecreëerd noch gewijzigd nadat de Aktiviteit is Afgesloten, Voltooid of Verworpen.",
      addToolboxNotAllowed: "Cannot create more than 19 toolbox",
      britRejectMsg: "Zeker dat u deze Aktiviteit wenst te Verwerpen? Na het verwepen kan u geen wijzigningen meer aanbrengen.",
      rejectMsg: "Zeker dat u deze Aktiviteit wenst te Verwerpen? Na het verwepen kan u geen wijzigningen meer aanbrengen.",
      selectNMSCTab: "Gelieve de NMSC-taal te selecteren voor publicatie",
      all: "Alles",
      deleteConfirm: "Weet u zeker dat u de geselecteerde KPI wilt wissen?",
      no: "Nee",
      retireConfirm: "Weet u zeker dat u deze KPI wilt terugtrekken? Deze KPI kan niet meer worden gereactiveerd nadat deze is teruggetrokken",
      warning: "Waarschuwing",
      yes: "Ja",
      saveDataConfirmation: "Weet u zeker dat u de gegevens wilt bewaren?",
      saveKPIWarning: "Note:Data cannot be edited once saved. To modify the data please contact the NMSC KET admin",
      addKpiNotAllowed: "KPI voor de volgende maand kunnen ingebracht  worden wanneer de huidige maand eindigt.",
      activityLaunch: "Zeker dat u deze Aktiviteit wil uitbrengen? Nadat u het uitbrengt kan de Aktiviteit niet teruggezet worden naar Geplande status, of Wissen.",
      deleteConfirmSubQuestionNaire: "Merk (Toyota, Lexus)",
      retireConfirmSubQuestionNaire: "Bent u zeker dat u de geslecteerde deelvraag wil terugtrekken?",
      deleteConfirmMainQuestion: "Gelieve te bevestigen dat u de hoofdvraag en zijn deelvragen wilt verwijderen.",
      deleteConfirmChapter: "Bent u zeker dat u dit hoofdstuk wilt verwijderen?",
      deleteLibraryFolder: "Bent u zeker dat u deze map wilt verwijderen?",
      deletePublishedFolder: "Bepaalde bestanden in deze map kunnen gekoppeld zijn aan de Vragenlijst. Bent u zeker om deze submap voorgoed te wissen waardoor de koppelingen met de Vragenlijst verdwijnen?",
      importQuestion: "Bent u zeker om deze deelvraag in te voeren? De huidige deelvraag zal worden vervangen.",
      noQuestion: "Geen vragen beschikbaar",
      MandatoryWarning: "Gelieve de verplicht velden in te vullen voor al uw lokale talen.",
      certificationSubmission:
        "Toolbox is verzonden naar TME ter certificering van de dealer",
      sameToolBox: "U kan een toolbox maar één keer verzenden. Gelieve een andere toolbox te selecteren.",
      deleteConfirmDealer: "Are you sure you want to delete this dealer ?",
      updateVcConfirmDealer: "Alle activiteiten voor deze dealer in 'Waardeketen' worden hierdoor verwijderd. Wilt u doorgaan?",
      updateBritConfirmDealer: "Alle activiteiten voor deze dealer in 'BRiT' worden hierdoor verwijderd. Wilt u doorgaan?",
      completed_activity_delete:"De gegevens kunnen niet meer teruggehaald worden wanneer ze gewist zijn. Wilt u wissen?",
      deleteAllQuestionary: "Are you sure you want to delete the entire Questionnaire?",
      deleteAllLibraries: "Are you sure you want to delete all folders and files in the Library?",
      selectAll: "Select All",
      unSelectAll: "Unselect All",
      select:"Select...",
      warningMsg:"There are Open Action plan(s) in this activity. Would you like to Complete the activity?"
    },
    status: {
      published: "Gepubliceerd",
      retired: "Teruggetrokken",
      saved: "Bewaard",
      rejected: "Verworpen",
      open: "Openen",
      closed: "Afgesloten",
      close: "Afgesloten",
      ongoing: "Bezig",
      completed: "Voltooid",
      complete: "Voltooid",
      planned: "Gepland",
      overdue: "Verlopen",
      due: "Binnen tijdsbestek",
      pending: "Pending",
    },
    Rejected: "Rejected",
    Open: "Openen",
    Closed: "Afgesloten",
    Close: "Afgesloten",
    Ongoing: "Bezig",
    Completed: "Voltooid",
    Complete: "Voltooid",
    Planned: "Gepland",
    tabDetails: {
      noActionAvailable: "Geen Acties beschikbaar",
      activeDealer: "Dealer is active.",
      dealerDeleted: "Dealer is deleted."
    },
    Table: {
      action: "",
      activity: "Activiteit",
      activityCompletionDate: "Einddatum activiteit",
      activityCompletionDateFrom: "Einddatum activiteit vanaf",
      activityCompletionDateTo: "Einddatum activiteit tot",
      activityStartDate: "Startdatum activiteit",
      activityStartDateFrom: "Startdatum activiteit vanaf",
      activityStartDateTo: "Startdatum activiteit tot",
      assignedTo: "Toegekend aan",
      assignee: "Toegekend aan",
      benchmark: "Referentiepunt",
      city: "Stad",
      completedBy: "Voltooid door ",
      completionBy: "Vervolledigd door",
      completionDate: "Vervolledigd op",
      counterMeasure: "Counter Measure",
      country: "Land",
      createdBy: "Aangemaakt door",
      dealer: "Servicepunt",
      dealerNameAD: "Dealer name (A2D)",
      dealerName: "Naam dealer",
      definition: "Definitie",
      description: "Omschrijving",
      dueDate: "Geplande datum",
      endDate: "Einddatum",
      firstVisit: "Eerste bezoek",
      followup: "Opvolging",
      franchise: "Franchise",
      history: "Historie",
      id: "ID",
      initial: "Bij start",
      kpi: "KPI",
      launch: "Uitbrengen",
      mandatory: "Verplicht",
      nmsc: "NMSC",
      problem: "Storing",
      publishedOn: "Gepubliceerd op",
      questionReference: "Vraag referentie",
      reference: "Referentie",
      reminderOn: "Herinnering op",
      score: "Score",
      startDate: "Startdatum",
      status: "Stand van zaken",
      target: "Target",
      toolbox: "Toolbox",
      view: "Beeld",
      kpiResult: "KPI resultaten",
      nmscTarget: "NMSC Target",
      penEbenchmark: "PanE Benchmark",
      kpiField: "KPI",
      location: "Plaats",
      ActionItemReport: "Aktiepunten in uitvoering",
      certification: "Certificatie stand van zaken",
      noOfToolbox: "Aantal Volooide tolboxen",
      program: "Programma",
      certificationDate: "Datum van certificatie",
      activityId : 'Activiteit ID',
      approvedBy:"Approved By",
      kpis:"KPIs",
      lastmonthKpis:"Last Month KPIs",
      questionPercent:"Completion %",
      actionPlan: "Actieplan",
      monthlyKpi:"Monthly KPIs",
      initialKpi:"Initial KPIs",
      targetKpi:"Target KPIs",
     },
     tabsDetails: {
      actionPlan: "Actieplan",
      confirmDeleteMessage: "Weet u zeker dat u de geselecteerde KPI wilt wissen?",
      confirmRetireMessage: "Bevestig dat u de geselecteerde KPI wilt terugtrekken omdat deze reeds eerder is gepubliceerd",
      errorRetireMsg: "Deze KPI kan niet worden teruggetrokken omdat deze een actieve, verplichte TME KPI is",
      import: "Invoeren",
      importKpiMessage: "Gelieve uw KPI van de TME KPI Stamlijst in te voeren",
      kpi: "Kritieke Prestatie-Indicatoren (KPI)",
      library: "Bibliotheek",
      noActionAvailable: "Geen Acties beschikbaar",
      NoKPI: "Geen KPI’s beschikbaar",
      publish: "Publiceren",
      questionnaire: "Vragenlijst",
      tmeKPIList: "TME KPI Stamlijst",
      toyotaCityBrussels: "TOYOTA CITY BRUSSELS",
      attachment: "Attachment",
      attachments: "Attachment(s)",
      uploadAttachment: "Upload Attachment",
      noAttachment: "No attachments available",
    },
    tmeAdminMaster: {
      ToolboxName: "Naam toolbox",
      newToolboxName: "Nieuwe naam",
      deleteToolbox: "Toolbox wissen?",
      programName: "Programma naam",
      ToolboxName_EN: "Naam toolbox (EN)",
      deleteTool: "Zeker om deze toolbox te wissen?",
      selectToolbox: "Gelieve de te wissen toolbox te selecteren.",
      editToolBox: "Bewerk de toolbox",
      addToolbox: "Voeg toolbox toe",
      toolbox: "Gelieve een naam te geven aan deze toolbox.",
      addActionPlan: "Add Actieplan",
      actionPlanItem: "Actieplan - itemnr.",
      add: "Voeg toe",
      addActivity: "Activiteit toevoegen",
      additionalInformation: "Bijkomende informatie",
      benchmark: "Referentiepunt",
      cancel: "Annuleren",
      createEditActivity: "Activiteit aanmaken / bewerken",
      createKPI: "KPI aanmaken",
      delete: "Wissen",
      download: "Download",
      edit: "Bewerken",
      editKPI: "KPI bewerken",
      keyPerformanceIndicator: "Kritieke Prestatie-Indicatoren (KPI)",
      kpiDefinition: "KPI-definitie",
      mandatoryKpi: "Verplichte KPI",
      save: "Bewaren",
      search: "Zoeken",
      unit: "Eenheid",
      update: "Bijwerken",
      validData: "Vul geldige gegevens in",
      hide: "Verberg",
      show: "Toon",
      upload_dealer_network: "Upload naar dealernetwerk",
      upload_question: "Upload vragenlijst",
      upload_user: "Upload gebruikergegevens",
      upload_activity: "Upload Aktiviteiten",
      remove: "Remove",
      toyota: "Toyota",
      lexus: "Lexus",
      launch: "Launch",
      close: "Close",
      generate: "Generate",
      dueDateExceed:"Due date exceeded",
      delQuestionnaire:"Delete questionnaire"
    },
    toastr: {
      activityStatus_condition: "Gelieve al de vragen in de Vragenlijst te beantwworden zodat u deze Aktiviteit kan afsluiten.",
      emptyTarget: "Please enter the NMSC target to publish the KPI",
      retailerActual: "Please enter the Retailer Actual",
      initialTargetMonthly: "Please enter the initial KPI measurement, the KPI targets and at least 1 month of measurement to continue.",
      initialTarget: "Gelieve de initiële KPI alsook de doelstelling KPI in te geven, voor verder te gaan.",
      upload_error: "Fout tijdens uploaden van het Dealernetwerk",
      action_item_create_warning: "Maximum vijf records kunnen samengevoegd worden in een aktiepunt.",
      actionPlanStartDateValidation: "Einddatum kan niet voor de Startdatum liggen",
      actionsNotClosed: "Er zijn Acties in bewerking in het Actieplan; sluit alle Acties voordat u een Activiteit sluit",
      activityDuplicate: "Een andere Activiteit voor dezelfde Dealer en Toolbox bestaat reeds",
      assigneeNotExist: "De geselecteerde Toegekende is niet bekend in het systeem",
      assigneeValidation: "Ongeldige Toegekende",
      atleastOnePublish: "Selecteer tenminste één KPI om te publiceren",
      cannotRetireActive: "Dit is een actieve, verplichte TME KPI die niet kan worden teruggetrokken ",
      completionDate: "De Einddatum kan niet voor de datum van het eerste bezoek liggen",
      confirDeletePlannedActivity: "Weet u zeker dat u deze geplande Activiteit wilt wissen?",
      DateformatValidation: "De datum moet voldoen aan opmaak DD/MM/JJ",
      DateValidation: "Startdatum moet voor de einddatum zijn",
      dealerValidation: "Ongeldige Dealer",
      deleteSelectedConfirm: "Weet u zeker dat u de geselecteerde KPI wilt wissen?",
      duplicate: "Een andere KPI met dezelfde naam en definitie bestaat reeds",
      modalMandatory: "Voer geldige gegevens in alle verplichte velden in",
      noPublishKpi: "De KPI’s zijn niet gepubliceerd in de geselecteerde Toolbox",
      validSearchCriteria: "Vul een geldig zoekcriterium in",
      multiSelectPlaceHolder: "Geef minstens 5 letters in",
      multiSelectPlaceHolderUser: "Geef minstens 3 letters in",
      startDateValidation: "Startdatum kan niet later dan einddatum zijn",
      fileSelectionErr: "Gelieve eerst het bestand te selecteren",
      atleastOneQuestion: "Gelieve minstens één deelvraag te selecteren om in te voeren",
      atleastOneSubQues: "Gelieve minstens één deelvraag te publiceren",
      uploadErr: "Aantal niet geüploade bestanden:",
      uploadSuccess: "Upload Dealernetwerk is geslaagd.",
      file_upload_success: "De bastandenden zijn geüpload met succes.",
      KPIinOngoingClosedActivity: "Deze KPI bevindt zich reeds in één of meerdere Bezig of Afgesloten activiteiten. Gelieve deze activiteit(en) te voltooien voor u de KPI wijzigt.",
      Role_Error: "The user setup is incomplete, please contact the NMSC KET Admin.",
      points_allocation_incorrect: "Points allocation is incorrect. Please modify.",
      activity_deleted:"De activiteit is succesvol gewist",
      activity_certified_dealer:"De dealer is reeds gecertificeerd voor deze activiteit, zodat hij niet gewist kan worden.",
      activity_submited_for_certificate_dealer:"Deze activiteit werd reeds toegevoegd aan het certificatieverzoek naar TME waardoor de stand van zaken niet meer gewijzigd kan worden."
    },
    TopNav: {
      ActivityLog: "Activiteiten",
      ApplicationName: "Kaizen Expert Tool",
      Home: "Startpagina",
      logout: "Afmelden",
      MasterDataUpload: "Stamgegevens uploaden",
      Toolbox: "Toolbox",
      welcome: "Welkom",
      Admin: "Administratie"
    },
    questionnaire: {
      addSubQuestion: "Voeg deelvraag toe",
      editSubQuestion: "Bewerk deelvraag",
      subQuestionTitle: "Deelvraag",
      additionalInfo: "Bijkomende informatie",
      na: "Voeg 'niet van toepassing' toe als mogelijk antwoord op deze vraag",
      question: "Vraag",
      questionTitle: "Titel van de vraag",
      why: "Waarom?",
      addMainQuestion: "Voeg hoofdvraag toe",
      editMainQuestion: "Bewerk hoofdvraag",
      previousQuestion: "Vorige vraag",
      nextQuestion: "Volgende vraag",
      what: "Wat?",
      library: "Bibliotheek",
      subQuestionTable: {
        no: "Nee.",
        subQuestion: "Deelvragen",
        status: "Stand van zaken",
        publishedDate: "Uitgegeven datum",
        notApplicable: "Niet van toepassing"
      },
      links: {
        question_link_button: "Koppeling",
        question_link_include_folder: "Voeg een submap bij",
        question_link_add_link: "Koppel bestand(en) van de Bibliotheek",
        question_link_view_folder: "Bekijk Bibliotheek"
      },
      mainQuestion: "Nieuwe / bijgewerkte hoofdvragen:",
      subQuestion: "Nieuwe / bijgewerkte deelvragen:",
      retiredQuestion: "Teruggetrokken deelvragen:",
      libraries: "Nieuwe / bijgewerkte bestanden in bibliotheek:",
      noFiles: "Aantal beschikbare bestanden."
    },
    library: {
      selectFile: "Gelieve minstens één bestand te selecteren.",
      fileSelected: "Bestanden geselecteerd",
      publish_library_file: "Gelieve tenminste één bestand uit te brengen",
      library_files_deleted: "Bent u zeker dat u dit bestand wil wissen?",
      library_files_linked_deleted: "Zeker dat u dit bestand wil wissen alsook de koppelingen met de Vragenlijst?",
      max_size_error: "De omvang van de bestanden is hoger dan de toegestande 1 Gb per upload.",
      empty_file_desc: "Gelieve een bestandsomschrijving te geven",
      file_not_selected: "Geleive de te uploaden bestand(en) te selecteren (max. 1 Gb)",
      file_type_error: "Dit bestandstype is niet toegestaan",
      file_name_error: "Ongeldig karakter in de bestandsnaam",
      file_name_lenght_error: "Bestandsnaam kan uit maximaal 50 karakters bestaan",
      addFolder: "Voeg map toe",
      editFolder: "Bewerk map",
      currentFolder: "Mapnaam",
      parentFolder: "Naam bovenste map",
      emptyFolderName: "Mapnaam moet ingevuld zijn",
      invalidFolderName: "De foldernaam mag geen '/' bevatten.",
      folder_created: "De map is gecreëerd",
      folder_updated: "De map is bijgewerkt",
      folder_not_found: "Map niet gevonden",
      lib_invalid_toolbox: "Ongeldige toolbox geselecteerd",
      lib_folder_already_exists: "Deze map bestaat al",
      lib_folder_deleted: "Map is verwijderd",
      library_files_published: "De bestanden zijn uitgegeven",
      addFile: "Voeg bestand toe",
      editFile: "Bewerk bestand",
      fileName: "Bestandsnaam",
      upload: "Upload",
      desc: "Omschrijving",
      folderCreationErr: "De hiërarchie is beperkt tot twee niveaus",
      subFolderWarning: "Gelieve een submap te selecteren om de bestanden aan toe te voegen.",
      deleteWarning: "Geleive een map te selecteren om te wissen.",
      beforeAfterNotSelected: "Please select if the evidence is before or after kaizen",
    },
    chapter: {
      chapter_exist: "Dit hoofdstuk bestaat al",
      chapter_saved: "Hoofdstuk bewaard",
      chapter_cannot_delete: "Dit hoofdstuk kan niet verwijderd worden omdat de deelvragen al uitgegeven zijn",
      failed: "Mislukt!",
      chapter_deleted: "Hoofdstuk verwijderd",
      chapter_nonEmpty: "Gelieve een naam voor het hoofdstuk te creëren.",
      addchapter: "Voeg hoofdstuk toe",
      editChapter: "Bewerk hoofdstuk",
      DELETE_SUCC:"Questionnaires deleted successfully.",
      DELETE_SUCC_LIB:"Libraries deleted successfully.",
      DELETE_ERR : "Something Went wrong."
    },
    evaluation: {
      attachments: "Bijlagen",
      comments: "Opmerkingen",
      evidence: "Bewijs",
      evidences: "Bewijs",
      yes: "Ja",
      no: "Nee",
      na: "N/A",
      uploadEvidence: "Opload bewijs",
      fileUpload: "Select File to Upload",
      who: "Wie?",
      answer: "Antwoord",
      comment: "Comment",
      date: "Datum",
      submit: "Verzend",
      commentMsg: "Gelieve opmerkingen in te geven.",
      noCommentAvailable: "Geen opmerkingen beschikbaar.",
      uploadSuccessMsg: "File(s)  have been uploaded successfully",
      uploadErrorMsg: "Number of files failing to upload:",
      fileWithSameName: "Deze bestandsnaam bestaat al.",
      noEvidence: "Geen bewijssukken beschikbaar",
      createAction: "Creëer aktiepunt",
      chapterName: "Naam hoofdstuk",
      questionNo: "Vraagnummer",
      when: "Wanneer?",
      noRecords: "Geen bestanden beschikbaar.",
      kaizenPoints: "Kaizenpunten",
      renameSuccessMsg: "File has been renamed successfully",
      deleteSuccessMsg: "File has been deleted successfully",
      question: "Vraag",
      before:"Before",
      after:"After",
      beforeAfterLabel:"Is this evidence from before or after kaizen?",
      move:"Move",
      moveMsg: "File(s) has been moved successfully",
      noToYesMsg:"Previous answer was NO"
    },
    WALK_AROUND_CHECK: "Walk Around Check",
    ketProgram: {
      TSM: "TSM/LSM",
      BRiT: "BRiT RI",
      TSM_BNP: "Autoschade",
      TSM_USED_CAR: "Tweedehands auto's",
      value_chain: "Waardeketen",
      brit: "BRiT",
      ea: "EA",
      per:"Performance",
      nettrans:"Network Transformation",
    },
    dashboard: {
      notice_board: "Aankondigingenbord",
      kpi_target_not_set: "KPI doelsellingen niet bepaald",
      kpi_initial_not_set: "Initiële KPI meting niet bepaald",
      kpi_monthly_not_set: "Maandelijkse KPI meting niet bepaald",
      kpi_below_target: "Bepaalde KPI halen doelstelling niet",
      activation: "Activatie",
      users: "Gebruikers",
      nmscs: "NMSC's",
      activeRetailer: "Actieve dealers",
      certificationRate: "Hercertificatiegraad",
      reCertificationRate: "Certificatiegraad",
      activityDue: "Activiteiten binnen tijdsbestek",
      avgdays: "Gemiddeld aantal dagen / toolbox",
      overDueAct: "Verlopen activiteiten",
      actionItem: "Aktie onderwerpen",
      bussResult: "Zakelijke resultaten",
      toolboxImpl: "Toolbox implementatie",
      statusCompletedL:"Status - Completed, Progress - ",
      statusClosedL:"Status - Closed, Progress - ",
      statusOngoingL:"Status - Ongoing, Progress - ",
      recentUpdates: "Laatst bijgewerkte gegevens",
      rolloutTopNmsc: "Uitrolresultaten - top NMSS",
      bestPractice: "Optimale Werkwijze bord",
      availableKpi: "Beschikbare KPI",
      selectedKpi: "Geselecteerde KPI",
      dealerBestPractice: "Dealers Optimale Werkwijze bord",
      kpiList: "KPI lijst",
      go: "Ga",
      nodata: "Geen gegevens ter beschikking",
      businessResultTop: "Zakelijke resultaten – Beste dealers",
      businessResultNMSC: "Zakenlijke resultaten - top NMSCs"


    },

    dealerCertification: {
      dealerCertification: "Dealer certificering",
      readyForCertification: "Klaar voor certificatie",
      certification: "Certificatie",
      submittedOn: "Uitgegeven op",
      certificateDate: "Certicatiedatum",
      reCertificateDate: "Hercertificatiedatum",
      toolboxDetails: "Toolboxgegevens",
      approve: "Keur goed",
      reject: "Verwerp",
      pending: "Hangende",
      region: "Regio"



    },

    uploadBulk: {
      browse: "Browse",
      executionId: "Execution Id",
      uploadedOn: "Uploaded On",
      lastUpdatedOn: "Last updated on",
      noDataTable: "No data to view",
      kpiUpload: "KPI Upload",
      action: "Action",
      processed: "Processed",
      error: "Error",
      received: "Received",
      createActivity: "Create Activities",
      fileUpload: "File Upload",
      targetSetting: "Target Setting",
      kpiEntry:"KPI Entry",
      accepted:"Accepted",
      launchNmsc:"Launch for NMSC",
      questionnaireUpload: "Questionnaire Upload",
    },
    userManagement: {
      userManagement: "Users management",
      createUser: "Create User",
      editUser: "Edit User",
      viewUser: "View User",
      nmscId: "NMSC ID",
      tarsId: "TARS ID",
      firstName: "First name",
      lastName: "Last name",
      emailId: "E-mail",
      britDealerChamp: "Brit Dealer Champion",
      invalidEmail: "Please enter valid E-Mail ID",
      invalidTars: "Please enter valid TARS ID",
      invalidFirstName: "Please enter valid First Name",
      invalidLastName: "Please enter valid Last Name",
      user_created: "User Created",
      user_updated: "User updated",
      duplicate_tarsuser: "This TARS id is already registered in the KET database.",
      user_define_in_tool: "User is defined as Business owner in Active Business Function. Hence, could not be made Inactive",
      user_refer_on: "User is referenced in Ongoing activity",
      pbiAccount : "PowerBI account",
      invalidPbiAccount : "Het PowerBI account moet minimaal uit 3 lettertekens bestaan.",
      businessArea: "Dienst binnen het bedrijf",
      valueChainAndBRiT: "Waardeketen en BRiT",
      only:"Only",
      optionText:"Please select at least one of the two options below",
      valueChainOnly: "Enkel 'Waardeketen'",
      britOnly: "Enkel 'BRiT'"
    },
    UserManagement: {
      nmscId: "NMSC ID",
      tarsId: "TARS ID",
      firstName: "First Name",
      lastName: "Last Name",
      emailId: "E-Mail ID",
      languageCode: "Language Code",
      ACTIVE: "Active",
      INACTIVE: "Inactive",
      addUser: "Add User",
      Assign: "Assign",
      List: "List",
      user: "User",
      error_message: "Er treedt een fout op, gelieve later opnieuw te proberen.",
      role: "Role",
      standardMessage: "No User Found",
      dealerCode: "Dealer Code",
      a2d: "A2D UUID",
      britReady: "Retailer participated in Brit Ready",
      britLive: "Retailer participated in Brit Live",
      britJapan: "Retailer participated in Brit Discovery Japan",
      britEss:"De dealer heeft het actieplan voortkomende uit de werknemers tevredenheidsenquête, bijgewerkt.",
      addDealer: "Add Dealer",
      noDealer: "No Dealer Found",
      dealerUuid: "Dealer uuid",
      language: "Language",
      L3: "L3",
      L4: "L4",
      L5: "L5",
      L6: "L6",
    },
    CeaKpi: {
      survey: "Survey",
      evaluationItem: "Evaluation Item",
      kpiMetric: "KPI Evaluation Method",
      stratagicPillar: "Strategic Pillar",
      addKPI: "Add KPI",
      edit: "Edit",
      delete: "Delete",
      subPillar: "Sub-Pillar",
      kaizenImp_impact_def: "Kaizen Importance / Impact / Definition",
      evalMethod: "Evaluation Method",
      scoreMethod: "Scoring Method",
      typeOftarget: "Type of Target",
      target: "Target",
      mode: "Mode",
      points: "Points",
      inputFreq: "Input Frequency",
      cea_kpi_created: "CEA KPI has been created successfully",
      noPointsAllocated:"No Points defined for selected Pillar/Business function mapping",
      targetIncorrect:"Target value is Invalid",
      viewKpi:"View KPI",
      general:"General",
      targetScoring:"Targets & Scoring",
      inputs:"Input Parameters",
      maxFiveParam:"Maximum 5 parameters for a KPI can be added",
      validParam:"Please Enter Valid Parameter",
      validDesc:"Please Enter Valid Parameter Description",
      dataEntry:"Data Entry in KET",
      paramater:"Parameter",
      paramDesc:"Parameter Description",
      noParamAvai:"No Parameters Available",
      kpiDeleted:"KPI deleted Successfully",
      kpiDeleteError:"KPI Not found",
      kpiActualsError:"Actuals present. KPI cannot be deleted",
      cea_kpi_updated: "CEA KPI has been updated successfully",
      addParam:"Add Parameters"

    },
    dealerManagement: {
      invalidUuid: "Please enter valid Uuid",
      invalidDealerName: "Please enter valid Dealer Name",
      invalidCity: "Please enter valid City",
      invaliDealerCode: "Please enter valid Dealer Code",
      existingUuid: "Existing Uuid",
      updateUuid: "Update Uuid",
      newUuid: "New Uuid",
    },
    networkManagement: {
      networkManagement: "Network management"
    },
    kpiEntry: {
      cancel : "Cancel",
      save : "Save",
      no : "No",
      yes : "Yes",
      hide : "Hide",
      show : "Show",
      dataNotSaved : "Data is not saved. Entered data may be lost. Do you want to Proceed?",
      wantToProceed : "Do you want to proceed?",
      status: 'Status',
      evaluationYear: 'Evaluation Year',
      frequency: 'Frequency',
      typeOfTarget: 'Type of Target',
      target: 'Target',
      unit: 'Unit',
      standardMessage: 'No Kpi Actuals Data Found',
      srNo: 'S.No.',
      actuals: 'Actuals',
      subPillar: 'Sub Pillar',
      businessFunc: 'Business Function',
      brand: 'Brand',
      Jan : "Jan",
      Feb : "Feb",
      Mar : "Mar",
      Apr : "Apr",
      May : "May",
      Jun : "Jun",
      Jul : "Jul",
      Aug : "Aug",
      Sep : "Sep",
      Oct : "Oct",
      Nov : "Nov",
      Dec : "Dec",
      q1 : "Q1",
      q2 : "Q2",
      q3 : "Q3",
      q4 : "Q4",
      h1 : "H1",
      h2 : "H2",
      cy : "CY",
      kpi : "Key Performance Indicators",
      actuals1 : "Actuals should not be more than allotted points. Please re-enter",
      actionPlan	: "Action Plan",
      enterActualsData : "Please enter actuals data.",
      notNumber : "Please enter valid number.",
      serverErrorMsg : "An error occured, please try again later.",
      launchEvaluationError : "Please launch evaluations.",
      actualsDataSaved : "Actuals data uploaded successfully.",
      inputTxt:"Inputs"
    },
    ceaEvaluation :{
      nmsc : "NMSC",
      search : "Search",
      all : "All",
      status : "Status",
      businessFunction :"Business Function",
      noEvaluationsFound : "No Evalutions Found",
      evaluationYear : "Evalution Year",
      view : "View",
      evaluations : "Evaluations",
      launchTargetSetting: "Launch Target Setting",
      launchEvaluation : "Launch Evaluation",
    },
    britDashboard:{
      noVideo:"No videos available",
      playVideo:"Play Video",
      trainingVideos:"Training  Videos",
      download: "Download Video",
      back:"Back"
    },
    ceaActionPlan:{
      id: "ID",
      counterMeasure: "Countermeasure",
      questionReference: "KPI Reference",
      status: "Status",
      startDate: "Start Date",
      dueDate: "Due Date",
      assignedTo: "Assigned To",
      noActionAvailable: "No Actions available",
      add: "Add",
      download: "Download",
      edit: "Edit",
      delete: "Delete",
      deleteMsg: "Are you sure you want to delete ?",
      no: "No",
      yes: "Yes",
      addActionPlanNotAllowed: "The Action Plan cannot be created, or edited after the Activity has been Closed, Completed, or Rejected.",
      actionPlanItem: "Action Plan - Item #",
      addActionPlan: "Add Action Plan",
      open: "Open",
      actionPlanStartDateValidation:"End Date cannot be earlier than the Start Date",
      modalMandatory: "Please enter valid data in all the mandatory fields",
      problem: "Problem",
      description: "Description",
      followup: "Follow Up",
      history: "History",
      cancel: "Cancel",
      save: "Save",
      update: "Update",
      assignee: "Assignee",
      endDate: "End Date", 
      select: "Select",
      notApplicablePer:"Not Applicable for CEA, Networks and Used car"
    },
    reachItDashboard:{
      topNavReachIT:"ReachIT",
      downloads:"Downloads",
      historyFiles:"Geschiedenis van vorige dossiers",
      reachText1:"Welkom op de REACH IT homepage. Het TME Environmental Management team zal deze pagina gebruiken om relevante informatie te delen met betrekking tot SCIP : Substances of Concern In Articles as such or in complex objects (Products) vastgesteld onder de Waste Framework Directive (WFD) zodat u kunt voldoen aan uw plicht tot verwerking met uw SCIP vereenvoudigde kennisgeving.",
      reachText2:"Bij vragen kunt u contact opnemen met het TME Environmental Management team via onderstaande link",
      reachText3:"Bij vragen kunt u contact opnemen met uw NMSC.",
      envMngt:"Environmental Management",
      fileStatistics: "Gedownloade bestanden Statistieken",
      Downloaded: "Downloaded",
      not: "niet",
      exportToExcel:"Export naar Excel"
    },
    usedCarComment:{
      ongoing:"Ongoing",
      completed:"Completed",
      commentHistory:"Monthly Comments History",
      addComment:"Add Monthly Comment",
      month:"Month",
      comment:"Comment",
      username:"Username",
      email:"Email",
      createdDate:"Created Date"
    },
  }
};

export default translationsNL;
