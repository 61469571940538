import React from 'react';
import { Container, Modal, ModalBody, ModalHeader } from 'reactstrap';
import { withTheme } from '../../../themes/theming';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import '../index.css';
import * as AppConst from '../../../AppConstant';
var I18n = require('react-redux-i18n').I18n;
class ViewListDealerModal extends React.Component {
    render() {
        let busiAreaValueChain = true
        let busiAreaBrit = true
        if(this.props.selectedRow.businessArea!==undefined && this.props.selectedRow.businessArea!==""){
            let busiAreaArr = this.props.selectedRow.businessArea.split(',');
            if(busiAreaArr.length>0){
                busiAreaValueChain = busiAreaArr.includes(AppConst.BUSINESS_AREA_VALUE_CHAIN) ? true : false
                busiAreaBrit = busiAreaArr.includes(AppConst.BUSINESS_AREA_BRIT) ? true : false
            }
        }
        return (
            <Container>
                <Modal className={"viewListDealerModal viewListDealerModal-" + this.props.theme.name}
                    isOpen="true"
                >
                    <ModalHeader toggle={() => this.props.toggle()}>
                        {I18n.t("Table.view") + " " + I18n.t("Table.dealer")}
                    </ModalHeader>
                    <ModalBody>
                        <form className="form-horizontal">
                            <div className="col-md-12 row control-label">
                                <label className="col-md-6 control-label" style={{ paddingLeft: '0.3rem' }}>{I18n.t("Table.dealerName")}</label>
                                <label className="col-md-6 control-label">{I18n.t("Table.city")}</label>
                            </div>
                            <div className="col-md-12 input-group control-label">
                                <input
                                    type="text"
                                    className="form-control black-font"
                                    defaultValue={this.props.selectedRow.dealerName}
                                    disabled="disabled" 
                                /> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                <input
                                    type="text"
                                    className="form-control black-font"
                                    defaultValue={this.props.selectedRow.city}
                                    disabled="disabled" 
                                />
                            </div>
                            <br />
                            <div className="col-md-12 row control-label">
                                <label className="col-md-6 control-label" style={{ paddingLeft: '0.3rem' }}>{I18n.t("Table.dealer")} UUID</label>
                                <label className="col-md-6 control-label">{I18n.t("UserManagement.dealerCode")}</label>
                            </div>
                            <div className="col-md-12 input-group control-label">
                                <input
                                    type="text"
                                    className="form-control black-font"
                                    defaultValue={this.props.selectedRow.dealerUuid}
                                    disabled="disabled" 
                                />  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                <input
                                    type="text"
                                    className="form-control black-font"
                                    defaultValue={this.props.selectedRow.dealerCode}
                                    disabled="disabled" 
                                />
                            </div>
                            <br />
                            <div className="col-md-12 row control-label">
                                <label className="col-md-6 control-label" style={{ paddingLeft: '0.3rem' }}>{I18n.t('UserManagement.language')}</label>
                                <label className="col-md-6 control-label">{I18n.t("Table.franchise")}</label>
                            </div>
                            <div className="col-md-12 input-group control-label">
                                <input
                                    type="text"
                                    className="form-control black-font"
                                    defaultValue={ this.props.language !== undefined ? this.props.language.toUpperCase() : ""}
                                    disabled="disabled" 
                                />  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                <input
                                    type="text"
                                    className="form-control black-font"
                                    defaultValue={this.props.selectedRow.franchise}
                                    disabled="disabled" 
                                />
                            </div>
                            <br />
                            <div className="col-md-12 row control-label">
                                <label className="col-md-6 control-label" style={{ paddingLeft: '0.3rem' }}>{I18n.t("Table.nmsc")}</label>
                                <label className="col-md-6 control-label">{I18n.t("Table.country")}</label>
                            </div>
                            <div className="col-md-12 input-group control-label">
                                <input
                                    type="text"
                                    className="form-control black-font"
                                    defaultValue={this.props.selectedRow.nmscCode}
                                    disabled="disabled" 
                                />  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                <input
                                    type="text"
                                    className="form-control black-font"
                                    defaultValue={this.props.selectedRow.country}
                                    disabled="disabled" 
                                />
                            </div>
                            <br />
                            <div className="col-md-12 row control-label">
                                <label className="col-md-6 control-label" style={{ paddingLeft: '0.3rem' }}>{I18n.t('UserManagement.L3')}</label>
                                <label className="col-md-6 control-label">{I18n.t('UserManagement.L4')}</label>
                            </div>
                            <div className="col-md-12 input-group control-label">
                                <input
                                    type="text"
                                    className="form-control black-font"
                                    defaultValue={this.props.selectedRow.l3}
                                    disabled="disabled" 
                                />  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                <input
                                    type="text"
                                    className="form-control black-font"
                                    defaultValue={this.props.selectedRow.l4}
                                    disabled="disabled" 
                                />
                            </div>
                            <br />
                            <div className="col-md-12 row control-label">
                                <label className="col-md-6 control-label" style={{ paddingLeft: '0.3rem' }}>{I18n.t('UserManagement.L5')}</label>
                                <label className="col-md-6 control-label">{I18n.t('UserManagement.L6')}</label>
                            </div>
                            <div className="col-md-12 input-group control-label">
                                <input
                                    type="text"
                                    className="form-control black-font"
                                    defaultValue={this.props.selectedRow.l5}
                                    disabled="disabled" 
                                />  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                <input
                                    type="text"
                                    className="form-control black-font"
                                    defaultValue={this.props.selectedRow.l6}
                                    disabled="disabled" 
                                />
                            </div>
                            <br />
                            <div className="col-md-12 input-group control-label" style={{ paddingLeft: "0px" }}>
                                <label className="col-md-6 control-label">
                                    <input
                                        type="checkbox"
                                        id="busiAreaValueChain"
                                        checked={busiAreaValueChain}
                                        disabled={true}
                                    /> {I18n.t("ketProgram.value_chain")}
                                </label>
                                <label className="col-md-6 control-label">
                                    <input
                                        type="checkbox"
                                        id="busiAreaBrit"
                                        checked={busiAreaBrit}
                                        disabled={true}
                                    /> {I18n.t("ketProgram.brit")}
                                </label>
                            </div>
                            <br />
                        </form>
                    </ModalBody>
                </Modal>
            </Container>
        );
    }
}
const mapStateToProps = state => {
    return { 
        language : state.userManagementReducer.dealerLang
    };
};
export default withRouter(connect(mapStateToProps)(withTheme(ViewListDealerModal)));