import React from "react";
import { withTheme } from "../../../themes/theming";
import '../index.css';
import Select from "react-select";
import 'react-datepicker/dist/react-datepicker.css';
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import ReactMultiSelectCheckboxes from 'react-multiselect-checkboxes';
import * as AppConst from '../../../AppConstant';
import {assignDealers,fetchLinkDealerList,fetchLinkFilters} from '../../../actions/userManagementAction.jsx'
import Autosuggest from 'react-autosuggest';
import { toastr } from 'react-redux-toastr';
var I18n = require("react-redux-i18n").I18n;

class LinkFilter extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            nmscFlag: false,
            isAdmin : this.props.isAdmin,
            suggestionsDealer : [],
            dealerList :[],
            nmscCodeList: [],
            franchiseList:[],
            countryList:[],
            l3List:[],
            l4List:[],
            l5List:[],
            l6List:[],
            businessFunc: [{ label: I18n.t('standardMessages.all'), value: "All" },{ label: I18n.t('userManagement.valueChainOnly'), value: "VALUE_CHAIN" },{ label: I18n.t('userManagement.britOnly'), value: "BRiT" }, { label: I18n.t('userManagement.valueChainAndBRiT'), value: "VALUE_CHAIN,BRiT" }],
            roleList :this.props.userDto.role,
            valueDealer :"",
            selectedNmscCode:[],
            selectedFranchise :[],
            selectedCountry:[],
            selectedL3:[],
            selectedL4:[],
            selectedL5:[],
            selectedL6:[],
            linkDto: {
                tarsId : this.props.userDto.tarsId,
                networkUserId : this.props.userDto.networkUserId,
                nmscCode: null,
                l1 : null,
                l2 : null,
                dealer:null,
                l3List : null,
                l4List : null,
                l5List : null,
                l6List : null,
                userNmscCode :this.props.userDto.nmscId,
                networkLevelDealerId: 0,
                businessArea: '',
                dealerCode: '',
            },
            defaultAllOption :{"value": "All" , "label":I18n.t('standardMessages.all'),"selected":"selected"},
            defaultAllObj :{"value": "All" , "label":I18n.t('standardMessages.all')},
            isRefresh : false
        };
        //this.changeSearchDto = this.changeSearchDto.bind(this);
    }
    componentWillReceiveProps(nextProps) {

    }

    componentWillMount() {
        
    
    }

    componentDidMount(){
        if(this.state.isAdmin){
            this.refreshFilters();
        }
    }

    getSelectedList(selectedList) {
        let filteredList = null;
        if (selectedList !== undefined || selectedList !== null) {
            if(selectedList.length >0){
                filteredList = [];
                selectedList.map(obj => {
                filteredList.push(obj.value);
                return null;
            })
        }
             }

        return filteredList;   
        }

        getSelectedString(selectedString) {
            let filteredString = null;
            if (selectedString !== undefined || selectedString !== null) {
                if(selectedString.length >0){
                    filteredString = selectedString.value;
            
                }
             }
            return filteredString;   
            }    

    getSelectedDropDownList(selectedList){
        let finalList = [];

       selectedList.map(objArr =>{
            objArr["selected"] ="selected"
            finalList.push(objArr) 
            return null;
        })

        return finalList;
    }
   
    componentDidUpdate(prevProps, prevState) {

        let linkDto = {...this.state.linkDto}
 


        if(prevProps.linkFilter.isRefresh !== this.props.linkFilter.isRefresh && this.props.linkFilter.isRefresh!==undefined){
            this.refreshFilters()
        }


       if(prevProps.linkFilter.nmscCodeList !== this.props.linkFilter.nmscCodeList && this.props.linkFilter.nmscCodeList!==undefined){
           
           if(this.props.linkFilter.nmscCodeList.length===1){
            this.setState({nmscCodeList:this.props.linkFilter.nmscCodeList})
                this.props.dispatch(fetchLinkFilters(linkDto,"l1"))
                this.props.dispatch(fetchLinkFilters(linkDto,"l2"))
                this.props.dispatch(fetchLinkDealerList(linkDto))
           }
           else{
            let nmscCodeList = this.props.linkFilter.nmscCodeList.length>1? [this.state.defaultAllObj].concat(this.props.linkFilter.nmscCodeList): this.props.linkFilter.nmscCodeList
            let defaultSelectedNmscCode = this.props.linkFilter.nmscCodeList.length>1?this.state.defaultAllOption:[]
            linkDto["nmscCode"] = AppConst.DEFAULT_ALL
            this.setState({linkDto,nmscCodeList:nmscCodeList,selectedNmscCode:[defaultSelectedNmscCode]})
            this.props.dispatch(fetchLinkFilters(linkDto,"l1"))
        }
           
       }
       if(prevProps.linkFilter.franchiseList !== this.props.linkFilter.franchiseList && this.props.linkFilter.franchiseList!== undefined){
        
         
        if(this.props.linkFilter.franchiseList.length===1){
            linkDto["l1"] =  this.props.linkFilter.franchiseList[0].value
            let selectedFranchise = this.getSelectedDropDownList(this.props.linkFilter.franchiseList)
            this.setState({linkDto,selectedFranchise :selectedFranchise, franchiseList:  this.props.linkFilter.franchiseList})
             this.props.dispatch(fetchLinkFilters(linkDto,"l2"))
             this.props.dispatch(fetchLinkDealerList(linkDto))
        }
        else{
            let frachiseList = this.props.linkFilter.franchiseList.length>1?[this.state.defaultAllObj].concat(this.props.linkFilter.franchiseList):this.props.linkFilter.franchiseList
            let defaultSelectedFranchise = this.props.linkFilter.franchiseList.length>1?this.state.defaultAllOption:[]
            linkDto["l1"] = AppConst.DEFAULT_ALL
            this.setState({linkDto,franchiseList:frachiseList,selectedFranchise:[defaultSelectedFranchise]})
        }
    }
    if(prevProps.linkFilter.countryList !== this.props.linkFilter.countryList && this.props.linkFilter.countryList!== undefined){
        
         
        if(this.props.linkFilter.countryList.length===1){
            linkDto["l2"] =  this.props.linkFilter.countryList[0].value
            let selectedCountry = this.getSelectedDropDownList(this.props.linkFilter.countryList)
            this.setState({linkDto,selectedCountry :selectedCountry, countryList:  this.props.linkFilter.countryList})
            this.props.dispatch(fetchLinkFilters(linkDto,"l3"))
            this.props.dispatch(fetchLinkFilters(linkDto,"l4"))
            this.props.dispatch(fetchLinkFilters(linkDto,"l5"))
            this.props.dispatch(fetchLinkFilters(linkDto,"l6"))
            this.props.dispatch(fetchLinkDealerList(linkDto)) 
         
        }
        else{
            let countryList = this.props.linkFilter.countryList.length>1?[this.state.defaultAllObj].concat(this.props.linkFilter.countryList):this.props.linkFilter.countryList
            let defaultSelectedCountry = this.props.linkFilter.countryList.length>1?this.state.defaultAllOption:[]
            linkDto["l2"] = AppConst.DEFAULT_ALL
            this.setState({linkDto,countryList: countryList,selectedCountry:[defaultSelectedCountry]})
        }

    }
    if(prevProps.linkFilter.l3List!== this.props.linkFilter.l3List && this.props.linkFilter.l3List!== undefined){
        
         
        if(this.props.linkFilter.l3List.length===1){
            linkDto["l3List"] = [this.props.linkFilter.l3List[0].value]
            let selectedL3 = this.getSelectedDropDownList(this.props.linkFilter.l3List)
            this.setState({linkDto,selectedL3 :selectedL3, l3List: this.props.linkFilter.l3List})
            //  this.props.dispatch(fetchLinkFilters(linkDto,"l4"))
            //  this.props.dispatch(fetchLinkDealerList(linkDto))
        }
        else{
            this.setState({l3List: this.props.linkFilter.l3List})
        }
    }
    if(prevProps.linkFilter.l4List!== this.props.linkFilter.l4List && this.props.linkFilter.l4List !== undefined){
        
         
        if(this.props.linkFilter.l4List.length===1){
            linkDto["l4List"] = [this.props.linkFilter.l4List[0].value]
            let selectedL4 = this.getSelectedDropDownList(this.props.linkFilter.l4List)
            this.setState({linkDto,selectedL4 :selectedL4, l4List: this.props.linkFilter.l4List})
            //  this.props.dispatch(fetchLinkFilters(linkDto,"l5"))
            //  this.props.dispatch(fetchLinkDealerList(linkDto))
        }
        else{
            this.setState({l4List: this.props.linkFilter.l4List})
        }
    }
    if(prevProps.linkFilter.l5List!== this.props.linkFilter.l5List && this.props.linkFilter.l5List!== undefined){
        
         
        if(this.props.linkFilter.l5List.length===1){
            linkDto["l5List"] = [this.props.linkFilter.l5List[0].value]
            let selectedL5 = this.getSelectedDropDownList(this.props.linkFilter.l5List)
            this.setState({linkDto,selectedL5 :selectedL5, l5List: this.props.linkFilter.l5List})
            //  this.props.dispatch(fetchLinkFilters(linkDto,"l6"))
            //  this.props.dispatch(fetchLinkDealerList(linkDto))
        }
        else{
            this.setState({l5List: this.props.linkFilter.l5List})
        }
        // this.props.dispatch(fetchLinkDealerList(linkDto))
    }
    if(prevProps.linkFilter.l6List!== this.props.linkFilter.l6List && this.props.linkFilter.l6List!== undefined){
        
         
        if(this.props.linkFilter.l6List.length===1){
            linkDto["l6List"] = [this.props.linkFilter.l6List[0].value]
            let selectedL6 = this.getSelectedDropDownList(this.props.linkFilter.l6List)
            this.setState({linkDto,selectedL6 :selectedL6, l6List: this.props.linkFilter.l6List})
            //  this.props.dispatch(fetchLinkFilters(linkDto,"l6"))
            // this.props.dispatch(fetchLinkDealerList(linkDto))
        }
        else{
            this.setState({l6List: this.props.linkFilter.l6List})
        }

    }
    if(prevProps.linkFilter.dealerList!== this.props.linkFilter.dealerList && this.props.linkFilter.dealerList!== undefined ){

   
        this.setState({dealerList: this.props.linkFilter.dealerList})
    
    }

    
    }

    isNotEmpty = (value) => {
        if (undefined === value || null === value) {
            return false;
        }
        else if ((value + "").trim() === "") {
            return false;
        }
        return true;
    }
   
    getSuggestionDealer = value => {
        const inputValue = value.trim().toLowerCase();
        const inputLength = inputValue.length;
        return inputLength > 2 && this.state.dealerList !== undefined ? (this.state.dealerList.filter(lang =>
            lang.label.toLowerCase().indexOf(inputValue) !== -1)
        ) : [];
    };

    onDealerChange = (event, { newValue }) => {
        var modifiedValue, modifiedId;

        if (newValue.indexOf("_") > -1) {
            modifiedValue = newValue.substr(0, newValue.indexOf("_"));
            modifiedId = Number(newValue.substr(newValue.indexOf("_") + 1));
        } else {
            modifiedValue = newValue;
            modifiedId = 0;
        }
        const linkDto = { ...this.state.linkDto };
        if(modifiedValue.trim().length>0){
            linkDto["dealer"] = modifiedValue;
            linkDto["networkLevelDealerId"] = modifiedId
        this.setState({ linkDto });}
        else{
            linkDto["dealer"] = null;
            linkDto["networkLevelDealerId"] = 0;
        this.setState({ linkDto })
        }
        this.setState({
            valueDealer: modifiedValue,
        });
    };


    onNmscChange = (selectedOption) => {
        
        const linkDto = { ...this.state.linkDto };
        if(this.isNotEmpty(selectedOption)){
        linkDto["nmscCode"] = selectedOption.value;
        linkDto["l1"] = null
        linkDto["l2"] = null
        linkDto["l3List"] = null
        linkDto["l4List"] = null
        linkDto["l5List"] = null
        linkDto["l6List"] = null
        linkDto["dealer"] = null
        linkDto["networkLevelDealerId"] = 0
        this.setState({ linkDto });
        this.setState({ selectedNmscCode: [selectedOption] , selectedCountry:[],
            selectedFranchise:[],selectedL3:[],selectedL4:[],selectedL5:[],selectedL6:[],valueDealer:""})
        this.props.dispatch(fetchLinkFilters(linkDto,"l1"))
        this.props.dispatch(fetchLinkFilters(linkDto,"l2"))
        
        }else{
            linkDto["nmscCode"] = null
            linkDto["l1"] = null
            linkDto["l2"] = null
            linkDto["l3List"] = null
            linkDto["l4List"] = null
            linkDto["l5List"] = null
            linkDto["l6List"] = null
            linkDto["dealer"] = null
            linkDto["networkLevelDealerId"] = 0
            this.setState({ linkDto });
            this.setState({ selectedNmscCode: [] , selectedCountry:[],
                selectedFranchise:[],selectedL3:[],selectedL4:[],selectedL5:[],selectedL6:[],valueDealer:""})
            }
        this.props.dispatch(fetchLinkDealerList(linkDto))
   
    }

    onFranchiseChange = (selectedOption) => {

        const linkDto = { ...this.state.linkDto };
        if(this.isNotEmpty(selectedOption)){
        linkDto["l1"] = selectedOption.value;
        linkDto["l2"] = null
        linkDto["l3List"] = null
        linkDto["l4List"] = null
        linkDto["l5List"] = null
        linkDto["l6List"] = null
        linkDto["dealer"] = null
        linkDto["networkLevelDealerId"] = 0
        this.setState({linkDto, selectedFranchise: [selectedOption],selectedCountry:[],
            selectedL3:[],selectedL4:[],selectedL5:[],selectedL6:[],valueDealer:"" })
            this.props.dispatch(fetchLinkFilters(linkDto,"l2"))
            //dealer Call
        }else{
        linkDto["l1"] = null;
        linkDto["l2"] = null;
        linkDto["l3List"] = null;
        linkDto["l4List"] = null;
        linkDto["l5List"] = null;
        linkDto["l6List"] = null;
        linkDto["dealer"] = null
        linkDto["networkLevelDealerId"] = 0
        this.setState({linkDto, selectedFranchise: [],selectedCountry:[],
            selectedL3:[],selectedL4:[],selectedL5:[],selectedL6:[],valueDealer:"" })
        }
        this.props.dispatch(fetchLinkDealerList(linkDto))
    }
    onCountryChange = (selectedOption) => {
        const linkDto = { ...this.state.linkDto };
        if(this.isNotEmpty(selectedOption)){
            linkDto["l2"] = selectedOption.value;
            linkDto["l3List"] = null
            linkDto["l4List"] = null
            linkDto["l5List"] = null
            linkDto["l6List"] = null
            linkDto["dealer"] = null
            linkDto["networkLevelDealerId"] = 0
            this.setState({linkDto, selectedCountry:[selectedOption],selectedL3:[],
                selectedL4:[],selectedL5:[],selectedL6:[],valueDealer:"" })
                this.props.dispatch(fetchLinkFilters(linkDto,"l3"))
                this.props.dispatch(fetchLinkFilters(linkDto,"l4"))
                this.props.dispatch(fetchLinkFilters(linkDto,"l5"))
                this.props.dispatch(fetchLinkFilters(linkDto,"l6"))
                //dealer code
            }else{
            linkDto["l2"] = null;
            linkDto["l3List"] = null;
            linkDto["l4List"] = null;
            linkDto["l5List"] = null;
            linkDto["l6List"] = null;
            linkDto["dealer"] = null
            linkDto["networkLevelDealerId"] = 0
            this.setState({linkDto,selectedCountry:[],selectedL3:[],
                selectedL4:[],selectedL5:[],selectedL6:[],valueDealer:"" })
    
            }
            this.props.dispatch(fetchLinkDealerList(linkDto))
    }

    onBusinessChange = (selectedOption) => {
        const linkDto = { ...this.state.linkDto };
        linkDto["businessArea"] = selectedOption.value;
        if (selectedOption.value === 'All') {
            linkDto["businessArea"] = '';
        }
        this.setState({ selectedBusinessFunc: selectedOption, linkDto })
    }

    onL3Change = (selectedOption) => {
        const linkDto = { ...this.state.linkDto };
        if(this.isNotEmpty(selectedOption)){

            var valueOptions = [];
            selectedOption.map(function (item) {
                valueOptions.push(item.value);
                return null;
            })
            linkDto["l3List"] =valueOptions;
            this.setState({linkDto,selectedL3:selectedOption,})
                //dealer call
            }else{
            linkDto["l3List"] = null;
            this.setState({linkDto,selectedL3:[] })    
            }
    }

    onL4Change = (selectedOption) => {
        const linkDto = { ...this.state.linkDto };
        if(this.isNotEmpty(selectedOption)){
            var valueOptions = [];
            selectedOption.map(function (item) {
                valueOptions.push(item.value);
                return null;
            })
            linkDto["l4List"] =valueOptions;
            this.setState({linkDto,selectedL4:selectedOption})
            }else{
            linkDto["l4List"] = null;
            this.setState({linkDto,selectedL4:[]})
    
            }
    }

    onL5Change = (selectedOption) => {
        const linkDto = { ...this.state.linkDto };
        if(this.isNotEmpty(selectedOption)){

            var valueOptions = [];
            selectedOption.map(function (item) {
                valueOptions.push(item.value);
                return null;
            })

            linkDto["l5List"] = valueOptions
            this.setState({linkDto,selectedL5:selectedOption})
                //dealer call
            }else{
            linkDto["l5List"] = null;
            this.setState({linkDto,selectedL5:[]})
    
            }
    }

    onL6Change = (selectedOption) => {
       
        const linkDto = { ...this.state.linkDto };
        if(this.isNotEmpty(selectedOption)){
            var valueOptions = [];
            selectedOption.map(function (item) {
                valueOptions.push(item.value);
                return null;
            })
            linkDto["l6List"] =valueOptions
            this.setState({linkDto,selectedL6:selectedOption})
            //dealer call
            }else{
            linkDto["l6List"] = null;
            this.setState({linkDto,selectedL6:[]})
    
            }
    }


    onSuggestionsDealerFetchRequested = ({ value }) => {
        this.setState({
            suggestionsDealer: this.getSuggestionDealer(value)
        });
    };
    onSuggestionsDealerClearRequested = () => {
        this.setState({
            suggestionsDealer: []
        });
    };
    getSuggestionDealerValue = suggestion => suggestion.label + "_" + suggestion.value;

    renderSuggestionDealer = suggestion => (
        <div>
            {suggestion.label}
        </div>
    );

    refreshFilters = () =>{
        let linkDto = {...this.state.linkDto}
        linkDto["userNmscCode"] = this.props.userDto.nmscId;
        linkDto["tarsId"] = this.props.userDto.tarsId;
        linkDto["networkUserId"] = this.props.userDto.networkUserId;
        linkDto["l1"] = null
        linkDto["l2"] = null
        linkDto["nmscCode"] =null
        linkDto["l3List"] = null
        linkDto["l4List"] = null
        linkDto["l5List"] = null
        linkDto["l6List"] = null
        linkDto["dealer"] = null
        linkDto["networkLevelDealerId"] = 0

        let selectedNmscCode = {...this.state.selectedNmscCode}
        if(this.props.userDto.nmscId !== null){
            if(this.props.userDto.nmscId.toUpperCase() !== "TME" && this.props.userDto.nmscId.toUpperCase() !== "TMME"){
                linkDto["nmscCode"] = this.props.userDto.nmscId

                let obj = {
                    "label" :this.props.userDto.nmscId,
                    "value" :this.props.userDto.nmscId,
                    "selected" : "selected"
                }
                selectedNmscCode = [obj];
                this.setState({linkDto, selectedNmscCode, selectedCountry:[],
                    selectedFranchise:[],selectedL3:[],selectedL4:[],selectedL5:[],selectedL6:[],valueDealer:""})
                this.props.dispatch(fetchLinkFilters(linkDto,"nmscCode"))
                this.props.dispatch(fetchLinkDealerList(linkDto))
            }
            else{
                
                this.setState({linkDto, selectedNmscCode:[], selectedCountry:[],
                    selectedFranchise:[],selectedL3:[],selectedL4:[],selectedL5:[],selectedL6:[],valueDealer:""})
            this.props.dispatch(fetchLinkFilters(linkDto,"nmscCode"))
            this.props.dispatch(fetchLinkDealerList(linkDto))
            }
        }
    }

    dealerCodeChange = e => {
        this.setState({ dealerCode: e.target.value });
        const linkDto = { ...this.state.linkDto };
        linkDto["dealerCode"] = e.target.value;
        this.setState({ linkDto });
    }



    assign = (e) => {
        let linkDto = {...this.state.linkDto}
        e.preventDefault(); 
        if((this.state.valueDealer!=="") &&  linkDto.networkLevelDealerId === 0){
            toastr.error((I18n.t("ServerMessage.proper_dealer_selected")))
            this.refreshFilters()}
            else{
        
        this.props.dispatch(assignDealers(this.state.linkDto,this.props.dealerFilter))
            }
    }

    render() {

        
        const { suggestionsDealer, valueDealer,isAdmin} = this.state;
        // Autosuggest will pass through all these props to the input.
        // console.log(this.state)
        const inputDealerProps = {
            placeholder: I18n.t('toastr.multiSelectPlaceHolderUser'),
            value: valueDealer,
            onChange: this.onDealerChange
        };

        let isNmscCodeDisabled = isAdmin&&this.state.nmscCodeList?this.state.nmscCodeList.length===1?true:false:true
        let isL1Disabled = isAdmin&&this.state.selectedNmscCode.length>0?this.state.franchiseList?this.state.franchiseList.length>1?false:true:true:true
        let isL2Disabled = isAdmin&&this.state.selectedNmscCode.length>0?this.state.selectedNmscCode[0].value !== AppConst.DEFAULT_ALL?this.state.countryList?this.state.countryList.length>1?false:true:true:true:true
        let isL3Disabled = isAdmin &&this.state.selectedCountry.length>0?this.state.selectedCountry[0].value !== AppConst.DEFAULT_ALL? this.state.l3List?this.state.l3List.length>1?false:true:true:true:true
        let isL4Disabled = isAdmin&&this.state.selectedCountry.length>0?this.state.selectedCountry[0].value !== AppConst.DEFAULT_ALL?this.state.l4List?this.state.l4List.length>1?false:true:true:true:true
        let isL5Disabled = isAdmin&&this.state.selectedCountry.length>0?this.state.selectedCountry[0].value !== AppConst.DEFAULT_ALL?this.state.l5List?this.state.l5List.length>1?false:true:true:true:true
       let isL6Disabled = isAdmin&&this.state.selectedCountry.length>0?this.state.selectedCountry[0].value !== AppConst.DEFAULT_ALL?this.state.l6List?this.state.l6List.length>1?false:true:true:true:true
       let isAutoSuggestionDisable =  isAdmin &&this.state.selectedNmscCode.length>0?this.state.selectedNmscCode[0].value !== AppConst.DEFAULT_ALL?false:true:true
       
       
       return (
            <div className="col-md-12" style={{ padding: "0px" }}>
                <div id="searchId" style={{borderColor: this.props.theme.primaryColor,backgroundColor: "rgba(247, 247, 247, 1)" }} className={"col-md-12 linkFilter"}>
                    <div className="col-md-12 row control-label">
                       
                        <label className="col-md-2 control-label" style={{ paddingLeft: "0rem", margin:"5px 42px 5px 1px" }}>{I18n.t('Table.nmsc')}</label>
                        <label className="col-md-2 control-label" style={{ paddingLeft: "0rem", margin:"5px 40px 5px 0px" }}>{I18n.t("Table.franchise")}</label>
                        <label className="col-md-2 control-label" style={{ paddingLeft: "0rem", margin:"5px 40px 5px 0px" }}>{I18n.t("Table.country")}</label>
                        <label className="col-md-2 control-label" style={{ paddingLeft: "0rem", margin:"5px 40px 5px 0px"}}>{I18n.t("Table.Dealer")}</label>
                        <label className="col-md-2 control-label" style={{ paddingLeft: '0rem', margin:"5px 0px 5px 0px" }}>{I18n.t("userManagement.businessArea")}</label>
                    </div>
                    <div className={"col-md-12 input-group control-label linkFilter-"+this.props.theme.name}>
                        <div className={"col-md-2 noPadding smallDD-" + this.props.theme.name}>
                            <Select
                                className = {isNmscCodeDisabled?"disabledField":"activeField-" + this.props.theme.name}
                                value={this.state.selectedNmscCode}
                                onChange={(e)=>{this.onNmscChange(e)}}
                                options={this.state.nmscCodeList}
                                isDisabled={isNmscCodeDisabled}
                                placeholder={I18n.t('standardMessages.all')}
                                isSearchable
                            />
                        </div>
                        <div style = {{margin : '20px'}}/>
                        <div className={"col-md-2 noPadding bigDD-" + this.props.theme.name}>
                            <Select
                                className = {isL1Disabled?"disabledField":"activeField-" + this.props.theme.name}
                                value={this.state.selectedFranchise}
                                onChange={(e)=>{this.onFranchiseChange(e)}}
                                options={this.state.franchiseList}
                                isDisabled={isL1Disabled}
                                placeholder={I18n.t('standardMessages.all')}
                                isSearchable
                            />
                            
                        </div>
                        <div style = {{margin : '20px'}}/>
                        <div className={"col-md-2 noPadding bigDD-" + this.props.theme.name}>
                            <Select
                                className = {isL2Disabled?"disabledField":"activeField-" + this.props.theme.name}
                                value={this.state.selectedCountry}
                                onChange={(e)=>{this.onCountryChange(e)}}
                                options={this.state.countryList}
                                isDisabled={isL2Disabled}
                                placeholder={I18n.t('standardMessages.all')}
                                isSearchable
                            />
                            
                        </div>
                        <div style = {{margin : '20px'}}/>
                        <div className={"col-md-2 noPadding bigDD-" + this.props.theme.name}>
                            {isAutoSuggestionDisable?<input
                                type="text"
                                className="form-control linkFormControl"
                                maxLength="100"
                                placeholder ={I18n.t('toastr.multiSelectPlaceHolderUser')}
                                disabled={true}
                            />: <Autosuggest class="form-control"
                                suggestions={suggestionsDealer}
                                onSuggestionsFetchRequested={this.onSuggestionsDealerFetchRequested}
                                onSuggestionsClearRequested={this.onSuggestionsDealerClearRequested}
                                getSuggestionValue={this.getSuggestionDealerValue}
                                renderSuggestion={this.renderSuggestionDealer}
                                inputProps={inputDealerProps}
                                />}
                            
                        </div>
                        <div style = {{margin : '20px'}}/>
                        <div className={"col-md-2 noPadding bigDD-" + this.props.theme.name} style={{ maxWidth: "14%" }}>
                           <Select
                                className = {"activeField"+ this.props.theme.name}
                                value={this.state.selectedBusinessFunc}
                                options={this.state.businessFunc}
                                onChange={(e)=>{this.onBusinessChange(e)}}
                                placeholder={I18n.t('standardMessages.all')}
                                //isDisabled = {true}
                                />
                                
                    </div>
                        </div>
                    
                        <div className="col-md-12 row control-label">
                       
                            <label className="col-md-2 control-label" style={{ paddingLeft: "0rem", margin:"5px 42px 5px 1px"}}>{I18n.t('UserManagement.L3')}</label>
                            <label className="col-md-2 control-label" style={{ paddingLeft: "0rem", margin:"5px 40px 5px 0px" }}>{I18n.t("UserManagement.L4")}</label>
                            <label className="col-md-2 control-label" style={{ paddingLeft: "0rem", margin:"5px 40px 5px 0px" }}>{I18n.t("UserManagement.L5")}</label>
                            <label className="col-md-2 control-label" style={{ paddingLeft: '0rem', margin:"5px 40px 5px 0px" }}>{I18n.t("UserManagement.L6")}</label>
                            <label className="col-md-2 control-label" style={{ paddingLeft: '0rem', margin:"5px 0px 5px 0px" }}>{I18n.t('UserManagement.dealerCode')}</label>
                        </div>
                        <div className={"col-md-12 input-group control-label linkFilter-"+this.props.theme.name}>
                        <div className={"col-md-2 noPadding bigDD-" + this.props.theme.name}>
                           { isL3Disabled?<Select
                                className = "disabledField"
                                value={this.state.selectedL3}
                                placeholder={I18n.t('standardMessages.all')}
                                isDisabled = {true}
                                />:<ReactMultiSelectCheckboxes
                                value={this.state.selectedL3}
                                onChange={(e)=>{this.onL3Change(e)}}
                                options={this.state.l3List}
                                placeholderButtonLabel={I18n.t('standardMessages.all')}
                            />}
                        </div>
                        <div style = {{margin : '20px'}}/>
                        <div className={"col-md-2 noPadding bigDD-" + this.props.theme.name}>
                            {isL4Disabled?<Select
                                className = "disabledField"
                                value={this.state.selectedL4}
                                placeholder={I18n.t('standardMessages.all')}
                                isDisabled = {true}
                                />:
                            <ReactMultiSelectCheckboxes
                                value={this.state.selectedL4}
                                onChange={(e)=>this.onL4Change(e)}
                                options={this.state.l4List}
                            
                                placeholderButtonLabel={I18n.t('standardMessages.all')}
                            />  }  
                        </div>
                        <div style = {{margin : '20px'}}/>
                        <div className={"col-md-2 noPadding bigDD-" + this.props.theme.name}>
                            {isL5Disabled?<Select
                                className = "disabledField"
                                value={this.state.selectedL5}
                                placeholder={I18n.t('standardMessages.all')}
                                isDisabled = {true}
                                />:<ReactMultiSelectCheckboxes
                                value={this.state.selectedL5}
                                onChange={(e)=>this.onL5Change(e)}
                                options={this.state.l5List}
                        
                                placeholderButtonLabel={I18n.t('standardMessages.all')}
                            />}

                        </div>
                        <div style = {{margin : '20px'}}/>
                        <div className={"col-md-2 noPadding bigDD-" + this.props.theme.name}>
                           {isL6Disabled?<Select
                                className = "disabledField"
                                value={this.state.selectedL6}
                                placeholder={I18n.t('standardMessages.all')}
                                isDisabled = {true}
                                />: <ReactMultiSelectCheckboxes
                                value={this.state.selectedL6}
                                onChange={(e)=>{this.onL6Change(e)}}
                                options={this.state.l6List}
                    
                                placeholderButtonLabel={I18n.t('standardMessages.all')}
                            />}
                    </div>
                    <div style={{ margin: '20px' }} />
                        <div className={"col-md-1 noPadding bigDD-" + this.props.theme.name}>
                            <input type="text"
                                className="form-control"
                                maxLength="50"
                                //placeholder ={I18n.t('toastr.multiSelectPlaceHolderUser')}
                                style={{width:'7.2rem',height:'calc(1.5em + .75rem + 5px)'}}
                                value={this.state.dealerCode} onChange={this.dealerCodeChange} />

                        </div> 

                    <div style = {{margin : '18px'}}/>
                        <div className="col-md-1" style={{ borderTop: '0px', padding: '0px' }}>
                            <button className={"button-" + this.props.theme.name + " noMargin"}  style={{width:'5rem',padding:'2px',marginLeft:'1rem'}} 
                            disabled = {(isAdmin && this.props.userDto.status !== "INACTIVE")?false:true}
                              
                            onClick={(e) =>this.assign(e)} >{I18n.t('UserManagement.Assign')}</button>
                        </div>
                    </div>
               
                </div>
                {/* <div id="hrId" className="col-md-12 hrClass">
                    <hr style={{ margin: '0px', borderTop: "1px solid" }} />
                </div> */}
            </div>
        );
    }
}
const mapStateToProps = (state) => {
    return {
        user: state.loginReducer,
        blocking: state.loginReducer,
        router: state.routing,
        // userFilters : state.userManagementReducer.userFilters,
        // userSelectedFilters : state.userManagementReducer.userSelectedFilters
        linkFilter : state.userManagementReducer.linkFilters,
        dealerFilter: state.userManagementReducer.linkDealerFilters
     
      
    }
}
export default withRouter(connect(mapStateToProps)(withTheme(LinkFilter)));