import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { withTheme } from "../../../themes/theming";
import { getDealerMainQuestion, getDealerSubQuestion, fetchEvaluationChapterSequence, submitEvaluation } from '../../../actions/evaluationAction';
import { saveVisibilityOnUpdate} from '../../../actions/activityAction.jsx';
import { Collapse } from "react-bootstrap";
import LibraryLink from "./libraryLink";
import UploadEvidence from "./uploadEvidence"
import AddComments from "./addComments"
const $ = require('jquery')

var I18n = require("react-redux-i18n").I18n;

class QuestionDetails extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            openQuestion: true,
            openLibrary: false,
            rotateQuestion: "rotate-true",
            rotateLibrary: "rotate-false",
            questionNo: '',
            mainQuestionDetails: {},
            subQuestionDetails: [],
            chapterSequence: '',
            selectedQuestionId: this.props.selectedNodeId,
            nmscQuestionId: ''
        }
        this.toggleQuestion = this.toggleQuestion.bind(this);
        this.toggleLibrary = this.toggleLibrary.bind(this);
        this.getSubQuestion = this.getSubQuestion.bind(this);
    }

    componentDidMount() {
        this.props.dispatch({
            type: "EVALUATION_ID",
            payload: null
        });
    }

    componentWillReceiveProps(nextProps) {
        if (this.props.selectedNodeId !== nextProps.selectedNodeId && nextProps.selectedNodeId && nextProps.selectedNodeId !== '') {
            this.setState({
                selectedQuestionId: nextProps.selectedNodeId,
                openQuestion: true,
                openLibrary: false,
                rotateQuestion: "rotate-true",
                rotateLibrary: "rotate-false"
            });
            this.props.dispatch(fetchEvaluationChapterSequence(nextProps.selectedNodeId));
            this.props.dispatch(getDealerMainQuestion(nextProps.selectedNodeId));
            this.props.dispatch(getDealerSubQuestion(nextProps.selectedNodeId, this.props.activityId));
            $('ul.evaluationList').scrollTop(0);
            $('div.subQuestionDetails').scrollTop(0);
        }
        if (nextProps.evaluation.evaluationMainQuestion) {
            const mainQuestion = nextProps.evaluation.evaluationMainQuestion[0];
            this.setState({ mainQuestionDetails: mainQuestion });
        }
        if (nextProps.evaluation.evaluationSubQuestion) {
            const subQuestions = [...nextProps.evaluation.evaluationSubQuestion];
            this.setState({ subQuestionDetails: subQuestions });
        }
        if (nextProps.evaluation.nmscChapterSequence) {
            this.setState({ chapterSequence: nextProps.evaluation.nmscChapterSequence });
        }
        if (nextProps.evaluation.evaluationVersion) {
            this.setState({ evaluationVersion: nextProps.evaluation.evaluationVersion });
        }
        if (nextProps.evaluation.nmscQuestionId) {
            this.setState({ nmscQuestionId: nextProps.evaluation.nmscQuestionId });
        }


    }
    toggleQuestion = e => {
        if (this.state.rotateQuestion === "rotate-false") {
            this.setState({ rotateQuestion: "rotate-true" });
        } else {
            this.setState({ rotateQuestion: "rotate-false" });
        }
    };
    toggleLibrary = e => {
        if (this.state.rotateLibrary === "rotate-false") {
            this.setState({ rotateLibrary: "rotate-true" });
        } else {
            this.setState({ rotateLibrary: "rotate-false" });
        }
    }

    submitAnswer = (answer, subQuestion, currentstatus,comment, refreshHistory) => {
        var evaluationDTO = {
            versionSubQuestionId: subQuestion.versionSubQuestionId,
            activityId: this.props.activityId,
            answer: answer,
            loginId: this.props.user.userMdl.username,
            comment: comment,
            evaluationId: subQuestion.evaluationId,
            noToYesIND:currentstatus,
            // businessAreaKey:this.props.user.userMd1.businessAreaKey
        }

        this.setState({ subQuestion: subQuestion });
        this.setState({ answer: answer });
        var selectedChapterId = this.state.mainQuestionDetails.versionChapterId;
        this.setState({ selectedChapterId: selectedChapterId })
        this.props.dispatch(submitEvaluation(evaluationDTO, this.state.evaluationVersion, selectedChapterId, this.props.selectedNodeId, refreshHistory));
        //check Dealer Champion Role
        var isDealerUser = false;
        if (this.props.user.userMdl.roles !== undefined) {
          var roles = this.props.user.userMdl.roles;
          for (var i = 0; i < roles.length; i++) {
            if (roles[i].toUpperCase() ==="KET DEALER CHAMPION" || roles[i].toUpperCase() === "KET DEALER INTERNAL" || roles[i].toUpperCase() === "KET NMSC External" || this.props.user.userMdl.roles[i] === "KET NMSC User" || this.props.user.userMdl.roles[i] === "KET NMSC Field") {
                isDealerUser = true;
              break;
            }
          }
        }
        console.log(isDealerUser);
        if(isDealerUser===true){
            var viewStatusDTO = {
                activityId:  this.props.activityId,
                loginId: this.props.user.userMdl.username,
                questionnaireUpdated :"True",
            }
            this.props.dispatch(saveVisibilityOnUpdate(viewStatusDTO));
        }
      
    }


    toggleEvidence = (subQuestion) => {
        this.setState({ subQuestion: subQuestion });
        this.setState({ toggleEvidence: !this.state.toggleEvidence });
    }

    toggleComment = (subQuestion, answer) => {
        if (subQuestion) {
            this.props.dispatch({
                type: "EVALUATION_ID",
                payload: subQuestion.evaluationId
            });
            if (!answer) {
                this.setState({ answer: subQuestion.answer });
            }
        }

        this.setState({ subQuestion: subQuestion });
        this.setState({ toggleComment: !this.state.toggleComment });
    }

    submitAnswerNo = (subQuestion) => {
        this.setState({ answer: -1 });
        this.toggleComment(subQuestion, true);
    }
	
	buttonFormatter = (row) => {
 
	    return (
		 
		   <div className="btn-toolbar float-right">
		     {row.additionalInfo && row.additionalInfo.length > 0 ?
		 
		        <div
		          style={{ whiteSpace: "pre" }}
		          title={row.additionalInfo}
		          className="addP"
		        />
		       : ""}
		 
		   </div>
		 
		);
	};

    getSubQuestion(subQuestion) {
        // console.log(subQuestion)

        let accessType = '';
        if (this.props.user.userMdl && this.props.user.userMdl.accessControl) {
            accessType = this.props.user.userMdl.accessControl.filter(obj =>
                obj.function === 'ACTIVITY_EVALUATION')[0] ? this.props.user.userMdl.accessControl.filter(obj =>
                    obj.function === 'ACTIVITY_EVALUATION')[0].accessType : "";
        }
        var hasAccess = (accessType === "R/W" && this.props.activityStatus === "Ongoing") ? true : false;
        return (<div key={subQuestion.nmsc_subquestion_id} className="subQuestionEvaluation">
            <div style={{ fontWeight: '700' }} className="evalSubQuestionSeq">{(this.state.chapterSequence.type === "LOCAL" ? this.state.chapterSequence.sequence + "#" : this.state.chapterSequence.sequence) + "." + (this.state.mainQuestionDetails.type === "LOCAL" ? this.state.mainQuestionDetails.seqNum + "#" : this.state.mainQuestionDetails.seqNum) + "." + subQuestion.seqNum + " "}</div>
            <div className="subQuestionEvaluationContent">
                <div style={{ fontWeight: '700' }}>{subQuestion.name}</div>
                <hr />
                <div className="evaluationAnswers">
                    {subQuestion.answer === 1 ?            
                        <div className="evaluationAnswerButton yesAns">
                            <div className="answerContent " >{I18n.t("evaluation.yes")}</div>
                        </div> : hasAccess ?
                            <div className="evaluationAnswerButton">
                                {console.log(subQuestion.answer)}
                                <div className="answerContent" onClick={() => this.submitAnswer(1, subQuestion ,subQuestion.answer===-1?"Y":"N" )
                               }>{I18n.t("evaluation.yes")}
                                </div>
                            </div> : <div className="evaluationAnswerButton defaultPointer">
                                <div className="answerContent" >{I18n.t("evaluation.yes")}</div>
                                
                            </div>}
                    {subQuestion.answer === -1 ?
                        <div className="evaluationAnswerButton noAns">
                            <div className="answerContent" >{I18n.t("evaluation.no")}</div>{subQuestion.noToYesIND==="Y"?<div className="noToYesmsg">{I18n.t("evaluation.noToYesMsg")}</div>:" "}
                        </div> : hasAccess ?
                            <div className={subQuestion.noToYesIND==="Y"?"evaluationAnswerButtonGrey":"evaluationAnswerButton"}>
                                <div className="answerContent" onClick={() => this.submitAnswerNo(subQuestion,subQuestion.answer?subQuestion.answer:"")
                                }>{I18n.t("evaluation.no")}
                                </div>{subQuestion.noToYesIND==="Y"?<div className="noToYesmsg">{I18n.t("evaluation.noToYesMsg")}</div>:" "}
                            </div> : <div className="evaluationAnswerButton defaultPointer">
                                <div className="answerContent">{I18n.t("evaluation.no")}</div>
                                
                            </div> }
                    {subQuestion.answer === 0 ?
                        <div className="evaluationAnswerButton naAns">
                            <div className="answerContent">{I18n.t("evaluation.na")}</div>
                        </div> : (subQuestion.isApplicable && hasAccess) ?
                            <div className="evaluationAnswerButton">
                                <div className="answerContent" onClick={() => this.submitAnswer(0, subQuestion)}>{I18n.t("evaluation.na")}</div>
                            </div>
                            : <div className="evaluationAnswerButton defaultPointer isNotApplicable">
                                <div className="answerContent" >{I18n.t("evaluation.na")}</div>
                            </div>}
                </div>
                <div className={"evaluationComments-" + this.props.theme.name}>
                    <div title={I18n.t("evaluation.comments")} className="comment-icon" />
                    <span>
                        {subQuestion.evaluationId ?
                            <a onClick={() => this.toggleComment(subQuestion)}>
                                {I18n.t("evaluation.comments")}
                            </a> : I18n.t("evaluation.comments")}</span>
                    {subQuestion.isCommentLogged ?
                        <div className="evidenceUploaded" />
                        : <div className="evidenceNotUploaded" />}
                </div>
                <div className={"evaluationHistory-" + this.props.theme.name}>
                    <div title={I18n.t("evaluation.evidence")} className="evidence-icon" />
                    <span>
                        {subQuestion.evaluationId ?
                            <a onClick={() => this.toggleEvidence(subQuestion)}>{I18n.t("evaluation.evidence")}</a>
                            : I18n.t("evaluation.evidence")}</span>
                    {subQuestion.isEvidenceUploaded ?
                        <div className="evidenceUploaded" />
                        : <div className="evidenceNotUploaded" />}
                </div>
				
				<div className={"evaluationHistory-" + this.props.theme.name}>
					<div title={I18n.t("questionnaire.additionalInfo")} />
					{this.buttonFormatter(subQuestion)}
				</div>
			</div>
        </div>);
    }

    render() {
        // console.log(this.state.subQuestionDetails)


        if (this.props.theme.name === "lexus" && $('img.toyota')) {
            $(".mainQuestionArrow").removeClass("toyota");
            $(".mainQuestionArrow").addClass("lexus");
            $(".mainQuestionArrow").attr("src", "/images/upArrow-lexus.png");
        }
        else if (this.props.theme.name === "toyota" && $('img.lexus')) {
            $(".mainQuestionArrow").removeClass("lexus");
            $(".mainQuestionArrow").addClass("toyota");
            $(".mainQuestionArrow").attr("src", "/images/upArrow-toyota.png");
        } else {
            //..
        }

        const { openQuestion } = this.state;
        const { openLibrary } = this.state;
        var libraryContainer = null;
        if (this.state.nmscQuestionId !== '' && this.state.nmscQuestionId !== -9999 && this.state.nmscQuestionId !== "-9999") {

            libraryContainer = (
                <LibraryLink questionId={this.state.nmscQuestionId} versionId={this.props.versionId}
                />
            );
        }

        return (

            <div style={{ width: '100%', marginLeft: '1rem' }}>
                <div className="mainQuestionDetails" style={{ width: '100%' }}>
                    <div className="col-md-12">
                        <div className="questionTitle">
                            {I18n.t('questionnaire.question')}{" " + this.state.chapterSequence.sequence + "." + this.state.mainQuestionDetails.seqNum + ((this.state.chapterSequence.type === "LOCAL" || this.state.mainQuestionDetails.type === "LOCAL") ? "#" : "") + "- "}
                            {this.state.mainQuestionDetails.description}
                        </div>
                    </div>
                    <div className="col-md-11 whySection">
                        <div style={{ position: "relative" }}>
                            <span
                                onClick={() => {
                                    this.setState({ openQuestion: !openQuestion });
                                    this.toggleQuestion();
                                }}
                                aria-expanded={openQuestion}
                            >
                                <img
                                    src={"/images/upArrow-" + this.props.theme.name + ".png"}
                                    className={this.state.rotateQuestion + " mainQuestionArrow toyota"}
                                    alt=""
                                />
                            </span>
                            <span>
                                <div className={"buttonHeading-" + this.props.theme.name}>
                                    {I18n.t("questionnaire.why")}
                                </div>
                            </span>
                        </div>
                        <Collapse in={this.state.openQuestion} className="mainQuestionWhyEvaluation">
                            <div id="collapseText">
                                <div className="collapseContainerEval">
                                    {this.state.mainQuestionDetails.reason}
                                </div>
                            </div>
                        </Collapse>
                    </div>
                    <div className="col-md-11 librarySection">
                        <div style={{ position: "relative" }}>
                            <span
                                onClick={() => {
                                    this.setState({ openLibrary: !openLibrary });
                                    this.toggleLibrary();
                                }}
                                aria-expanded={openLibrary}
                            >
                                <img
                                    src={"/images/upArrow-" + this.props.theme.name + ".png"}
                                    className={this.state.rotateLibrary + " mainQuestionArrow toyota"}
                                    alt=""
                                />
                            </span>
                            <span>
                                <div className={"buttonHeading-" + this.props.theme.name}>
                                    {I18n.t('evaluation.attachments')}
                                </div>
                            </span>
                            <span />
                        </div>
                        <Collapse
                            in={this.state.openLibrary}
                            className="mainQuestionLibraryEvaluation"
                        >
                            <div id="collapseText" className="libraryLinkContainer">
                                <div className="collapseContainerLibrary">
                                    {this.state.selectedQuestionId !== '' ?
                                        libraryContainer
                                        : null}
                                </div>
                            </div>
                        </Collapse>
                    </div>
                </div>
                <div className="subQuestionDetails">
                    {/* <div className=""> */}
                        <div className={"buttonHeadingWhat-" + this.props.theme.name}>
                            {I18n.t("questionnaire.what")}{" "}
                        </div>
                    {/* </div> */}
                    <ul className="evaluationList">
                        {this.state.subQuestionDetails.map((subQuestion) => {
                            return <li>{this.getSubQuestion(subQuestion)}</li>
                        })}
                    </ul>
                </div>

                {this.state.toggleEvidence ? <UploadEvidence activityId={this.props.activityId} questionId={this.state.selectedQuestionId} toggleEvidence={this.toggleEvidence} subQuestion={this.state.subQuestion} activityStatus={this.props.activityStatus}
                    chapterSequence={this.state.chapterSequence} mainQuestionDetails={this.state.mainQuestionDetails} /> : ""}
                {this.state.toggleComment ? <AddComments toggleComment={this.toggleComment} submitAnswer={this.submitAnswer} subQuestion={this.state.subQuestion} answer={this.state.answer} activityStatus={this.props.activityStatus} /> : ""}
            </div>

        );
    }
}
const mapStateToProps = state => {
    return {
        user: state.loginReducer,
        evaluation: state.evaluationReducer,
        tab: state.tabReducer
    };
};
export default withRouter(connect(mapStateToProps)(withTheme(QuestionDetails)));
