import React from "react";
import { Modal, ModalHeader, ModalFooter } from "reactstrap";
import BootstrapTable from 'react-bootstrap-table-next';
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { withTheme } from "../../themes/theming";
import './index.css';
import { getMonthlyCommentHistory } from "../../actions/bulkCeaKpiEntryAction";
var I18n = require("react-redux-i18n").I18n;

class historyMonthlyComments extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            commentHistory: []
        }
    }

    componentWillMount() {
        this.props.dispatch(getMonthlyCommentHistory(this.props.activityId));
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.kpiMonthlyComment) {
            this.setState({ commentHistory: nextProps.kpiMonthlyComment })
        }
    } 
    
    CommentFormatter = (cell, row) => {
        return (
            <div style={{ textOverflow: "ellipsis", overflow: "hidden", width: "24rem"}}>{row.comment}</div>
        );
    }
    monthFormatter = (cell, row) => {
        const month = ["January","February","March","April","May","June","July","August","September","October","November","December"];
        return month[row.kpiMonth - 1];
    }

    preventDefault() { }
    render() {
        const columns = [{
            dataField: 'username',
            text: I18n.t("usedCarComment.username"),
            style: { width: "15%", textAlign: "left" },
            headerStyle: { width: "14%", textAlign: "left" },
        },
        {
            dataField: 'email',
            text: I18n.t("usedCarComment.email"),
            style: { width: "25%", textAlign: "left" },
            headerStyle: { width: "24%", textAlign: "left" },
        },
        {
            dataField: 'kpiMonth',
            text: I18n.t("usedCarComment.month"),
            style: { width: "5%", textAlign: "left" },
            headerStyle: { width: "6%", textAlign: "left" },
            formatter: this.monthFormatter,
        },
        {
            dataField: 'comment',
            text: I18n.t('evaluation.comment'),
            style: { width: "30%", wordWrap: "break-word", textAlign: "left" },
            headerStyle: { width: "43%", textAlign: "left" },
            formatter: this.CommentFormatter,
            title: true
        }, {
            dataField: 'updatedTimestamp',
            text: I18n.t("usedCarComment.createdDate"),
            style: { width: "20.5%" },
            headerStyle: { width: "15%",textAlign: "left" }
        }];

        return (
            <Modal centered
                className={"historyComments uploadModal-" + this.props.theme.name}
                isOpen={true}
                toggle={() => this.preventDefault()} >
                <ModalHeader toggle={() => this.props.toggleCommentsCancel()}>
                {I18n.t("usedCarComment.commentHistory")}
                </ModalHeader>
                <ModalFooter>
                    <div className="monthComment">
                        <BootstrapTable
                            keyField='historyId'
                            data={this.state.commentHistory}
                            columns={columns}
                            hover
                            condensed
                            noDataIndication={I18n.t('evaluation.noCommentAvailable')}
                        />
                    </div>
                </ModalFooter>
            </Modal >
        );
    }
}
const mapStateToProps = state => {
    return {
        kpiMonthlyComment: state.ceaKpiEntryReducer.kpiMonthlyComment,  
    };
};
export default withRouter(connect(mapStateToProps)(withTheme(historyMonthlyComments)));


