import React from "react";
import { Modal, ModalBody, ModalHeader, ModalFooter } from "reactstrap";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { withTheme } from "../../themes/theming";
import { Form } from 'react-bootstrap';
import { saveSubQuestion, saveNMSCSubQuestion, saveLocalSubQuestionnaire } from './../../actions/subQuestionAction'
import "./index.css";
import { toastr } from "react-redux-toastr";
import * as AppConst from '../../AppConstant';
var I18n = require("react-redux-i18n").I18n;

class SubQuestionnairModal extends React.Component {
  /* eslint-disable */
  constructor(props) {
    super(props);
    this.state = {
      subQuestion: this.props.selectedRow ? this.props.selectedRow.name : "",
      additionalInfo: this.props.selectedRow ? this.props.selectedRow.additionalInfo : "",
      notApplicable: this.props.selectedRow && this.props.selectedRow.notApplicable === true ? true : false,
      critical: this.props.selectedRow && this.props.selectedRow.isCritical === true ? true : false,
    };
  }

  saveSubQuestion = e => {
    if (!this.state.subQuestion || this.state.subQuestion === "") {
      toastr.error(I18n.t("toastr.modalMandatory"));
      return;
    }
    if (this.state.notApplicable===true && this.state.critical === true) {
      toastr.error("A question can be labeled as either 'Not Applicable' or 'Critical', but not both. Please uncheck one option and proceed.");
      return;
    }
    e.preventDefault();
   if(this.props.isNmsc){
     if(this.props.selectedRow && this.props.selectedRow.type && this.props.selectedRow.type === "TME") {
        var subQuestionnaireDTO = {
          id: this.props.selectedRow.nmscSubQuestionId,
          questionId: this.props.questionId,
          seqNum: this.props.selectedRow ? this.props.selectedRow.seqNum : 0,
          name: this.state.subQuestion,
          additionalInfo: this.state.additionalInfo,
          notApplicable: this.state.notApplicable,
          critical: this.state.critical,
          loginId: this.props.user.userMdl.username
        }
        this.props.dispatch(saveNMSCSubQuestion(subQuestionnaireDTO, this.props.questionId));
     }
     else{
      var subQuestionnaireDTO = {
        nmscSubQuestionId: this.props.selectedRow ? this.props.selectedRow.nmscSubQuestionId : 0,
        nmscQuestionId: this.props.questionId,
        seqNum: this.props.selectedRow ? this.props.selectedRow.seqNum : 0,
        name: this.state.subQuestion,
        additionalInfo: this.state.additionalInfo,
        notApplicable: this.state.notApplicable,
        critical: this.state.critical,
        loginId: this.props.user.userMdl.username,
        nmscCode: this.props.user.userMdl.nmsc,
        nmscLang: this.props.activeTabNmscLang,
        type: this.props.selectedRow ? this.props.selectedRow.type : "Local"
      }
      this.props.dispatch(saveLocalSubQuestionnaire(subQuestionnaireDTO, this.props.questionId));
    }
  }
    else {
      var subQuestionnaireDTO = {
        id: this.props.selectedRow ? this.props.selectedRow.id : 0,
        questionId: this.props.questionId,
        seqNum: this.props.selectedRow ? this.props.selectedRow.seqNum : 0,
        name: this.state.subQuestion,
        additionalInfo: this.state.additionalInfo,
        notApplicable: this.state.notApplicable,
        critical: this.state.critical,
        loginId: this.props.user.userMdl.username
      }
      this.props.dispatch(saveSubQuestion(subQuestionnaireDTO, this.props.questionId));
    }
    this.props.toggle(null);
  };

  onSubQuestionChange = (e) => {
    this.setState({ subQuestion: e.target.value });

  }

  onAddInfoChange = (e) => {
    this.setState({ additionalInfo: e.target.value });

  }

  onNotApplicableChange = (e) => {
    this.setState({ notApplicable: !this.state.notApplicable });
  }

  onIsCriticalChange = (e) => {
    this.setState({ critical: !this.state.critical });
  }
 
  preventDefault() { }
  render() {
    return (
      <Modal
        className={"addEditQuestionModal addEditQuestionModal-" + this.props.theme.name}
        isOpen={true}
        toggle={() => this.preventDefault()}
      >
        <ModalHeader toggle={() => this.props.toggle()}>
          {this.props.modalFor && this.props.modalFor === "Add" ? I18n.t("questionnaire.addSubQuestion") : I18n.t("questionnaire.editSubQuestion")}
        </ModalHeader>

        <ModalBody>
          <Form style={{ margin: "20px" }}>
            {this.props.modalFor === "Edit" && this.props.selectedRow.seqNum ? <label style={{ color: "red" }}>{this.props.selectedRow.seqNum}</label> : ""}
            <br />
            <br />
            <label className={"questionLabel"}>
              {I18n.t("questionnaire.subQuestionTitle")}
              <span className="redFont"> *</span>
            </label>
            <textarea
              className="form-control"
              rows="3" maxLength="1000" value={this.state.subQuestion} onChange={this.onSubQuestionChange}
            />
            < br />
            <label className={"questionLabel"}>
              {I18n.t("questionnaire.additionalInfo")}
            </label>
            <textarea
              className="form-control" onChange={this.onAddInfoChange}
              rows="3" maxLength="1000" value={this.state.additionalInfo}
            />
            <br />
            <br />
            <Form.Group controlId="formBasicChecbox">
              {console.log(this.state.critical,this.state.notApplicable)}
             
              <Form.Check type="checkbox" label={I18n.t("questionnaire.na")} checked={this.state.notApplicable} onChange={this.onNotApplicableChange} />

            {/*   {this.props.selectedRow && this.props.selectedRow.type && this.props.selectedRow.type === "TME" ?
                < Form.Check type="checkbox" disabled label={I18n.t("questionnaire.na")} checked={this.state.notApplicable} onChange={this.onNotApplicableChange} /> : */}
              
             { this.props.user.userMdl.activeProgram === AppConst.activeProgramNas ?
                <Form.Check type="checkbox" label={I18n.t("questionnaire.cr")} checked={this.state.critical}  onChange={this.onIsCriticalChange}  /> :"" }
                
              
                  
            </Form.Group>
          </Form>
        </ModalBody>
        <ModalFooter>
          <button
            className={"buttonCancel-" + this.props.theme.name}
            onClick={() => this.props.toggle(this.props.modalFor)}
          >
            {I18n.t("tmeAdminMaster.cancel")}
          </button>
          {this.props.modalFor === "Add" ? (
            <button
              className={"button-" + this.props.theme.name}
              onClick={this.saveSubQuestion}
            >
              {I18n.t("tmeAdminMaster.save")}
            </button>
          ) : (
              <button
                className={"button-" + this.props.theme.name}
                onClick={this.saveSubQuestion}
              >
                {I18n.t("tmeAdminMaster.update")}
              </button>
            )}
        </ModalFooter>
      </Modal>
    );
  }
}
const mapStateToProps = state => {
  return {
    user: state.loginReducer
  };
};
export default withRouter(connect(mapStateToProps)(withTheme(SubQuestionnairModal)));
