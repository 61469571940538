export default function evaluationReducer(state = {
    userMdl: {}, blocking: false, refreshRequired: false, closeModal: false, fileList: [], successFileCount: 0, failureFileCount: 0, totalFileCount: 0
}, action) {
    switch (action.type) {
        case "EVALUATION_CHAPTERS":
            return Object.assign({}, state, {
                evaluationChapters: action.payload
            });
        case "EVALUATION_VERSION":
            return Object.assign({}, state, {
                evaluationVersion: action.payload
            });
        case "EVALUATION_MAIN_CHAPTER_SEQ":
            return Object.assign({}, state, {
                nmscChapterSequence: action.payload
            });
        case "EVALUATION_NMSC_QUESTION":
            return Object.assign({},state,{
                nmscQuestionId:action.payload
            });
        case "EVALUATION_ACTIVITY_COMPLETED":
            return Object.assign({}, state, {
                evaluationActivityCompleted: action.payload
            });
        case "EVALUATION_PROGRESSBAR":
            return Object.assign({}, state, {
                evalautionProgressBar: action.payload
            });
        case "EVALUATION_MAIN_QUESTION":
            return Object.assign({}, state, {
                evaluationMainQuestion: action.payload
            });
        case "EVALUATION_SUB_QUESTION":
            return Object.assign({}, state, {
                evaluationSubQuestion: action.payload
            });
        case "EVIDENCE_FILE":
            return Object.assign({}, state, {
                evidenceFiles: action.payload
            });
        case "ATTACHMENT_FILE":
            return Object.assign({}, state, {
                referEvidenceFiles: action.payload
            });
        case "EVALUATION_HISTORY_COMMENT":
            return Object.assign({}, state, {
                evidenceHistory: action.payload
            });
        case "SUCCESS_FILE_COUNT":
            return Object.assign({}, state, {
                successFileCount: state.successFileCount + 1
            });
        case "FAILURE_FILE_COUNT":
            return Object.assign({}, state, {
                failureFileCount: state.failureFileCount + 1
            });
        case "TOTAL_FILE_COUNT":
            return Object.assign({}, state, {
                totalFileCount:action.payload
            });
        case "RESET_FILE_COUNT":
            return Object.assign({}, state, {
                totalFileCount: 0,failureFileCount:0,successFileCount:0
            });
        case "EVALUATION_ID":
            return Object.assign({}, state, {
                evaluationId:  action.payload
            });
        case "VISIBILITY_STATUS":
            return Object.assign({}, state, {
                visibilityStatus:  action.payload
            });
        case "ACTIVITY_DETAILS":
            return Object.assign({},state,{
                activityDetails:action.payload
            });

        case "COMPLETE_AUDIT":
            return Object.assign({},state,{
                firstAuditDetails:action.payload
            });

        default:
            return state;
    }
};