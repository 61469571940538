import React from 'react';
import { connect } from 'react-redux';
import { withTheme, themes } from '../../../themes/theming';
import { NavbarNav, NavItem, Fa, Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'mdbreact';
import { withRouter, Link } from 'react-router-dom';
import { push } from "connected-react-router";
import './index.css';
var I18n = require('react-redux-i18n').I18n;
const $ = require('jquery')
window.jQuery = $;

class AuditMenu extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            collapse: false,
            isWideEnough: false,
            theme: themes.default
        };
        this.onClick = this.onClick.bind(this);
    }

    onClick() {
        this.setState({
            collapse: !this.state.collapse,
        });
    }

    // addUser(e) {
    //     e.preventDefault();
    //     this.props.dispatch(push("/userManagement"));
    // }

    // addDealer(e) {
    //     e.preventDefault();
    //     this.props.dispatch(push("/dealerManagement"));
    // }

    componentWillMount() {
        // alert("inside header" + JSON.stringify(this.props.user.userMdl))
    }

    addCss(e) {
        let selectedTabName = e.target.innerText;
        $("a").filter(function () {
            if ($(this).text() === selectedTabName) {
                $(this).addClass("selectedMenu");
            } else {
                $(this).removeClass("selectedMenu");
            }
            return null;
        });
    }

    kpi(e) {
        e.preventDefault();
        this.props.dispatch(push("/kpiUpload"));
    }

    render() {
        const navLinkClass = this.props.theme.name + "-nav-link nav-link";
        const navLinkClassAdmin = this.props.theme.name + "-nav-link nav-link backColor";
        return (
            <NavbarNav left >  <NavItem active>

                 <Link className={navLinkClass} to="/home" onClick={this.addCss.bind(this)}>
                    <Fa icon="home" fixed className="mr-1" />
                    {I18n.t('TopNav.Home')}
                </Link> 
            </NavItem>
                <NavItem>
                      <Link className={navLinkClass} to="/toolbox" onClick={this.addCss.bind(this)}>
                    <Fa icon="wrench" fixed className="mr-1" />
                        {I18n.t('TopNav.AuditsSetUp')}
                    </Link>
                </NavItem>
                <NavItem>
                    <Link className={navLinkClass} to="/activity" onClick={this.addCss.bind(this)}>
                        <Fa icon="briefcase" fixed className="mr-1" />
                        {I18n.t('TopNav.Audits')}
                    </Link>
                </NavItem>
		
                <NavItem>
                    <Dropdown>
                        <DropdownToggle nav caret className={navLinkClassAdmin} onClick={this.addCss.bind(this)}>
                            {I18n.t('TopNav.Admin')}
                        </DropdownToggle>
                        <DropdownMenu>
                            <DropdownItem
                                className="dd_item"
                                onClick={this.kpi.bind(this)}
                            >
                                 {I18n.t('others.bulkActions')}
                            </DropdownItem>
                        
                        </DropdownMenu>
                    </Dropdown>
                </NavItem>



           
            </NavbarNav>
        );
    }
}
const mapStateToProps = (state) => {
    return {
        user: state.loginReducer,
        blocking: state.loginReducer,
        router: state.router
    }
}

export default withRouter(connect(mapStateToProps)(withTheme(AuditMenu)));