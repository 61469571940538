const translationsCS = {
  cs: {
    Policy: {
      HeaderMsg: "Oznámení o ochraně osobních údajů při jejich zpracování v rámci nástroje Kaizen Expert Tool",
      Message1: "Toto Prohlášení o ochraně osobních údajů se týká zpracování Vašich osobních údajů v rámci nástroje Kaizen Expert Tool. Vždy se musí číst spolu se Všeobecnou politikou ochrany osobních údajů a ochrany osobních údajů společnosti Toyota, ve které jsou popsány obecné způsoby zpracování Vašich osobních údajů a které musíte přijmout, abyste mohli pokračovat dále.",
      Message2: "Toyota Motor Europe NV / SA, se sídlem na Avenue du Bourget / Bourgetlaan 60, 1140 Brusel, Belgie, jako Správce osobních údajů, zpracovává vaše osobní údaje (jak je uvedeno níže) pro účely popsané níže.",
      Message3: "Které z Vašich osobních údajů budou zaznamenány?",
      Message4: "V souvislosti s nástrojem Kaizen Expert Tool budeme zaznamenávat následující kategorie vašich osobních údajů:",
      Table1: {
        tr1: "Kategorie osobních údajů:",
        tr2: "Uživatelské ID",
        tr3: "Uživatelské jméno (celé)",
        tr4: "Značka ( Toyota, Lexus) ",
        tr5: "Jazyk",
        tr6: "Organizace"
      },

      Message5: "Účel a právní zásady zpracování vašich osobních údajů.",
      Message6: "V souladu s právními zásadami si vyhrazujeme právo zpracováváme Vaše osobní údaje pro účely popsané níže:",
      Table2: {
        tr1: {
          th1: "Účel",
          th2: "Právní zásady:"
        },
        tr2: {
          td1: "KET – 1: Retailer Performance Kaizen",
          td2: "Oprávnění Správce nakládat s osobními údaji"
        },
        tr3: {
          td1: "KET – 2: IT Support",
          td2: "Oprávnění Správce nakládat s osobními údaji"
        }
      },
      Message7: "Doba uchování Vašich osobních údajů",
      Message8: "Vaše osobní údaje budou uchovávány po dobu uvedenou níže:",
      Table3: {
        tr1: {
          th1: "Doba uchování:",
          th2: "Kategorie osobních údajů:"
        },
        tr2: {
          td1: "Všechny výše uvedené osobní údaje budou uchovávány maximálně 5 let + 1 měsíc ode dne konečného uzavření nástroje Kaizen Expert Tool.",
          td2: "Jméno uživatele, Příjmení, Jazyk, organizace"
        }
      },
      Message9: "Nepovinné: V případě zveřejnění / přenosu vašich osobních údajů do zemí mimo Evropského hospodářského prostoru (EHP)",
      Message10: "Vaše osobní údaje budou zpřístupněny příjemcům v zemích mimo EHP, jak je uvedeno níže:",
      Table4: {
        tr1: {
          td1: "Totožnost",
          td2: "Stát",
          td3: ""
        },
        tr2: {
          td1: "Příjemce (-i) mimo EHP",
          td2: "Infosys",
          td3: "India"
        }
      },
      Message11: "Tímto potvrzuji, že jsem porozuměl upozornění; Přečetl (a) jsem si a akceptuji politiku ochrany osobních údajů a osobních údajů společnosti Toyota."
    },
    ACCESSORIES: "Příslušenství",
    activity: {
      Assignee: "Odpovědná osoba",
      CompletionDate: "Datum ukončení ",
      Dealer: "Dealer",
      FirstVisit: "První návštěva",
      kpi: {
        kpi: "KPI by měly být čísla (+ nebo -)",
        kpiLength: "Maximální počet znaků pro KPI je 10",
        mandatory: "Prosím, zadejte platné údaje do všech povinných polí",
        mandatoryTargets: "Zadejte prosím všechny KPI. ",
        target: "Target by měl obsahovat pouze čísla",
        targetLength: "Maximální počet znaků pro target je 10"
      },
      ReminderOn: "Připomínka zapnutá",
      statusDD: "Stav",
      Toolbox: "Nástroje",
      activityCreation: "Byl spuštěn proces vytváření aktivity. ",
      activityCreated: "Aktivita byla vytvořena",
    },
    DPOK: "DPOK",
    EFFICIENCY: "Efektivnost",
    FIX_IT_RIGHT: "Oprava napoprvé",
    footer: {
      message: "Povinná pole"
    },
    FOUNDATION: "Záklaní informace",
    MAINTENANCE_REMINDERS: "Připomínka údržby",
    EMPLOYEE_ENGAGEMENT: "Employee Engagement",
    CUSTOMER_CENTRICITY: "Customer Centricity",
    TOYOTA_WAY: "Toyota Way",
    BUSINESS_SUSTAINABILITY: "Business Sustainability",
    FUNDAMENTALS: "Základní principy",
    FIX_IT_RIGHT_BnP: "Fix it Right (B&P)",
    UTILIZATION_AND_EFFICIENCY: "Utilization and Efficiency",
    CUSTOMER_EXPERIENCE: "Customer Experience",
    UPSELLING_OPPORTUNITIES: "Upselling Opportunities",
    MANAGING_CAPACITY: "Managing Capacity",
    VEHICLE_TRADE_IN: "Vehicle Trade-In",
    STOCK_MANAGEMENT: "Stock Management",
    REFURBISHMENT: "Refurbishment",
    months: {
      apr: "Duben",
      aug: "Srpen",
      dec: "Prosinec",
      feb: "Únor",
      jan: "Leden",
      jul: "Červenec",
      jun: "Červen",
      mar: "Březen",
      may: "Květen",
      nov: "Listopad",
      oct: "Říjen",
      sep: "Září"
    },
    others: {
      back: "Zpět",
      closed: "Zavřené",
      completed: "Dokončené ",
      countryLanguageCode: "Kód zemně /jazyka ",
      createActivity: "Vytvořit aktivitu",
      createdDate: "Datum vytvoření",
      dataUneditableNote:
        "Poznámka: Uložená data již nelze upravovat. Pro úpravu dat, se prosím obraťte na správce distributorské sítě.",
      date: "Datum",
      downloadQuestionnaire: "Stáhnout dotazník",
      editActivity: "Upravit aktivitu",
      initialHelpText:
        "Počáteční měření KPI by se mělo rovnat průměrné hodnotě z předchozích šesti měsíců",
      inProgress: "Probíhající",
      name: "Název",
      next: "Další ",
      noActivities: "Nebyly nalezeny žádné aktivity",
      noResults: "Žádné výsledky",
      of: "z",
      ongoing: "Probíhající",
      open: "Otevřít",
      page: "Strana",
      planned: "Plánované",
      problemDescription: "Popis problému",
      signature: "Podpis",
      select: "Vybrat",
      bulkAction: "Bulk Action",
      bulkActions: "Bulk Actions"
    },
    ServerMessage: {
      error_message: "Během stahování došlo k chybě.",
      no_published_subquestion: "Chcete-li zahájit tuto aktivitu, publikujte prosím dotazník pro toto nastavení.",
      activity_upload_error_msg: "Chyba při nahrávání Activity",
      action_plan_created: "Akční plán byl úspěšně vytvořen",
      action_plan_deleted: "Akční plán byl vymazán",
      action_plan_saved: "Akční plán byl uložen",
      activity_kpi_saved: "Aktivity KPI byly uloženy",
      master_kpi_confirm_delete: "Opravdu chcete smazat vybrané KPI?",
      master_kpi_created: "Master KPI byl úspěšně vytvořen",
      master_kpi_deleted: "Master KPI byl vymazán",
      master_kpi_not_found: "Master KPI nebyl nalezen",
      master_kpi_published: "Vybrané Master KPIs byly úspěšně publikovány",
      master_kpi_retired: "Master KPI byl pozastaven",
      master_kpi_saved: "Master KPI byl úspěšně uložen",
      subquestion_created: "Podotázka byla úspěšně uložena.",
      subquestion_updated: "Podotázka byla úspěšně aktualizována.",
      subquestionnaire_not_found: "Tato podotázka nebyla nalezena.",
      subquestion_retired: "Podotázka byla vyřazena.",
      subquestion_deleted: "Podotázka byla vymazána.",
      subquestion_published: "Podotázky byly úspěšně zveřejněny.",
      mainquestion_created: "Hlavní dotazník byl vytvořen.",
      mainquestion_deleted: "Hlavní dotazník byl vymazán.",
      mainquestion_updated: "Otázka byla aktualizována",
      duplicateMsg: "Duplikovaný záznam v Excelu",
      deleteErr: "Chyba při mazání souboru",
      toolbox_deleted: "Modul byl úspěšně smazán",
      toolbox_already_exists: "Tento modul již existuje.",
      toolbox_saved: "Modul byl uložen",
      toolbox_created: "Modul byl vytvořen",
      toolbox_not_found: "Modul nebyl nalezen",
      published_content_found: "Tento modul nelze smazat, protože obsahuje již zveřejněný obsah",
      dealers_already_mapped: " Vybraní dealeři už byli mapováni tímto uživatelem. ",
      dealer_already_mapped: " Dealer already mapped ",
      dealer_added: "  Dealer přidán. ",
      dealers_added: "  Dealer přidán. ",
      total_dealers_mapped_now: "Celkový počet dealerů namapovaných tímto uživatelem je . ",
      no_dealer_available: "Pro zvolené kritérium není dostupný žádný dealer.",
      proper_dealer_selected: "Prosím vyberte vhodného dealera.",
      user_referenced_ongoing_activity: "Uživatel je momentálně přiřazený k probíhajícím aktivitám. Nahraďte v těchto aktivitách zástupce a zkuste to znovu. ",
      want_to_remove_mapping: "Opravdu chcete odebrat mapování uživatelů pro tyto dealery?",
      user_mapping_removed: "Vybraní dealeři byli úspěšně odstraněni z mapování uživatele.",
      dealer_closed_in_A2D: "Tento dealer je registrovaný jako 'Closed' v A2D.",
      network_level_not_found: "Prosím vyberte platnou kombinaci úrovně sítí podle vašeho KET nastavení.",
      duplicate_dealer_uuid: "Dealer je již založený v KET databázi.",
      dealer_created: "Dealer vytvořený.",
      dealer_updated: "Atributy tohoto dealera byly aktualizované. ",
      dealer_not_present: "Tento dealer není registrovaný v A2D.",
      dealer_uuid_other_nmsc: "Vybraný A2D uuid kód není pro vaši síť k dispozici. ",
      target_kpi_updated: "The KPI's status have been updated successfully.",
      target_updated: "The KPI's target have been updated successfully.",
      kpi_launched: "The KPI's bulk launch successfully.",
      targetUpdate:"Application doesn't allow target to be modified for accepted status.",
    },
    standardMessages: {
      delete: "Opravdu chcete smazat ? ",
      addActionPlanNotAllowed: "Akční plán nemůže být vytvořen ani upraven poté, co byla aktivita ukončena, dokončena nebo zamítnuta.",
      addToolboxNotAllowed: "Cannot create more than 19 toolbox",
      britRejectMsg: "Opravdu chcete tuto aktivitu odmítnout? Změny nejsou možné po odmítnutí.",
      rejectMsg: "Opravdu chcete tuto aktivitu odmítnout? Změny nejsou možné po odmítnutí.",
      selectNMSCTab: "Vyberte prosím jazyk příslušného NMSC, v němž se má obsah zveřejnit",
      all: "Vše",
      deleteConfirm: "Opravdu chcete smazat vybrané KPI?",
      no: "Ne",
      retireConfirm:
        "Opravdu chcete tento KPI odebrat? Odebrané KPI, nelze znovu aktivovat.",
      warning: "Varování",
      yes: "Ano",
      saveDataConfirmation: "Opravdu chcete uložit data?",
      saveKPIWarning:
        "Poznámka: Data nelze po uložení upravit. Chcete-li změnit data, kontaktujte správce NMSC KET",
      addKpiNotAllowed: "Vstup KPI pro tento/budoucí měsíce není povolen.",
      activityLaunch: "Opravdu chcete zahájit tyto Activity ? Po zahájení aktivit je nemůžete vrátit do Plánu, nebo smazat. ",
      deleteConfirmSubQuestionNaire: "Opravdu chcete odstranit vybranou podotázku?",
      retireConfirmSubQuestionNaire: "Opravdu chcete vyřadit vybranou podotázku?",
      deleteConfirmMainQuestion: "Potvrďte prosím, zda chcete vymazat otázku a související podotázky.",
      deleteConfirmChapter: "Opravdu chcete odstranit vybranou kapitolu?",
      deleteLibraryFolder: "Opravdu chcete odstranit vybraný složku?",
      deletePublishedFolder: "Některé soubory v této složce mohou být propojeny s dotazníkem. Opravdu chcete trvale smazat tuto podsložku a odstranit odkazy z dotazníku?",
      importQuestion: "Opravdu chcete importovat tuto podotázku? Aktuální podotázka bude nahrazena.",
      noQuestion: "Žádné otázky nejsou dostupné",
      MandatoryWarning: "Prosím vyplňte všechny povinné pole pro místní jazyk",
      certificationSubmission:
        "Modul byl odeslán TME za účelem certifikace prodejce",
      sameToolBox: "Tentýž modul nelze odesílat opakovaně. Vyberte prosím jiný modul",
      deleteConfirmDealer: "Are you sure you want to delete this dealer ?",
      updateVcConfirmDealer: "Všechny aktivity vytvořené pro tohoto dealera v 'Value chain' budou odstraněny. Jste si jisti, že chcete pokračovat?",
      updateBritConfirmDealer: "Všechny aktivity vytvořené pro tohoto dealera v \"Brit\" budou odstraněny. Jste si jisti, že chcete pokračovat?",
      completed_activity_delete:"Po smazání dat již nelze data načíst. Jste si jisti, že data chcete smazat?",
      deleteAllQuestionary: "Are you sure you want to delete the entire Questionnaire?",
      deleteAllLibraries: "Are you sure you want to delete all folders and files in the Library?",
      selectAll: "Select All",
      unSelectAll: "Unselect All",
      select:"Select...",
      warningMsg:"There are Open Action plan(s) in this activity. Would you like to Complete the activity?"
    },
    status: {
      published: "Zveřejněno",
      retired: "Zrušeno",
      saved: "Uloženo",
      rejected: "Odmítnuto",
      open: "Otevřít",
      closed: "Zavřené",
      close: "Zavřené",
      ongoing: "Probíhající",
      completed: "Dokončené",
      complete: "Dokončené",
      planned: "Plánované",
      overdue: "Zpožděný",
      due: "Očekávaný",
      pending: "Pending",
    },
    Rejected: "Odmítnuto",
    Open: "Otevřít",
    Closed: "Zavřené",
    Close: "Zavřené",
    Ongoing: "Probíhající",
    Completed: "Dokončené",
    Complete: "Dokončené",
    Planned: "Plánované",
    tabDetails: {
      noActionAvailable: "Žádná akce není k dispozici",
      activeDealer: "Tento dealer je momentálně registrovaný jako aktivní v A2D a nemůže být smazán.",
      dealerDeleted: "Dealer is deleted."
    },
    Table: {
      action: "",
      activity: "Aktivity",
      activityCompletionDate: "Datum dokončení aktivity",
      activityCompletionDateFrom: "Datum ukončení aktivity od",
      activityCompletionDateTo: "Datum ukončení aktivity do",
      activityStartDate: "Datum zahájení aktivity",
      activityStartDateFrom: "Datum zahájení aktivity od",
      activityStartDateTo: "Datum zahájení aktivity do",
      assignedTo: "Přiřazeno",
      assignee: "Odpovědná osoba",
      benchmark: "Benchmark",
      city: "Město",
      completedBy: "Dokončil/a ",
      completionBy: "Ukončené",
      completionDate: "Datum ukončení ",
      counterMeasure: "Opatření",
      country: "Stát",
      createdBy: "Vytvořil/a",
      dealer: "Dealer",
      dealerNameAD: "Jméno dealera (A2D)",
      dealerName: "Název prodejce",
      definition: "Definice",
      description: "Popis",
      dueDate: "Termín dokončení",
      endDate: "Datum ukončení",
      firstVisit: "První návštěva",
      followup: "Následná akce",
      franchise: "Franchise",
      history: "Historie",
      id: "ID",
      initial: "Výchozí",
      kpi: "KPI",
      launch: "Spustit",
      mandatory: "Povinné",
      nmsc: "Dovozce",
      problem: "Problém",
      publishedOn: "Zveřejněno dne",
      questionReference: "Otázky",
      reference: "Reference",
      reminderOn: "Připomínka zapnutá",
      score: "Skóre",
      startDate: "Datum zahájení",
      status: "Stav",
      target: "Target",
      toolbox: "Nástroje",
      view: "Zobrazit",
      kpiResult: "KPI výsledky",
      nmscTarget: "NMSC Target",
      penEbenchmark: "PanE Benchmark",
      kpiField: "KPI",
      location: "Poloha",
      ActionItemReport: "Probíhající akční položky",
      certification: "Stav certifikace",
      noOfToolbox: "Počet dokončených nástavení",
      program: "Program",
      certificationDate: "Datum certifikace",
      activityId :'ID aktivita',
      approvedBy:"Approved By",
      kpis:"KPIs",
      lastmonthKpis:"Last Month KPIs",
      questionPercent:"Completion %",
      actionPlan: "Plán činností",
      monthlyKpi:"Monthly KPIs",
      initialKpi:"Initial KPIs",
      targetKpi:"Target KPIs",
     },
     tabsDetails: {
      actionPlan: "Plán činností",
      confirmDeleteMessage: "Opravdu chcete smazat vybrané KPI?",
      confirmRetireMessage: "Potvrďte prosím, zda chcete zrušit vybraný KPI, protože již by dříve publikován",
      errorRetireMsg: "Tento KPI nemůže být vyřazen, jedná se o povinné KPI",
      import: "Importovat",
      importKpiMessage: "Importujte své KPI ze seznamu KPI hlavního TME",
      kpi: "Key Performance Indicator",
      library: "Knihovna",
      noActionAvailable: "Žádná akce není k dispozici",
      NoKPI: "Žádné KPI nejsou k dispozici",
      publish: "Zveřejnit",
      questionnaire: "Dotazník",
      tmeKPIList: "TME Master KPI",
      toyotaCityBrussels: "TOYOTA CITY BRUSSELS",
      attachment: "Attachment",
      attachments: "Attachment(s)",
      uploadAttachment: "Upload Attachment",
      noAttachment: "No attachments available",
    },
    tmeAdminMaster: {
      ToolboxName: "Název nastavení",
      newToolboxName: "Nové jméno",
      deleteToolbox: "Vymazat nastavení ? ",
      programName: "Název programu",
      ToolboxName_EN: "Název nastavení (EN)",
      deleteTool: "Opravdu chcete smazat toto nastavení ? ",
      selectToolbox: "Prosím vyberte nastavení, které chcete smazat.",
      editToolBox: "Upravit nastavení",
      addToolbox: "Přidat nastavení",
      toolbox: "Prosím, vložte název pro toto nastavení",
      addActionPlan: "Add Plán činností",
      actionPlanItem: "Plán činností - Č. položky",
      add: "Přidat",
      addActivity: "Přidat aktivitu",
      additionalInformation: "Doplňující informace",
      benchmark: "Benchmark",
      cancel: "Zrušit",
      createEditActivity: "Vytvořit/upravit aktivitu",
      createKPI: "Vytvořit KPI",
      delete: "Vymazat",
      download: "Stáhnout",
      edit: "Upravit",
      editKPI: "Upravit KPI",
      keyPerformanceIndicator: "Key Performance Indicator",
      kpiDefinition: "Definice KPI",
      mandatoryKpi: "Povinné KPI",
      save: "Uložit",
      search: "Hledat",
      unit: "Jednotka",
      update: "Aktualizace",
      validData: "Zadejte prosím platná data",
      hide: "Skrýt",
      show: "Zobrazit",
      upload_dealer_network: "Nahrát dealerskou síť ",
      upload_question: "Nahrát dotaz ",
      upload_user: "Nahrát Uživatelské informace",
      upload_activity: "Nahrát aktivity ",
      remove: "Remove",
      toyota: "Toyota",
      lexus: "Lexus",
      launch: "Launch",
      close: "Close",
      generate: "Generate",
      dueDateExceed:"Due date exceeded",
      delQuestionnaire:"Delete questionnaire"
    },
    toastr: {
      activityStatus_condition: "Odpovězte prosím na všechny otázky, abyste mohli tuto aktivitu ukončit.",
      emptyTarget: "Please enter the NMSC target to publish the KPI",
      retailerActual: "Please enter the Retailer Actual",
      initialTargetMonthly: "Please enter the initial KPI measurement, the KPI targets and at least 1 month of measurement to continue.",
      initialTarget: "Chcete-li pokračovat, zadejte počáteční měření KPI a cíle KPI.",
      upload_error: "Chyba při nahrávání dealerské sítě",
      action_item_create_warning: "Maximálně 5 záznamů může být spojeno do jednoho Action souboru",
      actionPlanStartDateValidation: "Datum ukončení nemůže být dříve než datum zahájení",
      actionsNotClosed: "Stále probíhají akce; zavřete prosím všechny akce před uzavřením aktivity",
      activityDuplicate: "Již existuje jiná aktivita se stejným prodejcem a nástrojem",
      assigneeNotExist: "Zvolená osoba není v systému registrována",
      assigneeValidation: "Odpovědná osoba nenalezena",
      atleastOnePublish: "Vyberte alespoň jedno KPI k zveřejnění",
      cannotRetireActive: "Jedná se o povinné KPI, které nelze vyřadit ",
      completionDate: "Datum dokončení nemůže nastat dříve než datum první návštěvy",
      confirDeletePlannedActivity: "Opravdu chcete tuto plánovanou aktivitu smazat?",
      DateformatValidation: "Datum musí být uvedeno ve formátu DD/MM/RRRR",
      DateValidation: "Datum zahájení musí předcházet datu ukončení",
      dealerValidation: "Neplatný prodejce",
      deleteSelectedConfirm: "Opravdu chcete smazat vybrané KPI?",
      duplicate: "Již existuje jiné KPI se stejným názvem a popisem",
      modalMandatory: "Prosím, zadejte platné údaje do všech povinných polí",
      noPublishKpi: "KPI nebyly dosud zveřejněny pro vybrané nástroje",
      validSearchCriteria: "Zadejte platné kritérium vyhledávání",
      multiSelectPlaceHolder: "Zadejte alespoň 5 znaků",
      multiSelectPlaceHolderUser: "Zadejte alespoň 3 znaků",
      startDateValidation: "Datum zahájení nesmí být pozdější než datum ukončení",
      fileSelectionErr: "Prosím vyberte nejdříve soubor",
      atleastOneQuestion: "Prosím vyberte alespoň jednu podotázku na import.",
      atleastOneSubQues: "Vyberte alespoň jeden dílčí dotaz k uveřejnění.",
      uploadErr: "Počet souborů neúspešně nahraných:",
      uploadSuccess: "Dealerská síť byla úspěšně nahrána",
      file_upload_success: "Soubor/y byly úspěšně nahrány",
      KPIinOngoingClosedActivity: "Tento KPI je obsažen minimálně v jedné Probíhající/Uzavřené aktivitě. Před prováděním změn daného KPI prosím tyto aktivity dokončete. ",
      Role_Error: "Nastavení uživatele není kompletní, prosím kontaktujte správce NMS KET.",
      points_allocation_incorrect: "Nesprávné přidělení bodů, prosím upravte.",
      activity_deleted:"Aktivita je úspěšně zrušena",
      activity_certified_dealer:"Dealer byl cetrifikován pro vybranou aktivitu, nelze smazat.",
      activity_submited_for_certificate_dealer:"Tato aktivita byla zahrnuta do žádosti o certifikaci odeslané do TME, proto stav nelze změnit."
    },
    TopNav: {
      ActivityLog: "Aktivity",
      ApplicationName: "Kaizen Expert Tool",
      Home: "Domů",
      logout: "Odhlásit",
      MasterDataUpload: "Nahrát základní data",
      Toolbox: "Modul",
      welcome: "Vítejte",
      Admin: "Administrátor"
    },
    questionnaire: {
      addSubQuestion: "Přidat podotázku",
      editSubQuestion: "Upravit podotázku",
      subQuestionTitle: "Podotázka",
      additionalInfo: "Doplňující informace",
      na: "Na tuto otázku lze použít odpověď 'Nevztahuje se'",
      question: "Otázka",
      questionTitle: "Název otázky",
      why: "Proč?",
      addMainQuestion: "Přidat hlavní otázku",
      editMainQuestion: "Upravit hlavní otázku",
      previousQuestion: "Předcházející oázka",
      nextQuestion: "Další otázka",
      what: "Co? ",
      library: "Knihovna",
      subQuestionTable: {
        no: "Ne",
        subQuestion: "Podotázky",
        status: "Stav",
        publishedDate: "Datum zvěřejnění",
        notApplicable: "Nevztahuje se"
      },
      links: {
        question_link_button: "Link",
        question_link_include_folder: "Zahrnout podsložku",
        question_link_add_link: "Připojte soubor/y z knihovny",
        question_link_view_folder: "Knihovna"
      },
      mainQuestion: "Nové / aktualizované otázky:",
      subQuestion: "Nové / aktualizované podotázky:",
      retiredQuestion: "Vyřazené podotázky:",
      libraries: "Nové / aktualizované soubory v Knihovnách:",
      noFiles: "Žádné soubory nejsou dostupné."
    },
    library: {
      selectFile: "Prosím vyberte alespoň jeden soubor.",
      fileSelected: "Vybrané soubory",
      publish_library_file: "Prosím vyberte alespoň jeden soubor pro nahrání",
      library_files_deleted: "Opravdu chcete tento soubor smazat?",
      library_files_linked_deleted: "Opravdu chcete tento soubor a odkazy vytvořené v dotazníku smazat?",
      max_size_error: "Velikost souborů pro nahrání převyšuje limit 1GB",
      empty_file_desc: "Vložte popis souboru.",
      file_not_selected: "Vyberte prosím soubor/y k nahrání (max. velikost 1GB)",
      file_type_error: "Formát souboru není podporován",
      file_name_error: "Chybný znak v názvu složky ",
      file_name_lenght_error: "Název složky může obsahovat maximálně 50 znaků.",
      addFolder: "Přidat složku",
      editFolder: "Editovat složku",
      currentFolder: "Název složky",
      parentFolder: "Nadřazená složka ",
      emptyFolderName: "Jméno složky nemůže být prázdné",
      invalidFolderName: "Název složky nesmí obsahovat znak '/'.",
      folder_created: "Složka byla úspěšně vytvořena",
      folder_updated: "Složka byla úspěšně aktualizována",
      folder_not_found: "Složka nenalezena",
      lib_invalid_toolbox: "Byl vybrán neplatný Toolbox",
      lib_folder_already_exists: "Složka již existuje ",
      lib_folder_deleted: "Složka byla úspěšně smazána",
      library_files_published: "Soubory byly nahrány",
      addFile: "Přidat soubor ",
      editFile: "Editovat soubor",
      fileName: "Název souboru",
      upload: "Nahrát",
      desc: "Popis",
      folderCreationErr: "Hierarchie je omezena do 2 stupňů.",
      subFolderWarning: "Vyberte podsložku pro přidání souborů",
      deleteWarning: "Vyberte složku pro smazání",
      beforeAfterNotSelected: "Please select if the evidence is before or after kaizen",
    },
    chapter: {
      chapter_exist: "Kapitola již existuje.",
      chapter_saved: "Kapitola byla uložena",
      chapter_cannot_delete: "Tuto kapitolu nelze vymazat, protože podotázky již byly zveřejněny.",
      failed: "Chyba !",
      chapter_deleted: "Kapitola vymazána",
      chapter_nonEmpty: "Vytvořte název kapitoly",
      addchapter: "Přidat kapitolu",
      editChapter: "Upravit oddíl",
      DELETE_SUCC:"Questionnaires deleted successfully.",
      DELETE_SUCC_LIB:"Libraries deleted successfully.",
      DELETE_ERR : "Something Went wrong."
    },
    evaluation: {
      attachments: "Přílohy",
      comments: "Komentáře",
      evidence: "Evidence",
      evidences: "Evidence",
      yes: "Ano",
      no: "Ne",
      na: "NA",
      uploadEvidence: "Nahrát evidenci",
      fileUpload: "Vyberte prosím soubor/y k nahrání (max. velikost 1GB)",
      who: "Kdo ?",
      answer: "Odpověď",
      comment: "Comment",
      date: "Datum",
      submit: "Potvrdit",
      commentMsg: "Prosím vložte komentář.",
      noCommentAvailable: "Žádné komentáře nejsou dostupné.",
      uploadSuccessMsg: "File(s)  have been uploaded successfully",
      uploadErrorMsg: "Number of files failing to upload: ",
      fileWithSameName: "Jiný soubor se stejným názvem již existuje.",
      noEvidence: "Žádné podklady k dispozici",
      createAction: "Vytvořit Action soubor",
      chapterName: "Název kapitoly",
      questionNo: "Otázka č. ",
      when: "Kdy ? ",
      noRecords: "Žádné záznamy.",
      kaizenPoints: "Body Kaizen ",
      renameSuccessMsg: "File has been renamed successfully",
      deleteSuccessMsg: "File has been deleted successfully",
      question: "Otázka",
      before:"Before",
      after:"After",
      beforeAfterLabel:"Is this evidence from before or after kaizen?",
      move:"Move",
      moveMsg: "File(s) has been moved successfully",
      noToYesMsg:"Previous answer was NO",
    },
    WALK_AROUND_CHECK: "Fyzický příjem vozu",
    ketProgram: {
      TSM: "TSM/LSM",
      BRiT: "BRiT RI",
      TSM_BNP: "Body & Paint",
      TSM_USED_CAR: "Ojeté vozy",
      value_chain: "Value Chain",
      brit: "BRiT",
      ea: "EA",
      per:"Performance",
      nettrans:"Network Transformation",
    },
    dashboard: {
      notice_board: "Nástěnka",
      kpi_target_not_set: "Cíle pro KPI nenastaveny",
      kpi_initial_not_set: "Výchozí měření KPI nenastaveno",
      kpi_monthly_not_set: "Měsíční měření KPI nenastaveno",
      kpi_below_target: "Nesplněné cíle KPI",
      activation: "Aktivace",
      users: "Uživatelé",
      nmscs: "NMSC's",
      activeRetailer: "Aktivní partneři",
      certificationRate: "Certifikační hodnocení",
      reCertificationRate: "Recertifikační hodnocení",
      activityDue: "Plánované činnosti",
      avgdays: "Průměrný počet dní / nastavení",
      overDueAct: "Zpožděné aktivity",
      actionItem: "Akční položky",
      bussResult: "Obchodní výsledky",
      toolboxImpl: "Implementace nastavení",
      statusCompletedL:"Status - Completed, Progress - ",
      statusClosedL:"Status - Closed, Progress - ",
      statusOngoingL:"Status - Ongoing, Progress - ",
      recentUpdates: "Poslední aktualizace",
      rolloutTopNmsc: "Výsledky rollout - nejlepší NMSC",
      bestPractice: "Nástěnka úspěchů",
      availableKpi: "Dostupné KPI",
      selectedKpi: "Vybrané KPI",
      dealerBestPractice: "Panel nejlepších známých postupů prodejce",
      kpiList: "Seznam KPI",
      go: "Jít",
      nodata: "Žádná data k dispozici",
      businessResultTop: "Obchodní výsledky - Top partneři",
      businessResultNMSC: "Obchodní výsledky - nejlepší NMSC"


    },
    dealerCertification: {
      dealerCertification: "Certifikace dealera",
      readyForCertification: "Připraveno k certifikaci",
      certification: "Certifikace",
      submittedOn: "Odesláno dne",
      certificateDate: "Datum certifikátu",
      reCertificateDate: "Datum re-certifikátu",
      toolboxDetails: "Podrobnosti nastavení",
      approve: "Schválit",
      reject: "Zamítnout",
      pending: "Nevyřízeno",
      region: "Oblast"



    },
    uploadBulk: {
      browse: "Browse",
      executionId: "Provedení id",
      uploadedOn: "Nahráno dne",
      lastUpdatedOn: "Poslední aktualizace dne",
      noDataTable: "No data to view",
      kpiUpload: "Načítání KPI",
      action: "Akce",
      processed: "Zpracované",
      error: "Chyba",
      received: "Received",
      createActivity: "Vytvořit aktivity",
      fileUpload: "File Upload",
      targetSetting: "Target Setting",
      kpiEntry:"KPI Entry",
      accepted:"Accepted",
      launchNmsc:"Launch for NMSC"
    },

    userManagement: {
      userManagement: "Správa uživatelů",
      createUser: "Create User",
      editUser: "Edit User",
      viewUser: "View User",
      nmscId: "NMSC ID",
      tarsId: "TARS ID",
      firstName: "Křestní jméno",
      lastName: "Příjmení",
      emailId: "E-mail",
      britDealerChamp: "Brit Dealer Champion",
      invalidEmail: "Please enter valid E-Mail ID",
      invalidTars: "Please enter valid TARS ID",
      invalidFirstName: "Please enter valid First Name",
      invalidLastName: "Please enter valid Last Name",
      user_created: "User Created",
      user_updated: "User updated",
      duplicate_tarsuser: "This TARS id is already registered in the KET database.",
      user_define_in_tool: "User is defined as Business owner in Active Business Function. Hence, could not be made Inactive",
      user_refer_on: "User is referenced in Ongoing activity",
      pbiAccount : "Účet PBI",
      invalidPbiAccount : "Účet PBI by měl obsahovat minmálně 3 znaky.",
      businessArea: "Business Area",
      valueChainAndBRiT: "Value Chain & BRiT",
      only:"Only",
      optionText:"Please select at least one of the two options below",
      valueChainOnly: "Value Chain pouze",
      britOnly: "Brit pouze"

    },
    UserManagement: {
      nmscId: "NMSC ID",
      tarsId: "TARS ID",
      firstName: "Křestní jméno",
      lastName: "Příjmení",
      emailId: "E-mail",
      languageCode: "Jazykový kód",
      ACTIVE: "Active",
      INACTIVE: "Neaktivní",
      addUser: "Přidat uživatele",
      Assign: "Přiřadit",
      List: "Seznam",
      user: "Uživatel",
      error_message: "Došlo k chybě, zkuste prosím později.",
      role: "Role uživatele",
      standardMessage: "No User Found",
      dealerCode: "Kód dealera",
      a2d: "A2D UUID",
      britReady: "Prodejce se účastnil Brit Ready.",
      britLive: "Prodejce se účastnil Brit Live.",
      britJapan: "Prodejce se účastnil Brit Discovery Japan.",
      britEss:"Prodejce aktualizoval EES akční plán",
      addDealer: "Přidat dealera (z A2D)",
      noDealer: "Žádný nalezený dealer.",
      dealerUuid: "Kód dealera uuid (A2D)",
      language: "Jazyk",
      L3: "L3",
      L4: "L4",
      L5: "L5",
      L6: "L6",
    },
    CeaKpi: {
      survey: "Survey",
      evaluationItem: "Evaluation Item",
      kpiMetric: "KPI Evaluation Method",
      stratagicPillar: "Strategic Pillar",
      addKPI: "Add KPI",
      edit: "Edit",
      delete: "Delete",
      subPillar: "Sub-Pillar",
      kaizenImp_impact_def: "Kaizen Importance / Impact / Definition",
      evalMethod: "Evaluation Method",
      scoreMethod: "Scoring Method",
      typeOftarget: "Type of Target",
      target: "Target",
      mode: "Mode",
      points: "Points",
      inputFreq: "Input Frequency",
      cea_kpi_created: "CEA KPI has been created successfully",
      noPointsAllocated:"No Points defined for selected Pillar/Business function mapping",
      targetIncorrect:"Target value is Invalid",
      viewKpi:"View KPI",
      general:"General",
      targetScoring:"Targets & Scoring",
      inputs:"Input Parameters",
      maxFiveParam:"Maximum 5 parameters for a KPI can be added",
      validParam:"Please Enter Valid Parameter",
      validDesc:"Please Enter Valid Parameter Description",
      dataEntry:"Data Entry in KET",
      paramater:"Parameter",
      paramDesc:"Parameter Description",
      noParamAvai:"No Parameters Available",
      kpiDeleted:"KPI deleted Successfully",
      kpiDeleteError:"KPI Not found",
      kpiActualsError:"Actuals present. KPI cannot be deleted",
      cea_kpi_updated: "CEA KPI has been updated successfully",
      addParam:"Add Parameters"

    },
    dealerManagement: {
      invalidUuid: "Prosím zadejte platné A2D uuid.",
      invalidDealerName: "Prosím zadejte platné jméno dealera.",
      invalidCity: "Prosím zadejte platnou polohu.",
      invaliDealerCode: "Prosím zadejte platný kód dealera.",
      existingUuid: "A2D uuid kód je používaný v KET databázi. ",
      updateUuid: "Aktualizujte A2D uuid kód",
      newUuid: "Nový A2D uuid kód",
    },
    networkManagement: {
      networkManagement: "Správa sítě",
    },
    kpiEntry: {
      cancel : "Cancel",
      save : "Save",
      no : "No",
      yes : "Yes",
      hide : "Hide",
      show : "Show",
      dataNotSaved : "Data is not saved. Entered data may be lost. Do you want to Proceed?",
      wantToProceed : "Do you want to proceed?",
      status: 'Status',
      evaluationYear: 'Evaluation Year',
      frequency: 'Frequency',
      typeOfTarget: 'Type of Target',
      target: 'Target',
      unit: 'Unit',
      standardMessage: 'No Kpi Actuals Data Found',
      srNo: 'S.No.',
      actuals: 'Actuals',
      subPillar: 'Sub Pillar',
      businessFunc: 'Business Function',
      brand: 'Brand',
      Jan : "Jan",
      Feb : "Feb",
      Mar : "Mar",
      Apr : "Apr",
      May : "May",
      Jun : "Jun",
      Jul : "Jul",
      Aug : "Aug",
      Sep : "Sep",
      Oct : "Oct",
      Nov : "Nov",
      Dec : "Dec",
      q1 : "Q1",
      q2 : "Q2",
      q3 : "Q3",
      q4 : "Q4",
      h1 : "H1",
      h2 : "H2",
      cy : "CY",
      kpi : "Key Performance Indicators",
      actuals1 : "Actuals should not be more than allotted points. Please re-enter",
      actionPlan	: "Action Plan",
      enterActualsData : "Please enter actuals data.",
      notNumber : "Please enter valid number.",
      serverErrorMsg : "An error occured, please try again later.",
      launchEvaluationError : "Please launch evaluations.",
      actualsDataSaved : "Actuals data uploaded successfully.",
      inputTxt:"Inputs"
    },
    ceaEvaluation :{
      nmsc : "NMSC",
      search : "Search",
      all : "All",
      status : "Status",
      businessFunction :"Business Function",
      noEvaluationsFound : "No Evalutions Found",
      evaluationYear : "Evalution Year",
      view : "View",
      evaluations : "Evaluations",
      launchTargetSetting: "Launch Target Setting",
      launchEvaluation : "Launch Evaluation",
    },
    britDashboard:{
      noVideo:"No videos available",
      playVideo:"Play Video",
      trainingVideos:"Training  Videos",
      download: "Download Video",
      back:"Back"
    },
    ceaActionPlan:{
      id: "ID",
      counterMeasure: "Countermeasure",
      questionReference: "KPI Reference",
      status: "Status",
      startDate: "Start Date",
      dueDate: "Due Date",
      assignedTo: "Assigned To",
      noActionAvailable: "No Actions available",
      add: "Add",
      download: "Download",
      edit: "Edit",
      delete: "Delete",
      deleteMsg: "Are you sure you want to delete ?",
      no: "No",
      yes: "Yes",
      addActionPlanNotAllowed: "The Action Plan cannot be created, or edited after the Activity has been Closed, Completed, or Rejected.",
      actionPlanItem: "Action Plan - Item #",
      addActionPlan: "Add Action Plan",
      open: "Open",
      actionPlanStartDateValidation:"End Date cannot be earlier than the Start Date",
      modalMandatory: "Please enter valid data in all the mandatory fields",
      problem: "Problem",
      description: "Description",
      followup: "Follow Up",
      history: "History",
      cancel: "Cancel",
      save: "Save",
      update: "Update",
      assignee: "Assignee",
      endDate: "End Date", 
      select: "Select",
      notApplicablePer:"Not Applicable for CEA, Networks and Used car"
    },
    reachItDashboard:{
      topNavReachIT:"ReachIT",
      downloads:"Ke stažení",
      historyFiles:"Historie předchozích souborů",
      reachText1:"Vítejte na domovské stránce REACH IT. Tým správy životního prostředí TME bude tuto stránku využívat ke sdílení informací týkajících se SCIP tzv. databáze informací o látkách vzbuzující obavy v předmětech jako takových nebo ve složených produktech (Substances of Concern In articles as such or in complex objects)  řízená podle rámcové směrnice o odpadech (WFD -Waste Framework Directive), abyste mohli splnit svou povinnost a zpracovat zjednodušené oznámení SCIP.",
      reachText2:"V případě dotazů se prosím obraťte na tým správy životného prostředí TME prostřednictvím níže uvedeného odkazu",
      reachText3:"V případě dotazu kontaktujte prosím na své NMSC.",
      envMngt:"Správa životního prostředí",
      fileStatistics: "Statistiky stažených souborů",
      Downloaded: "Stažené",
      not: "Ne",
      exportToExcel:"Exportovat do Excelu"
    },
    usedCarComment:{
      ongoing:"Ongoing",
      completed:"Completed",
      commentHistory:"Monthly Comments History",
      addComment:"Add Monthly Comment",
      month:"Month",
      comment:"Comment",
      username:"Username",
      email:"Email",
      createdDate:"Created Date"
    },
  }
};

export default translationsCS;
